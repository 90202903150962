import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import { useFormik } from "formik";
import * as Yup from "yup";
import {connect, useDispatch} from "react-redux";
import moment from "moment";
import {Checkbox} from "antd";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import { changeStep, changeValues } from "../../../../../../redux/actions/stepsActions";
import FormikInput from "../../../../../SimpleComponents/FormikInput";

interface CustomProps {
  steps?: any,
  setButtonDisabled: (state:boolean) => void,
  id?: any
}

function LocalDefaultContainer({steps, setButtonDisabled, id}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [multiCountriesLayout, setMultiCountriesLayout] = useState(false);

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    if (parsedSearch && parsedSearch["?multi"]) {
      setMultiCountriesLayout(true);
    }
  }, []);

  // Get current language to show different text

  const validateYum = (value:string, isEmail = false) => {
    return Yup.string().when(value, {
      is: (value:any) => value?.length > 0,
      then: isEmail
        ? Yup.string().required(t("partner_portal.deals.create.warnings.validation.required"))
          .email(t("partner_portal.deals.create.warnings.validation.email"))
        : Yup.string().required(t("partner_portal.deals.create.warnings.validation.required")),
      otherwise: Yup.string().notRequired()
    });
  };

  const formik = useFormik<any>({
    initialValues: {
      country: ["at"],
      offer: "",
      startDate: "",
      endDate: "",
      specialOffer: false
    },
    validationSchema: Yup.object().shape({
      country: Yup.array().required(t("partner_portal.deals.create.warnings.validation.required")).min(1),
      offer: Yup.string().required(t("partner_portal.deals.create.warnings.validation.required"))
    }, [
      ["secondContactRowName", "secondContactRowEmail"],
      ["secondContactRowEmail", "secondContactRowName"],
      ["thirdContactRowName", "thirdContactRowEmail"],
      ["thirdContactRowEmail", "thirdContactRowName"]
    ]),
    onSubmit: () => {
      // Block submit
    },
  });

  const [stateIsInitialized, setStateIsInitialized] = useState(false);

  useEffect(() => {
    if (!steps.values[0].startDate && !stateIsInitialized) {
      formik.setFieldValue("startDate", moment(Date.now()).format("YYYY-MM-DD"));
      formik.setFieldValue("endDate", moment(Date.now()).format("YYYY-MM-DD"));
    }
  }, [steps]);

  // Initialize form values
  useEffect(() => {
    if (!stateIsInitialized && steps.values[0].startDate) {
      Object.keys(steps.values[0]).forEach((key:string) => {
        formik.setFieldValue(key, steps.values[0][key], true);
        setTimeout(() => formik.setFieldTouched(steps.values[0][key], true));
      });
    }
    setStateIsInitialized(true);
  }, [steps]);

  // Submit form handler
  const handleFormSubmit = (e:any) => {
    e.preventDefault();
    if (formik.isValid && formik.dirty) {
      dispatch(changeStep(1));
    }
  };

  const [formWithIdIsInitialized, setFormWithIdIsInitialized] = useState(false);

  // Save values to state
  useEffect(() => {
    if (stateIsInitialized) {
      const stateCopy = [...steps.values];
      stateCopy[steps.step] = formik.values;
      dispatch(changeValues(stateCopy, 0));
    }

  }, [formik.values]);

  // Handle Date Picker changes
  const handleDateChange = (dates:any) => {
    formik.setFieldValue("startDate", moment(dates[0].startDate).format("YYYY-MM-DD"));
    formik.setFieldValue("endDate", moment(dates[0].endDate).format("YYYY-MM-DD"));
  };

  const getContactFieldName = (idx:number) => {
    switch (idx) {
    case 0:
      return {
        name: "firstContactRowName",
        email: "firstContactRowEmail"
      };
    case 1:
      return {
        name: "secondContactRowName",
        email: "secondContactRowEmail"
      };
    case 2:
      return {
        name: "thirdContactRowName",
        email: "thirdContactRowEmail"
      };
    }
  };

  useEffect(() => {
    if (id && steps.values[0].partner && !formWithIdIsInitialized) {
      setFormWithIdIsInitialized(true);
      setStateIsInitialized(true);
      Object.keys(steps.values[0]).forEach((key:string) => {
        formik.setFieldValue(key, steps.values[0][key], true);
        setTimeout(() => formik.setFieldTouched(steps.values[0][key], true));
      });
      // Contact fields
      steps.values[0].contact.map((contactField:any, idx:number) => {
        formik.setFieldValue(
          getContactFieldName(idx)?.name || "",
          steps.values[0].contact[idx].name,
          true
        );
        setTimeout(() => formik.setFieldTouched(
          getContactFieldName(idx)?.email || "", true));
        formik.setFieldValue(
          getContactFieldName(idx)?.email || "",
          steps.values[0].contact[idx].email,
          true
        );
        setTimeout(() => formik.setFieldTouched(
          getContactFieldName(idx)?.email || "", true));
      });

    }
  }, [steps.values]);

  useEffect(() => {
    setButtonDisabled(!(formik.isValid && formik.dirty));
  }, [formik.dirty, formik.isValid]);

  const handleCountrySelect = (event:any, country: "de" | "at") => {
    const countriesCopy = [...formik.values.country];
    let filteredArray:string[] = [];
    if (countriesCopy.includes(country)) {
      filteredArray = countriesCopy.filter(function(val:string) {
        return val !== country;
      });
    } else {
      countriesCopy.push(country);
      filteredArray = countriesCopy;
    }
    formik.setFieldValue("country", filteredArray, true);
  };

  return (
    <form onSubmit={(e:any) => handleFormSubmit(e)}>
      <div className="default_container">
        <div data-testid="default_partner_container">

          <p className="simple_label_checklist">
            {t("partner_portal.deals.create.default_container.form.offer_label")}
          </p>
          <p className="sub_label_checklist" style={{margin: 0}}>
            {t("partner_portal.deals.create.default_container.form.offer_sub_label")}
          </p>
          <FormikInput
            label={""}
            htmlFor="offer"
            name="offer"
            errors={formik.errors.offer}
            touched={formik.touched.offer}
            value={formik.values.offer}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />

          <div
            style={{maxWidth: "664px"}}
          >
            <Checkbox
              onChange={(e: any) => formik.setFieldValue("specialOffer", !formik.values.specialOffer, true)}
              checked={formik.values.specialOffer}
              style={{marginRight: "7px"}}
            />
            <strong>{t("partner_portal.deals.create.default_container.form.special_offer")}</strong>
            <span style={{fontSize: "12px"}}>
              {t("partner_portal.deals.create.default_container.form.special_offer_text")}
            </span>
          </div>

          <div style={{ height: "20px" }} />

        </div>
        <div className="right_container">
          <div>
            {multiCountriesLayout
              ? <>
                <h3
                  style={{marginBottom: 0}}
                >
                  {t("partner_portal.deals.create.default_container.form.countries_title")}
                </h3>
                <p
                  style={{fontSize: "14px", marginTop: 0, marginBottom: 0}}
                >
                  {t("partner_portal.deals.create.default_container.form.countries_text")}
                </p>

                <Checkbox
                  onChange={(e:any) => handleCountrySelect(e, "at")}
                  checked={formik.values.country.includes("at")}
                >
                  {t("partner_portal.deals.create.default_container.form.countries_selection_text",
                    // eslint-disable-next-line max-len
                    {country: t("partner_portal.deals.create.default_container.form.countries_selection_text_aut")}
                  )}
                </Checkbox>

                <Checkbox
                  style={{marginLeft: 0, marginBottom: "10px"}}
                  onChange={(e:any) => handleCountrySelect(e, "de")}
                  checked={formik.values.country.includes("de")}
                >
                  {t("partner_portal.deals.create.default_container.form.countries_selection_text",
                    // eslint-disable-next-line max-len
                    {country: t("partner_portal.deals.create.default_container.form.countries_selection_text_de")}
                  )}
                </Checkbox>
              </>
              : null
            }

            <p className="simple_label_checklist" data-testid="default_calendar_title">
              {t("partner_portal.deals.create.default_container.form.offer_expire_label")}
            </p>
            <p
              className="sub_label_checklist"
              style={{margin: 0}}
              data-testid="default_calendar_sub_label"
            >
              {t("partner_portal.deals.create.default_container.form.local_offer_expire_sub_label")}
            </p>
          </div>
        </div>
      </div>
    </form>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    steps: state.steps
  };
};

export default connect(mapStateToProps, null)(LocalDefaultContainer);
