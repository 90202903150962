import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import useWindowDimensions from "../../utils/useWindowSize";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import {
  clearPlanData,
  getPlanData,
  postPlansData,
  updatePlan,
} from "../../redux/actions/plansActions";

import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import PageTitle from "Components/SimpleComponents/PageTitle";
import PlansContainer from "../../Components/Containers/PlansContainer";
import VerticalDivider from "../../Components/Containers/VerticalDivider";
import BenefitSummary from "../../Components/Containers/BenefitSummary";
import Modal from "react-modal";
import ModalPlansAndModules from "../../Components/SimpleComponents/ModalPlansAndModules";
import PlansSuccess from "../../Components/Containers/PlansSuccess";
import StopPlanModal from "../../Components/Modals/StopPlanModal";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { modalStyles } from "../../utils/modalStyles";
import { useCallbackPrompt } from "../../hooks/useCallbackPrompt";
import LeavingPageModal from "../../Components/Modals/LeavingPageModal/LeavingPageModal";
import {
  getUserCompany,
  getUserId,
} from "../../utils/cookies/userCookies/userCookies";

interface CustomProps {
  updatePlanData?: any;
  plans?: any;
  RouterProps?: any;
  update?: boolean;
  companyInfo?: any;
  company?: any;
}

function Plans({
  updatePlanData,
  plans,
  company,
  update,
  companyInfo,
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const location: any = useLocation();

  // show Success screen after posting new plan
  const [showSuccessScreen, setShowSuccessScreen] = useState(false);

  const [showDialog, setShowDialog] = useState<boolean>(!showSuccessScreen);
  const [showPrompt, confirmNavigation, cancelNavigation] =
    useCallbackPrompt(showDialog);
  const [isCopyPlan, setIsCopyPlan] = useState(false);
  // Supermarkets switch
  const [supermarketsAllowed, setSupermarketsAllowed] = useState(false);

  const [fetchedCopyPlan, setFetchedCopyPlan] = useState(false);

  useEffect(() => {
    if (!update) {
      dispatch(clearPlanData());
    }
    return () => {
      dispatch(clearPlanData());
    };
  }, []);

  const [prefilledSupermarket, setPrefilledSupermarket] = useState(false);
  useEffect(() => {
    if (company.company && !prefilledSupermarket) {
      if (update || location.state?._id) {
        //Plan update
        if (updatePlanData.plan) {
          setSupermarketsAllowed(!updatePlanData.plan.isSupermarketDisabled);
          setPrefilledSupermarket(true);
        }
      } else {
        //Plan create
        setSupermarketsAllowed(company.company.isSupermarketAllowed);
        setPrefilledSupermarket(true);
      }
    }
  }, [company, updatePlanData, isCopyPlan, params.planId]);

  const [isFetchedPlanData, setIsFetchedPlanData] = useState(false);
  useEffect(() => {
    if (params && params.planId) {
      dispatch(getPlanData(params.planId));
    } else if (location.state) {
      setIsCopyPlan(true);
      dispatch(getPlanData(location.state._id));
    }
    setIsFetchedPlanData(true);
  }, []);

  // initial true
  const [showBenefitsContainer, setShowBenefitsContainer] = useState(true);

  const handleLunchButton = (state: boolean) => {
    setShowBenefitsContainer(state);
  };
  // Handling summary amount of plans
  const [summaryAmount, setSummaryAmount] = useState<number>(0);

  //Show or hide submit popup initial: false
  const [showModal, setShowModal] = useState(false);

  const [disableSubmitButton, setDisableSubmitButton] = useState(true);

  const isValidTimePeriod = () => {
    if (isCopyPlan && plans && plans.length > 0) {
      const newPlanStartDate = moment(
        plans[0].dateRange.startDate,
        "DD.MM.YYYY"
      ).valueOf();
      const newPlanEndDate = moment(
        plans[0].dateRange.endDate,
        "DD.MM.YYYY"
      ).valueOf();
      const oldPlanStartDate = moment(updatePlanData.plan?.startDate).valueOf();
      const oldPlanEndDate = moment(updatePlanData.plan?.endDate).valueOf();

      if (
        newPlanStartDate > oldPlanEndDate ||
        newPlanEndDate < oldPlanStartDate
      ) {
        return true;
      }
    }
    return false;
  };

  useEffect(() => {
    if (plans.length > 0) {
      let disableButton = false;
      plans.forEach((plan: any) => {
        if (plan.limit) {
          if (+plan.limit === 0) {
            disableButton = true;
          }
        } else {
          if (+plan.restaurantLimit === 0 && +plan.supermarketLimit === 0 && Number(plan.generalLimit === 0)) {
            disableButton = true;
          }
        }
        if (plan.title.trim() === "") {
          disableButton = true;
        }
        if (plan.employee.length === 0) {
          disableButton = true;
        }
      });
      if (isCopyPlan) {
        if (!isValidTimePeriod()) {
          disableButton = true;
        }
        if (!isFetchedPlanData) {
          disableButton = true;
        }
        if (!fetchedCopyPlan) {
          disableButton = true;
        }
      }
      setDisableSubmitButton(disableButton);
    }
  }, [plans, isFetchedPlanData]);

  const handleSum = (sum: number) => {
    sum.toFixed(2);
    setSummaryAmount(sum);
  };

  // form data
  const [setFormData] = useState<any>();
  const handleData = (data: any) => {
    setFormData(data);
  };

  // handle submit
  const handleSubmit = () => {
    setShowModal(true);
  };

  // posting plans Data
  const postPlansAndModules = () => {
    // taking required data
    const companyId = getUserCompany() || "";
    const userId = getUserId() || "";

    const plansArray: any = [];

    plans.forEach((plan: any) => {
      const employeeArray: any = [];
      plan.employee.forEach((employee: any) => {
        if (employee._id) {
          employeeArray.push(employee._id);
        } else {
          employeeArray.push(employee);
        }
      });

      const startDate = moment(
        `${plan.dateRange.startDate.split(".").reverse().join("-")}T02:00:01`
      );

      const endDate = moment(
        `${plan.dateRange.endDate.split(".").reverse().join("-")}T22:59:59`
      );
      //invalid: "30-06-2023T01:00:01"
      //valid: "2023-06-30T01:00:01"

      const newPlan: any = {
        userId: userId,
        companyId: companyId,
        isActive: true,
        startDate: startDate,
        endDate: endDate,
        type: Number(plan.generalLimit || plan.limit) ? "2" : "1",
        title: plan.title,
        totalAmount: Number(plan.generalLimit || plan.limit)
          ? Number(plan.generalLimit || plan.limit)
          : +plan.restaurantLimit + +plan.supermarketLimit,
        employees: employeeArray,
        generalLimit: Number(plan.generalLimit || plan.limit) ? Number(plan.generalLimit || plan.limit) : 0,
        supermarket: Number(plan.generalLimit || plan.limit) ? 1 : plan.supermarketLimit ? plan.supermarketLimit : 0,
        restaurant: Number(plan.generalLimit || plan.limit) ? 1 : plan.restaurantLimit ? plan.restaurantLimit : 0,
        country: companyInfo.country,
        isSupermarketAllowed: supermarketsAllowed,
      };
      newPlan.selectedDays = plan.selectedDays
        ? plan.selectedDays
        : [1, 2, 3, 4, 5];
      newPlan.allowWorkOnHolidays = plan.allowWorkOnHolidays
        ? plan.allowWorkOnHolidays
        : false;
      plansArray.push(newPlan);
    });

    const postData: any = {
      plans: plansArray,
    };
    if (updatePlanData && Object.keys(updatePlanData).length && update) {
      if (moment().format("YYYY-MM-DD") < plansArray[0].endDate) {
        plansArray[0].endDate = `${moment().format("YYYY-MM-DD")}-T04:00:00`;
      }
      dispatch(updatePlan(plansArray[0], updatePlanData.plan._id));
    } else {
      dispatch(postPlansData(postData));
    }
  };

  useEffect(() => {
    if (updatePlanData && Object.keys(updatePlanData).length && update) {
      setShowForm(true);
      setShowBenefitsContainer(false);
    }
    if (
      updatePlanData &&
      updatePlanData.invoiceCount &&
      update &&
      !showSuccessScreen
    ) {
      setShowStopPlanModal(true);
    }
  }, [updatePlanData]);

  // Show new plan form
  const [showForm, setShowForm] = useState(false);

  // set less item in table for small screens
  const [mainRatio, setMainRatio] = useState("80% 20%");
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 1450) {
      setMainRatio("70% 30%");
    }
    if (width < 800) {
      setMainRatio("1fr");
    } else {
      setMainRatio("80% 20%");
    }
  }, [width]);

  useEffect(() => {
    const unloadCallback = (event: any) => {
      event.preventDefault();
      event.returnValue = "";
      return "";
    };

    window.addEventListener("beforeunload", unloadCallback);
    return () => window.removeEventListener("beforeunload", unloadCallback);
  }, []);

  const [showStopPlanModal, setShowStopPlanModal] = useState(false);
  return (
    <ContentContainer>
      {showPrompt ? (
        <LeavingPageModal
          confirmNavigation={confirmNavigation}
          cancelNavigation={cancelNavigation}
        />
      ) : null}

      <Modal style={modalStyles} isOpen={showModal}>
        <ModalPlansAndModules
          submitData={postPlansAndModules}
          closeModal={setShowModal}
          handleSuccessScreen={() => {
            setShowSuccessScreen(true);
            setShowDialog(false);
          }}
          updatePlanData={updatePlanData}
        />
      </Modal>
      <Modal style={modalStyles} isOpen={showStopPlanModal}>
        <StopPlanModal
          closeModal={() => setShowStopPlanModal(false)}
          invoiceCount={updatePlanData.invoiceCount}
        />
      </Modal>
      <MainSpace>
        <div className="center">
          <div className="plans_wrapper">
            <div className="row">
              <p
                onClick={() => navigate("/plans")}
                className="crumb"
                test-id="plans_create_crumb"
              >
                {t("dashboard.plans.breadcrumbs_list")}
              </p>
              <p className="crumb_arrow">{">"}</p>
              {showForm &&
              !showSuccessScreen &&
              !Object.keys(updatePlanData).length ? (
                  <>
                    <p onClick={() => setShowForm(false)} className="crumb">
                      {t("dashboard.plans.breadcrumbs_select")}
                    </p>
                    <p className="crumb_arrow">{">"}</p>
                    <p className="crumb_current">
                      {t("dashboard.plans.breadcrumbs_form")}
                    </p>
                  </>
                ) : (
                  <p className="crumb_current">
                    {t("dashboard.plans.breadcrumbs_select")}
                  </p>
                )}
            </div>
            <PageTitle mainTitle={t("dashboard.plans.container_title")} />
            {showSuccessScreen ? (
              <PlansSuccess sum={summaryAmount} />
            ) : isFetchedPlanData ? (
              <>
                <VerticalDivider ratio={mainRatio}>
                  <div>
                    <PlansContainer
                      updatePlanData={updatePlanData}
                      showForm={true}
                      setShowForm={(s: boolean) => setShowForm(s)}
                      handleData={handleData}
                      handleSum={handleSum}
                      plan="lunch"
                      showBenefits={handleLunchButton}
                      isCopyPlan={isCopyPlan}
                      addedPlans={plans}
                      setIsFetched={(value: boolean) =>
                        setFetchedCopyPlan(value)
                      }
                      supermarketsAllowed={supermarketsAllowed}
                      toggleSupermarketAllowed={() =>
                        setSupermarketsAllowed(!supermarketsAllowed)
                      }
                    />
                    {/*{showBenefitsContainer && (
                    <PlansContainer
                      updatePlanData={updatePlanData}
                      showForm={showForm}
                      setShowForm={(s: boolean) => setShowForm(s)}
                      handleData={handleData}
                      handleSum={handleSum}
                      plan="benefits"
                    />
                  )}*/}
                  </div>

                  <BenefitSummary
                    plans={plans}
                    disableSubmitButton={disableSubmitButton}
                    handleSubmit={handleSubmit}
                    sum={summaryAmount}
                  />
                </VerticalDivider>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedRows: state.selectedRows.selectedRows,
    plans: state.addedPlans.addedPlans,
    updatePlanData: state.plans.planData,
    companyInfo: state.company.company?.company?.companyInfo,
    company: state.company.company,
  };
};

export default connect(mapStateToProps, null)(Plans);
