// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.secondary-container {
    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;
    border-radius: 15px;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%;
    position: relative;
    background-color: white;
}

.download-wrapper {
    display: grid;
    grid-template-columns: 40% 60%;
}
@media (max-width: 700px) {
    .download-wrapper {
        grid-template-columns: 1fr;
    }
}

.download-app-image {
    width: 100%;
    height: 100%;
    text-align: right;
}
@media (max-width: 700px) {
    .download-app-image {
        display: none;
    }
}

.download-app-image > img {
    max-width: 90%;
    margin-right: 45px;
}

@media (max-width: 950px) {
    .download-app-image {
        height: 65%;
        padding-top: 10%;
        width: 100%;
    }
}

@media (max-width: 500px) {
    .download-app-image {
        height: 50%;
    }
}

@media (max-width: 700px) {
    .download_btn {
        width: 70%;
    }
    h2{
        float: revent;
        font-size: 12px;
    }
}

`, "",{"version":3,"sources":["webpack://./src/Components/Content/DownloadBox.css"],"names":[],"mappings":"AAAA;IAEI,+CAA+C;IAC/C,mBAAmB;IACnB,WAAW;IACX,sBAAsB;IACtB,eAAe;IACf,kBAAkB;IAClB,uBAAuB;AAC3B;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;AACA;IACI;QACI,0BAA0B;IAC9B;AACJ;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;AACA;IACI;QACI,aAAa;IACjB;AACJ;;AAEA;IACI,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,WAAW;IACf;AACJ;;AAEA;IACI;QACI,WAAW;IACf;AACJ;;AAEA;IACI;QACI,UAAU;IACd;IACA;QACI,aAAa;QACb,eAAe;IACnB;AACJ","sourcesContent":[".secondary-container {\n    -webkit-box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;\n    box-shadow: rgba(149, 157, 165, 0.2) 0 8px 24px;\n    border-radius: 15px;\n    width: 100%;\n    box-sizing: border-box;\n    max-width: 100%;\n    position: relative;\n    background-color: white;\n}\n\n.download-wrapper {\n    display: grid;\n    grid-template-columns: 40% 60%;\n}\n@media (max-width: 700px) {\n    .download-wrapper {\n        grid-template-columns: 1fr;\n    }\n}\n\n.download-app-image {\n    width: 100%;\n    height: 100%;\n    text-align: right;\n}\n@media (max-width: 700px) {\n    .download-app-image {\n        display: none;\n    }\n}\n\n.download-app-image > img {\n    max-width: 90%;\n    margin-right: 45px;\n}\n\n@media (max-width: 950px) {\n    .download-app-image {\n        height: 65%;\n        padding-top: 10%;\n        width: 100%;\n    }\n}\n\n@media (max-width: 500px) {\n    .download-app-image {\n        height: 50%;\n    }\n}\n\n@media (max-width: 700px) {\n    .download_btn {\n        width: 70%;\n    }\n    h2{\n        float: revent;\n        font-size: 12px;\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
