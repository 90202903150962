import React, {useEffect, useRef, useState} from "react";
import MainSpace from "../../Components/Containers/MainSpace";
import ContentContainer from "../../Components/Containers/ContentContainer";
import {useLocation} from "react-router-dom";
import OnboardingForm from "./Onboarding/onboardForm";
import PaymentsStepper from "../../Components/PaymentsStepper/PaymentsStepper";
import PaymentInfo from "./Onboarding/info";
import CreatePaymentsPassword from "./Onboarding/createPassword";
import {connect, useDispatch} from "react-redux";
import {getWeavrCorporate} from "../../redux/actions/weavrActions";
import {RootState} from "../../redux/reducers/rootReducer";
import PaymentEmailVerification from "./Onboarding/emailVerification";
import {
  getVirtualCardOnboardingNextStepEnum,
  getVirtualCardOnboardingPreviousStepEnum,
  VirtualCardOnboardingStep
} from "../../enums/weavrEnums";
import SmsVerification from "./Onboarding/SmsVerification";
import WeavrStepperLogin from "./Onboarding/WeavrStepperLogin";
import OnboardingKyb from "./Onboarding/kyb";
import OnboardingKyc from "./Onboarding/kyc";
import PaymentsOnboardingPendingView from "./Onboarding/PaymentsOnboardingPendingView/PaymentsOnboardingPendingView";
import {io} from "socket.io-client";
import environment from "../../environment";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import KYBEmailInfo from "./Onboarding/kybEmailInfo";

interface IProps {
  weavr?: any,
  payments?: any
}

function OnboardingPage({weavr}:IProps) {
  const dispatch = useDispatch();
  const location:any = useLocation();
  const socket:any = useRef();

  const [step, setStep] = useState<VirtualCardOnboardingStep>(VirtualCardOnboardingStep.INITIAL);
  const [savedStep, setSavedStep] = useState(0);

  useEffectOnce(() => {
    socket.current = io(environment.socketUrl);
    socket.current.on("onKYBStatusChange", (data: string) => {
      if (data === "success") {
        setStep(VirtualCardOnboardingStep.KYB_EMAIL_INFO);
      }
    });
    socket.current.on("onKYCStatusChange", (data: string) => {
      if (data === "success") {
        setStep(VirtualCardOnboardingStep.IN_REVIEW);
      }
    });
  });

  useEffect(() => {
    if (location && location.state && location.state.nextStep) {
      setStep(VirtualCardOnboardingStep.FIRST_LOGIN);
      /*setStep(4);*/
    }
  }, [location]);

  useEffect(() => {
    if (weavr && weavr.changelog) {
      if (weavr.currentStep === VirtualCardOnboardingStep.FIRST_LOGIN && location.search.includes("verify")) {
        setStep(VirtualCardOnboardingStep.SMS_VERIFICATION);
      } else {
        setStep(weavr.currentStep);
      }
      // eslint-disable-next-line max-len
      /*if (step === 0 || step === 6 || step === 3) {
        switch (weavr.changelog[weavr.changelog.length - 1].status) {
        case weavrStatusEnum.notCreated:
          setStep(0);
          break;
        case weavrStatusEnum.sendCreateRequest:
          setStep(2);
          break;
        case weavrStatusEnum.passwordCreated:
          setStep(3);
          break;
        case weavrStatusEnum.emailVerified:
          setStep(4);
          break;
        case weavrStatusEnum.loggedIn:
          setStep(5);
          break;
        case weavrStatusEnum.KYBStarted:
          setStep(6);
          break;
        case weavrStatusEnum.KYBPending:
          setStep(6);
          break;
        case weavrStatusEnum.KYBApproved:
          setStep(6);
          break;
        case weavrStatusEnum.KYBRejected:
          setStep(6);
          break;
        case weavrStatusEnum.KYCStarted:
          setStep(7);
          break;
        case weavrStatusEnum.KYCPending:
          setStep(8);
          break;
        }
      }*/
    }
  }, [weavr]);

  useEffect(() => {
    dispatch(getWeavrCorporate());
  }, [step]);

  const handleSlider = (condition: "next" | "prev" | "login") => {
    if (condition === "next") {
      console.log("Set step from slider: ", step);
      setStep(getVirtualCardOnboardingNextStepEnum(step));
      /*if (step === 4 || step === 6) {
        setStep(savedStep);
        setSavedStep(0);
      } else {
        setStep(step + 1);
      }*/
    } else if (condition === "prev") {
      setStep(getVirtualCardOnboardingPreviousStepEnum(step));
      /*setStep(step - 1);*/
    } else if (condition === "login") {
      setStep(VirtualCardOnboardingStep.FIRST_LOGIN);
      /*setSavedStep(step);
      setStep(4);*/
    }
  };

  /*const handleSlider = (condition:string) => {
    if (condition === "next") {
      if (step === 4 || step === 6) {
        setStep(savedStep);
        setSavedStep(0);
      } else {
        setStep(step + 1);
      }
    } else if (condition === "prev") {
      setStep(step - 1);
    } else if (condition === "login") {
      setSavedStep(step);
      setStep(4);
    }

  };*/

  const getCurrentComponent = () => {
    switch (step) {
    case VirtualCardOnboardingStep.INITIAL:
      return <PaymentInfo handleSlider={() => handleSlider("next")} />;
    case VirtualCardOnboardingStep.INFO_FORM:
      return <OnboardingForm handleSlider={() => handleSlider("next")} />;
    case VirtualCardOnboardingStep.PASSWORD_CREATION:
      return <CreatePaymentsPassword
        weavr={weavr}
        handleSlider={() => handleSlider("next")}
      />;
    case VirtualCardOnboardingStep.EMAIL_VERIFICATION:
      return <PaymentEmailVerification
        weavr={weavr}
        handleSlider={() => handleSlider("next")}
      />;
    case VirtualCardOnboardingStep.FIRST_LOGIN:
      return <WeavrStepperLogin
        data={weavr}
        handleSlider={() => handleSlider("next")}
        step={step}
      />;
    case VirtualCardOnboardingStep.SMS_VERIFICATION:
      return <SmsVerification
        weavr={weavr}
        handleSlider={(condition: "next" | "prev" | "login") => handleSlider(condition)}
      />;
    case VirtualCardOnboardingStep.KYB:
      return <OnboardingKyb
        data={weavr}
        handleSlider={(condition: "next" | "prev" | "login") => handleSlider(condition)}
      />;
    case VirtualCardOnboardingStep.KYB_EMAIL_INFO:
      return <KYBEmailInfo />;
    case VirtualCardOnboardingStep.KYC:
      return <OnboardingKyc
        data={weavr}
        handleSlider={() => handleSlider("next")}
      />;
    case VirtualCardOnboardingStep.IN_REVIEW:
      return <PaymentsOnboardingPendingView />;
    }
  };

  /*const getCurrentComponent = () => {
    switch (step) {
    case 0:
      return <PaymentInfo handleSlider={() => handleSlider("next")} />;
    case 1:
      return <OnboardingForm handleSlider={() => handleSlider("next")} />;
    case 2:
      return <CreatePaymentsPassword
        weavr={weavr}
        handleSlider={() => handleSlider("next")}
      />;
    case 3:
      return <PaymentEmailVerification
        weavr={weavr}
        handleSlider={() => handleSlider("next")}
      />;
    case 4:
      return <WeavrStepperLogin
        data={weavr}
        handleSlider={() => handleSlider("next")}
        step={step}
      />;
    case 5:
      return <SmsVerification
        weavr={weavr}
        handleSlider={(condition:string) => handleSlider(condition)}
      />;
    case 6:
      return <OnboardingKyb
        data={weavr}
        handleSlider={(condition:string) => handleSlider(condition)}
      />;
    case 7:
      return <OnboardingKyc
        data={weavr}
        handleSlider={() => handleSlider("next")}
      />;
    case 8:
      return <PaymentsOnboardingPendingView />;
    }
  };*/

  return (
    <ContentContainer>
      <MainSpace>
        <div className="row">
          <PaymentsStepper currentStep={step} />
        </div>
        {getCurrentComponent()}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    weavr: state.weavr.weavrCorporate,
    payments: state.payments
  };
};

export default connect(mapStateToProps, null)(OnboardingPage);
