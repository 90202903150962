/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import "./HeaderComponentWithUser.css";
import HeaderLogo from "../../img/logo@2x.png";

import userArrow from "./icon-down.png";
import {connect, useDispatch} from "react-redux";
import {handleSidebar} from "../../redux/actions/sidebarActions";
import {RootState} from "../../redux/reducers/rootReducer";
import userAvatar from "./user_avatar.svg";
import {takeNotificationData} from "../../redux/actions/notificationDataActions";
import {prepareImage} from "../../redux/actions/prepareImage";
import {useTranslation} from "react-i18next";
import {logoutDispatcher} from "../../redux/actions/authActions";
import {
  getUserRoles,
  removeUserPermission,
  removeUserPermissionType,
  removeUserRoles
} from "../../utils/cookies/userRolesCookies";
import {getToken, removeToken, setToken} from "../../utils/cookies/tokensCookie";
import SelectWithSearch from "../SimpleComponents/SelectWithSearch";
import {isAllowed, userRoles} from "../../utils/permissionsAllow";
import NotificationIcon from "./bell-regular.svg";
import {clearCompany, getCompanies} from "../../redux/actions/companiesActions";
import UserAvatar from "../Containers/UserAvatar";
import { readNotifications } from "../../redux/actions/notifications";
import {timeSince} from "../../utils/timeSince";
import {capitalize} from "../../utils/capitalizeFirstLetter";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import questionIcon from "../../img/components/header/question.png";
import Modal from "react-modal";

import {
  getUserCompany, getUserId,
  removeUserCompany,
  removeUserId,
  setUserCompany
} from "../../utils/cookies/userCookies/userCookies";
import { CLEAR_COMPANY } from "redux/types/companiesTypes";
import { clearHrZoneAuth } from "redux/actions/hrZoneAuthActions";
import { clearUserInfo } from "redux/actions/usersActions";
import {authService} from "../../api/services/Auth/auth.service";
import {modalStyles} from "../../utils/modalStyles";
import ContactSupportModal from "../Modals/ContactSupportModal/ContactSupportModal";


interface CustomProps {
  notification?: boolean,
  withSidebar?: boolean,
  withSearch?: boolean,
  notifications?: any,
  handleSidebar: (sidebarOpen: boolean) => void,
  sidebar?: boolean,
  companies?: any,
  company?: any,
  userInfo?: any
}


function HeaderWithUser (
  {
    notifications,
    withSidebar,
    handleSidebar,
    sidebar,
    companies,
    company,
    userInfo
  }:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Take notifications data
  useEffect(() => {
    if (company && Object.keys(company).length
      && isAllowed({data: {
        roles: [userRoles.superAdmin, userRoles.admin]
      }})
      && company.notifications
    ) {
      dispatch(takeNotificationData({
        limit: 8,
        skip: 0,
        newNotificationsLimit: 8,
        newNotificationsSkip: 0
      }));
    }
  }, [company]);

  const userData = JSON.parse(localStorage.getItem("user") || "null");
  const [menuOpen, setMenuOpen] = useState(false);
  const [supportMenuOpen, setSupportMenuOpen] = useState(false);
  const [showContactSupportModal, setShowContactSupportModal] = useState(false);
  const openSearchBox = false;

  // Detect click outside for close menu
  const headerMenuRef = useRef<HTMLDivElement>(null);
  const supportMenuRef = useRef<HTMLDivElement>(null);
  const companySelect = useRef<HTMLDivElement>(null);
  const notificationsMenu = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)
        && !companySelect.current?.contains(event.target as Node)) {
        setMenuOpen(false);
      }
      if (!supportMenuRef.current?.contains(event.target as Node)) {
        setSupportMenuOpen(false);
      }
      if (!notificationsMenu.current?.contains(event.target as Node)) {
        closeNotificationsMenuAndRead();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleBurger = () => {
    dispatch(handleSidebar(!sidebar));
  };

  const handleLogout = () => {
    dispatch(clearCompany());
    dispatch(clearHrZoneAuth());
    dispatch(clearUserInfo());
    dispatch(logoutDispatcher());
    removeToken();
    removeToken(true);
    removeUserPermission();
    removeUserPermissionType();
    removeUserRoles();
    removeUserId();
    removeUserCompany();
    navigate("/login");
  };

  // Image sign handler
  const [currentImage, setCurrentImage] = useState("");
  useEffect(() => {
    async function fetchData() {
      if (userInfo && userInfo.imageUrl && userInfo.imageUrl !== "undefined") {
        try {
          const response = await prepareImage(userInfo.imageUrl);
          setCurrentImage(response);
        } catch (e: any) {
          setCurrentImage("/images/user_avatar.svg");
        }
      } else {
        setCurrentImage("/images/user_avatar.svg");
      }
    }
    fetchData();
  }, [userInfo]);

  // Companies state for select
  const [companiesState, setCompaniesState] = useState([]);

  // Get companies for company selector
  useEffect(() => {
    if (getUserRoles() === "5") {
      dispatch(getCompanies());
    }
  }, []);

  const [chosenCompany, setChosenCompany] = useState({});

  // Set companies select options for Super Admin
  useEffect(() => {
    if (getUserRoles() === "5") {
      const changedArray:any = [];
      if (companies.companies && companies.companies.length) {
        companies.companies.forEach((companyItem:any) => {
          if (companyItem._id === getUserCompany()) {
            setChosenCompany({
              label: companyItem.companyInfo.companyName,
              value: companyItem._id
            }
            );
          }
          changedArray.push({
            label: companyItem.companyInfo.companyName,
            value: companyItem._id
          });
        });
        setCompaniesState(changedArray);
      }
    }
  }, [companies.companies]);

  // Handle company select
  const handleCompanySelect = async (item: any) => {
    //Save company id to project constant
    setMenuOpen(false);
    setUserCompany(item.value);
    const response = await new authService().changeCompanyAdmin({companyId: item.value});
    if (response.status && response.data) {
      await setToken(response.data.token);
    }
    window.location.reload();
  };

  const [showNotifications, setShowNotifications] = useState(false);

  const closeNotificationsMenuAndRead = () => {
    if (showNotifications) {
      setShowNotifications(false);
      const notificationIds: string[] = [];
      if (notifications) {
        notifications.notificationData.newData.forEach((notification: any) => {
          notificationIds.push(notification._id);
        });
        readNotifications(notificationIds);
      }
    }
  };

  const closeNotificationMenuAndNavigate = (path:string) => {
    setShowNotifications(false);
    navigate(path);
  };

  const [associatedCompanies, setAssociatedCompanies] = useState(null);

  const getAssociatedCompanies = async () => {
    const token = await getToken();
    await axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.roles.associatedCompaniesForUser}`,
      {
        headers: {Authorization: `Bearer ${token}`}
      })
      .then((res:any) => {
        if (res.data.companies) {
          const changedArray:any = [];
          res.data.companies.forEach((companyItem:any) => {
            changedArray.push({
              label: companyItem.companyInfo.companyName,
              value: companyItem._id
            });
            if (companyItem._id === getUserCompany()) {
              setChosenCompany({
                label: companyItem.companyInfo.companyName,
                value: companyItem._id
              }
              );
            }
          });
          setAssociatedCompanies(changedArray);
        }
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // Get associated companies
  useEffect(() => {
    if (userInfo && userInfo.roleId) {
      getAssociatedCompanies();
    }
  }, [userInfo]);

  return (
    <header className="header">

      <Modal
        style={modalStyles}
        isOpen={showContactSupportModal}
      >
        <ContactSupportModal
          onClose={() => setShowContactSupportModal(false)}
        />
      </Modal>

      <div className="row">
        <div className="row" style={{paddingLeft:"2vw"}}>
          <a href='/'>
            <img
              className="header_logo"
              src={HeaderLogo} alt="Corplife-Logo"
              width="150px"
            />
          </a>
          {withSidebar && (
            <div
              className="burger_menu"
              onClick={handleBurger}
            >
              <div
                className={sidebar ? "burger_line" : "burger_line_close"}
              />
              <div
                className={sidebar ? "burger_line" : "burger_line_close"}
              />
              <div
                className={sidebar ? "burger_line" : "burger_line_close"}
              />
            </div>
          )}
        </div>
      </div>
      {!openSearchBox && (
        <div
          className="user-container-header space-between"
        >
          {company && company.notifications && isAllowed({data: {
            roles: [userRoles.superAdmin, userRoles.admin]
          }})
            ?
            <div
              className="header_notification"
            >
              <img
                src={NotificationIcon}
                alt="notification"
                onClick={() => {
                  if (notifications.notificationData
                    && notifications.notificationData.newNotification > 0) {
                    setShowNotifications(true);
                  } else {
                    navigate("/notification-center");
                  }
                }}
              />
              {notifications.notificationData
              && notifications.notificationData.newNotification > 0
                ? <div className="notification_dot">
                  {notifications.notificationData.newNotification}
                </div>
                : null
              }
              {showNotifications ?
                notifications.notificationData
                && notifications.notificationData.newNotification > 0
                  ? <div className="notification_menu" ref={notificationsMenu}>
                    {notifications.notificationData.newData.map((notification: any) => (
                      <div key={notification._id} className="header_update_item"
                        onClick={() => closeNotificationMenuAndNavigate(
                          `overview/${notification.info.id}`)}
                      >
                        <UserAvatar imageUrl={notification.createdBy.image} small/>
                        <p>{t("components.header.notifications.notification_text",
                          {
                            name: notification.createdBy.name,
                            time: timeSince(new Date(notification.createdAt))
                          })}</p>
                      </div>
                    ))}
                    <div
                      className="notification_menu_view_all"
                      onClick={() => closeNotificationMenuAndNavigate("/notification-center")}
                    >
                      {t("components.header.notifications.view_all")}
                    </div>
                  </div>
                  : null
                : null
              }
            </div>
            : null
          }

          {/*Support menu*/}

          <div ref={supportMenuRef}>
            <img
              src={questionIcon}
              className="header-support-menu-item"
              onClick={() => {
                setSupportMenuOpen(!supportMenuOpen);
              }}
            />
            <div className={supportMenuOpen
              ? "support-menu-header"
              : "hide-menu"}>
              <div
                onClick={() => {
                  setSupportMenuOpen(false);
                  setShowContactSupportModal(true);
                }}
                style={{
                  textDecoration: "none",
                  color: "black"
                }}
                className="header-user-menu-item row"
              >
                <p>{t("components.header.support.contact")}</p>
              </div>

              <div
                onClick={() => {
                  setSupportMenuOpen(false);
                  window.open(
                    "https://www.corplife.at/Datenschutz/",
                    "_blank"
                  );
                }}
                style={{
                  textDecoration: "none",
                  color: "black"
                }}
                className="header-user-menu-item row"
              >
                <p>{t("components.header.support.privacy")}</p>
              </div>

              <div
                onClick={() => {
                  setSupportMenuOpen(false);
                  window.open(
                    "https://portal-stag.corplife.at/imprint",
                    "_blank"
                  );
                }}
                style={{
                  textDecoration: "none",
                  color: "black"
                }}
                className="header-user-menu-item row"
              >
                <p>{t("components.header.support.imprint")}</p>
              </div>

            </div>
          </div>




          <div
            ref={headerMenuRef}
          >
            <div className="space-between" onClick={() => setMenuOpen(!menuOpen)}>
              <div
                className="user-image-header"
                style={currentImage
                  ? {backgroundImage: `url(${currentImage})`}
                  : {backgroundImage: `url(${userAvatar})`}
                }>
              </div>
              <p
                className="user-name-header">
                {userInfo && userInfo.firstName
                  ? `${capitalize(userInfo.firstName)} ${capitalize(userInfo.lastName)}`
                  : userInfo.userEmail ? userInfo.userEmail : (userInfo.email ?? "")}
              </p>
              <img
                src={userArrow}
                className={menuOpen
                  ? "user-arrow-hide"
                  : "user-arrow-show"}
                alt="arrow" width="20px"/>
            </div>
            <div className={menuOpen
              ? "user-menu-header"
              : "hide-menu"}>
              {isAllowed({data: {
                roles: [userRoles.superAdmin, userRoles.admin, userRoles.user]
              }})
                ?
                <div
                  onClick={() => {
                    setMenuOpen(false);
                    navigate(`/general-info/${getUserId()}`);
                  }}
                  style={{
                    textDecoration: "none",
                    color: "black"
                  }}
                  className="header-user-menu-item row"
                >
                  <p>{t("components.header.menu_1")}</p>
                </div>

                : null
              }

              {/*General Info*/}
              {isAllowed({data: {
                roles: [userRoles.superAdmin, userRoles.admin]
              }}) &&
                <div
                  className="header-user-menu-item row"
                  onClick={() => {
                    setMenuOpen(false);
                    navigate("/general-info");
                  }}
                >
                  <p>{t("components.header.menu_account")}</p>
                </div>
              }

              {/*Roles and permission*/}
              {isAllowed({data: {
                roles: [userRoles.superAdmin, userRoles.admin]
              }}) &&
                <div
                  className="header-user-menu-item row"
                  onClick={() => {
                    setMenuOpen(false);
                    navigate("/userSettings");
                  }}
                >
                  <p>{t("components.header.menu_user")}</p>
                </div>
              }

              <div
                className="header-user-menu-item row"
                onClick={handleLogout}
              >
                <p>{t("components.header.menu_2")}{userData
                  ? userData.name : ""}</p>
              </div>
              {isAllowed({data: {roles: [userRoles.superAdmin]}})
                ?
                <div
                  ref={companySelect}
                  onClick={() => {
                    setMenuOpen(false);
                    navigate("/general-info?add");
                  }}
                  style={{
                    textDecoration: "none",
                    color: "black"
                  }}
                  className="header-user-menu-item row"
                >
                  <p>{t("components.header.admin_add_company")}</p>
                </div>
                : null
              }

              {isAllowed({data: {roles: [userRoles.superAdmin]}})
                ? <SelectWithSearch
                  value={chosenCompany}
                  data={companiesState}
                  handleSelect={handleCompanySelect}
                />
                : null
              }

              {associatedCompanies
                ? <SelectWithSearch
                  value={chosenCompany}
                  data={associatedCompanies}
                  handleSelect={handleCompanySelect}
                />
                : null
              }

              {/*<div className="header-user-menu-item row">
								<p>{t("components.header.menu_3")}</p>
							</div>*/}
            </div>
          </div>
        </div>
      )}
    </header>
  );
}



const mapStateToProps = (state:RootState) => {
  return {
    sidebar: state.sidebar.sidebarCon,
    userInfo: state.users.userInfo.user,
    notifications: state.notificationData,
    companies: state.company.companies,
    company: state.company.company.company,
  };
};

const mapDispatchToProps = {
  handleSidebar,
  logoutDispatcher
};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWithUser);

