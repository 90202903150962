import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import { components } from "react-select";
import Checkbox from "./Checkbox";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#E3E5F1",
    minHeight: "42px",
    height: "42px",
    minWidth: "175px",
    boxShadow: null
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "42px",
    fontSize: "12px"
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, stat) => ({
    ...provided,
    height: "42px"
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#0E0A40",
    };
  }
};

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <div className="row">
          <Checkbox
            id={props.value}
            checked={props.isSelected}
            handleCheckboxChange={() => null}
          />{" "}
          <label style={{fontSize: "12px"}}>{props.label} </label>
        </div>
      </components.Option>
    </div>
  );
};


const animatedComponents = makeAnimated();
export default class SingleSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      optionSelected: this.props.defaultValue || null
    };
  }

	handleChange = selected => {
	  this.setState({
	    optionSelected: selected
	  });
	  this.props.handleAddChoice(selected);
	  /*this.props.selectedOptions(selected)*/
	}

	render() {
	  return (
	    <span
	      className="d-inline-block"
	      data-toggle="popover"
	      data-trigger="focus"
	      data-content="Please select account(s)"
	    >
	      <MySelect
	        placeholder={this.props.placeholder || "Select..."}
	        styles={customStyles}
	        options={this.props.options}
	        isMulti={this.props.isMulti !== "no"}
	        closeMenuOnSelect={false}
	        components={{
	          Option,
	          animatedComponents
	        }}
	        onBlur={this.props.onBlur}
	        onChange={this.handleChange}
	        value={this.state.optionSelected}
	      />
	    </span>
	  );
	}
}
