import React, {useEffect, useState} from "react";
import "./CardDetailTopSection.css";
import {useTranslation} from "react-i18next";
import {Bar, Line, Pie} from "react-chartjs-2";
import {getToken} from "../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../environment";
import {urls} from "../../../../../api/urls";
import Lottie from "react-lottie";
import emptyStateImg from "../../../../../img/Dashboard/Orders/empty_table_img.svg";

export default function DailySpendTopSectionCard() {
  const { t } = useTranslation();

  const [dailyLabels, setDailyLabels] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dailyChartData, setDailyChartData] = useState({
    total: [],
    restaurant: [],
    supermarket: [],
    unknown: []
  });
  const [showEmptyState, setShowEmptyState] = useState(false);

  const getDailyStatistic = async () => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.cardsDailyStatistic}/${location.pathname.split("/")[3]}?token=${weavrToken}`,
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    if (response && response.data && response.data.data) {
      const labels:any = [];
      const data:any = {
        total: [],
        restaurant: [],
        supermarket: [],
        unknown: []
      };
      response.data.data.map((dataEntry:any) => {
        const day = dataEntry.day;
        labels.push(day);
        Object.keys(dataEntry).map((key:string) => {
          if (key !== "day") {
            data[key].push(+dataEntry[key].toFixed(2));
          }
        });
      });
      setDailyLabels(labels);
      setDailyChartData(data);
      // Check if data is presented, if not show empty statement
      if (data.total[data.total.length - 1] === 0) {
        setShowEmptyState(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getDailyStatistic();
  }, []);

  const data = {
    labels: ["Restaurant", "Supermarket"],
    datasets: [
      {
        label: "Daily spend",
        data: [
          dailyChartData.restaurant[dailyChartData.restaurant.length - 1],
          dailyChartData.supermarket[dailyChartData.supermarket.length - 1]
        ],
        backgroundColor: [
          "#EC6666",
          "#147AD6"
        ],
        borderColor: [
          "#EC6666",
          "#147AD6"
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    legend: {
      display: false,
    }
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: require("img/animations/chart_loading.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return(
    <div className="card_detail_top_section_container">

      <p className="card_detail_top_section_container_title">
        {t("dashboard.payments.card_detail.cards.daily.title")}
      </p>

      <div style={{
        marginTop: "30px",
        height: "200px"
      }}>
        {loading
          ? <Lottie options={lottieOptions}/>
          : showEmptyState
            ? <div className="center">
              <img src={emptyStateImg} alt="empty_table"/>
            </div>
            : <div className={"daily_chart_top_section_container_chart"}>
              <Pie data={data} options={options} />
            </div>
        }
      </div>

      <div style={{width: "100%", height: "1px", backgroundColor: "#D8D8D8", marginTop: "7px", marginBottom: "7px"}} />

      <div className="daily_chart_top_section_container">
        <p className="card_detail_top_section_container_label small" style={{fontSize: "14px"}}>
          {t("dashboard.payments.card_detail.cards.monthly.restaurant")}
        </p>
        <p className="card_detail_top_section_container_label supermarket small" style={{fontSize: "14px"}}>
          {t("dashboard.payments.card_detail.cards.monthly.supermarket")}
        </p>
      </div>

    </div>
  );
}
