import "./ContactSupportModal.css";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import FormikInput from "../../SimpleComponents/FormikInput";
import Button from "../../Buttons/Button";
import {supportService} from "../../../api/services/Support/support.service";
import {toast} from "react-toastify";
import CustomToastContainer from "../../SimpleComponents/ToastContainer";

interface IContactSupportModal {
  onClose: () => void;
}

export default function ContactSupportModal(props: IContactSupportModal) {
  const { t } = useTranslation();
  const supportApiService = new supportService();
  const maxAllowedCharacters = 500;

  const [buttonLoading, setButtonLoading] = useState(false);

  const formik = useFormik({
    initialValues: {
      text: ""
    },
    validationSchema: Yup.object({
      text: Yup.string().max(maxAllowedCharacters, t("formik_warnings.max", {count: maxAllowedCharacters}))
    }),
    // handle form submitting
    onSubmit: async (event: any, ev: any) => {
      //
    },
  });

  const handleSubmit = async () => {
    setButtonLoading(true);
    const response = await supportApiService.createSupportMessage({message: formik.values.text});
    if (response.status) {
      toast.success(
        <CustomToastContainer message={t("components.modals.contact_support.toasts.success")} status={1} />,
        {
          autoClose: 5000
        }
      );
    } else {
      toast.error(
        <CustomToastContainer message={t("components.modals.contact_support.toasts.error")} status={3} />,
        {
          autoClose: 5000
        }
      );
    }
    setButtonLoading(false);
    props.onClose();
  };

  return(
    <div className="modal_container">
      <img
        onClick={props.onClose}
        className="close_popup"
        src={closeIcon}
        alt="close"
      />
      <h2>{t("components.modals.contact_support.title")}</h2>

      <FormikInput
        htmlFor={"text"}
        name={"text"}
        value={formik.values.text}
        disabled={false}
        handleChange={formik.handleChange}
        label={t("components.modals.contact_support.label")}
        type={"textarea"}
        inputStyle={{
          maxWidth: "none"
        }}
        errors={formik.errors.text}
        touched={formik.touched.text}
        onBlur={formik.handleBlur}
        maxLength={maxAllowedCharacters}
      />

      <p className={"character-counter"}>
        {t("components.modals.reject_invoice.counter", {count: maxAllowedCharacters - formik.values.text.length})}
      </p>

      <div
        className={"space-between"}
        style={{
          marginTop: "30px",
          gap: "20px"
        }}
      >
        <Button
          buttonType={"primary"}
          buttonLabel={t("components.modals.contact_support.btn")}
          buttonHandler={() => handleSubmit()}
          buttonHtmlType={"submit"}
          buttonStyles={{
            maxWidth: "none"
          }}
          loading={buttonLoading}
          disabled={!(formik.isValid && formik.dirty)}
        />
      </div>

    </div>
  );
}
