import React from "react";
import "./styles.css";
import { useTranslation } from "react-i18next";
import appleStore from "img/Dashboard/app-store-icon.png";
import googlePlayImg from "img/Dashboard/google-play-icon.png";
import {appStoreLink, googlePlayLink} from "../../contstants";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="dashboard_footer">
      <div className="dashboard_footer_top_container">
        <div className="dashboard_footer_top_box">
          <h3>{t("components.footer.top.contact")}</h3>
          <a href="mailto:service-lunch@corplife.at">
            service-lunch@corplife.at
          </a>
          <a href="tel:+43 676 633 9008">+43 676 633 9008</a>
        </div>
        <div>
          <h3>{t("components.footer.top.apps")}</h3>
          <div className="row">
            <a href={appStoreLink} target="_blank" rel="noreferrer">
              <img
                src={appleStore}
                alt="appStore"
                className="footer_download_btn"
              />
            </a>
            <a href={googlePlayLink} target="_blank" rel="noreferrer">
              <img
                src={googlePlayImg}
                alt="googlePlay"
                className="footer_download_btn"
              />
            </a>
          </div>
        </div>
      </div>
      <p className="dashboard_footer_bottom_text">
        {t("components.footer.bottom.text")}
      </p>
      <div className="dashboard_footer_bottom_box">
        <p>{t("components.footer.bottom.stamp")}</p>
        <div className="row">
          <a href="/agb">{t("components.footer.bottom.agb")}</a>
          <a href="/agb-cards">
            {t("components.footer.bottom.terms_corporates")}
          </a>
          {/*<a href="/about-cards">
            {t("components.footer.bottom.terms_consumers")}
          </a>*/}
          <a href="/privacy-policy">{t("components.footer.bottom.privacy")}</a>
          <a href="imprint">{t("components.footer.bottom.imprint")}</a>
        </div>
      </div>
    </footer>
  );
}
