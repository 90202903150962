import React from "react";
import "./successContainer.css";
import successImage from "img/success_image.svg";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";

interface CustomProps {
	type: number
	id?: string,
	update: string
}

function SuccessContainer({id, type}:CustomProps) {
  const { t } = useTranslation();

  const linkRoute = () => {
    switch (type) {
    case 0:
      return "werbepartner";
    case 1:
      return "interne-benefits";
    }
  };

  return (
    <div className="center">
      <div className="success_container">
        <img src={successImage} alt="success"/>
        <h2>{t("partner_portal.deals.create.success_screen.title")}</h2>
        <p>{t("partner_portal.deals.create.success_screen.text")}</p>
        {type === 0
          ? <p>{t("partner_portal.deals.create.success_screen.text_2")}</p>
          : type === 1
            ?  <p>{t("partner_portal.deals.create.success_screen.text_2_intern")}</p>
            :  <p>{t("partner_portal.deals.create.success_screen.text_2_corporate")}</p>
        }
      </div>
    </div>
  );
}


const mapStateToProps = (state: RootState) => {
  return {
    id: state.steps.id
  };
};

export default connect(mapStateToProps, null)(SuccessContainer);
