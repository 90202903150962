import React, { useEffect, useState } from "react";
import EmployeeList from "../../Components/Lists/EmployeeListFull";
import ContentContainer from "Components/Containers/ContentContainer";
import ButtonWithIcon from "Components/Buttons/ButtonWithIcon";
import SearchBarLight from "../../Components/SimpleComponents/SearchBarLight";
import EmployeeSelectedMenu from "Components/SelectedMenu/EmployeeSelectedMenu";
import VerticalDivider from "../../Components/Containers/VerticalDivider";
import MainSpace from "Components/Containers/MainSpace";
import Spacer from "Components/SimpleComponents/Spacer";
import PageTitle from "Components/SimpleComponents/PageTitle";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import { handleSidebar } from "../../redux/actions/sidebarActions";
import Modal from "react-modal";
import ModalEmployeeUpdate from "../../Components/SimpleComponents/ModalEmployeeUpdate";
import ModalEmployeeAdd from "../../Components/SimpleComponents/AddEmployeeModal";
import { ToastContainer } from "react-toastify";
import useWindowDimensions from "../../utils/useWindowSize";
import SubmitActionModal from "../../Components/Modals/SubmitActionModal";
import uploadImg from "../../img/Dashboard/icon-upload_2.svg";
import UploadCsvModal from "../../Components/Modals/UploadCsvModal";
import {
  isAllowed,
  userPermissions,
  userRoles
} from "../../utils/permissionsAllow";
import { useTranslation } from "react-i18next";
import {
  deleteOneUser,
  deleteUsers,
  getUsers,
  restoreUsers,
  showUserLoading
} from "../../redux/actions/usersActions";
import TableLoading from "../../Components/LoadingPlaceholders/TableLoading";
import { modalStyles } from "../../utils/modalStyles";
import queryString from "query-string";
import SelectWithChoices from "../../Components/SimpleComponents/SelectWithChoices";
import FilteringBlock from "../../Components/Blocks/FilteringBlock";
import MultipleUserInvitationModal from "Components/Modals/EmployeePage/MultipleUserInvitationModal/MultipleUserInvitationModal";

interface CustomProps {
  employee?: any;
  sidebar?: any;
  checkedBoxes?: any;
  departments?: any;
  loading?: any;
  selectedRows?: any;
}

function Employee({
  employee,
  loading,
  departments,
  selectedRows,
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // show modal after click in actions
  const [showModal, setShowModal] = useState(false);
  const [invitationModalData, setInvitationModalData] = useState<any[]>([]);
  const [filtersState, setFiltersState] = useState<any>({
    search: undefined,
    isActive: undefined
  });

  useEffect(() => {
    dispatch(showUserLoading());
    dispatch(
      getUsers({
        limit: 8,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8
      })
    );
  }, []);

  const parsedSearch = queryString.parse(location.search);

  // data of selected user in actions
  const [selectedUserData, setSelectedUserData] = useState({});

  const [showCsvModal, setShowCsvModal] = useState(false);

  // close modal window from modal
  const handleModalWindow = (state: boolean, data: any) => {
    setShowModal(state);
    setSelectedUserData(data);
  };

  // show add new user modal
  const [newUserModalState, setNewUserModalState] = useState(false);
  const addNewUserButtonHandler = () => {
    setNewUserModalState(true);
  };
  const [submitModal, setSubmitModal] = useState(false);
  const [deleteUsersArr, setDeleteUsersArray] = useState<any>([]);

  //Handle submit delete users modal
  const openSubmitModal = (
    users: string[],
    state: { showWarning: boolean; type: string }
  ) => {
    if (state.type === "delete") {
      if (state && state.showWarning) {
        setSubmitModal(true);
        setDeleteUsersArray(users);
      } else {
        setDeleteUsersArray(users);
        submitUsersDelete(users);
      }
    } else if (state.type === "restore") {
      setDeleteUsersArray(users);
      submitUsersRestore(users);
    }
  };

  const submitUsersRestore = (users?: any) => {
    setClearSelections(true);
    setSubmitModal(false);
    const restoreUsersData: any = {
      users: users ? users : deleteUsersArr
    };
    dispatch(restoreUsers(restoreUsersData));
    dispatch(showUserLoading());
    dispatch(
      getUsers({
        limit: 8,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8
      })
    );
  };

  const submitUsersDelete = (passedUsers?: any) => {
    setClearSelections(true);
    setSubmitModal(false);
    if (deleteUsersArr.length === 1 || passedUsers?.length === 1) {
      dispatch(deleteOneUser(passedUsers ? passedUsers[0] : deleteUsersArr[0]));
    } else if (deleteUsersArr.length > 1 || passedUsers?.length > 1) {
      const deleteUsersData: any = {
        users: passedUsers ? passedUsers : deleteUsersArr
      };
      dispatch(deleteUsers(deleteUsersData));
    }
    dispatch(
      getUsers({
        limit: 8,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
        search: searchValue
      })
    );
  };

  const [searchValue, setSearchValue] = useState("");

  // Close CSV upload modal
  const closeCsvModal = () => {
    setShowCsvModal(false);
  };

  const handleSearch = (value: string) => {
    setSearchValue(value);
    dispatch(showUserLoading());
    setFiltersState({...filtersState, search: value});
    dispatch(
      getUsers({
        limit: 8,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
        search: value
      })
    );
  };

  // set less item in table for small screens
  const [headerRatio, setHeaderRatio] = useState("40% 60%");
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 850) {
      setHeaderRatio("1fr");
    }
    if (width < 1000) {
      dispatch(handleSidebar(false));
    } else {
      setHeaderRatio("40% 60%");
    }
  }, [width]);

  const handleActiveFilter = (state: any) => {
    let isActive: any;
    switch (state.value) {
    case "true":
      isActive = 1;
      break;
    case "false":
      isActive = 0;
      break;
    }
    setFiltersState({...filtersState, isActive: isActive});
    dispatch(
      getUsers({
        limit: 8,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
        isActive: isActive
      })
    );
  };

  const [clearSelections, setClearSelections] = useState<boolean>(false);

  return (
    <ContentContainer>
      <ToastContainer style={{ zIndex: 99999 }} />
      <Modal style={modalStyles} isOpen={showModal}>
        <ModalEmployeeUpdate
          selectedUserData={selectedUserData}
          closeModal={handleModalWindow}
        />
      </Modal>
      <Modal style={modalStyles} isOpen={newUserModalState}>
        <ModalEmployeeAdd
          departments={departments}
          closeModal={() => setNewUserModalState(false)}
        />
      </Modal>

      <Modal style={modalStyles} isOpen={showCsvModal}>
        <UploadCsvModal closeModal={closeCsvModal} />
      </Modal>

      <Modal style={modalStyles} isOpen={submitModal}>
        <SubmitActionModal
          handleSubmit={submitUsersDelete}
          type="employee_delete"
          data={deleteUsersArr}
          closeModal={() => setSubmitModal(false)}
        />
      </Modal>

      <Modal style={modalStyles} isOpen={invitationModalData.length > 0}>
        <MultipleUserInvitationModal
          users={invitationModalData}
          closeModal={() => setInvitationModalData([])}
        />
      </Modal>

      <MainSpace>
        <VerticalDivider ratio={headerRatio}>
          <PageTitle mainTitle={t("dashboard.employee.banner_title")} />
          <div className="text-right justify-right">
            {isAllowed({
              data: {
                roles: [
                  userRoles.superAdmin,
                  userRoles.admin,
                  userRoles.custom
                ],
                permissions: {
                  isAdmin: true,
                  permissionsArray: [userPermissions.users]
                }
              }
            }) ? (
                <div className="flex-end">
                  <div
                    style={{ position: "relative", marginRight: "1rem" }}
                    className="btn icon primary"
                    onClick={() => setShowCsvModal(true)}
                  >
                    <div className="row">
                      <img
                        src={uploadImg}
                        className="btn_icon"
                        alt="upload"
                        style={{ marginTop: "0px" }}
                      />
                      <p>CSV</p>
                    </div>
                  </div>
                  <ButtonWithIcon
                    paddingTop="10px"
                    buttonType="primary"
                    buttonIcon="add-person"
                    buttonHandler={addNewUserButtonHandler}
                  />
                </div>
              ) : null}

            <Spacer spacerHeight="22px" />

            <div className="employee_header_row">
              {selectedRows.length > 0 &&
              isAllowed({
                data: {
                  roles: [
                    userRoles.superAdmin,
                    userRoles.admin,
                    userRoles.custom
                  ],
                  permissions: {
                    isAdmin: true,
                    permissionsArray: [userPermissions.users]
                  }
                }
              }) ? (
                  <EmployeeSelectedMenu
                    openSubmitModal={openSubmitModal}
                    data={selectedRows}
                    users={employee.users}
                    openInvitationModal={(data: any[]) =>
                      setInvitationModalData(data)
                    }
                  />
                ) : null}

              <SearchBarLight
                searchPlaceholder={t("dashboard.employee.search_placeholder_1")}
                handleSearchBar={handleSearch}
              />
            </div>
          </div>
        </VerticalDivider>

        <div>
          <FilteringBlock title={t("dashboard.plans.filters.title")}>
            <>
              <div>
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("dashboard.employee.filter.active.title")}
                </p>
                <SelectWithChoices
                  defaultValue={""}
                  name={t("dashboard.overview.filter_opt_3_place")}
                  selectPlaceHolder={t("dashboard.overview.filter_opt_3_place")}
                  selectId="status"
                  isMulti={"no"}
                  options={[
                    {
                      value: "all",
                      label: t("dashboard.employee.filter.active.all")
                    },
                    {
                      value: "true",
                      label: t("dashboard.employee.filter.active.active")
                    },
                    {
                      value: "false",
                      label: t("dashboard.employee.filter.active.not_active")
                    }
                  ]}
                  handleAddChoice={(options) => handleActiveFilter(options)}
                />
              </div>
            </>
          </FilteringBlock>
        </div>

        {!(employee && departments) ? (
          <TableLoading />
        ) : (
          <EmployeeList
            clearSelections={clearSelections}
            updateClearSelections={(val: boolean) => setClearSelections(val)}
            search={searchValue}
            submitUsersDelete={openSubmitModal}
            searchValue={searchValue}
            departments={departments}
            openModal={handleModalWindow}
            employee={employee}
            setNewUserModalState={(state: boolean) =>
              setNewUserModalState(state)
            }
            filtersState={filtersState}
          />
        )}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    employee: state.users.users,
    loading: state.users.loading,
    sidebar: state.sidebar.sidebarTabCondition,
    checkedBoxes: state.employeeCheckboxesState.selectedCheckboxes,
    departments: state.depsAndBranches.departments,
    selectedRows: state.selectedRows.selectedRows
  };
};

export default connect(mapStateToProps, null)(Employee);
