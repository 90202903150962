import { lazy } from "react";

// AUTHENTICATION ROUTES
export const ChangePassword = lazy(
  () => import("./pages/authentication/change_password/page")
);
export const LoginPage = lazy(() => import("./pages/authentication/login/page"));

// GENERAL ROUTES
export const ForgotPassword = lazy(
  () => import("pages/authentication/forgot_password/page")
);
    
// ERROR ROUTES
export const NotFoundPage = lazy(() => import("./pages/error_pages/NotFoundPage"));

export const ServerErrorPage = lazy(() => import("./pages/error_pages/ServerErrorPage"));


export default {};
