import { Route, Routes } from "react-router-dom";
import React from "react";
import ProfilePage from "./page";

import HrZoneCompanies from "./companies";
import HrZoneEmployees from "./hremployees";

export default function HrGeneralInfoRouter() {
  return (
    <Routes test-id="hrzone_generalInfo_router">
      {/* <Route path="/" element={<Redirect to="/dashboard" />} /> */}
      <Route path="/" element={<ProfilePage />} />
      <Route path="/hremployee" element={<HrZoneEmployees />} />
      <Route path="/companies" element={<HrZoneCompanies />} />
    </Routes>
  );
}
