import React, { useEffect, useState } from "react";
import Spacer from "../SimpleComponents/Spacer";
import SelectWithChoices from "../SimpleComponents/SelectWithChoices";
import "../SimpleComponents/Modal.css";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import SearchBarLight from "../SimpleComponents/SearchBarLight";
import EmployeePlansList from "../Lists/EmployeePLansList";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import Button from "../Buttons/Button";
import ListCounterFeedback from "../SimpleComponents/ListCounterFeedback";
import { changeSelectedRows } from "../../redux/actions/selectedUsersFromListActions";
import { handleEmployeeCheckBox } from "../../redux/actions/employeePageCheckboxActions";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import {
  getBranches,
  getDepartments,
  getDepartmentsAndBranches
} from "../../redux/actions/branchAndDepartmentActions";
import { getUsers, showUserLoading } from "../../redux/actions/usersActions";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import axios from "axios";
import environment from "../../environment";
import { urls } from "../../api/urls";
import { getToken } from "../../utils/cookies/tokensCookie";

interface CustomProps {
  closeModal: (state: boolean, data: any) => void;
  branches?: any;
  departments?: any;
  branchesAndDep?: any;
  loading?: boolean;
  selectedRows?: any;
  employee?: any;
  updatePlanData: any;
  employeeInPlan: any;
  currentPlan: any;
  plans: any;
  currentPlanIndex: number;
  isCopyPlan?: boolean;
  addedPlans?: any;
}

interface selectOption {
  value: string;
  label: string;
}

const BtnStyles = {
  maxWidth: "300px",
  marginBottom: "40px",
  marginLeft: "auto"
};

function EmployeePlansModal({
  closeModal,
  branches,
  departments,
  branchesAndDep,
  loading,
  selectedRows,
  employee,
  updatePlanData,
  employeeInPlan,
  currentPlan,
  plans,
  currentPlanIndex,
  isCopyPlan,
  addedPlans
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [usersWithInvoices, setUsersWithInvoices] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState<string[]>([]);
  const [numberOfAllAvailableUsers, setNumberOfAllAvailableUsers] = useState<number>(0);

  // Initial of selects
  const [branchesOptions, setBranchesOptions] = useState<selectOption[]>([]);
  const [departmentsOptions, setDepartmentsOptions] = useState<selectOption[]>(
    []
  );

  // Filtered data for options
  const [filteredDepartments, setFilteredDepartments] = useState<
    selectOption[]
  >([]);

  // Filtered ids for request
  const [branchIDs, setBranchIDs] = useState([]);
  const [departmentIDs, setDepartmentIDs] = useState([]);
  const [selectedEmploymentType, setSelectedEmploymentType] = useState("");

  // state for show Select all users in feedback
  const [selectAllUsers, setSelectAllUsers] = useState(false);

  // UI states
  const [addAllUsersButtonLoading, setAddAllUsersButtonLoading] = useState(false);

  const startDate = `${moment(
    currentPlan.dateRange.startDate,
    "DD.MM.YYYY"
  ).format("YYYY-MM-DD")}`;
  const endDate = `${moment(currentPlan.dateRange.endDate, "DD.MM.YYYY").format(
    "YYYY-MM-DD"
  )}`;

  const getUsersWithInvoices = async (planId: string) => {
    const token = await getToken();
    await axios
      .get(`${environment.baseUrl}${urls.plans.usersWithInvoices}/${planId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      })
      .then((res: any) => {
        setUsersWithInvoices(res.data.data);
      })
      .catch((err: any) => {
        console.error(err);
      });
  };

  // take departments data
  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  // take branches data
  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  // take branchesAndDepartments data
  useEffect(() => {
    dispatch(getDepartmentsAndBranches());
  }, [dispatch]);

  // from data to select options
  useEffect(() => {
    if (branches && branches.branches?.length) {
      const options: selectOption[] = [];
      branches.branches.forEach((item: any) => {
        options.push({
          value: item._id,
          label: item.name
        });
      });
      setBranchesOptions(options);
    }
  }, [branches.branches]);
  useEffect(() => {
    if (departments && departments.departments?.length) {
      const options: selectOption[] = [];
      departments.departments.forEach((item: any) => {
        options.push({
          value: item._id,
          label: item.name
        });
      });
      setDepartmentsOptions(options);
      setFilteredDepartments(options);
    }
  }, [departments.departments]);

  // Set employees from parent component
  useEffect(() => {
    setSelectedUsers(employeeInPlan);
  }, [employeeInPlan]);

  // remove all option from selected
  function removeAllOption(item: any) {
    if (item.value === "*") {
      return false;
    }
    return true;
  }

  // Handle button select / unselect all employee
  const handleMarkAllEmployeeButton = async () => {
    setAddAllUsersButtonLoading(true);
    if (numberOfAllAvailableUsers > 0 && numberOfAllAvailableUsers === selectedUsers.length) {
      // Unselect all
      setSelectedUsers([]);
    } else {
      // Select all
      const res = await axios.get(
        `${environment.baseUrl}${urls.users.getAllForPlanEmployeeSelection}`,
        {
          headers: {Authorization: `Bearer ${getToken()}`},
          params: {
            startDate,
            endDate,
            planId: Object.keys(updatePlanData).length > 0 && updatePlanData.plan && updatePlanData.plan._id
          }
        }
      );
      setSelectedUsers(res.data.users);
      setNumberOfAllAvailableUsers(res.data.users.length);
    }
    setAddAllUsersButtonLoading(false);
  };

  // handle actions after branch selected
  const handleBranchSelect = (selected: any) => {
    if (selected.length > 0) {
      if (departments) {
        const filtered = selected.filter(removeAllOption);
        const departmentInSelectedBranch: any = [];
        filtered.forEach((item: any) => {
          branchesAndDep.forEach((branAndDep: any) => {
            if (branAndDep.branchId === item.value) {
              departments.departments.forEach((department: any) => {
                if (branAndDep.departmentId === department._id) {
                  departmentInSelectedBranch.push({
                    value: department._id,
                    label: department.name
                  });
                }
              });
            }
          });
        });
        // remove duplicated branches
        const uniqueBranches = departmentInSelectedBranch.filter(
          (elem: any, index: number) =>
            departmentInSelectedBranch.findIndex(
              (obj: any) => obj.value === elem.value
            ) === index
        );
        setFilteredDepartments(uniqueBranches);
        // set array of department id's for request filtered users
        const branchesIDS = filtered.map(function (obj: any) {
          return obj.value;
        });
        setBranchIDs(branchesIDS);
        dispatch(showUserLoading());

        dispatch(
          getUsers({
            limit: 5,
            skip: 0,
            branchId: branchesIDS,
            departmentId: departmentIDs,
            startDate,
            endDate,
            isActive: 1
          })
        );
      }
    } else {
      setBranchIDs([]);
      dispatch(showUserLoading());

      dispatch(
        getUsers({
          limit: 5,
          skip: 0,
          departmentId: departmentIDs,
          startDate,
          endDate,
          isActive: 1
        })
      );
    }
  };
  // handle actions after DEPARTMENT selected
  const handleDepartmentSelect = (selected: any) => {
    if (selected.length > 0) {
      if (branches && branches.branches?.length) {
        const filtered = selected.filter(removeAllOption);
        const branchInSelectedDep: any = [];
        filtered.forEach((item: any) => {
          branchesAndDep.forEach((branAndDep: any) => {
            if (branAndDep.departmentId === item.value) {
              branches.branches.forEach((branch: any) => {
                if (branAndDep.branchId === branch._id) {
                  branchInSelectedDep.push({
                    value: branch._id,
                    label: branch.name
                  });
                }
              });
            }
          });
        });
        // set array of department id's for request filtered users
        const departmentsIDS = filtered.map(function (obj: any) {
          return obj.value;
        });
        setDepartmentIDs(departmentsIDS);
        dispatch(showUserLoading());

        dispatch(
          getUsers({
            limit: 5,
            skip: 0,
            branchId: branchIDs,
            departmentId: departmentsIDS,
            startDate,
            endDate,
            employmentType: selectedEmploymentType
          })
        );
      }
    } else {
      setDepartmentIDs([]);
      dispatch(showUserLoading());

      dispatch(
        getUsers({
          limit: 5,
          skip: 0,
          branchId: branchIDs,
          startDate,
          endDate,
          employmentType: selectedEmploymentType
        })
      );
      setFilteredDepartments(departmentsOptions);
    }
  };

  const handleEmploymentTypeSelection = (value: "fullTime" | "partTime") => {
    setSelectedEmploymentType(value);
    dispatch(showUserLoading());

    dispatch(
      getUsers({
        limit: 5,
        skip: 0,
        branchId: branchIDs,
        departmentId: departmentIDs,
        startDate,
        endDate,
        employmentType: value
      })
    );
  };

  // handle search function
  const [searchValue, setSearchValue] = useState("");
  const handleSearchBar = (value: string) => {
    dispatch(showUserLoading());
    setSearchValue(value.toLowerCase());

    dispatch(
      getUsers({
        limit: 5,
        skip: 0,
        branchId: branchIDs,
        departmentId: departmentIDs,
        search: value.toLowerCase(),
        startDate,
        endDate,
        employmentType: selectedEmploymentType
      })
    );
  };

  // handle select all checkbox from child
  const handleSelectAll = (state: boolean) => {
    setSelectAllUsers(state);
  };

  // Save users
  const handleSubmitEmployee = () => {
    closeModal(false, selectedUsers);
    /*dispatch(handleEmployeeCheckBox([]));
    dispatch(changeSelectedRows([]));*/
  };

  return (
    <div className="modal_container_big">
      <img
        onClick={handleSubmitEmployee}
        className="close_popup_paddings"
        src={closeIcon}
        alt="close"
      />

      <Spacer spacerHeight="50px" />

      <div className="employee_mobile_button" style={{ marginBottom: "20px" }}>
        <Button
          buttonHandler={() => closeModal(false, [])}
          buttonStyles={BtnStyles}
          buttonType="primary"
          buttonLabel={t("dashboard.plans.add_employee")}
        />
      </div>

      <div className="space-between plans_modal_filter">
        <div className="row plans_modal_filter">
          <SelectWithChoices
            style={{ marginRight: "1rem" }}
            name={t("dashboard.plans.select_1")}
            selectPlaceHolder={t("dashboard.plans.select_1")}
            selectId="branches"
            options={branchesOptions}
            handleAddChoice={handleBranchSelect}
          />
          <SelectWithChoices
            style={{ marginRight: "1rem" }}
            name={t("dashboard.plans.select_2")}
            selectPlaceHolder={t("dashboard.plans.select_2")}
            selectId="departments"
            options={filteredDepartments}
            handleAddChoice={handleDepartmentSelect}
          />
          <SelectWithChoices
            name={t("dashboard.employee.filter.employmentType.title")}
            selectPlaceHolder={t(
              "dashboard.employee.filter.employmentType.title"
            )}
            style={{ marginRight: "1rem" }}
            selectId="employmentType"
            isMulti={"no"}
            options={[
              {
                value: "",
                label: t("dashboard.employee.filter.active.all")
              },
              {
                value: "fullTime",
                label: t("dashboard.employee.filter.employmentType.fullTime")
              },
              {
                value: "partTime",
                label: t("dashboard.employee.filter.employmentType.partTime")
              }
            ]}
            handleAddChoice={(values: any) =>
              handleEmploymentTypeSelection(values.value)
            }
          />
        </div>
        <SearchBarLight
          style={{ minWidth: "0px", width: "100%", maxWidth: "500px" }}
          handleSearchBar={handleSearchBar}
          searchPlaceholder={t("dashboard.plans.search")}
        />
      </div>

      <Spacer spacerHeight="20px" />

      {employee ? (
        <ListCounterFeedback
          counter={selectedUsers.length}
          selectAll={selectAllUsers}
          totalCount={employee.totalUser}
          departmentIDs={departmentIDs}
          branchIDs={branchIDs}
          plans={plans}
          currentPlanIndex={currentPlanIndex}
          currentPlan={currentPlan}
          startDate={startDate}
          endDate={endDate}
        />
      ) : null}

      {loading ? (
        <TableLoading rowsNumber={5} />
      ) : (
        <>
          <EmployeePlansList
            updatePlanData={updatePlanData}
            selectAll={handleSelectAll}
            branches={branchIDs}
            departments={departmentIDs}
            selectedEmploymentType={selectedEmploymentType}
            search={searchValue}
            employeeInPlan={employeeInPlan}
            plans={plans}
            currentPlan={currentPlan}
            currentPlanIndex={currentPlanIndex}
            startDate={startDate}
            endDate={endDate}
            usersWithInvoices={usersWithInvoices}
            isCopyPlan={isCopyPlan}
            addedPlans={addedPlans}
            selectedUsers={selectedUsers}
            setSelectedUsers={(data: string[]) => setSelectedUsers(data)}
          />
          <div
            className="employee_desktop_button"
            style={{ marginBottom: "20px" }}
          >
            <Button
              buttonHandler={handleMarkAllEmployeeButton}
              buttonStyles={{...BtnStyles, marginLeft: 0, marginRight: "auto"}}
              buttonType="primary"
              buttonLabel={numberOfAllAvailableUsers > 0 && numberOfAllAvailableUsers === selectedUsers.length
                ? t("dashboard.plans.unselect_all")
                : t("dashboard.plans.select_all")
              }
              loading={addAllUsersButtonLoading}
            />
            <Button
              buttonHandler={handleSubmitEmployee}
              buttonStyles={BtnStyles}
              buttonType="primary"
              buttonLabel={t("dashboard.plans.add_employee")}
            />
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    branches: state.depsAndBranches.branches,
    departments: state.depsAndBranches.departments,
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    loading: state.users.loading,
    selectedRows: state.selectedRows.selectedRows,
    employee: state.users.users,
    addedPlans: state.addedPlans.addedPlans
  };
};

export default connect(mapStateToProps, null)(EmployeePlansModal);
