import "./MaxLimitChart.css";

import SubTitle from "Components/SimpleComponents/SubTitle";
import React, { useEffect, useState } from "react";
import { Doughnut } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import { getInvoicesStatisticData } from "../../redux/actions/chartsActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { rewriteCurrency } from "../../utils/rewriteCurrency";

interface CustomProps {
  invoicesStats?: any;
}

function MaxLimitChart({ invoicesStats }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Taking chart data after component is mounted
  useEffect(() => {
    dispatch(getInvoicesStatisticData());
  }, []);

  const [chartArray, setChartArray] = useState([0, 0]);

  useEffect(() => {
    if (invoicesStats) {
      const newArray = [
        invoicesStats.totalBudget
          ? invoicesStats?.remainingAmount?.toFixed(2)
          : 0,
        invoicesStats.totalBudget && invoicesStats.remainingAmount
          ? (invoicesStats.totalBudget - invoicesStats.remainingAmount)?.toFixed(
            2
          )
          : 0,
      ];

      console.log(newArray);

      setChartArray(newArray);
    }
  }, [invoicesStats]);

  return (
    <div className="max_limit">
      <div className="max_limit_left">
        <SubTitle>{t("dashboard.home.limit_header")}</SubTitle>
        <SubTitle>{t("dashboard.home.limit_title")}</SubTitle>
        <p className="max_limit_count">
          {invoicesStats && invoicesStats.totalBudget
            ? rewriteCurrency(chartArray[1], "EUR")
            : "0 EUR"}
        </p>
      </div>
      <div className="max_limit_nav_container">
        <div className="row max_limit_row">
          <div className="max_limit_dot" />
          <p className="employee_role">{t("dashboard.home.limit_opt_1")}</p>
          <span data-testid="remaining_amount">
            {invoicesStats && invoicesStats.remainingAmount
              ? rewriteCurrency(
                +invoicesStats.remainingAmount.toFixed(2),
                "EUR"
              )
              : "0 EUR"}
          </span>
        </div>

        <div className="row max_limit_row">
          <div className="max_limit_dot ocean" />
          <p className="employee_role">{t("dashboard.home.limit_opt_3")}</p>
          <span data-testid="claimed_amount">
            {invoicesStats && invoicesStats.claimedAmount
              ? rewriteCurrency(chartArray[1], "EUR")
              : "0"}
          </span>
        </div>
      </div>
      <div className="max_limit_right">
        <div className="doughnut_text_box">
          <div className="doughnut_text">
            <div>
              <p className="doughnut_number">
                {invoicesStats
                  ? rewriteCurrency(
                    +invoicesStats.totalBudget?.toFixed(2) || 0,
                    "EUR"
                  )
                  : "0 EUR"}
              </p>
              <p>{t("dashboard.home.limit_total")}</p>
            </div>
          </div>
        </div>
        <div className="doughnut_wrapper">
          <Doughnut
            data={{
              datasets: [
                {
                  data: chartArray,
                  backgroundColor: ["#FF6E42", "#D7DAE0", "#C3C8DB"],
                  hoverBackgroundColor: ["#FF6E42", "#D7DAE0", "#C3C8DB"],
                  hoverBorderColor: ["#FF6E42", "#D7DAE0", "#C3C8DB"],
                  hoverBorderWidth: "5",
                  borderWidth: 0,
                },
              ],
              labels: [
                t("dashboard.home.limit_opt_1"),
                t("dashboard.home.limit_opt_4"),
              ],
            }}
            options={{
              maintainAspectRatio: false,
              legend: {
                display: false,
              },
              cutoutPercentage: 65,
            }}
          />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    invoicesStats: state.charts.invoiceStatistic,
  };
};

export default connect(mapStateToProps, null)(MaxLimitChart);
