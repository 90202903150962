import React, { useEffect, useState } from "react";
import "./NotFound.css";
import errorImg from "img/Dashboard/error_image.svg";
import Button from "../../Components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import queryString from "query-string";

export default function ServerErrorPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [previousPath, setPreviousPath] = useState("");

  useEffect(() => {
    const parsedSearch = queryString.parse(location.search);
    if (
      parsedSearch &&
      parsedSearch.previousPath &&
      parsedSearch.previousPath.length
    ) {
      setPreviousPath(parsedSearch.previousPath.toString());
    }
  }, []);

  const reloadPage = () => {
    if (previousPath && previousPath.length) {
      navigate(previousPath);
    } else {
      navigate("/");
    }
  };
  return (
    <div className="error_page_box">
      <h1 className="error_type">500</h1>
      <h2 className="error_page_title">{t("warnings.500_page_title")}</h2>
      <h3 className="error_page_text">{t("warnings.500_page_text")}</h3>
      <div className="center">
        <img src={errorImg} alt="error" />
      </div>
      <div className="error_btn">
        <Button
          buttonType="primary"
          buttonHtmlType="submit"
          buttonLabel={
            previousPath && previousPath.length
              ? t("warnings.error_btn_reload")
              : t("warnings.error_btn")
          }
          fullWidth={true}
          buttonHandler={reloadPage}
        />
      </div>
    </div>
  );
}
