import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {getToken} from "../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import queryString from "query-string";

export default function WeavrEmailVerification() {
  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);
  const navigate = useNavigate();

  useEffect(() => {
    if (parsedSearch && parsedSearch.nonce) {
      sendEmailCode(parsedSearch.nonce, parsedSearch.email);
    }
  }, []);

  const sendEmailCode = async (code: any, email: any) => {
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.verify}`,
        {
          email: email,
          code: code
        },
        {
          headers: {Authorization: `Bearer ${token}`}
        });
      if (response.data) {
        setTimeout(() => {
          navigate("/payments", {state: {nextStep: true}});
        }, 400);
      }
    } catch (e) {
      setTimeout(() => {
        navigate("/payments", {state: {nextStep: true}});
      }, 200);
    }
  };

  return(
    <div></div>
  );
}
