import { Dispatch } from "redux";
import { getToken } from "../utils/cookies/tokensCookie";
import axios from "axios";
import qs from "qs";
import { errorHandler } from "../utils/errorHandler";
import environment from "../environment";
import {getUserCompany} from "../utils/cookies/userCookies/userCookies";
import { clearHrZoneAuth } from "redux/actions/hrZoneAuthActions";

interface IReduxRequestHandler {
  url: string;
  companyId?: string;
  dynamicParams?: any;
  successDispatchType?: string;
  errorDispatchType?: string;
  errorPayload?: string;
}

export class reduxRequestHandler {
  url = "";
  token = "";
  companyId = "";
  dynamicParams = {};
  successDispatchType = "";
  errorDispatchType = "";
  errorPayload = "";

  constructor(requestObject: IReduxRequestHandler) {
    this.url = requestObject.url;
    this.token = getToken() || "";
    if (!requestObject.companyId?.length) {
      this.companyId = getUserCompany() || "";
    }
    if (
      requestObject.dynamicParams &&
      Object.keys(requestObject.dynamicParams)
    ) {
      this.dynamicParams = requestObject.dynamicParams;
    }
    if (requestObject.successDispatchType?.length) {
      this.successDispatchType = requestObject.successDispatchType;
    }
    if (requestObject.errorDispatchType?.length) {
      this.errorDispatchType = requestObject.errorDispatchType;
    }
    if (requestObject.errorPayload?.length) {
      this.errorPayload = requestObject.errorPayload;
    }
  }

  // Get requests
  async get(dispatch: Dispatch<any>) {
    try {
      const response = await axios.get(`${environment.baseUrl}${this.url}`, {
        headers: { Authorization: `Bearer ${this.token}` },
        params: this.dynamicParams,
        paramsSerializer: (params: any) => qs.stringify(params)
      });
      dispatch({
        type: this.successDispatchType,
        payload: response.data
      });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: this.errorDispatchType,
          payload: this.errorPayload
        });
      }
    }
  }

  // Get requests
  async postHrZone(dispatch: Dispatch<any>) {
    try {
      const response = await axios.post(`${this.url}`, this.dynamicParams, {
        headers: { Authorization: `Bearer ${this.token}` },
      });

      dispatch({
        type: this.successDispatchType,
        payload: {...response.data, fetched: true}
      });
    } catch (e: any) {
      if(e.request.responseURL.includes("preflight")){
        dispatch({
          type: this.errorDispatchType,
          payload: this.errorPayload
        });
      }else{
        dispatch(clearHrZoneAuth());
        if (!errorHandler(e.request.status)) {
          dispatch({
            type: this.errorDispatchType,
            payload: this.errorPayload
          });
        }
      }
    }
  }
}
