import React, {useState} from "react";
import "./PaymentsOverviewHeaderContainer.css";
import {useTranslation} from "react-i18next";
import Button from "../../../../Buttons/Button";
import {useNavigate} from "react-router-dom";
import PaymentsOverviewHeaderContainerCardInfoTab
  from "./Tabs/PaymentsOverviewHeaderContainerCardInfoTab/PaymentsOverviewHeaderContainerCardInfoTab";
import PaymentsOverviewHeaderContainerAccountInfoTab
  from "./Tabs/PaymentsOverviewHeaderContainerAccountInfoTab/PaymentsOverviewHeaderContainerAccountInfoTab";
import PaymentsOverviewHeaderContainerTransactionHistoryTab
  from "./BottomTabs/PaymentsOverviewHeaderContainerTransactionHistoryTab/PaymentsOverviewHeaderContainerTransactionHistoryTab";
import AntTabs from "../../../../Payments/Shared/Tabs/AntTabs";

interface IProps {
  showLogin: () => void;
}

export default function PaymentsOverviewHeaderContainer(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<"cardInfo" | "accountInfo">("cardInfo");

  const renderTabContent = () => {
    switch (selectedTab) {
    case "cardInfo":
      return <PaymentsOverviewHeaderContainerCardInfoTab showLogin={() => props.showLogin()} />;
    case "accountInfo":
      return <PaymentsOverviewHeaderContainerAccountInfoTab />;
    }
  };

  const tabs = [
    {
      label: t("dashboard.payments.overview.header_component.tabs.card_info"),
      value: "cardInfo"
    },
    {
      label: t("dashboard.payments.overview.header_component.tabs.account_info"),
      value: "accountInfo"
    }
  ];

  return(
    <>
      <div className="payment_overview_header_container_header">
        <AntTabs
          selectedTab={selectedTab}
          onChange={(newValue: "cardInfo" | "accountInfo") => setSelectedTab(newValue)} aria-label="basic tabs example"
          tabs={tabs}
        />
        <Button
          buttonType="primary"
          buttonLabel={t("dashboard.payments.overview.header_component.create_card_btn")}
          buttonHandler={() => navigate("/payments/overview/create")}
          buttonIcon="add"
        />
      </div>

      {renderTabContent()}

    </>

  );
}
