import {decryptWithAES, encryptWithAES} from "../permissionsAllow";

export function setUserRoles(roles: any) {
  const encryptedRole = encryptWithAES(roles.toString());
  localStorage.setItem("lunch_role", encryptedRole);
}

export function getUserRoles() {
  const role = localStorage.getItem("lunch_role");
  if (role) {
    return decryptWithAES(role);
  }
}

export function removeUserRoles() {
  localStorage.removeItem("lunch_role");
}

export function setUserPermission(permission: any) {
  const encryptedRole = encryptWithAES(JSON.stringify(permission));
  localStorage.setItem("lunch_permission", encryptedRole);
}

export function getUserPermission() {
  const permission = localStorage.getItem("lunch_permission");
  if (permission) {
    return JSON.parse(decryptWithAES(permission));
  }
}

export function setUserPermissionType(permission: any) {
  const encryptedRole = encryptWithAES(permission.toString());
  localStorage.setItem("lunch_permission_type", encryptedRole);
}

export function getUserPermissionType() {
  const permission = localStorage.getItem("lunch_permission_type");
  if (permission) {
    return decryptWithAES(permission);
  }
}

export function removeUserPermission() {
  localStorage.removeItem("lunch_permission");
}

export function removeUserPermissionType() {
  localStorage.removeItem("lunch_permission_type");
}
