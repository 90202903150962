import React, { useEffect, useState } from "react";
import dashboardIcon from "img/Dashboard/SidebarIcons/icon_dashboard.svg";
import generalInfoIcon from "img/Dashboard/SidebarIcons/icon_general-info.svg";
import plansIcon from "img/Dashboard/SidebarIcons/icon_plans.svg";
import employeeIcon from "img/Dashboard/SidebarIcons/icon_employee.svg";
import ordersIcon from "img/Dashboard/SidebarIcons/icon_overview.svg";
import settingsIcon from "img/Dashboard/SidebarIcons/icon_settings.svg";
import onboardingIcon from "img/Dashboard/SidebarIcons/icon_onboarding.svg";
import companiesIcon from "img/Dashboard/SidebarIcons/icon_companies.svg";
import notificationIcon from "img/Dashboard/SidebarIcons/icon_notification-center.svg";
import SidebarItem from "./SidebarItem";

import caretUp from "../../img/caret-up-white.svg";
import caretDown from "../../img/caret-down-white.svg";
import { RootState } from "redux/reducers/rootReducer";
import { connect } from "react-redux";

interface CustomProps {
  handleSidebarChanges: (page: string) => void;
  selectedTab: string;
  itemName: string;
  itemText: string;
  openSidebar: boolean;
  children: any;
image: string;
  company?: any;
}

function SidebarDropdownItem({
  handleSidebarChanges,
  selectedTab,
  itemName,
  itemText,
  openSidebar,
  children,
  image,
  company
}: CustomProps) {
  const [showChildren, setShowChildren] = useState<boolean>(true);
  const propImage = image;

  // Setting image for tab
  //   let image = "";
  switch (image) {
  case "dashboard":
    image = dashboardIcon;
    break;
  case "general-info":
    image = generalInfoIcon;
    break;
  case "plans":
    image = plansIcon;
    break;
  case "employee":
    image = employeeIcon;
    break;
  case "overview":
    image = ordersIcon;
    break;
  case "settings":
    image = settingsIcon;
    break;
  case "payments":
    image = onboardingIcon;
    break;
  case "companies":
    image = companiesIcon;
    break;
  case "notification-center":
    image = notificationIcon;
    break;
  case "hrzone":
    image = employeeIcon;
    break;
  default:
    image = dashboardIcon;
  }



  /**
   * Function to check if the mother component (Sidebar item with children) is active.
   * It is active if one of the child components is active
   * @returns boolean
   */
  const isActive = () => {
    const childrenItemNames = children.map((child: any) => {
    	if (child && child.props && child.props.itemName) {
    	  return child.props.itemName;
      }
    });
    const currentUrlSplits = window.location.pathname.split("/");
    return(currentUrlSplits[1] === itemText.toLowerCase().replace(" ","") || childrenItemNames.includes(selectedTab));
  };

  return (
    <>
      <li
        id="sidebar_item"
        data-testid="sidebar_item"
        className={
          "sidebar_tab row selected_tab" 
        }
        onClick={() => setShowChildren(!showChildren)}
        style={{ position: "relative", borderLeft: "none"}}
      >
        {!(propImage.length > 0) ? (
          <p id="sidebar_text" className="sidebar_text">
            {itemText}
          </p>
        ) : (
          <img
            data-testid="sidebar_image"
            id="dashboard_icon"
            src={image}
            alt={itemText}
          />
        )}

        {/*{openSidebar*/}
        {/*  ?*/}
        {/*  <p*/}
        {/*    id="sidebar_text"*/}
        {/*    className="sidebar_text">*/}
        {/*    {itemText}*/}
        {/*  </p>*/}
        {/*  : null*/}
        {/*}*/}
        {/* {openSidebar ? <img
          data-testid="sidebar_image"
          id="dashboard_icon"
          src={caretUp}
          alt={itemText}
          className={showChildren ? "sidebar_caret-up" : "sidebar_caret-down"}
        /> : <></>} */}
       
      </li>
      {showChildren ? (
        <ul className="sidebar_dropdown-container">{children}</ul>
      ) : (
        <></>
      )}
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
	  company: state.company.company.company
  };
};

export default connect(mapStateToProps)(SidebarDropdownItem);
// export default SidebarDropdownItem;
