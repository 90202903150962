export const GET_DEPARTMENTS_SUCCESS = "GET_DEPARTMENTS_SUCCESS";
export const GET_BRANCHES_SUCCESS = "GET_BRANCHES_SUCCESS";
export const GET_DEPARTMENTS_AND_BRANCHES_SUCCESS = "GET_DEPARTMENTS_AND_BRANCHES_SUCCESS";
export const BRANCH_AND_DEPS_ERROR = "BRANCH_AND_DEPS_ERROR";

export interface getDepartmentsSuccess {
	type: typeof GET_DEPARTMENTS_SUCCESS
	payload: any
}

export interface getBranchesSuccess {
	type: typeof GET_BRANCHES_SUCCESS
	payload: any
}

export interface getDepartmentsAndBranchesSuccess {
	type: typeof GET_DEPARTMENTS_AND_BRANCHES_SUCCESS
	payload: any
}

export interface chartsDataError {
	type: typeof BRANCH_AND_DEPS_ERROR,
	payload: string
}


export type branchAndDepsDispatchTypes =
	getDepartmentsSuccess |
	chartsDataError |
	getBranchesSuccess |
	getDepartmentsAndBranchesSuccess




