import React, {useEffect, useState} from "react";
import image from "../../img/Dashboard/Card/weavr_onboarding_login.svg";
import {useTranslation} from "react-i18next";
import {getToken, setToken} from "../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import FormikInput from "../../Components/SimpleComponents/FormikInput";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";

interface IProps {
  userEmail: string;
  setShowModal: (state:boolean) => void;
}

export default function PaymentsResetPassword(props:IProps) {
  const { t } = useTranslation();

  const [refLogin, setRefLogin] = useState(null);

  const [nonce, setNonce] = useState("");

  const resetPassword = async (passcode:string) => {
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.resetPassword}`,
        {
          nonce: nonce,
          email: props.userEmail,
          newPassword: {
            value: passcode
          }
        },
        {
          headers: {Authorization: `Bearer ${token}`}
        });
      if (response.data && response.data.token) {
        setToken(response.data.token, true);
        props.setShowModal(false);
        setTimeout(() => {
          window.location.reload();
        }, 50);
      }
    } catch (e) {
      console.error(e);
      toast.error(
        <CustomToastContainer
          message={"error"}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const onSubmit = (e:any) => {
    e.preventDefault();
    if (nonce && nonce.length) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      refLogin.tokenize(function(tokens) {
        if (tokens && tokens.passcode) {
          resetPassword(tokens.passcode);
        }
      });
    }
  };

  useEffect(() => {
    const createdForm = (window as any).OpcUxSecureClient.form();

    setRefLogin(createdForm);

    setTimeout(() => {
      // Create an instance of a secure Passcode component that will collect the passcode
      const input = createdForm.input(
        "passcode",
        "passCode",
        {
          placeholder: "0000"
        }
      );

      // Embed the secure Passcode component in the HTML element where you want
      // the Passcode component to the shown on screen
      input.mount(document.getElementById("passcode"));
    }, 100);
  }, []);

  return(
    <form
      ref={refLogin}
      onSubmit={(e) => onSubmit(e)}
      className="weavr_passcode_container"
    >
      <img
        onClick={() => props.setShowModal(false)}
        style={{ top: "20px", right: "20px" }}
        className="close_popup"
        src={closeIcon}
        alt="close"
      />

      <div>
        <div className="weavr_login_container_content">
          <h1>Passwort zurücksetzen</h1>

          <FormikInput
            htmlFor="nonce"
            name="nonce"
            value={nonce}
            disabled={false}
            handleChange={(e:any) => setNonce(e.target.value)}
            label={"Code from email"}
            style={{
              maxWidth: "247px"
            }}
          />

          <div style={{display: "none"}}>
            Username: <input type="input" value={props.userEmail} name="u" /><br/>
          </div>
          <div className="weavr_passcode_input_box">
            Passwort <div id="passcode"></div><br/>
          </div>
          <input
            className="weavr_passcode_btn"
            type="submit"
          />
        </div>
      </div>
      <div>
        <img src={image} alt="login"/>
      </div>
    </form>
  );
}
