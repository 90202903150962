// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.grid-spacer {
    display: grid;
}


@media (max-width:600px){
    .grid-spacer {
        display: inherit;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Containers/VerticalDivider.css"],"names":[],"mappings":"AAAA;IACI,aAAa;AACjB;;;AAGA;IACI;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".grid-spacer {\n    display: grid;\n}\n\n\n@media (max-width:600px){\n    .grid-spacer {\n        display: inherit;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
