import React, { Component } from "react";

import Select from "react-select";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#E3E5F1",
    minHeight: "40px",
    height: "40px",
    boxShadow: null,
    minWidth: "200px!important"
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "40px",
    fontSize: "12px"
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, stat) => ({
    ...provided,
    height: "40px"
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#0E0A40",
    };
  }
};


export default class SelectWithSearch extends Component {
  state = {
    isClearable: false,
    isDisabled: false,
    isLoading: false,
    isRtl: false,
    isSearchable: true,
  };

  toggleClearable = () =>
    this.setState(state => ({ isClearable: !state.isClearable }));
  toggleDisabled = () =>
    this.setState(state => ({ isDisabled: !state.isDisabled }));
  toggleLoading = () =>
    this.setState(state => ({ isLoading: !state.isLoading }));
  toggleRtl = () => this.setState(state => ({ isRtl: !state.isRtl }));
  toggleSearchable = () =>
    this.setState(state => ({ isSearchable: !state.isSearchable }));

  render() {
    const {
      isClearable,
      isSearchable,
      isDisabled,
      isLoading,
      isRtl,
    } = this.state;
    if (this.props.data.length) {
      return (
        <Select
          onChange={(item) => this.props.handleSelect(item)}
          name={this.props.name}
          onBlur={this.props.onBlur}
          styles={customStyles}
          value={this.props.value}
          isDisabled={this.props.isDisabled}
          isLoading={isLoading}
          isClearable={isClearable}
          isRtl={isRtl}
          isSearchable={isSearchable}
          options={this.props.data}
        />
      );
    } else {
      return (
        <></>
      );
    }
  }
}
