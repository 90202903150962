// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dep_branch_container {
    margin-top: 40px;
    margin-bottom: 30px;
}

.dep_branch_container > p {
    font-size: 14px;
}

.branch_dev_box {
    padding-top: 20px;
}

.general_half_ratio {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 20px;
}

@media (max-width: 800px) {
    .general_half_ratio {
        width: 100%;
        display: grid;
        grid-template-columns: 1fr;
        grid-gap: 5px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/depAndBranchContainer/depAndBranchContainer.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI;QACI,WAAW;QACX,aAAa;QACb,0BAA0B;QAC1B,aAAa;IACjB;AACJ","sourcesContent":[".dep_branch_container {\n    margin-top: 40px;\n    margin-bottom: 30px;\n}\n\n.dep_branch_container > p {\n    font-size: 14px;\n}\n\n.branch_dev_box {\n    padding-top: 20px;\n}\n\n.general_half_ratio {\n    width: 100%;\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n    grid-gap: 20px;\n}\n\n@media (max-width: 800px) {\n    .general_half_ratio {\n        width: 100%;\n        display: grid;\n        grid-template-columns: 1fr;\n        grid-gap: 5px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
