import "./profile.css";

import ContentContainer from "Components/Containers/ContentContainer";
import { connect } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Button from "Components/Buttons/Button";
import React, { useEffect, useRef, useState } from "react";
import Modal from "react-modal";
import SimpleInput from "Components/SimpleComponents/SimpleInput";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import { useNavigate } from "react-router-dom";
import HrZoneUploadCsvEmployeeModal from "../../../Components/Modals/HrZoneUploadCsvEmployeeModal";
import { modalStyles } from "utils/modalStyles";
import UploadCsvModal from "Components/Modals/UploadCsvModal";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";

import { getToken } from "utils/cookies/tokensCookie";
import { useTranslation } from "react-i18next";
import { urls } from "api/urls";
import HrZoneUploadCsvCompanyModal from "Components/Modals/HrZoneUploadCsvCompanyModal";

interface IData {
  data: {
    numberOfEmployees: number;
    numberOfHR: number;
    HR: {
      firstName: string;
      lastName: string;
      email: string;
      isMasterHr: number;
    }[];
    HQAddress?: any;

    numberOfOffices: number;
  };
}

function ProfilePage(props: any) {
  const buttonStyles = {
    margin: "8px",
    fontWeight: 700,
    textTransform: "uppercase",
  };

  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState<IData>();

  const [showInviteModal, setShowInviteModal] = useState(false);
  const [inviteModalType, setInviteModalType] = useState(0); // 1 - hr, 2 - employee

  const [showCompanyModal, setShowCompanyModal] = useState(false);
  const [companyModalType, setCompanyModalType] = useState(0); // 1 - Hauptzentrale, 2 - Filiale

  const [employeeCount, setEmployeeCount] = useState(0);
  const [showEmployeeCountModal, setShowEmployeeCountModal] = useState(false);

  const [showEmployeeCsvModal, setEmployeeShowCsvModal] = useState(false);
  const [showCompanyCsvModal, setCompanyShowCsvModal] = useState(false);

  const [isMasterHr, setIsMasterHr] = useState(false);
  const headerMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)) {
        setShowInviteModal(false);
        setShowCompanyModal(false);
        setShowEmployeeCountModal(false);
        resetUser();
        resetCompany();
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const fetchData = async () => {
    try {
      const res: any = await axios.post(
        urls.hrZone.base + "profile",
        {
          hr_email: props.employee.email,
          sessionKey: props.hrZoneAuth.sessionKey,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          timeout: 10000,
        }
      );
      if (res.status == 200 && res.data.success === 1) {
        setIsMasterHr(props.hrZoneAuth.isMasterHr === 1);
        setData(res.data);
        setEmployeeCount(res.data.data.numberOfEmployees);
      } else {
        toast.error(
          <CustomToastContainer
            message={t("hrzone.toastMessages.error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("hrzone.toastMessages.error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }

    setIsLoading(false);
  };

  useEffect(() => {
    if (props.hrZoneAuth.sessionKey.length > 0) {
      fetchData();
    }
  }, [props.hrZoneAuth.sessionKey]);

  const navigate = useNavigate();

  const [modalUser, setModalUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
  });

  const [company, setCompany] = useState({
    name: "",
    address: "",
    plz: "",
    city: "",
  });

  const resetCompany = () => {
    setCompany({
      name: "",
      address: "",
      plz: "",
      city: "",
    });
  };

  const validateEmail = () => {
    return String(modalUser.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validInputs =
    (modalUser.firstName.length > 0 &&
      modalUser.lastName.length > 0 &&
      validateEmail()) ||
    (company.name.length > 0 &&
      company.address.length > 0 &&
      company.plz.length > 0 &&
      company.city.length > 0);

  const resetUser = () => {
    setModalUser({ email: "", firstName: "", lastName: "" });
  };

  const inviteEmployee = async () => {
    try {
      const res: any = await axios.post(
        urls.hrZone.base + "invite",
        {
          hr_email: props.employee.email,
          // customerEmail: props.employee.email,
          users: [{ ...modalUser, office: "Office", isHR: inviteModalType }],
          sessionKey: props.hrZoneAuth.sessionKey,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          timeout: 4000,
        }
      );
      if (res.status == 200 && res.data.status === 1) {
        toast.success(
          <CustomToastContainer
            message={t("hrzone.toastMessages.invite.success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        fetchData();
      } else {
        toast.error(
          <CustomToastContainer
            message={t("hrzone.toastMessages.error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("hrzone.toastMessages.error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const createHQOffice = async () => {
    try {
      const res: any = await axios.post(
        urls.hrZone.base + "createHqOffice",
        {
          hr_email: props.employee.email,
          hqName: company.name,
          hqStreet: company.address,
          hqZip: company.plz,
          hqCity: company.city,
          sessionKey: props.hrZoneAuth.sessionKey,
        },
        { headers: { Authorization: `Bearer ${getToken()}` }, timeout: 10000 }
      );
      if (res.status == 200 && res.data.success === 1) {
        toast.success(
          <CustomToastContainer
            message={t(
              data?.data.HQAddress
                ? "hrzone.toastMessages.edit.company.hqSuccess"
                : "hrzone.toastMessages.create.hqSuccess"
            )}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        fetchData();
      } else {
        toast.error(
          <CustomToastContainer
            message={t("hrzone.toastMessages.error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("hrzone.toastMessages.error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const createOffice = async () => {
    try {
      const res: any = await axios.post(
        urls.hrZone.base + "createOffice",
        {
          hr_email: props.employee.email,
          sessionKey: props.hrZoneAuth.sessionKey,
          offices: [
            {
              officeName: company.name,
              officeStreet: company.address,
              officeZip: company.plz,
              officeCity: company.city,
            },
          ],
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          timeout: 10000,
        }
      );
      if (res.status == 200 && res.data.success === 1) {
        toast.success(
          <CustomToastContainer
            message={t("hrzone.toastMessages.create.success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        fetchData();
      } else {
        toast.error(
          <CustomToastContainer
            message={t("hrzone.toastMessages.error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("hrzone.toastMessages.error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const editEmployeeCount = async () => {
    try {
      const res: any = await axios.post(
        urls.hrZone.base + "setEmployeeCount",
        {
          hr_email: props.employee.email,
          count: employeeCount,
          sessionKey: props.hrZoneAuth.sessionKey,
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          timeout: 10000,
        }
      );
      if (res.status == 200 && res.data.success === 1) {
        toast.success(
          <CustomToastContainer
            message={t("hrzone.toastMessages.edit.employee.countSuccess")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        fetchData();
      } else {
        toast.error(
          <CustomToastContainer
            message={t("hrzone.toastMessages.error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("hrzone.toastMessages.error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }

    setShowEmployeeCountModal(false);
  };

  const invitationModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px",
        },
      }}
      isOpen={showInviteModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px",
        }}
      >
        <img
          onClick={() => setShowInviteModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px",
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>
          {inviteModalType === 1
            ? t("hrzone.modals.employee.invite.hrTitle")
            : t("hrzone.modals.employee.invite.title")}
        </h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={t("hrzone.modals.employee.email")}
            isDisabled={false}
            inputValue={modalUser.email}
            handleChange={(e) => {
              setModalUser({ ...modalUser, email: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={t("hrzone.modals.employee.firstName")}
            isDisabled={false}
            inputValue={modalUser.firstName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, firstName: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={t("hrzone.modals.employee.lastName")}
            isDisabled={false}
            inputValue={modalUser.lastName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, lastName: e });
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "15px",
              fontSize: "12px",
              color: "#9aa9b3",
            }}
          >
            {t("hrzone.modals.separator")}
          </p>

          <Button
            buttonStyles={{
              margin: "0px auto 15px auto",
              fontWeight: 600,
              background: "none",
            }}
            buttonType={"secondary"}
            buttonLabel={t("hrzone.modals.csvUpload")}
            buttonHandler={() => setEmployeeShowCsvModal(true)}
          />
          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600,
            }}
            disabled={!validInputs}
            buttonType={"primary"}
            buttonLabel={t("hrzone.buttons.invite")}
            buttonHandler={async () => {
              await inviteEmployee();
              setShowInviteModal(false);
              resetUser();
            }}
          />
        </div>
      </div>
    </Modal>
  );

  const companyModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          //   height: "420px",
          padding: "0px",
          borderRadius: "20px",
        },
      }}
      isOpen={showCompanyModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px",
        }}
      >
        <img
          onClick={() => setShowCompanyModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px",
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>
          {companyModalType === 1
            ? t("hrzone.modals.company.titleHq")
            : t("hrzone.modals.company.title")}
        </h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"name"}
            placeholder={t("hrzone.modals.company.name")}
            isDisabled={false}
            inputValue={company.name}
            handleChange={(e) => {
              setCompany({ ...company, name: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"address"}
            placeholder={t("hrzone.modals.company.address")}
            isDisabled={false}
            inputValue={company.address}
            handleChange={(e) => {
              setCompany({ ...company, address: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"plz"}
            placeholder={t("hrzone.modals.company.plz")}
            isDisabled={false}
            inputValue={company.plz}
            handleChange={(e) => {
              const re = /^[0-9\b]+$/;
              setCompany({ ...company, plz: company.plz.replace(/\D/g, "") });
              if (e === "" || re.test(e)) {
                setCompany({ ...company, plz: e });
              }
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"city"}
            placeholder={t("hrzone.modals.company.city")}
            isDisabled={false}
            inputValue={company.city}
            handleChange={(e) => {
              setCompany({ ...company, city: e });
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end",
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "15px",
              fontSize: "12px",
              color: "#9aa9b3",
            }}
          >
            {t("hrzone.modals.separator")}
          </p>
          <Button
            buttonStyles={{
              margin: "0px auto 15px auto",
              fontWeight: 600,
              background: "none",
            }}
            buttonType={"secondary"}
            buttonLabel={t("hrzone.modals.csvUpload")}
            buttonHandler={() => setCompanyShowCsvModal(true)} //csv upload
          />

          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600,
            }}
            buttonType={"primary"}
            buttonLabel={
              companyModalType === 1
                ? t("hrzone.buttons.update")
                : t("hrzone.buttons.add")
            }
            disabled={!validInputs}
            buttonHandler={() => {
              companyModalType === 1 ? createHQOffice() : createOffice();
              setShowCompanyModal(false);
              resetCompany();
            }}
          />
        </div>
      </div>
    </Modal>
  );

  const employeeCountModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999,
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          //   height: "420px",
          padding: "0px",
          borderRadius: "20px",
        },
      }}
      isOpen={showEmployeeCountModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px",
        }}
      >
        <img
          onClick={() => setShowEmployeeCountModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px",
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>
          {t("hrzone.modals.employee.edit.titleEmployeeCount")}
        </h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"employeeCount"}
            placeholder={t("hrzone.modals.employee.edit.employeeCount")}
            isDisabled={false}
            inputValue={"" + employeeCount}
            handleChange={(e) => {
              if (!isNaN(Number(e))) {
                setEmployeeCount(Number(e));
              }
            }}
          />

          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "end",
            }}
          >
            <Button
              buttonStyles={{
                width: "100%",
                maxWidth: "100%",
                fontWeight: 600,
              }}
              buttonType={"primary"}
              buttonLabel={t("hrzone.buttons.save")}
              buttonHandler={async () => await editEmployeeCount()}
            />
          </div>
        </div>
      </div>
    </Modal>
  );

  return (
    <ContentContainer>
      <div className="profile_page">
        {invitationModal}
        {companyModal}
        {employeeCountModal}

        <Modal isOpen={showEmployeeCsvModal} style={modalStyles}>
          <HrZoneUploadCsvEmployeeModal
            // users={[]}
            isHR={inviteModalType}
            closeModal={() => {
              fetchData();
              setEmployeeShowCsvModal(false);
            }}
            hrZoneAuth={props.hrZoneAuth}
          />
        </Modal>

        <Modal isOpen={showCompanyCsvModal} style={modalStyles}>
          <HrZoneUploadCsvCompanyModal
            // users={[]}
            closeModal={() => {
              fetchData();
              setCompanyShowCsvModal(false);
            }}
            hrZoneAuth={props.hrZoneAuth}
          />
        </Modal>

        <h1 style={{ marginBottom: "20px" }}>
          {t("hrzone.generalInfo.title")}
        </h1>
        <div className="profile_container">
          <div className="profile_card">
            <div>
              <h1>{t("hrzone.generalInfo.hrEmployees")}</h1>
              <h1>{data?.data ? data.data.numberOfHR : 0}</h1>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                buttonType={"tertiary"}
                buttonLabel={t("hrzone.buttons.overview")}
                buttonStyles={buttonStyles}
                buttonHandler={() =>
                  navigate("/hrzone/general-info/hremployee")
                }
              />
              {isMasterHr &&
              <Button
                buttonType={"primary"}
                buttonLabel={t("hrzone.buttons.inviteHr")}
                buttonStyles={buttonStyles}
                buttonHandler={() => {
                  setInviteModalType(1);
                  setShowInviteModal(true);
                }}
              />
              }
            </div>

            <img
              className="profile_card-image"
              src="/images/hrzone/profile/person.svg"
              alt="person"
            />
          </div>

          <div className="profile_card">
            <div>
              <h1>{t("hrzone.generalInfo.employeeCount")}</h1>
              <h1>
                {data?.data.numberOfEmployees
                  ? data?.data.numberOfEmployees
                  : 0}
              </h1>
            </div>
            {isMasterHr &&
                <div style={{ display: "flex", justifyContent: "end" }}>

              <Button
                buttonType={"tertiary"}
                buttonLabel={t("hrzone.buttons.edit")}
                buttonStyles={buttonStyles}
                buttonHandler={() => setShowEmployeeCountModal(true)}
              />
              <Button
                buttonType={"primary"}
                buttonLabel={t("hrzone.buttons.inviteEmployee")}
                buttonStyles={buttonStyles}
                buttonHandler={() => {
                  setInviteModalType(0);
                  setShowInviteModal(true);
                }}
              />

            </div>
            }
            <img
              className="profile_card-image"
              src="/images/hrzone/profile/people.svg"
              alt="people"
            />
          </div>
        </div>

        <div
          className="profile_card full"
          style={{ justifyContent: "flex-start" }}
        >
          <div>
            <h1>{t("hrzone.generalInfo.hrEmployees")}</h1>
          </div>

          <div className="profile_employee-container">
            {data?.data.HR.map((hr, idx) => {
              return (
                <>
                  <p
                    key={hr.firstName + " " + hr.lastName + idx}
                    style={{
                      fontSize: "16px",
                      color: "#8a8a8a",
                      // padding: "0.5em",
                    }}
                  >
                    <span style={{ fontSize: "1em", fontWeight: 400 }}>
                      {hr.isMasterHr === 1
                        ? "Haupt-HR-Mitarbiter"
                        : "HR-Mitarbeiter"}
                    </span>
                    <br />
                    {hr.firstName + " " + hr.lastName}
                    <br />
                    {hr.email}
                  </p>
                </>
              );
            })}
          </div>
        </div>

        <div className="profile_container">
          <div className="profile_card">
            <div>
              <h1>{t("hrzone.generalInfo.hq")}</h1>
            </div>

            <div
              style={{
                display: "flex",
                justifyContent: "end",
                alignItems: "end",
              }}
            >
              <div className="profile_company-address">
                {t("hrzone.generalInfo.address")}
                <p>
                  {data?.data.HQAddress?.Name ? data?.data.HQAddress?.Name : ""}
                </p>
                <p>
                  {data?.data.HQAddress?.Street
                    ? data?.data.HQAddress.Street
                    : ""}
                </p>
                <p>
                  {data?.data.HQAddress?.Zip ? data?.data.HQAddress?.Zip : ""}{" "}
                  {data?.data.HQAddress?.City ? data?.data.HQAddress?.City : ""}
                </p>
              </div>
              {isMasterHr &&
              <Button
                buttonType={"primary"}
                buttonLabel={t("hrzone.buttons.update")}
                buttonStyles={buttonStyles}
                buttonHandler={() => {
                  setCompanyModalType(1);
                  setShowCompanyModal(true);
                  setCompany({
                    name: data?.data.HQAddress?.Name ?? "",
                    address: data?.data.HQAddress?.Street ?? "",
                    plz: data?.data.HQAddress?.Zip ?? "",
                    city: data?.data.HQAddress?.City ?? "",
                  });
                }}
              />
              }
            </div>

            <img
              className="profile_card-image"
              src="/images/hrzone/profile/company.svg"
              alt="company"
            />
          </div>

          <div className="profile_card">
            <div>
              <h1>{t("hrzone.generalInfo.companies")}</h1>
              <h1 className="profile_location-text">
                {data && data.data.numberOfOffices > 0 ? (
                  <>{data.data.numberOfOffices}</>
                ) : (
                  <div className="profile_location-missingCompanies">
                    {t("hrzone.generalInfo.missingCompanies")}
                  </div>
                )}
              </h1>
            </div>

            <div style={{ display: "flex", justifyContent: "end" }}>
              <Button
                buttonType={"tertiary"}
                buttonLabel={t("hrzone.buttons.overview")}
                buttonStyles={buttonStyles}
                buttonHandler={() => navigate("/hrzone/general-info/companies")}
              />
              {isMasterHr &&
              <Button
                buttonType={"primary"}
                buttonLabel={t("hrzone.buttons.add")}
                buttonStyles={buttonStyles}
                buttonHandler={() => {
                  setCompanyModalType(2);
                  setShowCompanyModal(true);
                }}
              />
              }
            </div>

            <img
              className="profile_card-image"
              src="/images/hrzone/profile/location.svg"
              alt="location"
            />
          </div>
        </div>
      </div>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    employee: state.users.userInfo.user,
    company: state.company.company.company,
    postCompanyDataState: state.company,
    hrZoneAuth: state.hrZone,
  };
};

export default connect(mapStateToProps, null)(ProfilePage);