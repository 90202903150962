// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employee_page {
	padding: 30px;
	min-height: 100%
}

.secondary {
	transition: .25s;
	color: #fc542b
}

.secondary:hover {
	color: #c64422
}`, "",{"version":3,"sources":["webpack://./src/pages/hrzone/employee/employee.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb;AACD;;AAEA;CACC,gBAAgB;CAChB;AACD;;AAEA;CACC;AACD","sourcesContent":[".employee_page {\n\tpadding: 30px;\n\tmin-height: 100%\n}\n\n.secondary {\n\ttransition: .25s;\n\tcolor: #fc542b\n}\n\n.secondary:hover {\n\tcolor: #c64422\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
