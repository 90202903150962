import {
  CHANGE_INVOICES_FILTERING_STATE,
  CHANGE_PLANS_FILTERING_STATE,
  filteringActionsTypes
} from "../types/filteringTypes";

export interface DefaultStateI {
  invoiceFilters: {
    limit: number,
    skip: number,
    branches: string[],
    departments: string[],
    employees: string[],
    plans: string[],
    merchants: string[],
    status: number | undefined,
    startDate: string,
    endDate: string,
    minAmount: number | undefined,
    maxAmount: number | undefined,
    search: string,
    sortBy: string
	},
  plansFilters: {
    limit: number,
    skip: number,
    search: string,
    status: string,
    users: string[],
    startDate: string,
    endDate: string
  }
}

const initialState:DefaultStateI = {
  invoiceFilters: {
    limit: 8,
    skip: 0,
    branches: [],
    departments: [],
    employees: [],
    plans: [],
    merchants: [],
    status: undefined,
    startDate: "",
    endDate: "",
    minAmount: undefined,
    maxAmount: undefined,
    search: "",
    sortBy: "-invoice.date"
  },
  plansFilters: {
    limit: 8,
    skip: 0,
    search: "",
    status: "",
    users: [],
    startDate: "",
    endDate: ""
  }
};

export const filteringReducer = (
  state = initialState,
  action:filteringActionsTypes) => {
  switch (action.type) {
  case CHANGE_INVOICES_FILTERING_STATE:
    return{
      ...state,
      invoiceFilters: action.payload
    };
  case CHANGE_PLANS_FILTERING_STATE:
    return{
      ...state,
      plansFilters: action.payload
    };
  default: return state;
  }
};
