import React, {useEffect} from "react";
import {useLocation, useNavigate} from "react-router-dom";

export default function KycEmailVerification() {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/payments/verify${location.search}`);
  }, []);

  return(
    <div></div>
  );
}
