import React, {useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import {useTranslation} from "react-i18next";
import axios from "axios";
import image from "img/Dashboard/Card/weavr_onborading_verification.svg";
import {toast} from "react-toastify";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import environment from "../../../../environment";
import {urls} from "../../../../api/urls";
import {RootState} from "../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import CustomToastContainer from "../../../SimpleComponents/ToastContainer";
import Button from "../../../Buttons/Button";
import {paymentsService} from "../../../../api/services/Payments/payments.service";

interface IProps {
  onSuccess: () => void;
  onError: () => void;
  weavr?: any;
}

function EnrollUserDevice(props: IProps) {
  const { t } = useTranslation();

  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);
  const [code, setCode] = useState("");

  const handleSvnInput = async (e:any) => {
    const inputNumber = e.target.value;
    setCode(inputNumber.toString().split(" ").join(""));
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (code.length === 6) {
      setShowSubmitButtonLoading(true);
      const token = await getToken();
      const weavrToken = await getToken(true);
      if (weavrToken) {
        try {
          const response = await axios.post(`${environment.baseUrl}${urls.payments.verifySms}`,
            {
              token: weavrToken,
              code: code,
              corporateId: props.weavr.corporateId.id,
              ignore: true
            },
            {
              headers: { Authorization: `Bearer ${token}` }
            });
          await new paymentsService().sendStepUp(weavrToken);
          props.onSuccess();
        } catch (e:any) {
          props.onError();
        }
      }
    }
  };

  const resendSms = async () => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    const response = await axios.post(`${environment.baseUrl}${urls.payments.sendSmsVerification}`,
      {
        token: weavrToken
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    if (response && response.data) {
      toast.success(
        <CustomToastContainer
          message={t("dashboard.payments.onboarding.stepper.verification.success_notification")}
          status={1} />, {
          autoClose: 5000,
        });
    } else {
      toast.error(
        <CustomToastContainer
          message={"Something went wrong"}
          status={3} />, {
          autoClose: 5000,
        });
    }
  };

  return(
    <form className="weavr_passcode_container" onSubmit={(e) => handleSubmit(e)}>
      <div className="center">
        <div className="verification_form_content">
          <h1>{t("dashboard.payments.onboarding.stepper.enroll_user_device.title")}</h1>
          <p>{t("dashboard.payments.onboarding.stepper.enroll_user_device.text")}</p>
          <div className="verification_form_content_input">
            <p>{t("dashboard.payments.onboarding.stepper.enroll_user_device.label")}</p>
            <NumberFormat
              disabled={false}
              className="code_input"
              format="# # # # # #"
              onChange={(e:any) => handleSvnInput(e)}
            />
          </div>

          <div style={{height: "15px"}} />

          <p
            style={{color: "blue", textDecoration: "underline", cursor: "pointer", marginBottom: "0px"}}
            onClick={() => {
              resendSms();
            }}
          >
            {t("dashboard.payments.onboarding.stepper.verification.resend_sms")}
          </p>

          <Button
            buttonType="primary"
            buttonLabel={t("dashboard.payments.onboarding.stepper.enroll_user_device.btn")}
            buttonHtmlType="submit"
            submitButton={true}
            fullWidth={true}
            buttonStyles={{maxWidth: "250px"}}
            disabled={code.length < 6}
            loading={showSubmitButtonLoading}
          />
        </div>
      </div>
      <div className="center">
        <img src={image} alt="verification"/>
      </div>
    </form>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    weavr: state.weavr.weavrCorporate
  };
};

export default connect(mapStateToProps, null)(EnrollUserDevice);
