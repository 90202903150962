import React, {useEffect, useRef, useState} from "react";
import "./ActionDots.css";
import BeatLoader from "react-spinners/BeatLoader";
import editUser from "./icons/editUser.svg";
import deactivateUser from "./icons/deactivate_employee.svg";
import restoreUser from "./icons/restore.png";
import createPlan from "./icons/post_add_black_48dp.svg";
import inviteUser from "./icons/invite_user_2.svg";
import manageCard from "./icons/card.svg";
import cardDetail from "./icons/card_detail.png";
import freezeCard from "./icons/freeze_card.png";
import removeCard from "./icons/remove_card.png";

export interface IActionDotsAction {
  key: string;
  label: (state: any) => string | string;
  onClick: (rootState: any) => void;
  imageKey?: "editUser" | "deactivateUser" | "restoreUser" | "createPlan" | "inviteUser" | "manageCard" | "cardDetail" | "freezeCard" | "removeCard";
  imageAlt?: string;
  hide?: (rootState: any) => boolean;
  imageStyle?: React.CSSProperties;
}

interface IActionDots {
  actions: IActionDotsAction[];
  rootState: any;
  loadingItem?: string | undefined;
  loadingIdentifier?: string | undefined;
}

export default function ActionDots(props: IActionDots) {
  const actionsRef = useRef<HTMLDivElement>(null);
  const actionRef = useRef<HTMLDivElement>(null);

  const images:any = {editUser, deactivateUser, restoreUser, createPlan, inviteUser, manageCard, cardDetail, freezeCard, removeCard};

  const [menuListOpen, setMenuListOpen] = useState(false);

  useEffect(() => {
    const handler = (event: any) => {
      if (!actionsRef.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return props.loadingIdentifier && props.loadingItem === props.rootState[props.loadingIdentifier] ? (
    <BeatLoader size="8px" />
  ) : (
    <div ref={actionsRef} className="action_dots_container">
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container"
        ref={actionRef}
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          {props.actions.map((action) => (
            action.hide && action.hide(props.rootState)
              ? <div key={action.key} />
              : <div
                key={action.key}
                className="table_menu_item row"
                onClick={() => {
                  setMenuListOpen(false);
                  action.onClick(props.rootState);
                }}
              >
                {action.imageKey
                  &&
                  <img
                    src={images[action.imageKey]}
                    alt={action.imageAlt}
                    style={action.imageStyle}
                  />
                }
                <p>{typeof action.label === "string" ? action.label : action.label(props.rootState) }</p>
              </div>
          ))}
        </div>
      )}
    </div>
  );
}
