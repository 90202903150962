import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Employee from "./page";
import { changeSidebarTab } from "../../redux/actions/sidebarActions";

export default function EmployeeRoute() {
  const dispatch = useDispatch();

  return (
    <Routes test-id="router_employee">
      <Route path="/" element={<Employee />} />
      <Route path="/*" element={<Navigate to={"/employee"} />} />
    </Routes>
  );
}
