// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.filtering_container {
	cursor: pointer
}
.filtering_block {
    width: 100%;
    height: 100%;
    min-height: 60px;
    padding: 10px;
    background-color: #D7D7DE;
    margin-top: 1rem;
    border-radius: 7px;
    margin-bottom: 30px!important;
}
.filtering_title > img {
    margin-right: 1rem;
}

.filters_item {
    width: 100%;
    margin-bottom: 10px;
}

.filtering_text {
    font-size: 0.9rem;
    font-weight: 600;
}
@media (max-width: 600px) {
    .filtering_text {
        max-width: 120px;
    }
}
.filter_label {
    font-size: 0.8rem;
    font-weight: 500;
    margin-top: 0.5rem;
    margin-bottom: 0.1rem;
}
.space_between_filter {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 10px;
}

@media (max-width: 1200px) {
    .space_between_filter {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
    }
}

@media (max-width: 920px) {
    .space_between_filter {
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media (max-width: 720px) {
    .space_between_filter {
        grid-template-columns: 1fr 1fr;
    }
}

@media (max-width: 600px) {
    .space_between_filter {
        grid-template-columns: 1fr;
    }
}


`, "",{"version":3,"sources":["webpack://./src/Components/Blocks/OrdersFilteringBlock.css"],"names":[],"mappings":"AAAA;CACC;AACD;AACA;IACI,WAAW;IACX,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,yBAAyB;IACzB,gBAAgB;IAChB,kBAAkB;IAClB,6BAA6B;AACjC;AACA;IACI,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI;QACI,gBAAgB;IACpB;AACJ;AACA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,kBAAkB;IAClB,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,sCAAsC;IACtC,cAAc;AAClB;;AAEA;IACI;QACI,aAAa;QACb,sCAAsC;IAC1C;AACJ;;AAEA;IACI;QACI,kCAAkC;IACtC;AACJ;;AAEA;IACI;QACI,8BAA8B;IAClC;AACJ;;AAEA;IACI;QACI,0BAA0B;IAC9B;AACJ","sourcesContent":[".filtering_container {\n\tcursor: pointer\n}\n.filtering_block {\n    width: 100%;\n    height: 100%;\n    min-height: 60px;\n    padding: 10px;\n    background-color: #D7D7DE;\n    margin-top: 1rem;\n    border-radius: 7px;\n    margin-bottom: 30px!important;\n}\n.filtering_title > img {\n    margin-right: 1rem;\n}\n\n.filters_item {\n    width: 100%;\n    margin-bottom: 10px;\n}\n\n.filtering_text {\n    font-size: 0.9rem;\n    font-weight: 600;\n}\n@media (max-width: 600px) {\n    .filtering_text {\n        max-width: 120px;\n    }\n}\n.filter_label {\n    font-size: 0.8rem;\n    font-weight: 500;\n    margin-top: 0.5rem;\n    margin-bottom: 0.1rem;\n}\n.space_between_filter {\n    display: grid;\n    grid-template-columns: 1fr 1fr 1fr 1fr;\n    grid-gap: 10px;\n}\n\n@media (max-width: 1200px) {\n    .space_between_filter {\n        display: grid;\n        grid-template-columns: 1fr 1fr 1fr 1fr;\n    }\n}\n\n@media (max-width: 920px) {\n    .space_between_filter {\n        grid-template-columns: 1fr 1fr 1fr;\n    }\n}\n\n@media (max-width: 720px) {\n    .space_between_filter {\n        grid-template-columns: 1fr 1fr;\n    }\n}\n\n@media (max-width: 600px) {\n    .space_between_filter {\n        grid-template-columns: 1fr;\n    }\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
