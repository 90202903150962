import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../redux/reducers/rootReducer";
import {convertToDDMMYYYY} from "../../utils/dateConverter";
import {rewriteCurrency} from "../../utils/rewriteCurrency";
import {handleEmployeeCheckBox} from "../../redux/actions/employeePageCheckboxActions";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import {changeSelectedRows} from "../../redux/actions/selectedUsersFromListActions";
import {useTranslation} from "react-i18next";
import {clearInvoicesMessages, getInvoices, updateInvoice} from "../../redux/actions/invoicesActions";

import Checkbox from "../SimpleComponents/Checkbox";
import SubTitle from "Components/SimpleComponents/SubTitle";
import Spacer from "Components/SimpleComponents/Spacer";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import OrdersSelectedMenu from "../SelectedMenu/OrdersSelectedMenu";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {isAllowed, userPermissions, userRoles} from "../../utils/permissionsAllow";
import moment from "moment/moment";
import {getInvoicesStatisticData} from "../../redux/actions/chartsActions";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";
import ActionDots, {IActionDotsAction} from "../Elements/ActionDots/ActionDots";
import getBadgeDataForInvoiceStatus from "../Elements/Badge/helpers/getBadgeDataForInvoiceStatus";
import Badge from "../Elements/Badge/Badge";


interface CustomProps {
	invoices?: any,
	checkedBoxes?: any,
	invoiceState?: any,
	selectedRows?: any,
	user?: any
}

function PendingList(
  {invoices, checkedBoxes, invoiceState, selectedRows, user}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [checkedRows, setCheckedRows] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);
  const [actionLoading, setActionLoading] = useState<string | undefined>(undefined);

  // List dots actions
  const actions: IActionDotsAction[] = [
    {
      key: "detail",
      label: t("dashboard.overview.menu_1"),
      onClick: (item: any) => {
        window.localStorage.setItem("amount", item.totalClaim.toFixed(2));
        navigate(
          `/overview/${item.invoiceId}`,
          {state: { prevPath: location.pathname}}
        );
      }
    },
    {
      key: "approve",
      label: t("dashboard.overview.menu_2"),
      onClick: (item: any) => {
        setActionLoading(item.invoiceId);
        handleInvoice(2, item);
      }
    },
    {
      key: "reject",
      label: t("dashboard.overview.menu_3"),
      onClick: (item: any) => {
        setActionLoading(item.invoiceId);
        handleInvoice(3, item);
      }
    }
  ];

  const handleInvoice = (status:number, data: any) => {
    if (isAllowed({data: {
      roles: [userRoles.superAdmin, userRoles.custom, userRoles.admin],
      permissions: {
        isAdmin: true,
        permissionsArray: [userPermissions.invoices]
      }
    }})) {
      const postData = {
        "invoices": [
          {
            "id": data.invoiceId,
            "status": status,
            "description": ""
          }
        ]
      };
      dispatch(updateInvoice(postData));
    } else {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.not_allowed")}
          status={2} />);
    }
    setActionLoading(undefined);
  };

  useEffect(() => {
    dispatch(getInvoices({
      limit: 4,
      skip: 0,
      branches: [],
      departments: [],
      employees: [],
      plans: [],
      merchants: [],
      status: 1,
      startDate: moment().startOf("month").format("YYYY-MM-DD"),
      endDate: moment().add(1,"days").format("YYYY-MM-DD"),
      minAmount: undefined,
      maxAmount: undefined,
      search: "",
      sortBy: ""
    }));
  }, [dispatch]);

  // Show/hide accept options if user don't have permission
  const [showOptions, setShowOptions] = useState(false);
  useEffect(() => {
    if (user && Object.keys(user).length) {
      setShowOptions(user.approveInvoices);
    }
  }, [user]);

  // Listening checkbox changes and push to selected Users
  useEffect(() => {
    const uniqueArr =
			Array.from(new Set([...selectedRows, ...checkedRows]));
    const filteredArray =
			uniqueArr.filter((item:any) => item.isClicked === true);
    const newArray = filteredArray.filter((v:any,i:number,a:any) =>
      a.findIndex((tr:any) =>
        (tr.invoiceId===v.invoiceId))===i);
    dispatch(changeSelectedRows(newArray));
    //Need to disable it, because eslint says, that I need to add
    //selectedRows as dependency but it will woke
    // that function in every render
    // eslint-disable-next-line
	}, [checkedRows])

  useEffect(() => {
    if (invoices.invoices && invoices.invoices.data && !checkAll) {
      const newEmployee = invoices.invoices.data.map((item:any) => {
        return ({
          ...item, isClicked: false
        });
      });
      setCheckedRows(newEmployee);
      dispatch(handleEmployeeCheckBox(newEmployee));
    }
    //Need to disable it, because eslint says, that I need to add
    //checkAll as dependencies, but it will make to much renders
    // eslint-disable-next-line
	}, [invoices])

  const handleCheckboxChange = (checked:boolean, id:string) => {
    const changedObj:any = checkedRows.map((item:any) =>
      item.invoiceId === id
        ? ({...item, isClicked: checked})
        : ({...item})
    );
    // DON'T CHANGE IT!!! It's clears redux store
    selectedRows
      .forEach((selected:any, index:number, object:any) => {
        if (selected.invoiceId === id) {
          object.splice(index, 1);
        }
      });
    dispatch(handleEmployeeCheckBox(changedObj));
    setCheckedRows(changedObj);
  };


  // handling click on Checkbox "select all"
  const handleCheckAll = (checked:boolean) => {
    setCheckAll(checked);
    const changedObj:any = checkedRows.map((item:any) =>
      ({...item, isClicked: checked})
    );
    selectedRows
      .forEach((selected:any, index:number, object:any) => {
        object.splice(0, 4);
      });
    setCheckedRows(changedObj);
    dispatch(handleEmployeeCheckBox(changedObj));
  };

  // taking Success/Error after update invoice
  useEffect(() => {
    if (invoiceState.success === "update_invoices") {
      dispatch(clearInvoicesMessages());
      toast.success(
        <CustomToastContainer
          message={t("warnings.update_invoice")}
          status={1} />, {
          autoClose: 5000,
          toastId: "marketing"
        });
      dispatch(getInvoicesStatisticData());
      dispatch(getInvoices({
        limit: 4,
        skip: 0,
        branches: [],
        departments: [],
        employees: [],
        plans: [],
        merchants: [],
        status: 1,
        startDate: moment().startOf("month").format("YYYY-MM-DD"),
        endDate: moment().add(1,"days").format("YYYY-MM-DD"),
        minAmount: undefined,
        maxAmount: undefined,
        search: "",
        sortBy: ""
      }));
    } else if (invoiceState.error === "update_invoices") {
      dispatch(clearInvoicesMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.change_invoice_status")}
          status={3} />, {
          autoClose: 5000,
          toastId: "marketing"
        });
    }
    //Need to disable it, because eslint says, that I need to add
    //all dependencies
    // state is updated
    // eslint-disable-next-line
	}, [invoiceState])

  return(
    <div className="dashboard_box_padding">
      <div className="space-between">
        <SubTitle>{t("dashboard.home.pending_orders_title")}</SubTitle>
        <div className="row">
          {checkedBoxes.length > 0 && isAllowed({data: {
            roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
            permissions: {
              isAdmin: true,
              permissionsArray: [userPermissions.invoices]
            }
          }}) && new getLunchSettings().isApprovalProcess()
            ? <OrdersSelectedMenu checkedBoxes={checkedBoxes} />
            : null
          }
          <p
            onClick={() => navigate("/overview")}
            className="dash_container_more"
          >
            {t("dashboard.home.pending_orders_all")}
          </p>
        </div>
      </div>
      <div className="line table_line">
        <div className="line_element" />
      </div>
      {
        invoices.loading
        || !invoices.invoices
        || !invoices.invoices.data
        || !invoices.invoices.data.length
        || !checkedRows
        || !checkedRows.length? (
            <EmptyTableState style={{height:"400px"}} />
          ) : (
            <div className="table_box">
              <div className="table_wrapper" >
                <table style={{width:"100%"}}>
                  <thead>
                    <tr>
                      <th>
                        <div className="row">
                          {showOptions
                            ?
                            <Checkbox
                              id="selectAll"
                              handleCheckboxChange=
                                {handleCheckAll}
                              checked={checkAll}
                            />
                            : null}
                          <Spacer spacerWidth="1rem" />
                          {t("dashboard.home" +
														".pending_orders_th_1")}
                        </div>
                      </th>
                      <th>
                        {t("dashboard.home.pending_orders_th_2")}
                      </th>
                      <th>
                        {t("dashboard.home.pending_orders_th_3")}
                      </th>
                      <th>
                        {t("dashboard.home.pending_orders_th_4")}
                      </th>
                      <th style={{textAlign:"center"}}>
                        {t("dashboard.home.pending_orders_th_5")}
                      </th>
                      <th style={{textAlign:"center"}}>
                        {t("dashboard.home.pending_orders_th_6")}
                      </th>
                      <th style={{textAlign:"center"}}>
                        {t("dashboard.home.pending_orders_th_7")}
                      </th>
                      <th>
                        {t("dashboard.home.pending_orders_th_8")}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {invoices.invoices.data
                      .map((item:any, index:number) => (
                        <React.Fragment key={item.invoiceId}>
                          <tr className="spacer" />
                          <tr className="table-item">
                            <td style={{width:"11.11%"}}>
                              <div className="row">
                                {showOptions
                                  ?
                                  <Checkbox
                                    id={item.invoiceId
                                      .toString()}
                                    handleCheckboxChange={
                                      handleCheckboxChange
                                    }
                                    checked=
                                      {checkedRows[index]
                                        .isClicked}
                                  />
                                  : null}
                                <Spacer spacerWidth="1rem" />
                                {convertToDDMMYYYY(item.date)}
                              </div>
                            </td>
                            <td style={{width:"16.68%"}}>
                              {item.employee}
                            </td>
                            <td style={{width:"14.00%"}}>
                              {item.branch}
                            </td>
                            <td style={{width:"14.00%"}}>
                              {item.department}
                            </td>
                            <td
                              style={{
                                width:"11.11%",
                                textAlign:"center"}}
                            >
                              {rewriteCurrency(item.grandTotal
                                .toFixed(2), "EUR")}
                            </td>
                            <td
                              style={{
                                width:"11.11%",
                                textAlign:"center"}}
                            >
                              {rewriteCurrency(item.totalClaim
                                .toFixed(2), "EUR")}
                            </td>
                            <td
                              style={{
                                width:"11.11%",
                                textAlign:"center"}}
                            >
                              <Badge
                                color={getBadgeDataForInvoiceStatus(item.status).color}
                                text={getBadgeDataForInvoiceStatus(item.status).text}
                              />
                            </td>
                            <td style={{paddingLeft:"2rem"}}>
                              <ActionDots
                                loadingItem={actionLoading}
                                loadingIdentifier={"invoiceId"}
                                actions={actions}
                                rootState={item}
                              />
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                  </tbody>
                </table>
                <Spacer spacerHeight="8px" />
              </div>
            </div>
          )}
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    invoices: state.invoices,
    checkedBoxes: state.employeeCheckboxesState.selectedCheckboxes,
    invoiceState: state.invoices,
    selectedRows: state.selectedRows.selectedRows,
    user: state.users.userInfo.user
  };
};

export default connect(mapStateToProps, null)(PendingList);
