export const CHANGE_INVOICES_FILTERING_STATE = "CHANGE_INVOICES_FILTERING_STATE";
export const CHANGE_PLANS_FILTERING_STATE = "CHANGE_PLANS_FILTERING_STATE";

interface invoiceFilteringStateAction {
	type: typeof CHANGE_INVOICES_FILTERING_STATE
	payload: any
}

interface plansFilteringStateAction {
	type: typeof CHANGE_PLANS_FILTERING_STATE
	payload: any
}

export type filteringActionsTypes =
	invoiceFilteringStateAction |
	plansFilteringStateAction
