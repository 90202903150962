import React, {useEffect, useRef, useState} from "react";
import {motion} from "framer-motion";
import caretIcon from "img/Dashboard/PlansAndModules/input_caret.svg";
import "./PhoneNumberInput.css";
import {phoneNumberCodes} from "./phoneNumberCodes";
import {useTranslation} from "react-i18next";

interface IPhoneNumberInput {
  label: string;
  handleChange: (value: string) => void;
  name: string;
  onBlur?: (event: any) => void;
  disabled?: boolean;
  value?: string;
  error?: string;
}

export default function PhoneNumberInput(props: IPhoneNumberInput) {
  const { t } = useTranslation();

  const dropdownRef = useRef<any>();
  const selectRef = useRef<any>();

  const [selectedCountry, setSelectedCountry] = useState(phoneNumberCodes.AT);
  const [inputValue, setInputValue] = useState<string>(selectedCountry.code);
  const [dropdownOpen, setDropdownOpen] = useState(false);

  // Close on click outside
  useEffect(() => {
    const handler = (event:any) => {
      if (!dropdownRef.current?.contains(event.target as Node) && !selectRef.current?.contains(event.target as Node)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // Assign changed value to input field
  useEffect(() => {
    if (props.value) {
      setInputValue(props.value);
    }
  }, [props.value]);

  // Detect if input field do not have pre-defined country code, assign it
  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.includes(selectedCountry.code) && !isNaN(Number(value))) {
      if (value === selectedCountry.code) {
        props.handleChange("");
        setInputValue(selectedCountry.code);
      } else {
        props.handleChange(value);
      }
    } else {
      props.handleChange("");
    }
  };

  return(
    <div
      className="simple_input"
      style={{position: "relative"}}
    >
      <label
        className="simple_label"
      >
        {props.label}
      </label>

      <div
        style={{
          position: "absolute",
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center"
        }}
      >
        <div
          ref={selectRef}
          className={"phoneNumber_flag"}
          onClick={() => setDropdownOpen(!dropdownOpen)}
        >
          <p>
            {selectedCountry.flag}
          </p>
          <img src={caretIcon} alt="caret"/>
        </div>
      </div>
      <motion.input
        name={props.name}
        whileFocus={{ backgroundColor: "rgba(151, 151, 226, 0.281)"}}
        type="text"
        className={"simple_input phoneNumber"}
        disabled={props.disabled}
        value={inputValue}
        onChange={onChange}
        onBlur={props.onBlur}
      />
      {/*Phone number codes dropdown*/}
      {dropdownOpen
        &&
        <div
          ref={dropdownRef}
          className={"phone_number_input_dropdown"}
        >
          {Object.keys(phoneNumberCodes).map((key: string) => (
            <div
              className={"phone_number_input_dropdown_item"}
              key={key}
              onClick={() => {
                setSelectedCountry(phoneNumberCodes[key]);
                setInputValue(phoneNumberCodes[key].code);
                props.handleChange("");
                setDropdownOpen(false);
              }}
            >
              <p>{phoneNumberCodes[key].flag}</p>
              <p>{t(phoneNumberCodes[key].label)}</p>
            </div>
          ))}
        </div>
      }

      {props.error && <p className="input_error phoneNumber">{props.error}</p>}
    </div>
  );
}
