import React from "react";
import "./InfoScreen.css";
import {useTranslation} from "react-i18next";
import fileImage from "img/info_file.svg";
import InfoFirstStep from "./InfoFirstStep";
import InfoSecondStep from "./InfoSecondStep";
import deImage from "img/info_image_de.svg";
import enImage from "img/info_image_en.svg";
import i18next from "../../../../../i18next";

interface IProps {
	step: number;
	business?: boolean;
}

export default function ChecklistInfoScreen({step, business}:IProps) {
  const { t } = useTranslation();

  return(
    <div className="info_screen">
      <div className="info_content" >
        {step === 1
          ? <h1>{t("partner_portal.deals.create.info_container.title")}</h1>
          : null
        }
        {step === 1
          ? <div className="info_content_image">
            <img style={{marginTop: "60px"}} src={fileImage} alt="file"/>
          </div>
          : null
        }
        {step === 1
          ? <InfoFirstStep />
          : <InfoSecondStep business={business} />
        }
      </div>
      <div className="info_images" style={i18next.language === "de"
        ? {backgroundImage: `url('${deImage}')`}
        : {backgroundImage: `url('${enImage}')`}
      } />
    </div>
  );
}
