import React, {useEffect, useState} from "react";
import "./Modal.css";
import closeIcon from "img/Dashboard/Employee/close_btn.svg";
import successImage from "img/Dashboard/PlansAndModules/popup_success.png";
import Spacer from "./Spacer";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import Button from "../Buttons/Button";
import {toast} from "react-toastify";
import CustomToastContainer from "./ToastContainer";
import { useTranslation } from "react-i18next";
import {clearDeactivatePlanMessages} from "../../redux/actions/plansActions";

interface CustomProps {
	closeModal: (state:boolean) => void,
	submitData: () => void,
	plansMessage?: any,
	handleSuccessScreen: (state:boolean) => void,
	updatePlanData: any
}

function ModalPlansAndModules(
  {closeModal, submitData, plansMessage,
    handleSuccessScreen, updatePlanData}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const postNewPlan = () => {
    setShowSubmitButtonLoading(true);
    submitData();
  };

  const [updatePlan] = useState(false);

  const [modalTitle, setModalTitle] = useState("");
  const [modalText, setModalText] = useState("");
  const [buttonText, setButtonText] = useState("");
  useEffect(() => {
    if (updatePlan) {
      setModalTitle(t("dashboard.plans.popup_title_update"));
      setModalText(t("dashboard.plans.popup_text_2_update"));
      setButtonText(t("dashboard.plans.success_btn_update"));
    } /*else if (updatePlanData.invoiceCount > 0) {
      setModalTitle(t("dashboard.plans.popup_title_cancel"));
      setModalText(t("dashboard.plans.popup_text_cancel"));
      setButtonText(t("dashboard.plans.popup_btn_cancel"));
    }*/ else {
      setModalTitle(t("dashboard.plans.popup_title"));
      setModalText(t("dashboard.plans.popup_text_2"));
      setButtonText(t("dashboard.plans.success_btn"));
    }
  }, [updatePlan, updatePlanData]);

  useEffect(() => {
    if (plansMessage.success === "post_plans" ||
			plansMessage.success === "update_plan") {
      setShowSubmitButtonLoading(false);
      closeModal(false);
      handleSuccessScreen(true);
      dispatch(clearDeactivatePlanMessages());
    }
    if (plansMessage.error === "post_plans"
			|| plansMessage.error === "update_plan") {
      setShowSubmitButtonLoading(false);
      toast.error(
        <CustomToastContainer
          message={t("warnings.csv_upload_error")}
          status={3} />, {
          autoClose: 5000,
        });
      dispatch(clearDeactivatePlanMessages());
    }
  }, [plansMessage.success, plansMessage.error,
    closeModal, handleSuccessScreen, t]);

  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);

  return(
    <div className="modal_container">
      <img
        onClick={() => closeModal(false)}
        className="close_popup"
        src={closeIcon} alt="close"/>
      <h2 style={{textAlign:"center"}}>
        {modalTitle}
      </h2>
      <div className="column_center">
        <img
          className="success_pop_img"
          src={successImage} alt="successPlan"
        />
        <p className="plans_pop_text">
          {t("dashboard.plans.popup_text_1")}
        </p>
        <p className="plans_pop_text">
          {modalText}
        </p>
        <Spacer spacerHeight="30px" />
        <Button
          buttonType="primary"
          buttonLabel=
            {buttonText}
          buttonHandler={postNewPlan}
          loading={showSubmitButtonLoading}
        />
      </div>
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    plansMessage: state.plans
  };
};

export default connect(mapStateToProps, null)(ModalPlansAndModules);
