import React, { useEffect } from "react";
import "./ListStyles.css";
import { Link } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import inviteIcon from "img/Dashboard/invite_icon.svg";
import { toast } from "react-toastify";
import { isAllowed, userRoles } from "../../utils/permissionsAllow";
import { useTranslation } from "react-i18next";
import {
  clearUserMessages,
  getInactiveUsers,
  inviteUser
} from "../../redux/actions/usersActions";

import SubTitle from "Components/SimpleComponents/SubTitle";
import Spacer from "../SimpleComponents/Spacer";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import CustomToastContainer from "../SimpleComponents/ToastContainer";

interface CustomProps {
  users?: any;
  inviteMessages?: any;
}

function EmployeeList({ users, inviteMessages }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getInactiveUsers(4));
  }, [dispatch]);

  useEffect(() => {
    if (inviteMessages.success === "invite_users") {
      toast.success(
        <CustomToastContainer
          message={t("warnings.send_invite_success")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
      dispatch(clearUserMessages());
    } else if (inviteMessages.error === "invite_users") {
      toast.error(
        <CustomToastContainer
          message={t("warnings.send_invite_error")}
          status={3}
        />,
        {
          autoClose: 5000
        }
      );
      dispatch(clearUserMessages());
    }
  }, [inviteMessages, t, dispatch]);

  // handle Invite to app click
  const handleInviteClick = (email: string) => {
    if (
      isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin]
        }
      })
    ) {
      dispatch(
        inviteUser({
          email: email
        })
      );
    } else {
      toast.warning(
        <CustomToastContainer message={t("warnings.not_allowed")} status={2} />,
        {
          autoClose: 5000
        }
      );
    }
  };

  return (
    <div className="dashboard_box_padding">
      <div className="space-between">
        <SubTitle>{t("dashboard.home.employee_list_title")}</SubTitle>
        <p className="dash_container_more">
          <Link to="employee">{t("dashboard.home.employee_list_all")}</Link>
        </p>
      </div>
      <div className="line table_line">
        <div className="line_element" />
      </div>
      {users.users && !users.users.length ? (
        <EmptyTableState style={{ maxHeight: "400px" }} />
      ) : (
        <div className="table_box">
          <div className="table_wrapper">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>
                    <div className="row">
                      <Spacer spacerWidth="1rem" />
                      {t("dashboard.home.employee_list_th_1")}
                    </div>
                  </th>
                  <th>{t("dashboard.home.employee_list_th_2")}</th>
                  <th>{t("dashboard.home.employee_list_th_3")}</th>
                  <th style={{ textAlign: "center" }}>
                    {t("dashboard.home.employee_list_th_5")}
                  </th>
                </tr>
              </thead>
              <tbody>
                {users.users &&
                  users.users.map((item: any) => (
                    <React.Fragment key={item._id}>
                      <tr className="spacer" />
                      <tr className="table-item">
                        <td style={{ width: "25%" }}>
                          <div className="row">
                            <Spacer spacerWidth="1rem" />
                            <p className="list_check_text">{item.firstName}</p>
                          </div>
                        </td>
                        <td style={{ width: "25%" }}>{item.lastName}</td>
                        <td style={{ width: "33.32%" }}>{item.email}</td>
                        <td style={{ width: "16.66%" }}>
                          <img
                            onClick={() => handleInviteClick(item.email)}
                            style={{
                              cursor: "pointer",
                              margin: "auto",
                              display: "block"
                            }}
                            src={inviteIcon}
                            alt="invite"
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      )}
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    users: state.users.inactiveUsers,
    inviteMessages: state.users
  };
};

export default connect(mapStateToProps, null)(EmployeeList);
