import React, {useEffect, useImperativeHandle, useState, forwardRef} from "react";
import "./InvoiceOverviewTopCards.css";
import {useTranslation} from "react-i18next";
import approvedIcon from "../../../img/Dashboard/Invoices/InvoiceOverviewTopCards/approved.png";
import rejectedIcon from "../../../img/Dashboard/Invoices/InvoiceOverviewTopCards/rejected.png";
import pendingIcon from "../../../img/Dashboard/Invoices/InvoiceOverviewTopCards/pending.png";
import {invoicesService} from "../../../api/services/Invoices/invoices.service";
import {useNavigate} from "react-router-dom";
import InfoCard, {InfoCardNumberTypeEnum} from "Components/Containers/InfoCard/InfoCard";
import {getLunchSettings} from "../../../utils/lunch/getLunchSettings/getLunchSettings";
import {useEffectOnce} from "../../../hooks/useEffectOnce";

interface ICard {
  id: number;
  title: string;
  amount: null | number;
  color: string;
  image: string;
  iconHeight?: string;
  link?: {
    onClick: () => void;
    linkText: string;
  }
}

const InvoiceOverviewTopCards = (props: any, ref: any) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const onClick = (cardId: number, invoiceId: string | undefined) => {
    if (cardId === 3 && invoiceId) {
      navigate(`/overview/${invoiceId}?approval=true`);
    }
  };
  const [cards, setCards] = useState<ICard[]>([
    {
      id: 0,
      title: t("components.blocks.invoice_overview_top_cards.cards_titles.aut_approved"),
      amount: null,
      color: "rgba(139, 188, 188, 1)",
      image: approvedIcon,
      iconHeight: "28px"
    },
    {
      id: 1,
      title: t("components.blocks.invoice_overview_top_cards.cards_titles.man_approved"),
      amount: null,
      color: "rgba(134, 190, 192, 1)",
      image: approvedIcon,
      iconHeight: "28px"
    },
    {
      id: 2,
      title: t("components.blocks.invoice_overview_top_cards.cards_titles.rejected"),
      amount: null,
      color: "rgba(222, 57, 73, 1)",
      image: rejectedIcon,
      iconHeight: "28px"
    },
    {
      id: 3,
      title: t("components.blocks.invoice_overview_top_cards.cards_titles.pending"),
      amount: null,
      color: "rgba(248, 181, 102, 1)",
      image: pendingIcon,
      iconHeight: "34px",
      link: new getLunchSettings().isOcr()
        ? {
          onClick: () => onClick(3, ""),
          linkText: t("components.blocks.invoice_overview_top_cards.links.approve")
        }
        : undefined
    }
  ]);

  useImperativeHandle(ref, () => ({
    getData() {
      getData();
    }
  }));

  const getData = async () => {
    const response = await new invoicesService().getInvoicesTopCardsData();
    if (response && response.status && response.data) {
      const cardsCopy = [...cards];
      // Set first pending invoice id for navigation to approval section
      if (response.data.firstPendingItem && cardsCopy[3].link) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        cardsCopy[3].link.onClick = () => onClick(3, response.data.firstPendingItem);
      }
      cardsCopy[0].amount = response.data.automaticallyApproved;
      cardsCopy[1].amount = response.data.manuallyApproved;
      cardsCopy[2].amount = response.data.rejected;
      cardsCopy[3].amount = response.data.pending;
      // Hide a link if there is no invoices to approve
      if (!response.data.pending || response.data.pending < 1) {
        cardsCopy[3].link = undefined;
      }
      setCards(cardsCopy);
    }
  };

  useEffectOnce(() => {
    getData();
  });

  return(
    <div>
      <h4 className="invoice_overview_top_cards_title">
        {t("components.blocks.invoice_overview_top_cards.title")}
      </h4>

      <div className="invoice_overview_top_cards_list">

        {cards.map((card) => (
          <React.Fragment
            key={card.id}
          >
            <InfoCard
              title={card.title}
              number={card.amount}
              numberType={InfoCardNumberTypeEnum.number}
              icon={card.image}
              iconHeight={card.iconHeight}
              link={card.link}
            />
          </React.Fragment>
        ))}

      </div>

    </div>
  );
};

export default forwardRef(InvoiceOverviewTopCards);
