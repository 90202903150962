import React, {useEffect, useState} from "react";
import "../SimpleComponents/Modal.css";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import Spacer from "../SimpleComponents/Spacer";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import Button from "../Buttons/Button";
import CsvTableItem from "../Lists/CsvTableItem";
import CsvWarningImage from "img/Dashboard/Employee/csv-warning.png";
import CsvTableIMage from "img/Dashboard/Employee/csv-table@2x.png";
import {toast} from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  clearUserMessages,
  getUsers,
  postManyEmployee
} from "../../redux/actions/usersActions";
import {
  getBranches,
  getDepartments, getDepartmentsAndBranches
} from "../../redux/actions/branchAndDepartmentActions";
import CSVReader from "react-csv-reader";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";
import validatePhoneNumber from "../../utils/phoneNumberValidation";

interface CustomProps {
  closeModal: () => void,
  branchesAndDep?: any,
  uploadState?: any,
  company?: any
}

const submitBtnStyles = {
  marginRight: "2rem"
};


function UploadCsvModal(
  {closeModal, branchesAndDep, uploadState, company}:CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(true);

  const [clearInput, setClearInput] = useState(false);

  const [error, setError] = useState<"" | "email">("");
  // Show modal after csv was uploaded
  const [csvIsUploaded, setCsvIsUploaded] = useState(false);

  useEffect(() => {
    if (clearInput && (document.getElementById("csvInput") as HTMLInputElement)) {
      (document.getElementById("csvInput") as HTMLInputElement).value = "";
    }
  }, [clearInput]);

  const [csvData, setCsvData] = useState<any>();
  const handleForce = (data: any, fileInfo: any) => {
    dispatch(getDepartmentsAndBranches());
    setCsvData(data);
    setCsvIsUploaded(true);
    setShowInfo(false);
  };

  // Errors from departments and branches validation in CsvTableItem
  const [depsAreValid, setDepsAreValid] = useState(true);

  // Show success/warning and close modal after submit
  useEffect(() => {
    if (uploadState && uploadState.success === "add_many_employee") {
      dispatch(getUsers({
        limit: 8,
        skip: 0
      }));
      toast.success(t("warnings.csv_upload_success"));
      dispatch(clearUserMessages());
      setClearInput(true);
    } else if (uploadState && uploadState.error === "add_many_employee") {
      toast.error(t("warnings.csv_upload_error"));
      dispatch(clearUserMessages());
      setClearInput(true);
    }
    //Need to disable it, because eslint says, that I need to add
    //all dependencies, but will cause unused re-renders
    // eslint-disable-next-line
	}, [uploadState])

  // Show warning state if data uploaded data not valid
  const [infoState, setInfoState] = useState(false);

  // take departments data
  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  // take branches data
  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  const validateDepartmentsAndBranches = () => {
    if (csvWithoutHeader && csvWithoutHeader.length) {
      let isAllowedBranchAndSelect = true;
      csvWithoutHeader.map((user:any) => {
        let userIsValid = false;
        branchesAndDep.forEach((branAndDep:any) => {
          let departmentIsValid = false;
          if (branAndDep.branchId === user.branch.value) {
            if (branAndDep.departmentId === user.department.value) {
              departmentIsValid = true;
            }
          }
          if (departmentIsValid) {
            userIsValid = departmentIsValid;
          }
        });
        if (!userIsValid) {
          isAllowedBranchAndSelect = false;
        }
      });
      setDepsAreValid(isAllowedBranchAndSelect);
    }
  };

  // Handle selects dropdown
  const handleBranchSelect = (selected:any, index:number) => {
    const newUserObj = {...csvWithoutHeader[index], branch: selected};
    const temporaryArr = csvWithoutHeader;
    temporaryArr[index] = newUserObj;
    setCsvWithoutHeader(temporaryArr);
    validateDepartmentsAndBranches();
  };

  const handleDepartmentSelect = (selected:any, index:number) => {
    const newUserObj = {...csvWithoutHeader[index], department: selected};
    const temporaryArr = csvWithoutHeader;
    temporaryArr[index] = newUserObj;
    setCsvWithoutHeader(temporaryArr);
    validateDepartmentsAndBranches();
  };

  // Handle delete user from List
  const deleteUser = (index:number) => {
    setCsvWithoutHeader(
      csvWithoutHeader.filter((item:any) => item.index !== index));
    validateDepartmentsAndBranches();
  };

  // Define lines with errors
  const [notValidUsers, setNotValidUsers] = useState<any[]>([]);

  // Validate email
  const validateEmail = (email:string) => {
    // eslint-disable-next-line max-len
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  useEffect(() => {
    if (csvData?.length && branchesAndDep && branchesAndDep?.length) {
      const requiredHeaders = ["firstName", "lastName", "email", "phoneNumber", "customIdentifier", "employmentType"];
      const headerIndexes: any = {
        "firstName": undefined,
        "lastName": undefined,
        "email": undefined,
        "phoneNumber": undefined,
        "customIdentifier": undefined,
        "employmentType": undefined
      };
      const indexArray:any = [];
      requiredHeaders.forEach((reqItem: string) => {
        csvData[0].forEach((header:string, i:number) => {
          if (reqItem === header.trim()) {
            headerIndexes[reqItem] = i;
            indexArray.push(i);
          }
        });
      });
      if (indexArray.length < 3) {
        setInfoState(true);
      }
      if (indexArray.length > 6) {
        setInfoState(true);
      }
      // Clear empty fields from csv like [""]
      const csvDataWithoutEmptyLines = csvData.map((item: string[]) => item.filter((string: string) => string.length > 0)).filter((item:any) => item.length);
      const cutData = csvDataWithoutEmptyLines.slice(1, csvDataWithoutEmptyLines.length);
      const finalArray:any = [];
      cutData.forEach((data:any, i:number) => {
        const userFinArray:any = [];
        indexArray.forEach((index:number) => {
          userFinArray.push(data[index]);
        });
        const userObj = {
          index: i,
          firstName: userFinArray[headerIndexes["firstName"]],
          lastName: userFinArray[headerIndexes["lastName"]],
          email: userFinArray[headerIndexes["email"]],
          phoneNumber: userFinArray[headerIndexes["phoneNumber"]],
          customIdentifier: userFinArray[headerIndexes["customIdentifier"]],
          employmentType: userFinArray[headerIndexes["employmentType"]],
          branch: {
            value: branchesAndDep[0].branchId || "",
            label:branchesAndDep[0].branchName || ""
          },
          department: {
            value: branchesAndDep[0].departmentId || "",
            label:branchesAndDep[0].departmentName || ""
          }
        };
        finalArray.push(userObj);
      });
      setCsvWithoutHeader(finalArray);
    }
  }, [csvData, branchesAndDep]);

  const validateUsers = (users:any) => {
    if (users && users.length && users[0].index === 0) {
      setNotValidUsers([]);
      const newArray:any[] = [];
      users.forEach((user:any) => {
        if (user.firstName && user.lastName && user.email) {
          if (
            !user.firstName.length
            || !user.lastName.length
            || !validateEmail(user.email)
            || (new getLunchSettings().isVirtualCard() && (!user.phoneNumber || !validatePhoneNumber(user.phoneNumber)))
          ) {
            let page = 1;
            let line = 1;
            page = Math.ceil((+user.index + 1) / 5);
            if (page === 1) {
              line = (+user.index + 1);
            } else {
              line = (+user.index + 1) - ((page - 1) * 5);
            }
            newArray.push({page, line});
          }
        } else {
          let page = 1;
          let line = 1;
          page = Math.ceil((+user.index + 1) / 5);
          if (page === 1) {
            line = (+user.index + 1);
          } else {
            line = (+user.index + 1) - ((page - 1) * 5);
          }
          newArray.push({page, line});
        }
      });
      setNotValidUsers(newArray);
    }
  };

  const [csvWithoutHeader, setCsvWithoutHeader] = useState<any>();

  // Validate after every change
  useEffect(() => {
    validateUsers(csvWithoutHeader);
  }, [csvWithoutHeader]);

  // handle Paginate
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = csvWithoutHeader?.slice(indexOfFirstPost, indexOfLastPost);

  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const saveEmployee = () => {
    const companyId = getUserCompany();
    const usersArray:any = [];
    csvWithoutHeader.forEach((user:any) => {
      usersArray.push({
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        phoneNumber: user.phoneNumber,
        customIdentifier: user.customIdentifier,
        employmentType: user.employmentType,
        companyId: companyId,
        departmentId: user.department.value,
        branchId: user.branch.value
      });
    });
    const postData = {
      companyId: companyId || "",
      users: usersArray
    };
    dispatch(postManyEmployee(postData));
    closeModal();
  };

  const handleValidationItemClick = (user:any) => {
    paginate(user.page);
  };

  return (
    <div style={{paddingTop: "3rem"}} className="modal_container_big white">
      <img
        onClick={() => {
          setClearInput(true);
          closeModal();
        }}
        className="close_popup_paddings"
        src={closeIcon} alt="close"
      />
      {showInfo
        ? <div className="csv_pop_wrapper">
          <div className="center">
            <img
              style={{maxWidth:"10vw"}}
              src={CsvWarningImage}
              alt="warning"
            />
          </div>
          <h3 className="text-center">
            {t("dashboard.employee.csv_modal.info.title")}
          </h3>
          <Spacer spacerHeight="2rem" />
          <p>{t("dashboard.employee.csv_modal.info.text")}</p>
          <Spacer spacerHeight="5px" />
          <div className="center">
            <table style={{width:"100%"}}>
              <thead>
                <tr>
                  <th>
                    <div className="center">
                      firstName
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      lastName
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      email
                    </div>
                  </th>
                  {company.company.virtualCard
                    ? <th>
                      <div className="center">
                        phoneNumber
                      </div>
                    </th>
                    : null
                  }
                  <th>
                    <div className="center">
                      customIdentifier
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      employmentType
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      Branch
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      Department
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="spacer" />
                <tr className="table-item">
                  <td>
                    <div className="center">
                      <p>Kunz</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>Holderman</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>holderman@vitlife.at</p>
                    </div>
                  </td>
                  {company.company.virtualCard
                    ? <td>
                      <div className="center">
                        <p>+4367771383407</p>
                      </div>
                    </td>
                    : null
                  }
                  <td>
                    <div className="center">
                      <p>cc8287f6-dea9-413f</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>partTime</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>Vienna</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>IT</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="center" style={{marginTop: "30px"}}>
            <div
              style={{ position: "relative", marginRight: "1rem", color: "#fd552b", border: "1px solid #fd552b"}}
              className="btn icon outlined"
            >
              <div className="row">
                <p>{t("dashboard.employee.csv_modal.info.btn")}</p>
              </div>
              <CSVReader
                inputId={"csvInput"}
                cssClass="csv_reader"
                cssLabelClass="csv_label"
                cssInputClass="csv_upload_wrapper"
                label="Select CSV "
                onFileLoaded={handleForce}
              />
            </div>
          </div>
        </div>
        : infoState || !csvData?.length
          ?
          <div className="csv_pop_wrapper">
            <div className="center">
              <img
                style={{maxWidth:"10vw"}}
                src={CsvWarningImage}
                alt="warning"
              />
            </div>
            <h3 className="text-center">
              {t("dashboard.employee.csv_error_title")}
            </h3>
            <p>
              {t("dashboard.employee.csv_error_text_1")}
              <strong>firstName, </strong>
              <strong>lastName </strong>
              <span>{t("dashboard.employee.csv_error_text_2")}</span>
              <strong>email </strong>
              <span>{t("dashboard.employee.csv_error_text_3")}</span>
            </p>
            <Spacer spacerHeight="2rem" />
            <p>{t("dashboard.employee.csv_error_text_4")}</p>
            <Spacer spacerHeight="5px" />
            <div className="center">
              <img
                style={{width:"100%"}}
                src={CsvTableIMage}
                alt="table_example"
              />
            </div>
          </div>
          :
          <>
            <div className="space-between_plans">
              <h2>{t("dashboard.employee.csv_title")}</h2>
              <Button
                disabled={notValidUsers.length > 0 || !depsAreValid}
                buttonStyles={submitBtnStyles}
                buttonType="primary"
                buttonLabel={t("dashboard.plans.add_employee")}
                buttonHandler={saveEmployee}
              />
            </div>
            <Spacer spacerHeight="1rem" />
            <Spacer spacerHeight="1rem" />
            <div className="table_wrapper">
              {csvData?.length
                ?
                <>
                  <div className="table_box">
                    <div className="table_wrapper" >
                      <table style={{width:"100%"}}>
                        <thead>
                          <tr>
                            <th className="row">
                              <Spacer spacerWidth="1rem" />
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_1")}
                            </th>
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_2")}
                            </th>
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_3")}
                            </th>
                            {company.company.virtualCard
                              &&
                              <th>
                                {/* eslint-disable-next-line max-len */}
                                {t("dashboard.employee.employee_csv_list_th_phone_number")}
                              </th>
                            }
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_custom_identifier")}
                            </th>
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_employment_type")}
                            </th>
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_4")}
                            </th>
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_5")}
                            </th>
                            <th>
                              {/* eslint-disable-next-line max-len */}
                              {t("dashboard.employee.employee_csv_list_th_6")}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPosts
                        && currentPosts.length
                        && currentPosts.map((item:any, index:number) => (
                          <React.Fragment
                            key={`${currentPage}${index}`}>
                            <CsvTableItem
                              index={index}
                              setCsvWithoutHeader={
                                (newValues:any) => {
                                  setCsvWithoutHeader(newValues);
                                }}
                              data={csvWithoutHeader}
                              item={item}
                              handleBranchSelect=
                                {handleBranchSelect}
                              handleDepartmentSelect=
                                {handleDepartmentSelect}
                              deleteUser={deleteUser}
                              currentPage={currentPage}
                              withAction={true}
                            />
                          </React.Fragment>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts=
                      { csvWithoutHeader && csvWithoutHeader.length}
                    paginate={paginate}
                    outsideControl={currentPage}
                  />
                </>
                :
                <h2>Loading</h2>
              }
            </div>
          </>
      }
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    uploadState: state.users,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(UploadCsvModal);
