import React, {useEffect, useState} from "react";
import "./PaymentsOverviewHeaderContainerCardInfoTab.css";
import {useTranslation} from "react-i18next";
import totalCardsImage from "img/Dashboard/Payments/Overview/Header/cards.png";
import totalUsersImage from "img/Dashboard/Payments/Overview/Header/users.png";
import totalPercentageImage from "img/Dashboard/Payments/Overview/Header/percentage.png";
import accountImage from "img/Dashboard/Payments/Overview/Header/chart.png";
import availableImage from "img/Dashboard/Payments/Overview/Header/card_accept.png";
import axios from "axios";
import environment from "../../../../../../../environment";
import {urls} from "../../../../../../../api/urls";
import {getToken} from "../../../../../../../utils/cookies/tokensCookie";
import InfoCard, {InfoCardNumberTypeEnum} from "../../../../../../Containers/InfoCard/InfoCard";

interface ICard {
  id: string;
  title: string;
  number: number | undefined;
  numberType: InfoCardNumberTypeEnum.number | InfoCardNumberTypeEnum.amount | InfoCardNumberTypeEnum.percentage;
  icon: string;
  iconWidth: string;
  iconHeight: string;
}

interface IProps {
  showLogin: () => void;
}

export default function PaymentsOverviewHeaderContainerCardInfoTab(props:IProps) {
  const { t } = useTranslation();

  const [cards, setCards] = useState<ICard[]>([
    {
      id: "1",
      title: t("dashboard.payments.overview.header_component.tabs_content.card_info.cards.total_cards"),
      number: undefined,
      icon: totalCardsImage,
      iconWidth: "25px",
      iconHeight: "21px",
      numberType: InfoCardNumberTypeEnum.number
    },
    {
      id: "2",
      title: t("dashboard.payments.overview.header_component.tabs_content.card_info.cards.total_users"),
      number: undefined,
      icon: totalUsersImage,
      iconWidth: "26px",
      iconHeight: "20px",
      numberType: InfoCardNumberTypeEnum.number
    },
    {
      id: "3",
      title: t("dashboard.payments.overview.header_component.tabs_content.card_info.cards.total_percentage"),
      number: undefined,
      icon: totalPercentageImage,
      iconWidth: "18px",
      iconHeight: "18px",
      numberType: InfoCardNumberTypeEnum.percentage
    },
    {
      id: "4",
      title: t("dashboard.payments.overview.header_component.tabs_content.card_info.cards.account"),
      number: undefined,
      icon: accountImage,
      iconWidth: "20px",
      iconHeight: "20px",
      numberType: InfoCardNumberTypeEnum.amount
    },
    {
      id: "5",
      title: t("dashboard.payments.overview.header_component.tabs_content.card_info.cards.available"),
      number: undefined,
      icon: availableImage,
      iconWidth: "22px",
      iconHeight: "20px",
      numberType: InfoCardNumberTypeEnum.amount
    }
  ]);

  const getCardsData = async () => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    try {
      const response = await axios.get(`${environment.baseUrl}${urls.payments.stats.infoCards}?weavrToken=${weavrToken}`, {
        headers: {Authorization: `Bearer ${token}`}
      });
      if (response.data) {
        const keys = ["totalCards", "totalUsers", "totalPercentage", "account", "availableAmount"];
        const newAmountsForCards = cards.map((card: ICard, idx: number) => {
          return {...card, number: response.data[keys[idx]]};
        });
        setCards(newAmountsForCards);
      }
    } catch (e: any) {
      if (e.response.data.message === "weavr_login") {
        props.showLogin();
      }
    }
  };

  useEffect(() => {
    getCardsData();
  }, []);

  return(
    <div className="payment_overview_header_container_tab_content_container">

      <h3 className="payment_overview_header_container_tab_content_container_title">
        {t("dashboard.payments.overview.header_component.tabs_content.card_info.title")}
      </h3>

      <p className="payment_overview_header_container_tab_content_container_sub_title">
        {t("dashboard.payments.overview.header_component.tabs_content.card_info.sub_title")}
      </p>

      <div className="payment_overview_header_container_tab_content_container_cards_container">
        {cards.map((card: ICard) => (
          <React.Fragment
            key={card.id}
          >
            <InfoCard
              title={card.title}
              number={card.number}
              numberType={card.numberType}
              icon={card.icon}
              iconWidth={card.iconWidth}
              iconHeight={card.iconHeight}
            />
          </React.Fragment>
          /*<div
            key={card.id}
            className="payment_overview_header_container_tab_content_container_card"
          >
            <div className="payment_overview_header_container_tab_content_container_card_grid" style={{height: "100%"}}>

              <div className="payment_overview_header_container_tab_content_container_card_content">

                <h6>
                  {card.title}
                </h6>

                {card.number === undefined
                  ? <LoadingText
                    height="40px"
                    width="30%"
                    borderRadius="5px"
                    opacity="opacity(0.5)"
                  />
                  : <h5>
                    {card.id === "3"
                      ? `${card.number}%`
                      : card.number
                    }
                  </h5>
                }

              </div>

              <div className="payment_overview_header_container_tab_content_container_card_icon_container">
                <img
                  src={card.icon}
                  alt={`icon_${card.title}`}
                  style={{
                    width: card.iconWidth,
                    height: card.iconHeight
                  }}
                />
              </div>

            </div>
          </div>*/
        ))}
      </div>

    </div>
  );
}
