export default function validatePhoneNumber(phoneNumber: string) {
  const emptyStringRegex = /^\S*$/;
  // Check if there is any empty spaces in string
  if (!emptyStringRegex.test(phoneNumber)) {
    return false;
  } else {
    const austrianRegex = /((\+43)s?)((\d{3})\s?)\d{7,8}$/;
    const germanRegex = /\(?\+\(?49\)?[ ()]?([- ()]?\d[- ()]?){11,11}(?!\d)/;

    return austrianRegex.test(phoneNumber) || germanRegex.test(phoneNumber);
  }
}
