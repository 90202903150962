import {Dispatch} from "redux";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import {
  CLEAR_PAYMENTS_MESSAGES,
  CREATE_PAYMENTS_ERROR,
  GET_CARD,
  GET_CARD_TRANSACTIONS,
  GET_CARDS,
  PAYMENTS_ERROR,
  paymentsTypes,
  SHOW_PAYMENTS_LOADING
} from "../types/paymentsTypes";
import {getToken} from "../../utils/cookies/tokensCookie";
import {urls} from "../../api/urls";

interface IGetTransactionsQuery {
  id?:string;
  limit:number;
  skip:number;
  sortBy:string;
  backoffice?: boolean;
  [key: string]: any;
}

// Get cards for company
export const getCards = (data: {
  companyId:string;
  skip: number;
  limit: number;
  startDate?: string;
  endDate?: string;
  search?: string;
}) => async (dispatch: Dispatch<paymentsTypes>) => {
  const savedToken = await getToken(true);

  const dynamicParams:any = {
    token: savedToken,
    companyId: data.companyId,
    limit: data.limit || 8,
    skip: data.skip || 0,
    search: data.search || ""
  };

  if (data.startDate) {
    dynamicParams.startDate = data.startDate;
  }

  if (data.endDate) {
    dynamicParams.endDate = data.endDate;
  }

  if (savedToken) {
    await new reduxRequestHandler({
      url: urls.payments.cards,
      dynamicParams,
      successDispatchType: GET_CARDS,
      errorDispatchType: PAYMENTS_ERROR,
      errorPayload: "get_cards"
    }).get(dispatch);
  }

};

// Get one card
export const getCard = (id:string) => async (dispatch: Dispatch<paymentsTypes>) => {
  const savedToken = await getToken(true);
  await new reduxRequestHandler({
    url: `${urls.payments.cards}/${id}?token=${savedToken}`,
    successDispatchType: GET_CARD,
    errorDispatchType: PAYMENTS_ERROR,
    errorPayload: "get_card"
  }).get(dispatch);
};

// Get card transactions
export const getTransactions = (filters:IGetTransactionsQuery) =>
  async (dispatch: Dispatch<paymentsTypes>) => {
    const savedToken = await getToken(true);
    const dynamicParams = {
      token: savedToken,
      limit: filters.limit || 8,
      skip: filters.skip || 0,
      sortBy: filters.sortBy || "DESC",
      backoffice: filters.backoffice
    };
    await new reduxRequestHandler({
      url: `${urls.payments.transactions}/${filters.id}`,
      dynamicParams,
      successDispatchType: GET_CARD_TRANSACTIONS,
      errorDispatchType: PAYMENTS_ERROR,
      errorPayload: "get_transactions"
    }).get(dispatch);
  };

// Show loading
export function showPaymentsLoading() {
  return {
    type: SHOW_PAYMENTS_LOADING
  };
}

// Clear messages
export function clearPaymentsMessages() {
  return {
    type: CLEAR_PAYMENTS_MESSAGES
  };
}

// Create error messages
export function createPaymentsError() {
  return {
    type: CREATE_PAYMENTS_ERROR
  };
}
