import React, { useEffect, useState } from "react";
import "../SimpleComponents/Modal.css";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import Spacer from "../SimpleComponents/Spacer";
import Pagination from "../SimpleComponents/Pagination";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import Button from "../Buttons/Button";
import CsvTableItem from "../Lists/CsvTableItem";
import CsvWarningImage from "img/Dashboard/Employee/csv-warning.png";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import {
  clearUserMessages,
  getUsers
} from "../../redux/actions/usersActions";
import { getUserCompany } from "../../utils/cookies/userCookies/userCookies";
import CSVReader from "react-csv-reader";
import axios from "axios";
import { getToken } from "utils/cookies/tokensCookie";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { urls } from "api/urls";
import { HrZoneAuthInterface } from "Components/Sidebar/SideBarItemsClass";

interface CustomProps {
  closeModal: () => void;
  branchesAndDep?: any;
  uploadState?: any;
  hrZoneAuth: HrZoneAuthInterface
}

const submitBtnStyles = {
  marginRight: "2rem"
};

function HrZoneUploadCsvCompanyModal({
  closeModal,
  branchesAndDep,
  uploadState,
  hrZoneAuth
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(true);

  const [clearInput, setClearInput] = useState(false);

  const [error, setError] = useState<"" | "email">("");
  // Show modal after csv was uploaded
  const [csvIsUploaded, setCsvIsUploaded] = useState(false);

  useEffect(() => {
    if (
      clearInput &&
      (document.getElementById("csvInput") as HTMLInputElement)
    ) {
      (document.getElementById("csvInput") as HTMLInputElement).value = "";
    }
  }, [clearInput]);

  const [csvData, setCsvData] = useState<any>();
  const handleForce = (data: any, fileInfo: any) => {
    // dispatch(getDepartmentsAndBranches());

    data = data.map((dataset: any) => {
      let correctData = true;
	  
      if(dataset.length < 4){
        correctData = false;
      }
      //   else{
      //     //check every entry in this dataset if its filled
      //     dataset.map((set:any) => {
      //       if(!(set.length > 0)){
      //         correctData = false;
      //       }
      //     });
      //   }

      //return dataset if its correct
      if(correctData){
        return dataset;
      }

    });
    //filter undefined
    data = data.filter((dataset: any) => {
      return dataset !== undefined;
    });

    setCsvData(data);
    setCsvIsUploaded(true);
    setShowInfo(false);
  };

  // Show warning state if data uploaded data not valid
  const [infoState, setInfoState] = useState(false);

  // take departments data
  //   useEffect(() => {
  //     dispatch(getDepartments());
  //   }, [dispatch]);

  // take branches data
  //   useEffect(() => {
  //     dispatch(getBranches());
  //   }, [dispatch]);

  // Handle delete user from List
  const deleteUser = (index: number) => {
    setCsvWithoutHeader(
      csvWithoutHeader.filter((item: any) => item.index !== index)
    );
  };

  // Define lines with errors
  const [notValidUsers, setNotValidUsers] = useState<any[]>([]);


  useEffect(() => {
    if (csvData?.length) {
      const requiredHeaders = ["name", "adresse","plz", "stadt"];
      const indexArray: any = [];
      requiredHeaders.forEach((reqItem: string) => {
        csvData[0].forEach((header: string, i: number) => {
          if (reqItem === header.trim()) {
            indexArray.push(i);
          }
        });
      });

	  
      if (indexArray.length !== requiredHeaders.length) {
        setInfoState(true);
      }
      const cutData = csvData.slice(1, csvData.length);
      const finalArray: any = [];
      cutData.forEach((data: any, i: number) => {
        const userFinArray: any = [];
        indexArray.forEach((index: number) => {
          userFinArray.push(data[index]);
        });
        const userObj = {
          index: i,
          officeName: userFinArray[0],
          officeStreet: userFinArray[1],
          officeZip: userFinArray[2],
          officeCity: userFinArray[3]
        };
        finalArray.push(userObj);
      });
      setCsvWithoutHeader(finalArray);

    }
  }, [csvData]);

  

  const [csvWithoutHeader, setCsvWithoutHeader] = useState<any>();

  // Validate after every change
  //   useEffect(() => {
  //     validateUsers(csvWithoutHeader);
  //   }, [csvWithoutHeader]);

  // handle Paginate
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = csvWithoutHeader?.slice(
    indexOfFirstPost,
    indexOfLastPost
  );
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  const saveEmployee = () => {
    const companyId = getUserCompany();
    const usersArray: any = [];
    csvWithoutHeader.forEach((user: any) => {
      usersArray.push({
        officeName: user.officeName,
        officeStreet: user.officeStreet,
        officeZip: user.officeZip,
        officeCity: user.officeCity
      });
    });


    const fetch = async () => {
      const res: any = await axios.post(
        urls.hrZone.base + "createOffice",
        {
          hr_email: uploadState.userInfo.user.email,
		  sessionKey: hrZoneAuth.sessionKey,
          offices: usersArray
        },
        {
          headers: { Authorization: `Bearer ${getToken()}` },
          timeout: 10000
        }
      );
      if (res.status == 200 ) {

        toast.success(
          <CustomToastContainer message={t("hrzone.toastMessages.csv.create.success")} status={1} />,
          {
            autoClose: 5000
          }
        );
      } else {
        toast.error(
          <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
          {
            autoClose: 5000
          }
        );
      }
    };
    if (usersArray.length > 0) {
      fetch();
    }

    closeModal();
  };

  const handleValidationItemClick = (user: any) => {
    paginate(user.page);
  };

  return (
    <div style={{ paddingTop: "3rem" }} className="modal_container_big white">
      <img
        onClick={() => {
          setClearInput(true);
          closeModal();
        }}
        className="close_popup_paddings"
        src={closeIcon}
        alt="close"
      />
      {showInfo ? (
        <div className="csv_pop_wrapper">
          <div className="center">
            <img
              style={{ maxWidth: "10vw" }}
              src={CsvWarningImage}
              alt="warning"
            />
          </div>
          <h3 className="text-center">
            {t("dashboard.employee.csv_modal.info.title")}
          </h3>
          <Spacer spacerHeight="2rem" />
          <p>{t("dashboard.employee.csv_modal.info.text")}</p>
          <Spacer spacerHeight="5px" />
          <div className="center">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>
                    <div className="center">Name</div>
                  </th>
                  <th>
                    <div className="center">Adresse</div>
                  </th>
                  <th>
                    <div className="center">PLZ</div>
                  </th>
				  <th>
                    <div className="center">Stadt</div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="spacer" />
                <tr className="table-item">
                  <td>
                    <div className="center">
                      <p>Corplife</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>Universitätsstraße 9</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>1090</p>
                    </div>
                  </td>
				  <td>
                    <div className="center">
                      <p>Wien</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="center" style={{ marginTop: "30px" }}>
            <div
              style={{
                position: "relative",
                marginRight: "1rem",
                color: "#fd552b",
                border: "1px solid #fd552b"
              }}
              className="btn icon outlined"
            >
              <div className="row">
                <p>{t("dashboard.employee.csv_modal.info.btn")}</p>
              </div>
              <CSVReader
                inputId={"csvInput"}
                cssClass="csv_reader"
                cssLabelClass="csv_label"
                cssInputClass="csv_upload_wrapper"
                label="Select CSV "
                onFileLoaded={handleForce}
              />
            </div>
          </div>
        </div>
      ) : infoState || !csvData?.length ? (
        <div className="csv_pop_wrapper">
          <div className="center">
            <img
              style={{ maxWidth: "10vw" }}
              src={CsvWarningImage}
              alt="warning"
            />
          </div>
          <h3 className="text-center">
            {t("dashboard.employee.csv_error_title")}
          </h3>
          <p>
            {t("dashboard.employee.csv_error_text_1")}
            <strong>Name, </strong>
            <strong>Adresse </strong>
            <strong>PLZ </strong>
            <span>{t("dashboard.employee.csv_error_text_2")}</span>
            
            <strong>Stadt </strong>
            <span>{t("dashboard.employee.csv_error_text_3")}</span>
			
          </p>
          <Spacer spacerHeight="2rem" />
          <p>{t("dashboard.employee.csv_error_text_4")}</p>
          <Spacer spacerHeight="5px" />
          <div className="center">
		  <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>
                    <div className="center">Name</div>
                  </th>
                  <th>
                    <div className="center">Adresse</div>
                  </th>
                  <th>
                    <div className="center">PLZ</div>
                  </th>
                  <th>
                    <div className="center">Stadt</div>
                  </th>
				  {/* <th>
                    <div className="center">office</div>
                  </th> */}
                </tr>
              </thead>
              <tbody>
                <tr className="spacer" />
                <tr className="table-item">
                  <td>
                    <div className="center">
                      <p>Corplife</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>Universitätsstraße 9</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>1090</p>
                    </div>
                  </td>
				  <td>
                    <div className="center">
                      <p>Wien</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ) : (
        <>
          <div className="space-between_plans">
            <h2>{t("dashboard.employee.csv_title")}</h2>
            <Button
              disabled={notValidUsers.length > 0}
              buttonStyles={submitBtnStyles}
              buttonType="primary"
              buttonLabel={t("dashboard.plans.add_employee")}
              buttonHandler={saveEmployee}
            />
          </div>
          <Spacer spacerHeight="1rem" />
          <Spacer spacerHeight="1rem" />
          <div className="table_wrapper">
            {csvData?.length ? (
              <>
                <div className="table_box">
                  <div className="table_wrapper">
                    <table style={{ width: "100%" }}>
                      <thead>
                        <tr>
                          <th className="row">
                            <Spacer spacerWidth="1rem" />
                            {/* eslint-disable-next-line max-len */}
                            {/* {t("dashboard.employee.employee_csv_list_th_1")} */}
							Name
                          </th>
                          <th>
                            {/* eslint-disable-next-line max-len */}
                            {/* {t("dashboard.employee.employee_csv_list_th_2")} */}
							Adresse
                          </th>
                          <th>
                            {/* eslint-disable-next-line max-len */}
                            {/* {t("dashboard.employee.employee_csv_list_th_3")} */}
							PLZ
                          </th>
						  <th>
							  Stadt
							  </th>
                        </tr>
                      </thead>
                      <tbody>
                        {currentPosts &&
                          currentPosts.length &&
                          currentPosts.map((item: any, index: number) => (
                            <React.Fragment key={`${currentPage}${index}`}>
                              <CsvTableItem
                                index={index}
                                setCsvWithoutHeader={(newValues: any) => {
                                  setCsvWithoutHeader(newValues);
                                }}
                                data={csvWithoutHeader}
                                item={item}
                                handleBranchSelect={() => ({})}
                                handleDepartmentSelect={() => ({})}
                                deleteUser={deleteUser}
                                currentPage={currentPage}
                              />
                            </React.Fragment>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Pagination
                  postsPerPage={postsPerPage}
                  totalPosts={csvWithoutHeader && csvWithoutHeader.length}
                  paginate={paginate}
                  outsideControl={currentPage}
                />
              </>
            ) : (
              <h2>Loading</h2>
            )}
          </div>
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    uploadState: state.users,
    hrZoneAuth: state.hrZone
  };
};


export default connect(mapStateToProps, null)(HrZoneUploadCsvCompanyModal);