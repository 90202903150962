import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import de from "date-fns/locale/de";
import { useTranslation } from "react-i18next";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import {changeInvoicesFiltering} from "../../redux/actions/filteringActions";
import {getInvoices, showInvoicesLoading} from "../../redux/actions/invoicesActions";

import "./FilterDatePicker.css";

import {DateRange} from "react-date-range";
import Button from "../Buttons/Button";

interface DateObject {
  startDate: Date,
  endDate: Date
}

interface CustomProps {
  startDate?: any;
  endDate?: any;
  handleChange: (dateObject: any) => void
}

function FilterDatePicker({startDate, endDate, handleChange}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const calendarRef:any = useRef();

  // Show DatePicker dropdown
  const [showDatePicker, setShowDatePicker] = useState(false);

  // Detect click outside for close menu

  const filtersDateRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!filtersDateRef.current?.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [state, setState] = useState<any>([
    {
      startDate: new Date(startDate || new Date()),
      endDate: new Date(endDate || new Date()),
      key: "selection"
    }
  ]);

  const [formattedDate, setFormattedDate] = useState([{
    startDate: "--", //startDate
    endDate: "--", //endDate
    key: "selection"
  }]);

  const datePickerHandler = (item:any) => {
    setState(item);
    const newDate = [{
      startDate: moment(item[0].startDate)
        .format("YYYY-MM-DD"),
      endDate: moment(item[0].endDate)
        .format("YYYY-MM-DD"),
      key: "selection"
    }];
    setFormattedDate(newDate);
  };
  const selectedDate = `${moment(formattedDate[0].startDate).format("DD.MM.YYYY")} - ${moment(formattedDate[0].endDate).format("DD.MM.YYYY")}`;

  // handle Open/Close dropdown
  const handleDropdown = () => {
    setShowDatePicker(!showDatePicker);
    setFormattedDate([{
      startDate: startDate,
      endDate: endDate,
      key: "selection"
    }]);
  };

  useEffect(() => {
    if (showDatePicker) {
      calendarRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [showDatePicker]);

  // handle submit dates
  const handleSubmit = () => {
    setShowDatePicker(!showDatePicker);
    handleChange({
      startDate: formattedDate[0].startDate,
      endDate: formattedDate[0].endDate
    });
  };



  return (
    <div ref={filtersDateRef} className="filter_date_box">
      <div onClick={handleDropdown} className="filter_date_space">
        <p>
          {formattedDate[0].startDate === "--"
            ? t("dashboard.overview.date_place")
            : selectedDate
          }
        </p>
        <svg height="20" width="20" viewBox="0 0 20 20">
          {/* eslint-disable-next-line max-len */}
          <path id="arrow_svg" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </div>
      {showDatePicker
			&&
			<div className="filter_date_drop shadow" ref={calendarRef}>
			  <DateRange
			    editableDateInputs={true}
			    onChange={(item:any) => datePickerHandler([item.selection])}
			    moveRangeOnFirstSelection={false}
			    ranges={state}
			    direction="horizontal"
			    showDateDisplay={false}
			    showPreview={false}
			    locale={de}
			  />
			  <div className="filter_date_btn_box">
			    <Button
			      buttonType="secondary"
			      buttonLabel=
			        {t("dashboard.overview.date_btn_1")}
			      buttonHandler={handleDropdown}
			    />
			    <Button
			      buttonType="primary"
			      buttonLabel=
			        {t("dashboard.overview.date_btn_2")}
			      buttonHandler={handleSubmit}
			    />
			  </div>
			  <div className="filter_date_clear_btn">
			    <p
			      onClick={() => {
			        setState([{
			          startDate: new Date(),
			          endDate: new Date(),
			          key: "selection"
			        }]);
			        handleChange({
			          startDate: "",
			          endDate: ""
			        });
			        setFormattedDate([{
			          startDate: "--",
			          endDate: "--",
			          key: "selection"
			        }]);
			      }}
			    >{t("dashboard.overview.date_btn_clear")}</p>
			  </div>
			</div>
      }
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    filteringState: state.filters.invoiceFilters
  };
};

export default connect(mapStateToProps, null)(FilterDatePicker);
