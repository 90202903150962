import "./DailyComparisons.css";

import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import { getDailyChartData } from "../../redux/actions/chartsActions";
import { RootState } from "../../redux/reducers/rootReducer";

interface IProps {
  chartData?: any;
  company?: any;
}

function DailyComparisons(props:IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Get chart data after component is mounted
  useEffect(() => {
    dispatch(getDailyChartData());

    //Taking last 9 days
    const datesArray: any = [];
    const labelsArray: any = [];
    const current = moment();
    let n = 9;
    while (n > 0) {
      datesArray.push(current.format("D"));
      labelsArray.push(current.format("DD.MM"));
      current.subtract(1, "day");
      n--;
    }
    setChartDays(datesArray.reverse());
    setChartLabels(labelsArray.reverse());
  }, []);

  // Manipulating chart data
  const [restaurantData, setRestaurantData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [supermarketData, setSupermarketData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [chartDays, setChartDays] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

  const [chartLabels, setChartLabels] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);

  // Manipulating data for chart
  useEffect(() => {
    const restaurantArr: number[] = [];
    const supermarketArr: number[] = [];

    if (props.chartData && props.chartData.datasets) {
      chartDays.forEach((item: number) => {
        let restaurantAmount = 0;
        let supermarketAmount = 0;
        Object.keys(props.chartData.datasets).forEach((serverDate: string) => {
          const date = moment(serverDate, "YYYY-MM-DD");
          const day = date.date();
          if (day === +item) {
            props.chartData.datasets[serverDate].forEach((obj: any) => {
              if (obj.type === "1") {
                restaurantAmount = +obj.amount;
              } else {
                supermarketAmount = +obj.amount;
              }
            });
          }
        });
        restaurantArr.push(restaurantAmount);
        supermarketArr.push(supermarketAmount);
      });
      setRestaurantData(restaurantArr);
      setSupermarketData(supermarketArr);
    }
  }, [props.chartData, t, chartDays]);

  // Get maximal amount and step for chart

  const maxRestaurant = Math.max(...restaurantData);
  const maxSupermarket = Math.max(...supermarketData);
  const maxLimit =
    maxRestaurant > maxSupermarket ? maxRestaurant : maxSupermarket;
  const step = Math.round(maxLimit / 5);

  const data = {
    labels: chartLabels,
    datasets: [
      {
        borderWidth: 1,
        label: t("charts.daily_chart.restaurant"),
        data: restaurantData,
        fill: false,
        backgroundColor: "rgb(255, 82, 66 ,0.2)",
        borderColor: "#FF5242",
        pointBackgroundColor: "#FF5242",
        yAxisID: "y-axis-1",
        lineTension: 0,
      },
      props.company?.company?.isSupermarketAllowed
        ? {
          borderWidth: 1,
          label: t("charts.daily_chart.supermarket"),
          data: supermarketData,
          fill: false,
          backgroundColor: "rgb(100, 87, 232 ,0.2)",
          borderColor: "#6457E8",
          pointBackgroundColor: "#6457E8",
          yAxisID: "y-axis-2",
          lineTension: 0,
        }
        : {}
    ],
  };

  const options = {
    elements: {
      point: {
        radius: 2,
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          const item = data["datasets"][tooltipItem.datasetIndex];
          return `${item.label} ${item.data && item.data[tooltipItem.index].toFixed(2)} €`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
            borderDash: [3, 3],
            color: "rgb(100, 87, 232 ,0.1)",
          },
          ticks: {
            suggestedMax: maxLimit || 23,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: step,
            callback: function (value: any, index: any, values: any) {
              return `€ ${value}`;
            },
          },
        },
        {
          type: "linear",
          display: false,
          position: "right",
          id: "y-axis-2",
          ticks: {
            suggestedMax: maxLimit || 23,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: step,
            callback: function (value: any, index: any, values: any) {
              return `€ ${value}`;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            fontSize: 10,
            fontColor: "#7C828A",
          },
        },
      ],
    },
  };

  return (
    <div className="dashboard_box_padding">
      <div>
        <div className="row summary_row">
          <div className="summary_dot red_summary" />
          <p>{t("dashboard.home.summary_opt_1")}</p>
        </div>
        {props.company?.company?.isSupermarketAllowed
          ? <div className="row summary_row">
            <div className="summary_dot blue_summary" />
            <p>{t("dashboard.home.summary_opt_2")}</p>
          </div>
          : null
        }
      </div>
      <h3 style={{ marginLeft: "15px" }}>
        {t("dashboard.home.comparisons_title")}
      </h3>
      <div className="comparisons_chart_container">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    chartData: state.charts.dailyChartData,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(DailyComparisons);
