import environment from "../../../environment";
import {urls} from "../../urls";
import axios from "axios";
import {getToken} from "../../../utils/cookies/tokensCookie";
import {
  IChangeAuthorisedUserPhoneNumberRequestParams, IChangeAuthorisedUserPhoneNumberResponse,
  IGetDepositTransactionsRequestParams,
  IGetDepositTransactionsResponse
} from "./payments.interface";

export class paymentsService {
  constructor() {}

  async enrollDevice(token: string): Promise<any> {
    const fullUrl = `${environment.baseUrl}${urls.payments.sendSmsVerification}`;
    const userToken = await getToken();
    return await axios.post(
      fullUrl,
      {token: token},
      {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data.message
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false,
          data: []
        };
      });
  }

  async sendStepUp(token: string): Promise<any> {
    const fullUrl = `${environment.baseUrl}${urls.payments.sendStepUp}`;
    const userToken = await getToken();
    return await axios.post(
      fullUrl,
      {token: token},
      {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false,
          data: err.response.data || ""
        };
      });
  }

  async verifyStepUp(token: string, verificationCode: string): Promise<any> {
    const fullUrl = `${environment.baseUrl}${urls.payments.verifyStepUp}`;
    const userToken = await getToken();
    return await axios.post(
      fullUrl,
      {
        token: token,
        verificationCode: verificationCode
      },
      {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false,
          data: err.response.data || ""
        };
      });
  }

  async getDepositTransaction(data: IGetDepositTransactionsRequestParams): Promise<IGetDepositTransactionsResponse> {
    const fullUrl = `${environment.baseUrl}${urls.payments.getDepositTransactions}`;
    const userToken = getToken();
    const weavrToken = getToken(true);
    return await axios.get(
      fullUrl,
      {
        headers: { Authorization: `Bearer ${userToken}` },
        params: {...data, weavrToken: weavrToken}
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

  async changeAuthorisedUserPhoneNumber(data: IChangeAuthorisedUserPhoneNumberRequestParams): Promise<IChangeAuthorisedUserPhoneNumberResponse> {
    const fullUrl = `${environment.baseUrl}${urls.payments.changeAuthorisedUserPhoneNumber}`;
    const userToken = getToken();
    const weavrToken = getToken(true);
    return await axios.put(
      fullUrl,
      {...data, weavrToken: weavrToken},
      {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false,
          data: err.response.data
        };
      });
  }

}
