import React, {useEffect, useRef, useState} from "react";
import "./Tooltip.css";

interface IProps {
  text: string;
  containerOffsetTop: number;
  opacity: number;
}

export default function TooltipBox(props:IProps) {
  const tooltipRef:any = useRef<HTMLInputElement>(null);

  const [styleTopOffset, setStyleTopOffset] = useState(0);
  const [tooltipHeight, setTooltipHeight] = useState(0);
  const [arrowDirection, setArrowDirection] = useState("down");

  useEffect(() => {
    if (tooltipRef && tooltipRef.current) {
      setTooltipHeight(tooltipRef.current.clientHeight);
    }
  }, []);

  useEffect(() => {
    if ((props.containerOffsetTop + 20) > tooltipHeight) {
      setStyleTopOffset(-tooltipHeight - 10);
      setArrowDirection("down");
    } else {
      setStyleTopOffset(tooltipHeight + 10);
      setArrowDirection("up");
    }
  }, [props.containerOffsetTop, tooltipRef.current]);

  return(
    <div
      className="tooltip_box"
      ref={tooltipRef}
      style={{
        top: `${styleTopOffset}px`,
        opacity: props.opacity
      }}
    >
      {arrowDirection === "up"
        ? <div className="arrow-up"/>
        : null
      }
      {arrowDirection === "down"
        ? <div className="arrow-down"/>
        : null
      }
      <p>{props.text}</p>
    </div>
  );
}
