import ContentContainer from "Components/Containers/ContentContainer";
import { connect, useDispatch } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Button from "Components/Buttons/Button";
import React, { useEffect, useRef, useState } from "react";
import TableLoading from "Components/LoadingPlaceholders/TableLoading";
import {
  getHrZoneHrUsers,
  showUserLoading
} from "redux/actions/usersActions";
import HrZoneEmployeeList from "Components/Lists/HrZoneEmployeeList";
import Modal from "react-modal";
import SimpleInput from "Components/SimpleComponents/SimpleInput";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import axios from "axios";
import { urls } from "api/urls";
import { getToken } from "utils/cookies/tokensCookie";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { modalStyles } from "utils/modalStyles";
import HrZoneUploadCsvEmployeeModal from "Components/Modals/HrZoneUploadCsvEmployeeModal";
import { useTranslation } from "react-i18next";


function HrEmployees(props: any) {
  const dispatch = useDispatch();

  const [showInviteModal, setInviteShowModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);

  const [filter, setFilter] = useState<any>({
    status: undefined,
    firstName: "",
    lastName: "",
    email: ""
  });

  const [modalUser, setModalUser] = useState({
    email: "",
    firstName: "",
    lastName: ""
  });

  const {t} = useTranslation();

  const [showCsvModal, setShowCsvModal] = useState(false);

  const headerMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)) {
        setInviteShowModal(false);
        setEditShowModal(false);
        setModalUser({ email: "", firstName: "", lastName: "" });
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });


  useEffect(() => {
	  if(props.hrZoneAuth.sessionKey.length > 0){
      dispatch(showUserLoading());
      dispatch(
        getHrZoneHrUsers({
          perPage: 8,
          page: 1,
          //   ...filter,
			  isHR: 1,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
		  })
      );
	  }

  },[props.hrZoneAuth.sessionKey]);

  const inviteEmployee = async () => {
    if (validInputs) {
      try{
        const res: any = await axios.post(
          urls.hrZone.base + "invite",
          {
				  hr_email: props.user.email,
				  //   customerEmail: props.user.email,
				  users: [{ ...modalUser, office: "Office", isHR: 0 }],
				  isHR: 1,
				  sessionKey: props.hrZoneAuth.sessionKey
          },
          {
				  headers: { Authorization: `Bearer ${getToken()}` },
				  timeout: 10000
          }
			  );
			  if (res.status == 200 && res.data.success === 1) {
          setInviteShowModal(false);
          setModalUser({
				  firstName: "",
				  lastName: "",
				  email: ""
          });
          toast.success(
				  <CustomToastContainer message={t("hrzone.toastMessages.invite.success")} status={1} />,
				  {
              autoClose: 5000
				  }
          );
			  } else {
          toast.error(
				  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
				  {
              autoClose: 5000
				  }
          );
			  }
      }catch(e){
        toast.error(
          <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
          {
				  autoClose: 5000
          }
			  );
      }

    } else {
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.wrongInput")} status={3} />,
        {
          autoClose: 5000
        }
      );
    }
  };

  const editEmployee = async () => {
    if (validInputs) {
      try{
        const res: any = await axios.post(
          urls.hrZone.base + "editEmployee",
          {
				  hr_email: props.user.email,
				  email: modalUser.email,
				  firstName: modalUser.firstName,
				  lastName: modalUser.lastName,
				  sessionKey: props.hrZoneAuth.sessionKey
          },
          {
				  headers: { Authorization: `Bearer ${getToken()}` },
				  timeout: 10000
          }
			  );
			  if (res.status == 200 && res.data.success === 1) {
          setEditShowModal(false);
          setModalUser({
				  firstName: "",
				  lastName: "",
				  email: ""
          });
          toast.success(
				  <CustomToastContainer message={t("hrzone.toastMessages.edit.employee.success")} status={1} />,
				  {
              autoClose: 5000
				  }
          );
          dispatch(
            getHrZoneHrUsers({
              perPage: 8,
              page: 1,
			  sessionKey: props.hrZoneAuth?.sessionKey,
              //   status:
              // 		  props.filter.status.value === "true"
              // 		    ? 1
              // 		    : props.filter.status.value === "false"
              // 			  ? 0
              // 			  : undefined,
              hr_email: props.user.email
				  })
          );
			  } else {
          toast.error(
				  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
				  {
              autoClose: 5000
				  }
          );
			  }
      }catch(e){
        toast.error(
          <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
          {
				  autoClose: 5000
          }
			  );
      }

    } else {
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.wrongInput")} status={3} />,
        {
          autoClose: 5000
        }
      );
    }
  };

  const validateEmail = () => {
    return String(modalUser.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validInputs =
    modalUser.firstName.length > 0 &&
    modalUser.lastName.length > 0 &&
    validateEmail();

  const invitationModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showInviteModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px"
        }}
      >
        <img
          onClick={() => setInviteShowModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>Mitarbeiter einladen</h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={"E-Mail-Adresse"}
            isDisabled={false}
            inputValue={modalUser.email}
            handleChange={(e) => {
              setModalUser({ ...modalUser, email: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={"Vorname"}
            isDisabled={false}
            inputValue={modalUser.firstName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, firstName: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={"Nachname"}
            isDisabled={false}
            inputValue={modalUser.lastName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, lastName: e });
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end"
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "15px",
              fontSize: "12px",
              color: "#9aa9b3"
            }}
          >
            oder
          </p>

          <Button
            buttonStyles={{
              margin: "0px auto 15px auto",
              fontWeight: 600,
              background: "none"
            }}
            buttonType={"secondary"}
            buttonLabel={"CSV DATEI HOCHLADEN"}
            buttonHandler={() => {
              setShowCsvModal(true);
              setInviteShowModal(false);
            }}
          />
          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600
            }}
            disabled={!validInputs}
            buttonType={"primary"}
            buttonLabel={"EINLADEN"}
            buttonHandler={() => inviteEmployee()}
          />
        </div>
      </div>
    </Modal>
  );

  const editModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showEditModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px"
        }}
      >
        <img
          onClick={() => setEditShowModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>Mitarbeiter bearbeiten</h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={"E-Mail-Adresse"}
            isDisabled={false}
            inputValue={modalUser.email}
            handleChange={(e) => {
              setModalUser({ ...modalUser, email: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={"Vorname"}
            isDisabled={false}
            inputValue={modalUser.firstName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, firstName: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={"Nachname"}
            isDisabled={false}
            inputValue={modalUser.lastName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, lastName: e });
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end"
          }}
        >
          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600
            }}
            buttonType={"primary"}
            buttonLabel={"SPEICHERN"}
            buttonHandler={() => editEmployee()}
          />
        </div>
      </div>
    </Modal>
  );

  return (
    <ContentContainer>
      <Modal isOpen={showCsvModal} style={modalStyles}>
        <HrZoneUploadCsvEmployeeModal
				  // users={[]}
				  isHR={1}
				  closeModal={() => setShowCsvModal(false)} hrZoneAuth={props.hrZoneAuth}        />
      </Modal>
      <div className="employee_page">
        {invitationModal}
        {editModal}
        <h2 style={{ marginBottom: "30px" }}>{t("hrzone.generalInfo.hrEmployees")}</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "30px"
          }}
        ></div>

        {!(props.employee.users) ? (
          <TableLoading rowsNumber={8} />
        ) : (
          <HrZoneEmployeeList
            user={props.user}
            filter={filter}
            employee={props.employee}
            isHR={1}
            isMasterHr={props.hrZoneAuth.isMasterHr}
          />
        )}
      </div>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.userInfo.user,
    employee: state.users.users,
    loading: state.users.loading,
    sidebar: state.sidebar.sidebarTabCondition,
    checkedBoxes: state.employeeCheckboxesState.selectedCheckboxes,
    departments: state.depsAndBranches.departments,
    selectedRows: state.selectedRows.selectedRows,
    hrZoneAuth: state.hrZone
  };
};

export default connect(mapStateToProps, null)(HrEmployees);