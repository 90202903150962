import React from "react";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";

export default function InfoFirstStep() {
  const { t } = useTranslation();

  return(
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: "100%", opacity: 0 }}
      transition={{
        delay: 0.3,
        default: { duration: 0.4 },
      }}
      className="info_step"
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        height: "60%"
      }}
    >
      <h2 style={{textAlign: "center", transform: "translateY(-60px)"}}>
        {t("partner_portal.deals.create.info_container.step_1.title")}
      </h2>
      <div className="info_step_title">
        <div />
        <h3>{t("partner_portal.deals.create.info_container.step_1.title_online")}</h3>
      </div>
      <div className="info_step_sub_title">
        <div />
        <p>{t("partner_portal.deals.create.info_container.step_1.sub_title_online")}</p>
      </div>
      <div className="info_step_title">
        <div />
        <h3>{t("partner_portal.deals.create.info_container.step_1.title_present")}</h3>
      </div>
      <div className="info_step_sub_title">
        <div />
        <p>{t("partner_portal.deals.create.info_container.step_1.sub_title_present_1")}</p>
      </div>
      <div className="info_step_sub_title">
        <div />
        <p>{t("partner_portal.deals.create.info_container.step_1.sub_title_present_2")}</p>
      </div>
    </motion.div>
  );
}
