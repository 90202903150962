import axios from "axios";
import {urls} from "../api/urls";
import environment from "../environment";

export default async function signImages(key: string) {
  const options = {headers: {"Content-Type": "application/json"}};
  const fullUrl = `${environment.baseUrl}${urls.files.file}/${key}`;
  try {
    const response = await axios.get(fullUrl, options);
    return response.data;
  } catch (e) {
    console.error(e);
  }
}
