import React, {useEffect, useState} from "react";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import "./OutgoingWireTransferModal.css";
import {modalStyles} from "../../../../utils/modalStyles";
import closeIcon from "../../../../img/Dashboard/Employee/close_btn.svg";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../environment";
import {urls} from "../../../../api/urls";
import {RootState} from "../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import NumberFormat from "react-number-format";
import Button from "../../../Buttons/Button";
import FormikInput from "../../../SimpleComponents/FormikInput";
import successImage from "img/Dashboard/Payments/Overview/OutgoingWireTransfer/card_success.png";

interface IProps {
  showModal: boolean;
  onClose: (clearState?: boolean) => void;
  weavr?: any;
  amount: number;
  receiverName: string;
  iban: string;
  onSubmit: () => void;
  modalState: "" | "check" | "verification"
  transactionId: string;
}

function OutgoingWireTransferModal(props: IProps) {
  const { t } = useTranslation();
  const [errorMessage, setErrorMessage] = useState("");
  const [modalState, setModalState] = useState<"check" | "verification" | "success">("check");
  const [verificationCode, setVerificationCode] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    if (props.modalState) {
      setModalState(props.modalState);
    }
  }, [props.modalState]);

  const handleSendVerificationCode = async () => {
    const weavrToken = await getToken(true);
    const userToken = await getToken();
    axios.post(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.verifyAnOutgoingWireTransferTransaction}`,
      {
        transactionId: props.transactionId,
        code: verificationCode,
        corporateToken: weavrToken
      },
      {
        headers: {Authorization: `Bearer ${userToken}`}
      })
      .then((res: any) => {
        if (res.data) {
          setModalState("success");
        }
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data.error);
      });
  };

  return(
    <Modal
      style={modalStyles}
      isOpen={props.showModal}
    >

      {modalState === "success"
        ? <img
          style={{top:"15px", right:"15px"}}
          onClick={() => props.onClose(true)}
          className="close_popup"
          src={closeIcon} alt="close"
        />
        : null
      }

      <div className="modal_container" style={{marginTop: "20px"}}>

        {modalState === "verification"
          ? <div>
            <h2>
              {t("dashboard.payments.overview.outgoing_payment_modal.verification_state.title")}
            </h2>
            {errorMessage && errorMessage.length > 0
              ? <p
                style={{
                  fontSize: "12px",
                  color: "red"
                }}
              >
                {errorMessage}
              </p>
              : null
            }
            <div className="verification_form_content_input">
              <p>{t("dashboard.payments.onboarding.stepper.sms_verification.label")}</p>
              <NumberFormat
                disabled={false}
                className="code_input"
                format="# # # # # #"
                onChange={(e:any) => {
                  const inputNumber = e.target.value;
                  setVerificationCode(inputNumber.toString().split(" ").join(""));
                }}
              />
            </div>
            <Button
              disabled={verificationCode.length < 6}
              buttonType="primary"
              buttonHtmlType="submit"
              buttonLabel={t("dashboard.payments.overview.outgoing_payment_modal.verification_state.btn")}
              buttonHandler={() => handleSendVerificationCode()}
              fullWidth={true}
            />
          </div>
          : modalState === "success"
            ? <div className="payment_overview_outgoing_transfer_success_container">

              <h2>
                {t("dashboard.payments.overview.outgoing_payment_modal.success.title")}
              </h2>

              <img src={successImage} alt="success"/>

              <p>
                {t("dashboard.payments.overview.outgoing_payment_modal.success.text")}
              </p>

            </div>
            : <div>

              <h4 className="payment_overview_outgoing_transfer_success_container_title">
                {t("dashboard.payments.overview.outgoing_payment_modal.check.title")}
              </h4>

              <div className="payment_overview_outgoing_transfer_success_container_box">
                <div className="space-between">
                  <p className="payment_overview_outgoing_transfer_success_container_box_title">
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.transfer_details")}
                  </p>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_edit"
                    onClick={() => props.onClose()}
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.transfer_details_edit")}
                  </p>
                </div>

                <div className="space-between" style={{marginTop: "42px"}}>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_sub_title"
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.send")}
                  </p>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_amount"
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.transfer_amount", {amount: props.amount})}
                  </p>
                </div>

                <div className="payment_overview_outgoing_transfer_success_container_box_underline" />

                <div className="space-between" style={{marginTop: "42px"}}>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_sub_title"
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.recipient_details")}
                  </p>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_edit"
                    onClick={() => props.onClose()}
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.recipient_details_change")}
                  </p>
                </div>

                <div className="space-between" style={{marginTop: "42px"}}>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_sub_title"
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.name")}
                  </p>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_sub_title"
                  >
                    {props.receiverName}
                  </p>
                </div>

                <div className="space-between" style={{marginTop: "42px"}}>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_sub_title"
                  >
                    {t("dashboard.payments.overview.outgoing_payment_modal.check.send_to")}
                  </p>
                  <p
                    className="payment_overview_outgoing_transfer_success_container_box_sub_title"
                  >
                    {props.iban}
                  </p>
                </div>

                <div className="center" style={{marginTop: "40px"}}>
                  <Button
                    buttonType="primary"
                    buttonLabel={t("dashboard.payments.overview.outgoing_payment_modal.check.continue_btn")}
                    buttonHandler={() => props.onSubmit()}
                    buttonStyles={{
                      backgroundColor: "#16B477",
                      minWidth: "205px"
                    }}
                  />
                </div>

              </div>

            </div>
        }

      </div>

    </Modal>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    weavr: state.weavr.weavrCorporate
  };
};

export default connect(mapStateToProps, null)(OutgoingWireTransferModal);
