import {HANDLE_PLANS, plansActionTypes} from "../types/addedPlansTypes";

const initialState = {
  addedPlans: []
};

export const addedPlansReducer = (
  state = initialState,
  action:plansActionTypes) => {
  switch (action.type) {
  case HANDLE_PLANS:
    return{addedPlans: action.payload};
  default: return state;
  }
};
