import React from "react";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import "../SimpleComponents/Modal.css";
import Checkbox from "../SimpleComponents/Checkbox";
import {useNavigate} from "react-router-dom";
import { useTranslation } from "react-i18next";

interface CustomProps {
	completion: any
	closeModal: () => void
}


export default function CompleteProfileModal(
  {completion, closeModal}:CustomProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <div className="modal_container">
      <img
        style={{top:"15px", right:"15px"}}
        onClick={closeModal}
        className="close_popup"
        src={closeIcon} alt="close"/>
      <h2 className="complete_title">
        {t("dashboard.home.complete_title")}
      </h2>
      <div className="complete_row_selected">
        <div className="row">
          <Checkbox
            id="info"
            checked={true}
            handleCheckboxChange={() => {
              //
            }}
          />
          <p className="complete_text">
            {t("dashboard.home.complete_1")}
          </p>
        </div>
        <p className="complete_text_completed">
          {t("dashboard.home.completed")}
        </p>
      </div>
      <div className="complete_row_selected">
        <div className="row">
          <Checkbox
            id="employee"
            checked={completion.employee}
            handleCheckboxChange={() => {
              //
            }}
          />
          <p className="complete_text">
            {t("dashboard.home.complete_2")}
          </p>
        </div>
        {completion.employee
          ?  <p className="complete_text_completed">
            {t("dashboard.home.completed")}
          </p>
          :<p
            onClick={() => navigate("/employee")}
            className="complete_text_complete_now">
            {t("dashboard.home.complete_now")}
          </p>}

      </div>
      <div className="complete_row_selected">
        <div className="row">
          <Checkbox
            id="plans"
            checked={completion.plans}
            handleCheckboxChange={() => {
              //
            }}
          />
          <p className="complete_text">
            {t("dashboard.home.complete_3")}
          </p>
        </div>
        {completion.plans
          ?  <p className="complete_text_completed">
            {t("dashboard.home.completed")}
          </p>
          :<p
            onClick={() => navigate("/plans/create")}
            className="complete_text_complete_now">
            {t("dashboard.home.complete_now")}
          </p>}

      </div>
    </div>
  );
}
