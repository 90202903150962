import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";
import SelectWithChoices from "../../SimpleComponents/SelectWithChoices";
import {getUsers} from "../../../redux/actions/usersActions";
import FilterDatePicker from "../../SimpleComponents/FilterdatePicker";
import { plansData, showPlansLoading } from "../../../redux/actions/plansActions";
import {
  changePlansFiltering
} from "../../../redux/actions/filteringActions";

interface ISelectOption {
  value: string;
  label: string;
}

function PlansFilters({ employee, filteringState }: any) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const planStatuses = [
    {
      value: "",
      label: "Alle"
    },
    {
      value: "active",
      label: t("dashboard.plans.filters.statusValues.0")
    },
    {
      value: "not_active",
      label: t("dashboard.plans.filters.statusValues.1")
    },
    {
      value: "ready",
      label: t("dashboard.plans.filters.statusValues.2")
    },
    {
      value: "billed",
      label: t("dashboard.plans.filters.statusValues.3")
    }
  ];

  // Take all data
  useEffect(() => {
    dispatch(getUsers({
      limit: 0,
      skip: 0
    }));
  }, []);

  // Initial of selects
  const [employeesOptions, setEmployeesOptions] = useState<ISelectOption[]>([]);

  // remove all option from select
  function removeAllOption(item: any) {
    if (item.value === "*") {
      return false;
    }
    return true;
  }

  // Transform data and set to filter options
  useEffect(() => {
    if (employee && employee.users && employee.users.length) {
      const transformedData:ISelectOption[] = [];
      employee.users.map((user:any) => {
        transformedData.push({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`
        });
      });
      setEmployeesOptions(transformedData);
    }
  }, [employee]);

  // HANDLE SELECTS
  const handleChoice = (options: any, type: string) => {

    if (type === "status") {
      const changedState = { ...filteringState };
      changedState[type] = options.value.toString();
      changedState.skip = 0;
      dispatch(showPlansLoading());
      // Update table with new filtering state
      dispatch(changePlansFiltering(changedState));
      dispatch(plansData(changedState));

    } else {
      if (options.length > 0) {
        const filtered = options.filter(removeAllOption);
        const temporaryIDs = filtered.map(function (obj: any) {
          return obj.value;
        });

        // Assign changed value to global filtering state, to keep it sync in all components
        const changedState = { ...filteringState };

        changedState[type] = temporaryIDs;

        changedState.skip = 0;
        dispatch(showPlansLoading());
        // Update table with new filtering state
        dispatch(changePlansFiltering(changedState));
        dispatch(plansData(changedState));
      } else {
        // Clear selected value in global state
        const changedState = { ...filteringState };
        changedState[type] = [];
        if (type === "users") {
          changedState.skip = 0;
        }
        dispatch(showPlansLoading());
        // Update table with new filtering state
        dispatch(changePlansFiltering(changedState));
        dispatch(plansData(changedState));
      }
    }
  };

  const handleDatePicker = (values:any) => {
    const changedState = { ...filteringState };

    changedState.startDate = values.startDate;
    changedState.endDate = values.endDate;
    // Show table loading component
    dispatch(showPlansLoading());
    // Update table with new filtering state
    dispatch(changePlansFiltering(changedState));
    dispatch(plansData(changedState));
  };

  return(
    <>

      <div className="filters_item" style={{ marginBottom: "10px" }}>

        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.plans.filters.labels.status")}
        </p>

        <SelectWithChoices
          defaultValue={""}
          name={t("dashboard.plans.filters.labels.status")}
          selectPlaceHolder={t("dashboard.overview.filter_opt_1_place")}
          selectId="status"
          options={planStatuses}
          handleAddChoice={(options) => handleChoice(options, "status")}
          isMulti={"no"}
        />

      </div>

      <div className="filters_item" style={{ marginBottom: "10px" }}>

        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.plans.filters.labels.employee")}
        </p>

        <SelectWithChoices
          defaultValue={""}
          name={t("dashboard.plans.filters.labels.employee")}
          selectPlaceHolder={t("dashboard.overview.filter_opt_1_place")}
          selectId="employeesId"
          options={employeesOptions}
          handleAddChoice={(options) => handleChoice(options, "users")}
        />

      </div>

      <div className="filters_item">
        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.overview.filter_opt_6_label")}
        </p>
        <FilterDatePicker
          startDate={filteringState.startDate}
          endDate={filteringState.endDate}
          handleChange={(values:any) => handleDatePicker(values)}
        />
      </div>

    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    filters: state.invoices.filters,
    filteringState: state.filters.plansFilters,
    employee: state.users.users
  };
};

export default connect(mapStateToProps, null)(PlansFilters);
