import React, {useEffect, useState} from "react";
import ContentContainer from "../../../Components/Containers/ContentContainer";
import MainSpace from "../../../Components/Containers/MainSpace";
import {getToken, setToken} from "../../../utils/cookies/tokensCookie";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import FormikInput from "../../../Components/SimpleComponents/FormikInput";
import {motion} from "framer-motion";
import Button from "../../../Components/Buttons/Button";
import axios from "axios";
import environment from "../../../environment";
import {useDispatch} from "react-redux";
import {getWeavrCorporate} from "../../../redux/actions/weavrActions";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import {useLocation} from "react-router-dom";
import queryString from "query-string";

export default function OnboardingKyc(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [ref, setRef] = useState();

  useEffect(() => {
    if (ref) {

      let reference = "";

      if (location.search) {
        const parsedSearch = queryString.parse(location.search);
        if (parsedSearch && parsedSearch.reference) {
          reference = parsedSearch.reference;
        }
      } else {
        reference = "108215375838511117";
      }

      window.OpcUxSecureClient.kyc().init(

        // The selector identifying the container where you want to display the component
        "#director-kyc-container",

        // The reference that will be included as as a query parameter in the URL sent
        // using email to the directors/representatives
        { reference: reference },

        function(messageType, payload) {
          //
        },
        { lang: "de" },

        // Specify a string of plain text styles to
        { customCssStr: "" },

        // Specify a CSS file containing styles to
        { customCss: "" }
      );
    }
  }, [ref]);

  return (
    <MainSpace>
      <div ref={newRef => setRef(newRef)} id="director-kyc-container"></div>
    </MainSpace>
  );
}
