import React from "react";
import {useTranslation, Trans} from "react-i18next";
import {motion} from "framer-motion";

interface IProps {
  business?: boolean;
}

export default function LocalInfoSecondStep({business}:IProps) {
  const { t } = useTranslation();

  return(
    <motion.div
      animate={{ y: 0, opacity: 1 }}
      initial={{ y: "100%", opacity: 0 }}
      transition={{
        delay: 0.3,
        default: { duration: 0.4 },
      }}
      className="info_step"
    >
      <h2 style={{marginTop: "4vw"}}>{t("partner_portal.deals.create.info_container.step_2.title")}</h2>
      <p>{t("partner_portal.deals.create.info_container.step_2.text")}</p>

      {business
        ? <div className="info_step_title">
          <div />
          <h3 style={{fontWeight: "normal"}}>
            <Trans
              i18nKey="info_container.step_2.sub_1_b"
              values={{
                strong1: t("partner_portal.deals.create.info_container.step_2.sub_1_b_strong_1"),
                strong2: t("partner_portal.deals.create.info_container.step_2.sub_1_b_strong_2")
              }}
            >
              {/* eslint-disable-next-line max-len */}
              Alle Deals auf corplife B2B sind in der <strong>Du-Form</strong> und aus <strong>Sicht Dritter</strong> geschrieben.

            </Trans>
          </h3>
        </div>
        : <div className="info_step_title">
          <div />
          <h3 style={{fontWeight: "normal"}}>
            <Trans
              i18nKey="info_container.step_2.sub_1"
              values={{
                strong1: t("partner_portal.deals.create.info_container.step_2.sub_1_strong_1"),
                strong2: t("partner_portal.deals.create.info_container.step_2.sub_1_strong_2")
              }}
            >
              {/* eslint-disable-next-line max-len */}
              Alle Deals auf Corplife Benefits sind in der <strong>WORKSq</strong> und aus <strong>WORKS2</strong> geschrieben.

            </Trans>
          </h3>
        </div>
      }

      <div className="info_step_title">
        <div />
        <h3 style={{fontWeight: "normal"}}>
          <Trans
            i18nKey="info_container.step_2.sub_2"
            values={{
              strong: t("partner_portal.deals.create.info_container.step_2.sub_2_strong")
            }}
          >
            {/* eslint-disable-next-line max-len */}
            Alle <strong>Texte</strong> haben eine minimale und eine maximale Zeichenanzahl, die den Beschreibungen über jedem Feld zu entnehmen sind.

          </Trans>
        </h3>
      </div>

      <div className="info_step_title">
        <div />
        <h3 style={{fontWeight: "normal"}}>
          <Trans
            i18nKey="info_container.step_2.sub_3"
            values={{
              strong: t("partner_portal.deals.create.info_container.step_2.sub_3_strong")
            }}
          >
            {/* eslint-disable-next-line max-len */}
            Alle <strong>Bilder</strong> haben vorgegebene Maße, die den konkreten Feldbeschreibungen auf den nächsten Seiten zu entnehmen sind.

          </Trans>
        </h3>
      </div>

      <div className="info_step_title">
        <div />
        <h3 style={{fontWeight: "normal"}}>
          <Trans
            i18nKey="info_container.step_2.sub_4"
            values={{
              strong: t("partner_portal.deals.create.info_container.step_2.sub_4_strong")
            }}
          >
            {/* eslint-disable-next-line max-len */}
            Unsere Deals haben ein fixes <strong>Layout</strong>, das auf den nächsten Seiten zusammen mit den Deal-Inhalten ersichtlich ist.

          </Trans>
        </h3>
      </div>

      <div className="info_step_title">
        <div />
        <h3 style={{fontWeight: "normal"}}>
          <Trans
            i18nKey="info_container.step_2.local_sub_5"
            values={{
              strong: t("partner_portal.deals.create.info_container.step_2.local_sub_5_strong")
            }}
          >
            {/* eslint-disable-next-line max-len */}
            Falls Sie zusätzliche Werbematerialien (z.B. Banner, Newsletter, etc.) gebucht haben, stellen Sie uns bitte entsprechendes <strong>Banner-Material</strong> zur Verfügung. Falls Sie kein Banner-Material zur Verfügung stellen, werden wir für eine gebuchte Platzierung folgende Quellen nutzen: Ihr Deal, Ihre Homepage, allg. Stockbilder.
          </Trans>
        </h3>
      </div>

    </motion.div>
  );
}
