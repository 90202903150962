import {
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  NotificationData,
  notificationDataDispatchTypes
} from "../types/notificationDataTypes";


interface DefaultStateI {
	notificationData?: NotificationData
	error?: boolean
}

const initialState:DefaultStateI = {
  notificationData: {
    "data": [""],
    "newNotification": 0
  },
  error: false
};

export const notificationDataReducer = (
  state: DefaultStateI = initialState,
  action: notificationDataDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case GET_NOTIFICATIONS_SUCCESS:
    return {
      notificationData: action.payload
    };
  case GET_NOTIFICATIONS_ERROR:
    return {
      error: true
    };
  default:
    return state;
  }
};
