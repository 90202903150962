import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {toast} from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import {getToken, setToken} from "../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import image from "../../img/Dashboard/Card/weavr_onboarding_login.svg";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import {useLocation, useNavigate} from "react-router-dom";

interface IProps {
  payments?: any,
  weavr?: any
}

function PaymentsChangePassword(props:IProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const [userEmail, setUserEmail] = useState("");
  const [resetStep, setResetStep] = useState(0);
  const [oldPassValue, setOldPassValue] = useState("");

  useEffect(() => {
    if (props.weavr && props.weavr.rootUser && props.weavr.rootUser.email) {
      setUserEmail(props.weavr.rootUser.email);
    }
  }, [props.weavr]);

  const [refLogin, setRefLogin] = useState(null);

  const resetPassword = async (passcode:string) => {
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.resetPassword}`,
        {
          nonce: location.pathname.split("/")[2],
          email: userEmail || location.pathname.split("/")[3].replace("%40", "@"),
          newPassword: {
            value: passcode
          }
        },
        {
          headers: {Authorization: `Bearer ${token}`}
        });
      if (response.data && response.data.token) {
        setToken(response.data.token, true);
        setTimeout(() => {
          navigate("/");
        }, 50);
      }
    } catch (e) {
      console.error(e);
      toast.error(
        <CustomToastContainer
          message={"error"}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const onSubmit = (e:any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    refLogin.tokenize(function(tokens) {
      if (tokens && tokens.passcode) {
        if (resetStep === 0) {
          setOldPassValue(tokens.passcode);
          resetPassword(tokens.passcode);
        }
      }
    });
  };

  useEffect(() => {
    const createdForm = (window as any).OpcUxSecureClient.form();

    setRefLogin(createdForm);

    setTimeout(() => {
      // Create an instance of a secure Passcode component that will collect the passcode
      const input = createdForm.input(
        "passcode",
        "passCode",
        {
          placeholder: "Neues Passwort"
        }
      );

      // Embed the secure Passcode component in the HTML element where you want
      // the Passcode component to the shown on screen
      input.mount(document.getElementById("passcode"));
    }, 100);
  }, []);

  return(
    <form
      ref={refLogin}
      onSubmit={(e) => onSubmit(e)}
      className="weavr_passcode_container"
    >
      <div>
        <div className="weavr_login_container_content">
          <h1>Passwort zurücksetzen</h1>
          <div style={{display: "none"}}>
            Username: <input type="input" value={userEmail} name="u" /><br/>
          </div>
          <div className="weavr_passcode_input_box">
            Passwort <div id="passcode"></div><br/>
          </div>
          <input
            className="weavr_passcode_btn"
            type="submit"
            value={"Passwort zurücksetzen"}
          />
        </div>
      </div>
      <div>
        <img src={image} alt="login"/>
      </div>
    </form>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    payments: state.payments,
    weavr: state.weavr.weavrCorporate
  };
};

export default connect(mapStateToProps, null)(PaymentsChangePassword);
