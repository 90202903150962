import React from "react";
import {useTranslation} from "react-i18next";
import image from "img/Dashboard/Card/weavr_onborading_verification.svg";

export default function KYBEmailInfo() {
  const { t } = useTranslation();

  return(
    <div className="create_password_page">
      <div className="verification_form">
        <div className="center">
          <div className="verification_form_content">
            <h1>{t("dashboard.payments.onboarding.stepper.kyb_info.title")}</h1>
            <p>{t("dashboard.payments.onboarding.stepper.kyb_info.description")}</p>
            <p>{t("dashboard.payments.onboarding.stepper.kyb_info.text")}</p>
          </div>
        </div>
        <div className="center">
          <img src={image} alt="verification"/>
        </div>
      </div>
    </div>
  );
}
