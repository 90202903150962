import React from "react";
import "./VerticalDivider.css";

const Divider = (props: any) => {
  return(
    <div
      className="grid-spacer"
      style={{
        gridTemplateColumns: props.ratio,
      }}
    >
      {props.children}
    </div>
  );
};

export default Divider;
