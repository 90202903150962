import "./App.css";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";

import Tracker from "@openreplay/tracker/cjs";
import * as Sentry from "@sentry/react";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { Routes, Route, Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import {
  ChangePassword,
  LoginPage,
  NotFoundPage,
  ServerErrorPage
} from "routes";

import DashboardPage from "./pages/dashboard/route";
import { userTokenDispatcher } from "./redux/actions/authActions";
import { RootState } from "./redux/reducers/rootReducer";
import { analytics } from "./services/firebase_setup";
import { getToken } from "./utils/cookies/tokensCookie";
import Modal from "react-modal";
import customHistory from "./customHistory";
import { changeSidebarTab } from "redux/actions/sidebarActions";
import VerifyPage from "./pages/verify/VerifyPage";
import KycEmailVerification from "./pages/corporates/kyc";
import ConsumeWeavrInvite from "./pages/consumeWeavrInvite/ConsumeWeavrInvite";
import MagentoLoginPage from "./pages/authentication/magentoLogin/page";
import PaymentsChangePassword from "./pages/payments/ChangePasssword";
import {useEffectOnce} from "./hooks/useEffectOnce";

/* Setup values for OpenReplay tracker */
export const tracker =
  process.env.REACT_APP_ENV &&
  new Tracker({
    projectKey: (process.env.CRASHLYTICS_KEY as string) || "",
    ingestPoint: process.env.CRASHLYTICS_INGEST
  });

function App() {
  // Language changing for all pages
  const dispatch = useDispatch();

  // Setup for modal
  if (process.env.NODE_ENV !== "test") Modal.setAppElement("#root");

  // Initialize token for application
  useEffect(() => {
    const token = getToken();
    token && dispatch(userTokenDispatcher(token));
  }, [dispatch]);

  // INITIALIZE FIREBASE TOOLS
  // FOR ANALYTICS AND PERFORMANCE BASE
  useEffect(() => {
    if (process.env.REACT_APP_ENV === "production") {
      tracker && tracker.start();
      analytics && analytics.logEvent("started");
    }
  }, []);

  useEffectOnce(() => {
    Sentry.init({
      debug: true,
      dsn: "https://99b0738f18595ed7a4ff00c84262969c@o538122.ingest.sentry.io/4506574919368704",
      integrations: [
        new Sentry.Replay({
          maskAllText: false,
          blockAllMedia: false,
        }),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions
      // Session Replay
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  });

  const CustomRouter = ({ history, ...props }: any) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location
    });

    useLayoutEffect(() => history.listen(setState), [history]);

    // add location listener to dispatch sidebarTab changes
    useEffect(() => {
      if (state.location.pathname.length > 1) {
        if (state.location.pathname[0] === "/") {
          // cut away the first character which is a slash
          dispatch(
            changeSidebarTab(
              state.location.pathname.slice(1, state.location.pathname.length)
            )
          );
        } else {
          dispatch(changeSidebarTab(state.location.pathname));
        }
      } else {
        // Root route (/) which is dashboard route
        dispatch(changeSidebarTab("dashboard"));
      }
    }, [state.location]);

    return (
      <Router
        {...props}
        location={state.location}
        navigationType={state.action}
        navigator={history}
      />
    );
  };

  const hideScreen =
    window?.innerWidth < 900
      ? process.env.REACT_APP_ENV === "production"
        ? true
        : false
      : false;

  return (
    <div className="App">
      <p className="versioning">{process.env.REACT_APP_VERSION}</p>
      <ToastContainer />
      <CustomRouter history={customHistory}>
        <Routes>
          <Route path="/*" element={<DashboardPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/magentoLogin" element={<MagentoLoginPage />} />
          <Route path="/resetPassword" element={<ChangePassword />} />
          <Route
            path="/activateUser"
            element={<ChangePassword activateUser={true} />}
          />
          <Route path="/verify" element={<VerifyPage />} />
          <Route path="/invite/consume" element={<ConsumeWeavrInvite />} />
          <Route path="/set-password/*" element={<PaymentsChangePassword />} />
          <Route
            path="/corporates/kyc"
            element={<KycEmailVerification />}
          />
          <Route path="/server-error" element={<ServerErrorPage />} />
          <Route path="/error" element={<NotFoundPage />} />
        </Routes>
      </CustomRouter>
    </div>
  );
}

const mapDispatchToProps = {
  userTokenDispatcher
};

const mapStateToProps = (state: RootState) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
