import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {connect, useDispatch} from "react-redux";
import {toast} from "react-toastify";
import uploadIcon from "../../../../../../img/upload_icon.png";
import axios from "axios";
import {Checkbox} from "antd";
import { changeValues } from "../../../../../../redux/actions/stepsActions";
import { setTooltip } from "../../../../../../redux/actions/tooltipActions";
import { RootState } from "../../../../../../redux/reducers/rootReducer";
import CustomToastContainer from "../../../../../SimpleComponents/ToastContainer";
import RadioGroup from "../../../Components/RadioGroup/RadioGroup";
import {urls} from "../../../../../../api/urls";
import environment from "../../../../../../environment";
import moment from "moment";


interface CustomProps {
  stateValues?: any,
  setButtonDisabled: (state:boolean) => void,
  id?: string
}

function LocalMainForm({ stateValues, setButtonDisabled, id}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const formik = useFormik<any>({
    initialValues: {
      text: "",
      image: "",
      multipleImages: false,
      uploadedImages: [],
      uploadedImagesKeys: [],
      uploadedImagesDE: [],
      uploadedImagesKeysDE: [],
      multipleFeature: false,
      featureImage: [],
      featureImageKeys: [],
      featureImageDE: [],
      featureImageKeysDE: [],
      logoImage: [],
      logoImageKeys: [],
      multipleOptions: false,
      options: "",
      optionsSub: "",
      optionsFile: [],
      optionsFileKeys: [],
      optionsDE: "",
      optionsSubDE: "",
      optionsFileDE: [],
      optionsFileKeysDE: [],
      multipleLinks: false,
      linksSub: "",
      linksSubDE: "",
      tags: "",
      tagsSub: "",
      videos: "",
      videosDE: "",
      multipleVideos: false,
      customVideo: "",
      customVideoDE: ""
    },
    validationSchema: Yup.object({
      image: Yup.string().required(t("partner_portal.deals.create.warnings.validation.required")),
    }),
    onSubmit: () => console.log("CLICK"),
  });

  const [subErrors, setSubErrors] = useState<any>({});

  // Validate sub values
  useEffect(() => {
    validateForm();
  }, [formik.values, formik.isValid, formik.dirty]);
  const validateForm = () => {
    let valid = true;
    const errors:any = {};
    if (formik.values.image === "1") {
      if (!formik.values.uploadedImages.length) {
        valid = false;
        errors.images = t("partner_portal.deals.create.warnings.validation.images_upload");
      }
    }
    if (formik.isValid && formik.dirty) {
      setButtonDisabled(!valid);
    } else {
      setButtonDisabled(true);
    }
    setSubErrors(errors);
  };

  // Handle change from inputs in radio group
  const handleRadioInputChange = (name:string, value:string) => {
    formik.setFieldValue(name, value);
  };

  // Save form changes to state
  useEffect(() => {
    if (stateIsInitialized) {
      const stateCopy = [...stateValues];
      stateCopy[1] = formik.values;
      dispatch(changeValues(stateCopy, 0));
    }
  }, [
    formik.values.uploadedImages.length,
    formik.values.logoImage.length,
    formik.values.options,
    formik.values.optionsDE,
    formik.values.image
  ]);

  const [stateIsInitialized, setStateIsInitialized] = useState(false);

  // Initialize form values
  useEffect(() => {
    if (stateValues[1].text && !stateIsInitialized) {
      if (stateValues[1].optionsSubDE && stateValues[1].optionsSubDE.length > 0) {
        formik.setFieldValue("multipleOptions", true, true);
      }
      if (stateValues[1].featureImageDE && stateValues[1].featureImageDE.length > 0) {
        formik.setFieldValue("multipleFeature", true, true);
      }
      if (stateValues[1].linksSubDE && stateValues[1].linksSubDE.length > 0) {
        formik.setFieldValue("multipleLinks", true, true);
      }
      if (stateValues[1].videosDE && stateValues[1].videosDE.length > 0) {
        formik.setFieldValue("multipleVideos", true, true);
      }

      Object.keys(stateValues[1]).forEach((key:string) => {
        formik.setFieldValue(key, stateValues[1][key], true);
        setTimeout(() => formik.setFieldTouched(stateValues[1][key], true));
      });
    }
    setStateIsInitialized(true);
  }, [stateValues]);


  const [formWithIdIsInitialized, setFormWithIdIsInitialized] = useState(false);

  useEffect(() => {
    if (id && stateValues[1].text && !formWithIdIsInitialized) {
      setFormWithIdIsInitialized(true);
      setStateIsInitialized(true);
      Object.keys(stateValues[1]).forEach((key:string) => {
        formik.setFieldValue(key, stateValues[1][key], true);
        setTimeout(() => formik.setFieldTouched(stateValues[1][key], true));
      });
    }
  }, [stateValues]);

  // Handle files upload
  const fileSelectedHandler = async (name: string, e: any, country?: "at" | "de") => {
    if (name === "images") {
      const validFileExtensions = ["image/jpg", "image/jpeg", "image/png"];
      const files = e.target.files;
      let valuesCopy;
      if (country && country === "de") {
        valuesCopy = formik.values.uploadedImagesDE;
      } else {
        valuesCopy = formik.values.uploadedImages;
      }
      if ((Object.values(files).length + valuesCopy.length) <= 8) {
        let showTypeError = false;
        Object.values(files).forEach((file: any) => {
          if (validFileExtensions.includes(file.type)) {
            valuesCopy.push(file);
          } else {
            showTypeError = true;
          }
        });
        if (showTypeError) {
          toast.error(
            <CustomToastContainer
              message={t("partner_portal.deals.create.warnings.image_upload.file_type_img")}
              status={3}/>, {
              autoClose: 2000,
            });
        } else {
          if (country && country === "de") {
            formik.setFieldValue("uploadedImagesDE", valuesCopy, true);
          } else {
            formik.setFieldValue("uploadedImages", valuesCopy, true);
          }
          await Promise.resolve();
          validateForm();
          if (country && country === "de") {
            getImageKey(valuesCopy, "images", "de");
          } else {
            getImageKey(valuesCopy, "images");
          }
        }
      } else {
        toast.error(
          <CustomToastContainer
            message={t("partner_portal.deals.create.warnings.image_upload.max_number", {number: 1})}
            status={3}/>, {
            autoClose: 2000,
          });
      }
    } else if (name === "feature") {
      const validFileExtensions = ["image/jpg", "image/jpeg", "image/png"];
      const files = e.target.files;
      let valuesCopy;
      if (country && country === "de") {
        valuesCopy = [...formik.values.featureImageDE];
      } else {
        valuesCopy = [...formik.values.featureImage];
      }
      let showTypeError = false;
      Object.values(files).forEach((file: any) => {
        if (validFileExtensions.includes(file.type)) {
          valuesCopy.push(file);
        } else {
          showTypeError = true;
        }
      });
      if (showTypeError) {
        toast.error(
          <CustomToastContainer
            message={t("partner_portal.deals.create.warnings.image_upload.file_type_img")}
            status={3}/>, {
            autoClose: 2000,
          });
      } else {
        if (country && country === "de") {
          formik.setFieldValue("featureImageDE", files, true);
        } else {
          formik.setFieldValue("featureImage", files, true);
        }
        await Promise.resolve();
        validateForm();
        if (country && country === "de") {
          getImageKey(files, "feature", "de");
        } else {
          getImageKey(files, "feature");
        }
      }
    } else if (name === "options") {
      let validFileExtensions:string[];
      if (country && country === "de") {
        if (formik.values.optionsDE.toString() === "0") {
          validFileExtensions = [
            "application/pdf"
          ];
        } else {
          validFileExtensions = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel"
          ];
        }
      } else {
        if (formik.values.options.toString() === "0") {
          validFileExtensions = [
            "application/pdf"
          ];
        } else {
          validFileExtensions = [
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
            "application/vnd.ms-excel"
          ];
        }
      }

      const file = e.target.files[0];
      let valuesCopy;
      if (country && country === "de") {
        valuesCopy = [...formik.values.optionsFileDE];
      } else {
        valuesCopy = [...formik.values.optionsFile];
      }

      let showTypeError = false;
      if (validFileExtensions.includes(file.type)) {
        if (country && country === "de") {
          formik.setFieldValue("optionsFileDE", [file], true);
        } else {
          formik.setFieldValue("optionsFile", [file], true);
        }
        await Promise.resolve();
        validateForm();
        if (country && country === "de") {
          getImageKey([file], "options", "de");
        } else {
          getImageKey([file], "options");
        }
      } else {
        showTypeError = true;
      }
      if (showTypeError) {
        toast.error(
          <CustomToastContainer
            message={t("partner_portal.deals.create.warnings.image_upload.file_options_type")}
            status={3}/>, {
            autoClose: 2000,
          });
      }

    } else if (name === "logo") {
      const validFileExtensions = [
        "image/png",
        "image/svg",
        "image/svg+xml",
        "image/jpg",
        "image/jpeg"
      ];
      const file = e.target.files[0];
      let showTypeError = false;
      if (validFileExtensions.includes(file.type)) {
        formik.setFieldValue("logoImage", [file], true);
        await Promise.resolve();
        validateForm();
        getImageKey([file], "logo");
      } else {
        showTypeError = true;
      }
      if (showTypeError) {
        toast.error(
          <CustomToastContainer
            message={t("partner_portal.deals.create.warnings.image_upload.file_logo_type")}
            status={3}/>, {
            autoClose: 2000,
          });
      }
    }
  };

  const getImageKey = async (files:any, name:string, country?: "at" | "de") => {
    const formData = new FormData();
    const options = {headers: {"Content-Type": "multipart/form-data"}};
    Array.from(files).forEach((image:any) => {
      formData.append("files", image);
    });
    formData.append("files", files);
    const fullUrl = `${environment.baseUrl}${urls.files.multiple}`;
    try {
      const uploadImage = await axios.post(fullUrl, formData, options);
      const imageKeysArray:string[] = [];
      uploadImage.data.forEach((image:any) => {
        imageKeysArray.push(image.key);
      });
      switch (name) {
      case "images":
        if (country && country === "de") {
          formik.setFieldValue("uploadedImagesKeysDE", imageKeysArray);
        } else {
          formik.setFieldValue("uploadedImagesKeys", imageKeysArray);
        }
        break;
      case "feature":
        if (country && country === "de") {
          formik.setFieldValue("featureImageKeysDE", imageKeysArray);
        } else {
          formik.setFieldValue("featureImageKeys", imageKeysArray);
        }
        break;
      case "options":
        if (country && country === "de") {
          formik.setFieldValue("optionsFileKeysDE", imageKeysArray);
        } else {
          formik.setFieldValue("optionsFileKeys", imageKeysArray);
        }
        break;
      case "logo":
        formik.setFieldValue("logoImageKeys", imageKeysArray);
        break;
      }
    } catch (e: any) {
      console.error(e);
    }
  };

  const saveFormValues = () => {
    if (stateIsInitialized) {
      const stateCopy = [...stateValues];
      stateCopy[1] = formik.values;
      dispatch(changeValues(stateCopy, 0));
    }
  };

  return(
    <form className="form_container" onMouseLeave={saveFormValues}>

      {/*TEXTs*/}
      {/*<div
        style={{position: "relative"}}
        onBlur={formik.handleBlur("text")}
        data-testid="texts_form"
      >
        <p className="simple_label_checklist">
          {t("partner_portal.deals.create.main_form.form.text_title")}
        </p>
        <p className="sub_label_checklist">
          {t("partner_portal.deals.create.main_form.form.text_sub_title")}
        </p>
        <RadioGroup
          errors={formik.errors.text}
          touched={formik.touched.text}
          onChange={(value:string) => {
            formik.setFieldValue("text", value);
          }}
          selected={formik.values.text}
          onBlur={formik.handleBlur}
          buttons={[
            {
              groupName: "text",
              value: "0",
              label: "partner_portal.deals.create.main_form.form.text_label_corplife",
            },
            {
              groupName: "text",
              value: "1",
              label: "partner_portal.deals.create.main_form.form.text_label_custom"
            }
          ]}
        />
        {
          formik.errors.text
          && formik.touched.text
          && <p className="input_error main">
            {formik.errors.text.toString()}
          </p>
        }
        <div className="container_underline" />
      </div>*/}


      {/*IMAGES */}
      <div
        style={{position: "relative"}}
        onMouseEnter={() => dispatch(setTooltip("images"))}
        onMouseLeave={() => dispatch(setTooltip(""))}
        onBlur={formik.handleBlur("image")}
        data-testid="images_form"
      >
        <p className="simple_label_checklist">
          {t("partner_portal.deals.create.main_form.form.local_images_title")}
        </p>
        <p className="sub_label_checklist">
          {t("partner_portal.deals.create.main_form.form.images_text")}
        </p>
        {formik.values.image === "1"
          ?
          <>
            <div className="image_btn">
              <img src={uploadIcon} alt="upload"/>
              <input
                type="file"
                id="images_btn"
                hidden
                multiple
                onChange={(e:any) => fileSelectedHandler("images", e, "at")}
              />
              <label htmlFor="images_btn">
                {formik.values.uploadedImages.length
                  ? formik.values.multipleImages
                    ? t("partner_portal.deals.create.main_form.form.images_btn_filled",
                      {files: formik.values.uploadedImages.length})
                    : t("partner_portal.deals.create.main_form.form.images_btn_filled",
                      {files: formik.values.uploadedImages.length})
                  : formik.values.multipleImages
                    ? t("partner_portal.deals.create.main_form.form.images_btn_empty_countries",
                      // eslint-disable-next-line max-len
                      {country: t("partner_portal.deals.create.main_form.form.images_btn_empty_countries_at")})
                    : t("partner_portal.deals.create.main_form.form.images_btn_empty")
                }
              </label>
            </div>
            {formik.values.multipleImages
              ? <div className="image_btn" style={{top: "20%"}}>
                <img src={uploadIcon} alt="upload"/>
                <input
                  type="file"
                  id="images_btn_de"
                  hidden
                  multiple
                  onChange={(e:any) => fileSelectedHandler("images", e, "de")}
                />
                <label htmlFor="images_btn_de">
                  {formik.values.uploadedImagesDE.length
                    ? formik.values.multipleImages
                      ? t("partner_portal.deals.create.main_form.form.images_btn_filled",
                        {files: formik.values.uploadedImagesDE.length})
                      : t("partner_portal.deals.create.main_form.form.images_btn_filled",
                        {files: formik.values.uploadedImagesDE.length})
                    : formik.values.multipleImages
                      ? t("partner_portal.deals.create.main_form.form.images_btn_empty_countries",
                        // eslint-disable-next-line max-len
                        {country: t("partner_portal.deals.create.main_form.form.images_btn_empty_countries_de")})
                      : t("partner_portal.deals.create.main_form.form.images_btn_empty")
                  }
                </label>
              </div>
              : null
            }
          </>
          : null
        }
        <RadioGroup
          errors={formik.errors.image}
          touched={formik.touched.image}
          onChange={(value:string) => {
            formik.setFieldValue("image", value);
          }}
          selected={formik.values.image}
          onBlur={formik.handleBlur}
          buttons={[
            {
              groupName: "image",
              value: "0",
              label: "partner_portal.deals.create.main_form.form.images_labels.1",
            },
            {
              groupName: "image",
              value: "1",
              label: "partner_portal.deals.create.main_form.form.images_labels.2",
              description: "partner_portal.deals.create.main_form.form.images_labels.sub",
              multiCountry: stateValues[0].country.length === 2,
              countriesTitle: "partner_portal.deals.create.main_form.form.images_countries_title"
            }
            /*{
              groupName: "image",
              value: "2",
              label: "partner_portal.deals.create.main_form.form.images_labels.3",
            }*/
          ]}
          multiCountrySelect={formik.values.multipleImages}
          setMultiCountrySelect={(val:boolean) =>
            formik.setFieldValue("multipleImages", val, true)
          }
        />
        {
          formik.errors.image
          && formik.touched.image
          && <p className="input_error main">
            {formik.errors.image.toString()}
          </p>
        }
        {
          subErrors.images
          && formik.touched.image
          && <p className="input_error main">
            {subErrors.images}
          </p>
        }
        <div className="container_underline" />
      </div>

      {/*LOGO*/}
      <div
        onMouseEnter={() => dispatch(setTooltip("logo"))}
        onMouseLeave={() => dispatch(setTooltip(""))}
        style={{position: "relative"}}
        onBlur={formik.handleBlur("logo")}
        data-testid="logo_form"
      >
        <div className="image_btn">
          <img src={uploadIcon} alt="upload"/>
          <input
            type="file"
            id="logo_btn"
            hidden
            multiple
            onChange={(e:any) => fileSelectedHandler("logo", e)}
          />
          <label htmlFor="logo_btn">
            {formik.values.logoImage.length
              ? t("partner_portal.deals.create.main_form.form.images_btn_filled",
                {files: formik.values.logoImage.length})
              : t("partner_portal.deals.create.main_form.form.images_btn_empty")
            }
          </label>
        </div>
        <p className="simple_label_checklist">
          {t("partner_portal.deals.create.main_form.form.logo_title")}
        </p>
        <p className="sub_label_checklist">
          {t("partner_portal.deals.create.main_form.form.logo_labels.2_sub")}
        </p>
        {
          formik.errors.logo
          && formik.touched.logo
          && <p className="input_error main">
            {formik.errors.logo.toString()}
          </p>
        }
        {
          subErrors.logo
          && formik.touched.logo
          && <p className="input_error main">
            {subErrors.logo}
          </p>
        }
        <div className="container_underline" />
      </div>

      {/*FEATURES*/}
      <div
        style={{position: "relative"}}
        onBlur={formik.handleBlur("feature")}
        data-testid="features_form"
      >
        <div className="image_btn">
          <img src={uploadIcon} alt="upload"/>
          <input
            type="file"
            id="features_btn"
            hidden
            multiple
            onChange={(e:any) => fileSelectedHandler("feature", e, "at")}
          />
          <label htmlFor="features_btn">
            {formik.values.featureImage.length
              ? formik.values.multipleFeature
                ? t("partner_portal.deals.create.main_form.form.images_btn_filled",
                  {files: formik.values.featureImage.length})
                : t("partner_portal.deals.create.main_form.form.images_btn_filled",
                  {files: formik.values.featureImage.length})
              : formik.values.multipleFeature
                ? t("partner_portal.deals.create.main_form.form.images_btn_empty_countries",
                  // eslint-disable-next-line max-len
                  {country: t("partner_portal.deals.create.main_form.form.images_btn_empty_countries_at")})
                : t("partner_portal.deals.create.main_form.form.images_btn_empty")
            }

          </label>
        </div>
        {formik.values.multipleFeature
          ? <div className="image_btn" style={{top: "15%"}}>
            <img src={uploadIcon} alt="upload"/>
            <input
              type="file"
              id="features_btn_de"
              hidden
              multiple
              onChange={(e:any) => fileSelectedHandler("feature", e, "de")}
            />
            <label htmlFor="features_btn_de">
              {formik.values.featureImageDE.length
                ? formik.values.multipleFeature
                  ? t("partner_portal.deals.create.main_form.form.images_btn_filled",
                    {files: formik.values.featureImageDE.length})
                  : t("partner_portal.deals.create.main_form.form.images_btn_filled",
                    {files: formik.values.featureImageDE.length})
                : formik.values.multipleFeature
                  ? t("partner_portal.deals.create.main_form.form.images_btn_empty_countries",
                    // eslint-disable-next-line max-len
                    {country: t("partner_portal.deals.create.main_form.form.images_btn_empty_countries_de")})
                  : t("partner_portal.deals.create.main_form.form.images_btn_empty")
              }
            </label>
          </div>
          : null
        }

        <p className="simple_label_checklist">
          {t("partner_portal.deals.create.main_form.form.local_features_title")}
        </p>
        <p className="sub_label_checklist" data-testid="feature_sub_label">
          {t("partner_portal.deals.create.main_form.form.local_features_text")}
        </p>
        <div className="features_options_container">
          <div>
            <p className="sub_label_checklist">
              {t("partner_portal.deals.create.main_form.form.feature_labels.1")}
            </p>
            <p className="feature_description">
              {t("partner_portal.deals.create.main_form.form.feature_labels.1_sub")}
            </p>
          </div>
          <div>
            <p className="sub_label_checklist">
              {t("partner_portal.deals.create.main_form.form.feature_labels.2")}
            </p>
            <p className="feature_description">
              {t("partner_portal.deals.create.main_form.form.feature_labels.2_sub")}
            </p>
          </div>
          <div>
            <p className="sub_label_checklist">
              {t("partner_portal.deals.create.main_form.form.feature_labels.3")}
            </p>
            <p className="feature_description">
              {t("partner_portal.deals.create.main_form.form.feature_labels.3_sub")}
            </p>
          </div>
        </div>
        <p style={{fontSize: "12px"}} data-testid="default_features_text">
          {t("partner_portal.deals.create.main_form.form.feature_labels.text_1")}
          <strong>{t("partner_portal.deals.create.main_form.form.feature_labels.text_2")}</strong>
          {t("partner_portal.deals.create.main_form.form.feature_labels.text_3")}
        </p>
        {stateValues[0].country.length === 2
          ? <Checkbox
            checked={formik.values.multipleFeature}
            onChange={() => {
              formik.setFieldValue(
                "multipleFeature",
                !formik.values.multipleFeature,
                true
              );
            }}
          >
            {t("partner_portal.deals.create.main_form.form.images_countries_title")}
          </Checkbox>
          : null
        }
        {
          formik.errors.feature
          && formik.touched.feature
          && <p style={{bottom: "-20px"}} className="input_error main">
            {formik.errors.feature.toString()}
          </p>
        }
        {
          subErrors.feature
          && formik.touched.feature
          && <p style={{bottom: "-20px"}} className="input_error main">
            {subErrors.feature}
          </p>
        }
      </div>
      <div className="container_underline" />
    </form>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    tooltip: state.tooltip,
    stateValues: state.steps.values
  };
};

export default connect(mapStateToProps, null)(LocalMainForm);
