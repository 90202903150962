import "./Benefitsummary.css";
import React, { useEffect, useState } from "react";
import Button from "../Buttons/Button";
import { useTranslation } from "react-i18next";

interface CustomProps {
  sum: number;
  handleSubmit: () => void;
  disableSubmitButton: boolean;
  plans: any;
}

export default function BenefitSummary({
  sum,
  handleSubmit,
  disableSubmitButton,
  plans
}: CustomProps) {
  const { t } = useTranslation();

  const [tooltipMessage, setTooltipMessage] = useState("");

  useEffect(() => {
    // Tooltip if no plans
    if (plans.length === 0) {
      setTooltipMessage(t("warnings.plans_tool_plan"));
    }

    // Tooltip if name is missing
    if (plans.length) {
      plans.forEach((plan: any) => {
        if (plan.title.trim().length === 0) {
          setTooltipMessage(t("warnings.plans_tool_title"));
        }
      });
    }

    // Tooltip if employee is missing
    if (plans.length) {
      plans.forEach((plan: any) => {
        if (!plan.employee.length) {
          setTooltipMessage(t("warnings.plans_tool_employee"));
        }
      });
    }
    // Disable re-renders
    // eslint-disable-next-line
  }, [plans]);

  const amount = sum.toFixed(2);

  return (
    <div className="summary_container">
      <h2 className="benefit_summary_title">
        {t("dashboard.plans.summary_title")}
      </h2>
      <div className="plans_container_header space-between">
        <p className="summary_count_text">
          {t("dashboard.plans.summary_count_text")}
        </p>
        <p data-testid="summary_count_text" className="summary_count_text">
          € {amount}
        </p>
      </div>
      <div className="summary_total">
        <h2>{t("dashboard.plans.total")} €</h2>
        <h2 data-testid="summary_amount">{amount}</h2>
      </div>
      <p style={{ marginBottom: "10px" }} className="flex-end">
        {t("dashboard.plans.summary_total_notif")}
      </p>
      <div style={{ position: "relative" }}>
        <Button
          disabled={disableSubmitButton || sum === 0}
          buttonType="primary with_tooltip"
          buttonHtmlType="submit"
          fullWidth={true}
          buttonLabel={t("dashboard.employee.employee_modal_btn_2")}
          buttonHandler={handleSubmit}
        />
        {disableSubmitButton ? (
          <div className="tooltip_black">
            <p>{tooltipMessage}</p>
          </div>
        ) : null}
      </div>
    </div>
  );
}
