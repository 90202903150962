import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import ContentContainer from "../../Components/Containers/ContentContainer";
import MainSpace from "../../Components/Containers/MainSpace";
import {
  changeSidebarTab,
  handleSidebar,
} from "../../redux/actions/sidebarActions";
import PlansList from "../../Components/Lists/PlansList";
import PageTitle from "../../Components/SimpleComponents/PageTitle";
import ButtonWithIcon from "../../Components/Buttons/ButtonWithIcon";
import useWindowDimensions from "../../utils/useWindowSize";
import { toast } from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../utils/permissionsAllow";
import { useTranslation } from "react-i18next";
import {
  clearDeactivatePlanMessages,
  plansData,
  showPlansLoading,
} from "../../redux/actions/plansActions";
import SearchBarLight from "../../Components/SimpleComponents/SearchBarLight";
import TableLoading from "../../Components/LoadingPlaceholders/TableLoading";
import queryString from "query-string";
import { useNavigate, useLocation } from "react-router-dom";
import FilteringBlock from "../../Components/Blocks/FilteringBlock";
import PlansFilters from "../../Components/Blocks/PlansFilters/PlansFilters";
import {changePlansFiltering} from "../../redux/actions/filteringActions";
import CustomSelect from "Components/SimpleComponents/Select";
import { urls } from "api/urls";
import axios from "axios";
import environment from "environment";
import { getToken } from "utils/cookies/tokensCookie";
import { DOWNLOAD_FILES_SUCCESS } from "redux/types/downloadFilesTypes";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

interface CustomProps {
  plans?: any;
  departments?: any;
  plansMessage?: any;
  plansLoading?: any;
  filteringState?: any;
}

function PlansScreen({
  plans,
  departments,
  plansMessage,
  plansLoading,
  filteringState
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();

  const parsedSearch = queryString.parse(location.search);

  const statusOptions = [
    {
      value: "",
      label: t("dashboard.plans.select_opt_all"),
    },
    {
      value: "true",
      label: t("dashboard.plans.select_opt_active"),
    },
    {
      value: "false",
      label: t("dashboard.plans.select_opt_not_active"),
    },
  ];

  useEffect(() => {
    dispatch(changeSidebarTab("plans"));
    dispatch(showPlansLoading());
    dispatch(
      plansData({
        limit: 8,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
        sort: "-startDate"
      })
    );
  }, []);

  // Refresh table data after activate/deactivate
  useEffect(() => {
    if (plansMessage.success === "deactivate_plan") {
      dispatch(
        plansData({
          limit: 8,
          skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
          sort: "-startDate"
        })
      );
      clearDeactivatePlanMessages();
      toast.success(
        <CustomToastContainer
          message={t("warnings.plans_updated")}
          status={1}
        />,
        {
          autoClose: 5000,
        }
      );
    } else if (plansMessage.error === "deactivate_plan") {
      toast.error(
        <CustomToastContainer
          message={t("warnings.plans_update_error")}
          status={1}
        />,
        {
          autoClose: 5000,
        }
      );
      clearDeactivatePlanMessages();
    }
    //Need to disable it, because eslint says, that I need to add
    //limit as deps but it will cause to many calls for this function
    // eslint-disable-next-line
  }, [plansMessage.success, plansMessage.error]);

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 1000) {
      dispatch(handleSidebar(false));
    }
  }, [width, dispatch]);

  const handleSearch = (value: string) => {
    const updatedState = { ...filteringState, search: value };
    dispatch(showPlansLoading());
    dispatch(changePlansFiltering(updatedState));
    dispatch(plansData(updatedState));
  };

  const [selectedPlans, setSelectedPlans] = useState<any>([]);
  const handleDownload = (option: string) => {
    let type: string;
    if (option === "xlsx") {
      type = "1";
	  }
    if (option === "csv") {
      type = "2";
    }

    const postData = async () => {
      await axios.get(
        `${environment.baseUrl}${urls.files.generateReportForUser}`,
        {
		  headers: {Authorization: `Bearer ${getToken()}`},
          responseType: "blob",
		  params: {
        	exportType: type,
            companyId: getUserCompany(),
            plans: selectedPlans.map((plan: any) => plan.plan._id)
		  }
        }
		  ).then((res: any) => {
        try{
          let fileName = "Corplife_invoices.csv";
          let fileType = "application/csv";

          if(selectedPlans.length > 1){
            //more than one plan - default name
		  fileName = "Corplife_invoices";
          }else{
            fileName = selectedPlans[0].plan.title;
          }
          if (type === "1") {
            fileName += ".xlsx";
            fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          }
          if (type === "2") {
            fileName += ".csv";
            fileType = "application/csv";
          }

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file:any;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();
          dispatch({
            type: DOWNLOAD_FILES_SUCCESS,
            payload: res,
          });

        }catch(e){
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
        }
	  });
    };

    if(selectedPlans.length > 0){
      postData();
    }else{
      toast.error(
        <CustomToastContainer
          message={t("warnings.plans_download_no_selection")}
          status={2}
        />,
        {
          autoClose: 5000,
        }
		  );
    }



    // {
    // 	exportType: “1” | “2",
    // 	plans: [string]
    // }
  };

  /*const handleChoice = (options: any) => {
    if (options.value !== "") {
      dispatch(showPlansLoading());
      dispatch(
        plansData({
          limit: 8,
          skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
          active: options.value,
        })
      );
    } else {
      dispatch(showPlansLoading());
      dispatch(
        plansData({
          limit: 8,
          skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * 8,
        })
      );
    }
  };*/

  return (
    <ContentContainer>
      <MainSpace>
        <div
          className="space-between_plans"
          style={{ marginBottom: "15px", alignItems: "start" }}
        >
          <PageTitle mainTitle={t("dashboard.plans.plans_title")} />
          {isAllowed({
            data: {
              roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.plans],
              },
            },
          }) ? (
              <div className="flex-end">
			  <ButtonWithIcon
                  buttonType="primary add-plan"
                  buttonIcon="add_plan"
                  paddingTop="20px"
                  buttonHandler={() => navigate("/plans/create")}
                />
			  <CustomSelect
			  options={[t("dashboard.overview.export") as string, "csv", "xlsx"]}
			  icon="export"
			  onChange={handleDownload}
                />
			  </div>

            ) : null}
        </div>
        <div className="header_plans">

          <div />

          <SearchBarLight
            searchPlaceholder={t("dashboard.plans.search_placeholder")}
            handleSearchBar={handleSearch}
          />
        </div>

        <FilteringBlock title={t("dashboard.plans.filters.title")}>
          <PlansFilters />
        </FilteringBlock>

        {!plans.plans ? (
          <TableLoading />
        ) : (
          <PlansList
            plansDataProps={plans.plans}
            totalCount={plans.total}
            departments={departments}
            parsedSearch={parsedSearch}
            callBackSelections={(plans) => setSelectedPlans(plans)}
            filteringState={filteringState}
          />
        )}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    plans: state.plans.plans,
    departments: state.depsAndBranches.departments,
    plansMessage: state.plans,
    plansLoading: state.plans.loading,
    filteringState: state.filters.plansFilters
  };
};

export default connect(mapStateToProps, null)(PlansScreen);
