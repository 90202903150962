import {ICompany} from "../api/services/Company/company.interface";

interface IModuleIsActivated {
  company: ICompany;
  module: "PartnerPortal";
  magentoUser?: any;
}

export default function moduleIsActivated(params: IModuleIsActivated): boolean {
  let isActivated = false;
  if (params.company?.modules && params.company?.modules?.length > 0) {
    params.company.modules.map((module) => {
      if (module.name === params.module && module.isActivated) {
        if (params.module === "PartnerPortal" && params.magentoUser && params.magentoUser.length > 0 && params.magentoUser[2] === "partnerPortal") {
          isActivated = true;
        } else {
          isActivated = true;
        }
      }
    });
  } else if (params.module === "PartnerPortal" && params.magentoUser && params.magentoUser.length > 0 && params.magentoUser[2] === "partnerPortal") {
    isActivated = true;
  }
  return isActivated;
}
