import React, {useEffect, useState} from "react";
import "./styles.css";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getToken} from "../../../../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../../../../environment";
import {urls} from "../../../../../../../../api/urls";
import FormikInput from "../../../../../../../SimpleComponents/FormikInput";
import {RootState} from "../../../../../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import OutgoingWireTransferModal from "Components/Modals/PaymentsPage/OutgoingWireTransferModal/OutgoingWireTransferModal";

interface IProps {
  weavr?: any;
  sendTransfer: () => void;
  transactionId: string;
  setTransactionFormIsValid: (valid: boolean) => void;
  setTransferData: (data: any) => void;
  transferLayoutState: null | "check" | "verification"
  setTransferLayoutState: (state: null | "check" | "verification") => void;
}

function PaymentOverviewHeaderContainerAccountTabTransfer(props:IProps) {
  const { t } = useTranslation();

  const [modalState, setModalState] = useState<"" | "verification" | "success">("");
  const [verificationCode, setVerificationCode] = useState("");
  const [buttonLoading, setButtonLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showOutgoingWireTransferModal, setShowOutgoingWireTransferModal] = useState(false);

  useEffect(() => {
    props.setTransferLayoutState((showOutgoingWireTransferModal as any));
  }, [showOutgoingWireTransferModal]);

  useEffect(() => {
    if (props.transferLayoutState) {
      setModalState((props.transferLayoutState as any));
      setShowOutgoingWireTransferModal(true);
    }
  }, [props.transferLayoutState]);

  const formik = useFormik({
    initialValues: {
      amount: 0,
      destinationBeneficiary: {
        name: "",
        bankAccountDetails: {
          iban: "",
          bankIdentifierCode: ""
        }
      },
      description: ""
    },
    validationSchema: Yup.object().shape({
      amount: Yup.number().required(t("formik_warnings.payments.amount")).min(0.01, t("formik_warnings.payments.min_amount", {number: 0.01})),
      destinationBeneficiary: Yup.object().shape({
        name: Yup.string().required(t("formik_warnings.payments.name")),
        bankAccountDetails: Yup.object().shape({
          iban: Yup.string().required(t("formik_warnings.payments.iban")),
          bankIdentifierCode: Yup.string().required(t("formik_warnings.payments.bic"))
        })
      }),
      description: Yup.string().max(35, "Max 35 characters")
    }),
    onSubmit: async (values) => {
      const weavrToken = await getToken(true);
      props.sendTransfer();
    },
  });

  const handleSendVerificationCode = async () => {
    const weavrToken = await getToken(true);
    const userToken = await getToken();
    axios.post(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.verifyAnOutgoingWireTransferTransaction}`,
      {
        transactionId: props.transactionId,
        code: verificationCode,
        corporateToken: weavrToken
      },
      {
        headers: {Authorization: `Bearer ${userToken}`}
      })
      .then((res: any) => {
        if (res.data) {
          setModalState("success");
        }
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data.error);
      });
  };

  useEffect(() => {
    props.setTransferData(formik.values);
    props.setTransactionFormIsValid(formik.isValid && formik.dirty);
  }, [formik]);

  return(
    <div>

      <OutgoingWireTransferModal
        showModal={showOutgoingWireTransferModal}
        onClose={(clearState?: boolean) => {
          setShowOutgoingWireTransferModal(false);
          if (clearState) {
            formik.resetForm();
          }
        }}
        amount={formik.values.amount}
        receiverName={formik.values.destinationBeneficiary.name}
        iban={formik.values.destinationBeneficiary.bankAccountDetails.iban}
        onSubmit={() => props.sendTransfer()}
        modalState={(modalState as any)}
        transactionId={props.transactionId}
      />

      <div className="transfer_header">
        <p>
          {t("dashboard.payments.overview.header_component.tabs_content.account_info.transfer_layout.form.header")}
        </p>
      </div>
      <form onSubmit={formik.handleSubmit}>

        <div className="row">
          <div
            className="relative"
            style={{ width: "90%"}}
          >
            <FormikInput
              style={{ width: "100%" }}
              htmlFor="amount"
              name="amount"
              value={formik.values.amount.toString()}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.payments.overview.outgoing_payment_modal.labels.amount")}
              type={"number"}
              onBlur={formik.handleBlur}
            />
            {formik.errors?.amount && formik.touched?.amount && (
              <p className="input_warning">{formik.errors?.amount}</p>
            )}
          </div>

          <div
            className="relative"
            style={{ width: "90%", marginLeft: "20px"}}
          >
            <FormikInput
              style={{ width: "100%" }}
              htmlFor="destinationBeneficiary.name"
              name="destinationBeneficiary.name"
              value={formik.values.destinationBeneficiary.name}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.payments.overview.outgoing_payment_modal.labels.person_name")}
              onBlur={formik.handleBlur}
            />
            {formik.errors?.destinationBeneficiary?.name && formik.touched?.destinationBeneficiary?.name && (
              <p className="input_warning">{formik.errors?.destinationBeneficiary?.name}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div
            className="relative"
            style={{ width: "90%"}}
          >
            <FormikInput
              style={{ width: "100%" }}
              htmlFor="destinationBeneficiary.bankAccountDetails.iban"
              name="destinationBeneficiary.bankAccountDetails.iban"
              value={formik.values.destinationBeneficiary.bankAccountDetails.iban}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.payments.overview.outgoing_payment_modal.labels.iban")}
              onBlur={formik.handleBlur}
            />
            {formik.errors?.destinationBeneficiary?.bankAccountDetails?.iban && formik.touched?.destinationBeneficiary?.bankAccountDetails?.iban && (
              <p className="input_warning">{formik.errors?.destinationBeneficiary?.bankAccountDetails?.iban}</p>
            )}
          </div>

          <div
            className="relative"
            style={{ width: "90%", marginLeft: "20px"}}
          >
            <FormikInput
              style={{ width: "100%" }}
              htmlFor="destinationBeneficiary.bankAccountDetails.bankIdentifierCode"
              name="destinationBeneficiary.bankAccountDetails.bankIdentifierCode"
              value={formik.values.destinationBeneficiary.bankAccountDetails.bankIdentifierCode}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.payments.overview.outgoing_payment_modal.labels.bic")}
              onBlur={formik.handleBlur}
            />
            {formik.errors?.destinationBeneficiary?.bankAccountDetails?.bankIdentifierCode && formik.touched?.destinationBeneficiary?.bankAccountDetails?.bankIdentifierCode && (
              <p className="input_warning">{formik.errors?.destinationBeneficiary?.bankAccountDetails?.bankIdentifierCode}</p>
            )}
          </div>
        </div>

        <div className="row">
          <div
            className="relative"
            style={{ width: "90%"}}
          >
            <FormikInput
              style={{ width: "100%" }}
              htmlFor="description"
              name="description"
              value={formik.values.description}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.payments.overview.outgoing_payment_modal.labels.description")}
              onBlur={formik.handleBlur}
            />
            {formik.errors?.description && formik.touched?.description && (
              <p className="input_warning">{formik.errors?.description}</p>
            )}
          </div>

          <div
            className="relative"
            style={{ width: "90%", marginLeft: "20px"}}
          />

        </div>

      </form>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    weavr: state.weavr.weavrCorporate
  };
};

export default connect(mapStateToProps, null)(PaymentOverviewHeaderContainerAccountTabTransfer);

