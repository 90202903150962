import React, {useEffect, useState} from "react";
import { useTranslation } from "react-i18next";

interface CustomProps {
	options: any,
	setCustomSettings: (settings:any) => void,
	leftSide?: boolean,
  user: any
}

export default function PermissionCustomRoleMenu(
  {options, setCustomSettings, leftSide, user}:CustomProps) {
  const { t } = useTranslation();
  // Texts for custom settings
  const texts = [
    {text: t("dashboard.settings.permissions.custom_text_1")},
    {text: t("dashboard.settings.permissions.custom_text_2")},
    {text: t("dashboard.settings.permissions.custom_text_3")},
  ];

  const [optionsArray, setOptionsArray] = useState<any>([]);

  useEffect(() => {
    if (options.length) {
      const ObjCopy = [...options[0]];
      if (user.role.permissions.length) {
        Object.keys(options[0]).forEach((option:any, i:number) => {
          ObjCopy[i].isActive = user.role.permissions[i].isActive;
          ObjCopy[i].isAdmin = user.role.permissions[i].isAdmin;
        });
      }
      setOptionsArray(ObjCopy);
      setCustomSettings(ObjCopy);
    }
    //Need to disable it, because eslint says, that I need to add
    //deps, but I need to use it like ComponentDidMount
    // eslint-disable-next-line
	}, [])

  const handleRadioChange = (i:number, con:boolean | string) => {
    const newArray:any = [...optionsArray];
    if (con === "admin") {
      newArray[i] = {...newArray[i], isAdmin: !newArray[i].isAdmin, isActive: false};
    } else {
      newArray[i] = {...newArray[i], isActive: con, isAdmin: false};
    }
    setOptionsArray(newArray);
    setCustomSettings(newArray);
  };

  const translateOptionNames = (option:string) => {
    switch (option) {
    case "General Info":
      return t("dashboard.settings.permissions.custom_title_1");
    case "Plans":
      return t("dashboard.settings.permissions.custom_title_2");
    case "Employee":
      return t("dashboard.settings.permissions.custom_title_3");
    case "Orders":
      return t("dashboard.settings.permissions.custom_title_4");
    case "Settings":
      return t("dashboard.settings.permissions.custom_title_5");

    default:
      return option;
    }
  };

  const optionsText = (option:string) => {
    switch (option) {
    case "General Info":
      return t("dashboard.settings.permissions.custom_text_1");
    case "Plans":
      return t("dashboard.settings.permissions.custom_text_2");
    case "Employee":
      return t("dashboard.settings.permissions.custom_text_3");
    case "Orders":
      return t("dashboard.settings.permissions.custom_text_4");
    case "Settings":
      return t("dashboard.settings.permissions.custom_text_5");

    default:
      return option;
    }
  };

  return (
    <div className={leftSide
      ? "permission_custom_menu_left shadow"
      : "permission_custom_menu shadow"}>
      {optionsArray.map((option:any, i:number) => (
        <div className="perm_menu_box" key={option.name}>
          <div>
            <h4>{translateOptionNames(option.name)}</h4>
            {option.type === 2 || option.type === 3
              ? <p>{texts[option.type - 2].text}</p>
              : null}
            <p>
              {optionsText(option.name)}
            </p>
          </div>
          <div className="row">
            <div className="perm_radio">
              <label>{t("dashboard.settings.permissions.no")}</label>
              <input
                type="radio"
                value={`no_${option.type}`}
                name={option.type}
                checked={!option.isActive && !option.isAdmin}
                onChange={() => handleRadioChange(i, false)}
              />
            </div>

            <div className="perm_radio">
              <label>{t("dashboard.settings.permissions.viewer")}</label>
              <input
                type="radio"
                value={`yes_${option.type}`}
                name={option.type}
                onChange={() => handleRadioChange(i, true)}
                checked={option.isActive}
              />
            </div>

            <div className="perm_radio">
              <label>{t("dashboard.settings.permissions.admin")}</label>
              <input
                type="radio"
                value={`admin_${option.type}`}
                name={option.type}
                checked={option.isAdmin}
                onChange={() => handleRadioChange(i, "admin")}
              />
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
