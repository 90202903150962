import React, { useState } from "react";
import "../SimpleComponents/TableSelectedItemActions.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import deactivateCardIcon from "img/Dashboard/Payments/Overview/CardDetail/freeze_card.png";
import { getToken } from "utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../environment";
import { urls } from "../../api/urls";
import { getCard } from "../../redux/actions/paymentsActions";
import { useDispatch } from "react-redux";

interface IProps {
  cardIsActive: boolean;
}

export default function CardDetailsSelectedMenu(props: IProps) {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const dispatch = useDispatch();

  const [currentPosts, setCurrentPosts] = useState([]);

  const toggleCardStatus = async () => {
    setShowMenu(false);
    const token = await getToken();
    const weavrToken = await getToken(true);
    let response;
    if (!props.cardIsActive) {
      response = await axios.post(
        // eslint-disable-next-line max-len
        `${environment.baseUrl}${urls.payments.unblockCard}/${
          location.pathname.split("/")[3]
        }?token=${weavrToken}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } else {
      response = await axios.post(
        // eslint-disable-next-line max-len
        `${environment.baseUrl}${urls.payments.blockCard}/${
          location.pathname.split("/")[3]
        }?token=${weavrToken}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    }
    if (response && response.status === 200) {
      dispatch(getCard(location.pathname.split("/")[3]));
    }
  };

  return (
    <div className="selected_table_actions">
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={
          showMenu ? "selected_actions_dots selected" : "selected_actions_dots"
        }
      >
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
      </div>
      <div
        className={
          showMenu
            ? "selected_actions_menu shadow"
            : "selected_actions_menu_hide"
        }
        style={{ bottom: -51 }}
      >
        <div onClick={() => toggleCardStatus()}>
          <img src={deactivateCardIcon} alt="deactivate" />
          <p>
            {props.cardIsActive
              ? t("dashboard.payments.card_detail.actions.deactivate")
              : t("dashboard.payments.card_detail.actions.activate")}
          </p>
        </div>
      </div>
    </div>
  );
}
