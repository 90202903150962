import React, {useEffect, useState} from "react";
import "./PaymentsOverviewHeaderContainerAccountInfoTab.css";
import {useTranslation} from "react-i18next";
import {getToken} from "../../../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../../../environment";
import {urls} from "../../../../../../../api/urls";
import LoadingText from "../../../../../../LoadingPlaceholders/LoadingText";
import {Line} from "react-chartjs-2";
import Button from "../../../../../../Buttons/Button";
import OutgoingWireTransferModal from "Components/Modals/PaymentsPage/OutgoingWireTransferModal/OutgoingWireTransferModal";
import PaymentOverviewHeaderContainerAccountTabCardsLayout
  from "./Layouts/PaymentOverviewHeaderContainerAccountTabCardsLayout";
import PaymentOverviewHeaderContainerAccountTabTransfer
  from "./Layouts/PaymentOverviewHeaderContainerAccountTabTransfer";
import {RootState} from "../../../../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";

export interface ICardData {
  key: string;
  cardContainerBottomBorder: boolean;
  cardNestedRightBorder: boolean;
  cardTitle: string;
  cardData: string | undefined;
  paddingLeft: boolean;
}

interface IProps {
  weavr?: any;
}

function PaymentsOverviewHeaderContainerAccountInfoTab(props: IProps) {
  const { t } = useTranslation();

  const [cardsData, setCardsData] = useState<ICardData[]>([
    {
      key: "1",
      cardContainerBottomBorder: true,
      cardNestedRightBorder: true,
      cardTitle: t("dashboard.payments.overview.header_component.tabs_content.account_info.cards.account_created"),
      cardData: undefined,
      paddingLeft: false
    },
    {
      key: "2",
      cardContainerBottomBorder: true,
      cardNestedRightBorder: false,
      cardTitle: t("dashboard.payments.overview.header_component.tabs_content.account_info.cards.bank_name"),
      cardData: undefined,
      paddingLeft: true
    },
    {
      key: "3",
      cardContainerBottomBorder: true,
      cardNestedRightBorder: true,
      cardTitle: t("dashboard.payments.overview.header_component.tabs_content.account_info.cards.beneficiary_bank"),
      cardData: undefined,
      paddingLeft: false
    },
    {
      key: "4",
      cardContainerBottomBorder: true,
      cardNestedRightBorder: false,
      cardTitle: t("dashboard.payments.overview.header_component.tabs_content.account_info.cards.iban"),
      cardData: undefined,
      paddingLeft: true
    },
    {
      key: "5",
      cardContainerBottomBorder: false,
      cardNestedRightBorder: true,
      cardTitle: t("dashboard.payments.overview.header_component.tabs_content.account_info.cards.bank_address"),
      cardData: undefined,
      paddingLeft: false
    },
    {
      key: "6",
      cardContainerBottomBorder: false,
      cardNestedRightBorder: false,
      cardTitle: t("dashboard.payments.overview.header_component.tabs_content.account_info.cards.bic"),
      cardData: undefined,
      paddingLeft: true
    }
  ]);
  const [chartLabels, setChartLabels] = useState([1, 2, 3, 4, 5, 6, 7, 8, 9]);
  const [spendData, setSpendData] = useState([
    0, 0, 0, 0, 0, 0, 0, 0, 0,
  ]);
  const [showOutgoingWireTransferLayout, setShowOutgoingWireTransferLayout] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [transferLayoutState, setTransferLayoutState] = useState<null | "check" | "verification">(null);
  const [transactionFormIsValid, setTransactionFormIsValid] = useState(false);
  const [transferData, setTransferData] = useState<any>(null);

  const getData = async () => {
    const savedToken = await getToken(true);
    const userToken = await getToken();
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.statistic}?token=${savedToken}`,
      {
        headers: { Authorization: `Bearer ${userToken}` }
      });
    if (response && response.data) {
      const infoKeys = ["createdAt", "beneficiaryNameAndSurname", "beneficiaryBank", "iban", "beneficiaryBankAddress", "bic"];
      const newInfoForCards = cardsData.map((card: ICardData, idx: number) => {
        return {...card, cardData: response.data.accountInfo[infoKeys[idx]]};
      });
      setCardsData(newInfoForCards);
      setChartLabels(response.data.labels);
      setSpendData(response.data.values);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  const sendOutgoingTransferModal = async () => {
    const userToken = await getToken();
    const weavrToken = await getToken(true);
    axios.post(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.createAnOutgoingWireTransferTransaction}`,
      {
        ...transferData,
        corporateToken: weavrToken,
        managedAccount: props.weavr.managedAccountId
      },
      {
        headers: {Authorization: `Bearer ${userToken}`}
      })
      .then((res: any) => {
        setTransactionId(res.data.id);
        setErrorMessage("");
        setTransferLayoutState("verification");
      })
      .catch((err: any) => {
        setErrorMessage(err.response.data.message);
      });
  };

  return(
    <div className="payment_overview_header_container_tab_content_container">

      {/*<OutgoingWireTransferModal
        showModal={showOutgoingWireTransferModal}
        onClose={() => setShowOutgoingWireTransferModal(false)}
      />*/}

      <div className="space-between">
        <div>
          <h3 className="payment_overview_header_container_tab_content_container_title">
            {t("dashboard.payments.overview.header_component.tabs_content.account_info.title")}
          </h3>

          <p className="payment_overview_header_container_tab_content_container_sub_title">
            {t("dashboard.payments.overview.header_component.tabs_content.account_info.sub_title")}
          </p>
        </div>

        {showOutgoingWireTransferLayout
          ? <div className="row">
            <p
              className="payment_overview_header_container_tab_content_container_cancel_btn"
              onClick={() => setShowOutgoingWireTransferLayout(false)}
            >
              {t("dashboard.payments.overview.header_component.tabs_content.account_info.transfer_layout.cancel_btn")}
            </p>
            <Button
              buttonType="primary"
              buttonLabel={t("dashboard.payments.overview.header_component.tabs_content.account_info.transfer_layout.next_btn")}
              buttonHandler={() => setTransferLayoutState("check")}
              buttonStyles={{
                backgroundColor: "#16B477",
                minWidth: "205px"
              }}
              disabled={!transactionFormIsValid}
            />
          </div>
          : <Button
            buttonType="primary"
            buttonLabel={t("dashboard.payments.overview.header_component.tabs_content.account_info.create_transfer_btn")}
            buttonHandler={() => setShowOutgoingWireTransferLayout(true)}
            buttonIcon="add"
            buttonStyles={{
              backgroundColor: "#16B477"
            }}
          />
        }

      </div>

      {showOutgoingWireTransferLayout
        ? <PaymentOverviewHeaderContainerAccountTabTransfer
          sendTransfer={() => sendOutgoingTransferModal()}
          transactionId={transactionId}
          setTransactionFormIsValid={(valid: boolean) => setTransactionFormIsValid(valid)}
          setTransferData={(data:any) => setTransferData(data)}
          transferLayoutState={transferLayoutState}
          setTransferLayoutState={setTransferLayoutState}
        />
        : <PaymentOverviewHeaderContainerAccountTabCardsLayout
          cardsData={cardsData}
          spendData={spendData}
          chartLabels={chartLabels}
        />
      }

    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    weavr: state.weavr.weavrCorporate
  };
};

export default connect(mapStateToProps, null)(PaymentsOverviewHeaderContainerAccountInfoTab);
