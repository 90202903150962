// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.transfer_header {
    margin-top: 40px;
    margin-bottom: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid rgba(162, 173, 191, 0.22);
    height: 76px;
    background-color: #EAEAF0;
    border-radius: 5px;
    font-size: 28px;
    font-weight: bold;
    color: #626262;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/Tabs/PaymentsOverviewHeaderContainerAccountInfoTab/Layouts/styles.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,mBAAmB;IACnB,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,2CAA2C;IAC3C,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".transfer_header {\n    margin-top: 40px;\n    margin-bottom: 40px;\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border: 1px solid rgba(162, 173, 191, 0.22);\n    height: 76px;\n    background-color: #EAEAF0;\n    border-radius: 5px;\n    font-size: 28px;\n    font-weight: bold;\n    color: #626262;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
