import React, {useEffect, useState} from "react";
import ContentContainer from "../../../Components/Containers/ContentContainer";
import MainSpace from "../../../Components/Containers/MainSpace";
import {getToken, setToken} from "../../../utils/cookies/tokensCookie";
import {useTranslation} from "react-i18next";
import Button from "../../../Components/Buttons/Button";
import axios from "axios";
import environment from "../../../environment";
import {useDispatch} from "react-redux";
import {getWeavrCorporate} from "../../../redux/actions/weavrActions";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import {urls} from "../../../api/urls";
import image from "../../../img/Dashboard/Card/weavr_onboarding_login.svg";
import kybImage from "../../../img/Dashboard/Card/weav_onboarding_info.svg";

export default function OnboardingKyb(props) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [showLogin, setShowLogin] = useState(false);
  const [showKyb, setShowKyb] = useState(false);
  const [showButtonLoading, setShowButtonLoading] = useState(false);
  const [showStartButtonLoading, setShowStartButtonLoading] = useState(false);
  const [weavrToken, setWeavrToken] = useState("");

  const [ref, setRef] = useState();

  const checkForErrors = () => {
    window.addEventListener("error", async function (e) {
      console.error(e);
      setShowLogin(true);
      setTimeout(() => {
        createLogin();
      }, 0);
      // prevent React's listener from firing
      e.stopImmediatePropagation();
    });
  };

  useEffect(() => {
    const token = getToken(true);
    if (!token) {
      setShowLogin(true);
    } else {
      checkForErrors();
    }
  }, []);

  useEffect(() => {
    const getWeavrToken = async () => {
      const token = await getToken(true);
      if (token) {
        setWeavrToken(token);
      } else {
        setShowLogin(true);
        setTimeout(() => {
          createLogin();
        }, 0);
      }
    };
    if (showKyb && !weavrToken.length) {
      getWeavrToken();
    }
  }, [showKyb, weavrToken]);

  useEffect(() => {
    if (props.data && props.data.reference) {
      setShowKyb(true);
    }
  }, [props.data]);

  const createKYB = async () => {

    await window.OpcUxSecureClient.associate(`Bearer ${weavrToken}`,
      function() {

        // Initialise the KYB UI component
        window.OpcUxSecureClient.kyb().init(

          // The selector identifying the container where you want to display the component
          "#kyb-container",

          // The KYB reference returned in the previous step
          { reference: props.data.reference },

          function(messageType, payload) {
            console.log("messageType", messageType);
            console.log("payload", payload);
          },
          { lang: "de" },

          // Specify a string of plain text styles to brand the UI elements shown in the KYB flow
          { customCssStr: "" },

          // Specify a CSS file containing styles to brand the UI elements shown in the KYB flow
          { customCss: "" }
        );
      });

  };

  useEffect(() => {

    if (ref && showKyb && weavrToken) {
      createKYB();
    }
  }, [ref, showKyb, weavrToken]);


  const startKybHandler = async () => {
    setShowStartButtonLoading(true);
    const savedToken = await getToken(true);
    setWeavrToken(savedToken);
    const token = await getToken();
    if (savedToken) {
      try {
        const response = await axios.post(`${environment.baseUrl}${urls.payments.startKYB}`,
          {
            token: savedToken
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          });
        if (response.data) {
          setShowStartButtonLoading(false);
          dispatch(getWeavrCorporate());
          setShowKyb(true);
        }
      } catch (e) {
        if (e.response.data.message === "token_not_valid") {
          props.handleSlider("login");
        }
      }
    }
  };

  const [refLogin, setRefLogin] = useState(null);

  const createLogin = async () => {
    const createdForm = window.OpcUxSecureClient.form();

    setRefLogin(createdForm);

    // Create an instance of a secure Passcode component that will collect the passcode
    const input = createdForm.input(
      "passcode",
      "passCode",
      {
        placeholder: "Passwort"
      }
    );

    // Embed the secure Passcode component in the HTML element where you want
    // the Passcode component to the shown on screen
    input.mount(document.getElementById("passcode"));
  };

  useEffect(() => {
    if (showLogin) {
      createLogin();
    }
    // Set the user’s authentication token
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [showLogin]);
  const onSubmit = (e) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    refLogin.tokenize(function(tokens) {
      if (tokens && tokens.passcode) {
        login(tokens.passcode);
      }
    });
  };

  const login = async (tokenizedCode) => {
    setShowButtonLoading(true);
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.login}`,
        {
          email: props.data.rootUser.email,
          password: tokenizedCode
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      if (response.data && response.data.token) {
        setShowButtonLoading(false);
        setToken(response.data.token, true);
        setShowLogin(false);
        props.handleSlider("next");
      }
    } catch (e) {
      setShowButtonLoading(false);
      toast.error(
        <CustomToastContainer
          message={t("login.server_declined")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  return (
    <MainSpace>

      {showLogin
        ? <div className="create_password_page">
          <form ref={refLogin} onSubmit={(e) => onSubmit(e)} className="weavr_passcode_container">
            <div>
              <div className="weavr_login_container_content">
                <h1>{t("dashboard.payments.onboarding.stepper.login.title")}</h1>
                <p>{t("dashboard.payments.onboarding.stepper.login.text")}</p>
                <div style={{display: "none"}}>
                  Username: <input type="input" value={props.data.rootUser.email} name="u" /><br/>
                </div>
                <div className="weavr_passcode_input_box">
                  Passwort <div id="passcode"></div><br/>
                </div>
                <input
                  className="weavr_passcode_btn"
                  type="submit"
                  value={t("dashboard.payments.onboarding.stepper.login.btn")}
                />
              </div>
            </div>
            <div>
              <img src={image} alt="login"/>
            </div>
          </form>
        </div>
        : <div>
          {props.data && !props.data.reference
            ? <div className="kyb_box">
              <div>
                <div className="kyb_box_content">
                  <h1>{t("dashboard.payments.onboarding.stepper.kyb.title")}</h1>
                  <p>{t("dashboard.payments.onboarding.stepper.kyb.text")}</p>
                  <p>{t("dashboard.payments.onboarding.stepper.kyb.list.title")}</p>
                  <ul>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_1")}
                    </li>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_2")}
                    </li>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_3")}
                    </li>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_4")}
                    </li>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_5")}
                    </li>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_6")}
                    </li>
                    <li>
                      {t("dashboard.payments.onboarding.stepper.kyb.list.li_7")}
                    </li>
                  </ul>

                  <div style={{height: "20px"}} />

                  <Button
                    buttonType="primary"
                    buttonHtmlType="button"
                    buttonLabel={t("dashboard.payments.onboarding.stepper.kyb.btn")}
                    loading={showStartButtonLoading}
                    buttonHandler={() => startKybHandler()}
                  />
                </div>
              </div>
              <div>
                <img src={kybImage} alt="kyb"/>
              </div>
            </div>
            : <div ref={newRef => setRef(newRef)} id="kyb-container"></div>
          }
        </div>
      }
    </MainSpace>
  );
}
