import React, { useEffect, useRef, useState } from "react";
import "./ListStyles.css";
import TableSortedHeadItem from "./TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import { convertToDDMMYYYY } from "../../utils/dateConverter";
import { rewriteCurrency } from "../../utils/rewriteCurrency";
import PlansActionDots from "../SimpleComponents/PlansActionDots";
import Spacer from "../SimpleComponents/Spacer";
import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../utils/permissionsAllow";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import { generateUniqueID } from "@sentry/tracing/dist/browser/web-vitals/lib/generateUniqueID";
import { useTranslation } from "react-i18next";
import { plansData } from "../../redux/actions/plansActions";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import { useNavigate } from "react-router-dom";
import moment from "moment/moment";
import Checkbox from "../SimpleComponents/Checkbox";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { changeSelectedRows } from "redux/actions/selectedUsersFromListActions";
import PlansHelperService from "../../pages/plans/plans.service";
import Badge, {BadgeColorsEnum} from "../Elements/Badge/Badge";
import Tooltip from "../Tooltip/Tooltip";

interface CustomProps {
  plansDataProps: any;
  totalCount: number;
  departments?: any;
  parsedSearch: any;
  company?: any;
  callBackSelections: (plans: any) => void;
  filteringState: any;
}

function PlansList({
  plansDataProps,
  totalCount,
  parsedSearch,
  company,
  callBackSelections,
  filteringState,
}: CustomProps) {
  interface Lookup {
    [index: string]: string;
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLimit, setPageLimit] = useState(8);
  const [checkLoading, setCheckLoading] = useState(false);

  // Change limits of items in page
  const changePageLimit = (newLimit: number) => {
    if (newLimit !== pageLimit) {
      dispatch(plansData({ ...filteringState, limit: newLimit, skip: 0 }));
      setPageLimit(newLimit);
    }
  };

  useEffect(() => {
    setCurrentPage(parsedSearch.page ? +parsedSearch.page : 1);
  }, [parsedSearch]);

  const [sortedData, setSortedData] = useState([]);
  useEffect(() => {
    setSortedData(plansDataProps);
  }, [plansDataProps]);

  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    startDate: "down",
    endDate: "up",
  });

  const [sortValue, setSortValue] = useState("");
  const sortingData = (name: string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortValue === "up" ? "" : "-"}${name}`;
    dispatch(
      plansData({
        limit: pageLimit,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * pageLimit,
        sort: postValue,
      })
    );
    setSortValue(postValue);
    setSortButton({ ...sortButton, [name]: sortValue });
  };

  // handle Paginate
  const currentPosts: any = sortedData;
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      navigate(`/plans?page=${pageNumber}`);
      const nextPage = (pageNumber - 1) * pageLimit;
      setShowLoading(true);
      setCheckAll(false);
      dispatch(
        plansData({
          ...filteringState,
          limit: pageLimit,
          skip: nextPage,
          sort: sortValue,
        })
      );
    }
  };

  const [showLoading, setShowLoading] = useState(false);

  useEffect(() => {
    if (plansDataProps.length) {
      setShowLoading(false);
    }
  }, [plansDataProps]);

  const getPlanButton = (plan: any) => {
    if (plan.isBilled === "billed") {
      return (
        <div className="center">
          <button
            onClick={() => navigate(`billing/${plan._id}`)}
            className="billing_list_btn"
          >
            {t("dashboard.plans.table_status_ready")}
          </button>
        </div>
      );
    } else if (moment(plan.endDate).toDate() < new Date() && plan.isActive) {
      return (
        <div className="center">
          <button
            onClick={() => navigate(`billing/${plan._id}`)}
            className="billing_list_btn"
          >
            {t("dashboard.plans.table_status_ready")}
          </button>
        </div>
      );
    } else {
      return (
        <div className="center">
          <button className="billing_list_btn" disabled={true}>
            {t("dashboard.plans.table_status_ready")}
          </button>
        </div>
      );
    }
  };

  const getPlanStatusForCheckBox = (plan: any) => {
    if (plan.isBilled === "billed") {
      return "billed";
    } else if (moment(plan.endDate).toDate() < new Date() && plan.isActive) {
      return "ready";
    } else if (plan.isActive) {
      return "active";
    } else {
      return "not_active";
    }
  };

  // State to track which rows are checked
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  /**
   * Function to handle all checkbox changes. Used for toggling all checkboxes at the same time.
   */
  const handleCheckAll = () => {
    if (!checkLoading) {
      setCheckLoading(true);
      setCheckAll(!checkAll);
      if (checkAll) {
        //Remove current displayed plans
        currentPosts.forEach((post: any) => {
          if (
            checkedItems?.length > 0 &&
            getCheckedIds().includes(post.plan._id)
          ) {
            const index = getCheckedIds().indexOf(post.plan._id);
            if (index !== -1) {
              checkedItems.splice(index, 1);
            }
          }
        });
        setCheckedItems([...checkedItems]);
      } else {
        //Add current displayed plans
        currentPosts.forEach((post: any) => {
          if (getPlanStatusForCheckBox(post.plan) !== "active") {
            if (!getCheckedIds().includes(post.plan._id)) {
              setCheckedItems((oldArray) => [...oldArray, post]);
            }
          }
        });
      }
    }

    setCheckLoading(false);
  };

  /**
   * Hook to listen to checkboxes (checkedItems) and currently displayed rows (currentPosts).
   * Used to detect if all displayed rows are selected to handle the checkAll checkbox on top.
   */
  useEffect(() => {
    let count = 0;
    //count all selected rows and unavailable rows to detect if all available rows are selected
    currentPosts.forEach((post: any) => {
      if (getPlanStatusForCheckBox(post.plan) === "active") {
        count++;
      } else {
        if (getCheckedIds().includes(post.plan._id)) {
          count++;
        }
      }
    });
    if (
      count === currentPosts.length &&
      !showLoading &&
      currentPosts.length > 0
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
    callBackSelections(checkedItems);
  }, [checkedItems, currentPosts]);

  /**
   * Function to handle single checkbox changd. Used for toggling checkbox states.
   * @param item - any
   */
  const handleCheckboxChange = (item: any) => {
    if (getPlanStatusForCheckBox(item.plan) !== "active") {
      if (checkedItems?.length > 0 && getCheckedIds().includes(item.plan._id)) {
        const index = getCheckedIds().indexOf(item.plan._id);
        if (index !== -1) {
          checkedItems.splice(index, 1);
        }
        setCheckedItems([...checkedItems]);
      } else {
        setCheckedItems([...checkedItems, item]);
      }
    } else {
      toast.error(
        <CustomToastContainer
          message={t("warnings.plans_checkbox_error")}
          status={2}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  /**
   * Hook to update Redux on checked Items change
   */
  useEffect(() => {
    dispatch(changeSelectedRows(checkedItems));
  }, [checkedItems]);

  /**
   * Helper function to get the IDs of all selected rows
   * @returns Array of IDs
   */
  const getCheckedIds = () => {
    return checkedItems.map((item: any) => item.plan._id);
  };

  return !currentPosts.length ? (
    <EmptyTableState
      style={{ height: "600px", backgroundColor: "transparent" }}
    />
  ) : (
    <>
      <div className="table_box">
        {showLoading ? (
          <TableLoading />
        ) : (
          <table className="table planlist" style={{ width: "100%" }}>
            <thead>
              <tr>
                <th>
                  <Checkbox
                    disabled={checkLoading}
                    id="checkAll"
                    handleCheckboxChange={() => handleCheckAll()}
                    checked={checkAll}
                  />
                </th>
                <th className="pl-10">
                  <div className="row">
                    <Spacer spacerWidth="1rem" />
                    <div className="responsive-mr-40">
                      {t("dashboard.plans.table_head_name")}
                    </div>
                  </div>
                </th>
                <th>
                  <p
                    style={{
                      textAlign: "center",
                    }}
                  >
                    {t("dashboard.plans.table_head_1")}
                  </p>
                </th>
                <th>
                  <TableSortedHeadItem
                    sortingData={sortingData}
                    text={t("dashboard.plans.table_head_2")}
                    name="startDate"
                    sortButton={sortButton}
                  />
                </th>
                <th>
                  <TableSortedHeadItem
                    sortingData={sortingData}
                    text={t("dashboard.plans.table_head_7")}
                    name="endDate"
                    sortButton={sortButton}
                  />
                </th>
                <th>{t("dashboard.plans.table_head_3")}</th>

                <th>{t("dashboard.plans.table_head_5")}</th>
                {isAllowed({
                  data: {
                    roles: [
                      userRoles.superAdmin,
                      userRoles.admin,
                      userRoles.custom,
                    ],
                    permissions: {
                      isAdmin: true,
                      permissionsArray: [userPermissions.plans],
                    },
                  },
                }) ? (
                    <th style={{ textAlign: "center" }}>
                      {t("dashboard.plans.table_head_6")}
                    </th>
                  ) : null}

                <th>
                  <div
                    style={{
                      width: "70px",
                    }}
                  />
                </th>
              </tr>
            </thead>
            <tbody>
              {currentPosts.map((item: any, index: number) => (
                <React.Fragment key={generateUniqueID()}>
                  <tr className="spacer" />
                  <tr
                    className={
                      getCheckedIds().includes(item.plan._id)
                        ? "selected_row"
                        : "table-item"
                    }
                  >
                    <td>
                      {getPlanStatusForCheckBox(item.plan) !== "active"
                        ?
                        <Checkbox
                          id={item.plan._id}
                          handleCheckboxChange={() => handleCheckboxChange(item)}
                          checked={getCheckedIds().includes(item.plan._id)}
                        />
                        : <Tooltip
                          text={t("warnings.plans_checkbox_error")}
                        >
                          <Checkbox
                            id={item.plan._id}
                            handleCheckboxChange={() => handleCheckboxChange(item)}
                            checked={getCheckedIds().includes(item.plan._id)}
                            disabled={true}
                          />
                        </Tooltip>
                      }
                    </td>
                    <td>
                      <div className="row" style={{ marginLeft: "1rem" }}>
                        {item.plan.title}
                      </div>
                    </td>

                    <td>
                      <Badge
                        color={new PlansHelperService().getBillingStatus(item.plan).color}
                        text={new PlansHelperService().getBillingStatus(item.plan).text}
                      />
                    </td>
                    <td>{moment(item.plan.startDate).format("DD.MM.YYYY")}</td>
                    <td>{moment(item.plan.endDate).format("DD.MM.YYYY")}</td>
                    <td style={{ width: "12%" }}>
                      {item.plan.employees.length}
                    </td>

                    <td>
                      {rewriteCurrency(item.totalBudget.toFixed(2), "EUR")}
                    </td>
                    {isAllowed({
                      data: {
                        roles: [
                          userRoles.superAdmin,
                          userRoles.admin,
                          userRoles.custom,
                        ],
                        permissions: {
                          isAdmin: true,
                          permissionsArray: [userPermissions.plans],
                        },
                      },
                    }) ? (
                        <td>
                          <PlansActionDots
                            style={{ width: 30, margin: "auto" }}
                            data={item}
                          />
                        </td>
                      ) : null}
                    <td>{getPlanButton(item.plan)}</td>
                  </tr>
                </React.Fragment>
              ))}
            </tbody>
          </table>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        postsPerPage={pageLimit}
        totalPosts={totalCount}
        paginate={paginate}
        limit={pageLimit}
        changeLimits={(limit: number) => {
          setShowLoading(true);
          changePageLimit(limit);
        }}
      />
    </>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    searchData: state.pagesSearch.employeeSearchData,
    company: state.company,
  };
};

export default connect(mapStateToProps, null)(PlansList);
