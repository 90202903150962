import {
  BRANCHES_CHART_SUCCESS,
  CHARTS_DATA_ERROR,
  chartsDataDispatchTypes,
  DAILY_CHART_SUCCESS,
  INVOICES_STATS_SUCCESS,
  SUMMARY_CHART_SUCCESS
} from "../types/chartsTypes";


interface DefaultStateI {
	error?: string,
	invoiceStatistic?: any,
	dailyChartData?: any,
	summaryChartData?: any,
	branchesChart?: any
}

const stats1 = {} as any;

const initialState:DefaultStateI = {
  error: "",
  invoiceStatistic: stats1,
  dailyChartData: {},
  summaryChartData: {},
  branchesChart: {}
};

export const chartsReducer = (
  state: DefaultStateI = initialState,
  action: chartsDataDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case INVOICES_STATS_SUCCESS:
    return {
      ...state,
      invoiceStatistic: action.payload
    };
  case DAILY_CHART_SUCCESS:
    return {
      ...state,
      dailyChartData: action.payload
    };
  case SUMMARY_CHART_SUCCESS:
    return {
      ...state,
      summaryChartData: action.payload
    };
  case BRANCHES_CHART_SUCCESS:
    return {
      ...state,
      branchesChart: action.payload
    };
  case CHARTS_DATA_ERROR:
    return {
      ...state,
      error: action.payload
    };
  default:
    return state;
  }
};
