import React, {useEffect, useState} from "react";
import {isAllowed, userPermissions, userRoles} from "../../../utils/permissionsAllow";
import SelectWithChoices from "../../SimpleComponents/SelectWithChoices";
import FilterDatePicker from "../../SimpleComponents/FilterdatePicker";
import FilterRange from "../../SimpleComponents/FilterRange";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {
  getFiltersData,
  getInvoices,
  showInvoicesLoading
} from "../../../redux/actions/invoicesActions";
import {changeInvoicesFiltering} from "../../../redux/actions/filteringActions";
import {ICustomProps} from "Interfaces/ICustomProps";
import {RootState} from "../../../redux/reducers/rootReducer";

interface ISelectOption {
  value: string;
  label: string;
}

function InvoicesFilters({ filters, filteringState }: ICustomProps) {

  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Take all data
  useEffect(() => {
    dispatch(getFiltersData());
  }, []);

  const statusOptions = [
    { value: 1, label: t("dashboard.overview.status_1") },
    { value: 2, label: t("dashboard.overview.status_2") },
    { value: 3, label: t("dashboard.overview.status_3") },
  ];

  // Initial of selects
  const [branchesOptions, setBranchesOptions] = useState<ISelectOption[]>([]);
  // Filtered data for options
  const [filteredDepartments, setFilteredDepartments] = useState<
    ISelectOption[]
    >([]);
  const [employeeOptions, setEmployeeOptions] = useState<ISelectOption[]>([]);
  const [merchantsOptions, setMerchantsOptions] = useState<ISelectOption[]>([]);
  const [planNameOptions, setPlanNameOptions] = useState<ISelectOption[]>([]);

  // Create options for selects depends on received data
  const createOptionsArray = (data: any) => {
    const options: ISelectOption[] = [];
    data.forEach((item: any) => {
      options.push({
        value: item.id,
        label: item.name,
      });
    });
    return options;
  };

  // from data to select options
  useEffect(() => {
    if (filters.branches && filters.branches.length) {
      const selectOptions = createOptionsArray(filters.branches);
      setBranchesOptions(selectOptions);
    }

    if (filters.departments && filters.departments.length) {
      const selectOptions = createOptionsArray(filters.departments);
      setFilteredDepartments(selectOptions);
    }

    if (filters.users && filters.users.length) {
      const selectOptions = createOptionsArray(filters.users);
      setEmployeeOptions(selectOptions);
    }

    if (filters.merchants && filters.merchants.length) {
      const selectOptions = createOptionsArray(filters.merchants);
      setMerchantsOptions(selectOptions);
    }

    if (filters.plans && filters.plans.length) {
      const selectOptions = createOptionsArray(filters.plans);
      setPlanNameOptions(selectOptions);
    }
  }, [
    filters.branches,
    filters.departments,
    filters.users,
    filters.merchants,
    filters.plans,
  ]);

  // remove all option from select
  function removeAllOption(item: any) {
    if (item.value === "*") {
      return false;
    }
    return true;
  }

  // HANDLE SELECTS
  const handleChoice = (options: any, type: string) => {
    if (options.length > 0) {
      const filtered = options.filter(removeAllOption);
      let temporaryIDs = filtered.map(function (obj: any) {
        return obj.value;
      });
      if (type === "merchants" && options[0].value === "*") {
        temporaryIDs = [];
      }
      // Assign changed value to global filtering state, to keep it sync in all components
      const changedState = { ...filteringState };

      changedState[type] = type === "status" ? options[0].value : temporaryIDs;
      if (type === "employees") {
        changedState.skip = 0;
      }
      // Show table loading component
      dispatch(showInvoicesLoading());
      // Update table with new filtering state
      dispatch(changeInvoicesFiltering(changedState));
      dispatch(getInvoices(changedState));
    } else {
      // Clear selected value in global state
      const changedState = { ...filteringState };
      changedState[type] = [];
      if (type === "employees") {
        changedState.skip = 0;
      }
      dispatch(showInvoicesLoading());
      dispatch(changeInvoicesFiltering(changedState));
      dispatch(getInvoices(changedState));
    }
  };

  const handleDatePicker = (values:any) => {
    const changedState = { ...filteringState };

    changedState.startDate = values.startDate;
    changedState.endDate = values.endDate;
    // Show table loading component
    dispatch(showInvoicesLoading());
    // Update table with new filtering state
    dispatch(changeInvoicesFiltering(changedState));
    dispatch(getInvoices(changedState));
  };

  const getDefaultValuesFromState = (array: {label:string, value:string}[], filterState: string[]) => array.filter(option => filterState.includes(option.value));

  const defaultValues = {
      ...filteringState,
      employees: getDefaultValuesFromState(employeeOptions, filteringState.employees),
      branches: getDefaultValuesFromState(branchesOptions, filteringState.branches),
      departments: getDefaultValuesFromState(filteredDepartments, filteringState.departments),
      merchants: getDefaultValuesFromState(merchantsOptions, filteringState.merchants),
      planName: getDefaultValuesFromState(planNameOptions, filteringState.plans),
  }

  return(
    <>
      {/*BRANCHES FILTER*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom, userRoles.viewer],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.invoices],
          },
        },
      })
        ? <div className="filters_item" style={{ marginBottom: "10px" }}>
          <p className="filter_label" style={{ height: "1.2rem" }}>
            {t("dashboard.overview.filter_opt_1_label")}
          </p>
          <SelectWithChoices
            key={JSON.stringify(defaultValues.branches)}
            defaultValue={defaultValues.branches}
            name={t("dashboard.overview.filter_opt_1_place")}
            selectPlaceHolder={t("dashboard.overview.filter_opt_1_place")}
            selectId="branches"
            options={branchesOptions}
            handleAddChoice={(options) => handleChoice(options, "branches")}
          />
        </div>
        : null
      }


      {/*DEPARTMENTS FILTER*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom, userRoles.viewer],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.invoices],
          },
        },
      })
        ? <div className="filters_item">
          <p className="filter_label" style={{ height: "1.2rem" }}>
            {t("dashboard.overview.filter_opt_2_label")}
          </p>
          <SelectWithChoices
            key={JSON.stringify(defaultValues.departments)}
            defaultValue={defaultValues.departments}
            name={t("dashboard.overview.filter_opt_2_place")}
            selectPlaceHolder={t("dashboard.overview.filter_opt_2_place")}
            selectId="departments"
            options={filteredDepartments}
            handleAddChoice={(options) =>
              handleChoice(options, "departments")
            }
          />
        </div>
        : null
      }


      {/*EMPLOYEES FILTER*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom, userRoles.viewer],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.invoices],
          },
        },
      })
        ? <div className="filters_item">
          <p className="filter_label" style={{ height: "1.2rem" }}>
            {t("dashboard.overview.filter_opt_3_label")}
          </p>
          <SelectWithChoices
            key={JSON.stringify(defaultValues.employees)}
            defaultValue={defaultValues.employees}
            name={t("dashboard.overview.filter_opt_3_place")}
            selectPlaceHolder={t("dashboard.overview.filter_opt_3_place")}
            selectId="employees"
            options={employeeOptions}
            handleAddChoice={(options) => handleChoice(options, "employees")}
          />
        </div>
        : null
      }

      {/*PLANS FILTER*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom, userRoles.viewer],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.invoices],
          },
        },
      })
        ? <div className="filters_item">
          <p className="filter_label" style={{ height: "1.2rem" }}>
            {t("dashboard.overview.filter_opt_8_label")}
          </p>
          <SelectWithChoices
            key={JSON.stringify(defaultValues.planName)}
            defaultValue={defaultValues.planName}
            name={t("dashboard.overview.filter_opt_8_place")}
            selectPlaceHolder={t("dashboard.overview.filter_opt_8_place")}
            selectId="plans"
            options={planNameOptions}
            handleAddChoice={(options) => handleChoice(options, "plans")}
          />
        </div>
        : null
      }


      {/*MERCHANTS FILTER*/}
      <div className="filters_item">
        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.overview.filter_opt_4_label")}
        </p>
        <SelectWithChoices
            key={JSON.stringify(defaultValues.merchants)}
            defaultValue={defaultValues.merchants}
          name={t("dashboard.overview.filter_opt_4_place")}
          selectPlaceHolder={t("dashboard.overview.filter_opt_4_place")}
          selectId="merchants"
          options={merchantsOptions}
          handleAddChoice={(options) => handleChoice(options, "merchants")}
        />
      </div>

      {/*STATUS TYPE FILTER*/}
      <div className="filters_item">
        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.overview.filter_opt_5_label")}
        </p>
        <SelectWithChoices
            key={JSON.stringify(defaultValues.status)}
            defaultValue={defaultValues.status}
          name={t("dashboard.overview.filter_opt_5_place")}
          selectPlaceHolder={t("dashboard.overview.filter_opt_5_place")}
          selectId="status"
          options={statusOptions}
          handleAddChoice={(options) =>
            handleChoice(options, "status")
          }
        />
      </div>

      {/*DATE FILTER*/}
      <div className="filters_item">
        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.overview.filter_opt_6_label")}
        </p>
        <FilterDatePicker
          startDate={filteringState.startDate}
          endDate={filteringState.endDate}
          handleChange={(values:any) => handleDatePicker(values)}
        />
      </div>

      {/*AMOUNT FILTER*/}
      <div className="filters_item">
        <p className="filter_label" style={{ height: "1.2rem" }}>
          {t("dashboard.overview.filter_opt_7_label")}
        </p>
        <FilterRange key={JSON.stringify(`${defaultValues.minAmount}${defaultValues.maxAmount}`)} />
      </div>
    </>
  );
}


const mapStateToProps = (state: RootState) => {
  return {
    filters: state.invoices.filters,
    filteringState: state.filters.invoiceFilters,
  };
};

export default connect(mapStateToProps, null)(InvoicesFilters);
