import { Route, Routes } from "react-router-dom";
import React, { useEffect } from "react";
import PartnerPortalProfilePage from "./profile/index";
import PartnerPortalDealsPage from "./deals";
import PartnerPortalCreateDealPage from "./deals/create";

export default function PartnerPortalRoute() {

  //Reset the local storage on page change (clear filter states)
  useEffect(() => {
    window.localStorage.removeItem("hrZoneEmployeeFilter");
  },[window.location.pathname]);


  return (
    <Routes test-id="partner_portal_route">
      <Route path="/profile" element={<PartnerPortalProfilePage />} />
      <Route path="/deals" element={<PartnerPortalDealsPage />} />
      <Route path="/deals/create" element={<PartnerPortalCreateDealPage />} />
	  {/* <Route
		    path="*"
		    element={<Navigate to="/dashboard" replace />}
		  /> */}
    </Routes>
  );
}
