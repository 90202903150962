import React, {useEffect, useState} from "react";
import SelectWithSearch from "../../SimpleComponents/SelectWithSearch";
import FormikInput from "../../SimpleComponents/FormikInput";
import {useTranslation} from "react-i18next";
import ActionsDotsBranches from "../../SimpleComponents/ActionsDotsBranches";
import ActionDotsDepartments from "../../SimpleComponents/ActionsDotsDepartments";

interface CustomProps {
  formik: any,
  i:number,
  addNewBranch: () => void,
  removeBranch: (i:number) => void,
  addNewDepartment:any,
  deleteDepartment:any,
  regionValues: any
}

export default function DepAndBranchItem(
  {formik, i, addNewBranch, removeBranch,
    addNewDepartment, deleteDepartment, regionValues}:CustomProps) {
  const { t } = useTranslation();

  const [chosenRegion, setChosenRegion] = useState<any>({});
  const [chosenCity, setChosenCity] = useState<any>({});
  const [regionOptions, setRegionOptions] = useState([]);
  const [citiesOptions, setCitiesOptions] = useState([]);

  // Manipulate region data
  useEffect(() => {
    if (regionValues.length) {
      if (!formik.values.depsAndBranches[i].branchName) {
        const regionArray:any = [];
        handleSelectChange("region", {
          label: regionValues[0].region,
          value: regionValues[0].key
        });
        regionValues.forEach((region:any) => {
          regionArray.push({
            label: region.region,
            value: region.key
          });
        });
        setRegionOptions(regionArray);
      }
    }
  }, [regionValues]);

  // Set cities options depends on chosen region
  useEffect(() => {
    if (regionValues && Object.keys(regionValues).length) {
      const citiesArray:any = [];
      regionValues.forEach((region:any) => {
        if (region.key === chosenRegion.value) {
          region.cities.forEach((city:any) => {
            citiesArray.push({
              label: city,
              value: city
            });
          });
        }
      });
      setCitiesOptions(citiesArray);
    }
  }, [chosenRegion]);

  // Changes in select components
  const handleSelectChange = (name:string, value: any) => {
    if (name === "region") {
      setChosenRegion(value);
      formik.setFieldValue(`depsAndBranches[${i}].region`, value.value);
    } else if (name === "city") {
      setChosenCity(value);
      formik.setFieldValue(`depsAndBranches[${i}].city`, value.value);
    }
  };

  return(
    <>
      <div className="flex-end">
        <ActionsDotsBranches
          index={i}
          addNewBranch={addNewBranch}
          removeBranchAndDep={removeBranch}
        />
      </div>
      <div className="general_half_ratio">
        <div >
          <p className="simple_label" style={{marginBottom: "5px"}}>
            {t("dashboard.general.dep_branch.labels.region")}
          </p>
          <SelectWithSearch
            value={chosenRegion}
            name="region"
            data={regionOptions}
            handleSelect={(value:any) => handleSelectChange("region", value)}
          />
        </div>

        <div className="relative">
          <FormikInput
            style={{marginRight:"10px"}}
            htmlFor="branchName"
            name={`depsAndBranches[${i}].branchName`}
            value={formik.values.depsAndBranches[i].branchName}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("dashboard.general.dep_branch.labels.branchName")}
          />
          {formik.errors.depsAndBranches
          && formik.touched.depsAndBranches
          && formik.errors.depsAndBranches[i]
          && formik.touched.depsAndBranches[i]
          && formik.errors.depsAndBranches[i].branchName
          && formik.touched.depsAndBranches[i].branchName
          && <p
            className="input_warning"
          >
            {formik.errors.depsAndBranches[i].branchName}
          </p>}
        </div>
      </div>

      <div className="general_half_ratio">
        <div className="relative">
          <FormikInput
            style={{marginRight:"10px"}}
            htmlFor="country"
            name={`depsAndBranches[${i}].country`}
            value={formik.values.depsAndBranches[i].country}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("dashboard.general.dep_branch.labels.country")}
          />
          {formik.errors.depsAndBranches
          && formik.touched.depsAndBranches
          && formik.errors.depsAndBranches[i]
          && formik.touched.depsAndBranches[i]
          && formik.errors.depsAndBranches[i].country
          && formik.touched.depsAndBranches[i].country
          && <p
            className="input_warning"
          >
            {formik.errors.depsAndBranches[i].country}
          </p>}
        </div>

        <div className="relative">
          <FormikInput
            style={{marginRight:"10px"}}
            htmlFor="streetName"
            name={`depsAndBranches[${i}].streetName`}
            value={formik.values.depsAndBranches[i].streetName}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("dashboard.general.dep_branch.labels.streetName")}
          />
          {formik.errors.depsAndBranches
          && formik.touched.depsAndBranches
          && formik.errors.depsAndBranches[i]
          && formik.touched.depsAndBranches[i]
          && formik.errors.depsAndBranches[i].streetName
          && formik.touched.depsAndBranches[i].streetName
          && <p
            className="input_warning"
          >
            {formik.errors.depsAndBranches[i].streetName}
          </p>}
        </div>

      </div>

      <div className="general_half_ratio">
        <div>
          <p className="simple_label" style={{marginBottom: "5px"}}>
            {t("dashboard.general.dep_branch.labels.city")}
          </p>

          <div className="relative">
            <SelectWithSearch
              value={chosenCity}
              onBlur={formik.handleBlur(`depsAndBranches[${i}].city`)}
              name={`depsAndBranches[${i}].city`}
              data={citiesOptions}
              handleSelect={(value:any) => handleSelectChange("city", value)}
            />
            {formik.errors.depsAndBranches
            && formik.touched.depsAndBranches
            && formik.errors.depsAndBranches[i]
            && formik.touched.depsAndBranches[i]
            && formik.errors.depsAndBranches[i].city
            && formik.touched.depsAndBranches[i].city
            && <p
              style={{bottom: "-12px"}}
              className="input_warning"
            >
              {formik.errors.depsAndBranches[i].city}
            </p>}
          </div>

        </div>

        <div className="relative">
          <FormikInput
            style={{marginRight:"10px"}}
            htmlFor="zipCode"
            name={`depsAndBranches[${i}].zipCode`}
            value={formik.values.depsAndBranches[i].zipCode}
            disabled={false}
            handleChange={formik.handleChange}
            onBlur={formik.handleBlur}
            label={t("dashboard.general.dep_branch.labels.zipCode")}
          />
          {formik.errors.depsAndBranches
          && formik.touched.depsAndBranches
          && formik.errors.depsAndBranches[i]
          && formik.touched.depsAndBranches[i]
          && formik.errors.depsAndBranches[i].zipCode
          && formik.touched.depsAndBranches[i].zipCode
          && <p
            className="input_warning"
          >
            {formik.errors.depsAndBranches[i].zipCode}
          </p>}
        </div>

      </div>
      <div style={{position: "relative"}}>
        {formik.values.depsAndBranches[i].departmentNames.map((department:string, idx:number) => (
          <div key={idx.toString()} style={{position: "relative"}}>
            <div style={{position: "absolute", right: "25px", top: "10px", zIndex: 999}}>
              <ActionDotsDepartments
                index={i}
                addNewDepartment={addNewDepartment}
                deleteDepartment={deleteDepartment}
                idx={idx}
              />
            </div>

            <div className="relative">
              <FormikInput
                style={{marginRight:"10px"}}
                htmlFor="departmentName"
                name={`depsAndBranches[${i}].departmentNames[${idx}]`}
                value={formik.values.depsAndBranches[i].departmentNames[idx]}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.general.dep_branch.labels.departmentName")}
              />
              {formik.errors.depsAndBranches
              && formik.touched.depsAndBranches
              && formik.errors.depsAndBranches[i]
              && formik.touched.depsAndBranches[i]
              && formik.errors.depsAndBranches[i].departmentNames
              && formik.errors.depsAndBranches[i].departmentNames[idx]
              && formik.touched.depsAndBranches[i].departmentNames
              && formik.touched.depsAndBranches[i].departmentNames[idx]
              && <p
                className="input_warning"
              >
                {formik.errors.depsAndBranches[i].departmentNames[idx]}
              </p>}
            </div>

          </div>
        ))}
        <div className="line" style={{backgroundColor: "rgba(14, 10, 64, 0.15)"}} />
      </div>
    </>
  );
}
