import state from "../../../redux/store";

export class getLunchSettings {
  company:any = null;

  constructor() {
    this.company = state.getState().company?.company?.company;
  }

  isVirtualCard(): boolean {
    return this.company?.virtualCard || false;
  }

  isOcr(): boolean {
    console.log(this.company);
    return this.company?.ocr || false;
  }

  isApprovalProcess() {
    return this.company?.approvalProcess || false;
  }

  isSupermarketAllowed(): boolean {
    return this.company?.isSupermarketAllowed || false;
  }

}
