import React from "react";
import "./LoadingPlaceholders.css";

interface CustomProps {
	maxWidth?: string,
	minWidth?: string,
	width?: string,
	height: string,
	padding?: string,
	borderRadius?: string,
	opacity?: string
}

export default function LoadingText(
  {maxWidth,
    minWidth,
    height,
    padding,
    width,
    borderRadius,
    opacity}:CustomProps) {
  return(
    <div className="placeholder-item" style={{padding:padding}}>
      <div
        style={{
          maxWidth:maxWidth,
          minWidth:minWidth,
          height:height,
          borderRadius:borderRadius,
          width:width,
          filter:opacity
        }}
        className="loading_container loading_title" />
    </div>
  );
}
