import { Navigate, Route, Routes } from "react-router-dom";
import Dashboard from "./dashboard/page";
import EmployeePage from "./employee/page";
import React, { useEffect } from "react";

import HrGeneralInfoRouter from "./general_info/route";

export default function HrZoneRoute() {
  //Reset the local storage on page change (clear filter states)
  useEffect(() => {
    window.localStorage.removeItem("hrZoneEmployeeFilter");
  },[window.location.pathname]);


  return (
    <Routes test-id="hrzone_router">
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/employee" element={<EmployeePage />} />
      <Route path="/general-info/*" element={<HrGeneralInfoRouter />} />
	  {/* <Route
		    path="*"
		    element={<Navigate to="/dashboard" replace />}
		  /> */}
    </Routes>
  );
}
