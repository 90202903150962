/* eslint-disable max-len */
import React, {useEffect, useState} from "react";
import editPen from "../../img/icon-pencil.svg";
import uploadPlaceholder from "img/Dashboard/upload_placeholder.svg";
import axios from "axios";
import environment from "../../environment";
import {toast} from "react-toastify";
import {prepareImage} from "../../redux/actions/prepareImage";
import {isAllowed, userPermissions, userRoles} from "../../utils/permissionsAllow";
import CustomToastContainer from "./ToastContainer";
import {useTranslation} from "react-i18next";
import {getToken} from "../../utils/cookies/tokensCookie";
import Compressor from "compressorjs";
import {urls} from "../../api/urls";

interface CustomProps {
	ImageUrl: string,
	setImageUrl: (url:string) => void,
	update: ( e:any, imageKey?:string) => void,
	companyImage?: boolean
}

export default function ImageUpload(
  {ImageUrl, setImageUrl, update, companyImage}:CustomProps) {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(uploadPlaceholder);
  const [uploadedImage, setUploadedImage] = useState(false);

  // CHECK IMAGE EXIST
  const [isImageExist, setIsImageExist] = useState(false);

  useEffect(() => {
    const getImage = async () => {
      if (ImageUrl && !uploadedImage) {
        await axios.get(ImageUrl).then((value) => {
          if (value.status <= 201) {
            setIsImageExist(true);
          } else {
            setIsImageExist(false);
          }
        }).catch((error) => setIsImageExist(false));
      } else if (uploadedImage) {
        setIsImageExist(true);
      }
    };
    getImage();
    //Need to disable it, because eslint says, that I need to add
    //uploadedImage, but it will broke a logic
    // eslint-disable-next-line
	}, [])

  useEffect(() => {
    async function fetchData() {
      if (ImageUrl !== uploadPlaceholder && ImageUrl !== "undefined") {
        try {
          const response = await prepareImage(ImageUrl);
          setCurrentImage(response);
          setIsImageExist(true);
          setUploadedImage(true);
        } catch (e: any) {
          setCurrentImage(uploadPlaceholder);
        }
      } else {
        setCurrentImage(uploadPlaceholder);
      }
    }
    fetchData();
  }, [ImageUrl]);

  useEffect(() => {
    if (currentImage !== uploadPlaceholder && ImageUrl === "undefined") {
      setCurrentImage(uploadPlaceholder);
    }
  }, [currentImage]);


  const handleFile = async (e: any) => {
    if (isAllowed({data: {
      roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
      permissions: {
        isAdmin: true,
        permissionsArray: [userPermissions.generalInfo, userPermissions.users]
      }
    }})) {
      const save = async (fileToSave:any) => {
        setCurrentImage(URL.createObjectURL(fileToSave));
        const token = getToken();
        const options = {headers: {"Authorization": `Bearer ${token}`}};
        const fullUrl = `${environment.baseUrl}${urls.files.files}`;
        const formData = new FormData();
        formData.append("file", fileToSave);
        formData.append("parameterName", "file");
        formData.append("mimeType", "image/jpeg");
        try {
          const uploadImage = await axios.post(fullUrl, formData, options);
          setImageUrl(uploadImage.data.key);
          update(undefined, uploadImage.data.key);
        } catch (e: any) {
          console.error(e);
        }
      };

      const file = e.target.files[0];
      if (file) {
        if (file.size > 1000000) {
          new Compressor(file, {
            quality: 0.8, // 0.6 can also be used, but its not recommended to go below.,
            width: 1000,
            height: 1000,
            success: (compressedResult) => {
              save(compressedResult);
            },
          });
        } else {
          save(file);
        }


      }
    } else {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.not_allowed")}
          status={2} />);
    }
  };
  return(
    <div className="image_upload-center">
      <div
        className="image_upload"
      >
        <div
          className={companyImage ? "upload_image" : "upload_user"}
          style={isImageExist
            ? {backgroundImage: `url(${currentImage})`}
            : {backgroundImage: `url(${uploadPlaceholder})`}
          }>
        </div>
        <div className="custom_file_input center">
          <img src={editPen} alt="edit"/>
        </div>
        <input
          onChange={handleFile}
          type="file"
          className="hide-file-input"
          accept=".jpg, .jpeg, .png"
        />
      </div>
    </div>

  );
}
