import React, {useEffect, useRef, useState} from "react";
import "./GeneralDropdown.css";
import {useTranslation} from "react-i18next";
import {isAllowed, userRoles} from "../../utils/permissionsAllow";
import ActionDotsDepsAndBranches from "./ActionDotsDepsAndBranches";
import Button from "../Buttons/Button";
import ActionDotsUpdateDepartments from "./ActionsDotsUpdateDepartments";
import {
  IAddNewBranch,
  IBranchNameChange,
  ICreateDepsAndBranches
} from "../../Interfaces/IBranchAndDepartments";
import {
  addNewDepartment,
  deleteBranch,
  deleteDepartment,
  updateBranch,
  updateDepartment,
} from "../../redux/actions/branchesAndDepartmentsApi";
import {toast} from "react-toastify";
import CustomToastContainer from "./ToastContainer";
import {getUsers} from "../../redux/actions/usersActions";
import {getCompanyData} from "../../redux/actions/companiesActions";
import {getDepartments} from "../../redux/actions/branchAndDepartmentActions";
import {useDispatch} from "react-redux";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

interface CustomProps {
  data: any
  name: string,
  addBranchAndDeps: () => void
}

export default function GeneralDropdown({data, name, addBranchAndDeps}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Detect click outside for close inputs
  const branchesInputActions = useRef<HTMLDivElement>(null);
  const departmentsInputActions = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!departmentsInputActions.current?.contains(event.target as Node)
        && !branchesInputActions.current?.contains(event.target as Node)) {
        setBranchName(name);
        setDepartmentName("");
        setEditBranchName(false);
        setEditDepartmentName(null);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [openDropdown, setOpenDropdown] = useState(false);

  // Value for branch Name
  const [branchName, setBranchName] = useState(name);

  // Value for department name
  const [departmentName, setDepartmentName] = useState("");

  // Show edit branch/department name
  const [editBranchName, setEditBranchName] = useState(false);
  const [editDepartmentName, setEditDepartmentName] = useState<number | null>(null);

  // Save branch name changes
  const updateBranchName = () => {
    const newValues:IBranchNameChange = {
      name: branchName
    };
    updateBranch(newValues, data[0].branchId).then((res:any) => {
      if (res === 200) {
        showMessage("success", "warnings.update_branch_name_success");
      } else {
        showMessage("error", "warnings.update_branch_name_error");
      }
    });
  };

  // Save department name changes
  const updateDepartmentName = () => {
    const newValues:IBranchNameChange = {
      name: departmentName
    };
    if (editDepartmentName !== null) {
      updateDepartment(newValues, data[editDepartmentName].departmentId).then((res:any) => {
        if (res === 200) {
          showMessage("success", "warnings.update_department_name_success");
        } else {
          showMessage("error", "warnings.update_department_name_error");
        }
      });
    }
  };

  // Delete department
  const removeDepartment = (i:number) => {
    deleteDepartment(data[i].departmentId).then((res:any) => {
      if (res === 200) {
        showMessage("success", "warnings.delete_department_success");
      } else if (res === 409) {
        showMessage("error", "warnings.delete_department_conflict");
      } else {
        showMessage("error", "warnings.delete_department_error");
      }
    });
  };

  // Delete branch
  const removeBranch = () => {
    deleteBranch(data[0].branchId).then((res:any) => {
      if (res === 200) {
        showMessage("success", "warnings.delete_branch_success");
      } else if (res === 409) {
        showMessage("error", "warnings.delete_branch_conflict");
      } else {
        showMessage("error", "warnings.delete_branch_error");
      }
    });
  };

  // Add new department
  const [showNewDepartment, setShowNewDepartment] = useState(false);
  const [newDepartmentName, setNewDepartmentName] = useState("");
  const addDepartment = () => {
    const newDepsAndBr:ICreateDepsAndBranches = {
      companyId: getUserCompany() || "",
      branchId: data[0].branchId,
      branchName: data[0].branchName,
      departmentName: data[0].departmentName,
      departmentId: data[0].departmentId,
      streetName: data[0].streetName,
      city: data[0].city,
      zipCode: data[0].zipCode,
      costCenter: data[0].costCenter,
      country: data[0].country,
      region: data[0].region
    };
    const newBranch:IAddNewBranch = {
      companyId: getUserCompany() || "",
      name: newDepartmentName
    };
    addNewDepartment(newBranch, newDepsAndBr).then((res:any) => {
      if (res === 201) {
        showMessage("success", "warnings.create_department_success");
      } else {
        showMessage("error", "warnings.create_department_error");
      }
    });
  };

  // Show success // error messages
  const showMessage = (type:string, message:string) => {
    dispatch(getUsers({limit: 1}));
    dispatch(getCompanyData());
    dispatch(getDepartments());
    if (type === "success") {
      toast.success(
        <CustomToastContainer
          message={t(`${message}`)}
          status={1} />, {
          autoClose: 5000,
        });
    } else {
      toast.error(
        <CustomToastContainer
          message={t(`${message}`)}
          status={3} />, {
          autoClose: 5000,
        });
    }
    setBranchName(name);
    setDepartmentName("");
    setEditBranchName(false);
    setEditDepartmentName(null);
    setShowNewDepartment(false);
    setNewDepartmentName("");
  };

  // Set name and show department name
  const showEditDepartment = (i:number, departmentName:string) => {
    setEditDepartmentName(i);
    setDepartmentName(departmentName);
  };

  if (isAllowed({data: {
    roles: [userRoles.superAdmin, userRoles.admin]
  }})) {
    return (
      <div
        className="general_drop_box_open"
      >
        <div className="space-between_drop">
          {editBranchName
            ?
            <div className="row" style={{width: "100%"}} ref={branchesInputActions}>
              <input
                type="text"
                className="branch_input"
                value={branchName}
                onChange={(e:any) => setBranchName(e.target.value)}
              />
              <Button
                buttonType="primary"
                buttonLabel={t("dashboard.general.save_btn")}
                buttonStyles={{height: "30px", width: "90px", marginLeft: "10px"}}
                buttonHandler={updateBranchName}
              />
            </div>
            : <h4>{name}</h4>
          }
          <ActionDotsDepsAndBranches
            edit={() => setEditBranchName(true)}
            removeBranch={removeBranch}
            addDepartment={() => setShowNewDepartment(true)}
            addBranchAndDeps={addBranchAndDeps}
          />
        </div>
        <div
          style={{marginTop:"2rem"}}
          className="space-between">
          <div className="drop_data">
            <p>{t("dashboard.general.drop_title_1")}</p>
            {data.map((item:any, i:number) => (
              <div className="row" key={item.id} style={{marginBottom: "15px"}}>
                <div
                  className="general_item_box center"
                >
                  {editDepartmentName === i
                    ?
                    <div className="row" ref={departmentsInputActions}>
                      <input
                        type="text"
                        className="branch_input"
                        value={departmentName}
                        onChange={(e:any) => setDepartmentName(e.target.value)}
                      />
                      <Button
                        buttonType="primary"
                        buttonLabel={t("dashboard.general.save_btn")}
                        buttonStyles={{height: "30px", width: "90px", marginLeft: "10px"}}
                        buttonHandler={updateDepartmentName}
                      />
                    </div>
                    : <h4>{item.departmentName}</h4>
                  }
                </div>
                <ActionDotsUpdateDepartments
                  edit={() => showEditDepartment(i, item.departmentName)}
                  removeDepartment={() => removeDepartment(i)}
                />
              </div>
            ))}
            {showNewDepartment
              ?
              <div className="row" style={{marginBottom: "15px"}}>
                <div className="general_item_box center">
                  <div className="row" ref={departmentsInputActions}>
                    <form>
                      <input
                        type="text"
                        className="branch_input"
                        value={newDepartmentName}
                        onChange={(e:any) => setNewDepartmentName(e.target.value)}
                      />
                      <Button
                        buttonType="primary"
                        buttonLabel={t("dashboard.general.save_btn")}
                        buttonStyles={{height: "30px", width: "90px", marginLeft: "10px"}}
                        submitButton={false}
                        buttonHandler={() => addDepartment()}
                      />
                    </form>
                  </div>
                </div>
              </div>
              : null
            }
          </div>
        </div>
      </div>
    );
  } else {
    return(
      <div
        onClick={() => setOpenDropdown(!openDropdown)}
        className={openDropdown
          ? "general_drop_box_open"
          : "general_drop_box" }
      >
        <div className="space-between_drop">
          <h4>{name}</h4>
          <div
            className="general_drop_plus">
            <div className="general_line_one" />
            <div
              className={openDropdown
                ? "general_line_two_clicked"
                : "general_line_two"}
            />
          </div>
        </div>
        {openDropdown && (
          <div
            style={{marginTop:"2rem"}}
            className="space-between">
            <div className="drop_data">
              <p>{t("dashboard.general.drop_title_1")}</p>
              <div className="general_drop_grid">
                {data.map((item:any) => (
                  <div className="general_item_box center" key={item.id}>
                    <h4>{item.departmentName}</h4>
                  </div>
                ))}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
