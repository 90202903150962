import {getToken} from "../../../utils/cookies/tokensCookie";
import environment from "../../../environment";
import {urls} from "../../urls";
import axios from "axios";
import qs from "qs";
import {ICreateSupportMessage, ICreateSupportMessageResponse} from "./support.interface";

export class supportService {
  constructor() {}

  async createSupportMessage(params: ICreateSupportMessage): Promise<ICreateSupportMessageResponse> {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.support.index}`;
    return await axios.post(fullUrl, params,{
      headers: { Authorization: `Bearer ${token}` }
    })
      .then((res: any) => {
        console.log("RES");
        console.log(res);
        return {
          status: true
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

}
