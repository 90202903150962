import React, {useEffect, useState} from "react";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import SelectWithChoices from "../SimpleComponents/SelectWithChoices";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import { useTranslation } from "react-i18next";
import ActionDotsCSVUpload from "../SimpleComponents/ActionDotsCSVUpload";
import {updateEmployee} from "../../redux/actions/usersActions";
import {motion} from "framer-motion";
import validatePhoneNumber from "../../utils/phoneNumberValidation";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";

interface CustomProps {
  item: any;
  branches?: any;
  departments?: any;
  branchesAndDep?: any;
  handleBranchSelect: (selected:any, index:number) => void;
  handleDepartmentSelect: (selected:any, index:number) => void;
  deleteUser: (index:number) => void;
  setCsvWithoutHeader: (newValues:any) => void;
  data: any;
  index: number;
  currentPage: any;
  company?: any;
  withAction?: boolean;
}

interface ISelectOption {
    value: string,
    label: string
}

function CsvTableItem(
  {
    item,
    branches,
    departments,
    branchesAndDep,
    handleBranchSelect,
    handleDepartmentSelect,
    deleteUser,
    setCsvWithoutHeader,
    data,
    index,
    currentPage,
    company,
    withAction
  }:CustomProps) {
  const { t } = useTranslation();

  //Filtered Data for selects
  const [selectedBranch, setSelectedBranch] = useState(item.branch);
  const [selectedDepartment, setSelectedDepartment] = useState(item.department);
  const [filteredDepartments, setFilteredDepartments] = useState<ISelectOption[]>([]);
  const [filteredBranches, setFilteredBranches] = useState<ISelectOption[]>([]);
  const [branchAndDepError, setBranchAndDepError] = useState("");

  const createOptionsArray = (data: any) => {
    const options: ISelectOption[] = [];
    data.forEach((item: any) => {
      options.push({
        value: item._id,
        label: item.name
      });
    });
    return options;
  };

  // from data to select option
  useEffect(() => {
    if (branches && branches.branches && branches.branches?.length) {
      const result = createOptionsArray(branches.branches);
      setFilteredBranches(result);
    }
  }, [branches.branches]);

  // Set data to select options
  useEffect(() => {
    if (departments && departments.departments && departments.departments.length) {
      const result = createOptionsArray(departments.departments);
      setFilteredDepartments(result);
    }
  }, [departments.departments]);

  // Handle selects dropdown
  const branchSelect = (selected:any, index:number) => {

    if (selected.value !== "*") {

      handleBranchSelect(selected, index);
      setSelectedBranch(selected);

      const departmentInSelectedBranch:any = [];
      branchesAndDep.forEach((branAndDep:any) => {
        if (branAndDep.branchId === selected.value) {
          departments.departments.forEach((department:any) => {
            if (branAndDep.departmentId
                            === department._id) {
              departmentInSelectedBranch.push({
                value: department._id,
                label: department.name
              });
            }
          });
        }
      });
      const uniqueBranches = departmentInSelectedBranch
        .filter((elem:any, index:number) =>
          departmentInSelectedBranch
            .findIndex((obj:any) =>
              obj.value === elem.value) === index);
      setFilteredDepartments(uniqueBranches);

      // Detect errors in deps between branches and departments
      let isAllowedBranchAndSelect = false;
      branchesAndDep.forEach((branAndDep:any) => {
        if (branAndDep.branchId === selected.value) {
          if (branAndDep.departmentId === selectedDepartment.value) {
            isAllowedBranchAndSelect = true;
          }
        }
      });
      if (isAllowedBranchAndSelect) {
        setBranchAndDepError("");
      } else {
        setBranchAndDepError(t("warnings.branch_dep_error_csv"));
      }

    } else {
      handleDepartmentSelect(item.branch, index);
      toast.warning(
        <CustomToastContainer
          message={t("warnings.csv_upload_select")}
          status={2} />);
    }
  };

  // handle actions after DEPARTMENT selected
  const departmentSelect = (selected:any, index:number) => {
    setSelectedDepartment(selected);
    handleDepartmentSelect(selected, index);
    // Detect errors in deps between branches and departments
    let isAllowedBranchAndSelect = false;
    branchesAndDep.forEach((branAndDep:any) => {
      if (branAndDep.branchId === selectedBranch.value) {
        if (branAndDep.departmentId === selected.value) {
          isAllowedBranchAndSelect = true;
        }
      }
    });
    if (isAllowedBranchAndSelect) {
      setBranchAndDepError("");
    } else {
      setBranchAndDepError(t("warnings.branch_dep_error_csv"));
    }
  };

  // Show inputs if update data
  const [showInputs, setShowInputs] = useState(false);

  const updateData = (state:boolean) => {
    setShowInputs(state);
  };

  const handleInputChange = (e:any, name:string) => {
    const dataCopy = [...data];
    if (currentPage > 1) {
      dataCopy[(currentPage - 1) + 4][name] = e.target.value;
    } else {
      dataCopy[index][name] = e.target.value;
    }
    setCsvWithoutHeader(dataCopy);
  };

  // Validate email
  const validateEmail = (email:string) => {
    // eslint-disable-next-line max-len
    const re = /\S+@\S+\.\S+/; // /^[a-zA-Z0-9.!#$%&’*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$
    return re.test(email);
  };

  const isDirty = (item:any):any => {
    if (item && item.branch) {
      if (
        item?.firstName?.trim()?.length < 1
      ) {
        return {
          state: true,
          field: "firstName"
        };
      } else if (item?.lastName?.trim()?.length < 1) {
        return {
          state: true,
          field: "lastName"
        };
      } else if (!validateEmail(item?.email)) {
        return {
          state: true,
          field: "email"
        };
      } else if (new getLunchSettings().isVirtualCard() && !validatePhoneNumber(item?.phoneNumber)) {
        return {
          state: true,
          field: "phoneNumber"
        };
      }
      return {
        state: false
      };
    }
    return true;
  };

  const employmentTypeOptions = [
    {
      value: "fullTime",
      label: "Full Time"
    },
    {
      value: "partTime",
      label: "Part Time"
    }
  ];

  const getEmployeeTypeObject = (value: "partTime" | "fullTime") => {
    if (value === "partTime") {
      return {
        value: "partTime",
        label: "Part Time"
      };
    } else {
      return {
        value: "fullTime",
        label: "Full Time"
      };
    }
  };

  console.log(item);

  return(
    <React.Fragment>
      {Object.keys(item).length
        ?
        <>
          <tr className="spacer" />
          <tr className={isDirty(item).state ? "table-item dirty" : "table-item"}>
            {item.firstName ?
              <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.firstName}
                    onChange={(e:any) => handleInputChange(e, "firstName")}
                  />
                  : item.firstName
                }
                {isDirty(item).field && isDirty(item).field === "firstName"
                  ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                    {t("formik_warnings.general_info.firstName")}
                  </p>
                  : null
                }
              </td> : <></>}

            {item.lastName ?
              <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.lastName}
                    onChange={(e:any) => handleInputChange(e, "lastName")}
                  />
                  : item.lastName
                }
                {isDirty(item).field && isDirty(item).field === "lastName"
                  ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                    {t("formik_warnings.general_info.lastName")}
                  </p>
                  : null
                }
              </td>
              : <></>}
            {item.email ?
              <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.email}
                    onChange={(e:any) => handleInputChange(e, "email")}
                  />
                  : item.email
                }
                {isDirty(item).field && isDirty(item).field === "email"
                  ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                    {t("formik_warnings.general_info.emailValid")}
                  </p>
                  : null
                }
              </td>
              : <></>}

            {item.officeName
              ? <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.officeName}
                    onChange={(e:any) => handleInputChange(e, "officeName")}
                  />
                  : item.officeName
                }
              </td>
              : null
            }
            {item.officeStreet
              ? <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.officeStreet}
                    onChange={(e:any) => handleInputChange(e, "officeStreet")}
                  />
                  : item.officeStreet
                }
              </td>
              : null
            }
            {item.officeZip
              ? <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.officeZip}
                    onChange={(e:any) => handleInputChange(e, "officeZip")}
                  />
                  : item.officeZip
                }
              </td>
              : null
            }

            {item.officeCity
              ? <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.officeCity}
                    onChange={(e:any) => handleInputChange(e, "officeCity")}
                  />
                  : item.officeCity
                }
              </td>
              : null
            }

            {company.company?.virtualCard
              &&
              <td>
                {showInputs
                  ?
                  <input
                    type="text"
                    className="csv_upload_input"
                    value={item.phoneNumber}
                    onChange={(e:any) => handleInputChange(e, "phoneNumber")}
                  />
                  : item.phoneNumber
                }
                {isDirty(item).field && isDirty(item).field === "phoneNumber"
                  ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px", maxWidth: "100px"}}>
                    {t("formik_warnings.general_info.phoneNumber")}
                  </p>
                  : null
                }
              </td>
            }

            <td>
              {showInputs
                ?
                <input
                  type="text"
                  className="csv_upload_input"
                  value={item.customIdentifier}
                  onChange={(e:any) => handleInputChange(e, "customIdentifier")}
                />
                : item.customIdentifier ?? <></>
              }
            </td>

            <td>
              {item.employmentType ?
                <SelectWithChoices
                  defaultValue={getEmployeeTypeObject(item.employmentType)}
                  style={{marginRight:"1rem"}}
                  name={t("dashboard.plans.select_employment_type")}
                  selectPlaceHolder=
                    {t("dashboard.plans.select_employment_type")}
                  selectId="employmentType"
                  isMulti={"no"}
                  options={employmentTypeOptions}
                  handleAddChoice={
                    (sel:any) => {
                      handleInputChange({target: {value: sel.value}}, "employmentType");
                    }
                  }
                  size="small"
                /> : <></>}
            </td>

            <td>
              {item.branch ?
                <SelectWithChoices
                  defaultValue={item.branch}
                  style={{marginRight:"1rem"}}
                  name={t("dashboard.plans.select_1")}
                  selectPlaceHolder=
                    {t("dashboard.plans.select_1")}
                  selectId="branches"
                  isMulti={"no"}
                  options={filteredBranches}
                  handleAddChoice={
                    (sel:any) => {
                      branchSelect(sel, item.index);
                    }
                  }
                  size="small"
                /> : <></>}
            </td>

            <td>

              {item.branch
                ? <div style={{position: "relative"}}>
                  <SelectWithChoices
                    defaultValue={item.department}
                    name={t("dashboard.plans.select_2")}
                    selectPlaceHolder=
                      {t("dashboard.plans.select_2")}
                    selectId="departments"
                    isMulti={"no"}
                    options={filteredDepartments}
                    handleAddChoice={
                      (sel:any) =>
                        departmentSelect(
                          sel,
                          item.index)
                    }
                    size="small"
                  />
                  {branchAndDepError && branchAndDepError.length && (
                    <motion.p
                      animate={{ y: 5 }}
                      className="input_error"
                      style={{
                        position: "absolute",
                        fontSize: "10px",
                        marginTop: "-5px"
                      }}
                    >
                      {branchAndDepError}
                    </motion.p>
                  )}
                </div>
                : <></>
              }

            </td>

            <td>
              {withAction
                &&
                <ActionDotsCSVUpload
                  updateData={(state:boolean) => updateData(state)}
                  index={item.index}
                  removeUser={(index:number) => deleteUser(index)}
                />
              }
            </td>
          </tr>
        </>
        : null
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    branches: state.depsAndBranches.branches,
    departments: state.depsAndBranches.departments,
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(CsvTableItem);
