import "./PaymentAuthenticationPopup.css";
import {modalStyles} from "../../../utils/modalStyles";
import Modal from "react-modal";
import React, {useEffect, useState} from "react";
import image from "../../../img/Dashboard/Card/weavr_onboarding_login.svg";
import Button from "../../Buttons/Button";
import PaymentsResetPassword from "../../../pages/payments/ResetPassword";
import {getToken, setToken} from "../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../SimpleComponents/ToastContainer";
import {useTranslation} from "react-i18next";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import SelectWithChoices from "../../SimpleComponents/SelectWithChoices";
import {getUserRoles} from "../../../utils/cookies/userRolesCookies";
import {paymentsService} from "../../../api/services/Payments/payments.service";
import EnrollUserDevice from "../Auth/EnrollUserDevice/EnrollUserDevice";
import VerifySCA from "../Auth/VerifySCA/VerifySCA";
import {getWeavrCorporate} from "../../../redux/actions/weavrActions";
import {useDispatch} from "react-redux";

interface IProps {
  showModal: boolean;
  onModalChange: (state: boolean) => void;
  email: string;
  user?: any;
  onLoginSuccess: () => void;
  forEmployeePayments?: boolean;
  onCloseModal: () => void;
}

export default function PaymentAuthenticationPopup(props:IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userType = getUserRoles();

  const [refLogin, setRefLogin] = useState(null);
  const [authenticationLayout, setAuthenticationLayout] = useState<"login" | "forget" | "reset" | "enrollUserDevice" | "verifySCA">("login");
  const [forgetCompleted, setForgetCompleted] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [selectedEmail, setSelectedEmail] = useState(props?.user?.email || props.email);

  useEffect(() => {
    dispatch(getWeavrCorporate());
  }, []);

  useEffect(() => {
    if (props.showModal && authenticationLayout !== "verifySCA" && authenticationLayout !== "enrollUserDevice" && authenticationLayout !== "forget") {

      const createdForm = (window as any).OpcUxSecureClient?.form();

      setRefLogin(createdForm);

      setTimeout(() => {
        let input;
        if (props.forEmployeePayments) {
          input = createdForm.input(
            "p",
            "password",
            {
              placeholder: "0000",
              maxlength: 20
            }
          );
        } else {
          // Create an instance of a secure Passcode component that will collect the passcode
          input = createdForm.input(
            "passcode",
            "passCode",
            {
              placeholder: "0000"
            }
          );
        }

        // Embed the secure Passcode component in the HTML element where you want
        // the Passcode component to the shown on screen
        console.log("input", input);
        input.mount(document.getElementById("passcode"));
      }, 100);
    }
    // Set the user’s authentication token
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
  }, [props.showModal, authenticationLayout]);

  const onSubmit = (e:any) => {
    e.preventDefault();

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    refLogin.tokenize(function(tokens) {
      if (props.forEmployeePayments) {
        if (tokens && tokens.p) {
          login(tokens.p);
        }
      } else {
        if (tokens && tokens.passcode) {
          login(tokens.passcode);
        }
      }
    });
  };

  const login = async (tokenizedCode:any) => {
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.login}`,
        {
          email: selectedEmail,
          password: tokenizedCode
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      if (response.data && response.data.token) {

        console.log("response.data");
        console.log(response.data);

        if (response.data.stepUpRequired) {
          setToken(response.data.token, true);
          const stepUpResponse = await new paymentsService().sendStepUp(response.data.token);
          if (!stepUpResponse?.status && stepUpResponse?.data?.message === "CHANNEL_NOT_REGISTERED") {
            // Device is not enrolled => show "Enroll user device" state
            const weavrToken = await getToken(true);
            await axios.post(`${environment.baseUrl}${urls.payments.sendSmsVerification}`,
              {
                token: weavrToken
              },
              {
                headers: {Authorization: `Bearer ${token}`}
              });
            setAuthenticationLayout("enrollUserDevice");
          } else if (stepUpResponse?.status) {
            // Device is enrolled, can send SCA SMS verification
            setAuthenticationLayout("verifySCA");
          }
        } else {
          setToken(response.data.token, true);
          props.onModalChange(false);
          props.onLoginSuccess();
        }

      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={"error"}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const handleRecreatePassword = async () => {
    setButtonLoading(true);
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.recreatePassword}`,
        {
          email: selectedEmail
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      if (response.data && response.data.message && response.data.message === "sent") {
        setButtonLoading(false);
        setForgetCompleted(true);
      } else {
        toast.error(
          <CustomToastContainer
            message={"Etwas ist schief gelaufen"}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      setButtonLoading(false);
      toast.error(
        <CustomToastContainer
          message={"error"}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const options = [
    {
      label: props.email,
      value: props.email
    },
    {
      label: props?.user?.email,
      value: props?.user?.email
    }
  ];

  const renderModalComponent = () => {

    switch (authenticationLayout) {
    case "login":
      return <form
        ref={refLogin}
        onSubmit={(e) => onSubmit(e)}
        className="weavr_passcode_container"
      >
        <img
          onClick={() => props.onCloseModal()}
          style={{ top: "20px", right: "20px" }}
          className="close_popup"
          src={closeIcon}
          alt="close"
        />
        <div>
          <div className="weavr_login_container_content">
            <h1>{t("dashboard.payments.onboarding.stepper.login.title")}</h1>

            {
              props.user?.authorizedUserStatus
              && props.user?.authorizedUserStatus === "invitationConfirmed"
              && (userType === "1" || userType === "5")
              &&
              <>
                <p style={{marginBottom: "5px"}}>
                  {t("dashboard.payments.onboarding.stepper.login.user")}
                </p>
                <SelectWithChoices
                  defaultValue={{
                    label: props?.user?.email || props.email,
                    value: props?.user?.email || props.email
                  }}
                  style={{
                    width: "250px",
                    marginBottom: "24px"
                  }}
                  name={t("dashboard.plans.select_2")}
                  selectPlaceHolder={t("dashboard.plans.select_2")}
                  selectId="departments"
                  options={options}
                  handleAddChoice={(value) => setSelectedEmail(value.value)}
                  isMulti={"no"}
                />
              </>
            }

            <div style={{display: "none"}}>
              Username: <input type="input" value={selectedEmail} name="u" /><br/>
            </div>
            <div className="weavr_passcode_input_box">
              {t("dashboard.payments.onboarding.stepper.login.passcode")} <div id="passcode"></div><br/>
            </div>
            <p
              style={{cursor: "pointer"}}
              onClick={() => setAuthenticationLayout("forget")}
            >
              {t("dashboard.payments.onboarding.stepper.login.forgetPassword")}
            </p>
            <input
              className="weavr_passcode_btn"
              type="submit"
              value={t("dashboard.payments.onboarding.stepper.login.submit")}
            />
          </div>
        </div>
        <div>
          <img src={image} alt="login"/>
        </div>
      </form>;
    case "forget":
      return <div
        className="weavr_passcode_container"
      >
        <img
          onClick={() => props.onCloseModal()}
          style={{ top: "20px", right: "20px" }}
          className="close_popup"
          src={closeIcon}
          alt="close"
        />
        <div>
          <div className="weavr_login_container_content">
            {forgetCompleted
              ? <>
                <h1>Bitte prüfe dein Postfach</h1>
                <p>{`Wir haben eine E-Mail an ${selectedEmail} geschickt, um dein Passwort zurückzusetzen.`}</p>
              </>
              : <>
                <h1>Hast du dein Passwort vergessen?</h1>
                <Button
                  loading={buttonLoading}
                  disabled={false}
                  buttonType="primary"
                  buttonHtmlType="submit"
                  fullWidth={true}
                  buttonLabel={"Passwort zurücksetzen"}
                  buttonHandler={handleRecreatePassword}
                />
                <Button
                  loading={buttonLoading}
                  disabled={false}
                  buttonType="outlined"
                  fullWidth={true}
                  buttonLabel={"Zurück zum Login"}
                  buttonHandler={() => setAuthenticationLayout("login")}
                />
              </>
            }
          </div>
        </div>
        <div>
          <img src={image} alt="login"/>
        </div>
      </div>;
    case "reset":
      return <PaymentsResetPassword
        userEmail={selectedEmail}
        setShowModal={(state:boolean) => {
          setAuthenticationLayout("login");
          props.onModalChange(state);
        }}
      />;
    case "enrollUserDevice":
      return <EnrollUserDevice
        onSuccess={() => setAuthenticationLayout("verifySCA")}
        onError={() => setAuthenticationLayout("login")}
      />;
    case "verifySCA":
      return <VerifySCA
        onSuccess={() => {
          props.onModalChange(false);
          props.onLoginSuccess();
        }}
        onError={() => {
          setAuthenticationLayout("login");
        }}
      />;
    }
  };

  return(
    <Modal
      style={modalStyles}
      isOpen={props.showModal}
    >
      {renderModalComponent()}
    </Modal>
  );
}
