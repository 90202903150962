import React, { useState } from "react";
import "../SimpleComponents/TableSelectedItemActions.css";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import { toast } from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import { useTranslation } from "react-i18next";
import { updateInvoice } from "../../redux/actions/invoicesActions";

interface CustomProps {
  selectedRows?: any;
  checkedBoxes?: any;
  updateSelections?: () => void;
}

function OrdersSelectedMenu({
  selectedRows,
  checkedBoxes,
  updateSelections,
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);

  // Handle accept invoice
  const handleInvoice = (status: number) => {
    const arrayOfInvoices: any = [];
    if (checkedBoxes) {
      checkedBoxes.forEach((invoice: any) => {
        arrayOfInvoices.push({
          id: invoice.invoiceId,
          status: status,
          description: "",
        });
      });
    } else {
      selectedRows.forEach((invoice: any) => {
        arrayOfInvoices.push({
          id: invoice.invoiceId,
          status: status,
          description: "",
        });
      });
    }
    const postData = {
      invoices: arrayOfInvoices,
    };
    if (arrayOfInvoices.length) {
      dispatch(updateInvoice(postData));
    } else {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.invoices_status_warning")}
          status={2}
        />
      );
    }
    if (updateSelections) {
      updateSelections();
    }
    setShowMenu(false);
  };

  return (
    <div className="selected_table_actions">
      <p>
        {selectedRows.length && selectedRows.length > 1
          ? `${selectedRows.length} ${t("dashboard.overview.selected_title_1")}`
          : `${selectedRows.length} ${t(
            "dashboard.overview.selected_title_1_single"
          )}`}
      </p>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={
          showMenu ? "selected_actions_dots selected" : "selected_actions_dots"
        }
      >
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
      </div>
      <div
        className={
          showMenu
            ? "selected_actions_menu shadow"
            : "selected_actions_menu_hide"
        }
      >
        <div onClick={() => handleInvoice(2)}>
          <p>
            {selectedRows.length > 1
              ? t("dashboard.overview.selected_menu_1_plural")
              : t("dashboard.overview.selected_menu_1")}
          </p>
        </div>
        <div onClick={() => handleInvoice(3)}>
          <p>
            {selectedRows.length > 1
              ? t("dashboard.overview.selected_menu_2_plural")
              : t("dashboard.overview.selected_menu_2")}
          </p>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    selectedRows: state.selectedRows.selectedRows,
  };
};

export default connect(mapStateToProps, null)(OrdersSelectedMenu);
