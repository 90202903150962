// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.list_feedback_container {
    width: 100%;
    height: 50px;
    background-color: #EAEAEF;
    border-radius: 3px;
    margin-bottom: 10px;
    padding-left: 15px;
    display: flex;
    align-items: center;
}
.list_feedback_text {
    padding: 0;
    margin: 0;
    font-size: 0.8rem;
    color: #4B5960;
    font-weight: 500;
}
.list_feedback_span {
    padding: 0;
    margin-left: 10px;
    font-size: 0.8rem;
    color: rgb(107, 148, 215);
    font-weight: 600;
    cursor: pointer;
}
.list_feed_loader {
    height: 100%;
    max-height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 15px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/ListCounterFeedback.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,mBAAmB;IACnB,kBAAkB;IAClB,aAAa;IACb,mBAAmB;AACvB;AACA;IACI,UAAU;IACV,SAAS;IACT,iBAAiB;IACjB,cAAc;IACd,gBAAgB;AACpB;AACA;IACI,UAAU;IACV,iBAAiB;IACjB,iBAAiB;IACjB,yBAAyB;IACzB,gBAAgB;IAChB,eAAe;AACnB;AACA;IACI,YAAY;IACZ,gBAAgB;IAChB,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,iBAAiB;AACrB","sourcesContent":[".list_feedback_container {\n    width: 100%;\n    height: 50px;\n    background-color: #EAEAEF;\n    border-radius: 3px;\n    margin-bottom: 10px;\n    padding-left: 15px;\n    display: flex;\n    align-items: center;\n}\n.list_feedback_text {\n    padding: 0;\n    margin: 0;\n    font-size: 0.8rem;\n    color: #4B5960;\n    font-weight: 500;\n}\n.list_feedback_span {\n    padding: 0;\n    margin-left: 10px;\n    font-size: 0.8rem;\n    color: rgb(107, 148, 215);\n    font-weight: 600;\n    cursor: pointer;\n}\n.list_feed_loader {\n    height: 100%;\n    max-height: 50px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
