import React, { useEffect, useState } from "react";
import "./Modal.css";
import FormikInput from "./FormikInput";
import Button from "../Buttons/Button";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import SelectWithChoices from "./SelectWithChoices";
import { useTranslation } from "react-i18next";
import {
  getBranches,
  getDepartments,
  getDepartmentsAndBranches
} from "redux/actions/branchAndDepartmentActions";
import { addOneEmployee } from "../../redux/actions/usersActions";
import { capitalize } from "../../utils/capitalizeFirstLetter";
import validatePhoneNumber from "../../utils/phoneNumberValidation";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import PhoneNumberInput from "../Elements/PhoneNumberInput/PhoneNumberInput";

interface CustomProps {
  closeModal: () => void;
  departments?: any;
  branches?: any;
  branchesAndDep?: any;
  company?: any;
}

interface IUserInformation {
  imageUrl: string;
  firstName: string;
  lastName: string;
  customIdentifier: string;
  employmentType: "fullTime" | "partTime";
  email: string;
  approveInvoices: string;
  departmentId: string;
  branchId: string;
  phoneNumber: string;
}

function ModalEmployeeAdd({
  closeModal,
  departments,
  branches,
  branchesAndDep,
  company
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  //Take departments data
  useEffect(() => {
    dispatch(getDepartments());
  }, [dispatch]);

  // Filtered ids for request
  const [selectedBranch, setSelectedBranch] = useState({
    value: "",
    label: ""
  });
  const [selectedDepartment, setSelectedDepartment] = useState({
    value: "",
    label: ""
  });

  const employmentTypeOptions = [
    {
      value: "fullTime",
      label: "Vollzeit"
    },
    {
      value: "partTime",
      label: "Teilzeit"
    }
  ];

  const [branchAndDepError, setBranchAndDepError] = useState("");

  const formik = useFormik<IUserInformation>({
    initialValues: {
      imageUrl: "undefined",
      firstName: "",
      lastName: "",
      customIdentifier: "",
      employmentType: "fullTime",
      email: "",
      phoneNumber: "",
      approveInvoices: "false",
      departmentId: selectedDepartment.value,
      branchId: selectedBranch.value
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(
        t("formik_warnings.general_info.firstName")
      ),
      lastName: Yup.string().required(
        t("formik_warnings.general_info.lastName")
      ),
      email: Yup.string()
        .required(t("formik_warnings.general_info.email"))
        .email(t("formik_warnings.general_info.emailValid")),
      departmentId: Yup.string()
        .required(t("formik_warnings.general_info.branch"))
        .min(2),
      branchId: Yup.string()
        .required(t("formik_warnings.general_info.department"))
        .min(2),
      phoneNumber: Yup.string()
        .test("test-name", "Error", function(value:any) {
          if (value && value.length > 0) {
            const { path, createError } = this;
            const phoneValidation = validatePhoneNumber(value);
            if (phoneValidation) {
              return true;
            } else {
              return createError({ path, message: t("formik_warnings.general_info.phoneNumber_validation") });
            }
          }
          return true;
        })
    }),
    onSubmit: (values) => {
      values.firstName =
        values.firstName.substr(0, 1).toUpperCase() +
        values.firstName.substr(1);
      values.lastName =
        values.lastName.substr(0, 1).toUpperCase() + values.lastName.substr(1);
      let isAllowedBranchAndSelect = false;
      branchesAndDep.forEach((branAndDep: any) => {
        if (branAndDep.branchId === formik.values.branchId) {
          if (branAndDep.departmentId === formik.values.departmentId) {
            isAllowedBranchAndSelect = true;
          }
        }
      });
      if (isAllowedBranchAndSelect) {
        setButtonLoading(true);
        values.firstName = capitalize(values.firstName);
        values.lastName = capitalize(values.lastName);
        dispatch(addOneEmployee(values));
        setTimeout(() => {
          setButtonLoading(false);
        }, 500);
      } else {
        setBranchAndDepError(t("warnings.branch_dep_error"));
      }
    }
  });

  useEffect(() => {
    dispatch(getBranches());
  }, [dispatch]);

  // take branchesAndDepartments data
  useEffect(() => {
    dispatch(getDepartmentsAndBranches());
  }, [dispatch]);

  // from data to select options
  useEffect(() => {
    if (branches.branches?.length) {
      const options: any = [];
      branches.branches.forEach((item: any) => {
        options.push({
          value: item._id,
          label: item.name
        });
      });
      setBranchesOptions(options);
    }
  }, [branches.branches]);

  useEffect(() => {
    if (departments.departments?.length) {
      const options: any = [];
      departments.departments.forEach((item: any) => {
        options.push({
          value: item._id,
          label: item.name
        });
      });
      setFilteredDepartments(options);
    }
  }, [departments.departments]);

  // Options for selects
  const [branchesOptions, setBranchesOptions] = useState([]);

  // Filtered data for options
  const [filteredDepartments, setFilteredDepartments] = useState([]);

  // handle actions after branch selected
  const handleBranchSelect = (selected: any) => {
    if (departments.departments?.length) {
      const departmentInSelectedBranch: any = [];
      branchesAndDep.forEach((branAndDep: any) => {
        if (branAndDep.branchId === selected.value) {
          departments.departments.forEach((department: any) => {
            if (branAndDep.departmentId === department._id) {
              departmentInSelectedBranch.push({
                value: department._id,
                label: department.name
              });
            }
          });
        }
      });
      // remove duplicated branches
      const uniqueBranches = departmentInSelectedBranch.filter(
        (elem: any, index: number) =>
          departmentInSelectedBranch.findIndex(
            (obj: any) => obj.value === elem.value
          ) === index
      );
      setFilteredDepartments(uniqueBranches);
      setSelectedBranch({
        value: selected.value,
        label: selected.label
      });
      formik.setFieldValue("branchId", selected.value, true);
    }
  };

  // handle actions after DEPARTMENT selected
  const handleDepartmentSelect = (selected: any) => {
    setSelectedDepartment({
      label: selected.label,
      value: selected.value
    });
    formik.setFieldValue("departmentId", selected.value, true);
  };

  const labelStyles = {
    paddingBottom: "7px",
    paddingLeft: "7px",
    fontSize: "0.9rem"
  };

  const [buttonLoading, setButtonLoading] = useState(false);

  return (
    <div className="modal_container">
      <img
        onClick={() => closeModal()}
        className="close_popup"
        src={closeIcon}
        alt="close"
      />
      <h2>{t("dashboard.employee.employee_add_modal_title")}</h2>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="relative" style={{ width: "90%" }}>
            <FormikInput
              style={{ width: "100%", marginRight: "15px" }}
              htmlFor="firstName"
              name="firstName"
              value={formik.values.firstName}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_1")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.firstName && formik.touched.firstName && (
              <p className="input_warning">{formik.errors.firstName}</p>
            )}
          </div>
          <div
            className="relative"
            style={{ width: "90%", marginLeft: "20px" }}
          >
            <FormikInput
              style={{ width: "100%" }}
              htmlFor="lastName"
              name="lastName"
              value={formik.values.lastName}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_2")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.lastName && formik.touched.lastName && (
              <p className="input_warning">{formik.errors.lastName}</p>
            )}
          </div>
        </div>
        <div style={{ marginBottom: "1rem" }} className="employee_select_row">
          <div style={{ width: "100%" }}>
            <p className="filter_label" style={labelStyles}>
              {t("dashboard.employee.employee_modal_label_5")}
            </p>

            <div className="relative">
              <SelectWithChoices
                style={{ width: "100%", paddingRight: "10px" }}
                isMulti="no"
                name="branch"
                onBlur={formik.handleBlur("branchId")}
                selectPlaceHolder={t(
                  "dashboard.employee.employee_modal_label_5"
                )}
                selectId="branches"
                options={branchesOptions}
                handleAddChoice={handleBranchSelect}
              />
              {formik.errors.branchId && formik.touched.branchId && (
                <p className="input_warning" style={{ bottom: "-12px" }}>
                  {formik.errors.branchId}
                </p>
              )}
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <p className="filter_label" style={labelStyles}>
              {t("dashboard.employee.employee_modal_label_4")}
            </p>

            <div className="relative">
              <SelectWithChoices
                style={{ width: "100%", paddingLeft: "5px" }}
                isMulti="no"
                name="department"
                onBlur={formik.handleBlur("departmentId")}
                selectPlaceHolder={t(
                  "dashboard.employee.employee_modal_label_4"
                )}
                selectId="departments"
                options={filteredDepartments}
                handleAddChoice={handleDepartmentSelect}
              />
              {formik.errors.departmentId && formik.touched.departmentId && (
                <p className="input_warning" style={{ bottom: "-12px" }}>
                  {formik.errors.departmentId}
                </p>
              )}
              {branchAndDepError.length ? (
                <p style={{ bottom: "-12px" }} className="input_warning">
                  {branchAndDepError}
                </p>
              ) : null}
            </div>
          </div>
        </div>

        <div className="relative" style={{ width: "100%" }}>
          <p className="filter_label" style={labelStyles}>
            {t("dashboard.employee.employee_modal_label_6")}
          </p>
          <SelectWithChoices
            style={{ width: "100%", maxWidth: "none", marginBottom: "10px" }}
            isMulti="no"
            name="employmentType"
            onBlur={formik.handleBlur("employmentType")}
            selectPlaceHolder={t("dashboard.employee.employee_modal_label_6")}
            selectId="employmentType"
            options={employmentTypeOptions}
            handleAddChoice={(type: any) => {
              formik.setFieldValue("employmentType", type.value, true);
            }}
          />
        </div>

        <div className="relative" style={{ width: "100%" }}>
          <FormikInput
            style={{ width: "100%", marginRight: "15px" }}
            htmlFor="customIdentifier"
            name="customIdentifier"
            value={formik.values.customIdentifier}
            disabled={false}
            handleChange={formik.handleChange}
            label={t("dashboard.employee.employee_modal_label_identifier")}
            onBlur={formik.handleBlur}
          />
          {formik.errors.customIdentifier &&
            formik.touched.customIdentifier && (
            <p className="input_warning">{formik.errors.customIdentifier}</p>
          )}
        </div>

        <div className="row">
          <div className="relative" style={{width: "90%"}}>
            <FormikInput
              htmlFor="email"
              name="email"
              value={formik.values.email}
              disabled={false}
              handleChange={formik.handleChange}
              label={t("dashboard.employee.employee_modal_label_3")}
              onBlur={formik.handleBlur}
            />
            {formik.errors.email && formik.touched.email && (
              <p className="input_warning">{formik.errors.email}</p>
            )}
          </div>
          <div className="relative" style={{width: "90%", marginLeft: "20px"}}>

            <PhoneNumberInput
              label={t("dashboard.employee.employee_modal_label_7")}
              name={"phoneNumber"}
              handleChange={(value: string) => {
                formik.setFieldValue("phoneNumber", value.replace(/ /g,""), true);
              }}
              value={formik.values.phoneNumber}
              onBlur={formik.handleBlur}
              error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : undefined}
            />
          </div>
        </div>

        <Button
          disabled={!(formik.isValid && formik.dirty)}
          buttonType="primary"
          buttonHtmlType="submit"
          buttonLabel={t("dashboard.employee.employee_modal_btn_4")}
          submitButton={true}
          fullWidth={true}
          loading={buttonLoading}
        />
      </form>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    departments: state.depsAndBranches.departments,
    branches: state.depsAndBranches.branches,
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(ModalEmployeeAdd);
