import i18n from "../../../i18next";
export const phoneNumberCodes: any = {
  AT: {
    country: "Austria",
    label: "components.elements.phoneNumberInput.countries.AT",
    code: "+43",
    flag: "🇦🇹"
  },
  DE: {
    country: "Germany",
    label: "components.elements.phoneNumberInput.countries.DE",
    code: "+49",
    flag: "🇩🇪"
  }
};
