import React, {useState} from "react";
import NumberFormat from "react-number-format";
import Button from "../../../Components/Buttons/Button";
import {useTranslation} from "react-i18next";
import {getToken} from "../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import image from "img/Dashboard/Card/weavr_onborading_verification.svg";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { toast } from "react-toastify";


interface IProps {
  weavr: any;
  handleSlider: () => void
}


export default function PaymentEmailVerification({weavr, handleSlider}:IProps) {
  const { t } = useTranslation();

  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);
  const [code, setCode] = useState("");

  const handleSvnInput = async (e:any) => {
    const inputNumber = e.target.value;
    setCode(inputNumber.toString().split(" ").join(""));
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (code.length === 6) {
      setShowSubmitButtonLoading(true);
      const token = await getToken();
	  try{
        const response = await axios.post(`${environment.baseUrl}${urls.payments.verify}`,
          {
            email: weavr.rootUser.email,
            code: code
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          });
        if (response.data) {
          setShowSubmitButtonLoading(false);
          handleSlider();
        }
	  }catch(e){
        toast.error(
          <CustomToastContainer
			  message={"Wrong verification code"}
			  status={3} />, {
			  autoClose: 5000,
          });
        setShowSubmitButtonLoading(false);
	  }
    }
  };

  const resendEmail = async () => {
    setShowSubmitButtonLoading(true);
    const token = await getToken();
    const response = await axios.post(`${environment.baseUrl}${urls.payments.sendEmailVerification}`,
      {
        email: weavr.rootUser.email
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    if (response && response.data) {
      toast.success(
        <CustomToastContainer
          message={t("dashboard.payments.onboarding.stepper.verification.success_notification")}
          status={1} />, {
          autoClose: 5000,
        });
    } else {
      toast.error(
        <CustomToastContainer
          message={"Something went wrong"}
          status={3} />, {
          autoClose: 5000,
        });
    }
  };

  return(
    <div className="create_password_page">
      <form className="verification_form" onSubmit={(e) => handleSubmit(e)}>
        <div className="center">
          <div className="verification_form_content">
            <h1>{t("dashboard.payments.onboarding.stepper.verification.title")}</h1>
            <p>{t("dashboard.payments.onboarding.stepper.verification.text")}</p>

            <p
              style={{color: "blue", textDecoration: "underline", cursor: "pointer"}}
              onClick={() => {
                resendEmail();
              }}
            >
              {t("dashboard.payments.onboarding.stepper.verification.resend_link")}
            </p>

            {/*<div className="verification_form_content_input">
              <p>{t("dashboard.payments.onboarding.stepper.verification.label")}</p>
              <NumberFormat
                disabled={false}
                className="code_input"
                format="# # # # # #"
                onChange={(e:any) => handleSvnInput(e)}
              />
            </div>*/}
            {/*<Button
              buttonType="primary"
              buttonLabel={t("dashboard.payments.onboarding.stepper.verification.btn")}
              buttonHtmlType="submit"
              submitButton={true}
              fullWidth={true}
              buttonStyles={{maxWidth: "250px"}}
              disabled={code.length < 6}
              loading={showSubmitButtonLoading}
            />*/}
          </div>
        </div>
        <div className="center">
          <img src={image} alt="verification"/>
        </div>
      </form>
    </div>
  );
}
