import "../SimpleComponents/Modal.css";

import React from "react";
import ArrowIcon from "../../img/Dashboard/Orders/arrow-right-solid.svg";

import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import moment from "moment";
import UserAvatar from "../Containers/UserAvatar";
import getBadgeDataForInvoiceStatus from "../Elements/Badge/helpers/getBadgeDataForInvoiceStatus";
import Badge from "../Elements/Badge/Badge";

interface CustomProps {
  closeModal: () => void;
  changeLogData: any
}

export default function ChangelogModal({closeModal, changeLogData}: CustomProps) {

  return (
    <div className="changelog_modal white">
      <img
        onClick={() => closeModal()}
        style={{ top: "0px" }}
        className="close_popup_paddings"
        src={closeIcon}
        alt="close"
      />
      <div className="changelog_wrapper">
        <ul className="sessions">
          {changeLogData.map((changelog:any, i:number) => (
            <li key={`${changelog.updatedAt}${i.toString()}`}>
              <div className="time">
                {moment(changelog.updatedAt).format("DD.MM.YYYY HH:mm")}
              </div>
              <div className="row" style={{marginTop: "7px"}}>
                <UserAvatar small imageUrl={changelog.updatedBy.image} />
                <p className="changelog_name" style={{
                  marginBottom: "3px",
                  marginLeft: "10px",
                  marginRight: "40px"
                }}
                >
                  {changelog.updatedBy.name}
                </p>
                <div className="row">
                  <Badge
                    color={getBadgeDataForInvoiceStatus((+changelog.update.prevStatus as any)).color}
                    text={getBadgeDataForInvoiceStatus((+changelog.update.prevStatus as any)).text}
                  />
                  <img src={ArrowIcon} alt="arrow" className="update_info_arrow"/>
                  <Badge
                    color={getBadgeDataForInvoiceStatus((+changelog.update.currStatus as any)).color}
                    text={getBadgeDataForInvoiceStatus((+changelog.update.currStatus as any)).text}
                  />
                </div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
