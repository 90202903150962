import {Dispatch} from "redux";
import {
  BRANCHES_CHART_SUCCESS,
  CHARTS_DATA_ERROR,
  chartsDataDispatchTypes,
  DAILY_CHART_SUCCESS,
  INVOICES_STATS_SUCCESS,
  SUMMARY_CHART_SUCCESS
} from "../types/chartsTypes";
import moment from "moment";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import {urls} from "../../api/urls";
import { getUserCompany } from "utils/cookies/userCookies/userCookies";

// Max Limit chart
export const getInvoicesStatisticData = () =>
  async (dispatch: Dispatch<chartsDataDispatchTypes>) => {
    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.invoices.indexForCompanyByCompanyId}${companyId}`,
      successDispatchType: INVOICES_STATS_SUCCESS,
      errorDispatchType: CHARTS_DATA_ERROR,
      errorPayload: "get_invoices_stat"
    }).get(dispatch);
  };

// Daily chart
export const getDailyChartData = () =>
  async (dispatch: Dispatch<chartsDataDispatchTypes>) => {
    const companyId = getUserCompany();
    const endDate = moment().add(1,"days").format("YYYY-MM-DD");
    const startDate = moment().subtract(9,"d").format("YYYY-MM-DD");
    const dynamicParams = {
      startDate: startDate,
      endDate: endDate,
    };
    await new reduxRequestHandler({
      url: `${urls.charts.dailyReportByCompanyId}${companyId}`,
      dynamicParams,
      successDispatchType: DAILY_CHART_SUCCESS,
      errorDispatchType: CHARTS_DATA_ERROR,
      errorPayload: "get_daily_chart"
    }).get(dispatch);
  };

// Summary Chart
export const getSummaryChartData = () =>
  async (dispatch: Dispatch<chartsDataDispatchTypes>) => {
    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.charts.summaryByCompanyId}${companyId}`,
      successDispatchType: SUMMARY_CHART_SUCCESS,
      errorDispatchType: CHARTS_DATA_ERROR,
      errorPayload: "get_summary_chart"
    }).get(dispatch);
  };

// Branches Chart
export const getBranchesChartData = () =>
  async (dispatch: Dispatch<chartsDataDispatchTypes>) => {
    const companyId = getUserCompany();
    const endDate = moment().add(1, "days").format("YYYY-MM-DD");
    const startDate = moment().subtract(30,"d").format("YYYY-MM-DD");
    const dynamicParams = {
      startDate: startDate,
      endDate: endDate,
    };
    await new reduxRequestHandler({
      url: `${urls.charts.branchesByCompanyId}${companyId}`,
      dynamicParams,
      successDispatchType: BRANCHES_CHART_SUCCESS,
      errorDispatchType: CHARTS_DATA_ERROR,
      errorPayload: "get_branches_chart"
    }).get(dispatch);
  };
