/* eslint-disable max-len */
import React, { useEffect, useState } from "react";
import editPen from "../../img/icon-pencil.svg";
import uploadPlaceholder from "img/Dashboard/upload_placeholder.svg";
import axios from "axios";
import environment from "../../environment";
import { toast } from "react-toastify";
import { prepareImage } from "../../redux/actions/prepareImage";
import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../utils/permissionsAllow";
import CustomToastContainer from "./ToastContainer";
import { useTranslation } from "react-i18next";
import { getToken } from "../../utils/cookies/tokensCookie";
import Compressor from "compressorjs";
import { urls } from "../../api/urls";
import Button from "Components/Buttons/Button";

import { ReactComponent as PencilIcon } from "../../img/i_edit.svg";

interface CustomProps {
  ImageUrl: string;
  setImageUrl: (url: string) => void;
  setImageName: (url: string) => void;
  update: (e: any, imageKey?: string, imageName?: string) => void;
  icon: boolean;
}

export default function PdfUploadButton({
  ImageUrl,
  setImageUrl,
  setImageName,
  update,
  icon,
}: CustomProps) {
  const { t } = useTranslation();
  const [currentImage, setCurrentImage] = useState(uploadPlaceholder);
  const [uploadedImage, setUploadedImage] = useState(false);

  // CHECK IMAGE EXIST
  const [isImageExist, setIsImageExist] = useState(false);

  useEffect(() => {
    const getImage = async () => {
      if (ImageUrl && !uploadedImage) {
        await axios
          .get(ImageUrl)
          .then((value) => {
            if (value.status <= 201) {
              setIsImageExist(true);
            } else {
              setIsImageExist(false);
            }
          })
          .catch((error) => setIsImageExist(false));
      } else if (uploadedImage) {
        setIsImageExist(true);
      }
    };
    getImage();
    //Need to disable it, because eslint says, that I need to add
    //uploadedImage, but it will broke a logic
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    async function fetchData() {
      if (ImageUrl !== uploadPlaceholder && ImageUrl !== "undefined") {
        try {
          const response = await prepareImage(ImageUrl);
          setCurrentImage(response);
          setIsImageExist(true);
          setUploadedImage(true);
        } catch (e: any) {
          setCurrentImage(uploadPlaceholder);
        }
      } else {
        setCurrentImage(uploadPlaceholder);
      }
    }
    fetchData();
  }, [ImageUrl]);

  useEffect(() => {
    if (currentImage !== uploadPlaceholder && ImageUrl === "undefined") {
      setCurrentImage(uploadPlaceholder);
    }
  }, [currentImage]);

  const handleFile = async (e: any) => {
    if (
      isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
          permissions: {
            isAdmin: true,
            permissionsArray: [
              userPermissions.generalInfo,
              userPermissions.users,
            ],
          },
        },
      })
    ) {
      const save = async (fileToSave: any) => {
        setCurrentImage(URL.createObjectURL(fileToSave));
        const token = getToken();
        const options = { headers: { Authorization: `Bearer ${token}` } };
        const fullUrl = `${environment.baseUrl}${urls.files.files}`;
        const formData = new FormData();
        formData.append("file", fileToSave);
        formData.append("parameterName", "file");
        formData.append("mimeType", "application/pdf");
        try {
          const uploadImage = await axios.post(fullUrl, formData, options);
          setImageUrl(uploadImage.data.key);

          setImageName(uploadImage.data.originalname);
          update(
            undefined,
            uploadImage.data.key,
            uploadImage.data.originalname
          );
        } catch (e: any) {
          console.error(e);
        }
      };

      const file = e.target.files[0];
      save(file);
    } else {
      toast.warning(
        <CustomToastContainer message={t("warnings.not_allowed")} status={2} />
      );
    }
  };

  return (
    <div className="image_upload-center">
      <div style={{ position: "relative", cursor: "pointer" }}>
        {icon ? (
          <PencilIcon
            style={{ cursor: "pointer", width: "18px", display: "flex" }}
            fill={"#000"}
          />
        ) : (
          <div style={{ minWidth: "205px" }}>
            <Button buttonType={"primary"} buttonLabel={"PDF Upload"} />
          </div>
        )}

        <input
          style={{ width: "100%", height: "100%", cursor: "pointer", top: 0 }}
          onChange={handleFile}
          type="file"
          className="hide-file-input"
          accept=".pdf"
        />
      </div>
    </div>
  );
}
