import React, {useState} from "react";
import "./ListStyles.css";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import invoiceIcon from "img/Dashboard/Orders/invoice_placeholder.png";
import UserAvatar from "../Containers/UserAvatar";
import {timeSince} from "../../utils/timeSince";
import {takeNotificationData} from "../../redux/actions/notificationDataActions";
import linkIcon from "img/Dashboard/Notifications/link-solid.svg";
import {useNavigate} from "react-router-dom";
import { readNotifications } from "../../redux/actions/notifications";

interface CustomProps {
  notifications?: any,
}

function NotificationsList({notifications}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // handle Paginate
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      const skip = (pageNumber - 1) * 8;
      setCurrentPage(pageNumber);
      dispatch(takeNotificationData({
        limit: 8,
        skip: skip,
        newNotificationsLimit: 8,
        newNotificationsSkip: 0
      }));
    }
  };

  const markAsReadAndNavigate = (notification: any) => {
    readNotifications([notification._id]);
    navigate(
      `/overview/${notification.info.id}`,
      {state: { prevPath: location.pathname}});
  };

  return(
    <div>
      {notifications && notifications.total
        ?
        <>
          <div style={{overflowX: "auto", overflowY: "hidden"}}>
            {notifications.data.map((notification:any) => (
              <div key={notification._id} className="notifications_list_item">

                <div className="row">

                  {notification.new === "true"
                    ? <div className="unread_notification_dot" />
                    : null
                  }

                  <div className="notification_item_image_box">
                    <img src={invoiceIcon} alt="invoice"/>
                    <div style={{
                      position: "absolute",
                      zIndex: 10,
                      bottom: 5,
                      right: 5
                    }}>
                      <UserAvatar
                        imageUrl={notification.createdBy.image}
                        customSize={{
                          minWidth: "20px",
                          minHeight: "20px",
                          width: "20px",
                          height: "20px",
                        }}
                      />
                    </div>
                  </div>

                  <p className="notification_item_name">
                    {notification.createdBy.name}
                  </p>

                  <p className="notification_item_text">
                    {t(
                      "dashboard.notification.notifications_list.notification_items_texts.invoice")}
                  </p>

                  <img
                    className="notification_item_link"
                    src={linkIcon}
                    alt="link"
                    onClick={() => markAsReadAndNavigate(notification)}
                  />
                </div>

                <p className="notification_item_date">
                  {timeSince(new Date(notification.createdAt))}
                </p>

              </div>
            ))}
          </div>
          <Pagination
            currentPage={currentPage}
            postsPerPage={8}
            totalPosts={ notifications && notifications.total}
            paginate={paginate}
          />
        </>
        :
        notifications.total === 0
          ? <EmptyTableState />
          : <TableLoading />
      }
    </div>
  );
}
const mapStateToProps = (state:RootState) => {
  return {
    notifications: state.notificationData.notificationData
  };
};

export default connect(mapStateToProps, null)(NotificationsList);

