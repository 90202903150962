import React, {useEffect, useState} from "react";
import "../SimpleComponents/Modal.css";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import TableSortedHeadItem from "../Lists/TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import NoRecordsFound from "../Lists/NoRecordsList";
import MoonLoader from "react-spinners/MoonLoader";
import SearchBarLight from "../SimpleComponents/SearchBarLight";
import Button from "../Buttons/Button";
import { useTranslation } from "react-i18next";
import {addRole, getRoles} from "../../redux/actions/rolesActions";
import {getUsers, showUserLoading} from "../../redux/actions/usersActions";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

interface CustomProps {
    closeModal: () => void,
    employee?: any,
	roles?: any,
	updateState?: any,
	loading?: any
}

function PermissionsListModal(
  {closeModal, employee, roles, updateState, loading}:CustomProps) {
  const { t } = useTranslation();
	interface Lookup {
		[index: string]: string;
	}

	const dispatch = useDispatch();

	const [showCustomIdentifierColumn, setShowCustomIdentifierColumn] = useState(false);
	const [sortedData, setSortedData] = useState([]);

	// get employee data
	useEffect(() => {
	  dispatch(getUsers({
		  limit: 5,
		  skip: 0
	  }));
	  dispatch(getRoles());
	}, [dispatch]);

	// disable spinner after data Loaded
	useEffect(() => {
	  if (employee && employee.users) {
	    setSortedData(employee.users);
	  }
	}, [employee]);


	// Handle search bar
	const [searchValue, setSearchValue] = useState("");
	const handleSearch = (value:string) => {
	  setSearchValue(value);
	  dispatch(showUserLoading());
	  dispatch(getUsers({
		  limit: 5,
		  skip: 0,
		  search: value
	  }));
	};

	// set which sort is selected
	const [sortButton, setSortButton] = useState({
	  firstName: "up",
	  lastName: "up",
	  email: "up",
	  amount: "up"
	});

	// Sorting
	const [sortValue, setSortValue] = useState("");
	const sortingData = (name:string) => {
	  const sortButtonClone: Lookup = sortButton;
	  const sortValue = sortButtonClone[name] === "up" ? "down" : "up";
	  const postValue = `${sortValue === "up" ? "" : "-"}${name}`;
	  setSortValue(postValue);
	  dispatch(getUsers({
		  limit: 5,
		  skip: 0,
		  search: searchValue,
		  sortBy: postValue
	  }));
	  setSortButton({...sortButton, [name]: sortValue});
	};

	// handle Paginate
	const currentPosts = sortedData.slice(0, 5);
	const paginate = (pageNumber: number) => {
	  const skip = (pageNumber - 1) * 5;
	  dispatch(getUsers({
		  limit: 5,
		  skip,
		  search: searchValue,
		  sortBy: sortValue
	  }));
	  dispatch(getUsers({
		  limit: 5,
		  skip,
		  search: searchValue,
		  sortBy: sortValue
	  }));
	};

	useEffect(() => {
	  const newOptions:any = [];
	  if (roles && roles.length) {
	    roles.forEach((role:any) => {
	      newOptions.push(role.role);
	    });
	  }
	}, [roles]);

	// Get update messages
	useEffect(() => {
	  if (updateState.success === "add_role") {
	    dispatch(showUserLoading());
	    dispatch(getUsers({
	      limit: 5,
	      skip: 0
	    }));
	  } else if (updateState.error === "add_role") {
		  toast.warning(
			  <CustomToastContainer
				  message={t("warnings.password_change_error")}
				  status={2} />, {
				  autoClose: 5000,
			  });
	  }
	}, [updateState, dispatch]);

	// Add role to user
	const addRoleHandler = (userId:string) => {
	  const temporaryArr = {
	    companyId: getUserCompany() || "",
	    userId: userId,
	    type: 2,
	    role: "Viewer"
	  };
	  dispatch(addRole(temporaryArr));
	  closeModal();
	};

	// Check if custom identifier presented in list
	useEffect(() => {
	  if (currentPosts?.length > 0) {
	    const usersWithCustomIdentifier = currentPosts.filter((user:any) => user.customIdentifier);
	    if (usersWithCustomIdentifier?.length > 0) {
	      setShowCustomIdentifierColumn(true);
	    } else {
	      setShowCustomIdentifierColumn(false);
	    }
	  }
	}, [currentPosts]);

	return (
	  <div className="modal_container_big white">
	    <img
	      onClick={() => closeModal()}
	      className="close_popup_paddings"
	      src={closeIcon} alt="close"
	    />
	    <div className="search_box_permissions">
	      <SearchBarLight
	        searchPlaceholder=
	          {t("dashboard.employee.search_placeholder")}
	        handleSearchBar={handleSearch}
	      />
	    </div>
	    {loading
	      ?
	      <div className="center" style={{minHeight:"20vh"}}>
	        <MoonLoader />
	      </div>
	      :
	      <div>
	        {employee.users && employee.users.length === 0 ? (
	          <NoRecordsFound
	            text={t("warnings.dash_empty_table")}
	          />
	        ) : (
	          <>
	            <div style={{overflowX:"auto"}}>
	              <table className="full-width page-table">
	                <thead>
	                  <tr>
	                    <th className="pl-10">
	                      <div className="row">
	                        <TableSortedHeadItem
	                          sortingData={sortingData}
	                          text={t(
	                            "dashboard" +
															".home.employee_list_th_1"
	                          )}
	                          name="firstName"
	                          sortButton={sortButton}
	                        />
	                      </div>
	                    </th>
	                    <th>
	                      <TableSortedHeadItem
	                        sortingData={sortingData}
	                        text={t("dashboard.home" +
														".employee_list_th_2")}
	                        name="lastName"
	                        sortButton={sortButton}
	                      />
	                    </th>
	                    <th>
	                      <TableSortedHeadItem
	                        sortingData={sortingData}
	                        text={t("dashboard.home" +
														".employee_list_th_3")}
	                        name="email"
	                        sortButton={sortButton}
	                      />
	                    </th>
	                    {showCustomIdentifierColumn
	                      ? <th>
	                        <div className="responsive-mr-40">
	                          {t("dashboard.home.employee_list_th_custom_identifier")}
	                        </div>
	                      </th>
	                      : null
	                    }
	                    <th>
	                      {t("dashboard.settings" +
													".permissions.th_add")}
	                    </th>
	                  </tr>
	                </thead>
	                <tbody>
	                  {currentPosts.map((item:any, index:number) => (
	                    <React.Fragment key={item._id}>
	                      <tr className="spacer" />
	                      <tr className="table-item">
	                        <td>
	                          {item.firstName}
	                        </td>
	                        <td>
	                          {item.lastName}</td>
	                        <td>
	                          {item.email}
	                        </td>
	                        {showCustomIdentifierColumn
	                          ? <td>{item.customIdentifier}</td>
	                          : null
	                        }
	                        <td>
	                          {item.role
	                            ?
	                            <Button
	                              buttonStyles={{
	                                maxWidth: "50px"
	                              }}
	                              buttonType="primary"
	                              buttonLabel="+"
	                              disabled={true}
	                            />
	                            :
	                            <Button
	                              buttonStyles={{
	                                maxWidth: "50px"
	                              }}
	                              buttonType="primary"
	                              buttonLabel="+"
	                              buttonHandler={() =>
	                                addRoleHandler(
	                                  item._id
	                                )}
	                            />
	                          }
	                        </td>
	                      </tr>
	                    </React.Fragment>
	                  ))}
	                </tbody>
	              </table>
	            </div>
	            <Pagination
	              postsPerPage={5}
	              totalPosts={ employee && employee.totalUser }
	              paginate={paginate}
	            />
	          </>
	        )}
	      </div>
	    }
	  </div>
	);
}

const mapStateToProps = (state:RootState) => {
  return {
    employee: state.users.users,
    roles: state.roles.roles,
    updateState: state.roles,
    loading: state.users.loading
  };
};

export default connect(mapStateToProps, null)(PermissionsListModal);
