import {HANDLE_CHECKBOX} from "../types/employeePageCheckboxTypes";


export function handleEmployeeCheckBox(checkBoxesState:any[]) {
  const checkedItems = checkBoxesState.filter((item:any) => {
    return item.isClicked === true;
  });
  return {
    type: HANDLE_CHECKBOX,
    payload: checkedItems
  };
}
