import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./ConsumeWeavrInvite.css";
import queryString from "query-string";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {getToken, removeToken, setToken} from "../../utils/cookies/tokensCookie";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import {getUserInfo} from "../../redux/actions/usersActions";
import {logoutDispatcher} from "../../redux/actions/authActions";
import {removeUserPermission, removeUserPermissionType, removeUserRoles} from "../../utils/cookies/userRolesCookies";
import customHistory from "../../customHistory";
import {removeUserCompany, removeUserId} from "../../utils/cookies/userCookies/userCookies";

interface IProps {
  user?: any;
}

function ConsumeWeavrInvite(props: IProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [email, setEmail] = useState("");
  const [nonce, setNonce] = useState("");
  const [userId, setUserId] = useState("");
  const [refLogin, setRefLogin] = useState<any>(null);

  const parsedSearch:any = queryString.parse(location.search) || "";

  useEffect(() => {
    if (parsedSearch && parsedSearch.email && parsedSearch.nonce) {
      const token = getToken();
      if (!props.user && token) {
        dispatch(getUserInfo());
      } else if (props.user) {
        if (props.user.email !== parsedSearch.email) {
          // Logout and navigate
          removeToken();
          removeUserPermission();
          removeUserPermissionType();
          removeUserRoles();
          removeUserId();
          removeUserCompany();
          customHistory.push({
            pathname: "/login",
            search: `?path=/employeePaymentsVerification${location.search}`
          });
        } else {
          navigate(`/employeePaymentsVerification${location.search}`);
        }
      } else {
        setUserId(parsedSearch.user_id);
        setEmail(parsedSearch.email);
        setNonce(parsedSearch.nonce);
        navigate(`/login?path=/employeePaymentsVerification${location.search}`);
      }
    }
  }, [parsedSearch, props.user]);

  useEffect(() => {
    const createdForm = (window as any).OpcUxSecureClient.form();
    setRefLogin(createdForm);
    const input = createdForm.input(
      "p",
      "password",
      {
        placeholder: "Password",
        maxlength: 20
      }
    );
    input.mount(document.getElementById("password"));
  }, []);

  const createPasscodeForAuthorizedUser = async (passCodeToken: string) => {
    const token = getToken();
    await axios.post(
      `${environment.baseUrl}${urls.payments.createPasscodeForAuthorizedUser}`,
      {
        userId: userId,
        nonce: nonce,
        password: passCodeToken
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      }
    )
      .then((response) => {
        setToken(response.data.token, true);
        navigate("/");
      }, (error) => {
        console.error(error);
      });
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (refLogin) {
      refLogin.tokenize(function(tokens :any) {
        if (tokens && tokens.p) {
          createPasscodeForAuthorizedUser(tokens.p);
        }
      });
    }
  };

  return(
    <form onSubmit={onSubmit}>
      <div style={{display: "none"}}>
        Username: <input type="input" value={email} name="u" /><br/>
      </div>
      <div className="weavr_passcode_input_box">
        Passwort <div id="password"></div><br/>
      </div>
      <input
        className="weavr_passcode_btn"
        type="submit"
      />
    </form>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.userInfo.user
  };
};

export default connect(mapStateToProps, null)(ConsumeWeavrInvite);
