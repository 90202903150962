import React from "react";
import "./GeneralInfoSupermarketWarningModal.css";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import warningImage from "../../../img/Dashboard/PlansAndModules/plans_warning.svg";
import Button from "../../Buttons/Button";
import {useTranslation} from "react-i18next";

interface IProps {
  openPlans: number;
  onSubmit: () => void;
  onCancel: () => void;
}

export default function GeneralInfoSupermarketWarningModal(props:IProps) {
  const { t } = useTranslation();

  return(
    <div className="general_info_supermarket_warning_modal">
      <img
        onClick={() => props.onCancel()}
        style={{ top: "0px" }}
        className="close_popup_paddings"
        src={closeIcon}
        alt="close"
      />

      <img src={warningImage} alt="warning"/>

      <h2>
        {t("dashboard.general.warning_popup.title")}
      </h2>

      <p>
        {t("dashboard.general.warning_popup.text", {number: props.openPlans})}
      </p>

      <Button
        buttonType="primary"
        buttonLabel={t("dashboard.general.warning_popup.btn")}
        buttonHandler={() => props.onSubmit()}
      />

    </div>
  );
}
