import React from "react";
import "./styles.css";
import Button from "../../../../Components/Buttons/Button";
import image from "../../../../img/Dashboard/Card/weav_onboarding_info.svg";
import {useTranslation} from "react-i18next";
import DashboardContainer from "../../../../Components/Containers/DashboardContainer";

export default function PaymentsOnboardingPendingView() {
  const { t } = useTranslation();

  return(
    <DashboardContainer>
      <div className="card_onboarding_info_page_container">
        <div className="center">
          <div className="card_onboarding_info_page_card">
            <h1>{t("dashboard.payments.onboarding.pending.title")}</h1>
            <p>{t("dashboard.payments.onboarding.pending.text")}</p>
          </div>
        </div>
        <div className="center">
          <img src={image} alt="info"/>
        </div>
      </div>
    </DashboardContainer>
  );
}
