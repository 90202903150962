import Button from "Components/Buttons/Button";
import React from "react";
import "../payments.css";
import image from "img/Dashboard/Card/weav_onboarding_info.svg";
import {useTranslation} from "react-i18next";

interface IProps {
  handleSlider: () => void
}

export default function PaymentInfo({handleSlider}:IProps) {

  const { t } = useTranslation();

  return(
    <div className="card_onboarding_info_page_container">
      <div className="center">
        <div className="card_onboarding_info_page_card">
          <h1>{t("dashboard.payments.onboarding.stepper.info.title")}</h1>
          <p>{t("dashboard.payments.onboarding.stepper.info.text")}</p>
          <Button
            buttonType="primary"
            buttonLabel={t("dashboard.payments.onboarding.stepper.info.btn")}
            buttonHandler={() => handleSlider()}
          />
        </div>
      </div>
      <div className="center">
        <img src={image} alt="info"/>
      </div>
    </div>
  );
}
