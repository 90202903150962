import environment from "../../../environment";
import {urls} from "../../urls";
import axios from "axios";
import qs from "qs";
import {getToken} from "../../../utils/cookies/tokensCookie";
import {IGetInvoicesTopCardsDataResponse, IGetNearestInvoicesResponse} from "./invoices.interface";

export class invoicesService {
  constructor() {}

  async getInvoicesTopCardsData(): Promise<IGetInvoicesTopCardsDataResponse> {
    const fullUrl = `${environment.baseUrl}${urls.invoices.getAnalytics}`;
    const userToken = await getToken();
    return await axios.get(
      fullUrl,
      {
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

  async getNearestInvoices(invoiceId: string): Promise<IGetNearestInvoicesResponse> {
    const fullUrl = `${environment.baseUrl}${urls.invoices.getNearest}`;
    const userToken = await getToken();
    return await axios.get(
      fullUrl,
      {
        params: {invoiceId},
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

}
