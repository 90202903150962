import React from "react";

interface SpacerProps {
    spacerHeight?: string,
    spacerWidth?: string,
}

const Spacer = ({spacerHeight, spacerWidth }:SpacerProps) => {
  return(
    <div style={{
      height: spacerHeight,
      width: spacerWidth,
    }} />
  );
};

export default Spacer;
