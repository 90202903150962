import React, {useEffect, useState} from "react";
import "./MultipleUserInvitationModal.css";
import closeIcon from "../../../../img/Dashboard/Employee/close_btn.svg";
import {useTranslation} from "react-i18next";
import Button from "../../../Buttons/Button";
import axios from "axios";
import environment from "../../../../environment";
import {urls} from "../../../../api/urls";
import {errorHandler} from "../../../../utils/errorHandler";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../SimpleComponents/ToastContainer";

interface IProps {
  users: any[];
  closeModal: () => void;
}

export default function MultipleUserInvitationModal(props:IProps) {
  const { t } = useTranslation();
  const [allowedForInvitationEmails, setAllowedForInvitationEmails] = useState<string[]>([]);
  const [buttonLoading, setButtonLoading] = useState(false);

  useEffect(() => {
    // Filter only users that are not activated
    if (props.users?.length > 0) {
      const inactiveUsers = props.users.filter((user:any) => !user.isActivated);
      const inactiveUsersEmails = inactiveUsers.map((user: any) => {
        return user.email;
      });
      setAllowedForInvitationEmails(inactiveUsersEmails);
    }
  }, [props.users]);

  const sendInvitations = async () => {
    setButtonLoading(true);
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.general.inviteMultipleUser}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    await axios.post(
      fullUrl,
      {emails: allowedForInvitationEmails},
      options)
      .then((res: any) => {
        toast.success(
          <CustomToastContainer
            message={t("warnings.invite_multiple_employee", {count: allowedForInvitationEmails.length})}
            status={1} />, {
            autoClose: 5000,
            toastId: "inviteMultiple"
          });
      })
      .catch((e: any) => {
        if (!errorHandler(e.request.status)) {
          toast.error(
            <CustomToastContainer
              message={"Something went wrong"}
              status={3}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      }).finally(() => {
        setButtonLoading(false);
        props.closeModal();
      });
  };

  return(
    <div className="modal_container">
      <img
        onClick={() => props.closeModal()}
        className="close_popup"
        src={closeIcon} alt="close"
      />

      <div style={{height: "30px"}} />

      <h4 className="text-center multiple_invitation_modal_title">
        {t("dashboard.employee.invite_users_modal.title")}
      </h4>
      <p className="text-center multiple_invitation_modal_text">
        {t("dashboard.employee.invite_users_modal.text", {count: allowedForInvitationEmails.length})}
      </p>

      <div style={{height: "16px"}} />

      <p>
        {t("dashboard.employee.invite_users_modal.selected_employee_title")}
      </p>

      <div className="selection_grid">
        {allowedForInvitationEmails.map((employeeEmail: string) => (
          <p
            key={employeeEmail}
            className={"selection_grid_mail"}
          >
            {employeeEmail}
          </p>
        ))}
      </div>

      <div className="center" style={{marginTop: "16px"}}>
        <Button
          buttonType={"primary"}
          buttonLabel={t("dashboard.employee.invite_users_modal.submit_btn")}
          loading={buttonLoading}
          disabled={buttonLoading}
          buttonHandler={() => {
            sendInvitations();
          }}
        />
      </div>

    </div>
  );
}
