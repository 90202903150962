import React, {useEffect, useState} from "react";
import "./depAndBranchContainer.css";
import {useTranslation} from "react-i18next";
import {useFormik} from "formik";
import * as Yup from "yup";
import { getRegions } from "../../../redux/actions/api";
import DepAndBranchItem from "./depAndBranchItem";
import Button from "../../Buttons/Button";

interface CustomProps {
  setBranchAndDeps: any,
  update?: boolean,
  submitClick?: () => void,
  setDepartmentsValidation: (state:boolean) => void,
  closeAddDepsAndBranches?: () => void;
  countryCode: string;
}

export default function DepAndBranchContainer({
  setBranchAndDeps,
  update,
  submitClick,
  setDepartmentsValidation,
  closeAddDepsAndBranches,
  countryCode
}:CustomProps) {
  const { t } = useTranslation();

  const [regionValues, setRegionValues] = useState([]);

  // Get regions for select options after page is mounted
  useEffect(() => {
    getRegions(countryCode.toLowerCase()).then((res:any) => {
      setRegionValues(res);
    });
  }, [countryCode]);

  const formik = useFormik({
    initialValues: {
      companyId: "",
      depsAndBranches: [{
        branchName: "",
        departmentNames: [""],
        streetName: "",
        city: "",
        zipCode: "",
        country: "",
        region: "",
        costCenter: "50"
      }]
    },
    validationSchema: Yup.object({
      depsAndBranches: Yup.array().of(
        Yup.object({
          branchName: Yup.string().required(t("formik_warnings.general_info.branchName")),
          departmentNames: Yup.array().of(
            Yup.string().required(t("formik_warnings.general_info.departmentNames"))
          ),
          streetName: Yup.string().required(t("formik_warnings.general_info.streetName")),
          city: Yup.string().required(t("formik_warnings.general_info.city")),
          zipCode: Yup.string().required(t("formik_warnings.general_info.zipCode")),
          country: Yup.string().required(t("formik_warnings.general_info.country")),
          region: Yup.string().required(t("formik_warnings.general_info.region"))
        })
      ).required(t("formik_warnings.general_info.depsAndBranches"))
    }),
    // handle form submitting
    onSubmit: () => {
      //
    },
  });

  // Add new branch
  const addBranchAndDep = () => {
    formik.setFieldValue("depsAndBranches", [...formik.values.depsAndBranches, {
      branchName: "",
      departmentNames: [""],
      streetName: "",
      city: "",
      zipCode: "",
      country: "",
      region: "",
      costCenter: "50"
    }]);
  };

  // Save formik values to parent component
  useEffect(() => {
    setBranchAndDeps(formik.values);
    setDepartmentsValidation(!(formik.isValid && formik.dirty));
  }, [formik.values, formik.errors]);

  // Remove branch and Department
  const removeBranchAndDep = (index:number) => {
    if (formik.values.depsAndBranches.length > 1) {
      formik.setFieldValue(
        "depsAndBranches",
        formik.values.depsAndBranches.filter((item:any, i:number) => i !== index));
    } else if (formik.values.depsAndBranches.length == 1 && update && closeAddDepsAndBranches) {
      formik.setFieldValue(
        "depsAndBranches",
        formik.values.depsAndBranches.filter((item:any, i:number) => i !== index));
      closeAddDepsAndBranches();
    }
  };

  // Add new department
  const addNewDepartment = (i:number) => {
    const newDepartments = [...formik.values.depsAndBranches[i].departmentNames];
    newDepartments.push("");
    formik.setFieldValue(`depsAndBranches[${i}].departmentNames`, newDepartments);
  };

  // Delete department
  const deleteDepartment = (branchIdx:number, departmentIdx:number) => {
    const newDepartments = [...formik.values.depsAndBranches[branchIdx].departmentNames];
    if (newDepartments.length > 1) {
      newDepartments.splice(departmentIdx, 1);
      formik.setFieldValue(`depsAndBranches[${branchIdx}].departmentNames`, newDepartments);
    }
  };

  return (
    <div className="dep_branch_container">
      <div className="row">
        <p>{t("dashboard.general.dep_branch.title")}</p>
      </div>
      {formik.values.depsAndBranches.map((depsAndBranch: any, i:number) => (
        <div
          className="branch_dev_box"
          key={i.toString()}
        >
          <div className="flex-end">
          </div>
          <DepAndBranchItem
            regionValues={regionValues}
            formik={formik}
            i={i}
            addNewBranch={addBranchAndDep}
            removeBranch={removeBranchAndDep}
            addNewDepartment={addNewDepartment}
            deleteDepartment={deleteDepartment}
          />
        </div>
      ))}
      {update
        ?
        <div className="flex-end">
          <Button
            buttonType="primary"
            buttonLabel={t("dashboard.general.dep_branch.add_new_deps_btn")}
            buttonHandler={submitClick}
            disabled={!(formik.isValid && formik.dirty)}
          />
        </div>
        : null
      }
    </div>
  );
}
