import "./index.css";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import BannerTitle from "Components/SimpleComponents/BannerWithTitle";
import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import Spacer from "Components/SimpleComponents/Spacer";
import VerticalDivider from "Components/Containers/VerticalDivider";
import FormikInput from "../../../Components/SimpleComponents/FormikInput";
import useWindowDimensions from "utils/useWindowSize";
import {handleSidebar} from "../../../redux/actions/sidebarActions";
import {partnerPortalService} from "../../../api/services/Partner-portal/partner-portal.service";
import validatePhoneNumber from "../../../utils/phoneNumberValidation";
import {
  IUpdatePartnerPortalProfileRequestData
} from "../../../api/services/Partner-portal/partner-portal.interface";
import SelectWithChoices from "../../../Components/SimpleComponents/SelectWithChoices";
import Button from "../../../Components/Buttons/Button";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";

const labelStyles = {
  paddingBottom: "5px",
  paddingLeft: "7px",
  fontSize: "12px"
};

export default function PartnerPortalProfilePage() {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const countryOptions: any = [
    {
      label: t("dashboard.general_info.countries_options.at"),
      value: "AT"
    },
    {
      label: t("dashboard.general_info.countries_options.de"),
      value: "DE"
    }
  ];

  const [buttonLoading, setButtonLoading] = useState(false);

  const formik = useFormik<IUpdatePartnerPortalProfileRequestData>({
    initialValues: {
      firstname: "",
      lastname: "",
      email: "",
      phone: "",
      street: "",
      city: "",
      postcode: "",
      country: "",
    },
    validationSchema: Yup.object({
      firstname: Yup.string().required(t("formik_warnings.required", {field: t("dashboard.general.input_item_1")})),
      lastname: Yup.string().required(t("formik_warnings.required", {field: t("dashboard.general.input_item_2")})),
      email: Yup.string().required(t("formik_warnings.required", {field: t("dashboard.general.input_item_3")})),
      phone: Yup.string()
        .test("test-name", "Error", function(value:any) {
          if (value && value.length > 0) {
            const { path, createError } = this;
            const phoneValidation = validatePhoneNumber(value);
            if (phoneValidation) {
              return true;
            } else {
              return createError({ path, message: t("formik_warnings.general_info.phoneNumber_validation") });
            }
          } else {
            return true;
          }
        }),
      street: Yup.string(),
      city: Yup.string(),
      postcode: Yup.string(),
      country: Yup.string()
    }),
    // handle form submitting
    onSubmit: async () => {
      setButtonLoading(true);
      const response = await new partnerPortalService().updateProfile(formik.values);
      if (response && response.status) {
        // Show success notification
        toast.success(
          <CustomToastContainer
            message={t("dashboard.general.partner_portal_profile_success_update")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      }
      setButtonLoading(false);
    },
  });

  const getData = async () => {
    const response = await new partnerPortalService().getProfile();
    if (response.status && response.data) {
      formik.setValues({
        firstname: response.data.firstname,
        lastname: response.data.lastname,
        email: response.data.email,
        phone: response.data.phone,
        street: response.data.street,
        city: response.data.city,
        postcode: response.data.postcode,
        country: response.data.country
      });
    }
  };

  useEffect(() => {
    getData();
  }, []);

  // set less item in table for small screens
  const [mainSpaceRatio, setMainSpaceRation] = useState("60% 10% 30%");
  const [contactRatio, setContactRation] = useState("1fr 1fr 1fr");
  const [titleRatio, setTitleRatio] = useState("33% 33% 33%");
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 915) {
      setMainSpaceRation("1fr");
    }
    if (width < 650) {
      setContactRation("1fr");
      setTitleRatio("1fr");
    }
    if (width < 1000) {
      dispatch(handleSidebar(false));
    } else {
      setMainSpaceRation("60% 10% 30%");
      setContactRation("1fr 1fr 1fr");
      setTitleRatio("33% 33% 33%");
    }
  }, [width]);

  return (
    <ContentContainer>
      <BannerTitle
        mainTitle={t("dashboard.general.partner_portal_profile_title")}
      />
      <MainSpace>
        <VerticalDivider ratio={mainSpaceRatio}>
          <form onSubmit={formik.handleSubmit}>
            <VerticalDivider ratio={contactRatio}>
              <div className="relative">
                <FormikInput
                  style={{ marginRight: "10px" }}
                  htmlFor="firstname"
                  name="firstname"
                  value={formik.values.firstname}
                  disabled={false}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t("dashboard.general.input_item_1")}
                />
                {formik.errors.firstname &&
                  formik.touched.firstname && (
                  <p className="input_warning">
                    {formik.errors.firstname.toString()}
                  </p>
                )}
              </div>
              <div className="relative">
                <FormikInput
                  style={{ marginRight: "10px" }}
                  htmlFor="lastname"
                  name="lastname"
                  value={formik.values.lastname}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.general.input_item_2")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.lastname &&
                  formik.touched.lastname && (
                  <p className="input_warning">
                    {formik.errors.lastname.toString()}
                  </p>
                )}
              </div>
            </VerticalDivider>
            <div
              className="space-between"
              style={{ width: "100%", gap: "20px" }}
            >
              <div className="relative" style={{ width: "100%" }}>
                <FormikInput
                  htmlFor="email"
                  name="email"
                  value={formik.values.email}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.general.input_item_3")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.email &&
                  formik.touched.email && (
                  <p className="input_warning">
                    {formik.errors.email.toString()}
                  </p>
                )}
              </div>

              <div className="relative" style={{ width: "100%" }}>
                <FormikInput
                  htmlFor="phone"
                  name="phone"
                  value={formik.values.phone}
                  disabled={false}
                  handleChange={(event: any) => {
                    // Remove all spaces from string and set to formik, to run correct validation
                    formik.setFieldValue(
                      "phone",
                      event.target.value.replace(/ /g, ""),
                      true
                    );
                  }}
                  label={t("dashboard.general.input_item_phone")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.phone &&
                  formik.touched.phone && (
                  <p className="input_warning">
                    {formik.errors.phone.toString()}
                  </p>
                )}
              </div>
            </div>
            <div className="relative">
              <FormikInput
                htmlFor="street"
                name="street"
                value={formik.values.street}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.general.input_item_5")}
              />
              {formik.errors.street &&
                formik.touched.street && (
                <p className="input_warning">
                  {formik.errors.street.toString()}
                </p>
              )}
            </div>
            <VerticalDivider ratio={titleRatio}>
              <div className="relative">
                <FormikInput
                  style={{ marginRight: "10px" }}
                  htmlFor="city"
                  name="city"
                  value={formik.values.city}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.general.input_item_6")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.city &&
                  formik.touched.city && (
                  <p className="input_warning">
                    {formik.errors.city.toString()}
                  </p>
                )}
              </div>
              <div className="relative">
                <FormikInput
                  htmlFor="postcode"
                  name="postcode"
                  style={{ marginRight: "10px" }}
                  value={formik.values.postcode}
                  disabled={false}
                  handleChange={formik.handleChange}
                  label={t("dashboard.general.input_item_7")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.postcode &&
                  formik.touched.postcode && (
                  <p className="input_warning">
                    {formik.errors.postcode.toString()}
                  </p>
                )}
              </div>
              <div style={{ width: "100%" }}>
                <p className="filter_label" style={labelStyles}>
                  {t("dashboard.employee.input_item_country")}
                </p>

                <div className="relative">
                  <SelectWithChoices
                    style={{ width: "100%", paddingRight: "10px", height: "42px", minHeight: "42px"}}
                    isMulti="no"
                    name="country"
                    onBlur={formik.handleBlur("country")}
                    selectPlaceHolder={t(
                      "dashboard.employee.input_item_country"
                    )}
                    selectId="country"
                    options={countryOptions}
                    handleAddChoice={(value: any) => {
                      formik.setFieldValue("country", value.value, true);
                    }}
                  />
                  {formik.errors.country &&
                    formik.touched.country && (
                    <p className="input_warning">
                      {formik.errors.country.toString()}
                    </p>
                  )}
                </div>
              </div>
            </VerticalDivider>
            <Button
              disabled={!(formik.isValid && formik.dirty)}
              buttonType="primary"
              buttonHtmlType="submit"
              buttonLabel={t("dashboard.general.button_1")}
              submitButton={true}
              fullWidth={true}
              loading={buttonLoading}
              buttonStyles={{maxWidth: "240px"}}
            />
          </form>
          <div />
        </VerticalDivider>
        <Spacer spacerHeight="120px" />
      </MainSpace>
    </ContentContainer>
  );
}
