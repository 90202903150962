// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Overpass+Mono);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
	margin-top: 20px;
	width: 356px;
	height: 234px;
	border-radius: 15px;
	position: relative;
}

.card_amount {
	font-size: 18px;
	color: #ffffff;
	font-weight: bold;
	position: absolute;
	right: 20px;
	top: 40px;
}

.card_number {
	font-family: "Overpass Mono", monospaced;
	color: #FFFFFF;
	position: absolute;
	font-size: 12px;
	bottom: 60px;
	left: 20px;
	min-width: 180px;
}

.card_expiry {
	font-family: "Overpass Mono", monospaced;
	color: #FFFFFF;
	position: absolute;
	font-size: 12px;
	bottom: 20px;
	left: 20px;
	width: 40px;
}

.card_cvv {
	font-family: "Overpass Mono", monospaced;
	color: #FFFFFF;
	position: absolute;
	font-size: 12px;
	bottom: 50px;
	right: 20px;
	width: 40px;
	text-align: right;
}

.card_type {
	font-family: "Overpass Mono", monospaced;
	color: #a9a9a9;
	position: absolute;
	font-size: 12px;
	top: 20px;
	right: 20px;
	text-transform: uppercase;
	display: flex
}

.card_icon {
	width: 15px;
	margin-right: 8px
}`, "",{"version":3,"sources":["webpack://./src/Components/Payments/CreditCard/CreditCard.css"],"names":[],"mappings":"AAGA;CACC,gBAAgB;CAChB,YAAY;CACZ,aAAa;CACb,mBAAmB;CACnB,kBAAkB;AACnB;;AAEA;CACC,eAAe;CACf,cAAc;CACd,iBAAiB;CACjB,kBAAkB;CAClB,WAAW;CACX,SAAS;AACV;;AAEA;CACC,wCAAwC;CACxC,cAAc;CACd,kBAAkB;CAClB,eAAe;CACf,YAAY;CACZ,UAAU;CACV,gBAAgB;AACjB;;AAEA;CACC,wCAAwC;CACxC,cAAc;CACd,kBAAkB;CAClB,eAAe;CACf,YAAY;CACZ,UAAU;CACV,WAAW;AACZ;;AAEA;CACC,wCAAwC;CACxC,cAAc;CACd,kBAAkB;CAClB,eAAe;CACf,YAAY;CACZ,WAAW;CACX,WAAW;CACX,iBAAiB;AAClB;;AAEA;CACC,wCAAwC;CACxC,cAAc;CACd,kBAAkB;CAClB,eAAe;CACf,SAAS;CACT,WAAW;CACX,yBAAyB;CACzB;AACD;;AAEA;CACC,WAAW;CACX;AACD","sourcesContent":["@import url('https://fonts.googleapis.com/css?family=Overpass+Mono');\n@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,500;1,600&display=swap');\n\n.card {\n\tmargin-top: 20px;\n\twidth: 356px;\n\theight: 234px;\n\tborder-radius: 15px;\n\tposition: relative;\n}\n\n.card_amount {\n\tfont-size: 18px;\n\tcolor: #ffffff;\n\tfont-weight: bold;\n\tposition: absolute;\n\tright: 20px;\n\ttop: 40px;\n}\n\n.card_number {\n\tfont-family: \"Overpass Mono\", monospaced;\n\tcolor: #FFFFFF;\n\tposition: absolute;\n\tfont-size: 12px;\n\tbottom: 60px;\n\tleft: 20px;\n\tmin-width: 180px;\n}\n\n.card_expiry {\n\tfont-family: \"Overpass Mono\", monospaced;\n\tcolor: #FFFFFF;\n\tposition: absolute;\n\tfont-size: 12px;\n\tbottom: 20px;\n\tleft: 20px;\n\twidth: 40px;\n}\n\n.card_cvv {\n\tfont-family: \"Overpass Mono\", monospaced;\n\tcolor: #FFFFFF;\n\tposition: absolute;\n\tfont-size: 12px;\n\tbottom: 50px;\n\tright: 20px;\n\twidth: 40px;\n\ttext-align: right;\n}\n\n.card_type {\n\tfont-family: \"Overpass Mono\", monospaced;\n\tcolor: #a9a9a9;\n\tposition: absolute;\n\tfont-size: 12px;\n\ttop: 20px;\n\tright: 20px;\n\ttext-transform: uppercase;\n\tdisplay: flex\n}\n\n.card_icon {\n\twidth: 15px;\n\tmargin-right: 8px\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
