import React from "react";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import {useTranslation} from "react-i18next";
import FormikInput from "../../SimpleComponents/FormikInput";
import Button from "../../Buttons/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import "./RejectInvoiceModal.css";

interface IRejectInvoiceModal {
  onClose: () => void;
  onSubmit: (comment?: string) => void;
}

const maxAllowedCharacters = 100;

export default function RejectInvoiceModal(props: IRejectInvoiceModal) {
  const { t } = useTranslation();

  const formik = useFormik({
    initialValues: {
      comment: ""
    },
    validationSchema: Yup.object({
      comment: Yup.string().max(maxAllowedCharacters, t("formik_warnings.max", {count: maxAllowedCharacters}))
    }),
    // handle form submitting
    onSubmit: async (event: any, ev: any) => {
      //
    },
  });

  const handleSubmit = (data: {withComment: boolean}) => {
    props.onSubmit(data.withComment ? formik.values.comment : undefined);
  };

  return(
    <div className="modal_container">
      <img
        onClick={props.onClose}
        className="close_popup"
        src={closeIcon}
        alt="close"
      />
      <h2>{t("components.modals.reject_invoice.title")}</h2>

      <FormikInput
        htmlFor={"text"}
        name={"comment"}
        value={formik.values.comment}
        disabled={false}
        handleChange={formik.handleChange}
        label={t("components.modals.reject_invoice.comment_label")}
        type={"textarea"}
        inputStyle={{
          maxWidth: "none"
        }}
        errors={formik.errors.comment}
        touched={formik.touched.comment}
        onBlur={formik.handleBlur}
        maxLength={maxAllowedCharacters}
      />

      <p className={"character-counter"}>
        {t("components.modals.reject_invoice.counter", {count: maxAllowedCharacters - formik.values.comment.length})}
      </p>

      <div
        className={"space-between"}
        style={{
          marginTop: "30px",
          gap: "20px"
        }}
      >
        <Button
          buttonType={"primary"}
          buttonLabel={t("components.modals.reject_invoice.submit_btn")}
          buttonHandler={() => handleSubmit({withComment: true})}
          buttonHtmlType={"submit"}
          buttonStyles={{
            maxWidth: "none"
          }}
          disabled={!(formik.isValid && formik.dirty)}
        />

        <Button
          buttonType={"outlined"}
          buttonLabel={t("components.modals.reject_invoice.submit_without_comment_btn")}
          buttonHandler={() => handleSubmit({withComment: false})}
          buttonHtmlType={"submit"}
          buttonStyles={{
            maxWidth: "none"
          }}
        />

      </div>

    </div>
  );
}
