import {
  ADD_ROLE, CLEAR_ROLE_MESSAGES,
  GET_ROLES_SUCCESS, GET_USERS_WITH_ROLES_SUCCESS,
  ROLES_ERROR,
  rolesDispatchTypes, UPDATE_ROLE
} from "../types/rolesTypes";

interface DefaultStateI {
	success?: string
	error?: string,
	roles?: any,
	usersWithRoles?: any
}

const initialState:DefaultStateI = {
  success: "",
  error: "",
  roles: [],
  usersWithRoles: []
};

export const rolesReducer = (
  state: DefaultStateI = initialState,
  action: rolesDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case GET_ROLES_SUCCESS:
    return {
      ...state,
      roles: action.payload
    };
  case GET_USERS_WITH_ROLES_SUCCESS:
    return {
      ...state,
      usersWithRoles: action.payload
    };
  case ADD_ROLE:
    return {
      ...state,
      success: action.payload
    };
  case UPDATE_ROLE:
    return {
      ...state,
      success: action.payload
    };
  case CLEAR_ROLE_MESSAGES:
    return {
      ...state,
      success: "",
      error: ""
    };
  case ROLES_ERROR:
    return {
      ...state,
      error: action.payload
    };
  default:
    return state;
  }
};
