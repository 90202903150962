import React, {useEffect, useState} from "react";
import Avatar from "../../img/Dashboard/permission_avatar.svg";
import { prepareImage } from "../../redux/actions/prepareImage";
import {motion} from "framer-motion";

interface CustomProps {
	userImg: string
}

export default function RolesListAvatar({userImg}:CustomProps) {

  // Image sign handler
  const [currentImage, setCurrentImage] = useState("");
  const [isImageExist, setIsImageExist] = useState(false);

  useEffect(() => {
    async function fetchData() {
      if (userImg && userImg !== "undefined") {
        try {
          const response = await prepareImage(userImg);
          setCurrentImage(response);
          setIsImageExist(true);
        } catch (e: any) {
          setCurrentImage("/images/user_avatar.svg");
          setIsImageExist(false);
        }
      } else {
        setIsImageExist(false);
      }
    }
    fetchData();
  }, [userImg]);

  return(
    <motion.div
      animate={{ opacity: [0, 1] }}
      transition={{duration:0.3}}
      className="permission_avatar"
      style={isImageExist
        ? {backgroundImage: `url(${currentImage})`}
        : {backgroundImage: `url(${Avatar})`}
      }
    />
  );
}
