// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h2 {
    margin-top: 11px;
    margin-bottom: 0.5rem;
    font-size: 18px;
    font-weight: 700;
}
.page-header > p {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/PageTitle.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,qBAAqB;IACrB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":["h2 {\n    margin-top: 11px;\n    margin-bottom: 0.5rem;\n    font-size: 18px;\n    font-weight: 700;\n}\n.page-header > p {\n    font-size: 12px;\n    font-weight: 300;\n    margin-bottom: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
