export const CHANGE_STEP = "CHANGE_STEP";
export const CHANGE_VALUES = "CHANGE_VALUES";
export const CHANGE_LOCAL_VALUES = "CHANGE_LOCAL_VALUES";
export const CHANGE_INTERN_VALUES = "CHANGE_INTERN_VALUES";
export const CHANGE_CORPORATE_VALUES = "CHANGE_CORPORATE_VALUES";
export const SEND_VALUES = "SEND_VALUES";
export const GET_VALUES = "GET_VALUES";
export const UPDATE_VALUES = "UPDATE_VALUES";
export const CLEAR_VALUES_MESSAGES = "CLEAR_VALUES_MESSAGES";
export const VALUES_ERROR = "VALUES_ERROR";

interface changeStepAction {
	type: typeof CHANGE_STEP
	payload: number
}

interface changeValuesAction {
	type: typeof CHANGE_VALUES
	payload: any
}

interface changeLocalValuesAction {
	type: typeof CHANGE_LOCAL_VALUES
	payload: any
}

interface changeInternValuesAction {
	type: typeof CHANGE_INTERN_VALUES
	payload: any
}

interface changeCorporateValuesAction {
	type: typeof CHANGE_CORPORATE_VALUES
	payload: any
}


interface sendValuesAction {
	type: typeof SEND_VALUES
	payload: string
}

interface getValuesAction {
	type: typeof GET_VALUES
	payload: string
}

interface updateValuesAction {
	type: typeof UPDATE_VALUES
	payload: string
}

interface clearValuesMessagesAction {
	type: typeof CLEAR_VALUES_MESSAGES
}

interface valuesErrorAction {
	type: typeof VALUES_ERROR
	payload: string
}

export type stepsActionTypes =
	changeStepAction |
	changeValuesAction |
	changeLocalValuesAction |
	changeInternValuesAction |
	changeCorporateValuesAction |
	sendValuesAction |
	getValuesAction |
	updateValuesAction |
	clearValuesMessagesAction |
	valuesErrorAction
