import { ICustomProps } from "Interfaces/ICustomProps";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import InvoiceDetail from "./detail";
import Overview from "./page";
import { changeSidebarTab } from "../../redux/actions/sidebarActions";

export default function OverviewRoute({ user }: ICustomProps) {
  const dispatch = useDispatch();

  return (
    <Routes test-id="overview_router">
      <Route path="/" element={<Overview userId={user} />} />
      <Route path="/:invoiceId" element={<InvoiceDetail />} />
    </Routes>
  );
}
