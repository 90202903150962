// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `h3 {
    font-size: 14px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: 12px;
}

@media (max-width: 600px) {
    h3 {
        font-size: 14px;
        line-height: 20px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/SubTitle.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;AACvB;;AAEA;IACI;QACI,eAAe;QACf,iBAAiB;IACrB;AACJ","sourcesContent":["h3 {\n    font-size: 14px;\n    font-weight: 600;\n    line-height: 40px;\n    margin-bottom: 12px;\n}\n\n@media (max-width: 600px) {\n    h3 {\n        font-size: 14px;\n        line-height: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
