import {
  GET_WEAVE_CORPORATE,
  SHOW_WEAVR_ERROR,
  SHOW_WEAVR_LOADING,
  weavrTypes
} from "../types/weavrTypes";

interface DefaultStateI {
  loading: boolean;
  error: string;
  weavrCorporate: any;
}

const initialState:DefaultStateI = {
  loading: true,
  error: "",
  weavrCorporate: {}
};

export const weavrReducer = (
  state: DefaultStateI = initialState,
  action: weavrTypes):DefaultStateI => {
  switch (action.type) {
  case GET_WEAVE_CORPORATE:
    return {
      ...state, weavrCorporate: action.payload,
      loading: false
    };
  case SHOW_WEAVR_LOADING:
    return {
      ...state,
      loading: true
    };
  case SHOW_WEAVR_ERROR:
    return {
      ...state,
      error: action.payload
    };
  default:
    return state;
  }
};
