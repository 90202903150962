import React, { useEffect, useState } from "react";
import "./Sidebar.css";
import { useNavigate } from "react-router-dom";
import { connect, useDispatch } from "react-redux";
import { changeSidebarTab } from "redux/actions/sidebarActions";
import { RootState } from "redux/reducers/rootReducer";
import { changeSelectedRows } from "../../redux/actions/selectedUsersFromListActions";
import { handleEmployeeCheckBox } from "../../redux/actions/employeePageCheckboxActions";
import { useTranslation } from "react-i18next";
import {
  getUserPermissionType,
  getUserRoles
} from "../../utils/cookies/userRolesCookies";
import { HrZoneAuthInterface, SideBarItemsClass } from "./SideBarItemsClass";
import { getUserId } from "utils/cookies/userCookies/userCookies";
import packageJson from "../../../package.json";

interface CustomProps {
  showResponsiveSidebar: boolean;
  sidebarTab: string;
  changeSidebarTab: (sidebarTabCondition: string) => void;
  company?: any;
  user?: any,
  hrZoneAuth:  HrZoneAuthInterface
}

function Sidebar({
  showResponsiveSidebar,
  sidebarTab,
  company, user, hrZoneAuth
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // set tab style on click and change a path
  const handleSidebarChanges = (page: string) => {
    // Clear checked rows state after changing page
    dispatch(changeSelectedRows([]));
    dispatch(handleEmployeeCheckBox([]));
    // changeSidebarTab(page);
    page !== "dashboard" ? navigate(`/${page}`) : navigate("/");
  };

  useEffect(() => {
    const userRole = getUserRoles();
    if (userRole === "4") {
      setAllowedTabs(["5"]);
    }
    const permissions = getUserPermissionType();
    if (permissions?.length) {
      const temporaryArr: any = [];
      permissions.split("").forEach((item: any) => {
        temporaryArr.push((+item + 1).toString());
      });
      setAllowedTabs(temporaryArr);
    }
  }, []);

  const [allowedTabs, setAllowedTabs] = useState([
    "1",
    "2",
    "3",
    "4",
    "5",
    "6",
    "7",
    "8"
  ]);


  const Sidebar = new SideBarItemsClass({
    allowedTabs: allowedTabs,
    company: company,
    showResponsiveSidebar: showResponsiveSidebar,
    sidebarTab: sidebarTab,
    handleSidebarChanges: handleSidebarChanges,
    modules: company?.modules || false,
    hrZoneAuth: hrZoneAuth,
    user: user
  });

  const magentoUser = getUserId({withSplit: true});

  return (
    <div
      className={showResponsiveSidebar ? "sidebar_container" : "hide_sidebar"}
	  style={{overflowY: "scroll"}}
    >
      {(company?.modules?.length || (magentoUser && magentoUser[0] === "magento_user")) &&
        <ul>{Sidebar.generateAllSideBarItems()}</ul>
      }
      <p
        style={{
          position: "absolute",
          color: "#949090",
          bottom: 0,
          fontSize: "10px",
        }}
      >
        {`@${packageJson.version}`}
      </p>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    sidebarTab: state.sidebar.sidebarTabCondition,
    company: state.company.company.company,
    user: state.users?.userInfo.user,
    hrZoneAuth: state.hrZone
  };
};
const mapDispatchToProps = {
  changeSidebarTab
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
