import React from "react";
import "./Badge.css";

export enum BadgeColorsEnum {
  blue = "blue_badge",
  yellow = "yellow_badge",
  green = "accept_badge",
  red = "reject_badge"
}

export type IBadgeColorType = BadgeColorsEnum

export interface IBadge {
  color: IBadgeColorType;
  text: string;
  style?: React.CSSProperties;
}

export default function Badge(props: IBadge) {
  return (
    <div className={`badge ${props.color}`} style={props.style}>
      <p>{props.text}</p>
    </div>
  );
}
