import React, {useEffect, useRef, useState} from "react";
import "./styles.css";
import penIcon from "../../../img/Dashboard/PlansAndModules/Billing/pen.png";
import axios from "axios";
import {errorHandler} from "../../../utils/errorHandler";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import {getToken} from "../../../utils/cookies/tokensCookie";
import warningIcon from "img/Dashboard/PlansAndModules/Billing/warning_icon.png";
import {useTranslation} from "react-i18next";

interface IProps {
  amount: number;
  limit: number;
  getData: () => void;
  planId: string;
  companyId: string | undefined;
  userId: string;
  vacationCorrection: number;
  sickCorrection: number;
  otherCorrection: number;
  type: "vacation" | "sick" | "other"
}

export default function BillingListColumn(props:IProps) {
  const { t } = useTranslation();

  const [value, setValue] = useState<any>(0);
  const [blocked, setBlocked] = useState(false);
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    setValue(props.amount);
  }, [props.amount]);

  const handleChange = (event:any) => {
    const inputValue = event.target.value;
    if (inputValue <= props.limit) {
      setShowWarning(false);
      setValue(inputValue);
    } else {
      setShowWarning(true);
      setValue(props.limit);
    }
    if (inputValue < 0) {
      setShowWarning(false);
      setValue(0);
    }
  };

  function useOutsideAlerter(ref:any) {
    useEffect(() => {
      function handleClickOutside(event:any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setShowWarning(false);
          if (props.amount !== value && !blocked) {
            setBlocked(true);
            if (value === "") {
              setValue(0);
            }
            handleSubmit();
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, value, blocked]);
  }

  useEffect(() => {
    setBlocked(false);
  }, [props.amount]);

  const wrapperRef:any = useRef(null);
  useOutsideAlerter(wrapperRef);

  useEffect(() => {
    if (showWarning) {
      setTimeout(() => {
        setShowWarning(false);
      }, 3000);
    }
  }, [showWarning]);

  const handleSubmit = async (e?: any) => {
    if (e) {
      e.preventDefault();
    }
    const postData = {
      planId: props.planId,
      companyId: props.companyId,
      userId: props.userId,
      vacationCorrection: props.type === "vacation" ? +value : +props.vacationCorrection,
      sickCorrection: props.type === "sick" ? +value : +props.sickCorrection,
      otherCorrection: props.type === "other" ? +value : +props.otherCorrection,
    };

    const token = getToken();
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    await axios.post(
      `${environment.baseUrl}${urls.plans.billing}`,
      postData,
      options)
      .then((res: any) => {
        props.getData();
      })
      .catch((e: any) => {
        if (!errorHandler(e.request.status)) {
          console.error(e);
        }
      });
  };

  return(
    <form className="billing_column" onSubmit={(e:any) => handleSubmit(e)}>
      {showWarning
        ? <div className="billing_column_warning">
          <div>
            <p>{t("dashboard.plan_billing.warning")}</p>
          </div>
        </div>
        : null
      }
      <input
        ref={wrapperRef}
        min={0}
        type="number"
        value={value}
        onChange={(event:any) => handleChange(event)}
      />
      <img
        src={penIcon}
        alt="pen"
        onClick={() => wrapperRef.current.focus()}
      />
    </form>
  );
}
