import {
  CHANGE_INVOICES_FILTERING_STATE,
  CHANGE_PLANS_FILTERING_STATE
} from "../types/filteringTypes";
import {IInvoicesFilter} from "../../Interfaces/IInvoices";


export function changeInvoicesFiltering(filters: IInvoicesFilter) {
  return {
    type: CHANGE_INVOICES_FILTERING_STATE,
    payload: filters
  };
}

export function changePlansFiltering(filters: any) {
  return {
    type: CHANGE_PLANS_FILTERING_STATE,
    payload: filters
  };
}
