import React, {useEffect, useRef, useState} from "react";
import editIcon from "img/Dashboard/Employee/edit_employee.svg";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../redux/reducers/rootReducer";
import {useTranslation} from "react-i18next";
import {getToken} from "../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {useNavigate} from "react-router-dom";
import {toast} from "react-toastify";
import CustomToastContainer from "./ToastContainer";

interface IProps {
  transactionId: string;
}

function TrasactionsActionsDots(props: IProps) {
  const { t } = useTranslation();
  const actionRef:any = useRef();
  const navigate = useNavigate();

  const [menuListOpen, setMenuListOpen] = useState(false);

  const EmployeeActionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!EmployeeActionsRef.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (menuListOpen) {
      actionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuListOpen]);

  const handleNavigateToInvoice = async () => {
    const token = await getToken();
    axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.invoiceIdByTransactionId}/${props.transactionId}`,
      {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((res: any) => {
        if (res.data) {
          navigate(`/overview/${res.data}`);
        } else {
          toast.error(
            <CustomToastContainer
              message={t("dashboard.payments.card_detail.transactions_list.actions.errors.invoice_not_found")}
              status={3}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      }).catch((err:any) => {
        toast.error(
          <CustomToastContainer
            message={t("dashboard.payments.card_detail.transactions_list.actions.errors.invoice_not_found")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      });
  };

  return(
    <div ref={EmployeeActionsRef} className="action_dots_container">
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container"
        ref={actionRef}
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">

          <div
            className="table_menu_item row"
            onClick={() => handleNavigateToInvoice()}
          >
            <img src={editIcon} alt="edit"/>
            <p>
              {t("dashboard.payments.card_detail.transactions_list.actions.find_invoice")}
            </p>
          </div>
        </div>
      )}
    </div>

  );
}

const mapStateToProps = (state:RootState) => {
  return {
    deleteSuccess: state.users
  };
};

export default connect(mapStateToProps, null)(TrasactionsActionsDots);
