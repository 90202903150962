export const COMPANY_INFORMATION_SUCCESS = "COMPANY_INFORMATION_SUCCESS";
export const COMPANIES_INFORMATION_SUCCESS = "COMPANIES_INFORMATION_SUCCESS";
export const UPDATE_COMPANY_INFORMATION = "UPDATE_COMPANY_INFORMATION";
export const COMPANY_DATA_ERROR = "COMPANY_DATA_ERROR";
export const CLEAR_COMPANY_MESSAGES = "CLEAR_COMPANY_MESSAGES";
export const CLEAR_COMPANY = "CLEAR_COMPANY";


export interface companyInformationSuccess {
	type: typeof COMPANY_INFORMATION_SUCCESS
	payload: any
}

export interface companiesInformationSuccess {
	type: typeof COMPANIES_INFORMATION_SUCCESS
	payload: any
}

export interface updateCompanyInformationSuccess {
	type: typeof UPDATE_COMPANY_INFORMATION
	payload: any
}

export interface companiesDataError {
	type: typeof COMPANY_DATA_ERROR,
	payload: string
}

export interface clearCompanyMessages {
	type: typeof CLEAR_COMPANY_MESSAGES
}
export interface clearCompany{
	type: typeof CLEAR_COMPANY
}


export type companiesDataDispatchTypes =
	companyInformationSuccess |
	companiesInformationSuccess |
	updateCompanyInformationSuccess |
	clearCompanyMessages |
	companiesDataError | clearCompany




