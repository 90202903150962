export enum virtualCardState {
  ACTIVE = "ACTIVE",
  DESTROYED = "DESTROYED",
  BLOCKED = "BLOCKED"
}

export interface ICard {
  availableToSpend: Array<{
    interval: "ALWAYS";
    value: {
      amount: number;
    };
  }>;
  billingAddress: {
    addressLine1: string;
    addressLine2: string;
    city: string;
    country: string;
    postCode: string;
    state: string;
  };
  cardBrand: "MASTERCARD";
  cardLevelClassification: "CORPORATE";
  cardNumber: {
    value: string;
  };
  cardNumberFirstSix: string;
  cardNumberLastFour: string;
  cardholderMobileNumber: string;
  creationTimestamp: number;
  currency: "EUR";
  cvv: {
    value: string;
  };
  digitalWallets: {
    artworkReference: string;
    pushProvisioningEnabled: boolean;
    walletsEnabled: boolean;
  };
  email: string;
  expiryMmyy: string;
  expiryPeriodMonths: number;
  externalHandle: string;
  friendlyName: string;
  id: string;
  mode: "DEBIT_MODE";
  nameOnCard: string;
  parentManagedAccountId: string;
  profileId: string;
  renewalType: "NO_RENEW";
  startMmyy: string;
  state: {
    state: virtualCardState.ACTIVE | virtualCardState.DESTROYED | virtualCardState.BLOCKED;
  };
  type: "VIRTUAL";
}
