import React, {useEffect, useState} from "react";
import "../SimpleComponents/Modal.css";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import Spacer from "../SimpleComponents/Spacer";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import Button from "../Buttons/Button";
import CsvBillingTableItem from "../Lists/CsvBillingTableItem";
import CsvWarningImage from "img/Dashboard/Employee/csv-warning.png";
import CsvTableIMage from "img/Dashboard/Employee/csv-table@2x.png";
import { useTranslation } from "react-i18next";
import CSVReader from "react-csv-reader";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {getToken} from "../../utils/cookies/tokensCookie";
import {useLocation} from "react-router-dom";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import { getPlanData } from "redux/actions/plansActions";

interface IProps {
  closeModal: () => void;
  planId: string;
  billingPlan: any;
}


function UploadBillingCsvModal(props:IProps) {
  const { t } = useTranslation();
  const [showInfo, setShowInfo] = useState(true);
  const location = useLocation();

  const [allEmployees, setAllEmployees] = useState<any>([]);

  const getAllEmployeesNamesByPlan = async () => {
    const token = await getToken();
    const response = await axios({
      url: `${environment.baseUrl}${urls.plans.usersNamesByPlanId}/${props.planId}`,
      method: "GET",
      headers: {Authorization: `Bearer ${token}`},
    });
    if (response.data && response.data?.employees?.length > 0) {
      setAllEmployees(response.data.employees);
    }
  };

  const [csvWithoutHeader, setCsvWithoutHeader] = useState<any>();

  useEffect(() => {
    getAllEmployeesNamesByPlan();

  }, []);

  const [clearInput, setClearInput] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);

  // Show modal after csv was uploaded
  const [csvIsUploaded, setCsvIsUploaded] = useState(false);

  useEffect(() => {
    if (clearInput && (document.getElementById("csvInput") as HTMLInputElement)) {
      (document.getElementById("csvInput") as HTMLInputElement).value = "";
    }
  }, [clearInput]);

  const [csvHeaderFormat, setCsvHeaderFormat] = useState<string[]>([]);

  const [csvData, setCsvData] = useState<any>();
  const handleForce = (data: any, fileInfo: any) => {
    setCsvHeaderFormat(data[0]);
    setCsvData(data);
    setCsvIsUploaded(true);
    setShowInfo(false);
  };

  // Show warning state if data uploaded data not valid
  const [infoState, setInfoState] = useState(false);

  // Handle delete user from List
  const deleteUser = (index:number) => {
    setCsvWithoutHeader(
      csvWithoutHeader.filter((item:any) => item.index !== index));
  };

  // Define lines with errors
  const [notValidUsers, setNotValidUsers] = useState<any[]>([]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (csvData) {
      const requiredHeaders = ["Vorname", "Nachname", "Krankenstand", "Urlaubstage", "Andere Abwesenheiten"];
      const indexArray:any = [];

	  //Get the column index for each header to make reading dynamic
      requiredHeaders.forEach((reqItem: string) => {
        if(csvData && csvData[0] && csvData[0].length >= 2){
          csvData[0].forEach((header:string, i:number) => {
            if (reqItem === header.trim()) {
			  indexArray.push(i);
            }
		  });
        }
      });
      const cutData = csvData.slice(1, csvData.length);
      const finalArray:any = [];
	  //Get data from the csv file and save it as object in state
      cutData.forEach((data:any, i:number) => {
		  //check if row is valid
		  if(data[csvHeaderFormat.indexOf("Vorname")] && data[csvHeaderFormat.indexOf("Nachname")]){
		    const userFinArray:any = [];
		    indexArray.forEach((index:number) => {
		      userFinArray.push(data[index]);
		    });

          const userObj = {
            index: i,
            firstName: data[csvHeaderFormat.indexOf("Vorname")],
            lastName: data[csvHeaderFormat.indexOf("Nachname")],
            email: data[csvHeaderFormat.indexOf("Email")],
            vacation: data[csvHeaderFormat.indexOf("Urlaubstage")] ? Number(String(data[csvHeaderFormat.indexOf("Urlaubstage")]).replace(/,/g, ".")) : 0,
            sick: data[csvHeaderFormat.indexOf("Krankenstand")] ? Number(String(data[csvHeaderFormat.indexOf("Krankenstand")]).replace(/,/g, ".")) : 0,
            holiday: data[csvHeaderFormat.indexOf("Andere Abwesenheiten")] ? Number(String(data[csvHeaderFormat.indexOf("Andere Abwesenheiten")]).replace(/,/g, ".")) : 0
          };
          finalArray.push(userObj);
		  }
      });

	  //sort the array so incorrect rows show up first
      finalArray.sort((row: any) => {
        if(checkUserIsValid(`${row.firstName.toLowerCase()} ${row.lastName.toLowerCase()}`)){
          if(row.sick + row.vacation + row.holiday <= props.billingPlan.workingDays){
            return 1;
          }else{
            0;
          }
        }else{
          if(row.sick + row.vacation + row.holiday <= props.billingPlan.workingDays){
            return 0;
          }else{
            return -1;
          }
        }
        return -1;
      });

      setCsvWithoutHeader(finalArray);

      // Validate users
      if (finalArray && finalArray.length > 0) {
        const notValidUsersArray = [];
        for (const employee of finalArray) {
          if (!allEmployees.includes(`${employee.firstName.toLowerCase()} ${employee.lastName.toLowerCase()}`)) {
            notValidUsersArray.push(employee.lastName);
          }else{
			  if(employee.sick + employee.vacation + employee.holiday > props.billingPlan.workingDays){
              notValidUsersArray.push(employee.lastName);
			  }
		  }
        }
        setNotValidUsers(notValidUsersArray);
      }


    }
  }, [csvData]);

  // handle Paginate
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(5);
  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage;
  const currentPosts = csvWithoutHeader?.slice(indexOfFirstPost, indexOfLastPost);
  const paginate = (pageNumber: number) => {
    setCurrentPage(pageNumber);
  };

  /**
 * Function to save all valid rows
 */
  const saveBilling = async () => {
    setButtonLoading(true);
    const postArray: any = [];
    csvWithoutHeader.forEach((user: any) => {
      postArray.push({
        planId: location.pathname.split("/")[3],
        firstName: user.firstName,
        lastName: user.lastName,
        email: user.email,
        vacationCorrection: isNaN(Number(user.vacation)) ? 0 : Number(user.vacation),
        sickCorrection: isNaN(Number(user.sick)) ? 0 : Number(user.sick),
        otherCorrection: isNaN(Number(user.holiday)) ? 0 : Number(user.holiday),
      });
    });
    const token = await getToken();

    //Filter all rows for valid ones to post on backend
    const onlyValidArray = postArray.filter((item: any) => {
    	if(checkUserIsValid(`${item.firstName.toLowerCase()} ${item.lastName.toLowerCase()}`)){
        if(item.sickCorrection + item.vacationCorrection + item.otherCorrection <= props.billingPlan.workingDays){
          return true;
        }
      }
      return false;
    });

    if(onlyValidArray.length > 0){
      setButtonLoading(false);
      await axios.post(`${environment.baseUrl}${urls.plans.fileBilling}`,
        {
		  data: onlyValidArray
        },
        {
		  headers: {Authorization: `Bearer ${token}`}
        })
        .then(() => {
		  setButtonLoading(false);
		  dispatch(getPlanData(props.planId));
		  props.closeModal();
		  toast.success(
            <CustomToastContainer
			  message={t("dashboard.employee.csv_modal.upload.success", {employees: onlyValidArray.length})}
			  status={1}
            />,
            {
			  autoClose: 5000,
            }
		  );
        })
        .catch((err:any) => {
		  console.error(err);
		  toast.error(
            <CustomToastContainer
			  message={"Something went wrong"}
			  status={3}
            />,
            {
			  autoClose: 5000,
            }
		  );
        });
    }else{
      setButtonLoading(false);
      props.closeModal();
      toast.error(
        <CustomToastContainer
          message={t("dashboard.employee.csv_modal.upload.error")}
			  status={3}
        />,
        {
			  autoClose: 5000,
        }
		  );
    }
  };

  const handleValidationItemClick = (user:any) => {
    paginate(user.page);
  };

  /**
   * Function to check if an employee name is valid. The name is valid if its in the plan and also only once in the csv upload modal (no duplicates allowed)
   * @param userName - string
   * @returns boolean
   */
  const checkUserIsValid = (userName: string) => {
    if (allEmployees.includes(userName)) {
      let count = 0;
	  if(csvWithoutHeader && csvWithoutHeader.length > 0){
        csvWithoutHeader.forEach((employee: any) => {
          if(employee.firstName.toLowerCase()+ " " +employee.lastName.toLowerCase() == userName){
			  count++;
          }
		  });
	  }
      return count > 1 ? false : true;
    //   return true;
    } else {
      return false;
    }
  };

  const checkUserDaysAreValid = (user: any) => {
    if(user.holiday + user.sick + user.vacation > props.billingPlan.workingDays){
      return false;
    }
    return true;
  };

  /**
   * Function which is passed to child component to keep state synched
   * @param item - any
   * @param index - number
   */
  const updateCsvData = (item: any, index: number) => {
    const newState = csvWithoutHeader.map((row: any, rowIndex: number) => {
      if(rowIndex === index){
        return {
          ...row,
          holiday:item.holiday,
          sick: item.sick,
          vacation: item.vacation,
          firstName: item.firstName,
          lastName: item.lastName
        };
      }
      // otherwise return object as is
      return row;
    });
    setCsvWithoutHeader(newState);
  };

  return (
    <div style={{paddingTop: "3rem"}} className="modal_container_big white">
      <img
        onClick={() => {
          setClearInput(true);
          props.closeModal();
        }}
        className="close_popup_paddings"
        src={closeIcon} alt="close"
      />
      {showInfo
        ? <div className="csv_pop_wrapper">
          <div className="center">
            <img
              style={{maxWidth:"10vw"}}
              src={CsvWarningImage}
              alt="warning"
            />
          </div>
          <h3 className="text-center">
            {t("dashboard.employee.csv_modal.info.title")}
          </h3>
          <Spacer spacerHeight="2rem" />
          <p>{t("dashboard.employee.csv_modal.info.text")}</p>
          <Spacer spacerHeight="5px" />
          <div className="center">
            <table style={{width:"100%"}}>
              <thead>
                <tr>
                  <th>
                    <div className="center">
                    Vorname
                    </div>
                  </th>
                  <th>
                    <div className="center">
                    Nachname
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      Email
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      Urlaubstage
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      Krankenstand
                    </div>
                  </th>
                  <th>
                    <div className="center">
                      Andere Abwesenheiten
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr className="spacer" />
                <tr className="table-item">
                  <td>
                    <div className="center">
                      <p>Kunz</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>Holderman</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>kunz.holderman@company.at</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>0</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>4</p>
                    </div>
                  </td>
                  <td>
                    <div className="center">
                      <p>2</p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="center" style={{marginTop: "30px"}}>
            <div
              style={{ position: "relative", marginRight: "1rem", color: "#fd552b", border: "1px solid #fd552b"}}
              className="btn icon outlined"
            >
              <div className="row">
                <p>{t("dashboard.employee.csv_modal.info.btn")}</p>
              </div>
              <CSVReader
                inputId={"csvInput"}
                cssClass="csv_reader"
                cssLabelClass="csv_label"
                cssInputClass="csv_upload_wrapper"
                label="Select CSV "
                onFileLoaded={handleForce}
                parserOptions= {
                  {
                    dynamicTyping: true,
                    skipEmptyLines: true,
                  }
                }
              />
            </div>
          </div>
        </div>
        : infoState || !csvData?.length
          ?
          <div className="csv_pop_wrapper">
            <div className="center">
              <img
                style={{maxWidth:"10vw"}}
                src={CsvWarningImage}
                alt="warning"
              />
            </div>
            <h3 className="text-center">
              {t("dashboard.employee.csv_error_title")}
            </h3>
            <p>
              {t("dashboard.employee.csv_error_text_1")}
              <strong>Vorname, </strong>
              <strong>Nachname </strong>
            </p>
            <Spacer spacerHeight="2rem" />
            <p>{t("dashboard.employee.csv_error_text_4")}</p>
            <Spacer spacerHeight="5px" />
            <div className="center">
              <img
                style={{width:"100%"}}
                src={CsvTableIMage}
                alt="table_example"
              />
            </div>
          </div>
          :
          <>
            <div className="space-between_plans">
              <h2>{t("dashboard.employee.csv_title")}</h2>
			  {/* {notValidUsers.length > 0 ? <h2>Found {notValidUsers.length} invalid users</h2> : ""} */}
              <Button
                buttonType="primary"
                buttonLabel={t("dashboard.plans.add_employee")}
                buttonHandler={saveBilling}
                loading={buttonLoading}
              />
            </div>
            <Spacer spacerHeight="1rem" />
            <Spacer spacerHeight="1rem" />
            <div className="table_wrapper">
              {csvData?.length
                ?
                <>
                  <div className="table_box">
                    <div className="table_wrapper" >
                      <table style={{width:"100%"}}>
                        <thead>
                          <tr>
                            <th className="row">
                              <Spacer spacerWidth="1rem" />
                              Vorname
                            </th>
                            <th>
                              Nachname
                            </th>
                            <th>
                              Email
                            </th>
                            <th>
                              Urlaubstage
                            </th>
                            <th>
                              Krankenstand
                            </th>
                            <th>
                              Andere Abwesenheiten
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {currentPosts
                        && currentPosts.length > 0
                        && currentPosts.map((item:any, index:number) => (
                          <React.Fragment
                            key={`${currentPage}${index}`}>
                            <CsvBillingTableItem
                              index={index + ((currentPage-1) * postsPerPage)}
                              updateItem={(item: any, index: number) => updateCsvData(item, index)}
                              data={csvWithoutHeader}
                              item={item}
							  workingDays={props.billingPlan.workingDays}
							  employees={allEmployees}
                              deleteUser={deleteUser}
                              currentPage={currentPage}
                            />
                          </React.Fragment>
                        ))}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <Pagination
                    postsPerPage={postsPerPage}
                    totalPosts=
                      { csvWithoutHeader && csvWithoutHeader.length}
                    paginate={paginate}
                    outsideControl={currentPage}
                  />
                </>
                :
                <h2>Loading</h2>
              }
            </div>
          </>
      }
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    branchesAndDep: state.depsAndBranches.departmentAndBranches,
    uploadState: state.users
  };
};

export default connect(mapStateToProps, null)(UploadBillingCsvModal);
