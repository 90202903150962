export const GET_CARDS = "GET_CARDS";
export const GET_CARD = "GET_CARD";
export const GET_CARD_TRANSACTIONS = "GET_CARD_TRANSACTIONS";
export const SHOW_PAYMENTS_LOADING = "SHOW_PAYMENTS_LOADING";
export const CLEAR_PAYMENTS_MESSAGES = "CLEAR_PAYMENTS_MESSAGES";
export const PAYMENTS_ERROR = "PAYMENTS_ERROR";
export const CREATE_PAYMENTS_ERROR = "CREATE_PAYMENTS_ERROR";

interface getCardsAction {
  type: typeof GET_CARDS
  payload: any
}

interface getCardAction {
  type: typeof GET_CARD
  payload: any
}

interface getCardTransactionsAction {
  type: typeof GET_CARD_TRANSACTIONS
  payload: any
}

interface showPaymentsLoadingAction {
  type: typeof SHOW_PAYMENTS_LOADING
}

interface clearPaymentsMessagesAction {
  type: typeof CLEAR_PAYMENTS_MESSAGES
}

interface createPaymentsError {
  type: typeof CREATE_PAYMENTS_ERROR
}

interface paymentsErrorAction {
  type: typeof PAYMENTS_ERROR
  payload: string
}

export type paymentsTypes =
  getCardsAction |
  getCardAction |
  getCardTransactionsAction |
  showPaymentsLoadingAction |
  clearPaymentsMessagesAction |
  paymentsErrorAction |
  createPaymentsError
