import React, { useState } from "react";
import searchImg from "img/search_icon.svg";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";

interface CustomProps {
  searchPlaceholder: string;
  employee?: any;
  sidebar?: any;
  handleSearchBar: (value: string) => void;
  style?: any;
  defaultValue?: string;
}

export default function SearchBar({
  searchPlaceholder,
  handleSearchBar,
  style,
  defaultValue
}: CustomProps) {
  // handle searchbar for every page
  const [searchInputText, setSearchInputText] = useState(defaultValue ?? "");

  const handleSearchInputChange = (e: any, clear?: boolean) => {
    e.preventDefault();
    handleSearchBar(clear ? "" : searchInputText);
  };
  return (
    <form
      style={style}
      onSubmit={(e: any) => handleSearchInputChange(e)}
      className="search_container light_search"
    >
      <div onClick={handleSearchInputChange} className="search_button center">
        <img src={searchImg} className="search_icon" alt="search" />
      </div>
      <input
        type="text"
        placeholder={searchPlaceholder}
        onChange={(e: any) => setSearchInputText(e.target.value)}
        value={searchInputText}
      />
      {searchInputText.length > 0 ? (
        <div
          className="search_clear_button"
          onClick={(e) => {
            setSearchInputText("");
            handleSearchInputChange(e, true);
          }}
        >
          <img src={closeIcon} alt="close" />
        </div>
      ) : null}
    </form>
  );
}
