import "./dashboard.css";

import ContentContainer from "Components/Containers/ContentContainer";
import { connect } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Button from "Components/Buttons/Button";
import React, { useEffect, useRef, useState, useTransition } from "react";
import Modal from "react-modal";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";

import { Doughnut } from "react-chartjs-2";
import axios from "axios";
import SimpleInput from "Components/SimpleComponents/SimpleInput";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { modalStyles } from "utils/modalStyles";
import HrZoneUploadCsvEmployeeModal from "Components/Modals/HrZoneUploadCsvEmployeeModal";
import { getToken } from "utils/cookies/tokensCookie";

import { ReactComponent as Kulinarik } from "../../../img/hrZone/dashboard/ic-culinary.svg";
import { ReactComponent as Fashion } from "../../../img/hrZone/dashboard/ic-Fashion&Accessories.svg";
import { ReactComponent as Geschenkideen } from "../../../img/hrZone/dashboard/ic-geschenkideen.svg";
import { ReactComponent as Household } from "../../../img/hrZone/dashboard/ic-household&living.svg";
import { ReactComponent as InterneBenefits } from "../../../img/hrZone/dashboard/ic-InterneBenefits.svg";
import { ReactComponent as Kids } from "../../../img/hrZone/dashboard/ic-kids.svg";
import { ReactComponent as Freizeit } from "../../../img/hrZone/dashboard/ic-leisure.svg";
import { ReactComponent as Nachhaltigkeit } from "../../../img/hrZone/dashboard/ic-Nachhaltigkeit.svg";

import { ReactComponent as Sport } from "../../../img/hrZone/dashboard/ic-sport.svg";
import { ReactComponent as Technik } from "../../../img/hrZone/dashboard/ic-Technik.svg";
import { ReactComponent as Travel } from "../../../img/hrZone/dashboard/ic-travel.svg";
import { ReactComponent as Wellness } from "../../../img/hrZone/dashboard/ic-wellness&beauty.svg";
import { useTranslation } from "react-i18next";
import { urls } from "api/urls";

function DashboardPage(props: any) {

  const { t } = useTranslation();

  const [employeeData, setEmployeeData] = useState({
    numberOfEmployees: 0,
    numberOfRegisteredEmployees: 0,
    percentRegistered: 100
  });

  //State which will contain the data from backend response regarding the categories
  const [chartData, setChartData] = useState({});

  //Colors array which will be passed to chartData later on
  //   const colorsOLD = [
  //     "#FD028A",
  //     "#C453DD",
  //     "#0755FB",
  //     "#2DBC38",
  //     "#FD9B35",
  //     "#CCDE31",
  //     "#42BFE6",
  //     "#E81119"
  //   ];

  const colors = [
    "#fab168","#f06fac","#c48aef",
    "#73CCB8","#7B91F2","#C9D374",
    "#d59e5e","#6dc7c7","#81c0ef",
    "#ed6c6c","#7ccc83","#f5ca3a",
  ];

  //Helper state to define if initial fetching is done
  const [isLoading, setIsLoading] = useState(true);

  //State which defines if the invite modal should be displayed
  const [showInviteModal, setInviteShowModal] = useState(false);

  //State which helps defining if there is atleast one item with data
  const [sumCategories, setSumCategories] = useState(0);

  const [isMasterHr, setIsMasterHr] = useState(false);

  /**
   * Function to fetch data from backend and set local state depending on response
   */
  const fetch = async () => {
    try {
      const res: any = await axios.post(
        urls.hrZone.base + "dashboard",
        {
          hr_email: props.employee.email,
		  sessionKey: props.hrZoneAuth.sessionKey
        },

        {
          headers: { Authorization: `Bearer ${getToken()}` },
          timeout: 15000
        }
      );
      if (res.status == 200 && res.data.success === 1) {
        setIsMasterHr(props.hrZoneAuth.isMasterHr === 1);
        setEmployeeData({
          numberOfEmployees: res.data.data.numberOfEmployees ?? 0,
          numberOfRegisteredEmployees:
            res.data.data.numberOfRegisteredEmployees ?? 0,
          percentRegistered: res.data.data.percentRegistered ?? 0
        });

        setChartData(res.data.data.categoryChartData);

        let sum = 0;
        Object.values(res.data.data.categoryChartData).forEach((value: any) => {
          sum += value;
        });

        setSumCategories(sum);
        setIsLoading(false);
      } else {
        setChartData({ "No data": 0 });
      }
      setIsLoading(false);
    } catch (e) {
      //Error nothing got fetched  - set state
      setChartData({ "No data": 0 });
      setIsLoading(false);
    }
  };
  /**
   * Hook which will call fetch function on initial load
   */
  useEffect(() => {
    if (isLoading && props.hrZoneAuth.sessionKey.length > 0) {
      fetch();
    }
  }, [props.hrZoneAuth.sessionKey]);

  const headerMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)) {
        setInviteShowModal(false);
        setModalUser({ email: "", firstName: "", lastName: "", office: "" });
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [modalUser, setModalUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    office: ""
  });

  const getImageForText = (text: string, color: string) => {
    switch (text) {
    case "Freizeit":
      return <Freizeit stroke={color} fill={color} />;
    case "Haushalt & Wohnen":
      return <Household stroke={color} fill={color} />;
    case "Kinder":
      return <Kids stroke={color} fill={color} />;
    case "Kulinarik":
      return <Kulinarik stroke={color} fill={color} />;
    case "Reisen":
      return <Travel stroke={color} fill={color} />;
	  case "Sport & Gesundheit":
      return <Sport stroke={color} fill={color} />;
    case "Beauty & Wellness":
      return <Wellness stroke={color} fill={color} />;
	  case "Geschenkideen":
      return <Geschenkideen stroke={color} fill={color} />;
	  case "Mode & Accessoires":
      return <Fashion stroke={color} fill={color} />;
	  case "Nachhaltigkeit":
      return <Nachhaltigkeit stroke={color} fill={color} />;
	  case "Technik":
      return <Technik stroke={color} fill={color} />;
	  case "Interne Benefits":
      return <InterneBenefits stroke={color} fill={color} />;
    }
  };

  /**
   * Render function for cards. It either renders a dynamic amount of cards if there is data from backend. Otherwise it will render 8 loading cards or empty cards.
   */
  const categoryCards =
    !isLoading && Object.entries(chartData).length > 1
      ? Object.entries(chartData).map((chart: any, idx) => {
        const isEmpty = Number.parseFloat(chart[1]) > 0 ? true : false;
        return (
          <div
            key={chart[0].split(" ")[0].toLowerCase() + idx}
            className={"dashboard_card "}
            style={isEmpty ? { borderLeft: "4px solid " + colors[idx] } : {}}
          >
            <div className="dashboard_card-textContainer">
              <p className="card_text">{chart[0]}</p>
              <p className="card-text">{chart[1] + "%"}</p>
            </div>

            {getImageForText(
              chart[0],
              chart[1] > 0 ? colors[idx] : "#DEDEDE"
            )}
          </div>
        );
      })
      : Array.from(Array(8).keys()).map((entry, idx) => {
        //Render Loading cards without data if there is no data from backend
        return (
          <div key={"LoadingCard-" + idx} className={"dashboard_card"}>
            {isLoading ? (
              <div
                style={{
                  height: "80px",
                  borderRadius: "4px",
                  width: "100%",
                  filter: "0.1",
                }}
                className={isLoading ? "loading_container loading_title" : ""}
              />
            ) : (
              <div className="dashboard_card-textContainer">
                <p className="card_text">{t("hrzone.dashboard.categories.cardError")}</p>
                <p className="card-text">0 %</p>
              </div>
            )}
          </div>
        );
      });

  const inviteEmployee = async () => {
    if (validInputs) {
      try{
        const res: any = await axios.post(
          urls.hrZone.base + "invite",
          {
				  hr_email: props.employee.email,
				  users: [{ ...modalUser, isHR: 0 }],
				  sessionKey: props.hrZoneAuth.sessionKey
          },
          {
				  headers: { Authorization: `Bearer ${getToken()}` },
				  timeout: 10000
          }
			  );
			  if (res.status == 200 ) {
          toast.success(
				  <CustomToastContainer message={t("hrzone.toastMessages.invite.success")} status={1} />,
				  {
              autoClose: 5000
				  }
          );
			  } else {
          toast.error(
				  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
				  {
              autoClose: 5000
				  }
          );
			  }
      }catch(e){
        toast.error(
          <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
          {
				  autoClose: 5000
          }
			  );
      }

    } else {
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.wrongInput")} status={3} />,
        {
          autoClose: 5000
        }
      );
    }
  };

  const validateEmail = () => {
    return String(modalUser.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validInputs =
    modalUser.firstName.length > 0 &&
    modalUser.lastName.length > 0 &&
    validateEmail();

  const invitationModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          //   height: "520px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showInviteModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px"
        }}
      >
        <img
          onClick={() => setInviteShowModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>{t("hrzone.modals.employee.invite.title")}</h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={t("hrzone.modals.employee.email")}
            isDisabled={false}
            inputValue={modalUser.email}
            handleChange={(e) => {
              setModalUser({ ...modalUser, email: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={t("hrzone.modals.employee.firstName")}
            isDisabled={false}
            inputValue={modalUser.firstName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, firstName: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={t("hrzone.modals.employee.lastName")}
            isDisabled={false}
            inputValue={modalUser.lastName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, lastName: e });
            }}
          />
		  {/* <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={t("hrzone.modals.employee.office")}
            isDisabled={false}
            inputValue={modalUser.office}
            handleChange={(e) => {
              setModalUser({ ...modalUser, office: e });
            }}
          /> */}
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end"
          }}
        >
          <p
            style={{
              textAlign: "center",
              marginBottom: "15px",
              fontSize: "12px",
              color: "#9aa9b3"
            }}
          >
            {t("hrzone.modals.separator")}
          </p>

          <Button
            buttonStyles={{
              margin: "0px auto 15px auto",
              fontWeight: 600,
              background: "none"
            }}
            buttonType={"secondary"}
            buttonLabel={t("hrzone.modals.csvUpload")}
            buttonHandler={() => {setShowCsvModal(true);setInviteShowModal(false);}}
          />

          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600
            }}
            disabled={!validInputs}
            buttonType={"primary"}
            buttonLabel={t("hrzone.buttons.invite")}
            buttonHandler={() => {
              inviteEmployee();
              setInviteShowModal(false);
            }}
          />
        </div>
      </div>
    </Modal>
  );

  const [showCsvModal, setShowCsvModal] = useState(false);

  return (
    <ContentContainer>
      <div className="dashboard_page">
        {invitationModal}
        <Modal isOpen={showCsvModal} style={modalStyles}>
          <HrZoneUploadCsvEmployeeModal
					  // users={[]}
					  isHR={0}
					  closeModal={() => setShowCsvModal(false)} hrZoneAuth={props.hrZoneAuth}          />
        </Modal>
        <h1 style={{ marginBottom: "30px" }}>{t("hrzone.dashboard.title")}</h1>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "1fr",
            background: "#fff",
            borderRadius: "20px",
            padding: "30px"
          }}
        >
          <div className="dashboard_hrzone-container">
            <div
              style={{
                maxHeight: "250px",
                minWidth: "300px",
                minHeight: "250px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
              }}
            >
              <div style={{ position: "absolute", textAlign: "center" }}>
                <h1>{Number(employeeData?.percentRegistered)?.toFixed(2) || 0} %</h1>
                <p style={{ color: "#BABABA", fontSize: 12 }}>
                  {t("hrzone.dashboard.chartText")}
                </p>
              </div>
              <Doughnut
                data={{
                  datasets: [
                    {
                      data: [
                        Number(employeeData?.percentRegistered)?.toFixed(2),
                        (100 - Number(employeeData?.percentRegistered))?.toFixed(2)
                      ],
                      backgroundColor: ["#FF6E42", "#D7DAE0"],
                      hoverBackgroundColor: ["#FF6E42", "#D7DAE0"],
                      hoverBorderColor: ["#FF6E42", "#D7DAE0"],
                      hoverBorderWidth: "1",
                      borderWidth: 0
                    }
                  ],
                  labels: ["Registriert", "Unregistriert"]
                }}
                options={{
                  maintainAspectRatio: false,
                  legend: {
                    display: false
                  },
                  cutoutPercentage: 65
                }}
              />
            </div>

            <div className="dashboard_employee-container">
              <div
                className="dashboard_employee-border"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div className="dashboard_dot-container">
                  <div className="dashboard_employee-dot" />
                  <p style={{ fontSize: "20px" }}>{t("hrzone.dashboard.registered")}</p>
                </div>
                <p style={{ fontSize: "32px", fontWeight: 700 }}>
                  {employeeData.numberOfRegisteredEmployees}
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column"
                }}
              >
                <div className="dashboard_dot-container">
                  <div className="dashboard_employee-dot" />
                  <p style={{ fontSize: "20px" }}>{t("hrzone.dashboard.employees")}</p>
                </div>
                <p style={{ fontSize: "32px", fontWeight: 700 }}>
                  {employeeData.numberOfEmployees}
                </p>
              </div>
            </div>
              {isMasterHr &&
            <div className="dashboard_employee-button">
              <Button
                buttonType={"primary"}
                buttonLabel={t("hrzone.buttons.inviteEmployee")}
                buttonStyles={{
                  marginTop: "auto",
                  display: "block",
                  fontWeight: 700,
				  minWidth: "170px"
                }}
                buttonHandler={() => setInviteShowModal(true)}
              />
            </div>
              }
          </div>
        </div>

        <h1
          style={{
            width: "100%",
            borderBottom: "1px solid #000",
            margin: "30px 0",
            paddingBottom: "30px"
          }}
        >
          {t("hrzone.dashboard.categories.title")}
        </h1>

        <div className="dashboard_category-container">
          <div className="dashboard_category-circle">
            <Doughnut
              data={{
                datasets: [
                  {
                    data:
                      sumCategories > 0
                        ? Object.values(chartData).map((data) => data)
                        : [1],
                    backgroundColor: sumCategories > 0 ? colors : ["#A9A9A9"],
                    hoverBackgroundColor:
                      sumCategories > 0 ? colors : ["#A9A9A9"],
                    hoverBorderColor: sumCategories > 0 ? colors : ["#A9A9A9"],
                    hoverBorderWidth: "5",
                    borderWidth: 0
                  }
                ],
                labels: sumCategories > 0 ? Object.keys(chartData) : [t("hrzone.dashboard.categories.cardError")]
              }}
              options={{
                maintainAspectRatio: false,
                legend: {
                  display: false
                },
                cutoutPercentage: 65
              }}
            />
          </div>

          <div className="dashboard_cards-container">{categoryCards}</div>
        </div>
      </div>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    employee: state.users.userInfo.user,
    company: state.company.company.company,
    postCompanyDataState: state.company,
    hrZoneAuth: state.hrZone
  };
};

export default connect(mapStateToProps, null)(DashboardPage);