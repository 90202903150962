export const DEACTIVATE_PLAN_SUCCESS = "DEACTIVATE_PLAN_SUCCESS";
export const GET_ONE_PLAN_SUCCESS = "GET_ONE_PLAN_SUCCESS";
export const GET_PLAN_DATA_SUCCESS = "GET_PLAN_DATA_SUCCESS";
export const GET_PLANS_SUCCESS = "GET_PLANS_SUCCESS";
export const GET_BILLING_PLANS_SUCCESS = "GET_BILLING_PLANS_SUCCESS";
export const POST_PLANS_SUCCESS = "POST_PLANS_SUCCESS";
export const UPDATE_PLAN_SUCCESS = "UPDATE_PLAN_SUCCESS";
export const PLAN_ERROR = "PLAN_ERROR";
export const PLANS_LOADING = "PLANS_LOADING";
export const PLAN_CLEAR_MESSAGES = "PLAN_CLEAR_MESSAGES";
export const CLEAR_PLAN_DATA = "CLEAR_PLAN_DATA";

export interface deactivatePlanSuccess {
	type: typeof DEACTIVATE_PLAN_SUCCESS
	payload: any
}

export interface getOnePlanSuccess {
	type: typeof GET_ONE_PLAN_SUCCESS
	payload: any
}

export interface getPlanDataSuccess {
	type: typeof GET_PLAN_DATA_SUCCESS
	payload: any
}

export interface getPlansSuccess {
	type: typeof GET_PLANS_SUCCESS
	payload: any
}

export interface getBillingPlansSuccess {
	type: typeof GET_BILLING_PLANS_SUCCESS
	payload: any
}

export interface postPlansSuccess {
	type: typeof POST_PLANS_SUCCESS
	payload: any
}

export interface updatePlanSuccess {
	type: typeof UPDATE_PLAN_SUCCESS
	payload: any
}

export interface planError {
	type: typeof PLAN_ERROR
	payload: string
}

export interface plansLoading {
	type: typeof PLANS_LOADING
	payload: string
}

export interface planClearMessages {
	type: typeof PLAN_CLEAR_MESSAGES
}

export interface clearPlanData {
	type: typeof CLEAR_PLAN_DATA
}


export type plansDispatchTypes =
	deactivatePlanSuccess |
	planError |
	planClearMessages |
	getOnePlanSuccess |
	getPlanDataSuccess |
	getPlansSuccess |
	getBillingPlansSuccess |
	postPlansSuccess |
	updatePlanSuccess |
	plansLoading |
	clearPlanData




