// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.main-container {
    width: 100%;
    overflow-y: scroll;
    height: calc(100vh - 100px);
    position: relative;
}

.main_container_full {
    width: 100%;
    overflow-y: scroll;
    height: 100vh;
    position: relative;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/ContentContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,2BAA2B;IAC3B,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,aAAa;IACb,kBAAkB;AACtB","sourcesContent":[".main-container {\n    width: 100%;\n    overflow-y: scroll;\n    height: calc(100vh - 100px);\n    position: relative;\n}\n\n.main_container_full {\n    width: 100%;\n    overflow-y: scroll;\n    height: 100vh;\n    position: relative;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
