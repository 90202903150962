import environment from "environment";

export const urls = {
  auth: {
    login: "auth/tokens",
    resetPassword: "general/resetPassword",
    codeValidation: "codes/validate",
    magentoLogin: "auth/tokensWithMagento",
    changeCompanyAdmin: "auth/changeCompanyAdmin"
  },
  branchAndDepartments: {
    departmentsByCompanyId: "departments?companyId=",
    departments: "departments",
    branchesByCompanyId: "branches?companyId=",
    branches: "branches",
    departmentAndBranchesByCompanyId: "depandbranches?companyId=",
    departmentAndBranches: "depandbranches/depAndBranch",
    depsAndBranches: "depandbranches"
  },
  invoices: {
    invoices: "invoices",
    indexForCompanyByCompanyId: "invoices/indexInvoicesForCompany?companyId=",
    indexByMultipleDynamicSearch:
      "invoices/indexByMultipleDynamicSearch?companyId=",
    indexByMultipleDynamicSearchByUser:
      "invoices/indexByMultipleDynamicSearchByUser",
    statusByCompanyId: "invoices/status?companyId=",
    filtersForOrderOverview: "invoices/getFiltersForOrderOverview?companyId=",
    calculationByUserByCompanyId: "invoices/calculationByUser?companyId=",
    getAnalytics: "invoices/getAnalytics",
    getNearest: "invoices/getInvoiceByAgg"
  },
  charts: {
    dailyReportByCompanyId: "reports/daily?companyId=",
    summaryByCompanyId: "reports/summary?companyId=",
    branchesByCompanyId: "reports/branches?companyId="
  },
  companies: {
    companies: "companies",
    companyUpdate: "companies/update",
    testCompanyDelete: "companies/deleteTestCompany"
  },
  files: {
    files: "files",
    generateReportForUser: "export/generateReportForUser",
    transactions: "export/transactions",
    cardTransactions: "export/cardTransactions",
    cards: "export/cards",
    wireTransactions: "export/wireTransactions",
    file: "corplife/files/checklist",
    multiple: "corplife/files/checklist/multiple"
  },
  general: {
    companies: "companies",
    regionsByCompanyId: "regions?companyId=",
    activateUser: "general/activateUser",
    resetEmail: "general/resetEmail",
    inviteUser: "general/inviteUser",
    inviteMultipleUser: "general/inviteMultipleUser",
    checklists: "corplife/checklists"
  },
  merchants: {
    merchants: "merchants"
  },
  marketing: {
    marketing: "marketing",
    benefitsContact: "marketing/benefitsContact"
  },
  notification: {
    markAsRead: "notifications/markAsRead",
    markAllAsRead: "notifications/markAllAsRead"
  },
  payments: {
    stats: {
      infoCards: "payments/infoCardsStats"
    },
    managedAccountStatement: "payments/managedAccountStatement",
    getOutgoingWireTransactions: "payments/getOutgoingWireTransactions",
    invoiceIdByTransactionId: "payments/invoiceIdByTransactionId",
    cards: "payments/cards",
    createManagedAccount: "payments/createManagedAccount",
    transactions: "payments/transactions",
    getCorporateByCompanyId: "payments/getCorporateByCompanyId",
    changeStatusForKYC: "payments/changeStatusForKYC",
    createPassword: "payments/createPassword",
    verify: "payments/verify",
    sendEmailVerification: "payments/sendEmailVerification",
    sendSmsVerification: "payments/sendSmsVerification",
    verifySms: "payments/verifySms",
    startKYB: "payments/startKyb",
    login: "payments/login",
    createCorporate: "payments/createCorporate",
    statistic: "payments/statistic",
    iban: "payments/managedAccountIban",
    cardsTotalAmount: "payments/cards/getTotalAmount",
    cardsMonthlyStatistic: "payments/cards/monthlyStatistic",
    cardsDailyStatistic: "payments/cards/dailyStatistic",
    blockCard: "payments/block",
    recreate: "payments/recreate",
    removeCard: "payments/remove",
    blockMultipleCards: "payments/blockMultiple",
    unblockCard: "payments/unblock",
    recreatePassword: "payments/recreatePassword",
    resetPassword: "employeePayments/resetPassword",
    createAnOutgoingWireTransferTransaction:
      "payments/createAnOutgoingWireTransferTransaction",
    verifyAnOutgoingWireTransferTransaction:
      "payments/verifyAnOutgoingWireTransferTransaction",
    getManagedCardBackOffice: "payments/getManagedCardBackOffice",
    createPasscodeForAuthorizedUser: "employeePayments/createPasscodeForAuthorizedUser",
    inviteUserToAuthorisation: "payments/inviteUserToAuthorisation",
    sendStepUp: "payments/stepup/send",
    verifyStepUp: "payments/stepup/verify",
    getDepositTransactions: "payments/getDepositTransactions",
    changeAuthorisedUserPhoneNumber: "payments/changeAuthorisedUserPhoneNumber",
  },
  partnerPortal: {
    getProfile: "partner-portal/profile",
    updateProfile: "partner-portal/profile",
    getDeals: "partner-portal/deals"
  },
  plans: {
    plans: "plans",
    multiplePlans: "plans/multiplePlans",
    calculate: "plans/calculate",
    billing: "plans/billing",
    requestAbsenceDays: "plans/absence",
    requestAbsenceDaysGet: "plans/absence/get",
    usersWithInvoices: "plans/usersWithInvoicesByPlan",
    getSupermarketPlansExists: "plans/getSupermarketPlansExists",
    fileBilling: "plans/fileBilling",
    usersNamesByPlanId: "plans/usersNamesByPlanId",
    getBudgetToMove: "plans/getBudgetToMove",
    confirmBudgetMoving: "plans/confirmBudgetMoving"
  },
  roles: {
    roles: "roles",
    associatedCompaniesForUser: "roles/associatedCompaniesForUser"
  },
  users: {
    users: "users",
    reportUser: "reports/users",
    addMany: "users/addMany",
    deleteMany: "users/deleteMany",
    restoreMany: "users/restoreMany",
    getAllForPlanEmployeeSelection: "users/getAllForPlanEmployeeSelection",
    getUsersWithCards: "users/getUsersWithCards"
  },
  hrZone: {
    base: environment.baseUrl +"hrzone/"
  },
  support: {
    index: "support"
  }
};
