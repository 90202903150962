// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tabs .tab-header {
    height:60px;
    display:flex;
    align-items:center;
}
.tabs .tab-header > div {
    width: 150px;
    text-align:center;
    color:#888;
    font-weight:600;
    cursor:pointer;
    font-size:14px;
    text-transform:uppercase;
    outline:none;
}
.tabs .tab-header > div > i {
    display:block;
    margin-bottom:5px;
}
.tabs .tab-header > div.active {
    color:#00acee;
}
.tabs .tab-indicator {
    position:relative;
    width: 150px;
    height:5px;
    background:#00acee;
    left:0px;
    border-radius:5px;
    transition:all 500ms ease-in-out;
}

.modal_search_box {
    max-width: 200px;
    margin-top: 1rem;
    margin-bottom: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/pages/userSettings/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;AACA;IACI,YAAY;IACZ,iBAAiB;IACjB,UAAU;IACV,eAAe;IACf,cAAc;IACd,cAAc;IACd,wBAAwB;IACxB,YAAY;AAChB;AACA;IACI,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,aAAa;AACjB;AACA;IACI,iBAAiB;IACjB,YAAY;IACZ,UAAU;IACV,kBAAkB;IAClB,QAAQ;IACR,iBAAiB;IACjB,gCAAgC;AACpC;;AAEA;IACI,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".tabs .tab-header {\n    height:60px;\n    display:flex;\n    align-items:center;\n}\n.tabs .tab-header > div {\n    width: 150px;\n    text-align:center;\n    color:#888;\n    font-weight:600;\n    cursor:pointer;\n    font-size:14px;\n    text-transform:uppercase;\n    outline:none;\n}\n.tabs .tab-header > div > i {\n    display:block;\n    margin-bottom:5px;\n}\n.tabs .tab-header > div.active {\n    color:#00acee;\n}\n.tabs .tab-indicator {\n    position:relative;\n    width: 150px;\n    height:5px;\n    background:#00acee;\n    left:0px;\n    border-radius:5px;\n    transition:all 500ms ease-in-out;\n}\n\n.modal_search_box {\n    max-width: 200px;\n    margin-top: 1rem;\n    margin-bottom: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
