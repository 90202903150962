import React, {useEffect, useRef, useState} from "react";
import { AnyIfEmpty } from "react-redux";
import "./Tooltip.css";
import TooltipBox from "./TooltipBox";

interface IProps {
  children: JSX.Element;
  text: string;
  style?: React.CSSProperties;
}

export default function Tooltip(props:IProps) {
  const [containerHeight, setContainerHeight] = useState(0);
  const [containerOffsetTop, setContainerOffsetTop] = useState(0);
  const [showTooltip, setShowTooltip] = useState(false);
  const [opacity, setOpacity] = useState(0);

  const containerRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (containerRef && containerRef.current) {
      setContainerHeight(containerRef.current.clientHeight);
      setContainerOffsetTop(containerRef.current.offsetTop);
    }
  }, []);

  return(
    <div
      style={props.style}
      className="tooltip_container"
      ref={containerRef}
      onMouseEnter={() => {
        setShowTooltip(true);
        setTimeout(() => {
          setOpacity(1);
        }, 10);
      }}
      onMouseLeave={() => setShowTooltip(false)}
    >
      {showTooltip
        ? <TooltipBox
          text={props.text}
          containerOffsetTop={containerOffsetTop}
          opacity={opacity}
        />
        : null
      }
      {props.children}
    </div>
  );
}
