import React, {useState} from "react";
import "./CardDetailTopSection.css";
import {useTranslation} from "react-i18next";
import moment from "moment";
import CreditCard from "Components/Payments/CreditCard/CreditCard";
import infoCardIcon from "../../../../../img/Dashboard/Payments/Overview/Header/card_icon.png";

interface IProps {
  card: any;
  setShowPaymentsAuthModal?: (state: boolean) => void;
  hideTutorial?: boolean;
}

export default function CardDetailTopSectionCard(props: IProps) {
  const { t } = useTranslation();

  const [showDetails, setShowDetails] = useState(false);

  return(
    <div
      className="card_detail_top_section_wrapper"
      style={{
        gridTemplateColumns: props.hideTutorial ? "1fr" : "37% 1fr"
      }}
    >

      <div className="card_detail_top_section_container">

        <div className="space-between" style={{alignItems: "flex-start"}}>
          <div>
            <p className="card_detail_top_section_container_title">
              {t("dashboard.payments.card_detail.cards.card.title")}
            </p>
            <p className="card_detail_top_section_container_title">
              {moment(props.card.creationTimestamp).format("DD.MM.YYYY")}
            </p>
          </div>

          <p
            className="card_detail_top_section_container_title"
            style={{cursor: "pointer"}}
            onClick={() => setShowDetails(!showDetails)}
          >
            {t(`dashboard.payments.card_detail.cards.card.${showDetails ? "hide_details" : "show_details"}`)}
          </p>

        </div>

        <div className="center">
          <CreditCard
            cardNumberLastFour={props.card.cardNumberLastFour}
            amount={props.card.availableToSpend && props.card.availableToSpend[0]?.value?.amount / 100}
            type={props.card?.friendlyName?.includes("Supermarket") ? "supermarket" : "restaurant"}
            cardNumberValue={props.card?.cardNumber?.value}
            showDetails={showDetails}
            setShowPaymentsAuthModal={(state) => {
              setShowDetails(false);
              props.setShowPaymentsAuthModal && props.setShowPaymentsAuthModal(state);
            }}
            cvvValue={props.card?.cvv?.value}
            expiryDate={props.card?.expiryMmyy}
          />
        </div>

      </div>


      {!props.hideTutorial
        &&
        <div className="card_detail_top_section_container info">
          <h4>{t("dashboard.payments.card_detail.cards.info.title")}</h4>
          <h5>{t("dashboard.payments.card_detail.cards.info.description")}</h5>

          <p>
            {t("dashboard.payments.card_detail.cards.info.steps.first")}
          </p>

          <p>
            {t("dashboard.payments.card_detail.cards.info.steps.second")}
          </p>

          <div className="card_detail_top_section_info_container">

            <img src={infoCardIcon} alt="info_card"/>

            <div>
              <p>
                {t("dashboard.payments.card_detail.cards.info.steps.second_description_part_1")}
                <strong>
                  {t("dashboard.payments.card_detail.cards.info.steps.second_description_iPhone")}
                </strong>
                {t("dashboard.payments.card_detail.cards.info.steps.second_description_part_2")}
                <strong>
                  {t("dashboard.payments.card_detail.cards.info.steps.second_description_android")}
                </strong>
                {t("dashboard.payments.card_detail.cards.info.steps.second_description_part_3")}
              </p>
              <p>
                {t("dashboard.payments.card_detail.cards.info.steps.second_description_part_4")}
              </p>
            </div>

          </div>

          <p>
            {t("dashboard.payments.card_detail.cards.info.steps.third")}
          </p>

        </div>
      }

    </div>
  );
}
