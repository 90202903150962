import React, {useEffect, useState} from "react";
import "./ProfileCompletion.css";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import Modal from "react-modal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import qs from "qs";
import environment from "../../environment";
import {errorHandler} from "../../utils/errorHandler";
import {toast} from "react-toastify";
import Circle from "react-circle";
import Button from "Components/Buttons/Button";
import CompleteProfileModal from "../Modals/CompleteProfileModal";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {getToken} from "../../utils/cookies/tokensCookie";
import {modalStyles} from "../../utils/modalStyles";
import {urls} from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import {invoicesService} from "../../api/services/Invoices/invoices.service";
import {IInvoicesTopCardsData} from "../../api/services/Invoices/invoices.interface";
import PuffLoader from "react-spinners/PuffLoader";


interface BoxProps {
	employee?: any
}

const ProfileCompletion = ({employee}:BoxProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [planData, setPlanData] = useState(0);
  const [cardLayout, setCardLayout] = useState<"profile" | "invoices" | "loading">("loading");
  const [invoicesResponse, setInvoicesResponse] = useState<null | IInvoicesTopCardsData>(null);

  const getPlan = async () => {
    const token = getToken();
    const companyId = getUserCompany();
    const fullUrl = `${environment.baseUrl}${urls.plans.plans}?companyId=${companyId}`;
    const dynamicParams = {
      limit: 1,
      skip: 0,
    };
    try {
      const response = await axios.get(fullUrl, {
        headers: { Authorization: `Bearer ${token}` },
        params: dynamicParams,
        paramsSerializer: (params: any) => qs.stringify(params),
      });
      setCardLayout("profile");
      setPlanData(response.data.total);
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        toast.error(
          <CustomToastContainer
            message={t("warnings.marketing_error_1")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    }
  };

  const getPendingInvoices = async () => {
    const response = await new invoicesService().getInvoicesTopCardsData();
    if (response && response.status && response.data && response.data.pending > 0) {
      setCardLayout("invoices");
      setInvoicesResponse(response.data);
    } else {
      // If no pending invoices => get plan data and show plan card layout
      getPlan();
    }
  };

  useEffect(() => {
    getPendingInvoices();
  }, []);

  // show modal with complete profile
  const [showModal, setShowModal] = useState(false);
  const [percentage, setPercentage] = useState(0);
  const [completion, setCompletion] = useState({
    employee: false,
    plans: false
  });

  // Set percentage for progress circle
  useEffect(() => {
    let completedPercentage = 60;
    let employeeTemporary = false;
    let plansTemporary = false;
    if (employee.users && employee.users.length) {
      completedPercentage += 20;
      employeeTemporary = true;
    }
    if (planData > 0) {
      completedPercentage += 20;
      plansTemporary = true;
    }
    setCompletion({
      employee: employeeTemporary,
      plans: plansTemporary
    });
    setPercentage(completedPercentage);
    //Need to disable it, because eslint says, that I need to add
    //completion
    // eslint-disable-next-line
	}, [employee, planData])

  const getLayout = () => {
    switch (cardLayout) {
    case "loading":
      return <div
        style={{
          width: "100%",
          height: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: "center"
        }}
      >
        <PuffLoader
          color={"#ffffff"}
          size={120}
        />
      </div>;
    case "profile":
      return <>
        <div className="center">
          <div style={{marginLeft: "10px"}}>
            <div className="progress_text">
              <h2 data-testid="progress_text">
                {percentage === 100
                  ? t("dashboard.home.profile_completed")
                  : t("dashboard.home.profile_title")
                }
              </h2>
              {percentage === 100
                ? null
                :
                <p data-testid="progress_sub_text">
                  {t("dashboard.home.profile_text")}
                </p>
              }
            </div>
            {percentage === 100
              ?
              <div className="center">
                <Button
                  buttonStyles={{fontSize: "0.9rem"}}
                  buttonType="primary"
                  buttonLabel=
                    {t("dashboard.home.profile_button_compl")}
                  buttonHandler={() => navigate("/general-info")}
                />
              </div>
              :
              <div className="center">
                <Button
                  buttonStyles={{fontSize: "0.9rem"}}
                  buttonType="primary"
                  buttonLabel=
                    {t("dashboard.home.profile_button")}
                  buttonHandler={() => setShowModal(true)}
                />
              </div>
            }
          </div>
        </div>
        <div className="welcome_right">
          <div className="center">
            <div className="welcome_circle center">
              <Circle
                responsive={true}
                textColor="#ffffff"
                progress={percentage}
                lineWidth="40"
                progressColor="#09CE8A"
                bgColor="transparent"
                textStyle={{
                  fontWeight: 700,
                  fontSize: "5rem"
                }}
              />
            </div>
          </div>
        </div>
      </>;
    case "invoices":
      return <div className="profile_completion_invoices_container">
        <h2>
          {t("dashboard.home.invoices_title")}
        </h2>
        <p>
          {t("dashboard.home.invoices_text", {count: invoicesResponse?.pending})}
        </p>
        <div style={{
          position: "absolute",
          right: 35,
          bottom: 20
        }}>
          <Button
            buttonStyles={{maxWidth: "none", paddingLeft: "14px", paddingRight: "14px"}}
            buttonType="primary"
            buttonLabel={t("dashboard.home.invoices_btn")}
            buttonHandler={() => navigate(`overview/${invoicesResponse?.firstPendingItem}?approve=true`)}
          />
        </div>
      </div>;
    }
  };

  return(
    <div
      className="dash_first_one welcome_dash"
      style={{
        display: cardLayout === "profile" ? "grid" : "block"
      }}
    >
      <Modal
        style={modalStyles}
        isOpen={showModal}
      >
        <CompleteProfileModal
          completion={completion}
          closeModal={() => setShowModal(false)}
        />
      </Modal>
      {getLayout()}
    </div>
  );
};

const mapStateToProps = (state:RootState) => {
  return {
    employee: state.users.users
  };
};

export default connect(mapStateToProps, null)(ProfileCompletion);
