import React, { useEffect } from "react";
import PageTitle from "Components/SimpleComponents/PageTitle";
import MainSpace from "Components/Containers/MainSpace";
import ContentContainer from "Components/Containers/ContentContainer";
import { connect, useDispatch } from "react-redux";
import { changeSidebarTab } from "../../redux/actions/sidebarActions";
import { RootState } from "../../redux/reducers/rootReducer";
import NotificationsList from "../../Components/Lists/NotificationsList";
import { useTranslation } from "react-i18next";
import { readAllNotifications } from "../../redux/actions/notifications";

interface CustomProps {
  notifications?: any;
}

function NotificationCenter({ notifications }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Mark notifications as read
  const markNotificationsAsRead = () => {
    if (notifications && notifications.newNotification > 0) {
      readAllNotifications();
    }
  };

  const notificationCounter = notifications.total || 0;

  return (
    <ContentContainer>
      <MainSpace>
        <div className="space-between">
          <div className="row" style={{ gap: "20px" }}>
            <PageTitle
              mainTitle={t("dashboard.notification.title", {
                count: notificationCounter
              })}
            />
            {notifications.newNotification > 0 ? (
              <PageTitle
                mainTitle={t("dashboard.notification.title_new", {
                  count: notifications.newNotification
                })}
              />
            ) : null}
          </div>
          {notifications.newNotification > 0 ? (
            <p
              className="notification_mark_text"
              onClick={markNotificationsAsRead}
            >
              {t("dashboard.notification.marks_as_read")}
            </p>
          ) : null}
        </div>
        <NotificationsList />
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    notifications: state.notificationData.notificationData
  };
};

export default connect(mapStateToProps, null)(NotificationCenter);
