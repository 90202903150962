import ContentContainer from "Components/Containers/ContentContainer";
import { connect, useDispatch } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Button from "Components/Buttons/Button";
import React, { useEffect, useRef, useState } from "react";
import TableLoading from "Components/LoadingPlaceholders/TableLoading";
import { isAllowed, userPermissions, userRoles } from "utils/permissionsAllow";
import Modal from "react-modal";
import SimpleInput from "Components/SimpleComponents/SimpleInput";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import HrzoneEmployeeSelectedMenu from "Components/SelectedMenu/HrzoneSelectedMenu";
import axios from "axios";
import { urls } from "api/urls";
import { getToken } from "utils/cookies/tokensCookie";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import HrZoneCompanyList from "Components/Lists/hrZoneCompanyList";
import { useTranslation } from "react-i18next";
import { getHrZoneCompanies } from "redux/actions/corporateActions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

function HrZoneCompanies(props: any) {
  const dispatch = useDispatch();

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isMasterHr, setIsMasterHr] = useState(false);
  const [modalCompany, setModalCompany] = useState({
    name: "",
    street: "",
    zip: "",
    city: "",
    id: "",
  });

  const [clearSelections, setClearSelections] = useState(false);

  const {t} = useTranslation();

  const editCompany = async () => {
	  try{
      const res: any = await axios.post(
        urls.hrZone.base + "updateOffice",
        {
			  hr_email: props.user.email,
			  id: modalCompany.id,
			  name: modalCompany.name,
			  street: modalCompany.street,
			  city:modalCompany.city,
			  zip: modalCompany.zip,
			  sessionKey: props.hrZoneAuth.sessionKey
        },
        {
			  headers: { Authorization: `Bearer ${getToken()}` },
			  timeout: 10000
        }
		  );
		  if (res.status == 200 && res.data.success === 1) {
              setIsMasterHr(props.hrZoneAuth.isMasterHr === 1);
        setShowEditModal(false);
        setModalCompany({
			  name: "",
			  street: "",
			  zip: "",
			  city: "",
			  id: "",
        });
        toast.success(
			  <CustomToastContainer message={t("hrzone.toastMessages.edit.company.success")} status={1} />,
			  {
            autoClose: 5000
			  }
        );
        dispatch(
			  getHrZoneCompanies({
            perPage: 8,
            page: 1,
            hr_email: props.user.email,
            sessionKey: props.hrZoneAuth?.sessionKey,
			  })
        );
        setClearSelections(!clearSelections);
		  } else {
        toast.error(
			  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
			  {
            autoClose: 5000
			  }
        );
		  }
	  }catch(e){
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
        {
			  autoClose: 5000
        }
		  );
	  }
  };
  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = useState( Number(location.search.replace("?page=", "")) || 1);

  useEffect(() => {
    setCurrentPage(parsedSearch.page ? +parsedSearch.page : 1);
  }, [parsedSearch]);

  const deleteCompany = async() => {
	  try{
      const res: any = await axios.post(
        urls.hrZone.base + "deleteOffices",
        {
			  hr_email: props.user.email,
			  ids: props.selectedRows.map((company: any) => company.id),
			  sessionKey: props.hrZoneAuth.sessionKey
        },
        {
			  headers: { Authorization: `Bearer ${getToken()}` },
			  timeout: 10000
        }
		  );
		  if (res.status == 200 && res.data.success === 1) {
        setShowEditModal(false);
        setModalCompany({
          name: "",
          street: "",
          zip: "",
          city: "",
          id: "",
        });
        toast.success(
			  <CustomToastContainer message={t("hrzone.toastMessages.delete.company.success")} status={1} />,
			  {
            autoClose: 5000
			  }
        );
        dispatch(
			  getHrZoneCompanies({
            perPage: 8,
            page: currentPage,
            hr_email: props.user.email,
            sessionKey: props.hrZoneAuth?.sessionKey,
			  })
        );
        setClearSelections(!clearSelections);
        setShowDeleteModal(false);
		  } else {
        toast.error(
			  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
			  {
            autoClose: 5000
			  }
        );
		  }
	  }catch(e){
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
        {
			  autoClose: 5000
        }
		  );
	  }
  };


  const headerMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)) {
        setShowEditModal(false);
        setShowDeleteModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const editModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showEditModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px"
        }}
      >
        <img
          onClick={() => setShowEditModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>{t("hrzone.modals.company.edit.title")}</h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={t("hrzone.modals.company.edit.name")}
            isDisabled={false}
            inputValue={modalCompany.name}
            handleChange={(e) => {
              setModalCompany({ ...modalCompany, name: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={t("hrzone.modals.company.edit.street")}
            isDisabled={false}
            inputValue={modalCompany.street}
            handleChange={(e) => {
              setModalCompany({ ...modalCompany, street: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={t("hrzone.modals.company.edit.zip")}
            isDisabled={false}
            inputValue={modalCompany.zip}
            handleChange={(e) => {
              setModalCompany({ ...modalCompany, zip: e });
            }}
          />
		   <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"office"}
            placeholder={t("hrzone.modals.company.edit.city")}
            isDisabled={false}
            inputValue={modalCompany.city}
            handleChange={(e) => {
              setModalCompany({ ...modalCompany, city: e });
            }}
          />
        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end"
          }}
        >
          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600
            }}
            buttonType={"primary"}
            buttonLabel={t("hrzone.buttons.save")}
            buttonHandler={async () => await editCompany()}
          />
        </div>
      </div>
    </Modal>
  );

  const deleteModal = (
	  <Modal
	  style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showDeleteModal}>
		   <div
        ref={headerMenuRef}
        style={{
			  display: "flex",
			  flexDirection: "column",
			  alignItems: "center",
			  justifyContent: "space-between",
			  height: "100%",
			  padding: "40px"
        }}
		  >
			   <img
          onClick={() => setShowDeleteModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />
        <h1>{t("hrzone.modals.company.delete.multipleTitle")}</h1>
        <p style={{textAlign: "center", marginTop: "20px"}}>{t("hrzone.modals.company.delete.multipleText", {count: props.selectedRows.length})} </p>
		  {/* <div style={{display: "flex", flexWrap: "wrap",marginTop: "20px", maxHeight: "300px", overflowY: "scroll"}}>
	  		{props.selectedRows.map((row:any) => <div style={{ minWidth: "fit-content", margin: "8px"}}>{row.firstName + " " + row.lastName}</div>)}
		  </div> */}
		  <Button
          buttonStyles={{
            width: "50%",
            maxWidth: "100%",
            fontWeight: 600,
			  marginTop: "20px"
          }}
          buttonType={"primary"}
          buttonLabel={t("hrzone.buttons.delete")}
          buttonHandler={async () => await deleteCompany()}
        />
		  </div>

	  </Modal >
  );


  return (
    <ContentContainer>
      {deleteModal}
      {editModal}
      <div className="employee_page">
        <h2 style={{ marginBottom: "30px" }}>{t("hrzone.generalInfo.companies")}</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "30px"
          }}
        >
          {props.selectedRows.length > 0 && isMasterHr &&
          isAllowed({
            data: {
              roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.users]
              }
            }
          }) ? (
              <HrzoneEmployeeSelectedMenu
                data={props.selectedRows}
                user={props.user}
                type={"company"}
                openModal={(type) => {
                  if(type === "delete"){
                    setShowDeleteModal(true);
                  }
                  if(type === "edit"){
					  setModalCompany(props.selectedRows[0]);
                    setShowEditModal(true);
                  }
                }}
              />
            ) : (
              <div style={{ height: "40px" }} />
            )}
        </div>

        {!(props.employee && props.departments) ? (
          <TableLoading rowsNumber={8} />
        ) : (
          <HrZoneCompanyList clearSelections={clearSelections} employee={props.employee} user={props.user} hrZoneAuth={props.hrZoneAuth} isMasterHr={props.hrZoneAuth.isMasterHr} />
        )}
      </div>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.userInfo.user,
    employee: state.users.users,
    loading: state.users.loading,
    sidebar: state.sidebar.sidebarTabCondition,
    checkedBoxes: state.employeeCheckboxesState.selectedCheckboxes,
    departments: state.depsAndBranches.departments,
    selectedRows: state.selectedRows.selectedRows,
    hrZoneAuth: state.hrZone
  };
};

export default connect(mapStateToProps, null)(HrZoneCompanies);