import { getToken } from "../../utils/cookies/tokensCookie";
import environment from "../../environment";
import axios from "axios";
import { errorHandler } from "../../utils/errorHandler";
import {
  IAddNewBranch,
  IBranchNameChange,
  ICreateDepsAndBranches,
} from "../../Interfaces/IBranchAndDepartments";
import {urls} from "../../api/urls";

/*Post new branches and departments*/
export async function createDepsAndBranches(data: any) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.branchAndDepartments.departmentAndBranches}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const request = await axios.post(fullUrl, data, options);
    return request.data.toLowerCase();
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}

/*Update Branch*/
export async function updateBranch(data: IBranchNameChange, id: string) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.branchAndDepartments.branches}/${id}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const request = await axios.put(fullUrl, data, options);
    return request.status;
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}

/*Update Department*/
export async function updateDepartment(data: IBranchNameChange, id: string) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.branchAndDepartments.departments}/${id}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const request = await axios.put(fullUrl, data, options);
    return request.status;
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}

/*Delete Department*/
export async function deleteDepartment(id: string) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.branchAndDepartments.departments}/${id}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const request = await axios.delete(fullUrl, options);
    return request.status;
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}

/*Delete Branch*/
export async function deleteBranch(id: string) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.branchAndDepartments.branches}/${id}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const request = await axios.delete(fullUrl, options);
    return request.status;
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}

/*Add new department */
export async function addNewDepartment(
  data: IAddNewBranch,
  depsAndBr: ICreateDepsAndBranches
) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.branchAndDepartments.departments}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  // Create department
  try {
    const request = await axios.post(fullUrl, data, options);
    if (request.status === 201) {
      // Create deps and branch for new department
      try {
        depsAndBr.departmentId = request.data.department._id;
        depsAndBr.departmentName = request.data.department.name;
        const req = await axios.post(
          `${environment.baseUrl}${urls.branchAndDepartments.depsAndBranches}`,
          depsAndBr,
          options
        );
        return req.status;
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          return e.request.status;
        }
      }
    } else {
      return request.status;
    }
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}
