import { sidebarReducer } from "./sidebarReducer";
import { combineReducers } from "redux";
import { pageSearchDataReducer } from "./pageSearchReducer";
import { notificationDataReducer } from "./notificationDataReducer";
import { checkBoxReducer } from "./employeePageCheckboxReducer";
import { changeRowsReducer } from "./selectedUsersFromListReducer";
import { getMerchantsReducer } from "./getMerchantsReducer";
import { postMarketingReducer } from "./postMarketingReducer";
import { postDownloadFileReducer } from "./downloadFilesReducer";
import { addedPlansReducer } from "./addedPlansReducer";
import { usersReducer } from "./usersReducer";

import { chartsReducer } from "./chartsReducers";
import { plansReducer } from "./plansReducer";
import { departmentsAndBranchReducer } from "./branchAndDepartmentReducers";
import { companiesReducer } from "./companiesReducer";
import { rolesReducer } from "./rolesReducer";
import { invoicesReducer } from "./invoicesReducer";
import { authReducer } from "./authReducer";
import { filteringReducer } from "./filteringRecuder";
import { weavrReducer } from "./weavrReducer";
import { paymentsReducer } from "./paymentsReducers";
import { corporatesReducer } from "./corporateReducer";
import { hrZoneAuthReducer } from "./hrZoneAuthReducer";
import { stepsReducer } from "./stepsReducer";
import {tooltipReducer} from "./tooltipReducer";

export const rootReducer = combineReducers({
  sidebar: sidebarReducer,
  pagesSearch: pageSearchDataReducer,
  notificationData: notificationDataReducer,
  employeeCheckboxesState: checkBoxReducer,
  selectedRows: changeRowsReducer,
  merchants: getMerchantsReducer,
  marketingState: postMarketingReducer,
  downloadFileState: postDownloadFileReducer,
  addedPlans: addedPlansReducer,
  auth: authReducer,
  invoices: invoicesReducer,
  roles: rolesReducer,
  company: companiesReducer,
  depsAndBranches: departmentsAndBranchReducer,
  plans: plansReducer,
  charts: chartsReducer,
  users: usersReducer,
  filters: filteringReducer,
  weavr: weavrReducer,
  payments: paymentsReducer,
  corporates: corporatesReducer,
  hrZone: hrZoneAuthReducer,
  steps: stepsReducer,
  tooltip: tooltipReducer
});

export type RootState = ReturnType<typeof rootReducer>;
