// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.plan_billing_info {
    font-size: 12px;
}

.plan_billing_info > p {
    margin-bottom: 3px;
}

.plan_calendar_day_badge {
    display: flex;
    align-items: center;
}

.plan_calendar_day_badge > div {
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.plan_calendar_day_badge.sick > div {
    background-color: #c64422;
}
.plan_calendar_day_badge.holiday > div {
    background-color: #0E0A40;
}
.plan_calendar_day_badge.rest > div {
    background-color: #00acee;
}

.plan_calendar_day_badge > p {
    margin: 0;
    font-size: 12px;
    padding-left: 7px;
}

.plan_billing_heading {
    display: flex;
    flex-direction: column;
}

.plan_billing_heading > h6 {
    margin-top: 10px;
    font-size: 16px;
    margin-bottom: 5px;
}

.plan_billing_heading > p {
    font-size: 16px;
    color: #130F30;
    margin-bottom: 5px;
}

.plan_billing_heading_right {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
}
`, "",{"version":3,"sources":["webpack://./src/pages/plans/styles.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;;AAEA;IACI,SAAS;IACT,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,sBAAsB;AAC1B;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,cAAc;IACd,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,qBAAqB;AACzB","sourcesContent":[".plan_billing_info {\n    font-size: 12px;\n}\n\n.plan_billing_info > p {\n    margin-bottom: 3px;\n}\n\n.plan_calendar_day_badge {\n    display: flex;\n    align-items: center;\n}\n\n.plan_calendar_day_badge > div {\n    width: 10px;\n    height: 10px;\n    border-radius: 50%;\n}\n\n.plan_calendar_day_badge.sick > div {\n    background-color: #c64422;\n}\n.plan_calendar_day_badge.holiday > div {\n    background-color: #0E0A40;\n}\n.plan_calendar_day_badge.rest > div {\n    background-color: #00acee;\n}\n\n.plan_calendar_day_badge > p {\n    margin: 0;\n    font-size: 12px;\n    padding-left: 7px;\n}\n\n.plan_billing_heading {\n    display: flex;\n    flex-direction: column;\n}\n\n.plan_billing_heading > h6 {\n    margin-top: 10px;\n    font-size: 16px;\n    margin-bottom: 5px;\n}\n\n.plan_billing_heading > p {\n    font-size: 16px;\n    color: #130F30;\n    margin-bottom: 5px;\n}\n\n.plan_billing_heading_right {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-end;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
