// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.update_info_container {
    width: 100%;
    height: 100%;
    position: relative;
}

.update_info_hint {
    font-size: 11px;
    display: none;
    position: absolute;
    z-index: 2;
    width: max-content;
    min-width: 150px;
    min-height: 90px;
    bottom: 40px;
    right: 0;
    background-color: white;
    border-radius: 5px;
    box-shadow: rgba(149, 157, 165, 0.3) 0 8px 24px;
    padding: 20px;
}

@media (max-width: 1050px) {
    .update_info_hint {
        bottom: -100px;
        right: 100px;
    }
}

.update_info_container:hover .update_info_hint {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.update_info_grid {
    display: grid;
    grid-template-columns: 40% 60%;
    align-items: center;
    width: max-content;
}

.update_info_icon {
    width: 40px;
}

.update_info_title {
    font-size: 12px;
    font-weight: 600;
    color: #A3A6B4;
    width: 170px;
}

.update_info_text {
    margin-left: 7px;
    font-size: 12px;
    font-weight: 600;
    color: #707070;
}

@media (max-width: 1400px) {
    .update_info_hint {
        top: -50%;
        right: 110px;
        min-height: 220px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/UpdateStatus.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,aAAa;IACb,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,YAAY;IACZ,QAAQ;IACR,uBAAuB;IACvB,kBAAkB;IAElB,+CAA+C;IAC/C,aAAa;AACjB;;AAEA;IACI;QACI,cAAc;QACd,YAAY;IAChB;AACJ;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,8BAA8B;AAClC;;AAEA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,YAAY;AAChB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI;QACI,SAAS;QACT,YAAY;QACZ,iBAAiB;IACrB;AACJ","sourcesContent":[".update_info_container {\n    width: 100%;\n    height: 100%;\n    position: relative;\n}\n\n.update_info_hint {\n    font-size: 11px;\n    display: none;\n    position: absolute;\n    z-index: 2;\n    width: max-content;\n    min-width: 150px;\n    min-height: 90px;\n    bottom: 40px;\n    right: 0;\n    background-color: white;\n    border-radius: 5px;\n    -webkit-box-shadow: rgba(149, 157, 165, 0.3) 0 8px 24px;\n    box-shadow: rgba(149, 157, 165, 0.3) 0 8px 24px;\n    padding: 20px;\n}\n\n@media (max-width: 1050px) {\n    .update_info_hint {\n        bottom: -100px;\n        right: 100px;\n    }\n}\n\n.update_info_container:hover .update_info_hint {\n    display: flex;\n    flex-direction: column;\n    justify-content: space-between;\n}\n\n.update_info_grid {\n    display: grid;\n    grid-template-columns: 40% 60%;\n    align-items: center;\n    width: max-content;\n}\n\n.update_info_icon {\n    width: 40px;\n}\n\n.update_info_title {\n    font-size: 12px;\n    font-weight: 600;\n    color: #A3A6B4;\n    width: 170px;\n}\n\n.update_info_text {\n    margin-left: 7px;\n    font-size: 12px;\n    font-weight: 600;\n    color: #707070;\n}\n\n@media (max-width: 1400px) {\n    .update_info_hint {\n        top: -50%;\n        right: 110px;\n        min-height: 220px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
