import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import Checkbox from "../SimpleComponents/Checkbox";
import TableSortedHeadItem from "./TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import {handleEmployeeCheckBox} from "redux/actions/employeePageCheckboxActions";
import Spacer from "../SimpleComponents/Spacer";
import {changeSelectedRows} from "../../redux/actions/selectedUsersFromListActions";
import {useTranslation} from "react-i18next";
import {clearUserMessages, getUsers, inviteUser} from "../../redux/actions/usersActions";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import {useLocation, useNavigate} from "react-router-dom";
import queryString from "query-string";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {isAllowed, userPermissions, userRoles} from "../../utils/permissionsAllow";
import Tooltip from "../Tooltip/Tooltip";
import ActionDots, {IActionDotsAction} from "../Elements/ActionDots/ActionDots";
import {getToken} from "../../utils/cookies/tokensCookie";
import PaymentAuthenticationPopup from "../Payments/PaymentAuthenticationPopup/PaymentAuthenticationPopup";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import CardCreationLoadingModal from "../Modals/EmployeePage/CardCreationLoadingModal/CardCreationLoadingModal";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";
import Badge, {BadgeColorsEnum} from "../Elements/Badge/Badge";

interface CustomProps {
  employee: any;
  openModal: (state: boolean, data: any) => void;
  filtersState: any;
  searchValue: string;
  submitUsersDelete: (
    users: string[],
    state: { showWarning: boolean; type: string }
  ) => void;
  setNewUserModalState: (state: boolean) => void;
  search: string;
  clearSelections: boolean;
  updateClearSelections: (value: boolean) => void;
  company?: any;
  selectedRows?: any;
  usersMessagesState?: any;
  searchData?: any;
  departments?: any;
}

interface IDeleteFunction {
  showWarning: boolean;
  type: string;
  userId: string;
}

function EmployeeList({
  employee,
  searchData,
  searchValue,
  usersMessagesState,
  setNewUserModalState,
  company,
  clearSelections,
  updateClearSelections,
  filtersState,
  openModal,
  submitUsersDelete
}: CustomProps) {
  interface Lookup {
    [index: string]: string;
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const parsedSearch = queryString.parse(location.search);

  const [withVirtualCard, setWithVirtualCard] = useState(false);
  const [pageLimit, setPageLimit] = useState(8);
  const [showCustomIdentifierColumn, setShowCustomIdentifierColumn] = useState(false);
  const [showEmploymentTypeColumn, setShowEmploymentTypeColumn] = useState(false);
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    firstName: "up",
    lastName: "up",
    email: "up"
  });
  const [sortValue, setSortValue] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showLoading, setShowLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [deactivateUserState, setDeactivateUserState] = useState<any>(null);
  const [showWeavrLoginPopup, setShowWeavrLoginPopup] = useState(false);
  const [loginPopupReason, setLoginPopupReason] = useState<"card" | "invite" | "deactivate">("card");
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null);
  const [showCardCreationLoading, setShowCardCreationLoading] = useState(false);

  const currentPosts = sortedData;
  const lunchSettings = new getLunchSettings();

  const actions: IActionDotsAction[] = [
    {
      key: "edit",
      imageKey: "editUser",
      imageAlt: "edit user",
      label: t("dashboard.employee.employee_menu_2"),
      onClick: (item: any) => openModal(true, item)
    },
    {
      key: "delete",
      imageKey: "deactivateUser",
      imageAlt: "delete user",
      label: t("dashboard.employee.employee_menu_3"),
      onClick: (item: any) => deleteUsersHandler({ showWarning: true, type: "delete", userId: item._id }),
      hide: (item: any) => !item.isActive
    },
    {
      key: "restore",
      imageKey: "restoreUser",
      imageAlt: "restore user",
      label: t("dashboard.employee.employee_menu_5"),
      onClick: (item: any) => deleteUsersHandler({ showWarning: false, type: "restore", userId: item._id }),
      hide: (item: any) => item.isActive
    },
    {
      key: "createPlan",
      imageKey: "createPlan",
      imageAlt: "create plan",
      label: t("dashboard.employee.employee_menu_4"),
      onClick: (item: any) => navigate("/plans/create"),
      imageStyle: {height: "25px", width: "25px", marginRight: "-3px", marginLeft: "-5px"}
    },
    {
      key: "inviteUser",
      imageKey: "inviteUser",
      imageAlt: "invite user",
      label: t("dashboard.employee.employee_menu_invite"),
      onClick: (item: any) => sendUserInvite(item.email),
      imageStyle: {height: "13px", width: "25px", marginLeft: "-5px", marginRight: "-5px"},
      hide: (item: any) => item.isActivated
    },
    {
      key: "manageCard",
      imageKey: "manageCard",
      imageAlt: "manage card",
      label: t("dashboard.employee.employee_menu_card_manage"),
      onClick: (item: any) => navigate(`/payments/overview/${item.restaurantCardId ?? item.cardId}`),
      hide: (item: any) => getCardButtonState(item) !== "manage" || !lunchSettings.isVirtualCard()
    },
    {
      key: "createCard",
      imageKey: "manageCard",
      imageAlt: "create card",
      label: t("dashboard.employee.employee_menu_card_create"),
      onClick: (item: any) => createCard(item._id),
      hide: (item: any) => getCardButtonState(item) !== "create" || !lunchSettings.isVirtualCard()
    },
    {
      key: "inviteAuthUser",
      imageKey: "inviteUser",
      imageAlt: "invite auth user",
      label: t("dashboard.employee.employee_menu_invite_as_authorized_user"),
      imageStyle: {height: "13px", width: "25px", marginLeft: "-5px", marginRight: "3px"},
      onClick: (item: any) => sendUserAuthorisationUserInvite(item._id),
      hide: (item: any) => !showInviteToAuthorizedUserAccount(item) || !lunchSettings.isVirtualCard()
    }
  ];

  /**
   * Hook to listen to checkboxes (checkedItems) and currently displayed rows (currentPosts).
   * Used to detect if all displayed rows are selected to handle the checkAll checkbox on top.
   */
  useEffect(() => {
    setCheckAll(getCheckAll());
  }, [checkedItems, currentPosts]);
  useEffect(() => {
    if (
      company &&
      company.company &&
      company.company.company &&
      company.company.company.virtualCard
    ) {
      setWithVirtualCard(true);
    }
  }, [company]);
  useEffect(() => {
    setCurrentPage(parsedSearch.page ? +parsedSearch.page : 1);
  }, [parsedSearch]);
  useEffect(() => {
    if (employee.users) {
      //Check if received user quantity is higher than current pageLimit
      if (employee.users.length > pageLimit) {
        //Cut away all unused users
        employee.users.splice(pageLimit, employee.users.length);
      }
      setSortedData(employee.users);
    }
  }, [employee.users]);
  useEffect(() => {
    if (clearSelections) {
      setCheckLoading(true);
      setCheckedItems([]);
      dispatch(changeSelectedRows);
      updateClearSelections(false);
      setCheckLoading(false);
    }
  }, [clearSelections]);
  // Handle response messages
  useEffect(() => {
    if (usersMessagesState.success === "delete_user") {
      dispatch(
        getUsers({
          limit: pageLimit,
          skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * pageLimit
        })
      );
      dispatch(clearUserMessages());
      dispatch(handleEmployeeCheckBox([]));
      dispatch(changeSelectedRows([]));
    } else if (usersMessagesState.success === "add_one_employee") {
      setNewUserModalState(false);
      dispatch(
        getUsers({
          limit: pageLimit,
          skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * pageLimit
        })
      );
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_created")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
      dispatch(clearUserMessages());
    } else if (usersMessagesState.error) {
      if (usersMessagesState.error === "user_exist") {
        toast.error(
          <CustomToastContainer
            message={t("warnings.warning_user_exists")}
            status={3}
          />,
          {
            autoClose: 8000
          }
        );
        dispatch(clearUserMessages());
      } else {
        setNewUserModalState(false);
        if (usersMessagesState.error === "delete_user_admin") {
          toast.error(
            <CustomToastContainer
              message={t("warnings.last_admin")}
              status={3}
            />,
            {
              autoClose: 5000
            }
          );
          dispatch(clearUserMessages());
        } else if (usersMessagesState.error === "update_employee") {
          toast.error(
            <CustomToastContainer
              message={t("formik_warnings.general_info.phoneNumber_weavr_validation")}
              status={3}
            />,
            {
              autoClose: 5000
            }
          );
          dispatch(clearUserMessages());
        } else if (usersMessagesState.error === "delete_user") {
          setShowWeavrLoginPopup(true);
          dispatch(clearUserMessages());
        } else {
          toast.error(
            <CustomToastContainer
              message={t("warnings.warning_server_error")}
              status={3}
            />,
            {
              autoClose: 5000
            }
          );
          dispatch(clearUserMessages());
        }
      }
    } else if (usersMessagesState.success === "update_employee") {
      dispatch(clearUserMessages());
      dispatch(
        getUsers({
          limit: pageLimit,
          skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * pageLimit
        })
      );
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_updated")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
    } else if (usersMessagesState.success === "invite_users") {
      dispatch(clearUserMessages());
      toast.success(
        <CustomToastContainer
          message={t("warnings.send_invite_success")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
    } else if (usersMessagesState.error === "update_employee") {
      dispatch(clearUserMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.warning_server_error")}
          status={3}
        />,
        {
          autoClose: 5000
        }
      );
    }
  }, [usersMessagesState]);
  useEffect(() => {
    if (searchData.length > 0) {
      setSortedData(searchData);
    }
  }, [searchData]);
  /**
   * Hook to update Redux on checked Items change
   */
  useEffect(() => {
    dispatch(changeSelectedRows(checkedItems));
    setShowLoading(false);
  }, [checkedItems, currentPosts]);

  // Check if custom identifier presented in list
  useEffect(() => {
    if (currentPosts?.length > 0) {
      const usersWithCustomIdentifier = currentPosts.filter((user:any) => user.customIdentifier);
      if (usersWithCustomIdentifier?.length > 0) {
        setShowCustomIdentifierColumn(true);
      } else {
        setShowCustomIdentifierColumn(false);
      }
      const usersWithEmploymentType = currentPosts.filter((user:any) => user.employmentType);
      if (usersWithEmploymentType?.length > 0) {
        setShowEmploymentTypeColumn(true);
      } else {
        setShowEmploymentTypeColumn(false);
      }
    }
  }, [currentPosts]);

  // Change limits of items in page
  const changePageLimit = (newLimit: number) => {
    if (newLimit !== pageLimit) {
      dispatch(getUsers({...filtersState, limit: newLimit, skip: 0}));
      setPageLimit(newLimit);
    }
  };

  const sortingData = (name: string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortingValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortingValue === "up" ? "" : "-"}${name}`;
    setSortValue(postValue);
    dispatch(
      getUsers({
        limit: pageLimit,
        skip: ((parsedSearch.page ? +parsedSearch.page : 1) - 1) * pageLimit,
        search: searchValue,
        sortBy: postValue
      })
    );
    setSortButton({ ...sortButton, [name]: sortingValue });
  };

  // handle Paginate
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      //   navigate(`/employee?page=${pageNumber}`);
      navigate(location.pathname + `?page=${pageNumber}`);
      const skip = (pageNumber - 1) * pageLimit;
      setShowLoading(true);
      dispatch(
        getUsers({
          limit: pageLimit,
          skip: skip,
          search: searchValue,
          sortBy: sortValue
        })
      );
    }
  };

  const getStatusTooltip = (isActiveStatus: boolean, isActivatedStatus: boolean) => {
    if (isActiveStatus) {
      if (isActivatedStatus) {
        return t("dashboard.employee.list.tooltips.active");
      } else {
        return t("dashboard.employee.list.tooltips.not_activated");
      }
    } else {
      return t("dashboard.employee.list.tooltips.not_active");
    }
  };

  /**
   * Function to handle all checkbox changes. Used for toggling all checkboxes at the same time.
   */
  const handleCheckAll = () => {
    if (!checkLoading) {
      setCheckLoading(true);
      setCheckAll(!checkAll);
      if (checkAll) {
        //Remove current displayed plans
        currentPosts.forEach((post: any) => {
          if (checkedItems?.length > 0 && getCheckedIds().includes(post._id)) {
            const index = getCheckedIds().indexOf(post._id);
            if (index !== -1) {
              checkedItems.splice(index, 1);
            }
          }
        });
        setCheckedItems([...checkedItems]);
      } else {
        //Add current displayed plans
        currentPosts.forEach((post: any) => {
          if (!getCheckedIds().includes(post._id)) {
            setCheckedItems((oldArray) => [...oldArray, post]);
          }
        });
      }
    }
    setCheckLoading(false);
  };

  /**
   * Function to handle single checkbox changd. Used for toggling checkbox states.
   * @param item - any
   */
  const handleCheckboxChange = (item: any) => {
    if (checkedItems?.length > 0 && getCheckedIds().includes(item._id)) {
      //Getting index to remove the item from checkedItems
      const index = getCheckedIds().indexOf(item._id);
      if (index !== -1) {
        checkedItems.splice(index, 1);
      }
      setCheckedItems([...checkedItems]);
    } else {
      //Adding the item to checkedItems since  it's not in there yet
      setCheckedItems([...checkedItems, item]);
    }
  };

  /**
   * Helper function to get the IDs of all selected rows
   * @returns Array of IDs
   */
  const getCheckedIds = () => {
    return checkedItems.map((item: any) => item._id);
  };

  const getCheckAll = () => {
    let count = 0;
    //count all selected rows and unavailable rows to detect if all available rows are selected
    currentPosts.forEach((post: any) => {
      if (getCheckedIds().includes(post._id)) {
        count++;
      }
    });
    if (
      count === currentPosts.length &&
      !showLoading &&
      currentPosts.length > 0
    ) {
      return true;
    } else {
      return false;
    }
  };

  const deleteUsersHandler = async (state: IDeleteFunction) => {
    setSelectedUserId(state.userId);
    const weavrToken = await getToken(true);
    setDeactivateUserState(state);
    setLoginPopupReason("deactivate");
    if (company.company.company.virtualCard && !weavrToken) {
      setShowWeavrLoginPopup(true);
    } else {
      submitUsersDelete([state.userId], state);
    }
  };

  const sendUserAuthorisationUserInvite = async (userId: string) => {
    setSelectedUserId(userId);
    const token = getToken();
    const weavrToken = getToken(true);
    if (!weavrToken) {
      setLoginPopupReason("invite");
      setShowWeavrLoginPopup(true);
    } else {
      await axios.post(`${environment.baseUrl}${urls.payments.inviteUserToAuthorisation}`,
        {
          token: weavrToken,
          userId: userId
        },
        {
          headers: {Authorization: `Bearer ${token}`}
        })
        .then((res: any) => {
          if (res.status === 200) {
            toast.success(
              <CustomToastContainer
                message={t("dashboard.payments.invite_to_authorised_user.statuses.success")}
                status={1}
              />,
              {
                autoClose: 5000
              }
            );
          }
        })
        .catch((err: any) => {
          console.error(err);
        });
    }
  };

  const createCard = async (userId: string) => {
    const fullUrl = `${environment.baseUrl}payments/cards/multiple`;

    const bearerToken = getToken();
    const weavrToken = await getToken(true);

    // If weavr token is expired, show login to weavr modal
    if (!weavrToken) {
      setShowWeavrLoginPopup(true);
    } else {
      setShowCardCreationLoading(true);
      const res = await axios.post(
        fullUrl,
        {token: weavrToken, users: [userId]},
        {
          headers: {Authorization: `Bearer ${bearerToken}`}
        }
      );

      if (res.status == 200) {
        setShowCardCreationLoading(false);
        toast.success(
          <CustomToastContainer
            message={t("dashboard.payments.create_card.statuses.success_single")}
            status={1}
          />,
          {
            autoClose: 5000
          }
        );
      }
    }
  };

  const sendUserInvite = (email: string) => {
    dispatch(
      inviteUser({
        email: email,
      })
    );
  };

  const getCardButtonState = (userData: any) => {
    let returnState = "manage";

    // If user inactive
    if (!userData.isActive || !userData.isActivated) {
      returnState = "hide";
    }

    // If user don't have mobile phone number
    if (!userData.phoneNumber || userData.phoneNumber.length < 1) {
      returnState = "hide";
    }

    // If user don't have card
    if (!userData.restaurantCardId && !userData.supermarketCardId && !userData.cardId && returnState !== "hide") {
      returnState = "create";
    }

    // If user has a card but already inactive
    if (returnState === "hide" && (userData.restaurantCardId || userData.supermarketCardId || userData.cardId)) {
      returnState = "manage";
    }

    return returnState;
  };

  const showInviteToAuthorizedUserAccount = (userData: any): boolean => {
    let state = false;
    if (company.company?.company?.virtualCard && userData.authorizedUserStatus !== "invitationConfirmed") {
      state = true;
    }
    return state;
  };

  return (
    <div>
      <CardCreationLoadingModal
        showModal={showCardCreationLoading}
      />
      {showWeavrLoginPopup
        &&
        <PaymentAuthenticationPopup
          showModal={showWeavrLoginPopup}
          onModalChange={(state: boolean) => setShowWeavrLoginPopup(state)}
          email={usersMessagesState.userInfo.user.email}
          onLoginSuccess={() => {
            if (loginPopupReason === "invite") {
              setShowWeavrLoginPopup(false);
              sendUserAuthorisationUserInvite(selectedUserId || "");
            } else if (loginPopupReason === "deactivate") {
              setShowWeavrLoginPopup(false);
              submitUsersDelete([selectedUserId || ""], deactivateUserState);
            } else {
              createCard(selectedUserId || "");
            }
          }}
          onCloseModal={() => {
            setShowWeavrLoginPopup(false);
          }}
        />
      }
      {employee.users && employee.users.length === 0 ? (
        <EmptyTableState
          style={{ height: "600px", backgroundColor: "transparent" }}
        />
      ) : (
        <div className="table_box">
          {showLoading ? (
            <TableLoading />
          ) : (
            <div className="table_wrapper-overview">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="pl-10">
                      <div className="row">
                        {isAllowed({
                          data: {
                            roles: [
                              userRoles.superAdmin,
                              userRoles.admin,
                              userRoles.custom
                            ],
                            permissions: {
                              isAdmin: true,
                              permissionsArray: [userPermissions.users]
                            }
                          }
                        }) ? (
                            <Checkbox
                              disabled={checkLoading}
                              id="selectAll"
                              handleCheckboxChange={() => handleCheckAll()}
                              checked={getCheckAll()}
                            />
                          ) : null}
                        <Spacer spacerWidth="1rem" />
                        <TableSortedHeadItem
                          sortingData={sortingData}
                          text={t("dashboard.home.employee_list_th_1")}
                          name="firstName"
                          sortButton={sortButton}
                        />
                      </div>
                    </th>
                    <th>
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={t("dashboard.home.employee_list_th_2")}
                        name="lastName"
                        sortButton={sortButton}
                      />
                    </th>
                    <th>
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={t("dashboard.home.employee_list_th_3")}
                        name="email"
                        sortButton={sortButton}
                      />
                    </th>
                    {company.company?.company?.virtualCard
                      ? <th>
                        <div className="responsive-mr-40">
                          {t("dashboard.home.employee_list_th_phone_number")}
                        </div>
                      </th>
                      : null
                    }
                    {showCustomIdentifierColumn
                      ? <th>
                        <div className="responsive-mr-40">
                          {t("dashboard.home.employee_list_th_custom_identifier")}
                        </div>
                      </th>
                      : null
                    }
                    {showEmploymentTypeColumn
                      ? <th>
                        <div className="responsive-mr-40">
                          {t("dashboard.home.employee_list_th_employment_type")}
                        </div>
                      </th>
                      : null
                    }
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.home.employee_list_th_7_1")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.home.employee_list_th_7")}
                      </div>
                    </th>
                    <th>
                      <div
                        className="responsive-mr-40"
                        style={{ textAlign: "center" }}
                      >
                        {t("dashboard.home.employee_list_th_9")}
                      </div>
                    </th>
                    <th>
                      <div
                        className="responsive-mr-40"
                        style={{ textAlign: "center" }}
                      >
                        {t("dashboard.home.employee_list_th_10")}
                      </div>
                    </th>
                    {withVirtualCard ? (
                      <th>
                        <div
                          className="responsive-mr-40"
                          style={{ textAlign: "center" }}
                        >
                          {t("dashboard.home.employee_list_th_cards")}
                        </div>
                      </th>
                    ) : null}
                    {isAllowed({
                      data: {
                        roles: [
                          userRoles.superAdmin,
                          userRoles.admin,
                          userRoles.custom
                        ],
                        permissions: {
                          isAdmin: true,
                          permissionsArray: [userPermissions.users]
                        }
                      }
                    }) ? (
                        <th style={{ padding: "0px" }}>
                          <div
                            className="responsive-mr-40"
                            style={{ textAlign: "center" }}
                          >
                            {t("dashboard.home.employee_list_th_5")}
                          </div>
                        </th>
                      ) : null}
					  <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((item: any, index: number) => (
                    <React.Fragment key={item._id}>
                      <tr className="spacer" />
                      <tr
                        className={
                          getCheckedIds().includes(item._id)
                            ? "selected_row"
                            : "table-item"
                        }
                      >
                        <td>
                          <div className="row">
                            {isAllowed({
                              data: {
                                roles: [
                                  userRoles.superAdmin,
                                  userRoles.admin,
                                  userRoles.custom
                                ],
                                permissions: {
                                  isAdmin: true,
                                  permissionsArray: [userPermissions.users]
                                }
                              }
                            }) ? (
                                <Checkbox
                                  id={item._id.toString()}
                                  handleCheckboxChange={() =>
                                    handleCheckboxChange(item)
                                  }
                                  checked={getCheckedIds().includes(item._id)}
                                />
                              ) : null}
                            <Spacer spacerWidth="1rem" />
                            <p className="list_check_text">{item.firstName}</p>
                          </div>
                        </td>
                        <td>{item.lastName}</td>
                        <td>{item.email}</td>
                        {company.company?.company?.virtualCard
                          ? <td>{item.phoneNumber}</td>
                          : null
                        }
                        {showCustomIdentifierColumn
                          ? <td>{item.customIdentifier}</td>
                          : null
                        }
                        {showEmploymentTypeColumn
                          ? item.employmentType
                            ? <td>{t(`dashboard.home.employmentType.${item.employmentType}`)}</td>
                            : <td></td>
                          : null
                        }
                        <td style={{ width: "14.66%" }}>{item.branch}</td>
                        <td>{item.department}</td>
                        <td>
                          <Badge
                            color={item.hasPlan ? BadgeColorsEnum.green : BadgeColorsEnum.red}
                            text={item.hasPlan ? t("dashboard.employee.active_employee") : t("dashboard.employee.inactive_employee")}
                          />
                        </td>
                        <td>
                          {/* eslint-disable-next-line max-len */}
                          <Tooltip
                            text={getStatusTooltip(
                              item.isActive,
                              item.isActivated
                            )}
                            style={{
                              display: "flex",
                              justifyContent: "center"
                            }}
                          >
                            <div
                              style={{ margin: "auto" }}
                              className={`status_dot ${
                                item.isActive
                                  ? item.isActivated
                                    ? "green"
                                    : "yellow"
                                  : "red"
                              }`}
                            />
                          </Tooltip>
                        </td>
                        {withVirtualCard ? (
                          <td>
                            <Badge
                              color={item.restaurantCardId || item.supermarketCardId || item.cardId ? BadgeColorsEnum.green : BadgeColorsEnum.red}
                              text={item.restaurantCardId || item.supermarketCardId || item.cardId ? t("dashboard.employee.active_employee") : t("dashboard.employee.inactive_employee")}
                            />
                          </td>
                        ) : null}
                        {isAllowed({
                          data: {
                            roles: [
                              userRoles.superAdmin,
                              userRoles.admin,
                              userRoles.custom
                            ],
                            permissions: {
                              isAdmin: true,
                              permissionsArray: [userPermissions.users]
                            }
                          }
                        }) ? (
                            <td style={{ padding: "0px" }}>
                              <div style={{ margin: "auto", width: 30 }}>
                                <ActionDots
                                  actions={actions}
                                  rootState={item}
                                />
                              </div>
                            </td>
                          ) : null}
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      {employee.users && employee.users.length === 0 && showLoading ? null : (
        <Pagination
          currentPage={currentPage}
          postsPerPage={pageLimit}
          totalPosts={employee && employee.totalUser}
          paginate={paginate}
          limit={pageLimit}
          changeLimits={(limit: number) => {
            setShowLoading(true);
            changePageLimit(limit);
          }}
        />
      )}
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    searchData: state.pagesSearch.employeeSearchData,
    selectedRows: state.selectedRows.selectedRows,
    usersMessagesState: state.users,
    company: state.company,
  };
};

export default connect(mapStateToProps, null)(EmployeeList);
