import "./Button.css";

import React from "react";
import BeatLoader from "react-spinners/BeatLoader";

interface ButtonProps {
  buttonType: "outlined" |
    "primary" |
    "primary with_tooltip" |
    "secondary" |
    "tertiary" |
    "login_btn" |
    "tertiary btn_lg align_form" |
    "tertiary btn_lg align_form mb-25" |
    "tertiary btn_lg";
  buttonLabel: string;
  buttonHandler?: () => void;
  submitButton?: boolean;
  buttonHtmlType?: "button" | "submit" | "reset";
  disabled?: boolean;
  fullWidth?: boolean;
  buttonStyles?: any;
  loading?: boolean;
  buttonIcon?: any;
}

const Button = ({
  buttonType,
  buttonLabel,
  buttonHandler,
  fullWidth,
  loading,
  disabled,
  submitButton,
  buttonHtmlType,
  buttonStyles,
  buttonIcon
}: ButtonProps) => {
  const clickHandler = (e: any) => {
    e.preventDefault();
    buttonHandler && buttonHandler();
  };

  const contentRenderer = () => {

    if (buttonIcon && buttonIcon.length > 0) {
      return <div className="center" >
        <div className="row">
          <img
            className="btn_icon"
            src={"/images/icon-" + buttonIcon + ".svg"}
            alt={buttonIcon}
          />
          {buttonLabel}
        </div>
      </div>;
    } else {
      return buttonLabel;
    }
  };

  if (submitButton) {
    return (
      <button
        style={buttonStyles}
        disabled={disabled || loading}
        type={buttonHtmlType}
        className={fullWidth ? buttonType + " btn_full" : buttonType + " btn"}
      >
        {loading ? <BeatLoader size="10px" color="white" /> : contentRenderer()}
      </button>
    );
  } else {
    return (
      <button
        disabled={disabled || loading}
        style={buttonStyles}
        type={buttonHtmlType}
        className={fullWidth ? buttonType + " btn_full" : buttonType + " btn"}
        onClick={(e: any) => clickHandler(e)}
      >
        {loading ? <BeatLoader size="10px" color="white" /> : contentRenderer()}
      </button>
    );
  }
};

export default Button;
