export const GET_NOTIFICATIONS_SUCCESS = "GET_NOTIFICATIONS_SUCCESS";
export const GET_NOTIFICATIONS_ERROR = "GET_NOTIFICATIONS_ERROR";


export type NotificationData = {
	"data": string[],
	"newNotification": number
}

export interface notificationSuccess {
	type: typeof GET_NOTIFICATIONS_SUCCESS
	payload: NotificationData
}

export interface notificationError {
	type: typeof GET_NOTIFICATIONS_ERROR,
	payload: boolean
}


export type notificationDataDispatchTypes =
	notificationSuccess | notificationError
