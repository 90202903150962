import React from "react";
import "./PaymentsStepper.css";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, { stepConnectorClasses } from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import {useTranslation} from "react-i18next";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import BusinessIcon from "@mui/icons-material/Business";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import MarkEmailReadIcon from "@mui/icons-material/MarkEmailRead";
import PlayCircleFilledWhiteIcon from "@mui/icons-material/PlayCircleFilledWhite";
import SmsIcon from "@mui/icons-material/Sms";
import LoginIcon from "@mui/icons-material/Login";
import DomainIcon from "@mui/icons-material/Domain";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import MarkEmailUnreadIcon from "@mui/icons-material/MarkEmailUnread";
import FingerprintIcon from "@mui/icons-material/Fingerprint";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import {VirtualCardOnboardingStep} from "../../enums/weavrEnums";
import testIcon from "../../img/arrow.png";

interface IProps {
  currentStep: VirtualCardOnboardingStep; // number;
}

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#1EC0A6",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundColor: "#1EC0A6",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")<{
  ownerState: { completed?: boolean; active?: boolean };
}>(({ theme, ownerState }) => ({
  backgroundColor: "#ffffff",
  zIndex: 1,
  color: "#ccc",
  width: 40,
  height: 40,
  display: "flex",
  borderRadius: "50%",
  border: !ownerState.completed && !ownerState.active ? "4px solid #cccc" : "0px solid #ffffff",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundColor: "#0E0A40",
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    color: "#ffffff"
  }),
  ...(ownerState.completed && {
    backgroundColor: "#1EC0A6",
    color: "#ffffff"
  }),
}));

function ColorlibStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  const icons: { [index: string]: React.ReactElement } = {
    1: <HelpOutlineIcon />,
    2: <DomainAddIcon />,
    3: <VpnKeyIcon />,
    4: <MarkEmailReadIcon />,
    5: <LoginIcon />,
    6: <SmsIcon />,
    7: <DomainIcon />,
    8: <MarkEmailUnreadIcon />,
    9: <FingerprintIcon />,
    10: <ContentPasteSearchIcon />,
  };

  return (
    <ColorlibStepIconRoot ownerState={{ completed, active }} className={className}>
      {/*<img alt={"12"} src={testIcon} />*/}
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}


export default function PaymentsStepper({currentStep}:IProps) {
  const { t } = useTranslation();

  const steps = [
    t("dashboard.card_onboarding.stepper.info"),
    t("dashboard.card_onboarding.stepper.create_company"),
    t("dashboard.card_onboarding.stepper.password"),
    t("dashboard.card_onboarding.stepper.verify"),
    t("dashboard.card_onboarding.stepper.login"),
    t("dashboard.card_onboarding.stepper.verifySms"),
    t("dashboard.card_onboarding.stepper.kyb"),
    t("dashboard.card_onboarding.stepper.kyc_email"),
    t("dashboard.card_onboarding.stepper.kyc"),
    t("dashboard.card_onboarding.stepper.review"),
  ];

  const keys = Object.values(VirtualCardOnboardingStep);

  return(
    <Stack sx={{ width: "100%" }} spacing={4}>
      <Stepper alternativeLabel activeStep={keys.indexOf(currentStep)} connector={<ColorlibConnector />}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel StepIconComponent={ColorlibStepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </Stack>
  );
}
