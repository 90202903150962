import React, {useEffect, useState} from "react";
import "./CardDetailTopSection.css";
import {useTranslation} from "react-i18next";
import {Bar, Line} from "react-chartjs-2";
import {getToken} from "../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../environment";
import {urls} from "../../../../../api/urls";
import Lottie from "react-lottie";
import emptyStateImg from "../../../../../img/Dashboard/Orders/empty_table_img.svg";

export default function MonthlySpendTopSectionCard() {
  const { t } = useTranslation();

  const [monthlyLabels, setMonthlyLabels] = useState([]);
  const [monthlyData, setMonthlyData] = useState({
    total: [],
    restaurant: [],
    supermarket: [],
    unknown: []
  });
  const [loading, setLoading] = useState(true);
  const [showEmptyState, setShowEmptyState] = useState(false);

  const getMonthlyStatistic = async () => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    const response = await axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.cardsMonthlyStatistic}/${location.pathname.split("/")[3]}?token=${weavrToken}`,
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    if (response && response.data && response.data.data) {
      const labels:any = [];
      const data:any = {
        total: [],
        restaurant: [],
        supermarket: [],
        unknown: []
      };
      response.data.data.map((dataEntry:any) => {
        const months = dataEntry.months.includes("0") && dataEntry.months !== "10"
          ? dataEntry.months.slice(1,2)
          : dataEntry.months;
        const monthsLabel = t(`charts.summary_chart.month_${months}`);
        labels.push(monthsLabel);
        Object.keys(dataEntry).map((key:string) => {
          if (key !== "months") {
            data[key].push(+dataEntry[key].toFixed(2));
          }
        });
      });
      setMonthlyLabels(labels);
      setMonthlyData(data);
      // Get sum of all days and if it's 0 => show empty state for table
      const sumOfAll = data.total.reduce((prevVal: number, currVal: number) => prevVal + currVal);
      if (sumOfAll === 0) {
        setShowEmptyState(true);
      }
      setLoading(false);
    }
  };

  useEffect(() => {
    getMonthlyStatistic();
  }, []);

  const data = {
    labels: monthlyLabels,
    datasets: [
      {
        barPercentage: 0.9,
        categoryPercentage: 0.5,
        label: "total",
        data: monthlyData.total,
        backgroundColor: "#FF5242",
      },
      {
        barPercentage: 0.9,
        categoryPercentage: 0.5,
        label: t("charts.branches_chart.restaurant"),
        data: monthlyData.restaurant,
        backgroundColor: "#8AC142",
      },
      {
        barPercentage: 0.9,
        categoryPercentage: 0.5,
        label: t("charts.branches_chart.supermarket"),
        data: monthlyData.supermarket,
        backgroundColor: "#946EC6",
      },
      {
        barPercentage: 0.9,
        categoryPercentage: 0.5,
        label: "unknown",
        data: monthlyData.unknown,
        backgroundColor: "#a4a75a",
      }
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cornerRadius: 30,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          const item = data["datasets"][tooltipItem.datasetIndex];
          return `${item.label} ${+item.data[tooltipItem.index]} €`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          gridLines: {
            drawOnArea: false,
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            suggestedMin: 0,
            fontColor: "#7C828A",
            callback: function (value: any, index: any, values: any) {
              return `€ ${value}`;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnArea: false,
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            suggestedMax: 1000,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: 200,
          },
        },
      ],
    },
  };

  const lottieOptions = {
    loop: true,
    autoplay: true,
    animationData: require("img/animations/chart_loading.json"),
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice"
    }
  };

  return(
    <div className="card_detail_top_section_container">
      <div className="space-between" style={{alignItems: "flex-start"}}>
        <div>
          <p className="card_detail_top_section_container_title">
            {t("dashboard.payments.card_detail.cards.monthly.title")}
          </p>
        </div>

        <div className="card_detail_top_section_container_label_container">
          <p className="card_detail_top_section_container_label">
            {t("dashboard.payments.card_detail.cards.monthly.restaurant")}
          </p>
          <p className="card_detail_top_section_container_label supermarket">
            {t("dashboard.payments.card_detail.cards.monthly.supermarket")}
          </p>
        </div>
      </div>

      <div style={{
        marginTop: "15px",
        height: "200px"
      }}>
        {loading
          ? <Lottie options={lottieOptions}/>
          : showEmptyState
            ? <div className="center">
              <img src={emptyStateImg} alt="empty_table"/>
            </div>
            : <Bar data={data} options={options} />
        }
      </div>

    </div>
  );
}
