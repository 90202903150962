import {decryptWithAES, encryptWithAES} from "../../permissionsAllow";
import {removeUserPermission, removeUserPermissionType, removeUserRoles} from "../userRolesCookies";
import customHistory from "../../../customHistory";
import {IGetUserId} from "./userCookies.interface";

export function setUserId(info: any) {
  const encryptedRole = encryptWithAES(info.toString());
  localStorage.setItem("lunch_user_id", encryptedRole);
}

export function setMagentoData(data: any) {
  const encryptedRole = encryptWithAES(JSON.stringify(data));
  localStorage.setItem("lunch_magento_data", encryptedRole);
}

export function removeUserId() {
  localStorage.removeItem("lunch_user_id");
}

export function setUserCompany(company: any) {
  const encryptedRole = encryptWithAES(company.toString());
  localStorage.setItem("lunch_user_company", encryptedRole);
}

export function removeUserCompany() {
  localStorage.removeItem("lunch_user_company");
}

export function getUserId(params?: IGetUserId) {
  const id = localStorage.getItem("lunch_user_id");
  if (id) {
    return params?.withSplit
	  ? decryptWithAES(id).split(" ")
	  : decryptWithAES(id);

  } else {
    if (!params?.ignoreLogic) {
      removeUserPermission();
      removeUserPermissionType();
      removeUserRoles();
      removeUserId();
      removeUserCompany();
      sessionStorage.clear();
      localStorage.clear();
      customHistory.push({
        pathname: "/login"
      });
    } else {
      return false;
    }
  }
}

export function getUserCompany() {
  const company = localStorage.getItem("lunch_user_company");
  if (company) {
    return decryptWithAES(company);
  } else {
    removeUserPermission();
    removeUserPermissionType();
    removeUserRoles();
    removeUserId();
    removeUserCompany();
    sessionStorage.clear();
    localStorage.clear();
    customHistory.push({
      pathname: "/login",
      search: "?error=permission"
    });
  }
}

export function getMagentoData() {
  const magentoData = localStorage.getItem("lunch_magento_data");
  if (magentoData) {
    return JSON.parse(decryptWithAES(magentoData));
  }
  return false;
}

