import React, {useEffect, useState} from "react";
import "../payments.css";
import image from "../../../img/Dashboard/Card/weavr_onboarding_login.svg";
import {getToken, setToken} from "../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import {useTranslation} from "react-i18next";
import MainSpace from "../../../Components/Containers/MainSpace";
import ContentContainer from "../../../Components/Containers/ContentContainer";
import {VirtualCardOnboardingStep} from "../../../enums/weavrEnums";
import {useNavigate} from "react-router-dom";

interface IProps {
  data:any;
  handleSlider: () => void;
  step: VirtualCardOnboardingStep;
}

export default function WeavrStepperLogin(props:IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [refLogin, setRefLogin] = useState(null);
  const [showButtonLoading, setShowButtonLoading] = useState(false);

  useEffect(() => {
    const createdForm = (window as any).OpcUxSecureClient.form();
    setRefLogin(createdForm);
    const input = createdForm.input(
      "passcode",
      "passCode",
      {
        placeholder: "Passwort"
      }
    );
    input.mount(document.getElementById("passcode"));
  }, []);

  const login = async (tokenizedCode:any) => {
    setShowButtonLoading(true);
    const token = await getToken();
    try {
      const response = await axios.post(`${environment.baseUrl}${urls.payments.login}`,
        {
          email: props.data.rootUser.email,
          password: tokenizedCode,
          stepper: props.step !== VirtualCardOnboardingStep.INITIAL
        },
        {
          headers: { Authorization: `Bearer ${token}` }
        });
      if (response.data && response.data.token) {
        setShowButtonLoading(false);
        setToken(response.data.token, true);
        navigate("/payments/?verify");
        props.handleSlider();
      }
    } catch (e) {
      setShowButtonLoading(false);
      toast.error(
        <CustomToastContainer
          message={t("login.server_declined")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const onSubmit = (e:any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    refLogin.tokenize(function(tokens) {
      if (tokens && tokens.passcode) {
        login(tokens.passcode);
      }
    });
  };

  return(
    <ContentContainer>
      <MainSpace>
        <div className="center">
          <form ref={refLogin} onSubmit={(e) => onSubmit(e)} className="weavr_passcode_container">
            <div>
              <div className="weavr_login_container_content">
                <h1>{t("dashboard.payments.onboarding.stepper.login.title")}</h1>
                {/*<p>{t("dashboard.payments.onboarding.stepper.login.text")}</p>*/}
                <div style={{display: "none"}}>
                  Username: <input type="input" value={props.data.rootUser.email} name="u" /><br/>
                </div>
                <div className="weavr_passcode_input_box">
                  Passwort <div id="passcode"></div><br/>
                </div>
                <input
                  className="weavr_passcode_btn"
                  type="submit"
                  value={t("dashboard.payments.onboarding.stepper.login.btn")}
                />
              </div>
            </div>
            <div>
              <img src={image} alt="login"/>
            </div>
          </form>
        </div>
      </MainSpace>
    </ContentContainer>
  );
}
