import React, {useEffect, useState} from "react";
import "./employeePaymentsCardTab.css";
import {useTranslation} from "react-i18next";
import TableLoading from "../../../Components/LoadingPlaceholders/TableLoading";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import {getToken} from "../../../utils/cookies/tokensCookie";
import TransactionsList from "../../../Components/Lists/TransactionsList";
import {useDispatch} from "react-redux";
import {getTransactions} from "../../../redux/actions/paymentsActions";
import CardDetailTopSectionCard
  from "../../../Components/Pages/Payments/CardDetail/TopSections/CardDetailTopSectionCard";

interface IProps {
  cardId: string;
  setShowPaymentsAuthModal: (state: boolean) => void;
}

export default function EmployeePaymentsCardTab(props: IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);
  const [card, setCard] = useState<any>(undefined);

  useEffect(() => {
    setLoading(true);
    getCard();
    if (props.cardId) {
      dispatch(getTransactions({
        id: props.cardId,
        limit: 8,
        skip: 0,
        sortBy: "DESC",
        backoffice: true
      }));
    }
  }, [props.cardId]);

  const getCard = async () => {
    const res = await axios.get(
      `${environment.baseUrl}${urls.payments.getManagedCardBackOffice}/${props.cardId}`,
      {
        headers: {Authorization: `Bearer ${getToken()}`}
      }
    );
    if (res && res.status === 200) {
      setCard(res.data);
      setLoading(false);
    }
  };

  return(
    <div>

      {loading
        ? <TableLoading />
        : <div >
          <div className="space-between">
            <div className="payments_card_detail_header">
              <h1 style={{margin: "16px 0"}}>
                {t("dashboard.payments.card_detail.title", {name: card.friendlyName})}
              </h1>
              <h2 style={{marginBottom: "16px"}}>
                {t("dashboard.payments.card_detail.description")}
              </h2>
            </div>
          </div>

          <CardDetailTopSectionCard
            card={card}
            setShowPaymentsAuthModal={props.setShowPaymentsAuthModal}
          />

          <div style={{height: "30px"}} />

          <TransactionsList
            cardId={props.cardId}
          />

        </div>
      }

    </div>
  );
}
