import {
  CHANGE_CORPORATE_VALUES,
  CHANGE_INTERN_VALUES,
  CHANGE_LOCAL_VALUES,
  CHANGE_STEP,
  CHANGE_VALUES, CLEAR_VALUES_MESSAGES,
  GET_VALUES,
  SEND_VALUES,
  stepsActionTypes, UPDATE_VALUES, VALUES_ERROR
} from "../types/stepsTypes";
import {Dispatch} from "redux";
import axios from "axios";
import {errorHandler} from "../../utils/errorHandler";
import {urls} from "../../api/urls";
import environment from "../../environment";

// Change step number
export function changeStep(step:number) {
  return {
    type: CHANGE_STEP,
    payload: step
  };
}

// Change values
export function changeValues(values:any, type:number) {
  if (type === 0) {
    return {
      type: CHANGE_VALUES,
      payload: values
    };
  } else if (type === 1) {
    return {
      type: CHANGE_CORPORATE_VALUES,
      payload: values
    };
  } else if (type === 4) {
    return {
      type: CHANGE_LOCAL_VALUES,
      payload: values
    };
  } else {
    return {
      type: CHANGE_INTERN_VALUES,
      payload: values
    };
  }
}

// Send new checklist
export const sendValues = (data:any) =>
  async (dispatch: Dispatch<stepsActionTypes>) => {
    const options = {headers: {"Content-Type": "application/json"}};
    const fullUrl = `${environment.baseUrl}${urls.general.checklists}`;
    try {
      const response = await axios.post(fullUrl , data, options);
      dispatch({
        type: SEND_VALUES,
        payload: response.data._id
      });
    } catch (e:any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: VALUES_ERROR,
          payload: "error"
        });
      }
    }
  };

// Get checklist values
export const getValues = (id:string) =>
  async (dispatch: Dispatch<stepsActionTypes>) => {
    const options = {headers: {"Content-Type": "application/json"}};
    const fullUrl = `${environment.baseUrl}${urls.general.checklists}/${id}`;
    try {
      const response = await axios.get(fullUrl, options);
      dispatch({
        type: GET_VALUES,
        payload: response.data
      });
    } catch (e:any) {
      if (!errorHandler(e.request.status)) {
        console.error(e);
      }
    }
  };

// Update checklist values
export const updateValues = (data:any, id:string) =>
  async (dispatch: Dispatch<stepsActionTypes>) => {
    const options = {headers: {"Content-Type": "application/json"}};
    const fullUrl = `${environment.baseUrl}${urls.general.checklists}/${id}`;
    try {
      const response = await axios.put(fullUrl, data, options);
      dispatch({
        type: UPDATE_VALUES,
        payload: "update_success"
      });
    } catch (e:any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: VALUES_ERROR,
          payload: "update_error"
        });
      }
    }
  };

// Clear messages
export function clearValuesMessages() {
  return {
    type: CLEAR_VALUES_MESSAGES
  };
}
