// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `main {
    width: 100%;
    min-height: 100%;
    padding: 20px;
    background-color: #F2F2F4;
}

@media (max-width: 1250px) {
    main {
        width: 100%;
        min-height: 100%;
        padding: 15px;
        background-color: #F2F2F4;
    }
}

@media (max-width: 600px) {
    main {
        width: 100%;
        min-height: 100%;
        padding: 13px;
        background-color: #F2F2F4;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/MainSpace.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,aAAa;IACb,yBAAyB;AAC7B;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,aAAa;QACb,yBAAyB;IAC7B;AACJ;;AAEA;IACI;QACI,WAAW;QACX,gBAAgB;QAChB,aAAa;QACb,yBAAyB;IAC7B;AACJ","sourcesContent":["main {\n    width: 100%;\n    min-height: 100%;\n    padding: 20px;\n    background-color: #F2F2F4;\n}\n\n@media (max-width: 1250px) {\n    main {\n        width: 100%;\n        min-height: 100%;\n        padding: 15px;\n        background-color: #F2F2F4;\n    }\n}\n\n@media (max-width: 600px) {\n    main {\n        width: 100%;\n        min-height: 100%;\n        padding: 13px;\n        background-color: #F2F2F4;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
