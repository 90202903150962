import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import * as Yup from "yup";
import {useTranslation} from "react-i18next";
import MainSpace from "../../../Components/Containers/MainSpace";
import VerticalDivider from "../../../Components/Containers/VerticalDivider";
import ContentContainer from "../../../Components/Containers/ContentContainer";
import FormikInput from "../../../Components/SimpleComponents/FormikInput";
import Button from "../../../Components/Buttons/Button";
import axios from "axios";
import SelectWithChoices from "../../../Components/SimpleComponents/SelectWithChoices";
import {
  companyTypes,
  countriesAbbreviations,
  industryTypes, phoneCodes,
  sourceOfFoundsTypes
} from "../../../constants/weavr.constants";
import Checkbox from "../../../Components/SimpleComponents/Checkbox";
import environment from "../../../environment";
import {getToken} from "../../../utils/cookies/tokensCookie";
import {useNavigate} from "react-router-dom";
import {RootState} from "../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import {urls} from "../../../api/urls";


interface IProps {
  handleSlider: () => void;
  user?: any;
  company?: any;
}

function OnboardingForm({handleSlider, user, company}:IProps) {

  const { t } = useTranslation();
  const navigate = useNavigate();

  const [buttonLoading, setButtonLoading] = useState(false);
  const [mainSpaceRatio, setMainSpaceRation] = useState("60% 10% 30%");
  const [userRatio, setUserRatio] = useState("50% 50%");
  const [mobileRatio, setMobileRatio] = useState("20% 5% 75%");
  const [branchRatio, setBranchRatio] = useState("1fr 1fr");
  const [companyPostalRatio, setCompanyPostalRatio] = useState("33% 33% 33%");
  const [translatedCompanyTypes, setTranslatedCompanyTypes] = useState([]);
  const [translatedIndustryTypes, setTranslatedIndustryTypes] = useState([]);
  const [translatedSourceOfFoundsTypes, setTranslatedSourceOfFoundsTypes] = useState([]);
  const [translatedCountriesAbbreviationsTypes,
    setTranslatedCountriesAbbreviationsTypes] = useState([]);
  const [validateForm, setValidateForm] = useState(false);

  // Get user IP
  useEffect( () => {
    const getIP = async () => {
      const res = await axios.get("https://geolocation-db.com/json/");
      if (res.data) {
        await formik.setFieldValue("ipAddress", res.data.IPv4, true);
      }
    };
    getIP();
    const newCompanyTypes:any = [];
    companyTypes.map((company:any) => {
      newCompanyTypes.push({
        value: company.value,
        label: t(company.label)
      });
    });
    setTranslatedCompanyTypes(newCompanyTypes);
    const newIndustryTypes:any = [];
    industryTypes.map((industry:any) => {
      newIndustryTypes.push({
        value: industry.value,
        label: t(industry.label)
      });
    });
    setTranslatedIndustryTypes(newIndustryTypes);
    const newSourceOfFoundsTypes:any = [];
    sourceOfFoundsTypes.map((found:any) => {
      newSourceOfFoundsTypes.push({
        value: found.value,
        label: t(found.label)
      });
    });
    setTranslatedSourceOfFoundsTypes(newSourceOfFoundsTypes);

    const newCountriesAbbreviationsTypes:any = [];
    countriesAbbreviations.map((country:any) => {
      newCountriesAbbreviationsTypes.push({
        value: country.value,
        label: t(country.label)
      });
    });
    setTranslatedCountriesAbbreviationsTypes(newCountriesAbbreviationsTypes);

    if (user && user.userInfo && user.userInfo.user) {
      formik.setFieldValue("rootUser.name", user.userInfo.user.firstName, true);
      formik.setFieldValue("rootUser.surname", user.userInfo.user.lastName, true);
      formik.setFieldValue("rootUser.email", user.userInfo.user.email, true);
    }

    if (company && company.company && company.company.company) {

      formik.setFieldValue(
        "company.businessAddress.addressLine1",
        company.company.company.companyInfo.streetName,
        true
      );
      formik.setFieldValue(
        "company.businessAddress.city",
        company.company.company.companyInfo.city,
        true
      );
      formik.setFieldValue(
        "company.businessAddress.postCode",
        company.company.company.companyInfo.zipCode,
        true
      );

      formik.setFieldValue("company.name", company.company.company.companyInfo.companyName, true);
      formik.setFieldValue(
        "tag",
        company.company.company.companyInfo.companyName.split(" ").join(""),
        true
      );
    }

  }, []);

  const formik = useFormik({
    initialValues: {
      profileId: "107160986245005325",
      tag: "", // corplife
      rootUser: {
        name: "", //Ersin
        surname: "", //Ergul
        email: "", // it@corplife.at
        mobile: {
          countryCode: "", // 356
          number: "" //{{corp_mobile}}
        },
        companyPosition: "AUTHORISED_REPRESENTATIVE" //AUTHORISED_REPRESENTATIVE
      },
      company: {
        type: "", // LLC
        businessAddress: {
          addressLine1: "", // Universitatstrasse 18
          addressLine2: "", // Address line 2
          city: "", // Vienna
          postCode : "", // VLT 1234
          country: "" // AT
        },
        name: "", // Corplife
        registrationNumber: "", // C8989
        registrationCountry: "" // AT
      },
      acceptedTerms: false, // true
      ipAddress: "81.16.47.34",
      industry: "", // ACCOUNTING
      sourceOfFunds: "", // LABOUR_CONTRACT
      baseCurrency: "EUR"
    },
    validationSchema: Yup.object({
      tag: Yup.string().required(t("formik_warnings.card_onboarding.tag")),
      rootUser: Yup.object().shape({
        name: Yup.string().required(t("formik_warnings.card_onboarding.firstName")),
        surname: Yup.string().required(t("formik_warnings.card_onboarding.lastName")),
        email: Yup.string().required(t("formik_warnings.card_onboarding.email"))
          .email(t("formik_warnings.card_onboarding.email_validation")),
        mobile: Yup.object().shape({
          countryCode: Yup.string().required(t("formik_warnings.card_onboarding.mobile_code")),
          number: Yup.string().required(t("formik_warnings.card_onboarding.mobile_number"))
        }),
        companyPosition: Yup.string().required(t("formik_warnings.card_onboarding.streetName")),
      }),
      company: Yup.object().shape({
        type: Yup.string().required(t("formik_warnings.card_onboarding.type")),
        businessAddress: Yup.object().shape({
          addressLine1: Yup.string().required(t("formik_warnings.card_onboarding.address_line_1")),
          addressLine2: Yup.string().required(t("formik_warnings.card_onboarding.address_line_2")),
          city: Yup.string().required(t("formik_warnings.card_onboarding.city")),
          postCode: Yup.string().required(t("formik_warnings.card_onboarding.postCode")),
          country: Yup.string().required(t("formik_warnings.card_onboarding.country"))
        }),
        name: Yup.string().required(t("formik_warnings.card_onboarding.tag")),
        registrationNumber: Yup.string()
          .required(t("formik_warnings.card_onboarding.registrationNumber")),
        registrationCountry: Yup.string()
          .required(t("formik_warnings.card_onboarding.registrationCountry"))
      }),
      acceptedTerms: Yup.boolean().oneOf([true],
        t("formik_warnings.card_onboarding.acceptedTerms")),
      ipAddress: Yup.string().required(t("formik_warnings.card_onboarding.streetName")),
      industry: Yup.string().required(t("formik_warnings.card_onboarding.industry")),
      sourceOfFunds: Yup.string().required(t("formik_warnings.card_onboarding.sourceOfFunds")),
      baseCurrency: Yup.string().required(t("formik_warnings.card_onboarding.streetName"))
    }),
    // handle form submitting
    onSubmit: async (e) => {
      setButtonLoading(true);
      const token = await getToken();
      formik.values.tag = formik.values.tag.split(" ").join("");
      try {
        const response = await axios.post(`${environment.baseUrl}${urls.payments.createCorporate}`,
          formik.values,
          {
            headers: { Authorization: `Bearer ${token}` }
          });
        if (response.data) {
          setButtonLoading(false);
          handleSlider();
        }
      } catch (e:any) {
        setButtonLoading(false);
        switch (e.response.data.message) {
        case "mobile_validation":
          toast.error(
            <CustomToastContainer
              message={"Mobile number should not contain +"}
              status={1}
            />,
            {
              autoClose: 5000,
            }
          );
          break;
        case "email_exists":
          toast.error(
            <CustomToastContainer
              message={t("dashboard.card_onboarding.warning_email_exists")}
              status={1}
            />,
            {
              autoClose: 5000,
            }
          );
          break;
        }
      }
    },
  });

  const handleSelectBox = (field:string, value:string) => {
    formik.setFieldValue(field, value, true);
  };

  return (
    <MainSpace>
      <VerticalDivider ratio={mainSpaceRatio}>
        <form onSubmit={(e) => {
          formik.handleSubmit(e);
        }}>

          {/*COMPANY NAME*/}
          <div className="relative">
            <FormikInput
              htmlFor="tag"
              name="tag"
              value={formik.values.tag}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("dashboard.card_onboarding.company_name_label")}
            />
            {formik.errors.tag ?
              formik.touched.tag
              || validateForm ? <p className="input_warning">
                  {formik.errors.tag}
                </p> : null
              : null
            }
          </div>

          {/*CONTACT PERSON INFORMATION*/}
          <h3>{t("dashboard.card_onboarding.contact_person_title")}</h3>
          <VerticalDivider ratio={userRatio}>
            <div className="relative">
              <FormikInput
                style={{ marginRight: "10px" }}
                htmlFor="name"
                name="rootUser.name"
                value={formik.values.rootUser.name}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.firstName_label")}
              />
              {formik.errors.rootUser?.name ?
                formik.touched.rootUser?.name
                || validateForm ? <p className="input_warning">
                    {formik.errors.rootUser?.name}
                  </p> : null
                : null
              }
            </div>

            <div className="relative">
              <FormikInput
                htmlFor="lastName"
                name="rootUser.surname"
                value={formik.values.rootUser.surname}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.lastName_label")}
              />
              {formik.errors.rootUser?.surname ?
                formik.touched.rootUser?.surname
                || validateForm ? <p className="input_warning">
                    {formik.errors.rootUser?.surname}
                  </p> : null
                : null
              }
            </div>
          </VerticalDivider>
          <div className="relative">
            <FormikInput
              htmlFor="email"
              name="rootUser.email"
              value={formik.values.rootUser.email}
              disabled={false}
              handleChange={formik.handleChange}
              onBlur={formik.handleBlur}
              label={t("dashboard.card_onboarding.email_label")}
            />
            {formik.errors.rootUser?.email ?
              formik.touched.rootUser?.email
              || validateForm ? <p className="input_warning">
                  {formik.errors.rootUser?.email}
                </p> : null
              : null
            }
          </div>
          <VerticalDivider ratio={mobileRatio}>
            <div className="relative">
              <div className="filters_item">
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("dashboard.card_onboarding.mobileCode_label")}
                </p>
                <SelectWithChoices
                  size="small"
                  style={{marginTop: "4px"}}
                  defaultValue={""}
                  name={t("dashboard.card_onboarding.mobileCode_label")}
                  selectPlaceHolder={t("dashboard.card_onboarding.mobileCode_label")}
                  selectId="country"
                  options={phoneCodes}
                  isMulti={"no"}
                  handleAddChoice={(options) =>
                    handleSelectBox("rootUser.mobile.countryCode", options.value)}
                  onBlur={formik.handleBlur("rootUser.mobile.countryCode")}
                />
              </div>
              {formik.errors.rootUser?.mobile?.countryCode ?
                formik.touched.rootUser?.mobile?.countryCode
                || validateForm ? <p className="input_warning">
                    {formik.errors.rootUser?.mobile?.countryCode}
                  </p> : null
                : null
              }
            </div>

            <div />

            <div className="relative">
              <FormikInput
                htmlFor="phoneNumber"
                name="rootUser.mobile.number"
                value={formik.values.rootUser.mobile.number}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.phone_label")}
              />
              {formik.errors.rootUser?.mobile?.number ?
                formik.touched.rootUser?.mobile?.number
                || validateForm ? <p className="input_warning">
                    {formik.errors.rootUser?.mobile?.number}
                  </p> : null
                : null
              }
            </div>
          </VerticalDivider>

          {/*COMPANY INFORMATION*/}
          <VerticalDivider ratio={companyPostalRatio}>

            <div className="relative">
              <FormikInput
                style={{width: "calc(100% - 20px)" }}
                htmlFor="address1"
                name="company.businessAddress.addressLine1"
                value={formik.values.company.businessAddress.addressLine1}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.address_1_label")}
              />
              {formik.errors.company?.businessAddress?.addressLine1 ?
                formik.touched.company?.businessAddress?.addressLine1
                || validateForm ? <p className="input_warning">
                    {formik.errors.company.businessAddress.addressLine1}
                  </p> : null
                : null
              }
            </div>

            <div className="relative">
              <FormikInput
                style={{width: "calc(100% - 20px)" }}
                htmlFor="address2"
                name="company.businessAddress.addressLine2"
                value={formik.values.company.businessAddress.addressLine2}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.address_2_label")}
              />
              {formik.errors.company?.businessAddress?.addressLine2 ?
                formik.touched.company?.businessAddress?.addressLine2
                || validateForm ? <p className="input_warning">
                    {formik.errors.company.businessAddress.addressLine2}
                  </p> : null
                : null
              }
            </div>

            <div className="filters_item" style={{position: "relative"}}>
              <p className="filter_label" style={{ height: "1.2rem" }}>
                {t("dashboard.card_onboarding.companyType_label")}
              </p>
              <SelectWithChoices
                style={{marginTop: "4px", width: "calc(100% - 20px)"}}
                defaultValue={""}
                name={t("dashboard.card_onboarding.companyType_label")}
                selectPlaceHolder={t("dashboard.card_onboarding.companyType_placeholder")}
                selectId="plans"
                options={translatedCompanyTypes}
                isMulti={"no"}
                handleAddChoice={(options) => handleSelectBox("company.type", options.value)}
                onBlur={formik.handleBlur("company.type")}
              />
              {formik.errors.company?.type ?
                formik.touched.company?.type
                || validateForm ? <p className="input_warning" style={{bottom: -5}}>
                    {formik.errors.company.type}
                  </p> : null
                : null
              }
            </div>
          </VerticalDivider>

          <VerticalDivider ratio={companyPostalRatio}>

            <div className="relative">
              <FormikInput
                style={{ width: "calc(100% - 20px)" }}
                htmlFor="city"
                name="company.businessAddress.city"
                value={formik.values.company.businessAddress.city}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.city_label")}
              />
              {formik.errors.company?.businessAddress?.city ?
                formik.touched.company?.businessAddress?.city
                || validateForm ? <p className="input_warning">
                    {formik.errors.company.businessAddress.city}
                  </p> : null
                : null
              }
            </div>

            <div className="relative">
              <FormikInput
                style={{ width: "calc(100% - 20px)" }}
                htmlFor="postCode"
                name="company.businessAddress.postCode"
                value={formik.values.company.businessAddress.postCode}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.postCode_label")}
              />
              {formik.errors.company?.businessAddress?.postCode ?
                formik.touched.company?.businessAddress?.postCode
                || validateForm ? <p className="input_warning">
                    {formik.errors.company.businessAddress.postCode}
                  </p> : null
                : null
              }
            </div>


            <div className="filters_item" style={{position: "relative"}}>
              <p className="filter_label" style={{ height: "1.2rem" }}>
                {t("dashboard.card_onboarding.country_label")}
              </p>
              <SelectWithChoices
                style={{marginTop: "4px", width: "calc(100% - 20px)"}}
                defaultValue={""}
                name={t("dashboard.card_onboarding.country_label")}
                selectPlaceHolder={t("dashboard.card_onboarding.country_placeholder")}
                selectId="country"
                options={translatedCountriesAbbreviationsTypes}
                isMulti={"no"}
                handleAddChoice={(options) =>
                  handleSelectBox("company.businessAddress.country", options.value)}
                onBlur={formik.handleBlur("company.businessAddress.country")}
              />
              {formik.errors.company?.businessAddress?.country ?
                formik.touched.company?.businessAddress?.country
                || validateForm ? <p className="input_warning" style={{bottom: -5}}>
                    {formik.errors.company?.businessAddress?.country}
                  </p> : null
                : null
              }
            </div>

          </VerticalDivider>

          <VerticalDivider ratio={companyPostalRatio}>

            <div className="relative">
              <FormikInput
                style={{ width: "calc(100% - 20px)" }}
                htmlFor="companyName"
                name="company.name"
                value={formik.values.company.name}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.company_name_2_label")}
              />
              {formik.errors.company?.name ?
                formik.touched.company?.name
                || validateForm ? <p className="input_warning">
                    {formik.errors.company.name}
                  </p> : null
                : null
              }
            </div>

            <div className="relative">
              <FormikInput
                style={{ width: "calc(100% - 20px)" }}
                htmlFor="registrationNumber"
                name="company.registrationNumber"
                value={formik.values.company.registrationNumber}
                disabled={false}
                handleChange={formik.handleChange}
                onBlur={formik.handleBlur}
                label={t("dashboard.card_onboarding.registrationNumber_label")}
              />
              {formik.errors.company?.registrationNumber ?
                formik.touched.company?.registrationNumber
                || validateForm ? <p className="input_warning">
                    {formik.errors.company.registrationNumber}
                  </p> : null
                : null
              }
            </div>

            <div className="relative">
              <div className="filters_item" style={{position: "relative"}}>
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("dashboard.card_onboarding.registrationCountry_label")}
                </p>
                <SelectWithChoices
                  style={{marginTop: "4px", width: "calc(100% - 20px)"}}
                  defaultValue={""}
                  name={t("dashboard.card_onboarding.registrationCountry_label")}
                  selectPlaceHolder={t("dashboard.card_onboarding.registrationCountry_label")}
                  selectId="country"
                  options={translatedCountriesAbbreviationsTypes}
                  isMulti={"no"}
                  handleAddChoice={(options) =>
                    handleSelectBox("company.registrationCountry", options.value)}
                />
                {formik.errors.company?.registrationCountry ?
                  formik.touched.company?.registrationCountry
                  || validateForm ? <p className="input_warning" style={{bottom: -5}}>
                      {formik.errors.company?.registrationCountry}
                    </p> : null
                  : null
                }
              </div>
            </div>

          </VerticalDivider>

          {/*GENEARL INFO*/}
          <VerticalDivider ratio={branchRatio}>
            <div className="filters_item" style={{position: "relative"}}>
              <p className="filter_label" style={{ height: "1.2rem" }}>
                {t("dashboard.card_onboarding.industryType_label")}
              </p>
              <SelectWithChoices
                style={{marginTop: "4px"}}
                defaultValue={""}
                name={t("dashboard.card_onboarding.industryType_label")}
                selectPlaceHolder={t("dashboard.card_onboarding.industryType_placeholder")}
                selectId="plans"
                options={translatedIndustryTypes}
                isMulti={"no"}
                handleAddChoice={(options) => handleSelectBox("industry", options.value)}
              />
              {formik.errors.industry ?
                formik.touched.industry
                || validateForm ? <p className="input_warning" style={{bottom: -5}}>
                    {formik.errors.industry}
                  </p> : null
                : null
              }
            </div>
            <div className="filters_item" style={{position: "relative"}}>
              <p className="filter_label" style={{ height: "1.2rem" }}>
                {t("dashboard.card_onboarding.sourceOfFounds_label")}
              </p>
              <SelectWithChoices
                style={{marginTop: "4px"}}
                defaultValue={""}
                name={t("dashboard.card_onboarding.sourceOfFounds_label")}
                selectPlaceHolder={t("dashboard.card_onboarding.sourceOfFounds_placeholder")}
                selectId="plans"
                options={translatedSourceOfFoundsTypes}
                isMulti={"no"}
                handleAddChoice={(options) => handleSelectBox("sourceOfFunds", options.value)}
              />
              {formik.errors.sourceOfFunds ?
                formik.touched.sourceOfFunds
                || validateForm ? <p className="input_warning" style={{bottom: -5}}>
                    {formik.errors.sourceOfFunds}
                  </p> : null
                : null
              }
            </div>
          </VerticalDivider>
          <div className="row">
            <Checkbox
              id="newCard"
              checked={formik.values.acceptedTerms}
              handleCheckboxChange={() => {
                setValidateForm(true);
                formik.validateForm();
                formik.setFieldValue("acceptedTerms", !formik.values.acceptedTerms, true);
              }}
            />
            <p style={{fontSize: "14px"}}>
              Ich akzeptiere
              <a
                href="https://www.corplife.at/agbs/agb"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none"
                }}
              >
                {" Nutzungsbedingungen"}
              </a> und
              <a
                href="https://www.corplife.at/agbs/datenschutz"
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none"
                }}
              >
                {" AGB"}
              </a>
            </p>
          </div>

          <div style={{height: "40px"}} />

          <Button
            buttonType="primary"
            buttonLabel={t("dashboard.payments.onboarding.stepper.form.btn")}
            submitButton={true}
            disabled={!(formik.isValid && formik.dirty)}
            loading={buttonLoading}
          />
        </form>
        <div></div>
      </VerticalDivider>
    </MainSpace>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    user: state.users,
    company: state.company
  };
};

export default connect(mapStateToProps, null)(OnboardingForm);
