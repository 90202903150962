import React, {lazy, useEffect, useState} from "react";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../../redux/reducers/rootReducer";
import {useTranslation} from "react-i18next";
import {useLocation, useParams} from "react-router-dom";
import "./index.css";
import {
  changeStep,
  changeValues,
  clearValuesMessages, getValues,
  sendValues, updateValues
} from "../../../redux/actions/stepsActions";
import {toast} from "react-toastify";
import moment from "moment";
import {BeatLoader} from "react-spinners";
import ChecklistInfoScreen from "./Components/InfoScreen/InfoScreen";
import ChecklistFooter from "./Components/Footer/Footer";
import Button from "../../Buttons/Button";
import CustomToastContainer from "../../SimpleComponents/ToastContainer";
import {partnerPortalService} from "../../../api/services/Partner-portal/partner-portal.service";
import {getMagentoData} from "../../../utils/cookies/userCookies/userCookies";

const DefaultContainer = lazy(() => import("./Components/DefaultContainer/defaultContainer"));
const MainForm = lazy(() => import("./Components/MainForm/MainForm"));
const ExamplePage = lazy(() => import("./Components/ExamplePage/examplePage"));
const TextForm = lazy(() => import("./Components/TextForm/textForm"));
const SuccessContainer = lazy(() => import("./Components/SuccessContainer/successContainer"));

interface CustomProps {
  steps?: any;
  sendFormState?: string;
  storedValues?: any;
  serverMessages?: string;
  types?: number;
  business?: boolean;
}

function Main({
  steps,
  sendFormState,
  storedValues,
  serverMessages,
  types,
  business
}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const params = useParams();

  const [buttonDisabled, setButtonDisabled] = useState(true);

  useEffect(() => {
    if (steps.step === 3) {
      dispatch(changeStep(0));
    }
  }, [location]);

  // Save form id if url /:id
  const [formId, setFormId] = useState("");
  useEffect(() => {
    if (params && params.id) {
      setFormId(params.id);
      dispatch(getValues(params.id));
    }
  }, [params]);

  const renderElement = () => {
    switch (steps.step) {
    case 0:
      return <DefaultContainer
        setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
        id={formId}
      />;
    case 1:
      return <MainForm
        setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
        id={formId}
      />;
    case 2:
      return <TextForm
        setButtonDisabled={(state:boolean) => setButtonDisabled(state)}
        id={formId}
      />;
    default :
      return <p>Error</p>;
    }
  };

  useEffect(() => {
    if (sendFormState === "success") {
      dispatch(clearValuesMessages());
      dispatch(changeStep(steps.step + 1));
    } else if (sendFormState === "error") {
      dispatch(clearValuesMessages());
      toast.error(
        <CustomToastContainer
          message={t("partner_portal.deals.create.warnings.send_error", {number: 1})}
          status={3}/>, {
          autoClose: 2000,
        });
    } else if (sendFormState === "update_success") {
      dispatch(clearValuesMessages());
      toast.success(
        <CustomToastContainer
          message={t("partner_portal.deals.create.warnings.update_success", {number: 1})}
          status={1}/>, {
          autoClose: 2000,
        });
    } else if (sendFormState === "update_error") {
      dispatch(clearValuesMessages());
      toast.error(
        <CustomToastContainer
          message={t("partner_portal.deals.create.warnings.send_error", {number: 1})}
          status={1}/>, {
          autoClose: 2000,
        });
    }
  }, [sendFormState]);

  // Initialize data from DB
  useEffect(() => {
    if (Object.keys(storedValues).length) {

      const newValues = [
        {
          partner: storedValues.partner,
          offer: storedValues.offer,
          country: storedValues.country,
          startDate: moment(storedValues.startDate).toDate(),
          endDate: moment(storedValues.endDate).toDate(),
          contact: storedValues.contact,
        },
        {
          text: storedValues.questions[0].selectedIndex.toString(),
          image: storedValues.questions[1].selectedIndex.toString(),
          uploadedImages: storedValues.questions[1].images,
          uploadedImagesDE: storedValues.questions[1].imagesDE,
          uploadedImagesKeys: storedValues.questions[1].images,
          uploadedImagesKeysDE: storedValues.questions[1].imagesDE,
          featureImage: storedValues.questions[2].images,
          featureImageDE: storedValues.questions[2].imagesDE,
          featureImageKeys: storedValues.questions[2].images,
          featureImageKeysDE: storedValues.questions[2].imagesDE,
          logoImage: storedValues.questions[3].images,
          logoImageKeys: storedValues.questions[3].images,
          options: storedValues.questions[4].selectedIndex.toString(),
          optionsDE: storedValues.questions[4].selectedIndexDE?.toString(),
          optionsSub: storedValues.questions[4].nestedText,
          optionsSubDE: storedValues.questions[4].nestedTextDE,
          optionsFile: storedValues.questions[4].images,
          optionsFileDE: storedValues.questions[4].imagesDE,
          optionsFileKeys: storedValues.questions[4].images,
          optionsFileKeysDE: storedValues.questions[4].imagesDE,
          memberCard: storedValues.questions[15].nestedText === "true",
          memberCardDE: storedValues.questions[15].nestedTextDE === "true",
          linksSub: storedValues.questions[5].nestedText,
          linksSubDE: storedValues.questions[5].nestedTextDE,
          tags: storedValues.questions[6].selectedIndex.toString(),
          tagsSub: storedValues.questions[6].nestedText,
          videos: storedValues.questions[7].selectedIndex.toString(),
          videosDE: storedValues.questions[7].selectedIndex.toString(),
          customVideo: storedValues.questions[7].nestedText,
          customVideoDE: storedValues.questions[7].nestedText
        },
        {
          title: storedValues.questions[8].nestedText,
          titleDE: storedValues.questions[8].nestedTextDE,
          description: storedValues.questions[9].nestedText,
          descriptionDE: storedValues.questions[9].nestedTextDE,
          text: storedValues.questions[10].nestedText,
          textDE: storedValues.questions[10].nestedTextDE,
          highlights: storedValues.questions[11].nestedText,
          highlightsDE: storedValues.questions[11].nestedTextDE,
          conditions: storedValues.questions[12].nestedText,
          conditionsDE: storedValues.questions[12].nestedTextDE,
          additionalConditions: storedValues.questions[13].nestedText,
          additionalConditionsDE: storedValues.questions[13].nestedTextDE,
          merchant: storedValues.questions[14].nestedText,
          merchantDE: storedValues.questions[14].nestedTextDE
        }
      ];
      dispatch(changeValues(newValues, 0));
    }
  }, [storedValues]);

  // Show Button loading while sending values
  const [buttonLoading, setButtonLoading] = useState(false);

  // Hide button loading after response
  useEffect(() => {
    if (serverMessages?.length) {
      setButtonLoading(false);
      dispatch(clearValuesMessages());
    }
  }, [serverMessages]);

  // Handle back click
  const handleBackButton = async (condition: string) => {
    if (condition === "prev") {
      dispatch(changeStep(steps.step - 1));
    } else if (condition === "next") {
      if (steps.step === 2) {
        const start = moment(steps.values[0].startDate, "YYYY-MM-DD")
          .format("DD-MM-YYYY");
        const end = moment(steps.values[0].endDate, "YYYY-MM-DD").format("DD-MM-YYYY");
        const userData = await new partnerPortalService().getProfile();
        const magentoData = getMagentoData();
        const values: any = {
          magentoToken: magentoData.token,
          type: 0,
          partner: userData.data?.companyName,
          offer: steps.values[0].offer,
          country: steps.values[0].country,
          contact: [
            {
              name: `${userData.data?.firstname} ${userData.data?.lastname}`,
              email: userData.data?.email
            }
          ],
          startDate: `${start} 01:00:00.000`,
          endDate: `${end} 01:00:00.000`,
          status: 0,
          salesType: types,
          questions: [
            {
              questionIndex: 0,
              selectedIndex: +steps.values[1].text,
            },
            {
              questionIndex: 1,
              selectedIndex: +steps.values[1].image,
              images: steps.values[1].uploadedImagesKeys,
              imagesDE: steps.values[1].uploadedImagesKeysDE
            },
            {
              questionIndex: 2,
              images: steps.values[1].featureImageKeys,
              imagesDE: steps.values[1].featureImageKeysDE,
            },
            {
              questionIndex: 3,
              images: steps.values[1].logoImageKeys,
            },
            {
              questionIndex: 4,
              selectedIndex: +steps.values[1].options,
              selectedIndexDE: +steps.values[1].optionsDE,
              subSelectedIndex: +steps.values[1].optionsSub || 0,
              subSelectedIndexDE: +steps.values[1].optionsSubDE,
              nestedText: steps.values[1].optionsSub,
              nestedTextDE: steps.values[1].optionsSubDE,
              images: steps.values[1].optionsFileKeys,
              imagesDE: steps.values[1].optionsFileKeysDE,
            },
            {
              questionIndex: 5,
              nestedText: steps.values[1].linksSub,
              nestedTextDE: steps.values[1].linksSubDE,
            },
            {
              questionIndex: 6,
              selectedIndex: +steps.values[1].tags,
              nestedText: steps.values[1].tagsSub,
            },
            {
              questionIndex: 7,
              selectedIndex: +steps.values[1].videos,
              selectedIndexDE: +steps.values[1].videosDE,
              subSelectedIndex: +steps.values[1].customVideo,
              subSelectedIndexDE: +steps.values[1].customVideoDE,
              nestedText: steps.values[1].customVideo,
              nestedTextDE: steps.values[1].customVideoDE,
            },
            {
              questionIndex: 8,
              nestedText: steps.values[2].title,
              nestedTextDE: steps.values[2].titleDE,
            },
            {
              questionIndex: 9,
              nestedText: steps.values[2].description,
              nestedTextDE: steps.values[2].descriptionDE,
            },
            {
              questionIndex: 10,
              nestedText: steps.values[2].text,
              nestedTextDE: steps.values[2].textDE,
            },
            {
              questionIndex: 11,
              nestedText: steps.values[2].highlights,
              nestedTextDE: steps.values[2].highlightsDE,
            },
            {
              questionIndex: 12,
              nestedText: steps.values[2].conditions,
              nestedTextDE: steps.values[2].conditionsDE,
            },
            {
              questionIndex: 13,
              nestedText: steps.values[2].additionalConditions,
              nestedTextDE: steps.values[2].additionalConditionsDE,
            },
            {
              questionIndex: 14,
              nestedText: steps.values[2].merchant,
              nestedTextDE: steps.values[2].merchantDE
            },
            {
              questionIndex: 15,
              nestedText: steps.values[1].memberCard,
              nestedTextDE: steps.values[1].memberCardDE
            },
          ],
        };
        if (business) {
          values.business = true;
        }
        setButtonLoading(true);

        if (formId) {
          dispatch(updateValues(values, formId));
        } else {
          dispatch(sendValues(values));
        }
      }
      if (steps.step !== 2) {
        dispatch(changeStep(steps.step + 1));
      }
    }
  };

  const [showInfoScreen, setShowInfoScreen] = useState(1);

  const handleInfoNavigation = () => {
    if (showInfoScreen < 2) {
      setShowInfoScreen(showInfoScreen + 1);
    } else {
      setShowInfoScreen(0);
    }
  };

  return(
    <main style={{paddingTop: "1px"}}>
      {showInfoScreen !== 0
        ? <>
          <div
            className="end"
            data-testid="main_component"
            style={{marginBottom: "10px", paddingTop: "20px"}}
          >
            {showInfoScreen === 2
              ? <Button
                buttonLabel={t("partner_portal.deals.create.main_form.header.btn_back")}
                disabled={buttonLoading}
                buttonType={"tertiary"}
                buttonStyles={{marginRight: "15px"}}
                buttonHandler={() => setShowInfoScreen(1)}
              />
              : null
            }
            <Button
              buttonHandler={() => handleInfoNavigation()}
              buttonType={"primary"}
              buttonLabel={t("partner_portal.deals.create.main_form.header.btn")}
              disabled={false}
            />
          </div>
          <ChecklistInfoScreen
            business={business}
            step={showInfoScreen}
          />
        </>
        : <>
          {steps.step === 0
            ? <div
              className="end"
              style={{marginBottom: "10px", paddingTop: "20px"}}
            >
              <Button
                buttonLabel={t("partner_portal.deals.create.main_form.header.btn_back")}
                disabled={buttonLoading}
                buttonType={"tertiary"}
                buttonStyles={{marginRight: "15px"}}
                buttonHandler={() => setShowInfoScreen(2)}
              />
              <Button
                buttonHandler={() => handleBackButton("next")}
                buttonLabel={t("partner_portal.deals.create.main_form.header.btn")}
                buttonType={"primary"}
                disabled={buttonDisabled}
              />
            </div>
            :
            <div className="main_header">
              <div>
                <h3 className="title_h3" >{t("partner_portal.deals.create.main_form.header.title")}</h3>
                {business
                  ? null
                  : <>
                    <p className="main_form_header_text">
                      {t("partner_portal.deals.create.main_form.header.text")}
                    </p>
                    <p className="main_form_header_text">
                      {t("partner_portal.deals.create.main_form.header.text_2")}
                    </p>
                  </>
                }
              </div>
              {steps.step !== 3
                ?
                <div className="header_btn_container" style={{marginTop: "14px", marginBottom: "14px"}}>
                  <Button
                    buttonLabel={t("partner_portal.deals.create.main_form.header.btn_back")}
                    disabled={buttonLoading}
                    buttonType={"tertiary"}
                    buttonHandler={() => handleBackButton("prev")}
                  />
                  {buttonLoading
                    ?
                    <div className="button_placeholder">
                      <BeatLoader size="7px" color="white" />
                    </div>
                    :
                    <Button
                      buttonHandler={() => handleBackButton("next")}
                      buttonLabel={steps.step !== 2
                        ? t("partner_portal.deals.create.main_form.header.btn")
                        : t("partner_portal.deals.create.main_form.header.btn_send")}
                      disabled={buttonDisabled}
                      buttonType={"primary"}
                    />
                  }
                </div>
                : null
              }
            </div>
          }
          <div className={steps.step === 3
          || steps.step === 0 ? "main_container_checklist full" : "main_container_checklist"}>

            {steps.step === 3
              ? <SuccessContainer type={0} update={formId} />
              : null
            }

            {steps.step !== 3
              ?
              <>
                {renderElement()}
                {steps.step !== 0
                  ? <ExamplePage id={formId} />
                  : null
                }
              </>
              : null
            }
          </div>
        </>
      }
      <ChecklistFooter type={types === 5 ? 5 : 1} />
    </main>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    steps: state.steps,
    sendFormState: state.steps.message,
    storedValues: state.steps.valuesFromDB,
    serverMessages: state.steps.message
  };
};

export default connect(mapStateToProps, null)(Main);
