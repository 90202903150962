import React from "react";
import "./UpdateStatus.css";
import {useTranslation} from "react-i18next";
import ArrowIcon from "img/Dashboard/Orders/arrow-right-solid.svg";
import moment from "moment";
import disabledIcon from "img/Dashboard/Orders/disabled_update_icon.svg";
import activeIcon from "img/Dashboard/Orders/active_update_icon.svg";
import calendarIcon from "img/Dashboard/Orders/update_calendar_icon.svg";
import clockIcon from "img/Dashboard/Orders/clock_calendar_item.svg";
import Button from "../Buttons/Button";
import UserAvatar from "./UserAvatar";
import getBadgeDataForInvoiceStatus from "../Elements/Badge/helpers/getBadgeDataForInvoiceStatus";
import Badge from "../Elements/Badge/Badge";

interface CustomProps {
  showChangeLog: (data:any) => void,
  data: any,
  style?: any
}

export default function UpdatesStatus({showChangeLog, data, style}:CustomProps) {
  const { t } = useTranslation();

  return(
    <div className="update_info_container">
      {data && data.length
        ?
        <>
          <div className="update_info_hint">
            {/*UPDATED BY*/}
            <div className="space-between">
              <p data-testid="update_info_title_updated_by" className="update_info_title">
                {t("dashboard.overview.update_info.updated_by")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <UserAvatar imageUrl={data[0].updatedBy.image} small/>
                <p data-testid="update_info_text_name" className="update_info_text">
                  {data[0].updatedBy.name}
                </p>
              </div>
            </div>
            {/*UPDATED AT*/}
            <div className="space-between" style={{marginTop: "20px"}}>
              <p data-testid="update_info_title_updated_at" className="update_info_title">
                {t("dashboard.overview.update_info.updated_at")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <div className="row">
                  <img
				  style={style}
                    data-testid="update_info_image"
                    src={calendarIcon}
                    width="25px"
                    alt="calendar"
                  />
                  <p data-testid="update_info_text_date" className="update_info_text">
                    {moment(data[0].updatedAt).format("DD.MM.YYYY")}
                  </p>
                </div>
                <div className="row" style={{marginLeft: "10px"}}>
                  <img
				  style={style}
                    data-testid="update_info_image_time"
                    src={clockIcon}
                    width="25px"
                    alt="clock"
                  />
                  <p data-testid="update_info_text_time" className="update_info_text">
                    {moment(data[0].updatedAt).format("HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            {/*STATUS*/}
            <div className="space-between" style={{marginTop: "20px"}}>
              <p data-testid="update_info_title_status" className="update_info_title">
                {t("dashboard.overview.update_info.status")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <div className="row">
                  <Badge
                    color={getBadgeDataForInvoiceStatus((+data[0].update.prevStatus as any)).color}
                    text={getBadgeDataForInvoiceStatus((+data[0].update.prevStatus as any)).text}
                  />
                  <img
				  style={style}
                    data-testid="update_info_between_image"
                    src={ArrowIcon}
                    alt="arrow"
                    className="update_info_arrow"
                  />
                  <Badge
                    color={getBadgeDataForInvoiceStatus((+data[0].update.currStatus as any)).color}
                    text={getBadgeDataForInvoiceStatus((+data[0].update.currStatus as any)).text}
                  />
                </div>
              </div>
            </div>

            {/*Comment*/}
            {data[0].update.comment && data[0].update.comment.length > 0
              &&
              <div className="space-between" style={{marginTop: "20px"}}>
                <p data-testid="update_info_title_status" className="update_info_title">
                  {t("dashboard.overview.update_info.comment")}
                </p>
                <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                  <p>{data[0].update.comment}</p>
                </div>
              </div>
            }

            <div style={{
              backgroundColor: "#A2ADBF",
              width: "100%",
              height: "1px",
              marginTop: "15px",
              marginBottom: "15px"
            }} />
            <div className="flex-end">
              <Button
                buttonLabel={t("dashboard.overview.update_info.changelog_btn")}
                buttonType="outlined"
                buttonHandler={() => showChangeLog(data)}
              />
            </div>
          </div>
          <img src={activeIcon} className="update_info_icon" alt="info" style={style}/>
        </>
        : <img src={disabledIcon} className="update_info_icon" alt="info" style={style}/>
      }
    </div>
  );
}
