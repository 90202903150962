// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.slider {
    margin-bottom: 40px;
}

.filter_range_title {
    font-size: 0.8rem;
    font-weight: 600;
    margin-bottom: 1rem;
}

.input-range__slider {
    background: #ffffff!important;
    border: 1px solid #D7D7DE!important;
}

.input-range__track {
    background: #ddd!important;
}

.input-range__track--active {
    background: #A7A7C3!important;


}

.input-range__label--value {
    display: none;
}

.input-range__label-container {
    display: none;
}

.filter_range_input-box {
    max-width: 47%;
}
.filter_range_input-box > label {
    font-size: 0.8rem;
    font-weight: 600;
}
.filter-range_input {
    margin-top: 0.5rem;
    max-width: 100%;
    height: 50px;
    border-radius: 5px;
    border: 1px solid #D7D7DE;
    padding-left: 1rem;
    font-size: 1.5rem;
}


`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/FilterRange.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,6BAA6B;IAC7B,mCAAmC;AACvC;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,6BAA6B;;;AAGjC;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,cAAc;AAClB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;IACzB,kBAAkB;IAClB,iBAAiB;AACrB","sourcesContent":[".slider {\n    margin-bottom: 40px;\n}\n\n.filter_range_title {\n    font-size: 0.8rem;\n    font-weight: 600;\n    margin-bottom: 1rem;\n}\n\n.input-range__slider {\n    background: #ffffff!important;\n    border: 1px solid #D7D7DE!important;\n}\n\n.input-range__track {\n    background: #ddd!important;\n}\n\n.input-range__track--active {\n    background: #A7A7C3!important;\n\n\n}\n\n.input-range__label--value {\n    display: none;\n}\n\n.input-range__label-container {\n    display: none;\n}\n\n.filter_range_input-box {\n    max-width: 47%;\n}\n.filter_range_input-box > label {\n    font-size: 0.8rem;\n    font-weight: 600;\n}\n.filter-range_input {\n    margin-top: 0.5rem;\n    max-width: 100%;\n    height: 50px;\n    border-radius: 5px;\n    border: 1px solid #D7D7DE;\n    padding-left: 1rem;\n    font-size: 1.5rem;\n}\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
