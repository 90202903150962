import React, { useEffect, useRef, useState } from "react";
import invoiceIcon from "img/Dashboard/Payments/Overview/Header/invoice_icon.png";
import { connect } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers/rootReducer";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {getToken} from "../../utils/cookies/tokensCookie";
import {useNavigate} from "react-router-dom";

interface IProps {
  transactionId: string;
}

function ActionDotsPaymentsTransactions(props: IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const actionRef: any = useRef();

  const [menuListOpen, setMenuListOpen] = useState(false);
  // loading after delete
  const [showLoading, setShowLoading] = useState(false);


  // Detect click outside for close menu

  const EmployeeActionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!EmployeeActionsRef.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (menuListOpen) {
      actionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuListOpen]);

  const handleNavigateToInvoice = async () => {
    const token = await getToken();
    axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.invoiceIdByTransactionId}/${props.transactionId}`,
      {
        headers: {Authorization: `Bearer ${token}`},
      })
      .then((res: any) => {
        if (res.data) {
          if (res.data) {
            navigate(`/overview/${res.data}`);
          }
        }
      });
  };

  return showLoading ? (
    <BeatLoader size="8px" />
  ) : (
    <div ref={EmployeeActionsRef} className="action_dots_container">

      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container"
        ref={actionRef}
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          <div
            onClick={() => handleNavigateToInvoice()}
            className="table_menu_item row"
          >
            <img src={invoiceIcon} alt="invoice" />
            <p>
              {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.action_dots.show_invoice_detail")}
            </p>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    deleteSuccess: state.users,
    rootUser: state.users
  };
};

export default connect(mapStateToProps, null)(ActionDotsPaymentsTransactions);
