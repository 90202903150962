// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.character-counter {
    font-size: 11px!important;
    font-weight: normal!important;
    margin-top: -12px;
    color: #0d9683;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/RejectInvoiceModal/RejectInvoiceModal.css"],"names":[],"mappings":"AAAA;IACI,yBAAyB;IACzB,6BAA6B;IAC7B,iBAAiB;IACjB,cAAc;AAClB","sourcesContent":[".character-counter {\n    font-size: 11px!important;\n    font-weight: normal!important;\n    margin-top: -12px;\n    color: #0d9683;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
