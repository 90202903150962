import {
  CLEAR_PAYMENTS_MESSAGES, CREATE_PAYMENTS_ERROR, GET_CARD, GET_CARD_TRANSACTIONS,
  GET_CARDS,
  PAYMENTS_ERROR,
  paymentsTypes,
  SHOW_PAYMENTS_LOADING
} from "../types/paymentsTypes";

interface DefaultStateI {
  loading: boolean,
  error: string,
  success: string,
  cards: any,
  card: any,
  transactions: any
}

const initialState:DefaultStateI = {
  loading: true,
  error: "",
  success: "",
  cards: [],
  card: {},
  transactions: {}
};

export const paymentsReducer = (
  state: DefaultStateI = initialState,
  action: paymentsTypes):DefaultStateI => {
  switch (action.type) {
  case GET_CARDS:
    return {
      ...state, cards: action.payload,
      loading: false
    };
  case GET_CARD:
    return {
      ...state, card: action.payload,
      loading: false
    };
  case GET_CARD_TRANSACTIONS:
    return {
      ...state, transactions: action.payload,
      loading: false
    };
  case SHOW_PAYMENTS_LOADING:
    return {
      ...state,
      loading: true
    };
  case CLEAR_PAYMENTS_MESSAGES:
    return {
      ...state,
      error: "",
      success: "",
      loading: false
    };
  case PAYMENTS_ERROR:
    return {
      ...state, error: action.payload,
      loading: false
    };
  case CREATE_PAYMENTS_ERROR:
    return {
      ...state, error: "created_error",
      loading: false
    };
  default:
    return state;
  }
};
