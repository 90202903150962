import ContentContainer from "Components/Containers/ContentContainer";
import React, { useEffect, useState } from "react";
import "./employeePaymentsPage.css";
import MainSpace from "../../Components/Containers/MainSpace";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import DashboardLoading from "../../Components/LoadingPlaceholders/DashboardLoading";
import AntTabs from "../../Components/Payments/Shared/Tabs/AntTabs";
import { useTranslation } from "react-i18next";
import EmployeePaymentsCardTab from "./employeePaymentsCardTab/employeePaymentsCardTab";
import PaymentAuthenticationPopup from "../../Components/Payments/PaymentAuthenticationPopup/PaymentAuthenticationPopup";
import axios from "axios";
import { getToken, setToken } from "../../utils/cookies/tokensCookie";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from "query-string";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {getUserInfo} from "../../redux/actions/usersActions";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";
import {useEffectOnce} from "../../hooks/useEffectOnce";


interface IProps {
  user?: any;
}

type cardsIdsType = {
  restaurantCardId: string;
  supermarketCardId: string;
  cardId: string;
};

function EmployeePaymentsPage(props: IProps) {
  const { t } = useTranslation();
  const location = useLocation();
  const parsedSearch: any = queryString.parse(location.search) || "";
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [pageLoading, setPageLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("");
  const [cardsIds, setCardsIds] = useState<cardsIdsType>({
    supermarketCardId: "",
    restaurantCardId: "",
    cardId: ""
  });

  const [showPaymentsAuthModal, setShowPaymentsAuthModal] = useState(false);
  const [showPasswordComponent, setShowPasswordComponent] = useState(false);
  const [
    showAuthorizedUserNotActivatedLayout,
    setShowAuthorizedUserNotActivatedLayout,
  ] = useState(true);
  const [email, setEmail] = useState("");
  const [nonce, setNonce] = useState("");
  const [userId, setUserId] = useState("");
  const [refLogin, setRefLogin] = useState<any>(null);
  const [linkIsParsed, setLinkIsParsed] = useState(false);
  const [passwordInput, setPasswordInput] = useState("");
  const [tabs, setTabs] = useState<any>([]);

  useEffect(() => {
    if (
      parsedSearch &&
      parsedSearch.email &&
      parsedSearch.nonce &&
      !linkIsParsed
    ) {
      setUserId(parsedSearch.user_id);
      setEmail(parsedSearch.email);
      setNonce(parsedSearch.nonce);
      setShowPasswordComponent(true);
      setLinkIsParsed(true);
    }
  }, [parsedSearch]);

  useEffect(() => {
    if (
      props.user?.userInfo?.user?.authorizedUserStatus === "invitationConfirmed"
    ) {
      setShowAuthorizedUserNotActivatedLayout(false);
      setShowPasswordComponent(false);
    } else {
      setShowAuthorizedUserNotActivatedLayout(true);
    }
  }, [props.user?.userInfo?.user?.authorizedUserStatus]);

  // Get user card ids from user state
  useEffect(() => {
    if (props.user?.userInfo?.user) {
      if (
        (props.user.userInfo.user.supermarketCardId &&
        props.user.userInfo.user.restaurantCardId) || props.user.userInfo.user.cardId
      ) {
        setCardsIds({
          supermarketCardId: props.user.userInfo.user.supermarketCardId,
          restaurantCardId: props.user.userInfo.user.restaurantCardId,
          cardId: props.user.userInfo.user.cardId
        });
        if (props.user.userInfo.user.cardId) {
          setSelectedTab(props.user.userInfo.user.cardId);
        } else {
          setSelectedTab(props.user.userInfo.user.restaurantCardId);
        }
        const cardsTabs = [];
        // Set tabs
        if (props.user.userInfo.user.restaurantCardId) {
          cardsTabs.push({
            value: props.user.userInfo.user.restaurantCardId,
            label: t("dashboard.employeePayments.cards.tabs.restaurantCard"),
          });
        }
        // Add general card
        if (props.user.userInfo.user.cardId) {
          cardsTabs.push({
            value: props.user.userInfo.user.cardId,
            label: t("dashboard.employeePayments.cards.tabs.card"),
          });
        }
        // Add supermarket card tab if allowed in company setting
        if (new getLunchSettings().isSupermarketAllowed() && props.user.userInfo.user.supermarketCardId) {
          cardsTabs.push({
            value: props.user.userInfo.user.supermarketCardId,
            label: t("dashboard.employeePayments.cards.tabs.supermarketCard"),
          });
        }
        setTabs(cardsTabs);
      }
      setPageLoading(false);
    }
  }, [props.user.userInfo]);

  useEffect(() => {
    const weavrToken = getToken(true);
    if (!weavrToken && (props.user.userInfo?.user?.restaurantCardId || props.user.userInfo?.user?.cardId)) {
      setShowPaymentsAuthModal(true);
    } else {
      setShowPaymentsAuthModal(false);
    }
  }, []);

  useEffect(() => {
    if (cardsIds && (cardsIds.restaurantCardId || cardsIds.cardId)) {
      checkAuth();
    }
  }, [cardsIds]);

  useEffect(() => {
    if (showPasswordComponent) {
      const createdForm = (window as any)?.OpcUxSecureClient?.form();
      setRefLogin(createdForm);
      const input = createdForm.input("p", "password", {
        placeholder: "0000",
        maxlength: 20,
      });
      input.mount(document.getElementById("password"));
    }
  }, [showPasswordComponent]);

  const checkAuth = async () => {
    await axios.get(
      `${environment.baseUrl}${urls.payments.cards}/${cardsIds.restaurantCardId ?? cardsIds.cardId}`,
      {
        headers: {Authorization: `Bearer ${getToken()}`},
        params: {
          token: getToken(true)
        }
      }
    ).catch((e: any) => {
      if (e?.response?.status === 422) {
        setShowPaymentsAuthModal(true);
      }
    });
  };

  const renderTabContent = () => {
    if (cardsIds.supermarketCardId || cardsIds.restaurantCardId || cardsIds.cardId) {
      switch (selectedTab) {
      case cardsIds.supermarketCardId:
        return <EmployeePaymentsCardTab cardId={cardsIds.supermarketCardId} setShowPaymentsAuthModal={setShowPaymentsAuthModal} />;
      case cardsIds.restaurantCardId:
        return <EmployeePaymentsCardTab cardId={cardsIds.restaurantCardId} setShowPaymentsAuthModal={setShowPaymentsAuthModal} />;
      case cardsIds.cardId:
        return <EmployeePaymentsCardTab cardId={cardsIds.cardId} setShowPaymentsAuthModal={setShowPaymentsAuthModal} />;
      }
    } else {
      return <></>;
    }
  };

  const formSubmitting = async (e?: any) => {
    //
  };

  const createPasscodeForAuthorizedUser = async (passCodeToken: string) => {
    const token = getToken();
    await axios
      .post(
        `${environment.baseUrl}${urls.payments.createPasscodeForAuthorizedUser}`,
        {
          userId: userId,
          nonce: nonce,
          password: passCodeToken,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      )
      .then(
        (response) => {
          setToken(response.data.token, true);
          dispatch(getUserInfo());
          setShowPasswordComponent(false);
        },
        (error) => {
          console.error(error);
        }
      );
  };

  const onSubmit = (e: any) => {
    e.preventDefault();
    if (refLogin) {
      refLogin.tokenize(function (tokens: any) {
        if (tokens && tokens.p) {
          createPasscodeForAuthorizedUser(tokens.p);
          if (!cardsIds.restaurantCardId && !cardsIds.supermarketCardId && !cardsIds.cardId) {
            setShowPaymentsAuthModal(true);
          }
        }
      });
    }
  };

  return (
    <ContentContainer>
      <MainSpace>
        {showPasswordComponent ? (
          <div>
            <form onSubmit={onSubmit}>
              <h1>
                {t(
                  "dashboard.employeePayments.passwordCreationComponent.title"
                )}
              </h1>
              <p>
                {t("dashboard.employeePayments.passwordCreationComponent.text")}
              </p>
              <div style={{ display: "none" }}>
                Username: <input type="input" value={email} name="u" />
                <br />
              </div>

              <div
                className="weavr_passcode_input_box"
                style={{
                  marginTop: "3em",
                  fontSize: "16px",
                  maxWidth: "250px",
                }}
              >
                {t(
                  "dashboard.employeePayments.passwordCreationComponent.label"
                )}
                <div className="employeePaymentPage_password-field">
                  <div
                    id="password"
                    style={{
                      width: "100%",
                      borderRadius: "4px",
                      padding: "4px 8px",
                    }}
                  />
                </div>
              </div>
              <input
                className="weavr_passcode_btn"
                value={t(
                  "dashboard.employeePayments.passwordCreationComponent.btn"
                )}
                type="submit"
              />
            </form>
          </div>
        ) : showAuthorizedUserNotActivatedLayout ? (
          <div className={"employeePaymentPage_not_activated_container"}>
            <h1>{t("dashboard.employeePayments.notActiveState.title")}</h1>
            <p>{t("dashboard.employeePayments.notActiveState.text")}</p>
          </div>
        ) : pageLoading ? (
          <DashboardLoading />
        ) : (
          <div>
            <PaymentAuthenticationPopup
              showModal={showPaymentsAuthModal}
              onModalChange={(state: boolean) =>
                setShowPaymentsAuthModal(state)
              }
              email={props.user?.userInfo?.user?.email}
              onLoginSuccess={() => formSubmitting()}
              forEmployeePayments
              onCloseModal={() => {
                setShowPaymentsAuthModal(false);
                navigate("/");
              }}
            />
            <AntTabs
              selectedTab={selectedTab}
              onChange={(newValue: any) => setSelectedTab(newValue)}
              tabs={tabs}
            />
            {renderTabContent()}
          </div>
        )}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users,
  };
};

export default connect(mapStateToProps, null)(EmployeePaymentsPage);
