import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import TableSortedHeadItem from "./TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import NoRecordsFound from "./NoRecordsList";
import {changeSelectedRows} from "redux/actions/selectedUsersFromListActions";
import Spacer from "../SimpleComponents/Spacer";
import {rewriteCurrency} from "../../utils/rewriteCurrency";
import { useTranslation } from "react-i18next";
import {getOnePlan} from "../../redux/actions/plansActions";
import {uniqueIdGenerator} from "../../utils/uniqueIdGenerator";

interface CustomProps {
	plan?: any,
	searchData?: any,
	selectedRows?: any,
	selectAll: (state:boolean) => void,
	branches: any,
	departments: any,
	search: any,
	requestParams:any,
	setRequestParams: (req:any) => void,
	invoiceState: any,
	plansId: any
}

function PlanDetailsList(
  {plan, selectedRows, selectAll,
    requestParams, setRequestParams, invoiceState, plansId}:CustomProps) {

	interface Lookup {
		[index: string]: string
	}

	const { t } = useTranslation();
	const dispatch = useDispatch();

	const [pageLimit, setPageLimit] = useState(8);

	// Change limits of items in page
	const changePageLimit = (newLimit:number) => {
	  if (newLimit !== pageLimit) {
	    dispatch(getOnePlan(plansId, newLimit, 0));
	    setPageLimit(newLimit);
	  }
	};

	useEffect(() => {
	  if (plan.result && plan.result.length) {
	    setSortedData(plan.result);
	  }
	}, [plan.result]);

	// handling checkboxes
	const [checkedRows, setCheckedRows] = useState<any[]>([]);
	const [checkAll, setCheckAll] = useState(false);
	useEffect(() => {
	  if (plan.result && !checkAll) {
	    const newEmployee = plan.result.map((item:any) => {
	      return ({
	        ...item, isClicked: false
	      });
	    });

	    if (selectedRows.length > 0) {
	      newEmployee.forEach((selected:any) => {
	        selectedRows.forEach((selectedInvoices: any) => {
	          if (selected.invoiceId === selectedInvoices.invoiceId) {
	            selected.isClicked = true;
	          }
	        });
	      });
	    }

	    setCheckedRows(newEmployee);
	  }
	  //Need to disable it, because eslint says, that I need to add
	  //selectedRows as dependency but it will woke
	  // that function in every render
	  // eslint-disable-next-line
	}, [plan.result, dispatch])

	// Listening checkbox changes and push to selected Users
	useEffect(() => {
	  const uniqueArr =
			Array.from(new Set([...selectedRows, ...checkedRows]));
	  const filteredArray =
			uniqueArr.filter((item:any) => item.isClicked === true);
	  const newArray = filteredArray.filter((v:any,i:number,a:any) =>
	    a.findIndex((tr:any) =>
	      (tr.invoiceId===v.invoiceId))===i);
	  dispatch(changeSelectedRows(newArray));
	  //Need to disable it, because eslint says, that I need to add
	  //selectedRows as dependency but it will woke
	  // that function in every render
	  // eslint-disable-next-line
	}, [checkedRows])

	const [sortedData, setSortedData] = useState([]);

	// set which sort is selected
	const [sortButton, setSortButton] = useState({
	  firstName: "up",
	  lastName: "up"
	});

	const sortingData = (name:string) => {
	  const sortButtonClone: Lookup = sortButton;
	  const sortValue = sortButtonClone[name] === "up" ? "down" : "up";
	  const postValue = `${sortValue === "up" ? "" : "-"}${name}`;
	  dispatch(getOnePlan(
	    plansId,
	    pageLimit,
	    0,
	    postValue
	  ));
	  setSortButton({...sortButton, [name]: sortValue});
	};

	// handle Paginate
	const currentPosts = sortedData;
	const paginate = (pageNumber: number) => {
	  const nextPage = (pageNumber - 1) * pageLimit;
	  const newRequest = {...requestParams, nextPage: nextPage};
	  setRequestParams(newRequest);
	  dispatch(getOnePlan(
	    plansId,
	    pageLimit,
	    nextPage
	  ));
	};

	return(
	  plan.result && plan.result.length === 0 ? (
	    <NoRecordsFound
	      text={t("warnings.dash_empty_table")}
	    />
	  ) : (
	    <>
	      <div className="table_box">
	        <div className="table_wrapper" >
	          <table style={{width:"100%"}}>
	            <thead>
	              <tr>
	                <th className="pl-10">
	                  <div className="row">
	                    <Spacer spacerWidth="1rem" />
	                    <TableSortedHeadItem
	                      sortingData={sortingData}
	                      text={t(
	                        "dashboard.plan_detail.th_firstName")
	                      }
	                      name="firstName"
	                      sortButton={sortButton}
	                    />
	                  </div>
	                </th>
	                <th className="pl-10">
	                  <div className="row">
	                    <TableSortedHeadItem
	                      sortingData={sortingData}
	                      text={t(
	                        "dashboard.plan_detail.th_lastName")
	                      }
	                      name="lastName"
	                      sortButton={sortButton}
	                    />
	                  </div>
	                </th>
	                <th>
	                  {/*<TableSortedHeadItem
											sortingData={sortingData}
											text={
												t("dashboard.plan_detail.th_branch")
											}
											name="branch"
											sortButton={sortButton}
										/>*/}
	                  {t("dashboard.plan_detail.th_branch")}
	                </th>
	                <th>
	                  {/*<TableSortedHeadItem
											sortingData={sortingData}
											text={
												t("dashboard.plan_detail.th_department")}
											name="department"
											sortButton={sortButton}
										/>*/}
	                  {t("dashboard.plan_detail.th_department")}
	                </th>
	                <th style={{textAlign:"center"}}>
	                  <div className="responsive-mr-40">
	                    {t("dashboard.plan_detail.th_grandTotal")}
	                  </div>
	                </th>
	                <th style={{textAlign:"center"}}>
	                  <div className="responsive-mr-40">
	                    {t("dashboard.plan_detail.th_totalClaim")}
	                  </div>
	                </th>
	              </tr>
	            </thead>
	            <tbody>
	              {currentPosts.map((item:any, index:number) => (
	                <React.Fragment key={uniqueIdGenerator("plan")}>
	                  <tr className="spacer" />
	                  <tr className="table-item">
	                    <td
	                      style={{width:"12%"}}
	                      className={checkedRows[index]
	                        .isClicked
	                        ? "selected_row" : ""} >
	                      <div className="row">
	                        <Spacer spacerWidth="1rem" />
	                        {item.firstName}
	                      </div>
	                    </td>
	                    <td
	                      style={{width:"10%"}}
	                      className={checkedRows[index]
	                        .isClicked
	                        ? "selected_row" : ""}>
	                      {item.lastName}
	                    </td>
	                    <td
	                      style={{width:"10%"}}
	                      className={checkedRows[index]
	                        .isClicked
	                        ? "selected_row" : ""}>
	                      {item.branch}
	                    </td>
	                    <td
	                      style={{width:"10%"}}
	                      className={checkedRows[index]
	                        .isClicked
	                        ? "selected_row" : ""}>
	                      {item.department}
	                    </td>
	                    <td
	                      style={{width:"10%",textAlign:"center"}}
	                      className={checkedRows[index]
	                        .isClicked
	                        ? "selected_row" : ""}>
	                      {rewriteCurrency(
	                        item.calculation.spendAmount
	                          .toFixed(2), "EUR")}
	                    </td>
	                    <td
	                      style={{width:"12%",textAlign:"center"}}
	                      className={checkedRows[index]
	                        .isClicked
	                        ? "selected_row" : ""}>
	                      {rewriteCurrency(item.calculation
	                        .claimedAmount.toFixed(2), "EUR")}
	                    </td>
	                  </tr>
	                </React.Fragment>
	              ))}
	            </tbody>
	          </table>
	        </div>
	      </div>
	      <Pagination
	        currentPage={requestParams.nextPage}
	        postsPerPage={pageLimit}
	        totalPosts={plan && plan.totalUser}
	        paginate={paginate}
	        limit={pageLimit}
	        changeLimits={(limit:number) => changePageLimit(limit)}
	      />
	    </>

	  )
	);
}
const mapStateToProps = (state:RootState) => {
  return {
    plan: state.plans.plan,
    searchData: state.pagesSearch.employeeSearchData,
    selectedRows: state.selectedRows.selectedRows
  };
};

export default connect(mapStateToProps, null)(PlanDetailsList);
