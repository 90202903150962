import i18n from "../i18next";
import moment from "moment/moment";


export const timeSince = (date:any) => {

  const seconds = Math.floor((new Date().getTime() - date.getTime()) / 1000);

  const today = new Date();

  if (moment(date).format("YYYY-MM-DD") !== moment(today).format("YYYY-MM-DD")) {
    if (moment().subtract(1, "days").format("YYYY-MM-DD") === moment(date).format("YYYY-MM-DD")) {
      return i18n.t("dashboard.notification.time_since.yesterday",
        {time: moment(date).format("HH:mm")});
    } else {
      return i18n.t("dashboard.notification.time_since.date", {
        date: moment(date).format("DD.MM"),
        time: moment(date).format("HH:mm")
      });
    }
  } else {
    let interval;
    interval = seconds / 3600;
    if (interval > 1) {
      if (interval >= 2) {
        return i18n.t("dashboard.notification.time_since.hours",
          {time: Math.floor(interval)});
      } else {
        return i18n.t("dashboard.notification.time_since.hour",
          {time: Math.floor(interval)});
      }
    }
    interval = seconds / 60;
    if (interval > 1) {
      if (interval >= 2) {
        return i18n.t("dashboard.notification.time_since.minutes",
          {time: Math.floor(interval)});
      } else {
        return i18n.t("dashboard.notification.time_since.minute",
          {time: Math.floor(interval)});
      }

    }
    return i18n.t("dashboard.notification.time_since.seconds",
      {time: Math.floor(seconds)});
  }
};
