// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multiple_invitation_modal_title {
    font-size: 18px;
}

.multiple_invitation_modal_text {
    font-size: 14px;
}

.selection_grid {
    max-width: 600px;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
}

.selection_grid_mail {
    font-size: 12px;
    background-color: #95e0fd;
    color: #ffffff;
    padding: 5px 10px;
    border-radius: 10px;
    margin-right: 7px;
    margin-bottom: 7px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/EmployeePage/MultipleUserInvitationModal/MultipleUserInvitationModal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,yBAAyB;IACzB,cAAc;IACd,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".multiple_invitation_modal_title {\n    font-size: 18px;\n}\n\n.multiple_invitation_modal_text {\n    font-size: 14px;\n}\n\n.selection_grid {\n    max-width: 600px;\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: space-around;\n}\n\n.selection_grid_mail {\n    font-size: 12px;\n    background-color: #95e0fd;\n    color: #ffffff;\n    padding: 5px 10px;\n    border-radius: 10px;\n    margin-right: 7px;\n    margin-bottom: 7px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
