import React from "react";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";

function SelectItemBox({items, allOptions, name} :any) {
  const { t } = useTranslation();
  if (name === "days") {
    return (
      allOptions
        ? <span>
          {t("dashboard.overview.filtering_all_days")}
        </span>
        : <span>
          {t("dashboard.overview.filtering_more_days", {days: items})}
        </span>
    );
  } else {
    return (
      allOptions
        ? <span>
          {t("dashboard.overview.filtering_all")}
        </span>
        : <span>
          {items} {t("dashboard.overview.filtering_more")}
        </span>
    );
  }
}


export default connect(null, null)(SelectItemBox);
