import React, { useEffect, useState } from "react";
import MainSpace from "../../Components/Containers/MainSpace";
import PageTitle from "../../Components/SimpleComponents/PageTitle";
import ContentContainer from "../../Components/Containers/ContentContainer";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import {
  getUsersForCardCreation,
  showUserLoading
} from "../../redux/actions/usersActions";
import { RootState } from "../../redux/reducers/rootReducer";
import Button from "../../Components/Buttons/Button";

import environment from "../../environment";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import { getWeavrCorporate } from "../../redux/actions/weavrActions";
import { Link, useNavigate } from "react-router-dom";
import CreateCardList from "Components/Lists/CreateCardList";
import { getToken } from "../../utils/cookies/tokensCookie";
import CreateCardSuccessModal from "../../Components/Modals/PaymentsPage/CreateCard/CreateCardSuccessModal";

interface IProps {
  users?: any;
  weavr?: any;
  company?: any;
}

function CreateCard(props: any) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [selectUserData, setSelectUserData] = useState([]);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const [filter, setFilter] = useState({
    search: {
      value: "",
      label: ""
    },
    status: {
      value: "",
      label: ""
    }
  });

  useEffect(() => {
    dispatch(getWeavrCorporate());
    dispatch(
      getUsersForCardCreation({
        limit: 8,
        skip: 0
      })
    );
  }, []);

  useEffect(() => {
    if (props.users && props.users.users) {
      const changedUsersArray: any = [];
      props.users.users.map((user: any) => {
        changedUsersArray.push({
          value: user._id,
          label: `${user.firstName} ${user.lastName}`
        });
      });
      setSelectUserData(changedUsersArray);
    }
  }, [props.users]);

  const [clearSelections, setClearSelections] = useState(false);

  const submitEmployees = async () => {
    setButtonLoading(true);
    const selectedIds = props.selectedRows.map((user: any) => user._id);
    if (selectedIds.length > 0) {
      try {
        const fullUrl = `${environment.baseUrl}payments/cards/multiple`;

        const bearerToken = getToken();
        const weavrToken = await getToken(true);

        const res = await axios.post(
          fullUrl,
          { token: weavrToken, users: selectedIds },
          {
            headers: { Authorization: `Bearer ${bearerToken}` }
          }
        );

        if (res.status == 200) {
          setShowSuccessModal(true);
          dispatch(
            getUsersForCardCreation({
              limit: 8,
              skip: 0
            })
          );
          setClearSelections(!clearSelections);
        }
        setButtonLoading(false);
      } catch (e: any) {
        setButtonLoading(false);
        toast.error(
          <CustomToastContainer
            message={t("dashboard.payments.create_card.statuses.error")}
            status={3}
          />,
          {
            autoClose: 5000
          }
        );
      }
    }
  };

  const handleSearch = (value: string) => {
    setFilter({ ...filter, search: { value: value, label: value } });
    dispatch(showUserLoading());
    dispatch(
      getUsersForCardCreation({
        limit: 8,
        skip: 0,
        search: value
      })
    );
  };

  return (
    <>
      {buttonLoading ? (
        <div
          style={{
            position: "absolute",
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            zIndex: 999999999
          }}
        />
      ) : (
        <></>
      )}

      {showSuccessModal
        ? <CreateCardSuccessModal
          showModal={showSuccessModal}
          onClose={() => setShowSuccessModal(false)}
        />
        : null
      }

      <ContentContainer>
        <MainSpace>
          <div className="row" data-testid="createCard_breadcrumb">
            <Link to={"/payments/overview"} style={{ textDecoration: "none" }}>
              <p className="crumb">Kartenliste</p>
            </Link>
            <p className="crumb_arrow">{">"}</p>
            <p className="crumb_current">{"Karte erstellen"}</p>
          </div>

          <div className="space-between">

            <div>
              <h3 className="payment_overview_header_container_tab_content_container_title">
                {t("dashboard.payments.create_card.title")}
              </h3>

              <p className="payment_overview_header_container_tab_content_container_sub_title">
                {t("dashboard.payments.create_card.text")}
              </p>
            </div>

            <Button
              disabled={props.selectedRows.length <= 0}
              buttonType="primary"
              buttonHtmlType="submit"
              buttonLabel={t("dashboard.payments.create_card.btn")}
              buttonHandler={async () => await submitEmployees()}
              loading={buttonLoading}
            />

          </div>

          <div style={{height: "30px"}} />

          <CreateCardList
            clearSelections={clearSelections}
            filter={{}}
            employee={props.users}
          />
        </MainSpace>
      </ContentContainer>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    users: state.users.users,
    weavr: state.weavr.weavrCorporate,
    company: state.company.company,
    employee: state.users.users,
    loading: state.users.loading,
    sidebar: state.sidebar.sidebarTabCondition,
    checkedBoxes: state.employeeCheckboxesState.selectedCheckboxes,
    departments: state.depsAndBranches.departments,
    selectedRows: state.selectedRows.selectedRows
  };
};

export default connect(mapStateToProps, null)(CreateCard);
