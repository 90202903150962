export const GET_ROLES_SUCCESS = "GET_ROLES_SUCCESS";
export const GET_USERS_WITH_ROLES_SUCCESS = "GET_USERS_WITH_ROLES_SUCCESS";
export const ADD_ROLE = "ADD_ROLE";
export const UPDATE_ROLE = "UPDATE_ROLE";
export const ROLES_ERROR = "ROLES_ERROR";
export const CLEAR_ROLE_MESSAGES = "CLEAR_ROLE_MESSAGES";

export interface getRolesSuccess {
	type: typeof GET_ROLES_SUCCESS
	payload: any
}

export interface getUsersWithRolesSuccess {
	type: typeof GET_USERS_WITH_ROLES_SUCCESS
	payload: any
}

export interface addRole {
	type: typeof ADD_ROLE
	payload: any
}

export interface updateRole {
	type: typeof UPDATE_ROLE
	payload: any
}

export interface rolesError {
	type: typeof ROLES_ERROR,
	payload: string
}

export interface clearRoleMessages {
	type: typeof CLEAR_ROLE_MESSAGES
}


export type rolesDispatchTypes =
	getRolesSuccess |
	getUsersWithRolesSuccess |
	addRole |
	updateRole |
	clearRoleMessages |
	rolesError




