import React, {useEffect} from "react";
import {useNavigate, useLocation} from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import environment from "../../environment";
import {getWeavrCorporate} from "../../redux/actions/weavrActions";
import {getToken} from "../../utils/cookies/tokensCookie";
import {useDispatch} from "react-redux";
import {urls} from "../../api/urls";

export default function VerifyWeavrPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const changeWeavrStatus = async (referenceId: string) => {
    const token = await getToken();
    const response = await axios.post(`${environment.baseUrl}${urls.payments.changeStatusForKYC}`,
      {
        referenceId: referenceId
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    if (response.data) {
      dispatch(getWeavrCorporate());
      navigate(`/payments?reference=${referenceId}`);
    }
  };

  useEffect(() => {
    if (location.search) {
      const parsedSearch = queryString.parse(location.search);
      if (parsedSearch && parsedSearch.reference) {
        changeWeavrStatus(parsedSearch.reference.toString());
      }
    }
  }, []);

  return(
    <div>
      Loading...
    </div>
  );
}
