import React, {useState} from "react";
import "./index.css";
import BannerTitle from "../../../Components/SimpleComponents/BannerWithTitle";
import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "../../../Components/Containers/MainSpace";
import {useTranslation} from "react-i18next";
import {useEffectOnce} from "../../../hooks/useEffectOnce";
import {partnerPortalService} from "../../../api/services/Partner-portal/partner-portal.service";
import {IPartnerPortalDeal} from "../../../api/services/Partner-portal/partner-portal.interface";
import NoRecordsFound from "../../../Components/Lists/NoRecordsList";
import TableLoading from "../../../Components/LoadingPlaceholders/TableLoading";
import moment from "moment";
import Pagination from "../../../Components/SimpleComponents/Pagination";
import ActionDots, {IActionDotsAction} from "../../../Components/Elements/ActionDots/ActionDots";
import Button from "../../../Components/Buttons/Button";
import {useNavigate} from "react-router-dom";
import CustomSelect from "../../../Components/SimpleComponents/Select";

interface IDealsData {
  data: IPartnerPortalDeal[];
  count: number;
}

export default function PartnerPortalDealsPage() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [deals, setDeals] = useState<null | IDealsData>(null);
  const [loading, setLoading] = useState(true);
  const [paginationState, setPaginationState] = useState({
    currentPage: 1,
    pageLimit: 8
  });

  useEffectOnce(() => {
    getData(0);
  });

  const getData = async (skip: number) => {
    const response = await new partnerPortalService().getDeals({limit: paginationState.pageLimit, skip});
    if (response && response.status && response.data) {
      setDeals(response.data);
    }
    setLoading(false);
  };

  const paginate = (page: number) => {
    setPaginationState({...paginationState, currentPage: page});
    getData((page - 1) * paginationState.pageLimit);
  };

  const openEmailAgent = (selectedDeal: IPartnerPortalDeal, type: "edit" | "deactivate") => {
    window.open(`mailto:operations@corplife.at?subject=deal-${selectedDeal._id}-${type}`);
  };

  const actionsData: IActionDotsAction[] = [
    {
      key: "edit",
      imageKey: "editUser",
      imageAlt: "edit",
      label: t("partner_portal.deals.index.actions.edit"),
      onClick: (rootState: IPartnerPortalDeal) => openEmailAgent(rootState, "edit")
    },
    {
      key: "deactivate",
      imageKey: "deactivateUser",
      imageAlt: "deactivate",
      label: t("partner_portal.deals.index.actions.deactivate"),
      onClick: (rootState: IPartnerPortalDeal) => openEmailAgent(rootState, "deactivate")
    }
  ];

  return(
    <ContentContainer>
      <BannerTitle
        mainTitle={t("partner_portal.deals.index.title")}
      />
      <MainSpace>

        <div
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end"
          }}
        >

          <CustomSelect
            style={{
              marginTop: "14px",
              marginBottom: "14px"
            }}
            options={[
              {
                label: t("partner_portal.deals.index.create_btn"),
                value: "all"
              },
              {
                label: t("partner_portal.deals.index.create_btn_online"),
                value: "online"
              },
              {
                label: t("partner_portal.deals.index.create_btn_local"),
                value: "local"
              }
            ]}
            icon="export"
            onChange={(option) => {
              navigate(`create?deal=${option}`);
            }}
          />
        </div>

        {loading
          ? <TableLoading />
          : deals && deals?.count < 1
            ? <NoRecordsFound text={t("warnings.dash_empty_table")} />
            : <div className="table_wrapper-overview">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers._id")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.name")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.startDate")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.endDate")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.status")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.type")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.downloads")}
                      </div>
                    </th>
                    <th>
                      <div>
                        {t("partner_portal.deals.index.list_headers.actions")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {deals?.data.map((item) => (
                    <React.Fragment key={item._id}>
                      <tr className="spacer" />
                      <tr
                        className={"table-item"}
                      >
                        <td>
                          <p>{item._id}</p>
                        </td>
                        <td>
                          <p>{item.name}</p>
                        </td>
                        <td>
                          <p>{moment.unix(item.startDate).format("DD.MM.YYYY")}</p>
                        </td>
                        <td>
                          <p>{moment.unix(item.endDate).format("DD.MM.YYYY")}</p>
                        </td>
                        <td>
                          <div
                            style={{ margin: "auto" }}
                            className={`status_dot ${
                              item.status
                                ? "green"
                                : "red"
                            }`}
                          />
                        </td>
                        <td>
                          <p>{item.B2B && "B2B"} {item.B2C && "B2C"}</p>
                        </td>
                        <td>
                          <p>{item.downloads}</p>
                        </td>
                        <td>
                          <ActionDots
                            actions={actionsData}
                            rootState={item}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>

              <Pagination
                currentPage={paginationState.currentPage}
                postsPerPage={paginationState.pageLimit}
                totalPosts={(deals && deals.count) || 0}
                paginate={paginate}
                limit={paginationState.pageLimit}
              />

            </div>
        }
      </MainSpace>
    </ContentContainer>
  );
}
