// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error_page {
    width: 100%;
    height: 100%;
    min-height: calc(100vh - 150px);
    background-color: #ECECEF;
}
.error_page_box {
    position: relative;
    width: 70vw;
    padding-top: 15vh;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}
.error_type {
    position: absolute;
    left: 6vw;
    top: 15vh;
    font-size: 30vw;
    color: #e3e3e9;
}
.error_page_title {
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 3rem;
}
.error_page_text {
    padding-top: 2rem;
    position: relative;
    z-index: 2;
    text-align: center;
    font-size: 1rem;
    font-weight: 500;
}
.error_page_box > div {
    position: relative;
    z-index: 2;
    margin-top: 3rem;
}
.error_btn {
    max-width: 305px;
    margin-top: 5vw!important;
    margin-left: auto;
    margin-right: auto;
}
`, "",{"version":3,"sources":["webpack://./src/pages/error_pages/NotFound.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,YAAY;IACZ,+BAA+B;IAC/B,yBAAyB;AAC7B;AACA;IACI,kBAAkB;IAClB,WAAW;IACX,iBAAiB;IACjB,iBAAiB;IACjB,kBAAkB;IAClB,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,eAAe;IACf,cAAc;AAClB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,eAAe;AACnB;AACA;IACI,iBAAiB;IACjB,kBAAkB;IAClB,UAAU;IACV,kBAAkB;IAClB,eAAe;IACf,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,UAAU;IACV,gBAAgB;AACpB;AACA;IACI,gBAAgB;IAChB,yBAAyB;IACzB,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".error_page {\n    width: 100%;\n    height: 100%;\n    min-height: calc(100vh - 150px);\n    background-color: #ECECEF;\n}\n.error_page_box {\n    position: relative;\n    width: 70vw;\n    padding-top: 15vh;\n    margin-left: auto;\n    margin-right: auto;\n    margin-bottom: 100px;\n}\n.error_type {\n    position: absolute;\n    left: 6vw;\n    top: 15vh;\n    font-size: 30vw;\n    color: #e3e3e9;\n}\n.error_page_title {\n    position: relative;\n    z-index: 2;\n    text-align: center;\n    font-size: 3rem;\n}\n.error_page_text {\n    padding-top: 2rem;\n    position: relative;\n    z-index: 2;\n    text-align: center;\n    font-size: 1rem;\n    font-weight: 500;\n}\n.error_page_box > div {\n    position: relative;\n    z-index: 2;\n    margin-top: 3rem;\n}\n.error_btn {\n    max-width: 305px;\n    margin-top: 5vw!important;\n    margin-left: auto;\n    margin-right: auto;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
