import React, { useState } from "react";
import "../SimpleComponents/TableSelectedItemActions.css";
import { useTranslation } from "react-i18next";
import freezeCardImage from "img/Dashboard/Payments/Overview/CardDetail/freeze_card.png";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {getToken} from "../../utils/cookies/tokensCookie";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";

interface IProps {
  selectedRows?: any;
  updateListData: () => void;
}

export default function CardsSelectedMenu(props:IProps) {
  const { t } = useTranslation();

  const [showMenu, setShowMenu] = useState(false);

  const freezeCard = () => {
    const token = getToken();
    const weavrToken = getToken(true);
    const selectedCardsIds = props.selectedRows.map((card: any) => card.id);
    axios.post(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.blockMultipleCards}`,
      {
        cards: selectedCardsIds,
        weavrToken: weavrToken
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      })
      .then((res:any) => {
        toast.success(
          <CustomToastContainer
            message={t("dashboard.payments.overview.bottom_tabs.cards.list.toasts.freeze_success")}
            status={1}
          />,
          {
            autoClose: 3000,
          }
        );
      })
      .catch((err:any) => {
        toast.error(
          <CustomToastContainer
            message={t("dashboard.payments.overview.bottom_tabs.cards.list.toasts.freeze_error")}
            status={3}
          />,
          {
            autoClose: 3000,
          }
        );
      }).finally(() => {
        props.updateListData();
        setShowMenu(false);
      });
  };

  return (
    <div className="selected_table_actions">
      <p>
        {props.selectedRows && props.selectedRows.length && props.selectedRows.length > 1
          ? `${props.selectedRows.length} ${t("dashboard.overview.selected_title_1")}`
          : `${props.selectedRows.length} ${t(
            "dashboard.overview.selected_title_1_single"
          )}`}
      </p>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={
          showMenu ? "selected_actions_dots selected" : "selected_actions_dots"
        }
      >
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
      </div>
      <div
        className={
          showMenu
            ? "selected_actions_menu shadow"
            : "selected_actions_menu_hide"
        }
        style={{
          bottom: -60
        }}
      >
        <div className="row" onClick={() => freezeCard()}>
          <img src={freezeCardImage} alt="freeze card"/>
          <p>{t("dashboard.payments.overview.bottom_tabs.cards.actions.freeze_card")}</p>
        </div>
      </div>
    </div>
  );
}
