import React, {Suspense} from "react";
import ReactDOM from "react-dom/client";
import {Provider} from "react-redux";
import "./index.css";
import App from "./App";
import "./i18next";
import Store from "./redux/store";
import DashboardLoading from "Components/LoadingPlaceholders/DashboardLoading";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <Provider store={Store}>
    <React.StrictMode>
      {/* Show loading until languages will be not initialized */}
      <Suspense fallback={<DashboardLoading />}>
        <App />
      </Suspense>
    </React.StrictMode>
  </Provider>
);

/*ReactDOM.render(
  ,
  document.getElementById("root")
);*/
