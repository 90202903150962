import { getToken } from "../../utils/cookies/tokensCookie";
import environment from "../../environment";
import axios from "axios";
import { errorHandler } from "../../utils/errorHandler";
import qs from "qs";
import {urls} from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

/*Post new company*/
export async function createNewCompany(data: any) {
  const token = getToken();
  const fullUrl = `${environment.baseUrl}${urls.general.companies}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    const request = await axios.post(fullUrl, data, options);
    return request.data;
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return {
        status: e.request.status,
        message: e.response.data.message,
      };
    }
  }
}

/*Get regions */
export async function getRegions(country: string) {
  const token = getToken();
  const companyId = getUserCompany();
  const fullUrl = `${environment.baseUrl}${urls.general.regionsByCompanyId}${companyId}`;
  const dynamicParams = {
    limit: 0,
    skip: 0,
    country: country
  };
  try {
    const request = await axios.get(fullUrl, {
      headers: { Authorization: `Bearer ${token}` },
      params: dynamicParams,
      paramsSerializer: (params: any) => qs.stringify(params),
    });
    return request.data.regions;
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      return e.request.status;
    }
  }
}
