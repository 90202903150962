import React from "react";
import {isAllowed, userRoles} from "../../../utils/permissionsAllow";
import {useTranslation} from "react-i18next";
import {RootState} from "../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import Switch from "../Switch/Switch";
import {IFormikCompanySettingsSwitcher} from "./CompanySettingsSwitcher.interface";
import FormikInput from "../../SimpleComponents/FormikInput";

export interface ICompanySettingsSwitcher {
  formik: IFormikCompanySettingsSwitcher;
  createCompany?: boolean;
  inputsDisabled?: boolean;
  company?: any;
}

function CompanySettingsSwitcher(props: ICompanySettingsSwitcher) {
  const { t } = useTranslation();

  return(
    <>

      {/*Virtual card*/}
      {props.createCompany ? (
        <Switch
          checked={props.formik.values.virtualCard}
          onChange={(value) => {
            props.formik.setFieldValue(
              "virtualCard",
              value,
              true
            );
          }}
          id={"approval"}
          label={t("dashboard.general.virtual_card_check_label")}
          disabled={props.inputsDisabled}
        />
      ) : null}

      {/*Budget correction*/}
      {props.createCompany ? (
        <Switch
          checked={props.formik.values.budgetCorrection}
          onChange={(value) => {
            props.formik.setFieldValue(
              "budgetCorrection",
              value,
              true
            );
          }}
          id={"budgetCorrection"}
          label={t("dashboard.general.budgetCorrection_check_label")}
          disabled={props.inputsDisabled}
        />
      ) : null}

      {/*Budget moving*/}
      {isAllowed({
        data: {
          roles: [userRoles.admin, userRoles.superAdmin],
        },
      }) && (
        <Switch
          checked={props.formik.values.budgetMoving}
          onChange={(value) => {
            props.formik.setFieldValue(
              "budgetMoving",
              value,
              true
            );
          }}
          id={"budgetMoving"}
          label={t("dashboard.general.budget_moving_check_label")}
          disabled={props.inputsDisabled}
        />
      )}

      {/*Approval process*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin],
        },
      }) ? (
          <Switch
            checked={props.formik.values.approvalProcess}
            onChange={(value) => {
              props.formik.setFieldValue(
                "approvalProcess",
                value,
                true
              );
            }}
            id={"approval"}
            label={t("dashboard.general.approval_check_label")}
            disabled={props.inputsDisabled}
          />
        ) : null}

      {/*OCR*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin],
        },
      }) ? (
          <Switch
            checked={props.formik.values.ocr}
            onChange={(value) => {
              props.formik.setFieldValue(
                "ocr",
                value,
                true
              );
            }}
            id={"ocr"}
            label={t("dashboard.general.ocr_check_label")}
            disabled={props.inputsDisabled}
          />
        ) : null}

      {isAllowed({
        data: {
          roles: [userRoles.superAdmin],
        },
      }) && props.formik.values.ocr ? (
          <FormikInput
            htmlFor="maxOcr"
            name="maxOcr"
            type={"number"}
            value={props.formik.values.maxOcr.toString()}
            disabled={false}
            handleChange={(event: any) => {
              props.formik.setFieldValue(
                "maxOcr",
                event.target.value,
                true
              );
            }}
            label={"OCR max. Limit / Monat"}
            style={{
              maxWidth: "247px"
            }}
          />
        ) : null}

      {/*Notifications*/}
      <Switch
        checked={props.formik.values.isNotificationAllowed}
        onChange={(value) => {
          props.formik.setFieldValue(
            "isNotificationAllowed",
            value,
            true
          );
        }}
        id={"notifications"}
        label={t("dashboard.general.notifications_check_label")}
        disabled={props.inputsDisabled}
      />

      {/*Mobile notifications*/}
      <Switch
        checked={props.formik.values.notifications}
        onChange={(value) => {
          props.formik.setFieldValue(
            "notifications",
            value,
            true
          );
        }}
        id={"mobileNotifications"}
        label={t("dashboard.general.notifications_check_label_mobile")}
        disabled={props.inputsDisabled}
      />

      {/*Allow supermarket*/}
      <Switch
        checked={props.formik.values.isSupermarketAllowed}
        onChange={(value) => {
          props.formik.setFieldValue(
            "isSupermarketAllowed",
            !props.formik.values.isSupermarketAllowed,
            true
          );
        }}
        id={"isSupermarketAllowed"}
        label={t("dashboard.general.isSupermarketAllowed")}
        disabled={props.inputsDisabled}
      />

      {/*Virtual card upload restaurant invoices*/}
      {props.company?.virtualCard
        &&
        <Switch
          checked={props.formik.values.virtualCardUploadRestaurantInvoices}
          onChange={(value) => {
            props.formik.setFieldValue(
              "virtualCardUploadRestaurantInvoices",
              value,
              true
            );
          }}
          id={"virtualCardUploadRestaurantInvoices"}
          label={t("dashboard.general.upload_restaurant_invoice_check_label")}
          disabled={props.inputsDisabled}
        />
      }

      {/*Test account*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin],
        },
      }) &&
      (props.createCompany || props.company?.canBeDeleted) &&
        <Switch
          checked={props.formik.values.canBeDeleted}
          onChange={(value) => {
            props.formik.setFieldValue(
              "canBeDeleted",
              value,
              true
            );
          }}
          id={"canBeDeleted"}
          label={"Testkonto"}
          disabled={props.inputsDisabled}
        />
      }

      {/*Restaurant invoices is required for virtual card*/}
      {props.formik.values.virtualCard &&
        <Switch
          checked={props.formik.values.isManualInvoiceForVirtualCardAllowed}
          onChange={(value) => {
            props.formik.setFieldValue(
              "isManualInvoiceForVirtualCardAllowed",
              value,
              true
            );
          }}
          id={"isManualInvoiceForVirtualCardAllowed"}
          label={t("dashboard.general.isManualInvoiceForVirtualCardAllowed")}
          disabled={props.inputsDisabled}
        />
      }

      {/*Cumulation is allowed*/}
      <Switch
        checked={props.formik.values.isCumulationAllowed}
        onChange={(value) => {
          props.formik.setFieldValue(
            "isCumulationAllowed",
            !props.formik.values.isCumulationAllowed,
            true
          );
        }}
        id={"isCumulationAllowed"}
        label={t("dashboard.general.isCumulationAllowed")}
        disabled={props.inputsDisabled}
      />

      {isAllowed({
        data: {
          roles: [userRoles.superAdmin],
        },
      }) ? (
          <div style={{ marginTop: "20px" }}>
            <h4>{t("dashboard.general.input_item_modules")}</h4>
            <Switch
              checked={props.formik.values.isLunchActivated}
              onChange={(value) => {
                props.formik.setFieldValue(
                  "isLunchActivated",
                  value,
                  true
                );
              }}
              id={"modules"}
              label={t("dashboard.general.modules_lunch_check_label")}
              disabled={props.inputsDisabled}
            />

            <Switch
              checked={props.formik.values.isBenefitsActivated}
              onChange={(value) => {
                props.formik.setFieldValue(
                  "isBenefitsActivated",
                  value,
                  true
                );
              }}
              id={"modules"}
              label={t("dashboard.general.modules_benefits_check_label")}
              disabled={props.inputsDisabled}
            />
          </div>
        ) : null}
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    company: state.company.company.company
  };
};

export default connect(mapStateToProps, null)(CompanySettingsSwitcher);
