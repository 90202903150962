import React from "react";

interface CustomArray {
	[index: string]: string
}

interface CustomProps {
	sortingData: (sortItem:string) => void
	text: string
	name: string
	sortButton: CustomArray
}

export default function TableSortedHeadItem(
  {
    sortingData,
    text,
    name,
    sortButton
  }:CustomProps) {
  return (
    <div className="row responsive-mr-40">
      {text}
      <div onClick={() => sortingData(name)}>
        <div className={
          sortButton[name] === "up"
            ? "list-triangle top selected"
            : "list-triangle top"}>
					&#9662;</div>
        <div className={
          sortButton[name] === "down"
            ?
            "list-triangle bottom selected"
            :
            "list-triangle bottom"}>&#9662;
        </div>
      </div>
    </div>
  );
}
