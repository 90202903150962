import React from "react";
import "./PageTitle.css";
import Spacer from "./Spacer";


interface TitleProps {
	mainTitle: string,
	subTitle?: string,
}

const PageTitle = ({mainTitle, subTitle}:TitleProps) => {
  return(
    <div className="page-header">
      <h2>{mainTitle}</h2>
      {subTitle && <Spacer spacerHeight="14px" />}
      {subTitle && <p>{subTitle}</p>}
    </div>
  );
};

export default PageTitle;
