import React from "react";
import "./Inputs.css";

interface CustomInputProps {
	id: string
	name: string
	placeholder?: string
	inputValue?: string
	isDisabled?: boolean
	specialType?: string
	handleChange: (inputString:string, id:string) => void
	nameHTML?: string
	style?: any
	inputStyle? : any,
	autoFocus?: boolean,
	max?: number
}

export default function SimpleInput(
  {id, name, placeholder, isDisabled, inputValue, max,
    specialType, handleChange, nameHTML, style, autoFocus, inputStyle}
	:CustomInputProps) {
  return (
    <div
      style={style}
      className="simple_input">
      <label
        className="simple_label"
        htmlFor={id}
      >
        {name}
      </label>
      <input
        autoFocus={autoFocus}
        style={inputStyle}
        name={nameHTML}
        type="text"
        className={specialType + " simple_input"}
        placeholder={placeholder}
        disabled = {isDisabled}
        value = {inputValue}
        onChange={event => handleChange(event.target.value, id)}
        maxLength={max}
      />
    </div>
  );
}
