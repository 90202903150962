import React, { useState } from "react";
import "../SimpleComponents/TableSelectedItemActions.css";
import penIcon from "img/Dashboard/pen_icon.png";
import deactivateIcon from "img/Dashboard/Employee/deactivate_employee.svg";
import inviteUserIcon from "img/Dashboard/Employee/invite_user_2.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import axios from "axios";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { toast } from "react-toastify";
import { getToken } from "utils/cookies/tokensCookie";

interface CustomProps {
  data: any;
  openModal: (type: string) => void;
  user: any;
  type: "employee" | "company"
}

export default function EmployeeSelectedMenu(props: CustomProps) {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);

  const getUsersStatus = () => {
    return props.data.map((user: any) => user.status);
  };
  
  return (
    <div className="selected_table_actions">
      <p data-testid="string_item">
        {props.data.length && props.data.length > 1
          ? `${props.data.length} ${props.type === "employee" ? t("dashboard.employee.selected_title_1") : t("hrzone.employee.selected")}`
          : `${props.data.length} ${props.type === "employee" ? t("dashboard.employee.selected_title_1_single") : t("hrzone.employee.selectedSingle")}`}
      </p>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={
          showMenu ? "selected_actions_dots selected" : "selected_actions_dots"
        }
      >
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
      </div>
      <div
        className={
          showMenu
            ? "selected_actions_menu shadow"
            : "selected_actions_menu_hide"
        }
        style={props.data.length > 1 ? { bottom: -71} : {}}
      >
        {props.data.length > 1 ? (
          <></>
        ) : (
          <div
            data-testid="employee_select_menu_plans"
            onClick={() => props.openModal("edit")}
          >
            <img src={penIcon} alt="edit" />
            <p>{"Bearbeiten"}</p>
          </div>
        )}

        <div onClick={() => props.openModal("delete")}>
          <img src={deactivateIcon} alt="deactivate" />
          <p>{"Löschen"}</p>
        </div>

        {getUsersStatus().includes(false) && props.type === "employee" ? (
          <div onClick={() => props.openModal("invite")}>
            <img src={inviteUserIcon} alt="invite" />
            <p>{t("dashboard.employee.selected_menu_3")}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
