import React from "react";
import "./BannerWithTitle.css";


interface TitleProps {
    mainTitle: string,
    subTitle?: string,
}

const BannerTitle = ({mainTitle, subTitle}:TitleProps) => {
  return(
    <div className="dashboard_main_banner">
      <div className="banner_title">
        <h2>{mainTitle}</h2>
        {subTitle && <p>{subTitle}</p>}
      </div>
    </div>
  );
};

export default BannerTitle;
