import React from "react";
import "./ButtonWithIcon.css";


interface ButtonProps {
    buttonType: string,
	buttonLabel?: string,
	buttonIcon: string,
	iconPadding?: string
	buttonHandler?: () => void,
	paddingTop?: string,
	buttonStyles?: any,
	disabled?: boolean
}

const ButtonWithIcon = ({
  buttonType,
  buttonLabel,
  buttonIcon,
  iconPadding,
  paddingTop,
  buttonStyles,
  disabled,
  buttonHandler}:ButtonProps) => {
  return(
    disabled
      ? <div
        style={buttonStyles}
        className={"btn icon " + buttonType + " disabled"}>
        <div className="row">
          <img
            className="btn_icon"
            src={"/images/icon-" + buttonIcon + ".svg"}
            style={iconPadding || paddingTop
              ? {paddingRight:iconPadding, paddingTop:paddingTop}
              : undefined}
            alt={buttonIcon}
          />
          { buttonLabel
            ? <p>{buttonLabel}</p>
            : ""
          }
        </div>
      </div>
      :
      <div
        style={buttonStyles}
        onClick={buttonHandler && buttonHandler}
        className={"btn icon " + buttonType}>
        <div className={buttonLabel ? "row" : "center"}>
          <img
            className={buttonLabel ? "btn_icon" : ""}
            src={"/images/icon-" + buttonIcon + ".svg"}
            style={iconPadding || paddingTop
              ? {paddingRight:iconPadding, paddingTop:paddingTop}
              : undefined}
            alt={buttonIcon}
          />
          { (buttonLabel)
            ? <p>{buttonLabel}</p>
            : ""
          }
        </div>
      </div>
  );
};

export default ButtonWithIcon;
