import React, {useEffect, useState} from "react";
import "./PaymentsOverviewHeaderContainerTransactionsBottomTab.css";
import {getToken} from "../../../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../../../environment";
import {urls} from "../../../../../../../api/urls";
import Table, {ITableHeader} from "../../../../../../Elements/Table/Table";
import {rewriteCurrency} from "../../../../../../../utils/rewriteCurrency";
import Badge, {BadgeColorsEnum} from "../../../../../../Elements/Badge/Badge";
import {useTranslation} from "react-i18next";
import getOutgoingWireTransferStatusName, {OutgoingWireTransferStateType} from "../../../../../../../utils/virtualCards/getOutgoingWireTransferStatusName";

export default function PaymentsOverviewHeaderContainerTransactionsBottomTab() {
  const { t } = useTranslation();

  const [transactions, setTransactions] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [pageLimit, setPageLimit] = useState(8);
  const [currentPage, setCurrentPage] = useState(1);

  const getColors = (category: OutgoingWireTransferStateType): BadgeColorsEnum => {
    switch (category) {
    case "COMPLETED":
      return BadgeColorsEnum.green;
    case "SUBMITTED":
      return BadgeColorsEnum.blue;
    case "APPROVED":
      return BadgeColorsEnum.blue;
    case "PENDING_CHALLENGE":
      return BadgeColorsEnum.yellow;
    case "REJECTED":
      return BadgeColorsEnum.red;
    case "FAILED":
      return BadgeColorsEnum.red;
    }
  };

  const getTransactions = async (data: {skip: number, limit: number}) => {
    setLoading(true);
    const token = getToken();
    const weavrToken = getToken(true);
    const dynamicParams = {
      limit: data.limit || 8,
      skip: data.skip || 0
    };
    axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.getOutgoingWireTransactions}?weavrToken=${weavrToken}`,
      {
        headers: {Authorization: `Bearer ${token}`},
        params: dynamicParams
      })
      .then((res: any) => {
        if (res.data) {
          setTransactions(res.data);
        }
      })
      .catch((e: any) => {
        console.error(e.response);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTransactions({skip: 0, limit: pageLimit});
  }, []);

  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      const nextPage = (pageNumber - 1) * pageLimit;
      setCurrentPage(nextPage);
      getTransactions({skip: nextPage, limit: pageLimit});
    }
  };

  const tableHeaders:ITableHeader[] = [
    {
      id: "date",
      name: t("dashboard.payments.overview.header_component.bottom_tabs_content.outgoing_transactions.list.date"),
      render: (value: any) => {
        return(<div>
          <p>{value}</p>
        </div>);
      }
    },
    {
      id: "time",
      name: t("dashboard.payments.overview.header_component.bottom_tabs_content.outgoing_transactions.list.time"),
      render: (value: any) => {
        return(<div>
          <p>{value}</p>
        </div>);
      }
    },
    {
      id: "destinationName",
      name: t("dashboard.payments.overview.header_component.bottom_tabs_content.outgoing_transactions.list.destination_name"),
      render: (value: any) => {
        return(<div>
          <p>{value}</p>
        </div>);
      }
    },
    {
      id: "destinationIban",
      name: t("dashboard.payments.overview.header_component.bottom_tabs_content.outgoing_transactions.list.destination_iban"),
      render: (value: any) => {
        return(<div>
          <p>{value}</p>
        </div>);
      }
    },
    {
      id: "state",
      name: t("dashboard.payments.overview.header_component.bottom_tabs_content.outgoing_transactions.list.status"),
      render: (value: any) => {
        return(<div>
          <Badge
            color={getColors(value)}
            text={getOutgoingWireTransferStatusName(value)}
          />
        </div>);
      }
    },
    {
      id: "amount",
      name: t("dashboard.payments.overview.header_component.bottom_tabs_content.outgoing_transactions.list.amount"),
      render: (value: any) => {
        return(<div>
          <p>{rewriteCurrency(value.toFixed(2), "EUR")}</p>
        </div>);
      }
    }
  ];

  return(
    <Table
      headers={tableHeaders}
      data={transactions?.data || []}
      totalData={transactions?.total || 0}
      paginate={(page: number) => paginate(page)}
      dataIdentifier={"processedTimestamp"}
      loading={loading}
    />
  );
}
