import React from "react";
import closeIcon from "img/Dashboard/Employee/close_btn.svg";
import {connect} from "react-redux";
import Spacer from "../SimpleComponents/Spacer";
import Button from "../Buttons/Button";
import warningIcon from "img/Dashboard/PlansAndModules/plans_warning.svg";
import { useTranslation } from "react-i18next";

interface CustomProps {
  closeModal: (state:boolean) => void,
  invoiceCount: number
}

function StopPlanModal({closeModal, invoiceCount}:CustomProps) {
  const { t } = useTranslation();

  return (
    <div className="modal_container">
      <img
        onClick={() => closeModal(false)}
        className="close_popup"
        src={closeIcon} alt="close"
      />
      <Spacer spacerHeight="30px" />
      <div className="center">
        <img src={warningIcon} alt="warning"/>
      </div>
      <Spacer spacerHeight="30px" />
      <h2>
        {t("dashboard.plans.stop_module_title")}
      </h2>
      <p style={{fontWeight: "normal", fontSize: "0.8rem"}}>
        {t("dashboard.plans.stop_module_text", {count: invoiceCount})}
      </p>
      <div className="center" style={{marginTop: "50px"}}>
        <Button
          buttonType="primary"
          buttonLabel={t("dashboard.plans.stop_module_btn")}
          buttonHandler={() => closeModal(false)}
        />
      </div>
    </div>
  );
}

export default connect(null, null)(StopPlanModal);
