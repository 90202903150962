import {GET_HRZONEAUTH_SUCCESS, GET_HRZONEAUTH_ERROR, CLEAR_HRZONEAUTH, hrZoneAuthTypes} from "../types/hrZoneAuthTypes";

const initialState = {
  sessionKey: "",
  isMasterHr: 0,
  url: "",
  fetched :false
};

export const hrZoneAuthReducer = (
  state = initialState,
  action:hrZoneAuthTypes) => {
  switch (action.type) {
  case GET_HRZONEAUTH_SUCCESS:
    return{
      url: action.payload.url,
      sessionKey: action.payload.session_key,
      isMasterHr: action.payload.isMasterHr,
      fetched: action.payload.fetched
    };
  case GET_HRZONEAUTH_ERROR:
    return{
      url: "",
      sessionKey: "",
      isMasterHr: 0,
      fetched: true
    };

  case CLEAR_HRZONEAUTH:
    return initialState;
  default: return state;
  }
};