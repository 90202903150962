import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "../../redux/reducers/rootReducer";
import BeatLoader from "react-spinners/BeatLoader";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  clearDeactivatePlanMessages,
  deactivatePlan,
  plansData,
  postPlansData,
  showPlansLoading
} from "../../redux/actions/plansActions";
import moment from "moment/moment";
import { PropaneSharp } from "@mui/icons-material";
import { isAllowed, userPermissions, userRoles } from "utils/permissionsAllow";

interface CustomProps {
  data: any;
  deactivateState?: any;
  style?: any;
}

function PlansActionDots({ data, deactivateState, style }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actionRef: any = useRef();

  const [menuListOpen, setMenuListOpen] = useState(false);
  // loading after delete
  const [showLoading, setShowLoading] = useState(false);

  // disable loading/close menu after plan was updated
  useEffect(() => {
    if (
      deactivateState.success === "deactivate_plan" ||
      deactivateState.error.length === "deactivate_plan"
    ) {
      setMenuListOpen(false);
      setShowLoading(false);
      dispatch(clearDeactivatePlanMessages());
    }
  }, [deactivateState]);

  const deactivatePlanHandler = () => {
    dispatch(deactivatePlan(data.plan._id));
    setShowLoading(true);
  };

  const PlansActionsList = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!PlansActionsList.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  useEffect(() => {
    if (menuListOpen) {
      actionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuListOpen]);

  return showLoading ? (
    <BeatLoader size="8px" />
  ) : (
    <div ref={PlansActionsList} className="action_dots_container" style={style}>
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container"
        ref={actionRef}
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          {moment(data.plan.endDate).toDate() > moment(new Date()).toDate() ? (
            data.plan.isActive ? (
              <>
                <div
                  onClick={deactivatePlanHandler}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_1")}</p>
                </div>
                <div
                  onClick={() => navigate(`/plans/update/${data.plan._id}`)}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_3")}</p>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={deactivatePlanHandler}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_2")}</p>
                </div>
                <div
                  onClick={() => navigate(`/plans/update/${data.plan._id}`)}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_3")}</p>
                </div>
              </>
            )
          ) : null}
          <div
            onClick={() => navigate(`/plans/${data.plan._id}`)}
            className="table_menu_item row"
          >
            <p>{t("dashboard.plans.plans_menu_4")}</p>
          </div>
          {/*<div
  const copyPlan = () => {
    const newPlan: any = {
      userId: data.plan.userId,
      companyId: data.plan.companyId,
      isActive: false,
      startDate: null,
      endDate: null,
      type: data.plan.type,
      title: "Copy of " + data.plan.title,
      totalAmount: data.plan.totalAmount,
      employees: data.plan.employees,
      generalLimit: data.plan.generalLimit,
      supermarket: data.plan.supermarket,
      restaurant: data.plan.restaurant
    };

    const postData: any = {
      plans: [newPlan]
    };

    dispatch(postPlansData(postData));
    dispatch(showPlansLoading());
    dispatch(plansData({}));
  };

  return showLoading ? (
    <BeatLoader size="8px" />
  ) : (
    <div ref={PlansActionsList} className="action_dots_container" style={style}>
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container"
        ref={actionRef}
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          {moment(data.plan.endDate).toDate() > moment(new Date()).toDate() ? (
            data.plan.isActive ? (
              <>
                <div
                  onClick={deactivatePlanHandler}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_1")}</p>
                </div>
                <div
                  onClick={() => navigate(`/plans/update/${data.plan._id}`)}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_3")}</p>
                </div>
              </>
            ) : (
              <>
                <div
                  onClick={deactivatePlanHandler}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_2")}</p>
                </div>
                <div
                  onClick={() => navigate(`/plans/update/${data.plan._id}`)}
                  className="table_menu_item row"
                >
                  <p>{t("dashboard.plans.plans_menu_3")}</p>
                </div>
              </>
            )
          ) : null}
          <div
            onClick={() => navigate(`/plans/${data.plan._id}`)}
            className="table_menu_item row"
          >
            <p>{t("dashboard.plans.plans_menu_4")}</p>
          </div>
          {/*<div
              onClick={() => navigate("/plans/create", {state: data.plan})}
              className="table_menu_item row">
              <p>{t("dashboard.plans.plans_menu_5")}</p>
            </div>
          </div>
        )}
      </div>*/}
          {isAllowed({
            data: {
              roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.users]
              }
            }
          }) ? (
              <div
                onClick={() =>
                  navigate("/plans/create/", { state: { _id: data.plan._id } })
                }
                className="table_menu_item row"
              >
                <p>{"Plan kopieren"}</p>
              </div>
            ) : (
              <></>
            )}
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    deactivateState: state.plans
  };
};

export default connect(mapStateToProps, null)(PlansActionDots);
