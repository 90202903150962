import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import "./payments.css";
import MainSpace from "../../Components/Containers/MainSpace";
import ContentContainer from "../../Components/Containers/ContentContainer";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {getCard, getTransactions} from "../../redux/actions/paymentsActions";
import {RootState} from "../../redux/reducers/rootReducer";
import {getToken} from "../../utils/cookies/tokensCookie";
import environment from "../../environment";
import axios from "axios";
import {toast} from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import TransactionsList from "../../Components/Lists/TransactionsList";
import {urls} from "../../api/urls";
import CardDetailsSelectedMenu from "../../Components/SelectedMenu/CardDetailsSelectedMenu";
import CardDetailTopSectionCard
  from "../../Components/Pages/Payments/CardDetail/TopSections/CardDetailTopSectionCard";
import MonthlySpendTopSectionCard
  from "../../Components/Pages/Payments/CardDetail/TopSections/MothlySpendTopSectionCard";
import DailySpendTopSectionCard
  from "../../Components/Pages/Payments/CardDetail/TopSections/DailySpendTopSectionCard";
import CustomSelect from "../../Components/SimpleComponents/Select";


interface IProps {
  card?: any
}

function OverviewDetail({card}:any) {
  const navigate = useNavigate();
  const location = useLocation();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [cardIsBlocked, setCardIsBlocked] = useState(false);

  // Set card blocked state
  useEffect(() => {
    if (card.card.state && card.card.state.state && card.card.state.state === "BLOCKED") {
      setCardIsBlocked(true);
    } else {
      setCardIsBlocked(false);
    }
  }, [card.card.state]);

  useEffect(() => {
    if (
      location
      && location.pathname
      && location.pathname.split("/")[3]
      && location.pathname.split("/")[3] !== "create") {
      dispatch(getCard(location.pathname.split("/")[3]));
      dispatch(getTransactions({
        id: location.pathname.split("/")[3],
        limit: 8,
        skip: 0,
        sortBy: "DESC"
      }));
    }
  }, []);

  const handleDownload = (option: string) => {
    let type: string;
    if (option === "xlsx") {
      type = "1";
    }
    if (option === "csv") {
      type = "2";
    }

    const postData = async () => {
      const weavrToken = await getToken(true);
      await axios.get(
        `${environment.baseUrl}${urls.files.cardTransactions}`,
        {
          headers: {Authorization: `Bearer ${getToken()}`},
          responseType: "blob",
          params: {
            cardId: location.pathname.split("/")[3],
            exportType: type,
            weavrToken: weavrToken
          }
        }
      ).then((res: any) => {
        try{
          let fileName = "Corplife_transactions.csv";
          let fileType = "application/csv";

          if (type === "1") {
            fileName = "Corplife_transactions.xlsx";
            fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          }
          if (type === "2") {
            fileName = "Corplife_transactions.csv";
            fileType = "application/csv";
          }

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file:any;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();

        }catch(e){
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      });
    };
    postData();
  };


  return(
    <ContentContainer>
      <MainSpace>

        <div className="row">
          <p onClick={() => navigate("/payments/overview")} className="crumb" test-id="plans_detail_crumb">
            {t("dashboard.payments.card_detail.breadcrumbs")}
          </p>
          <p className="crumb_arrow">{">"}</p>
          <p className="crumb_current">{card.card?.friendlyName}</p>
        </div>

        <div style={{height: "20px"}} />

        <div className="space-between">
          <div className="payments_card_detail_header">
            <h1>
              {t("dashboard.payments.card_detail.title", {name: card.card.friendlyName})}
            </h1>
            <h2>
              {t("dashboard.payments.card_detail.description")}
            </h2>
          </div>

          <CardDetailsSelectedMenu
            cardIsActive={!cardIsBlocked}
          />

        </div>

        <div style={{height: "30px"}} />

        <div className="payments_card_detail_top_section">
          <CardDetailTopSectionCard
            card={card.card}
            hideTutorial
          />
          <MonthlySpendTopSectionCard />

          <DailySpendTopSectionCard />

        </div>

        <div style={{height: "40px"}} />

        <div className="space-between">
          <div className="payments_card_detail_header">
            <h1>
              {t("dashboard.payments.card_detail.transactions_list.title")}
            </h1>
            <h2>
              {t("dashboard.payments.card_detail.transactions_list.text")}
            </h2>
          </div>

          <CustomSelect
            options={[t("dashboard.overview.export") as string, "csv", "xlsx"]}
            icon="export"
            onChange={handleDownload}
            buttonStyle={{
              backgroundColor: "#707070"
            }}
          />

        </div>

        <div style={{height: "40px"}} />

        <TransactionsList
          cardId={location.pathname.split("/")[3]}
        />

      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    card: state.payments
  };
};

export default connect(mapStateToProps, null)(OverviewDetail);
