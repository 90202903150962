import firebase from "firebase/app";
import "firebase/auth";
import "firebase/analytics";
import "firebase/performance";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
}; //this is where your firebase app values you copied will go

process.env.NODE_ENV !== "test" && firebase.initializeApp(firebaseConfig);

export const auth = process.env.NODE_ENV !== "test" ? firebase.auth() : undefined;
export const analytics = process.env.NODE_ENV !== "test" ? firebase.analytics() : undefined;
export const performance = process.env.NODE_ENV !== "test" ? firebase.performance() : undefined;
