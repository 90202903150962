export const GET_MERCHANTS_SUCCESS = "GET_MERCHANTS_SUCCESS";
export const GET_MERCHANTS_ERROR = "GET_MERCHANTS_ERROR";

export interface getMerchantsSuccess {
	type: typeof GET_MERCHANTS_SUCCESS
	payload: any
}

export interface getMerchantsError {
	type: typeof GET_MERCHANTS_ERROR
}


export type getMerchantsDispatchTypes = getMerchantsSuccess | getMerchantsError
