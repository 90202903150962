import {Dispatch} from "redux";
import environment from "../../environment";
import axios from "axios";
import {
  ADD_ROLE,
  CLEAR_ROLE_MESSAGES,
  GET_ROLES_SUCCESS,
  GET_USERS_WITH_ROLES_SUCCESS,
  ROLES_ERROR,
  rolesDispatchTypes, UPDATE_ROLE
} from "../types/rolesTypes";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import {IRole} from "../../Interfaces/IRole";
import customHistory from "../../customHistory";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import {urls} from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
interface IGetUsersWithRolesFilters {
  limit?:number;
  skip?:number;
  search?:string;
  sortBy?: string;
  [key: string]: any;
}



// Get roles
export const getRoles = (limit?:number, skip?:number) =>
  async (dispatch: Dispatch<rolesDispatchTypes>) => {
    const dynamicParams = {
      limit: limit || 8,
      skip: skip || 0,
    };
    await new reduxRequestHandler({
      url: "roles",
      dynamicParams,
      successDispatchType: GET_ROLES_SUCCESS,
      errorDispatchType: ROLES_ERROR,
      errorPayload: "get_roles"
    }).get(dispatch);
  };

// Get users with roles
export const getUsersWithRoles = (filters:IGetUsersWithRolesFilters) =>
  async (dispatch: Dispatch<rolesDispatchTypes>) => {
    const companyId = getUserCompany();
    const dynamicParams = {
      limit: filters.limit,
      skip: filters.skip,
    };
    Object.keys(filters).map((key:any) => {
      if (filters[key] && filters[key].length) {
        Object.assign(dynamicParams, {[key]: filters[key]});
      }
    });
    await new reduxRequestHandler({
      url: `roles/byUser?companyId=${companyId}`,
      dynamicParams,
      successDispatchType: GET_USERS_WITH_ROLES_SUCCESS,
      errorDispatchType: ROLES_ERROR,
      errorPayload: "users_with_roles"
    }).get(dispatch);
  };

//Add role
export const addRole = (data:IRole) =>
  async (dispatch: Dispatch<rolesDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.roles.roles}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    try {
      await axios.post(fullUrl , data, options)
        .then((response) => {
          dispatch({
            type: ADD_ROLE,
            payload: "add_role"
          });
        }, (error) => {
          console.error(error);
        });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: ROLES_ERROR,
          payload: "add_role"
        });
      }
    }
  };

// Update role
export const updateRole = (data:IRole, roleId:string) =>
  async (dispatch: Dispatch<rolesDispatchTypes>) => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.roles.roles}/${roleId}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    try {
      await axios.put(fullUrl , data, options)
        .then((response) => {
          dispatch({
            type: UPDATE_ROLE,
            payload: "update_role"
          });
        }, (error) => {
          dispatch({
            type: ROLES_ERROR,
            payload: "update_role"
          });
        });
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: ROLES_ERROR,
          payload: "update_role"
        });
      }
    }
  };


// Clear Messages
export function clearRoleMessages() {
  return {
    type: CLEAR_ROLE_MESSAGES
  };
}
