import React from "react";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import {useEffect, useState} from "react";
import environment from "../../environment";
import { urls } from "api/urls";
import {getToken} from "../../utils/cookies/tokensCookie";
import axios from "axios";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import {useTranslation} from "react-i18next";
import PaymentsOverviewHeaderContainer
  from "../../Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/PaymentsOverviewHeaderContainer";
import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "../../Components/Containers/MainSpace";
import PaymentsOverviewHeaderContainerCardsBottomTab
  from "../../Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/BottomTabs/PaymentsOverviewHeaderContainerCardsBottomTab/PaymentsOverviewHeaderContainerCardsBottomTab";
import PaymentsOverviewHeaderContainerDepositBottomTab
  from "../../Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/BottomTabs/PaymentsOverviewHeaderContainerTransactionsBottomTab/PaymentsOverviewHeaderContainerDepositBottomTab";
import AntTabs from "../../Components/Payments/Shared/Tabs/AntTabs";
import CustomSelect from "../../Components/SimpleComponents/Select";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import PaymentsOverviewHeaderContainerTransactionsBottomTab
  from "../../Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/BottomTabs/PaymentsOverviewHeaderContainerDepositBottomTab/PaymentsOverviewHeaderContainerTransactionsBottomTab";
import PaymentsOverviewHeaderContainerTransactionHistoryTab
  from "../../Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/BottomTabs/PaymentsOverviewHeaderContainerTransactionHistoryTab/PaymentsOverviewHeaderContainerTransactionHistoryTab";


interface IProps {
  company?: any;
  weavr?: any;
  showLogin: () => void;
}

function PaymentOverview({company, weavr, showLogin}:IProps) {
  const { t } = useTranslation();

  const [createManagedAccountWasInitiated, setCreateManagedAccountWasInitiated] = useState(false);
  const [selectedBottomTab, setSelectedBottomTab] = useState<"cards" | "transactions" | "transactionsHistory" | "deposit" >("cards");

  const createWeavrManagedAccount = async () => {
    setCreateManagedAccountWasInitiated(true);
    const fullUrl = `${environment.baseUrl}${urls.payments.createManagedAccount}`;
    const userToken = await getToken();
    const options = {headers: {"Authorization": `Bearer ${userToken}`}};
    const weavrToken = await getToken(true);
    if (weavrToken) {
      await axios.post(
        fullUrl,
        {
          companyId: company.company.id,
          token: weavrToken
        },
        options)
        .catch((e: any) => {
          console.error(e);
        });
    }
  };

  useEffectOnce(() => {
    if (company && company.company && company.company.id) {
      if (weavr && Object.keys(weavr).length > 0 && !weavr.managedAccountId && !createManagedAccountWasInitiated) {
        createWeavrManagedAccount();
      }
    }
  });

  const renderBottomTabContent = () => {
    switch (selectedBottomTab) {
    case "cards":
      return <PaymentsOverviewHeaderContainerCardsBottomTab />;
    case "transactions":
      return <PaymentsOverviewHeaderContainerTransactionsBottomTab />;
    case "transactionsHistory":
      return <PaymentsOverviewHeaderContainerTransactionHistoryTab showLogin={() => showLogin()} />;
    case "deposit":
      return <PaymentsOverviewHeaderContainerDepositBottomTab />;
    }
  };

  const tabs = [
    {
      value: "cards",
      label: t("dashboard.payments.overview.header_component.bottom_tabs.cards")
    },
    {
      value: "transactions",
      label: t("dashboard.payments.overview.header_component.bottom_tabs.transactions")
    },
    {
      label: t("dashboard.payments.overview.header_component.tabs.transaction_history"),
      value: "transactionsHistory"
    },
    {
      value: "deposit",
      label: t("dashboard.payments.overview.header_component.bottom_tabs.deposit")
    }
  ];

  return(
    <ContentContainer>
      <MainSpace>
        <PaymentsOverviewHeaderContainer showLogin={() => showLogin()} />
        <div style={{height: "40px"}} />

        <div className="payment_overview_header_container_header">
          <div className="space-between" style={{width: "100%"}}>
            <AntTabs
              selectedTab={selectedBottomTab}
              onChange={(newValue: any) => setSelectedBottomTab(newValue)}
              tabs={tabs}
            />
          </div>
        </div>

        <div style={{height: "20px"}} />
        {renderBottomTabContent()}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    weavr: state.weavr.weavrCorporate,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(PaymentOverview);

