import React, {useState} from "react";
import "../UpdateStatus.css";
import {useTranslation} from "react-i18next";
import ArrowIcon from "img/Dashboard/Orders/arrow-right-solid.svg";
import moment from "moment";
import disabledIcon from "img/Dashboard/Orders/disabled_update_icon.svg";
import activeIcon from "img/Dashboard/Orders/active_update_icon.svg";
import calendarIcon from "img/Dashboard/Orders/update_calendar_icon.svg";
import clockIcon from "img/Dashboard/Orders/clock_calendar_item.svg";
import UserAvatar from "../UserAvatar";
import Button from "../../Buttons/Button";
import {modalStyles} from "../../../utils/modalStyles";
import closeIcon from "img/Dashboard/Employee/close_btn.svg";
import Modal from "react-modal";

interface CustomProps {
  data: any
}

export default function PlansChangelog(props:CustomProps) {
  const { t } = useTranslation();

  const [showModal, setShowModal] = useState(false);

  return(
    <div className="update_info_container">
      <Modal style={modalStyles} isOpen={showModal}>
        <div className="changelog_modal white">
          <img
            onClick={() => setShowModal(false)}
            style={{ top: "0px" }}
            className="close_popup_paddings"
            src={closeIcon}
            alt="close"
          />
          <div className="changelog_wrapper">
            <ul className="sessions">
              {props.data.map((changelog:any, i:number) => (
                <li key={`${changelog.updatedAt}${i.toString()}`}>
                  <div className="time">
                    {moment(changelog.updatedAt).format("DD.MM.YYYY HH:mm")}
                  </div>
                  <div className="row" style={{marginTop: "7px"}}>
                    <UserAvatar small imageUrl={changelog.updatedBy.image} />
                    <p className="changelog_name" style={{
                      marginBottom: "3px",
                      marginLeft: "10px",
                      marginRight: "40px"
                    }}
                    >
                      {changelog.updatedBy.name}
                    </p>
                    <div className="row">
                      <p data-testid="update_info_title_status" className="update_info_title">
                        {changelog.action === "amount"
                          ? t("dashboard.plans.billing_page.changelog.action.amount")
                          : changelog.key && changelog.key.length > 0
                            ? t(`dashboard.plans.billing_page.changelog.action.${changelog.key}`)
                            : t("dashboard.plans.billing_page.changelog.action.days")
                        }
                      </p>
                      <div className="badge accept_badge">
                        {changelog.action === "amount"
                          ? `€ ${Number(changelog.data.oldValue).toFixed(2).replace(".", ",")}`
                          : changelog.data.oldValue
                        }
                      </div>
                      <img
                        data-testid="update_info_between_image"
                        src={ArrowIcon}
                        alt="arrow"
                        className="update_info_arrow"
                      />
                      <div className="badge accept_badge">
                        {changelog.action === "amount"
                          ? `€ ${Number(changelog.data.newValue).toFixed(2).replace(".", ",")}`
                          : changelog.data.newValue
                        }
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </Modal>
      {props.data && props.data.length
        ?
        <>
          <div className="update_info_hint">
            <div className="space-between">
              <p data-testid="update_info_title_updated_by" className="update_info_title">
                {t("dashboard.overview.update_info.updated_by")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <UserAvatar imageUrl={props.data[0].updatedBy.image} small/>
                <p data-testid="update_info_text_name" className="update_info_text">
                  {props.data[0].updatedBy.name}
                </p>
              </div>
            </div>
            <div className="space-between" style={{marginTop: "20px"}}>
              <p data-testid="update_info_title_updated_at" className="update_info_title">
                {t("dashboard.overview.update_info.updated_at")}
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <div className="row">
                  <img
                    data-testid="update_info_image"
                    src={calendarIcon}
                    width="25px"
                    alt="calendar"
                  />
                  <p data-testid="update_info_text_date" className="update_info_text">
                    {moment(props.data[0].updatedAt).format("DD.MM.YYYY")}
                  </p>
                </div>
                <div className="row" style={{marginLeft: "10px"}}>
                  <img
                    data-testid="update_info_image_time"
                    src={clockIcon}
                    width="25px"
                    alt="clock"
                  />
                  <p data-testid="update_info_text_time" className="update_info_text">
                    {moment(props.data[0].updatedAt).format("HH:mm")}
                  </p>
                </div>
              </div>
            </div>
            <div className="space-between" style={{marginTop: "20px"}}>
              <p data-testid="update_info_title_status" className="update_info_title">
                {props.data[0].action === "amount"
                  ? t("dashboard.plans.billing_page.changelog.action.amount")
                  : props.data[0].key && props.data[0].key.length > 0
                    ? t(`dashboard.plans.billing_page.changelog.action.${props.data[0].key}`)
                    : t("dashboard.plans.billing_page.changelog.action.days")
                }
              </p>
              <div className="row" style={{marginLeft: "15px", width: "100%"}}>
                <div className="row">
                  <div className="badge accept_badge">
                    {props.data[0].action === "amount"
                      ? `€ ${Number(props.data[0].data.oldValue).toFixed(2).replace(".", ",")}`
                      : props.data[0].data.oldValue
                    }
                  </div>
                  <img
                    data-testid="update_info_between_image"
                    src={ArrowIcon}
                    alt="arrow"
                    className="update_info_arrow"
                  />
                  <div className="badge accept_badge">
                    {props.data[0].action === "amount"
                      ? `€ ${Number(props.data[0].data.newValue).toFixed(2).replace(".", ",")}`
                      : props.data[0].data.newValue
                    }
                  </div>
                </div>
              </div>
            </div>
            <div style={{
              backgroundColor: "#A2ADBF",
              width: "100%",
              height: "1px",
              marginTop: "15px",
              marginBottom: "15px"
            }} />
            <div className="flex-end">
              <Button
                buttonLabel={t("dashboard.overview.update_info.changelog_btn")}
                buttonType="outlined"
                buttonHandler={() => setShowModal(true)}
              />
            </div>
          </div>
          <img src={activeIcon} className="update_info_icon" alt="info"/>
        </>
        : <img src={disabledIcon} className="update_info_icon" alt="info"/>
      }
    </div>
  );
}
