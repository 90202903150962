// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.summary_container {
    width: 100%;
    margin-left: 15px;
    transform: translateY(-65px);
}
@media (max-width: 800px) {
    .plans_container {
        max-width: 87vw;
    }
    .summary_container {
        max-width: 87vw;
        margin-left: 0;
    }
}
.benefit_summary_title {
    font-size: 1.3rem;
    color: #0E0A40;
    margin-bottom: 29px;
}
.summary_count_text {
    font-size: 0.9rem;
    font-weight: 700;
}
.summary_total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 15px;
}

.summary_total > h2 {
    font-size: 1rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/Benefitsummary.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,4BAA4B;AAChC;AACA;IACI;QACI,eAAe;IACnB;IACA;QACI,eAAe;QACf,cAAc;IAClB;AACJ;AACA;IACI,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;AACA;IACI,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".summary_container {\n    width: 100%;\n    margin-left: 15px;\n    transform: translateY(-65px);\n}\n@media (max-width: 800px) {\n    .plans_container {\n        max-width: 87vw;\n    }\n    .summary_container {\n        max-width: 87vw;\n        margin-left: 0;\n    }\n}\n.benefit_summary_title {\n    font-size: 1.3rem;\n    color: #0E0A40;\n    margin-bottom: 29px;\n}\n.summary_count_text {\n    font-size: 0.9rem;\n    font-weight: 700;\n}\n.summary_total {\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    margin-top: 15px;\n}\n\n.summary_total > h2 {\n    font-size: 1rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
