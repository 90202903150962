import React, {useEffect, useRef, useState} from "react";
import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import PageTitle from "Components/SimpleComponents/PageTitle";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import { handleSidebar } from "../../redux/actions/sidebarActions";
import SearchBarLight from "../../Components/SimpleComponents/SearchBarLight";
import OrdersList from "../../Components/Lists/OrdersList";
import EmptyTableState from "../../Components/SimpleComponents/TableEmptyState";
import OrdersSelectedMenu from "../../Components/SelectedMenu/OrdersSelectedMenu";
import useWindowDimensions from "../../utils/useWindowSize";
import {
  clearDownloadFileMessages,
  postDownloadFile,
} from "../../redux/actions/downloadFileActions";
import CustomSelect from "../../Components/SimpleComponents/Select";
import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../utils/permissionsAllow";
import { useTranslation } from "react-i18next";
import {
  clearInvoicesMessages,
  getInvoices,
  showInvoicesLoading,
} from "../../redux/actions/invoicesActions";
import TableLoading from "../../Components/LoadingPlaceholders/TableLoading";
import { changeInvoicesFiltering } from "../../redux/actions/filteringActions";
import Modal from "react-modal";
import ChangelogModal from "../../Components/Modals/ChangeLogModal";
import { toast } from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import { modalStyles } from "../../utils/modalStyles";
import FilteringBlock from "../../Components/Blocks/FilteringBlock";
import InvoicesFilters from "../../Components/Blocks/InvoicesFilters/InvoicesFilters";
import {getUserId} from "../../utils/cookies/userCookies/userCookies";
import InvoiceOverviewTopCards from "../../Components/Blocks/InvoiceOverviewTopCards/InvoiceOverviewTopCards.component";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";

interface CustomProps {
  loading?: any;
  invoices?: any;
  selectedRows?: any;
  detail?: string;
  updatedInvoices?: any;
  userId?: boolean;
  filteringState?: any;
  downloadState?: any;
}

function OverviewUsers({
  loading,
  invoices,
  selectedRows,
  detail,
  updatedInvoices,
  userId,
  filteringState,
  downloadState,
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const ref: any = useRef();
  const lunchSettings = new getLunchSettings();

  const [showInvoiceDetail, setShowInvoiceDetail] = useState(false);

  const [clearSelections, setClearSelections] = useState<boolean>(false);

  useEffect(() => {
    if (downloadState.error === "download_invoices") {
      toast.error(
        <CustomToastContainer
          message={t("warnings.download_invoices")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
      dispatch(clearDownloadFileMessages());
    }
  }, [downloadState]);

  useEffect(() => {
    if (detail?.length) {
      setShowInvoiceDetail(true);
    } else {
      setShowInvoiceDetail(false);
    }
  }, [detail]);

  // getting data
  useEffect(() => {
    dispatch(showInvoicesLoading());
    if (userId) {
      const changedObj = { ...filteringState, employees: [getUserId()] };
      dispatch(changeInvoicesFiltering(changedObj));
      dispatch(getInvoices(changedObj));
    } else {
      dispatch(getInvoices(filteringState));
    }
  }, [dispatch, userId]);

  const handleSearchBar = (value: string) => {
    const updatedState = { ...filteringState, search: value };
    dispatch(showInvoicesLoading());
    dispatch(changeInvoicesFiltering(updatedState));
    dispatch(getInvoices(updatedState));
  };

  // Export files
  const handleDownload = (option: string) => {
    let type;
    if (option === "csv") {
      type = 2;
    }
    if (option === "xlsx") {
      type = 1;
    }
    dispatch(
      postDownloadFile(
        0,
        0,
        filteringState.branches,
        filteringState.departments,
        filteringState.employees,
        filteringState.merchants,
        filteringState.status,
        filteringState.startDate,
        filteringState.endDate,
        type
      )
    );
  };

  // refresh data after update invoices

  useEffect(() => {
    if (updatedInvoices.success === "update_invoices") {
      if (!showInvoiceDetail) {
        dispatch(clearInvoicesMessages());
      }
      dispatch(getInvoices(filteringState));
    }
    //Need to disable it, because eslint says, that I need to add
    //all dependencies from requestParams
    // eslint-disable-next-line
  }, [updatedInvoices]);

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 1000) {
      dispatch(handleSidebar(false));
    }
  }, [width, dispatch]);

  // Show hide changelog modal
  const [showChangelogModal, setShowChangelogModal] = useState(false);
  const [changeLogData, setChangeLogData] = useState([]);
  const showChangeLog = (data: any) => {
    setChangeLogData(data);
    setShowChangelogModal(true);
  };

  return (
    <ContentContainer>
      <MainSpace>
        <Modal style={modalStyles} isOpen={showChangelogModal}>
          <ChangelogModal
            closeModal={() => setShowChangelogModal(false)}
            changeLogData={changeLogData}
          />
        </Modal>

        <PageTitle mainTitle={t("dashboard.overview.title")} />

        {
          lunchSettings.isOcr()
          && isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.invoices],
              },
            },
          })
          &&
          <InvoiceOverviewTopCards ref={ref} />
        }

        <div
          className="space-between_plans"
          style={{ marginBottom: "15px", marginTop: "20px", alignItems: "start" }}
        >
          <div>
            {selectedRows.length &&
            isAllowed({
              data: {
                roles: [
                  userRoles.superAdmin,
                  userRoles.admin,
                  userRoles.custom,
                ],
                permissions: {
                  isAdmin: true,
                  permissionsArray: [userPermissions.invoices],
                },
              },
            }) &&
              lunchSettings.isApprovalProcess() ? (
                <OrdersSelectedMenu
                  updateSelections={() => {
                    setClearSelections(true);
                    setTimeout(() => {
                      ref.current.getData();
                    }, 300);
                  }}
                />
              ) : (
                <div style={{ minHeight: "40px" }}></div>
              )}
          </div>

          <div>
            {isAllowed({
              data: {
                roles: [
                  userRoles.superAdmin,
                  userRoles.admin,
                  userRoles.custom,
                  userRoles.viewer,
                ],
                permissions: {
                  isAdmin: true,
                  permissionsArray: [userPermissions.invoices],
                },
              },
            }) ? (
                <div className="flex-end" style={{ marginBottom: "1rem" }}>
                  <CustomSelect
                    options={[t("dashboard.overview.export") as string, "csv", "xlsx"]}
                    icon="export"
                    onChange={handleDownload}
                  />
                </div>
              ) : null}
            <SearchBarLight
              key={JSON.stringify(filteringState.search)}
              defaultValue={filteringState.search}
              handleSearchBar={handleSearchBar}
              searchPlaceholder={t("dashboard.overview.search")}
            />
          </div>
          {/*<PageTitle mainTitle={t("dashboard.plans.plans_title")} />
          {isAllowed({
            data: {
              roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.plans],
              },
            },
          }) ? (
            <ButtonWithIcon
              buttonType="primary add-plan"
              buttonIcon="add_plan"
              paddingTop="20px"
              buttonHandler={() => navigate("/plans/create")}
            />
          ) : null}*/}
        </div>
        <div>
          <FilteringBlock title={t("dashboard.plans.filters.title")}>
            <InvoicesFilters />
          </FilteringBlock>

          {loading ? (
            <TableLoading />
          ) : (
            <React.Fragment>
              {invoices && invoices.data && invoices.data.length > 0 ? (
                <OrdersList
                  clearSelections={clearSelections}
                  updateClearSelections={(value: boolean) =>
                    setClearSelections(value)
                  }
                  invoiceState={updatedInvoices}
                  showChangeLog={showChangeLog}
                  pullData={() => {
                    ref.current.getData();
                  }}
                />
              ) : (
                <EmptyTableState />
              )}
            </React.Fragment>
          )}
        </div>
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    invoices: state.invoices.invoices,
    sidebar: state.sidebar.sidebarTabCondition,
    loading: state.invoices.loading,
    selectedRows: state.selectedRows.selectedRows,
    updatedInvoices: state.invoices,
    filteringState: state.filters.invoiceFilters,
    downloadState: state.downloadFileState,
  };
};

export default connect(mapStateToProps, null)(OverviewUsers);
