import React, {useState} from "react";
import NumberFormat from "react-number-format";
import {useTranslation} from "react-i18next";
import image from "img/Dashboard/Card/weavr_onborading_verification.svg";
import {getToken} from "../../../../utils/cookies/tokensCookie";
import {RootState} from "../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import Button from "../../../Buttons/Button";
import {paymentsService} from "../../../../api/services/Payments/payments.service";

interface IProps {
  onSuccess: () => void;
  onError: () => void;
}

function VerifySCA(props: IProps) {
  const { t } = useTranslation();
  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);
  const [code, setCode] = useState("");

  const handleSvnInput = async (e:any) => {
    const inputNumber = e.target.value;
    setCode(inputNumber.toString().split(" ").join(""));
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (code.length === 6) {
      setShowSubmitButtonLoading(true);
      const weavrToken = await getToken(true);
      if (weavrToken) {
        const verifyStepUpResponse = await new paymentsService().verifyStepUp(weavrToken, code);
        if (verifyStepUpResponse?.status) {
          props.onSuccess();
        } else {
          props.onError();
        }
      }
    }
  };

  return(
    <form className="weavr_passcode_container" onSubmit={(e) => handleSubmit(e)}>
      <div className="center">
        <div className="verification_form_content">
          <h1>{t("dashboard.payments.onboarding.stepper.sms_verification.title")}</h1>
          <p>{t("dashboard.payments.onboarding.stepper.sms_verification.text")}</p>
          <div className="verification_form_content_input">
            <p>{t("dashboard.payments.onboarding.stepper.sms_verification.label")}</p>
            <NumberFormat
              disabled={false}
              className="code_input"
              format="# # # # # #"
              onChange={(e:any) => handleSvnInput(e)}
            />
          </div>

          <div style={{height: "15px"}} />

          <Button
            buttonType="primary"
            buttonLabel={t("dashboard.payments.onboarding.stepper.sms_verification.btn")}
            buttonHtmlType="submit"
            submitButton={true}
            fullWidth={true}
            buttonStyles={{maxWidth: "250px"}}
            disabled={code.length < 6}
            loading={showSubmitButtonLoading}
          />
        </div>
      </div>
      <div className="center">
        <img src={image} alt="verification"/>
      </div>
    </form>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    weavr: state.weavr.weavrCorporate
  };
};

export default connect(mapStateToProps, null)(VerifySCA);
