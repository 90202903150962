import "../../../Components/Lists/ListStyles.css";
import "../style.css";

import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import { changeSidebarTab, handleSidebar } from "redux/actions/sidebarActions";
import { RootState } from "redux/reducers/rootReducer";
import useWindowDimensions from "utils/useWindowSize";

interface CustomProps {
  types?: number;
}

const customStyles = {
  placeholder: () => {
    return {
      backgroundColor: "white",
      paddingLeft: 16,
      paddingRight: 16,
      paddingTop: 16,
      paddingBottom: 16
    };
  }
};

function AgbsPage({ types }: CustomProps) {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  const agb = `<html>
  <head> </head>

  <body>
    <h1>AGB</h1>
    <p><br /></p>
    <p>1. scope of application</p>
    <p>
      1.1 Corplife GmbH, Wasserzeile 27, 3400 Klosterneuburg, FN 442616 g
      (hereinafter &quot;Licensor&quot; or &quot;Corplife&quot;), licenses
      software produced by it for the management of the lunch allowance of a
      Partner Company (&quot;Licensee&quot;) in connection with &quot;tax-free
      lunch allowance&quot;, active users of the App, and persons who receive
      access to the App &quot;Corplife Lunch&quot; from the Partner Company (idf
      &quot;Users&quot;).
    </p>
    <p><br /></p>
    <p>
      1.2 Corplife and the Partner Company have entered into a license agreement
      for the use of Corplife Lunch by employees, active users of the App, and
      persons who receive access to the &quot;Corplife Lunch&quot; App from the
      Partner Company (idf &quot;Users&quot;). The Partner Company is the
      licensee of the Corplife Lunch software and (idf) the employer of the
      Users who use Corplife Lunch on their smartphones and to whom these Terms
      of Use apply.
    </p>
    <p><br /></p>
    <p>
      1.3 The User may only use Corplife Lunch if he/she has agreed to these
      Terms of Use and shall refrain from using Corplife Lunch if he/she has not
      agreed to the Terms of Use.
    </p>
    <p><br /></p>
    <p>2. contents of Corplife Lunch</p>
    <p>
      2.1 The Corplife Lunch application software consists of an app (iOS and
      Android) and a web admin area.
    </p>
    <p><br /></p>
    <p>
      - App Corplife Lunch: Users of the Licensee have the possibility to claim
      the tax-free allowance for lunches through the app Corplife Lunch. Users
      can use the app to upload invoices and thereby claim their tax-free lunch
      allowance provided by the licensee.
    </p>
    <p><br /></p>
    <p>
      - Web - Admin Area: Through the web admin area, the licensee can view
      various data (supermarket vs. restaurants usage ratio), create exports for
      payroll and manage their users.
    </p>
    <p><br /></p>
    <p>3. services of the licensor</p>
    <p>
      3.1 Within the scope of the License Agreement, Corplife shall provide the
      Partner Company with the non-exclusive right to use the &quot;Corplife
      Lunch&quot; application software, which right shall be limited to the term
      of the contractual relationship and shall not be transferable to third
      parties, subject to the applicable payment terms. The Partner Company has
      the right to use Corplife Lunch for its business purposes, i.e. to record
      and account for the tax-free lunch allowance from its employment
      relationship with Users. Neither the Partner Company nor the User shall
      receive any rights of use to source codes (source code) or other
      development documentation. Corplife shall be entitled to all rights to
      Corplife Lunch derived from patent, trademark, design protection and/or
      copyright. The User is entitled to use the App to collect the documents
      and data listed under item 2. in support of the Licensee&apos;s business
      purposes. The forwarding of data to the Licensee shall only take place in
      accordance with the agreements between Corplife and the Licensee, as well
      as in accordance with the consent granted.
    </p>
    <p><br /></p>
    <p>
      3.2 It is noted that Corplife Lunch, its form and its contents are
      protected by copyright. Any use of Corplife Lunch beyond the scope of this
      Agreement shall not be permitted without the express written consent of
      Corplife. Corplife shall be entitled to continuously adapt Corplife Lunch
      in accordance with technical and legal circumstances.
    </p>
    <p><br /></p>
    <p>
      3.3 The Corplife Lunch software shall be made available to the User around
      the clock (7x24), if possible. However, Corplife shall be entitled to
      limit the availability by times to fulfill maintenance and update work.
    </p>
    <p><br /></p>
    <p>
      3.4 The Partner Company shall be granted the right to use Corplife Lunch
      by its Users as of the conclusion of the License Agreement or as of a
      later date agreed in the License Agreement, for the duration of the
      License Agreement. Corplife shall provide access and registration
      facilities for Users named by the Partner Company.
    </p>
    <p><br /></p>
    <p>
      3.5 Corplife shall be responsible for the technical and content
      maintenance of the Corplife Lunch software. Corplife shall be available to
      the User by e-mail or telephone (support) between 9:00 a.m. and 6:00 p.m.
      on weekdays for any queries in connection with the use of or any problems
      arising in connection with the technical processing of the expense
      recording by Corplife Lunch.
    </p>
    <p><br /></p>
    <p>4 Duties of the User and important information</p>
    <p>
      4.1 The User is only entitled to use Corplife Lunch after consultation
      with the Partner Company and only during a valid employment relationship
      with the Partner Company. If the User leaves the employment relationship
      with the Partner Company or if the Partner Company prohibits the use of
      Corplife Lunch by the User, the User is no longer permitted to use
      Corplife Lunch. In such cases, the User shall of its own accord
      immediately delete Corplife Lunch from its smartphone and confirm the
      deletion of Corplife Lunch to the Partner Company in writing, if the
      Partner Company requests confirmation of the deletion. The User is not
      entitled to use Corplife Lunch to collect private data and receipts.
    </p>
    <p><br /></p>
    <p>
      4.2 The Corplife Lunch software is the exclusive intellectual property of
      Corplife. The User undertakes to refrain from doing anything that would
      enable him, the Partner Company or third parties to imitate the Corplife
      Lunch software, the structure of Corplife Lunch or the form of
      presentation of individual database contents (layout/design). These
      obligations shall apply without restriction even after termination of the
      service relationship with the Partner Company. The User shall be liable
      for damages caused by copyright infringements for which he is responsible.
    </p>
    <p><br /></p>
    <p>
      4.3 The User acknowledges that Corplife Lunch serves only as a support for
      the management of the tax-free lunch allowance as well as for the
      recording of receipts, but can in no way replace careful bookkeeping by
      the Partner Company or the User in connection with the fulfillment of
      legal obligations. In particular, the User is obliged to keep the
      originals (e.g. invoices, receipts) on which the lunch allowance
      settlements with the Partner Company are based in order to be able to fall
      back on these originals in the event of an input, transmission or software
      error.
    </p>
    <p><br /></p>
    <p>
      4.4 The User acknowledges that the amounts calculated by Corplife Lunch
      are only approximate values, which have no claim to completeness or
      accuracy. In particular, Corplife does not warrant and shall not be liable
      for the fact that the amounts calculated by Corplife Lunch actually
      represent reimbursable expenses in relation to the Partner Company.
    </p>
    <p><br /></p>
    <p>5. data protection and reference to the privacy policy</p>
    <p>
      5.1 Corplife undertakes to comply with the relevant data protection
      provisions as amended from time to time and to require its employees to
      comply with such provisions and to take and maintain the necessary
      technical and organizational measures to ensure data protection. The
      Corporate Partner shall ensure the security measures for the protection of
      personal data provided for under the relevant data protection provisions
      as amended from time to time.
    </p>
    <p><br /></p>
    <p>
      5.2 Corplife processes the data of its Corporate Partners (contact
      persons, contact details, company, contractual terms) for the purpose of
      fulfilling the respective contract. This data is processed for the term of
      the contract and thereafter for the retention period required by tax law.
      With regard to the employee data of the corporate partners, Corplife is
      the order processor under data protection law and the corporate partner is
      the controller. The Order Processor Agreement attached to these GTC is an
      integral part of this Agreement. Details of data processing can be found
      in Corplife&apos;s Privacy Policy as amended from time to time, available
      at https://www.corplife.at/agbs/datenschutz.
    </p>
    <p><br /></p>
    <p>6 Warranty and Liability</p>
    <p>
      6.1 Corplife has created the Corplife Lunch Software with the greatest
      possible care, reliability and availability. However, Corplife does not
      assume any liability and warranty for the correctness of the contents, for
      the completeness of the software as well as the accuracy, correctness and
      legal conformity of the expense reports, nor for the correctness,
      completeness and authenticity of the receipts or the expenses themselves.
      This applies in particular in the event that the reimbursement of expenses
      is disputed between the User and the Partner Company. Corplife shall also
      not be liable for the accuracy of the document reproduction generated by
      the OCR software used in the document capture.
    </p>
    <p><br /></p>
    <p>
      6.2 Corplife shall endeavor to ensure the functionality of Corplife Lunch
      and the technically smooth operation of its use by the User. Corplife
      shall not be liable for ensuring that the Corplife Lunch software is
      always accessible without interruption and that all stored data can be
      retrieved when required.
    </p>
    <p><br /></p>
    <p>
      6.3 Any liability of Corplife under warranty, damages, or general civil
      law claims arising from the defective or faulty provision of goods or
      services by cooperation partners of Corplife is excluded. Any claims
      against Corplife&apos;s cooperation partners shall remain unaffected.
    </p>
    <p><br /></p>
    <p>
      6.4 Corplife endeavors to select its partner restaurants with the utmost
      care. However, Corplife does not guarantee and is not liable for the
      quantity or quality of the food and beverages offered by the partner
      restaurant or the services associated with the restaurant visit. In
      particular, Corplife does not warrant and is not liable for the Partner
      Restaurant&apos;s compliance with commercial or other legal regulations.
    </p>
    <p><br /></p>
    <p>
      6.5 Corplife shall not be liable for any direct or indirect tax
      obligations that may arise from the provision of Corplife through the use
      of Corplife Lunch at home or abroad.
    </p>
    <p><br /></p>
    <p>
      6.6 Any liability of Corplife shall be excluded unless the damage was
      caused by intent or particularly gross negligence. Claims for damages by
      the Licensee, irrespective of their legal basis, in particular in case of
      culpa in contrahendo, tort, inability or impossibility of performance, as
      well as liability for consequential damages and damages resulting from
      advice given when using Corplife Lunch or damages resulting from software
      program errors, furthermore damages resulting from the use or
      impossibility of use of Corplife Lunch or a software program behind it,
      are excluded, unless they are caused by intent or particularly gross
      negligence. These limitations of liability apply without exception to
      business interruption, loss of data or information, loss of business
      profits, loss of business information or other financial loss. In any
      event, any damages shall be limited to two times the annual fee payable by
      the Partner Company for the use of Corplife Lunch under the License
      Agreement. Corplife Lunch shall not be liable for any damage resulting
      from a faulty Internet connection.
    </p>
    <p><br /></p>
    <p>
      6.7 The Licensor further excludes any direct or indirect liability towards
      the Partner Company according to the provisions of the Consumer Protection
      Law.
    </p>
    <p><br /></p>
    <p>
      6.8 Any damage resulting from the unauthorized use of the Corplife Lunch
      software shall be borne by the Partner Company and the User. The Partner
      Company and the User shall also bear the costs of the legal defense of
      such claims, which may be asserted against Corplife, the Partner Company
      or the User itself.
    </p>
  </body>
</html>
`;

  const agbLunch = `<html>
<head> </head>

<body>
  <h1>AGB Lunch Karten</h1>
  <p><br /></p>
  <p>BUSINESS DEBIT CARD Agreement &ndash; Corporate clients</p>
  <p>Introduction</p>
  <p>
    These terms and conditions (together with the Tariff and any other documents
    incorporated by reference, the&nbsp;&ldquo;Agreement&rdquo;) set out the terms
    and conditions that apply to your Account, Card and any other associated
    services provided to you (&ldquo;Customer&rdquo;, &ldquo;you&rdquo;,
    &ldquo;your&rdquo;), in your capacity as a legal entity, in relation to your
    business operations, and the individuals designated by you
    (&ldquo;Cardholder/s&rdquo;) by Paynetics AD (&ldquo;Paynetics&rdquo;,
    &ldquo;we&rdquo; &ldquo;us&rdquo;, &ldquo;our&rdquo;) under this Agreement.
  </p>
  <p>
    This Agreement constitutes a legal contract between you and Paynetics. It
    contains important information that may affect your rights, use of any of our
    services and your ability to recover your money. Please read all of the
    documents forming part of this Agreement carefully and keep a copy or download
    a copy of them for your records and future reference.
  </p>
  <p>
    You can request a copy of this Agreement at any time throughout its duration
    by contacting Customer Relations Centre. By submitting your order for a Card
    to Paynetics and by clicking &ldquo;I Accept&rdquo; in the relevant
    box,&nbsp;you indicate that you have accepted this Agreement.&nbsp;
  </p>
  <p>
    You should read this Agreement alongside the Privacy Policy which you can view
    here&nbsp;<a href="https://paynetics.digital/privacy-and-security-policy/"
      >https://paynetics.digital/privacy-and-security-policy/</a
    >.&nbsp;Our Privacy Policy (as it may be amended from time to time in
    accordance with its terms), together with this Agreement, describes the basis
    upon which any personal data we collect about you, either directly or from our
    authorised partners or that you provide to us or them, will be processed,
    handled and shared by us.
  </p>
  <ol>
    <li>
      <p>DEFINITIONS</p>
    </li>
  </ol>
  <p>In this Agreement:</p>
  <p>
    &ldquo;Account&rdquo; means any e-money payment account opened and maintained
    for you by Paynetics under this Agreement.&nbsp;
  </p>
  <p>&ldquo;App&rdquo; means either Mobile App and / or Web App as available.</p>
  <p>
    &nbsp;&ldquo;ATM&quot;&nbsp;means an automated teller machine that can be used
    for cash withdrawals using a Card, and sometimes for other payment and
    non-payment transactions.
  </p>
  <p>
    &ldquo;Business Debit Card&rdquo;&nbsp;or&nbsp;&ldquo;Card&rdquo;&nbsp;means
    any Paynetics debit Mastercard&copy; card for corporate clients (business
    customers) issued by Paynetics that is linked to your Account(s). The Card is
    a type of a payment instrument which allows you to access the Account's
    balance to make debit card payments. The Card may be physical or virtual.
  </p>
  <p>
    &ldquo;Card Organisation&rdquo;&nbsp;means Mastercard International
    (&lsquo;MasterCard&rsquo;), VISA Europe (&lsquo;VISA&rsquo;) or any other
    organisation maintaining a card payment system under which Card payments are
    processed.
  </p>
  <p>
    &ldquo;Cardholder&rdquo; means an individual designated by you as a
    cardholder, who represents you or is your signatory, or is your employee, and
    who is expressly approved by Paynetics.&nbsp;
  </p>
  <p>
    &ldquo;Faster Payments&rdquo;&nbsp;means a payments system which allows
    sending and receiving payments in GBP in the UK between participating payment
    service providers. &lrm;
  </p>
  <p><br /></p>
  <p>
    &ldquo;Mastercard&rdquo;&nbsp;means Mastercard International Incorporated or
    its successors.
  </p>
  <p>
    &ldquo;Mobile App&rdquo;&nbsp;means the mobile application developed by the
    Technical Provider and published in the Apple AppStore and Google PlayStore,
    &nbsp;which enables you to access your Account and/or Card.
  </p>
  <p>
    &ldquo;Paynetics&rdquo;, &ldquo;we&rdquo;, &ldquo;us&rdquo;,
    &ldquo;our&rdquo;&nbsp;means:
  </p>
  <p>
    (a) where payment services under this Agreement are provided in the UK -
    Paynetics AD, a company with its seat and registered address at Ground Floor,
    76A James Bourchier, Lozenets District, Sofia Municipality, Sofia, Bulgaria,
    entered in the Bulgarian Commercial Register kept by the Registry Agency under
    UIC: 131574695 (&ldquo;Paynetics AD&rdquo;) or such other Paynetics entity
    authorised in the UK as notified to you. For the purposes of UK services,
    Paynetics AD is an electronic money institution deemed authorised and
    regulated by the UK Financial Conduct Authority under the Temporary
    Permissions Regime (firm reference number 900785) for the issuance of e-money
    and provision of payment services in the UK. Details of the Temporary
    Permissions Regime, which allows EEA-based firms to operate in the UK for a
    limited period while seeking full authorisation, are available on the
    Financial Conduct Authority&rsquo;s website; or
  </p>
  <p>
    (b) where payment services under this Agreement are provided in the EEA
    &ndash; Paynetics AD. For the purposes of EEA services, Paynetics AD is an
    electronic money institution holding a license for operating as an electronic
    &lrm;money institution issued by the Board of Directors of the Bulgarian
    National Bank by Decision 44 &lrm;of 11 April 2016, and is entered in the
    register kept by the Bulgarian National Bank, which is &lrm;accessible
    on&nbsp;<a
      href="http://www.bnb.bg/RegistersAndServices/RSPIPublicRegisters/index.htm"
      >http://www.bnb.bg/RegistersAndServices/RSPIPublicRegisters/index.htm</a
    >. The &lrm;Bulgarian National Bank supervises the activities of Paynetics
    AD&lrm;.
  </p>
  <p>
    &ldquo;PIN&rdquo;&nbsp;shall mean a unique four-digit personal identification
    number, which can be used to confirm Card payments.
  </p>
  <p>
    &ldquo;Technical Provider&rdquo;&nbsp;means the operator that performs certain
    technical and operational functions in relation to our services, including the
    operation of Platform which connects the customers with Paynetics.
  </p>
  <p>
    &ldquo;Prohibited Transaction&rdquo; means any of the transactions or
    activities as listed below or as may be subsequently made available on the
    Website from time to time:
  </p>
  <p>
    1. Any sales of &lsquo;pyramid&rsquo; type, Ponzi schemes or similar marketing
    or matrix programs or other schemes for &lsquo;quick enrichment&rsquo; or
    high-yield investment programs;
  </p>
  <p>
    2. Sale, supply or purchase of illegal items or items promoting or
    facilitating illegal activities;
  </p>
  <p>
    3. Sale, supply or purchase of counterfeit products or products infringing
    intellectual property rights;
  </p>
  <p>
    4. Products or services for the processing or aggregation of payments by third
    parties;
  </p>
  <p>5. Money laundering;</p>
  <p>6. Terrorism financing or propaganda;</p>
  <p>
    7. Pornography, escort services and selling and/ or advertising sexual
    services;
  </p>
  <p>
    8. Using the Card or Account in a manner and/or for purposes that violated any
    applicable law and/or regulations of the Card Organisation or SEPA;
  </p>
  <p>
    &ldquo;SEPA&rdquo;&nbsp;means the Single Euro Payments Area scheme, which
    allows sending and receiving payments in EUR between participating payment
    service providers.
  </p>
  <p>
    &ldquo;Tariff&rdquo;&nbsp;means the fees and limits applicable to your
    Accounts, Card and any other services provided to you under this Agreement.
    The Tariff, as amended from time to time, is available on the Website
  </p>
  <p>
    &ldquo;Web App&rdquo; means the web application developed by the Technical
    Provider and run on a web server, which enables you to access your Account
    and/or Card.
  </p>
  <p>
    &ldquo;Website&rdquo;&nbsp;means the following website:
    https://www.corplife.at/
  </p>
  <ol start="2">
    <li>
      <p>ABOUT OUR SERVICES</p>
      <ol>
        <li>
          <p>
            Your Accounts are maintained by Paynetics and the Card is issued to
            you by Paynetics. &nbsp;The value stored on your Accounts is
            electronic money, otherwise referred to as &lsquo;e-money&rsquo;.
            Paynetics is not a bank and your Account is not a bank account. The
            funds available in your Account are not a deposit. No interest will
            accrue on your Account balance. Your Card is a debit card linked to
            your Account, it is not a credit or charge card.
          </p>
        </li>
        <li>
          <p>
            The Financial Services Compensation Scheme does not apply to funds
            held in your Accounts. Instead, Paynetics protects your funds through
            a process known as &lsquo;safeguarding&rsquo;, in line with its
            regulatory requirements. In this process, Paynetics keeps your money
            separate from our own funds and places it in a safeguarding account
            with a bank or cover it by an insurance policy or a comparable
            guarantee. Before we provide any of our services to you, you will have
            to successfully complete our process for verifying your identity.
            &nbsp;The process may involve you providing us a valid passport / ID
            card of your statutory representative/s and ultimate beneficial
            owner/s and a bank statement or other identification documents,
            together with the presented identity document in real time, or any
            other procedure we may specify. We may use ID verification agencies or
            other automated platforms to verify your identity. We or the Program
            Manager may require you to provide additional information at any time
            for the duration of this Agreement.&nbsp;
          </p>
        </li>
        <li>
          <p>
            This Agreement will only become effective once you receive a
            confirmation that Paynetics has approved your application. Paynetics
            will not be obliged to provide any of the services under this
            Agreement before such confirmation is provided. Paynetics may refuse
            to approve any application and/or enter into an agreement for its
            services for any reason.
          </p>
        </li>
        <li>
          <p>
            The Account and Card are issued to you in your capacity as a legal
            entity. You may designate individuals, who may use them &ndash; the
            Cardholders. The Cardholders may use your Account and/or Card only
            personally and may not provide them for use to anyone else. If a
            Cardholder provides access and/or any other means for use of the
            Account and Card to a third party, you shall be fully liable for all
            transactions initiated by such third parties You must not use your
            Account or Card for or in relation to (including sending or receiving
            proceeds from):
          </p>
        </li>
        <li>
          <p>
            You and the Cardholders must not use your Account or Card for or in
            relation to (including sending or receiving proceeds from):
          </p>
          <ol>
            <li>
              <p>
                any activities which do not comply with any applicable laws or
                regulations, including but not limited to laws relating to money
                laundering, fraud, financial services or consumer protection; or
              </p>
            </li>
            <li>
              <p>Prohibited Transaction under this Agreement.</p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            You shall ensure the Cardholders&rsquo; compliance with this
            Agreement. You shall be liable to Paynetics for the Cardholders&rsquo;
            actions.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>RECEIVING/SENDING PAYMENTS</p>
      <ol>
        <li>
          <p>
            When this Agreement becomes effective, Paynetics will set up and
            maintain:
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <ol>
    <li>
      <p>
        an EUR Account with a dedicated IBAN and one or more Cards, which may be
        used for sending or receiving payments via SEPA in EUR and for Card
        payments and cash withdrawals in EUR; and/or
      </p>
    </li>
    <li>
      <p>
        a GBP Account with a dedicated IBAN, an account number with sort code and
        one or more Cards, which may be used for sending or receiving payments via
        Faster Payments in GBP and for Card payments and cash withdrawals in GBP.
      </p>
    </li>
  </ol>
  <ol start="2">
    <li>
      <p>
        Limits may apply to your Account and Card, such as limits on minimum load
        payments to your Account, the maximum balance that can be held on your
        Account, the value of each Account and/or Card payments or an aggregate
        value or number of payments in a particular time period. The applicable
        limits are as set out in the Tariff and may be changed by us from time to
        time. To &lrm;manage our risk, &lrm;particularly with respect to money
        laundering, fraud &lrm;or security concerns, we may also apply
        &lrm;internal controls, &lrm;including &lrm;limits, to certain types of
        transactions from time to time but for security &lrm;purposes, will not
        disclose them.&lrm;&nbsp;
      </p>
    </li>
  </ol>
  <ol start="4">
    <li>
      <p>LOADING FUNDS TO YOUR ACCOUNT</p>
    </li>
  </ol>
  <p>
    4.1. &nbsp; &nbsp;The minimum load amount to top up your Account is indicated
    in the Tariff.
  </p>
  <ol start="2">
    <li>
      <p>
        The balance of the Account should never exceed the limits set out in the
        Tariff.&nbsp;
      </p>
    </li>
  </ol>
  <p>
    4.3.&nbsp;&nbsp; &nbsp;&nbsp;Your Account can be loaded using the following
    methods:
  </p>
  <p>
    4.3.1. &nbsp;&nbsp; &nbsp;&nbsp;EUR Account can be loaded by receiving
    payments via SEPA;&nbsp;
  </p>
  <ol start="2">
    <li>
      <p>GBP Account can be loaded by receiving payments via Faster Payments.</p>
    </li>
  </ol>
  <ol start="4">
    <li>
      <p>
        The received funds will be credited to your Account immediately after
        Paynetics receives the funds.
      </p>
    </li>
    <li>
      <p>
        Paynetics may refuse to execute an incoming payment and your Account will
        not be credited if:
      </p>
      <ol start="2">
        <li>
          <p>
            &nbsp;your Account or payment breaches any of the limits as set out in
            the Tariff;&nbsp;
          </p>
        </li>
        <li>
          <p>your Account is inactive, blocked or terminated;</p>
        </li>
        <li>
          <p>
            the sender has provided incorrect/invalid Account details for payment;
          </p>
        </li>
        <li>
          <p>
            Paynetics reasonably believes the payment is fraudulent, illegal or
            unauthorised;
          </p>
        </li>
        <li>
          <p>
            Paynetics reasonably believes the payment related to a Prohibited
            Transaction.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>
        If an incoming payment is refused for any of the reasons set out above,
        the funds may be sent back to the sender without prior notice to
        you.&nbsp;
      </p>
    </li>
    <li>
      <p>
        Paynetics will charge you with a top up fee every time your Account is
        loaded as set out in the Tariff.
      </p>
    </li>
  </ol>
  <ol start="5">
    <li>
      <p>MAKING PAYMENTS FROM YOUR ACCOUNT</p>
    </li>
  </ol>
  <ol>
    <li>
      <p>
        You/the Cardholders must make sure that your Account has sufficient funds
        available to cover the amount of a given payment and all applicable fees.
        Paynetics may refuse to perform any given payment if your Account does not
        have sufficient balance to cover the amount of the payment and all
        applicable fees.
      </p>
    </li>
    <li>
      <p>
        Paynetics will execute your/the Cardholder&rsquo;s payment instructions
        for payments from your Account on the same day it receives them (or the
        future day agreed if it is in the future). The time of receipt of your/the
        Cardholder&rsquo;s payment instruction is when Paynetics receives it
        (which will typically be on the same day the payment is instructed) or, if
        the payment instruction specifies the payment is to take place on a future
        day, then the payment instruction will be treated as received on that day.
        You/the Cardholder cannot cancel a payment after you/the Cardholder have
        authorised it and Paynetics has received your/the Cardholder&rsquo;s
        payment instruction for it, except that you/the Cardholder can cancel a
        future payment, such as a recurring Card payment if you/the Cardholder
        notify Paynetics of cancellation no later than the end of the business day
        before the payment is due to be made via the App. Cancelling a future
        dated payment such as a recurring Card payment with us will not cancel the
        agreement with the organisation you are paying. You/the Cardholder should
        tell the organisation collecting the payment about the changes to your/the
        Cardholder&rsquo;s instructions.
      </p>
    </li>
    <li>
      <p>
        After receiving your/the Cardholder&rsquo;s payment instruction for
        payment from your Account (including Card payments), Paynetics will credit
        the recipient&rsquo;s account within the timescales set out below.
      </p>
    </li>
  </ol>
  <div align="left">
    <table>
      <tbody>
        <tr>
          <td>
            <p>Type of payment</p>
          </td>
          <td>
            <p>Delivery to the recipient&rsquo;s account</p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Payments in EUR or GBP to an account in the UK or the EEA (including
              where there has been a currency conversion between EUR and GBP)
            </p>
          </td>
          <td>
            <p>
              No later than the end of the business day after the payment
              instruction is received
            </p>
          </td>
        </tr>
        <tr>
          <td>
            <p>
              Payments in any other currency or to an account outside the UK or
              the EEA
            </p>
          </td>
          <td>
            <p>
              Varies, depending on the currency or country the payment is sent to
            </p>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <ol start="4">
    <li>
      <p>
        Paynetics will deduct the value of payments together with all applicable
        fees from the balance on the relevant currency Account. &nbsp;Payments
        from your Account by way of credit transfers in EUR or GBP will be
        deducted from your EUR or GBP Account respectively. Card payments in EUR
        or GBP will also be deducted from your respective currency Account. Card
        payments in any other currency will always be deducted from your Account
        at then prevailing currency conversion rates. If there is insufficient
        balance on the relevant GBP or EUR &nbsp;Account to complete the payment,
        including all applicable fees, funds will be automatically transferred
        from your Account in the other currency to your relevant currency Account
        at then prevailing currency conversion rates. If there is insufficient
        balance on both your EUR and GBP Accounts to complete the payment,
        including all applicable fees, the payment will be refused. See clause 11
        for more detail on the applicable currency conversion rates and fees.
      </p>
    </li>
    <li>
      <p>
        You agree you and the Cardholders will not make any attempts to interrupt
        or impair the functionality of our information system, including but not
        limited to, not to spread files containing viruses, damaged files or other
        similar software which may be used for accessing, modifying, deleting or
        damaging data files.&nbsp;
      </p>
    </li>
  </ol>
  <ol start="6">
    <li>
      <p>ACCESS TO YOUR ACCOUNTS BY THIRD PARTY PROVIDERS</p>
      <ol>
        <li>
          <p>
            You may choose to allow (and provided you have given them your
            explicit consent):
          </p>
          <ol>
            <li>
              <p>
                providers of account information service (i.e. an online service
                which accesses one or more payment accounts to provide a
                consolidated view of such accounts) to access information on your
                Account; and/or
              </p>
            </li>
            <li>
              <p>
                providers of payment initiation service (i.e. an online service
                which allows a third party to initiate payments on behalf of the
                account holder from their account and at their request) to
                initiate payments (other than Card payments) from your GBP and EUR
                Accounts.&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            Only those providers of account information or payment initiation
            services that are authorised with the Financial Conduct Authority or
            another European regulator to provide the relevant service in the UK
            or an EU member state, as applicable, can be given access to your
            Account. The Financial Conduct Authority&rsquo;s register (available
            at https://register.fca.org.uk/&lrm;) will tell you whether a provider
            is authorised in the UK, and we recommend you check it before using
            their services.
          </p>
        </li>
        <li>
          <p>
            We will treat any instruction from such providers of account
            information or payment initiation services as if it was from you.
            &nbsp;Some providers may use your Account security details to provide
            their service. You should always consider the implications of sharing
            your security information.&nbsp;
          </p>
        </li>
        <li>
          <p>
            &lrm;We can deny providers of account information or payment
            initiation services access to your Account if we are concerned about
            unauthorised or &lrm;fraudulent access. We will notify you of the
            denial of access and the reasons &lrm;for it beforehand if possible or
            otherwise immediately afterwards (unless doing so would
            &lrm;compromise our security measures or would be unlawful). The
            access to your Account may be restored once the reasons &lrm;for
            denying the access no longer justify such &lrm;denial.&lrm;&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>GIVING INSTRUCTIONS FOR PAYMENTS FROM YOUR ACCOUNTS</p>
      <ol>
        <li>
          <p>
            For a payment from your Account (including transfers to your other
            Accounts) to be properly executed, you/the Cardholder must provide us
            the following information when you/the Cardholder instruct us to make
            the payment (in addition to any other information we may ask for):
          </p>
          <ol>
            <li>
              <p>
                for payments from your GBP Account to a UK account via Faster
                Payments: the recipient&rsquo;s name, account number, the payment
                amount and sort code and a payment reference;
              </p>
            </li>
            <li>
              <p>
                for payments from your EUR Account to an EEA account via SEPA: the
                recipient&rsquo;s (beneficiary) name, IBAN, the payment amount,
                its currency and reason for payment (this information meant for
                the beneficiary), additional clarifications;
              </p>
            </li>
            <li>
              <p>
                for payments from your EUR Account to an account outside the EEA
                via SEPA (to countries that participate in SEPA): in addition to
                information set out at 7.1.2, you/the Cardholder will also need to
                provide the sender&rsquo;s (originator) address and may also be
                required to provide the recipient&rsquo;s (beneficiary)
                bank&rsquo;s BIC;
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            For any payments above EUR 15,000 or its equivalent in another
            currency: the sender (originator) and / or recipient (beneficiary)
            could also be required to provide a declaration to prove the origin of
            funds;&nbsp;&lrm;If you/the Cardholder provide us with incorrect or
            incomplete information or refuse to provide information, we &lrm;may
            refuse to execute the payment, the funds could be lost and
            irrecoverable or there could be &lrm;a delay in the recipient
            receiving the payment. &lrm;
          </p>
        </li>
        <li>
          <p>
            You /the Cardholder give us instructions and consent to a payment from
            your Account in one of the following ways:
          </p>
          <ol>
            <li>
              <p>using the App;</p>
            </li>
            <li>
              <p>
                giving us instructions via a third party (such as payment
                initiation service provider);
              </p>
            </li>
            <li>
              <p>
                using Cards (see clause 10 below on how you instruct us to make
                payments using a Card);
              </p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    You/the Cardholder may be required to provide security details and/or use a
    particular authentication method depending on the method you use. We will tell
    you which authentication can be used or if they are unable for any particular
    types of service. &nbsp;Paynetics reserves the right to change or introduce
    new authentication methods at any time, including for reasons relating to
    changes in the law, technical characteristics of the services or security.
  </p>
  <ol start="4">
    <li>
      <p>
        Paynetics may refuse to carry out any instructed payment from your Account
        if it does not meet the requirements of this Agreement, including, but not
        limited to any of the following circumstances:&nbsp;
      </p>
      <ol>
        <li>
          <p>
            Paynetics reasonably suspects that the payment is unauthorised or
            involved in fraud or illegal activity;
          </p>
        </li>
        <li>
          <p>
            your/the Cardholder&rsquo;s instruction is unclear, incorrect or
            incomplete;
          </p>
        </li>
        <li>
          <p>
            Paynetics reasonably believes that there has been a violation of this
            Agreement;
          </p>
        </li>
        <li>
          <p>
            you/the Cardholder have failed to use the authentication method and/or
            provide security information required;
          </p>
        </li>
        <li>
          <p>
            there are insufficient funds in the Account to cover the payment and
            any applicable fees;
          </p>
        </li>
        <li>
          <p>the payment violates any applicable limits;</p>
        </li>
        <li>
          <p>
            carrying out your/the Cardholder&rsquo;s instruction might cause us to
            break the law, order of a regulatory body, code, rule of Card
            Organisation or other duty applicable to Paynetics;
          </p>
        </li>
        <li>
          <p>&nbsp;due to a technical impossibility to carry out the payment;</p>
        </li>
        <li>
          <p>
            Paynetics reasonably believes payment is related to a Prohibited
            Transaction.&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>
        If we refuse to execute the payment, we will notify you as soon as
        possible unless it would not be unlawful for us to do so. If
        &lrm;possible, we will provide the reasons for refusal to execute the
        payment and where those reasons relate to factual matters, the procedure
        for &lrm;rectifying any factual errors that led to such
        refusal.&lrm;&nbsp;
      </p>
    </li>
    <li>
      <p>
        The execution of any payment to or from your Account (including Card
        payments) may be delayed or refused due to the performance of
        Paynetics&rsquo; security or legal compliance checks, including if
        Paynetics suspects that the payment is involved in fraudulent, illegal or
        unacceptable activities or constitutes an unauthorised payment.
      </p>
    </li>
  </ol>
  <ol start="8">
    <li>
      <p>DESCRIPTION OF THE CARD</p>
      <ol>
        <li>
          <p>
            Your Card is issued to you by Paynetics and is linked to your Account
            in EUR and/or GBP, as applicable, maintained by Paynetics. Your Card
            can be used in your country of establishment or abroad.
          </p>
        </li>
        <li>
          <p>
            The Card is issued under the Mastercard brand pursuant to license
            granted by Mastercard International.
          </p>
        </li>
        <li>
          <p>
            Unless specified otherwise in this Agreement, your Card can be used to
            pay merchants for goods or services at (whether in-store, online or
            over the phone) and for cash withdrawals at ATMs. If you have been
            issued with a virtual Card, it can only be used to pay merchants
            online. Your Card can be used at all card acceptance terminal devices
            bearing the Mastercard logo which maintain the necessary functions.
          </p>
        </li>
        <li>
          <p>
            Card payments will be executed immediately after Paynetics receives
            the payment request.&nbsp;
          </p>
        </li>
        <li>
          <p>
            The value of all Card payments, including all applicable fees, will be
            deducted from your relevant Account balance, see clause 5.4.
          </p>
        </li>
        <li>
          <p>
            You will be responsible for all goods or services purchased with the
            Card. Any dispute with a merchant about a product or service purchased
            with the Card will be considered a dispute between you and the
            merchant, and should be addressed directly to that merchant. Paynetics
            does not accept any responsibility or liability for the quality,
            safety, legality or any other aspect relating to and does not provide
            any warranties regarding such goods or services purchased with the
            Card.
          </p>
        </li>
        <li>
          <p>
            Paynetics will not be liable if a merchant refuses to accept a Card or
            if Paynetics has refused to executed a payment in accordance with this
            Agreement.
          </p>
        </li>
        <li>
          <p>
            The Card is property of Paynetics as a card issuer and cannot be
            transferred and/or made available to anyone else.&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>CARD ISSUANCE, LOADING, ACTIVATION AND REPLACEMENT&nbsp;</p>
      <ol>
        <li>
          <p>
            Each Card has a validity period within which you/the Cardholder may
            use the Card. If your Card is physical, it will expire on the last day
            of the month/year indicated on its front. If your Card is virtual, it
            will expire on the last day of the month/year indicated on the App or
            on the expiry date sent to you by e-mail&lrm;. All Card payments
            initiated after the expiration or cancellation of the Card will not be
            authorised or executed.
          </p>
        </li>
        <li>
          <p>
            Paynetics will issue the Card within 10 business days from the date
            your/the Cardholder&rsquo;s application is accepted and, if the Card
            is not virtual, will deliver it to your/the Cardholder personally. If
            your Card is virtual, &lrm;the Card will be made available to you/the
            Cardholder via the App immediately after your/the Cardholder&rsquo;s
            application is approved by Paynetics.
          </p>
        </li>
        <li>
          <p>
            &lrm;The physical Card will be personally delivered to you/the
            Cardholder and the Cardholder may be required to produce an ID
            document to collect it. The Cardholder must sign on the signature
            strip on the reverse side of the physical Card immediately after
            receiving it.
          </p>
        </li>
        <li>
          <p>
            You/the Cardholder will be provided with a PIN to use with your Card.
            You/the Cardholder can change your PIN at an ATM. You/the Cardholder
            should memorise your PIN and then destroy the media on which it is
            recorded. You and the Cardholders have important obligations to keep
            all security details such as PIN safe (see clause 14 below).&nbsp;
          </p>
        </li>
        <li>
          <p>
            You may request Paynetics to issue a new Card in the following
            cases:&nbsp;
          </p>
          <ol>
            <li>
              <p>in case of destruction or damage to the Card;&nbsp;</p>
            </li>
            <li>
              <p>in case of loss, theft or other misappropriation of the Card,</p>
            </li>
            <li>
              <p>in case of a forgotten PIN, and</p>
            </li>
            <li>
              <p>
                upon expiry of your Card&rsquo;s validity period. If your Card
                expires, it will only be automatically replaced if your Card was
                used within the last two months before its expiry date.&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            If your Card is replaced because it was lost or damaged, or in case of
            a forgotten PIN or upon expiry of your Card&rsquo;s validity period,
            you will be charged a Replacement Fee (see Tariff) which will be
            deducted from your Account. If your Card has been reported stolen or
            misappropriated you will be charged a fee that is directly related to
            the cost of replacement (see Tariff). If your Card has been reported
            lost, stolen or misappropriated but later is found, then you should
            immediately inform Paynetics about it and destroy the Card.
          </p>
        </li>
        <li>
          <p>
            When you/the Cardholder receive the Card it will be inactive. You/the
            Cardholder have to activate the Card before using it. Otherwise, any
            Card payments attempted by you/the Cardholder will be rejected. The
            Card can be activated online by following the instructions for
            activation provided by us.&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>GIVING INSTRUCTIONS FOR CARD PAYMENTS</p>
      <ol>
        <li>
          <p>
            You /the Cardholder give us an instruction and consent to a payment
            made using your Card using any one of the following methods:&nbsp;
          </p>
          <ol>
            <li>
              <p>
                &nbsp;in case of cash withdrawal from an ATM: by entering a PIN;
              </p>
            </li>
            <li>
              <p>
                in case of payment for goods or services in-store: by entering a
                PIN and/or signature on the receipt or by tapping/waving the Card
                (or a device where it is held) over a card reader for contactless
                payments;&nbsp;
              </p>
            </li>
            <li>
              <p>
                in case of online payments for goods or services online or via the
                phone: by providing your Card details and a one-time payment code
                or other security information or credentials, when
                requested.&nbsp;
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            Your /the Cardholder&rsquo;s consent for a Card payment may cover a
            single payment or a series of recurring payments on a Card (such as
            where you/the Cardholder give your Card details to a merchant to be
            used for Card payments in the future) for a set or variable amount.
          </p>
        </li>
        <li>
          <p>
            Merchants in certain business sectors (e.g. car rental companies,
            hotels and other service providers) estimate the amount of the final
            Card payment to them and require to &ldquo;pre-authorise&rdquo; or
            withhold the estimated amount on the Card. Sometimes, that withheld
            amount may exceed the final amount spent. In such cases, the initially
            withheld funds will not be available to you/the Cardholder for up to
            15 days until the final Card payment request is received by us or
            released by the merchant. Paynetics may release such amounts only with
            the merchant&rsquo;s consent.
          </p>
        </li>
        <li>
          <p>
            You and the Cardholders are responsible to provide a correct and
            accurate instructions for execution of a payment. If the instructions
            provided by you/the Cardholders are incorrect, inaccurate or
            incomplete, Paynetics will not be liable for errors or inaccuracies in
            the transaction. If a payment is carried out in accordance with the
            instructions provided by you/the Cardholders, it will be deemed to
            have been correctly executed.&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>FEES AND EXCHANGE RATES</p>
      <ol>
        <li>
          <p>
            Fees that apply to your Account, Card and associated services and/or
            transactions are as set out in the Tariff. All fees will be determined
            in the currency of the relevant Card and/or Account to which they
            relate and will be deducted from the relevant Account.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Paynetics reserves the right to change the applicable fees in
            accordance with this Agreement (see further clause 16). Changes in the
            reference exchange rate will apply immediately without prior notice.
          </p>
        </li>
        <li>
          <p>
            If a payment received or sent into/from your Account is in a currency
            other than the currency of the Account (including transfer between
            your different currency Accounts), then the payment amount will be
            &lrm;converted into the currency of the destination account using an
            exchange rate made up of a reference rate (available at &lrm;&lrm;the
            App) plus the currency conversion mark-up fee as set out in the
            Tariff. The exchange rate will be determined at the time (with respect
            to payments into Account) the payment is received or &lrm;&lrm;(with
            respect to payments from Account) the payment instruction is received
            by &lrm;Paynetics.&lrm;
          </p>
        </li>
        <li>
          <p>
            If a payments using a Card is in a currency other than the currency of
            the Account from which it is taken, then the amount deducted will be
            the amount of the Card payment converted to the currency of the
            Account using the reference exchange rate applied by MasterCard&reg;
            (available at&nbsp;<a
              href="https://www.mastercard.co.uk/en-gb/personal/get-support/convert-currency.html"
              >https://www.mastercard.co.uk/en-gb/personal/get-support/convert-currency.html</a
            >) plus the currency conversion mark-up fee set out in the Tariff for
            Card payments. The exchange rate shall be determined on the date of
            the Card payment is processed. The &lrm;exchange rate is not set by
            Paynetics and varies throughout the day meaning it may &lrm;change
            between the date the Card payment is made and the date it is
            processed. Paynetics provides information on the total currency
            conversion charges &lrm;applicable with respect to Card payments,
            expressed as a percentage mark-up over the &lrm;latest available euro
            foreign exchange reference rates issued by the European Central
            &lrm;Bank on the &lrm;Website&lrm;.&lrm;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>NEGATIVE BALANCE</p>
      <ol>
        <li>
          <p>
            If any action results in a negative balance in your Account, you must
            reimburse Paynetics the amount of the negative balance immediately
            upon request. Paynetics may charge the amount of the negative balance
            against the funds on any other Accounts held by you, including any
            subsequently loaded funds. Paynetics may suspend Accounts and Card
            until it is reimbursed for the negative balance in full and may take
            legal action against you to recover such amount.&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>COMMUNICATION PROCEDURE</p>
      <ol>
        <li>
          <p>
            The Agreement is executed in English and all communications between
            you and us will be in English, unless expressly agreed otherwise in
            this Agreement.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Your representatives, persons authorised by you and the Cardholders
            may receive information about the payments made with the Card/Account
            electronically.&nbsp;
          </p>
        </li>
        <li>
          <p>
            We can communicate with you (including to provide any information or
            notifications in relation to this Agreement) using any of the
            following methods:
          </p>
          <ol>
            <li>
              <p>&nbsp;via the App;</p>
            </li>
            <li>
              <p>&nbsp;by email;</p>
            </li>
            <li>
              <p>&nbsp;by phone (including by sending you an SMS);</p>
            </li>
            <li>
              <p>&nbsp;by writing to your home address.</p>
            </li>
          </ol>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    We will tell you if any of these communication methods are not available. We
    will also tell you if you need any technical requirements or software to
    communicate with us.
  </p>
  <ol start="4">
    <li>
      <p>
        We will use the contact information you have most recently given us to
        communicate with you. If any of your contact details change, you must
        inform Paynetics about it as soon as possible by updating your details via
        the App. All notifications, invitations or other notices sent to your last
        email address last known to Paynetics will be deemed to have been
        delivered.&nbsp;
      </p>
    </li>
    <li>
      <p>
        You can contact Paynetics via Customer Relations Centre (see contact
        details at clause 21).
      </p>
    </li>
    <li>
      <p>
        Paynetics will provide information about your Account and Card
        transactions and fees by means of electronic statements which will be
        accessible on the App. Statements shall not be provided on paper.
        Information about your Account and Card shall be updated, if there was any
        activity with the Account and/or the Card, including information on all
        collected fees. From the moment of accessing the statement on the App you
        will be considered to be informed about the relevant transaction. You will
        be charged for obtaining additional information or for obtaining it in a
        manner different from the one described herein.&nbsp;
      </p>
    </li>
  </ol>
  <ol start="14">
    <li>
      <p>SECURITY MEASURES</p>
      <ol>
        <li>
          <p>
            You and the Cardholders must use your Card and Account in accordance
            with the terms and conditions set out in this Agreement. You and the
            Cardholders have important obligations to do everything you and the
            Cardholders reasonably can to keep your Account and Card safe and to
            notify Paynetics of any loss, theft, misappropriation or unauthorised
            use of the Card or Account immediately after becoming aware of it as
            further described below.
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    Your and the Cardholders&rsquo; obligations to keep your Account and Card safe
  </p>
  <ol start="2">
    <li>
      <p>
        You/the Cardholders will be provided with or will be able to set up
        certain security details (such as your Card PIN and any password, passcode
        or other login details which can be used to access or make payments with
        your Account or Card via the App). You/the Cardholders must:
      </p>
      <ol>
        <li>
          <p>
            take all reasonable measures to keep your Account, Card and security
            details safe;
          </p>
        </li>
        <li>
          <p>
            not write down any of your security details (such as PIN or password)
            unless it is done in a &nbsp;way to make it difficult for anyone else
            to recognise them;&nbsp;
          </p>
        </li>
        <li>
          <p>
            not record security details such as PIN on the Card or keep such
            information together with the Card;
          </p>
        </li>
        <li>
          <p>
            not to disclose to and/or allow anyone else (other than authorised
            providers of account information services or payment initiation
            services) to use in any manner whatsoever your security details such
            as user identifiers, passwords or PINs;
          </p>
        </li>
        <li>
          <p>
            keep your Card and any personal devices (mobile phones, computers,
            tablets) that can be used to access your Account, Cards or security
            details secure and not let anyone else use them to make payments;
          </p>
        </li>
        <li>
          <p>
            not choose security details such as PIN or password to access your
            Card or Account that is easy for someone to guess such as letters or
            digits that:
          </p>
          <ol>
            <li>
              <p>
                &nbsp;are easily associated with you, for example your telephone
                number or date of birth;
              </p>
            </li>
            <li>
              <p>are part of the data imprinted on the Card;</p>
            </li>
            <li>
              <p>
                consist of the same digits (1111) or the sequence of running
                digits (1234); or
              </p>
            </li>
            <li>
              <p>are identical to previously selected PIN/password.</p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            use up-to-date virus, malware, and spyware software and a firewall on
            any devices used to access your Account or cards to reduce the risk of
            security breaches.
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>Your obligation to notify us</p>
  <ol start="3">
    <li>
      <p>
        If your Card has been withheld by an ATM, you must immediately notify
        Paynetics. If Paynetics is not able to return the Card to you, Paynetics
        will issue a new Card to replace it.&nbsp;
      </p>
    </li>
    <li>
      <p>
        You must immediately notify Paynetics Customer Relations Centre (using
        contact details at clause 21) if:
      </p>
      <ol>
        <li>
          <p>your Card is lost, stolen or misappropriated; or</p>
        </li>
        <li>
          <p>
            you believe there has been unauthorised use of your Card or Account or
            anyone else may be able to use or access your Account, Card or
            security details to access them.&nbsp;
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    Paynetics will make all reasonable efforts to stop the use of Account and/or
    Card by blocking the Account and/or Card payments after receiving a
    notification from you.&nbsp;
  </p>
  <ol start="5">
    <li>
      <p>Paynetics may restrict, block or deactivate the Card or Account if:</p>
      <ol>
        <li>
          <p>
            &nbsp;Paynetics is concerned about the security of the Card or Account
            or the security details relating to them;
          </p>
        </li>
        <li>
          <p>
            Paynetics becomes aware or suspects that the Account, Card or security
            details relating to them are being used in an unauthorised, unlawful
            or fraudulent manner;
          </p>
        </li>
        <li>
          <p>
            Paynetics reasonably believes it needs to do so to comply with the law
            or court order in any applicable jurisdiction, the instructions
            provided by a Card Organisation or any other rule or duty applicable
            to Paynetics;
          </p>
        </li>
        <li>
          <p>this Agreement is terminated for any reason;</p>
        </li>
        <li>
          <p>you request us to do so;</p>
        </li>
        <li>
          <p>you have breached any term of this Agreement in a material way.</p>
        </li>
      </ol>
    </li>
    <li>
      <p>
        We will, if possible, notify you before restricting, blocking or
        deactivating your Account or Card that we will do so and the reasons for
        it. If we are unable to notify you beforehand, we will notify you
        immediately afterwards. We will not notify you if doing so would
        compromise our security measures or would be unlawful.&nbsp;
      </p>
    </li>
    <li>
      <p>
        Your Card and/ or Account will be unblocked or re-activated (or replaced)
        as soon as possible after the reasons for blocking cease to exist.&nbsp;
      </p>
    </li>
  </ol>
  <p>Communicating with you about fraud or security threats</p>
  <ol start="8">
    <li>
      <p>
        We may need to contact you urgently in the event of suspected or actual
        fraud or security &lrm;threats to your Account, Card and/or security
        details. To do so, we may &lrm;use an SMS, &lrm;telephone, e-mail or
        another secure procedure. When we contact you, we may also give you
        &lrm;information on how you can minimise any risk to your Account, Card or
        security details &lrm;depending on the nature of the security threat.&lrm;
        But will&nbsp;never&nbsp;ask you to give your full security details (such
        as PIN, password or passcode) &nbsp;or ask you to transfer money to a new
        account for security reasons.
      </p>
    </li>
  </ol>
  <ol start="15">
    <li>
      <p>LIABILITY &amp; REFUNDS</p>
      <ol>
        <li>
          <p>
            You must notify Paynetics in writing of any unauthorised or
            incorrectly executed payments on your Card or Account without undue
            delay after becoming aware of such unauthorised or incorrectly
            executed payment and in any case no later than two months from the
            date the payment was debited to your Account/Card. You can notify us
            of such unauthorised or incorrectly executed payments by contacting
            the Customer Relations Centre (see clause 21 for details). You shall
            be considered to be informed of an unauthorised or incorrectly
            executed payment as from the moment of accessing the relevant
            statement on the App. Paynetics shall not be held liable for any
            unauthorised or incorrectly executed payment transactions under this
            Article 15 where Paynetics has not received a notice within two months
            from the date when your Account/Card was debited.
          </p>
        </li>
        <li>
          <p>
            If you notify us of an unauthorised or incorrectly executed payment in
            accordance with clause 15.1, you may be entitled to receive a refund
            of the funds as detailed below. Paynetics will verify the authenticity
            of the payment, its proper recording and reporting, and whether the
            transaction has been affected by a technical malfunction or other
            defect. Since you are not a user, you agree that Articles 78 and 80 of
            the Bulgarian Payment Services and Payment Systems Act (or the
            equivalent provisions of the UK law, if applicable) shall not apply to
            the relations with Paynetics under the Agreement.
          </p>
        </li>
        <li>
          <p>
            In the event that Paynetics establishes an unauthorised transaction
            and where there are no reasonable grounds for suspecting that you/the
            Cardholder acted fraudulently, Paynetics shall refund to you the value
            of the unauthorised transaction within the statutory timelines. If
            necessary, Paynetics shall restore your Account/Card to the status it
            would be if the unauthorised transaction was not effected.
          </p>
        </li>
        <li>
          <p>
            In you are entitled to a refund for an incorrectly executed or
            unauthorised payment, we will refund you as soon as possible upon
            receipt of your claim or any additional information we may request in
            order to investigate your right to a refund. However, if after we
            refund you, our investigation leads us to reasonably that you were not
            entitled to the refund, we will have the right deduct the amount of
            the refund from any funds on Accounts you hold with us and reserve the
            right to recover the value of the refunded payment by any other legal
            means.
          </p>
        </li>
        <li>
          <p>
            In the event that Paynetics establishes an unauthorised transaction
            and where there are no reasonable grounds for suspecting that you/the
            Cardholder acted fraudulently, Paynetics shall refund to you the value
            of the unauthorised transaction within the statutory timelines. If
            necessary, Paynetics shall restore your Account/Card to the status it
            would be if the unauthorised transaction was not effected.&nbsp;
          </p>
        </li>
        <li>
          <p>
            The provision of Article 15.3 hereof shall not apply and you shall
            bear all losses, irrespective of their amount, relating to
            unauthorised transactions if you/the Cardholder have caused them by
            fraud or failure to perform one or more of the obligations under the
            Agreement.&nbsp;
          </p>
        </li>
        <li>
          <p>
            You shall pay a fee in accordance with the Tariff in the cases where
            it appears that their notification under Article 15.1 hereof is
            unjustified.&nbsp;
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>Unauthorised payments from your Account or Card</p>
  <ol start="8">
    <li>
      <p>
        You will not be entitled to a refund and you will bear all losses,
        irrespective of their amount, relating to an unauthorised payment if:
      </p>
      <ol>
        <li>
          <p>the payment was authorised by you/the Cardholder;</p>
        </li>
        <li>
          <p>you have acted fraudulently;</p>
        </li>
        <li>
          <p>
            you have failed to comply with one or more of the obligations under
            this Agreement (for example, with regards to keeping your Account and
            Card safe or to notify us of any unauthorised payments).&nbsp;
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    Non-executed or Incorrectly executed payments from your Account or Card&nbsp;
  </p>
  <ol start="9">
    <li>
      <p>
        If Paynetics fails to execute or incorrectly executes a payment from your
        Account or made using a Card, Paynetics will promptly reimburse the amount
        of the payment (including any fees to restore the Account to the position
        it would have been at if the defective payment was not made), unless
        Paynetics can show that the recipient&rsquo;s payment service provider has
        received the payment (in which case they will be liable). In this case, on
        your request, Paynetics will make reasonable efforts to trace the payment
        and inform you about the outcome. &nbsp;
      </p>
    </li>
    <li>
      <p>
        Paynetics will not be liable for any payment executed in accordance with
        the payment instructions given by you. If the payment instruction you have
        given us was incorrect or incomplete (for example, you have made a mistake
        in giving us the recipient&rsquo;s account details), Paynetics will make
        reasonable efforts to recover the payment amount. Paynetics may charge you
        a fee for any such recovery as indicated in the Tariff. If Paynetics is
        not able to recover the payment amount, Paynetics will, on your written
        request, provide you with the relevant information about the
        payment.&nbsp;
      </p>
    </li>
  </ol>
  <p>Late or incorrectly executed payments to your Account</p>
  <ol start="11">
    <li>
      <p>
        If a payment is received in your Account incorrectly (for example, by
        mistake), Paynetics may, where it considers reasonable to do so, hold the
        payment and return it to the sender. In any case, Paynetics is obliged to
        provide certain information to the sender&rsquo;s payment service provider
        about you and the payment to enable them to recover the funds.
      </p>
    </li>
  </ol>
  <p>Payments initiated by payee</p>
  <ol start="12">
    <li>
      <p>
        If you/the Cardholder authorise a payment initiated by or through the
        payee without knowing the final amount of the payment (for example, a Card
        payment initiated by the merchant when renting a car or booking a hotel
        room), you have the right to request Paynetics for a refund of such
        payment provided that all of the following conditions are met:
      </p>
      <ol>
        <li>
          <p>
            you have asked for a refund within 8 weeks from the date on which your
            Paynetics Account was debited;
          </p>
        </li>
        <li>
          <p>
            at the time of authorisation to execute the payment, its exact amount
            was not specified; and&nbsp;
          </p>
        </li>
        <li>
          <p>
            the amount of the payment exceeded the amount you could have
            reasonably expected &nbsp;taking into account your previous spending
            patterns, the terms of this Agreement and the case-specific
            circumstances. If the amount of the payment increased because of the
            currency exchange when the reference exchange rate agreed with
            Paynetics has been applied, it will not be a valid reason.
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>
    At the request of Paynetics, you must provide information to show the
    conditions above have been met.
  </p>
  <ol start="13">
    <li>
      <p>
        Within 10 business days of receiving your request for a refund, or, where
        applicable, of receiving the further information Paynetics requested,
        Paynetics will refund the full amount of the payment or inform you of the
        refusal to refund it, together with the grounds for refusal and the
        authorities to which you can complain if you do not accept those grounds.
        The refund will include the entire amount of the payment which will be
        dated back to the date on which your Account was debited.
      </p>
    </li>
    <li>
      <p>
        You will not be entitled to a refund of any payment initiated by or
        through payee when:
      </p>
      <ol>
        <li>
          <p>
            you have given your consent to perform the payment directly to
            Paynetics; and
          </p>
        </li>
        <li>
          <p>
            where applicable, Paynetics or the payee has informed you about the
            upcoming payment at least 4 weeks before it was due to be made.
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <p>General liability</p>
  <ol start="15">
    <li>
      <p>
        Paynetics will not be liable to you for any damages or losses arising from
        or relating to:
      </p>
      <ol>
        <li>
          <p>
            your/the Cardholders&rsquo; failure to use the Card or Account in
            accordance with this Agreement;
          </p>
        </li>
        <li>
          <p>
            any payments executed in accordance with the information or
            instructions provided by you which were incorrect, inaccurate or
            incomplete;&nbsp;
          </p>
        </li>
        <li>
          <p>
            any unusual or unforeseeable circumstances beyond the control of
            Paynetics, the consequences of which could not be avoided despite
            Paynetics&rsquo; best efforts;&nbsp;
          </p>
        </li>
        <li>
          <p>
            refusal of a merchant, ATM or any other person to accept the Card or
            any other Account payment;
          </p>
        </li>
        <li>
          <p>
            malfunction of a mobile device or other equipment, software or
            services required for the successful technical performance of an
            operation which is beyond the control of Paynetics;&nbsp;
          </p>
        </li>
        <li>
          <p>
            compliance with the applicable legal or regulatory requirements or any
            rules &nbsp;or guidelines provided by the Card Organisation;&nbsp;
          </p>
        </li>
        <li>
          <p>you/the Cardholders acting fraudulently or with gross negligence;</p>
        </li>
        <li>
          <p>
            loss of revenue, goodwill, lost benefits or expected savings;&nbsp;
          </p>
        </li>
        <li>
          <p>
            any loss or damage which is not a direct result nor a direct
            consequence of a breach of this Agreement by Paynetics; or&nbsp;
          </p>
        </li>
        <li>
          <p>
            any loss or damage caused by a virus, Denial of Service attack
            dissemination or other technologically harmful material that may
            infect a computer or other device or equipment, software programs,
            data or other proprietary material in connection to the Card, Account
            and this Agreement;
          </p>
        </li>
        <li>
          <p>
            the quality, safety, legality or any other aspect of goods and/or
            services purchased using the Card or Account or any possible disputes
            arising between you and the provider of such goods/services;
          </p>
        </li>
        <li>
          <p>
            Paynetics refusing to accept or execute any payment or restricting,
            blocking or deactivating your Account and/or Card in accordance with
            this Agreement;
          </p>
        </li>
        <li>
          <p>
            loss or damage beyond Paynetics&rsquo; responsibility by law.&nbsp;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>
        None of the terms of this Agreement will limit or exclude Paynetics&rsquo;
        liability for fraud, gross negligence or any other liability which cannot
        be legally excluded or limited by law.
      </p>
    </li>
    <li>
      <p>
        You and the Cardholders are responsible for the use of the Card and
        Account in accordance with this Agreement. You will be liable to Paynetics
        for all losses and damages caused by their improper and/or non-compliant
        use that does not comply with this Agreement.
      </p>
    </li>
  </ol>
  <ol start="16">
    <li>
      <p>CHANGES TO THIS AGREEMENT</p>
      <ol>
        <li>
          <p>
            Paynetics may make changes to this Agreement, including the fees set
            out in the Tariff, as set out further below for reasons which may
            include (but are not limited to):
          </p>
          <ol>
            <li>
              <p>
                &nbsp;changes to the products or services or introducing new
                products or services under this Agreement;&lrm;
              </p>
            </li>
            <li>
              <p>
                withdrawal of a particular part of our products or services;&lrm;
              </p>
            </li>
            <li>
              <p>
                changes in market conditions or operating costs that affect
                Paynetics;&lrm;
              </p>
            </li>
            <li>
              <p>
                changes in technology, our systems and/or payment methods;&lrm;
              </p>
            </li>
            <li>
              <p>making this Agreement clearer or more favourable to you; or</p>
            </li>
            <li>
              <p>
                changes in relevant laws or regulations, or codes or rules that
                apply to Paynetics.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>
            Paynetics may notify you about any changes to this Agreement,
            including the Tariff, and the date on which such changes shall take
            effect, by posting on the Website, App and/or via email. Changes shall
            have an immediate effect.&nbsp;
          </p>
        </li>
        <li>
          <p>
            If you do not approve of the changes to this Agreement, you have the
            right to terminate this Agreement. Paynetics will treat you as having
            accepted the changes unless you inform Paynetics that you do not
            approve these changes within 14 days after their posting on the
            Website/App/receipt via email. In case you have informed Paynetics
            that you do not accept the changes, this Agreement shall be
            terminated.&nbsp;
          </p>
        </li>
        <li>
          <p>
            Paynetics shall not notify you about any modifications associated to
            extending the scope of the provided services, modifications that are
            more favourable to you, or reproductive modifications in the
            regulatory acts.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>
        TERM OF THE AGREEMENT. CANCELLATION. TERMINATION. VALIDITY PERIOD OF THE
        CARD&nbsp;
      </p>
      <ol>
        <li>
          <p>
            This Agreement will become effective when your application is approved
            by Paynetics. This Agreement will remain valid until it is terminated
            in accordance with its terms.
          </p>
        </li>
        <li>
          <p>This Agreement can be terminated by you:</p>
          <ol>
            <li>
              <p>
                notifying Paynetics you do not accept changes to this Agreement in
                accordance with clause 16.3;
              </p>
            </li>
            <li>
              <p>
                providing Paynetics a written request for termination of this
                Agreement at any time.
              </p>
            </li>
          </ol>
        </li>
        <li>
          <p>This Agreement can be terminated by Paynetics:&nbsp;</p>
          <ol>
            <li>
              <p>
                providing you 1 month notice of termination of this
                Agreement;&nbsp;
              </p>
            </li>
            <li>
              <p>immediately if:</p>
              <ol>
                <li>
                  <p>
                    you/the Cardholders breach an important term or persistently
                    breach the provisions of this Agreement;&nbsp;
                  </p>
                </li>
                <li>
                  <p>
                    Paynetics reasonably believes that your Account, Card or any
                    of its services is being used for any fraudulent or illegal
                    purposes or in an unauthorised manner or for Prohibited
                    Transactions;
                  </p>
                </li>
                <li>
                  <p>
                    Paynetics is required to do so to comply with any law or
                    regulation or a requirement by a regulatory authority or a
                    Card Organisation;
                  </p>
                </li>
                <li>
                  <p>
                    &lrm;Paynetics reasonably believes that continuing to permit
                    use of your Account or Card may cause Paynetics to &lrm;breach
                    any applicable law or regulation, code or other &lrm;duty
                    applicable to it or expose Paynetics to any &lrm;adverse
                    action, &lrm;censure, fine or penalty from any regulatory
                    authority, law enforcement &lrm;or other &lrm;governmental
                    agency or Card Organisation;&lrm;
                  </p>
                </li>
                <li>
                  <p>
                    you become bankrupt, insolvent or are subject to an event or
                    circumstance which is analogous or with a similar legal
                    effect;
                  </p>
                </li>
                <li>
                  <p>in other cases provided by law or in the Agreement.&nbsp;</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
        <li>
          <p>
            If this Agreement is terminated for any reason, your and the
            Cardholders&rsquo; right to use the Account and Card will be
            terminated, your Account will be closed and Card will be deactivated.
            &nbsp;All fees and other amounts due to Paynetics under the Agreement,
            if any, will become payable on termination. You can request the return
            of any remaining funds on your Account in accordance with clause 18.
          </p>
        </li>
        <li>
          <p>
            You will be responsible for all payments performed prior to
            termination of this Agreement, their resulting liabilities and any
            other obligations relating to the use and servicing of the Account or
            Card prior to such termination.
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>REDEMPTION</p>
      <ol>
        <li>
          <p>
            You can redeem all or part of the e-money held on your Account any
            time for the duration of this Agreement by requesting Paynetics to
            &lrm;refund it to you. &nbsp;After this Agreement is terminated, you
            can only request to redeem the remaining e-money on your Account in
            full. You can request to redeem the e-money by contacting Customer
            Relations Centre. You will need to &lrm;provide Paynetics the details
            of an account [which must be an account in your name] to which you
            would like the refund to be made and &lrm;any other details we may
            require. Paynetics may require you to provide satisfactory
            confirmation of &lrm;your identity and address before the refund is
            made.&lrm;
          </p>
        </li>
        <li>
          <p>
            All redemptions will be paid in the currency of your Account in which
            the remaining e-money being redeemed is held, at an exchange rate
            &lrm;prevailing at the time of processing the redemption. &lrm;
          </p>
        </li>
        <li>
          <p>
            A redemption fee (see Tariff) will be charged to cover redemption
            costs on each &lrm;redemption request as per the Tariff.
          </p>
        </li>
        <li>
          <p>
            Paynetics will not refund the remaining value of e-money on your
            Account if you make the request for &lrm;redemption more than 6
            &lrm;years after the date of termination of this Agreement.&lrm;
          </p>
        </li>
      </ol>
    </li>
    <li>
      <p>COMPLAINTS</p>
      <ol>
        <li>
          <p>
            If you wish to complain about Paynetics services under this Agreement,
            you can do so by contacting Paynetics Customer Relations Centre.
          </p>
        </li>
        <li>
          <p>
            You can find more information on how we handle complaints on &lrm;the
            Website. We will provide you with a copy of our complaints
            &lrm;procedure on your request or if we receive a &lrm;complaint from
            you. &lrm;In most cases, Paynetics will review your complaint and
            provide a full response within 15 business days from the date the
            complaint is received. In exceptional circumstances, where Paynetics
            is unable to respond to your complaint in full within that timeframe,
            Paynetics will send you a holding response with reasons for the delay
            and the timeframe within which you will receive a full response, which
            in any case will be within 35 business days from the date your
            complaint was received.&nbsp;
          </p>
        </li>
      </ol>
    </li>
  </ol>
  <ol start="3">
    <li>
      <p>
        Where the payment services under this Agreement are provided in the UK, if
        Paynetics fails to provide a full response to your complaint within the
        time limit referred to above or has failed to resolve your complaint to
        your satisfaction, you may refer your complaints to the Financial
        Ombudsman Service (Exchange Tower, London E14 9SR, phone 0800 023 4567,
        email complaint.info@financial-ombudsman.org.uk&lrm;). Details of the
        service offered by the Financial Ombudsman Service are available
        at&nbsp;<a href="http://www.financial-ombudsman.org.uk"
          >www.financial-ombudsman.org.uk</a
        >.&nbsp;
      </p>
    </li>
  </ol>
  <p><br /></p>
  <ol start="4">
    <li>
      <p>
        Where the payment services under this Agreement are provided in the EEA,
        if Paynetics fails to provide a full response to your complaint within the
        time limit referred to above or has failed to resolve your complaint to
        your satisfaction, you may refer your complaints to the Payment Disputes
        Conciliation Committee with the Commission for Consumer Protection (1000,
        Sofia, 1 Vrabcha Str, fl. 4, Bulgaria). Details of the service offered by
        the Payment Disputes Conciliation Committee are available at&nbsp;<a
          href="http://www.kzp.bg"
          >www.kzp.bg</a
        >
        and&nbsp;https://abanksb.bg/pkps/pkps-contacts-En.html.
      </p>
    </li>
  </ol>
  <ol start="20">
    <li>
      <p>MISCELLANEOUS</p>
    </li>
  </ol>
  <ol>
    <li>
      <p>
        Where the payment services under this Agreement are provided in the UK,
        this Agreement shall be governed by the English law. Any disputes relating
        to this Agreement will be finally settled by English court.&nbsp;
      </p>
    </li>
  </ol>
  <p><br /></p>
  <ol start="2">
    <li>
      <p>
        Where the payment services under this Agreement are provided in the EEA,
        this Agreement shall be governed by the Bulgarian law. Any disputes
        relating to this Agreement will be finally settled by Bulgarian
        court.&nbsp;
      </p>
    </li>
  </ol>
  <p><br /></p>
  <ol start="3">
    <li>
      <p>
        Pursuant to Article 46, Paragraph 5 of the Bulgarian Law on Payment
        Services and Payment Systems (LPSPS), the Parties agree that Articles
        47-66 of the LPSPS (or any equivalent provisions under UK law, if
        applicable) shall not apply to their relations. All modifications to the
        Agreement and the Agreement and the Tariff shall be made as provided in
        Article 16 hereof.&nbsp;Pursuant to Article 67, Paragraph 4
        of&nbsp;the&nbsp;LPSPS, the Parties agree that Article 68, Paragraph 1,
        Article70, &nbsp;Paragraph 4 &nbsp;and 5, the term under Article 77,
        Paragraph 1, Article 78, Article 80, Article 82, &nbsp;Paragraph &nbsp;2
        and 3, Article85, &nbsp;Article 91, Article 92 and Article 93, Paragraph 1
        of the LPSPS&nbsp;(or any equivalent provisions under UK law, if
        applicable)&nbsp;shall not apply to their relations but only the relevant
        provisions of the Agreement and the Agreement in which they are
        incorporated by reference.
      </p>
    </li>
    <li>
      <p>
        Paynetics may transfer or assign its rights and obligations under this
        Agreement to another company or individual at any time. If the transfer
        means that another organisation will be providing the services under this
        Agreement to you instead of Paynetics, Paynetics will give you notice of
        the transfer and the date when the transfer will take effect. Unless
        specified otherwise in the notice, the terms of this Agreement will be
        binding on you and the transferee as if the transferee was the original
        party to this Agreement from the date the transfer takes effect.&lrm; This
        will not affect any of your legal rights relating to this Agreement or the
        services provided under it.&nbsp;
      </p>
    </li>
    <li>
      <p>
        You cannot transfer or assign any of your rights and obligations under
        this Agreement to another individual or company without Paynetics&rsquo;
        prior written consent.&nbsp;
      </p>
    </li>
    <li>
      <p>
        If a court or competent authority establishes that a certain provision in
        the Agreement (or any part of any provision) is invalid, illegal or
        unenforceable, such provision (or part of it) shall be deemed to be
        non-existent to the extent necessary, but the validity and applicability
        of all other provisions of the Agreement shall not be affected.&nbsp;
      </p>
    </li>
  </ol>
  <ol start="21">
    <li>
      <p>CUSTOMER RELATIONS CENTRE</p>
      <ol>
        <li>
          <p>
            You can contact Paynetics Customer Relations Centre using the contact
            details set out below. For monitoring purposes, we may record any
            conversation with the Customer Relations Centre.
          </p>
          <ol>
            <li>
              <p>
                Contact us about our services (open from 9 a.m. to 5 p.m., EET,
                from Monday to Friday):
              </p>
              <ol>
                <li>
                  <p>&nbsp;by phone: 0043 676 633 9008</p>
                </li>
                <li>
                  <p>&nbsp;by email: service-lunch@corplife.at</p>
                </li>
              </ol>
            </li>
            <li>
              <p>
                &nbsp;Report lost, stolen or misappropriated Cards or other
                unauthorised access to your Account (available 24 hours a day):
              </p>
              <ol>
                <li>
                  <p>&lrm;Via the app;&nbsp;</p>
                </li>
                <li>
                  <p>Via +44 20 3769 8510&lrm;</p>
                </li>
              </ol>
            </li>
          </ol>
        </li>
      </ol>
    </li>
  </ol>
</body>
</html>
`;

  const agbUberLunch = `<html>
<head> </head>

<body>
  <h1>Über Lunch Karte</h1>
  <p><br /></p>
  <p>Payment accounts and Mastercard cards are issued by Paynetics AD (via Paystratus Group Ltd, a Technical Provider of Paynetics AD) with company address at 76A James Bourchier Blvd, 1407 Sofia, Bulgaria, company registration number 131574695 and VAT BG131574695, pursuant to an e-money license issued by the Bulgarian National Bank No. 44/11.04.2016.</p>
</body>
</html>
`;

  const impressum = `<html>
<head> </head>

<body>
  <h1>Impressum</h1>
  <p><br /></p>
  <p>Corplife GmbH</p>
  <p><br></p>
  <p>Wasserzeile 27</p>
  <p>3400 Klosterneuburg</p>
  <p><br></p>
  <p>E-Mail:&nbsp;<a href="mailto:service@corplife.at">service@corplife.at<br></a>Telefonnummer: +43 676 633 9008</p>
  <p><br></p>
  <p>Gesellschaft mit beschr&auml;nkter Haftung</p>
  <p>Gesch&auml;ftsf&uuml;hrer: Mario Nowak</p>
  <p>Firmenbuchgericht: Handelsgericht Wien</p>
  <p>Firmenbuchnummer: FN 442616 g</p>
  <p>UID: ATU70038069</p>
  <p><br></p></body>
</html>
`;

  const policy = `<html>
<head> </head>

<body>
  <h1>Datenschutz</h1>
  <p><br /></p>
  <p>Corplife respects your privacy and personal information. We want to ensure that your visit to our website is safe and satisfying. This privacy policy explains what we collect and how we use, transfer and store your data. In doing so, the processing is carried out in strict compliance with the legal data protection regulations.</p>
  <p><br></p>
  <p>General information</p>
  <p>With this privacy policy, we inform you about how we use, share and otherwise process the personal data we collect from you. We treat all personal data in accordance with the provisions of European and Austrian data protection law.</p>
  <p><br></p>
  <p>1. person responsible</p>
  <p>2. personal data</p>
  <p>3. data subject rights</p>
  <p>4. data security</p>
  <p>5. transfer of data to third parties</p>
  <p>6. storage of data</p>
  <p>7. server log files (website / app)</p>
  <p>8. processing activities of third parties (website / app)</p>
  <p><br></p>
  <p>1. responsible person</p>
  <p>Corplife GmbH&nbsp;</p>
  <p>Wasserzeile 27, 3400 Klosterneuburg, Austria</p>
  <p><br></p>
  <p>E-mail: office@corplife.at&nbsp;</p>
  <p>Phone: +43 676 4302134</p>
  <p><br></p>
  <p>Company register number: FN 442616g</p>
  <p>Data protection officer: Wolfgang Weibl</p>
  <p><br></p>
  <p>2. personal data</p>
  <p>We collect, process and use your personal data only in accordance with the GDPR and other applicable data protection laws and regulations.</p>
  <p><br></p>
  <p>3. data subject rights</p>
  <p>As a data subject, you have the right to information about your stored personal data as well as a right to correction, data transfer, objection, restriction of processing as well as blocking/anonymization or deletion of the data, insofar as no exception (e.g. legal obligation to retain data) exists and in accordance with the legal provisions.If you are of the opinion that the processing of your personal data by us violates the applicable data protection law or your data protection claims have been violated in another way, you have the possibility to complain to the competent supervisory authority. In Austria, the data protection authority (<a href="http://www.dsb.gv.at">www.dsb.gv.at</a>) is responsible for this.</p>
  <p><br></p>
  <p>4. data security</p>
  <p>Your personal data is protected by appropriate organizational and technical precautions. These precautions relate in particular to protection against unauthorized, illegal or even accidental access, processing, loss, use and manipulation.</p>
  <p><br></p>
  <p>5. transmission of data to third parties</p>
  <p>If for individual processing purposes the data is transferred to third parties where further information is required, you will find this under the respective purpose.</p>
  <p><br></p>
  <p>6. storage of data</p>
  <p>In accordance with the applicable data protection requirements, we are obligated pursuant to Art. 5 para. 1 lit. e DSGVO to delete personal data immediately as soon as the purpose for processing has been fulfilled. In this context, we would like to point out that statutory retention obligations and periods constitute a legitimate purpose for the processing of personal data.</p>
  <p><br></p>
  <p>7. server log files (website / app)</p>
  <p>To operate and maintain the security and functionality of our websites and to provide information about our services, the operator of the respective website automatically collects and stores information in so-called server log files, which your browser automatically transmits to us. This includes your Internet protocol address (IP address), browser and language setting, operating system, referrer URL, your Internet service provider and date/time.We reserve the right to check this data retrospectively if we become aware of specific indications of unlawful use. These log files are stored indefinitely.The legal basis is on the one hand the fulfillment of the contract, specifically the free provision of our website (Art 6 para 1 lit b DSGVO), in our overriding interest in the security and functionality of our website (Art 6 para 1 lit f DSGVO) and, finally, these are technically necessary for the operation of the website (&sect; 165 para 3 TKG).</p>
  <p><br></p>
  <p>8. processing activities of third parties (website / app).</p>
  <p>Detailed information about cookies and other data transfers to third parties can be found in the Consent banner</p>
  <p><br></p>
  <p>Matomo</p>
  <p>InnoCraft Ltd.</p>
  <p>150 Willis St, 6011 Wellington</p>
  <p>New Zealand Privacy Policy of Matomo</p>
  <p>We have concluded a corresponding order processing agreement with the provider. The relationship with the web analytics provider is based on an adequacy decision of the European Commission, the third country has an adequate level of data protection within the meaning of the GDPR.</p>
  <p><br></p>
  <p>Magento Inc.</p>
  <p>3640 Holdrege Avenue</p>
  <p>Los Angeles, CA 90016</p>
  <p>USA</p>
  <p><a href="https://business.adobe.com/resources/main.html">Privacy policy of Magento</a></p>
  <p>We have concluded a corresponding order processing agreement with the provider. The relationship with the web analytics provider is based on an adequacy decision of the European Commission, the provider is a participant in the EU-US Privacy Shield (&quot;EU-US Privacy Shield&quot;).</p>
  <p><br></p>
  <p>Planio Gmbh</p>
  <p>Rudolfstr. 14</p>
  <p>10245 Berlin</p>
  <p>Germany</p>
  <p>Privacy Policy of Planio</p>
  <p>We have concluded a corresponding order processing agreement with the provider.</p>
  <p><br></p>
  <p>MailChimp</p>
  <p>The Rocket Science Group LLC d/b/a MailChimp</p>
  <p>675 Ponce de Leon Ave NE, Suite 5000</p>
  <p>Atlanta, GA 30308</p>
  <p>USA</p>
  <p><a href="https://mailchimp.com/legal/privacy/%20/">Privacy policy of MailChimp</a></p>
  <p>We have concluded a corresponding order processing agreement with the provider. The relationship with the web analytics provider is based on an adequacy decision of the European Commission, the provider is a participant of theEU-US Privacy Shield (&quot;EU-US Privacy Shield&quot;).</p>
  <p><br></p>
  <p>Slack</p>
  <p>500 Howard Street</p>
  <p>San Francisco, CA 94105</p>
  <p>USA</p>
  <p><a href="https://slack.com/intl/de-de/trust/privacy/privacy-policy?geocode=de-de">Privacy policy of Slack</a></p>
  <p><br></p>
  <p>Google Analytics</p>
  <p>Google LLC</p>
  <p>1600 Amphitheatre Parkway</p>
  <p>Mountain View, CA 94043</p>
  <p>USA</p>
  <p><a href="https://policies.google.com/privacy?hl=de&gl=at">Privacy policy of Google</a></p>
  <p><br></p>
  <p>This website uses Google Analytics, a web analytics service provided by Google, Inc (&quot;Google&quot;). Google Analytics uses &quot;cookies&quot;, which are text files placed on your computer, to help the website analyze how users use the site. The information generated by the cookie about your use of this website is usually transmitted to a Google server in the USA and stored there. In the event that IP anonymization is activated on this website, however, your IP address will be truncated beforehand by Google within member states of the European Union or in other contracting states to the Agreement on the European Economic Area. Only in exceptional cases will the full IP address be transmitted to a Google server in the USA and shortened there. On behalf of the operator of this website, Google will use this information for the purpose of evaluating your use of the website, compiling reports on website activity and providing other services relating to website activity and internet usage to the website operator. The IP address transmitted by your browser as part of Google Analytics will not be merged with other data from Google. You may refuse the use of cookies by selecting the appropriate settings on your browser, however please note that if you do this you may not be able to use the full functionality of this website.</p>
  <p><br></p>
  <p>You can also prevent the collection of data generated by the cookie and related to your use of the website (including your IP address) to Google and the processing of this data by Google by downloading and installing the browser plugin available at the following link:&nbsp;<a href="http://tools.google.com/dlpage/gaoptout?hl=de">http://tools.google.com/dlpage/gaoptout?hl=de</a></p>
  <p><br></p>
  <p>Cookies are used for this purpose, which enable an analysis of the use of the website by your users. The information thus generated is transferred to the provider&apos;s server and stored there.</p>
  <p><br></p>
  <p>You can prevent this by setting your browser so that no cookies are stored.</p>
  <p><br></p>
  <p>Your IP address is recorded, but pseudonymized immediately (e.g. by deleting the last 8 bits).</p>
  <p><br></p>
  <p>Facebook, Inc.</p>
  <p>The social network facebook.com is operated by Meta Platforms Inc (formerly Facebook Inc), 1601 S. California Ave, Palo Alto, CA 94304, USA and is used for user interaction. The data protection controller for users in the EU is Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland (both: &quot;Facebook&quot;). When you visit our Facebook profile, and on Facebook&apos;s website, their privacy policy applies.</p>
  <p><br></p>
  <p>The purpose of data collection and processing of data as well as use of the same by Facebook, as well as the types of data (scope of data) can be found by the user in the privacy notices published by Facebook itself; see:&nbsp;<a href="http://www.facebook.com/policy.php">http://www.facebook.com/policy.php</a>. In the interest of the best possible transparency, we summarize the key points for the user:</p>
  <p><br></p>
  <p>The data so collected is used to analyze user behavior and to serve, select, evaluate and understand the ads that Facebook serves on and off Facebook (including ads served by or on behalf of Facebook affiliates) and to compile statistics about users. Facebook also uses the data it has to improve its advertising and measurement systems so that Facebook can display relevant ads to users on and off Facebook Services and measure the effectiveness and reach of ads and services.</p>
  <p><br></p>
  <p>If the user is registered with Facebook, by using the data collected, Facebook is able to provide services to the user, personalize content for the user, and provide the user with links and suggestions in which the user may be interested. Finally, the collected data is used to send marketing communications to the user, to communicate with the user about its services and to inform the user about Facebook&apos;s policies and terms.If the user is the owner of a Facebook account and visits Facebook, he or she has given consent for his or her information to be collected, transferred, stored, disclosed and used in accordance with Facebook&apos;s Privacy Policy (<a href="https://www.facebook.com/about/privacy">https://www.facebook.com/about/privacy</a>). In the account settings, the user can change the privacy settings of his Facebook account.</p>
  <p><br></p>
  <p>For more information about Facebook and the GDPR, please visit:&nbsp;<a href="https://www.facebook.com/business/gdpr#Facebook-als-Datenverantwortlicher-vs.-Auftragsverarbeiter">https://www.facebook.com/business/gdpr#Facebook-als-Datenverantwortlicher-vs.-Auftragsverarbeiter</a>.</p>
  <p><br></p>
  <p>Facebook Plugins</p>
  <p>Our online offer uses social plugins (&quot;plugins&quot;) of the social network facebook.com, which is operated by Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland (&quot;Facebook&quot;), whereby the appearance of the Facebook social plugins can be viewed here:&nbsp;<a href="https://developers.facebook.com/docs/plugins/">https://developers.facebook.com/docs/plugins/</a>.</p>
  <p><br></p>
  <p>By integrating the plugins, Facebook receives the information that a user has accessed the corresponding page of the online offer. If the user is logged into Facebook, Facebook can assign the visit to his Facebook account. If users interact with the plugins, for example by clicking the Like button or posting a comment, the corresponding information is transmitted from your device directly to Facebook and stored there. If a user is not a member of Facebook, there is still the possibility that Facebook will learn and store his or her IP address. According to Facebook, only an anonymized IP address is stored in Germany.</p>
  <p><br></p>
  <p>The purpose and scope of the data collection and the further processing and use of the data by Facebook, as well as the related rights and settings options for protecting the privacy of users, can be found in the privacy policy of Facebook:&nbsp;<a href="https://www.facebook.com/about/privacy/">https://www.facebook.com/about/privacy/</a>.</p>
  <p><br></p>
  <p>If a user is a Facebook member and does not want Facebook to collect data about him or her via this online offer and link it to his or her membership data stored with Facebook, he or she must log out of Facebook and delete his or her cookies before using our online offer. Further settings and objections to the use of data for advertising purposes, are possible within the Facebook profile settings:&nbsp;<a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a> or via the US site&nbsp;<a href="http://www.aboutads.info/choices/">http://www.aboutads.info/choices/</a> or the EU site&nbsp;<a href="http://www.youronlinechoices.com/">http://www.youronlinechoices.com/</a>. The settings are platform-independent, i.e. they are applied to all devices, such as desktop computers or mobile devices.</p>
  <p><br></p>
  <p>Facebook Remarketing</p>
  <p>We also use &quot;Facebook Pixel&quot; of the social network Facebook, which is operated by Facebook Inc, 1 Hacker Way, Menlo Park, CA 94025, USA, or if you are a resident of the EU, Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Ireland. With the help of the Facebook pixel, it is possible for Facebook to determine the visitors to our offer as a target group for the display of advertisements, so-called &quot;Facebook ads&quot;. Accordingly, we use the Facebook pixel to display the Facebook ads placed by us only to those Facebook users who have also shown an interest in our Internet offer. This means that with the help of the Facebook pixel, we want to ensure that our Facebook ads correspond to the potential interest of the users and do not have a harassing effect. With the help of the Facebook pixel, we can also track the effectiveness of the Facebook ads for statistical and market research purposes by seeing whether users were redirected to our website after clicking on a Facebook ad.</p>
  <p><br></p>
  <p>The Facebook pixel is directly integrated by Facebook when you visit our website and can save a so-called cookie, i.e. a small file, on your device. If you then log in to Facebook or visit Facebook while logged in, your visit to our website will be noted in your profile. The data collected about you is anonymous for us, so it does not offer us any conclusions about the identity of the user. However, the data is stored and processed by Facebook, so that a connection to the respective user profile is possible. The processing of the data by Facebook takes place within the framework of Facebook&apos;s data usage policy. Accordingly, you can find more information on how the remarketing pixel works and generally how Facebook ads are displayed, in Facebook&apos;s data usage policy: https://www.facebook.com/policy.php.</p>
  <p><br></p>
  <p>You can object to the collection by the Facebook pixel and use of your data for the display of Facebook ads. To do so, you can visit the page set up by Facebook and follow the instructions there on the settings for usage-based advertising:&nbsp;<a href="https://www.facebook.com/settings?tab=ads">https://www.facebook.com/settings?tab=ads</a>. The settings are platform-independent, i.e. they are applied to all devices, such as desktop computers or mobile devices.</p>
  <p><br></p>
  <p>Hotjar</p>
  <p>We use Hotjar (Hotjar Ltd Dragonara Business Centre 5th Floor, Dragonara Road, Paceville St Julian&apos;s STJ 3141 Malta;) to better understand the needs of our users and to optimize the offering and experience on this website. Using Hotjar&apos;s technology, we get a better understanding of our users&apos; experience (e.g. how much time users spend on which pages, which links they click, what they like and dislike, etc.) and this helps us to tailor our offering to our users&apos; feedback. Hotjar works with cookies and other technologies to collect data about our users&apos; behavior and about their devices, in particular IP address of the device (collected and stored only in anonymized form during your website use), screen size, device type (Unique Device Identifiers), information about the browser used, location (country only), language preferred to view our website. Hotjar stores this information on our behalf in a pseudonymized user profile. Hotjar is contractually prohibited from selling the data collected on our behalf. For more information, see the &apos;<a href="https://help.hotjar.com/hc/en-us/categories/115001323967">about Hotjar</a>&apos; section on Hotjar&apos;s help page.</p>
  <p><br></p>
  <p>Instagram</p>
  <p>Instagram is part of Meta Platforms Inc (formerly Facebook Inc), 1601 S. California Ave, Palo Alto, CA 94304, USA. The data protection controller for users in the EU is Facebook Ireland Ltd, 4 Grand Canal Square, Grand Canal Harbour, Dublin 2 Ireland. When you visit our Instagram profile, and on Instagram&apos;s website, their privacy policy applies.</p>
  <p><br></p>
  <p>The purpose of the data collection and processing as well as the use of the data by Instagram or Facebook, as well as the types of data (scope of the data) can be found in the privacy policy published by Instagram itself; see:&nbsp;<a href="https://help.instagram.com/519522125107875">https://help.instagram.com/519522125107875</a>. The information listed above for Facebook also applies analogously to Instagram.</p>
  <p><br></p>
  <p>If the data subject follows the link to Instagram, data is processed, collected, transferred, stored, disclosed and used in accordance with Instagram&apos;s privacy policy. Furthermore, cookies may be stored on the device of the data subject when visiting the Instagram website. Facebook&apos;s cookie policy applies to this:&nbsp;<a href="https://www.facebook.com/policies/cookies">https://www.facebook.com/policies/cookies</a>. If the data subject is the owner of an Instagram account, the information transmitted by Instagram or Facebook can be linked to this account.</p>
  <p><br></p>
  <p>LinkedIn</p>
  <p>Our websites link to LinkedIn and we have a LinkedIn company page. The provider is LinkedIn Ireland Unlimited Company Wilton Place, Dublin 2, Ireland (&quot;LinkedIn&quot;).&nbsp;</p>
  <p><br></p>
  <p>If you follow a link to LinkedIn or visit our company page, your data will be processed by LinkedIn as the data controller based on their privacy policy. If you interact on our company page, i.e. for example comment, like the page or posts, your data will be processed by us as part of LinkedIn&apos;s functions and also by LinkedIn. We would like to point out that if you interact with public company pages, the data about this interaction may be published and the processing is technically necessary to provide the functions of LinkedIn.&nbsp;</p>
  <p><br></p>
  <p>For more information on data protection at LinkedIn, please see the privacy policy:&nbsp;<a href="http://www.linkedin.com/legal/privacy-policy">www.linkedin.com/legal/privacy-policy</a>, as well as the possibility to opt-out:&nbsp;<a href="http://www.linkedin.com/psettings/guest-controls/retargeting-opt-out">www.linkedin.com/psettings/guest-controls/retargeting-opt-out</a>.</p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  <p>.</p>
  <p><br></p>
  <p><br></p>
  <p><br></p>
  </body>
</html>
`;

  useEffect(() => {
    if (width < 1000) {
      dispatch(handleSidebar(false));
    }
  }, [width, dispatch]);

  const getHtml = () => {
    switch (types) {
    case 0:
      return (
        <div
          style={{
            backgroundColor: "white",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 16
          }}
          dangerouslySetInnerHTML={{ __html: agb }}
        />
      );
    case 1:
      return (
        <div
          style={{
            backgroundColor: "white",
            paddingLeft: 32,
            paddingRight: 32,
            paddingTop: 16,
            paddingBottom: 16
          }}
          dangerouslySetInnerHTML={{ __html: agbLunch }}
        />
      );
    case 2:
      return (
        <div
          style={{
            backgroundColor: "white",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 16
          }}
          dangerouslySetInnerHTML={{ __html: agbUberLunch }}
        />
      );
    case 3:
      return (
        <div
          style={{
            backgroundColor: "white",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 16
          }}
          dangerouslySetInnerHTML={{ __html: policy }}
        />
      );
    case 4:
      return (
        <div
          style={{
            backgroundColor: "white",
            paddingLeft: 16,
            paddingRight: 16,
            paddingTop: 16,
            paddingBottom: 16
          }}
          dangerouslySetInnerHTML={{ __html: impressum }}
        />
      );
    }
  };

  return (
    <ContentContainer>
      <MainSpace>{getHtml()}</MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: state.users.userInfo.user
  };
};

export default connect(mapStateToProps, null)(AgbsPage);
