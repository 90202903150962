import React, {ReactNode} from "react";
import {styled} from "@mui/material/styles";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

export type TabType = {
  value: string;
  label: string;
}

interface IProps {
  selectedTab: any;
  onChange: (value: any) => void;
  tabs: TabType[]
}

const AntTabsClone = styled(Tabs)({
  borderBottom: "1px solid #e8e8e8",
  "& .MuiTabs-indicator": {
    backgroundColor: "#3D3D8E",
    height: "6px",
    borderRadius: "10px"
  },
});

const AntTab = styled((props: any) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    minWidth: 0,
    [theme.breakpoints.up("sm")]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: "rgba(0, 0, 0, 0.85)",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      "\"Segoe UI\"",
      "Roboto",
      "\"Helvetica Neue\"",
      "Arial",
      "sans-serif",
      "\"Apple Color Emoji\"",
      "\"Segoe UI Emoji\"",
      "\"Segoe UI Symbol\"",
    ].join(","),
    "&:hover": {
      color: "#3D3D8E",
      opacity: 1
    },
    "&.Mui-selected": {
      color: "#3D3D8E",
      fontWeight: theme.typography.fontWeightMedium,
      paddingLeft: 0
    },
    "&.Mui-focusVisible": {
      backgroundColor: "#d1eaff",
    },
  }),
);

export default function AntTabs(props: IProps) {
  return(
    <AntTabsClone value={props.selectedTab} onChange={(e: any, newValue: any) => props.onChange(newValue)} aria-label="basic tabs example">
      {props.tabs && props.tabs.length > 0 && props.tabs.map((tab: TabType) => (
        <AntTab
          key={tab.value}
          label={tab.label}
          value={tab.value}
        />
      ))}
    </AntTabsClone>
  );
}
