import {
  SEARCH_DATA_EMPLOYEE_LIST,
  searchingDataDispatchTypes} from "../types/pageSearchTypes";


interface DefaultStateI {
	employeeSearchData?: any
}

const initialState:DefaultStateI = {
  employeeSearchData: []
};

export const pageSearchDataReducer = (
  state: DefaultStateI = initialState,
  action: searchingDataDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case SEARCH_DATA_EMPLOYEE_LIST:
    return {
      employeeSearchData: action.payload
    };
  default:
    return state;
  }
};
