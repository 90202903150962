import React, {useEffect, useRef, useState} from "react";
import deleteIcon from "img/Dashboard/Employee/delete_employee.svg";
import {connect} from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import {isAllowed, userRoles} from "../../utils/permissionsAllow";
import {useTranslation} from "react-i18next";
import {RootState} from "../../redux/reducers/rootReducer";
import {getToken} from "../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {modalStyles} from "../../utils/modalStyles";
import SubmitActionModal from "../Modals/SubmitActionModal";
import Modal from "react-modal";

interface IProps {
  data: any;
  updateData: () => void;
  style?: any;
}


function ActionDotsRoles(props:IProps) {
  const { t } = useTranslation();

  const actionRef:any = useRef();

  const [menuListOpen, setMenuListOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  // loading after delete
  const [showLoading, setShowLoading] = useState(false);

  // Detect click outside for close menu

  const EmployeeActionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!EmployeeActionsRef.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleRemoveRole = async () => {
    setShowLoading(true);
    const token = await getToken();
    try {
      const response = await axios.delete(`${environment.baseUrl}${urls.roles.roles}/${props.data.role._id}`,
        {
          headers: {Authorization: `Bearer ${token}`}
        });
      if (response && response.status === 200) {
        setShowLoading(false);
        setMenuListOpen(false);
        props.updateData();
      }
    } catch (e: any) {
      if (e.response.status === 401) {
        console.error(e);
      }
    }
  };

  useEffect(() => {
    if (menuListOpen) {
      actionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuListOpen]);

  return(
    showLoading
      ? <BeatLoader size="8px" />
      :
      <div ref={EmployeeActionsRef} className="action_dots_container">
        <Modal style={modalStyles} isOpen={showDeleteModal}>
          <SubmitActionModal
            handleSubmit={() => handleRemoveRole()}
            type="employee_delete"
            data={["1"]}
            closeModal={() => setShowDeleteModal(false)}
          />
        </Modal>
        <div
          onClick={() => setMenuListOpen(!menuListOpen)}
          className="action_container"
          ref={actionRef}
		  style={props.style}
        >
          <div className="action_dot" />
          <div className="action_dot" />
          <div className="action_dot" />
        </div>
        {menuListOpen && (
          <div className="table_menu shadow">

            {isAllowed({data: {
              roles: [userRoles.superAdmin, userRoles.admin],
              permissions: {
                isAdmin: true,
                permissionsArray: []
              }
            }})
              ?
              <div
                onClick={() => setShowDeleteModal(true)}
                className="table_menu_item row">
                <img src={deleteIcon} alt="delete"/>
                <p>{t("dashboard.settings.list.actions.delete")}</p>
              </div>
              : null
            }
          </div>
        )}
      </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    deleteSuccess: state.users
  };
};

export default connect(mapStateToProps, null)(ActionDotsRoles);
