import {Dispatch} from "redux";
import {
  GET_NOTIFICATIONS_ERROR,
  GET_NOTIFICATIONS_SUCCESS,
  notificationDataDispatchTypes
} from "../types/notificationDataTypes";
import {errorHandler} from "../../utils/errorHandler";
/*import socketIOClient from "socket.io-client";*/
import {getToken} from "../../utils/cookies/tokensCookie";

// Should be used instead of global url, because socket do not recognize localhost from environments
const takeLiveUrl = () => {
  if (process.env.REACT_APP_ENV === "development") {
    return "https://api-dev.corplife.at";
  } else if (process.env.REACT_APP_ENV === "staging") {
    return "https://api-stag.corplife.at";
  } else if (process.env.REACT_APP_ENV === "production") {
    return "https://api.corplife.at";
  } else {
    return "localhost:8000";
  }
};

interface NotificationQuery {
  limit: number;
  skip: number;
  newNotificationsLimit: number;
  newNotificationsSkip: number;
}

export const takeNotificationData = ({
  limit,
  skip,
  newNotificationsLimit,
  newNotificationsSkip
}:NotificationQuery) =>
  async (dispatch: Dispatch<notificationDataDispatchTypes>) => {
    try {
      /*const socket = socketIOClient(takeLiveUrl(), {
        query: {
          token: getToken() || "",
          limit: limit.toString(),
          skip: skip.toString(),
          newNotificationsLimit: newNotificationsLimit.toString(),
          newNotificationsSkip: newNotificationsSkip.toString(),
        },
      });
      socket.on("FromAPI", (data) => {
        dispatch({
          type: GET_NOTIFICATIONS_SUCCESS,
          payload: data,
        });
      });*/
    } catch (e: any) {
      if (!errorHandler(e.request.status)) {
        dispatch({
          type: GET_NOTIFICATIONS_ERROR,
          payload: false,
        });
      }
    }
  };

