import DashboardContainer from "Components/Containers/DashboardContainer";
import InvoiceImage from "Components/Containers/InvoiceImage";
import PlainContainer from "Components/Containers/PlainContainer";
import PurpleContainer from "Components/Containers/PurpleContainer";
import DownloadBox from "Components/Content/DownloadBox";
import PageTitle from "Components/SimpleComponents/PageTitle";
import Spacer from "Components/SimpleComponents/Spacer";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {Link, useLocation, useNavigate, useParams} from "react-router-dom";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import NotFoundPage from "../error_pages/NotFoundPage";
import { RootState } from "../../redux/reducers/rootReducer";
import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../utils/permissionsAllow";
import { rewriteCurrency } from "../../utils/rewriteCurrency";
import ButtonWithIcon from "../../Components/Buttons/ButtonWithIcon";
import VerticalDivider from "../../Components/Containers/VerticalDivider";
import {
  clearInvoicesMessages,
  getOneInvoice,
  updateInvoice,
} from "../../redux/actions/invoicesActions";
import GeneralInfoLoading from "../../Components/LoadingPlaceholders/GeneralInfoPageLoading";
import MainSpace from "../../Components/Containers/MainSpace";
import ContentContainer from "../../Components/Containers/ContentContainer";
import moment from "moment";
import queryString from "query-string";
import {invoicesService} from "../../api/services/Invoices/invoices.service";
import {IInvoiceNavigationData} from "../../api/services/Invoices/invoices.interface";
import arrowRightIcon from "../../img/Dashboard/Orders/CaretRight.png";
import getBadgeDataForInvoiceStatus from "../../Components/Elements/Badge/helpers/getBadgeDataForInvoiceStatus";
import Badge from "../../Components/Elements/Badge/Badge";
import WarningBadgeIcon from "../../img/Warning.png";
import {modalStyles} from "../../utils/modalStyles";
import Modal from "react-modal";
import RejectInvoiceModal from "../../Components/Modals/RejectInvoiceModal/RejectInvoiceModal";
import userAvatar from "../../Components/Header/user_avatar.svg";
import {prepareImage} from "../../redux/actions/prepareImage";
import UserAvatar from "../../Components/Containers/UserAvatar";
import Tooltip from "../../Components/Tooltip/Tooltip";
import MessageReadPendingIcon from "../../img/Dashboard/Orders/message_read_pending.png";
import MessageReadApprovedIcon from "../../img/Dashboard/Orders/message_read_approved.png";
import paginateArrow from "../../img/pagination_arrow.png";

interface CustomProps {
  invoice?: any;
  updatedInvoices?: any;
  user?: any;
}

function OverviewDepartments({
  invoice,
  updatedInvoices
}: CustomProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location:any = useLocation();
  const params = useParams();
  const parsedSearch:any = queryString.parse(location.search) || "";

  const [oldInvoiceId, setOldInvoiceId] = useState("");
  const [showLoading, setShowLoading] = useState(true);
  const [showRejectInvoiceModal, setShowRejectInvoiceModal] = useState(false);
  const [invoicesNavigation, setInvoicesNavigation] = useState<IInvoiceNavigationData>({
    preInvoice: null,
    nextInvoice: null
  });

  // Handle accept invoice
  const handleInvoice = (status: number, comment?: string) => {
    const postData = {
      invoices: [
        {
          id: invoice._id,
          status: status,
          description: comment || invoice.status.description
        },
      ],
    };
    setShowLoading(true);
    dispatch(updateInvoice(postData));
  };

  const getNearestInvoices = async (invoiceId: string) => {
    const response = await new invoicesService().getNearestInvoices(invoiceId);
    if (response && response.status && response.data) {
      setInvoicesNavigation(response.data);
    }
  };

  useEffect(() => {
    if (params && params.invoiceId && oldInvoiceId !== params.invoiceId) {
	  setOldInvoiceId(params.invoiceId);
      dispatch(getOneInvoice(params.invoiceId));
      if (parsedSearch && parsedSearch.approval) {
        getNearestInvoices(params.invoiceId);
      }
    }
  }, [params]);

  useEffect(() => {
    if ((invoice && Object.keys(invoice).length !== 0)) {
      setShowLoading(false);
    }
  }, [invoice]);

  // taking Success/Error after update invoice
  useEffect(() => {
    if (updatedInvoices.success === "update_invoices") {
      dispatch(clearInvoicesMessages());
      if (params && params.invoiceId) {
        dispatch(getOneInvoice(params.invoiceId));
      }
    } else if (updatedInvoices.error) {
      dispatch(clearInvoicesMessages());
      toast.error(t(`warnings.${updatedInvoices.error}`));
      setShowLoading(false);
    } else if (updatedInvoices.error === "get_one_invoice") {
      navigate("/error");
      dispatch(clearInvoicesMessages());
    }
    // Need to disable it, because eslint says that I need to add
    // all dependencies from request
    // eslint-disable-next-line
  }, [updatedInvoices]);

  return (
    <ContentContainer>

      <Modal
        style={modalStyles}
        isOpen={showRejectInvoiceModal}
      >
        <RejectInvoiceModal
          onClose={() => setShowRejectInvoiceModal(false)}
          onSubmit={(comment?: string) => {
            setShowRejectInvoiceModal(false);
            handleInvoice(3, comment);
          }}
        />
      </Modal>

      <MainSpace>
        {showLoading ? (
          <div className="center" style={{ minHeight: "20vh" }}>
            <GeneralInfoLoading />
          </div>
        ) : (
          <>
            {invoice.error ? (
              <NotFoundPage />
            ) : (
              <>
                <div className="row">
                  <Link
                    to={
                      location?.state?.prevPath ===
                      "/notification-center"
                        ? "/notification-center"
                        : "/overview"
                    }
                    style={{ textDecoration: "none" }}
                  >
                    <p className="crumb">
                      {location?.state?.prevPath ===
                      "/notification-center"
                        ? t("overview.breadcrumb.notification")
                        : t("overview.breadcrumb.invoice")}
                    </p>
                  </Link>
                  <p className="crumb_arrow">{">"}</p>
                  <p className="crumb_current">{invoice.invoice.merchant}</p>
                </div>
                <div className="space-between">
                  <PageTitle mainTitle={t("dashboard.overview.title")} />
                  {isAllowed({
                    data: {
                      roles: [
                        userRoles.superAdmin,
                        userRoles.admin,
                        userRoles.custom,
                      ],
                      permissions: {
                        isAdmin: true,
                        permissionsArray: [userPermissions.invoices],
                      },
                    },
                  }) ? (
                      <div className="row">
                        {parsedSearch.approval
                          &&
                          <div className="row">
                            <div
                              style={{
                                height: "40px",
                                opacity: !invoicesNavigation.preInvoice  ? 0.5 : 1,
                                cursor: !invoicesNavigation.preInvoice ? "not-allowed": "pointer"
                              }}
                              className="pagination-page center"
                              onClick={() => invoicesNavigation.preInvoice && navigate(`/overview/${invoicesNavigation.preInvoice}?approval=true`)}
                            >
                              <Tooltip
                                style={{padding: "10px"}}
                                text={t("overview.details.prev_btn")}
                              >
                                <img src={paginateArrow} alt="arrow-left" />
                              </Tooltip>
                            </div>
                            <div
                              style={{
                                height: "40px",
                                opacity: !invoicesNavigation.nextInvoice  ? 0.5 : 1,
                                cursor: !invoicesNavigation.nextInvoice ? "not-allowed": "pointer"
                              }}
                              className="pagination-page center"
                              onClick={() => invoicesNavigation.nextInvoice && navigate(`/overview/${invoicesNavigation.nextInvoice}?approval=true`)}
                            >
                              <Tooltip
                                style={{padding: "10px"}}
                                text={t("overview.details.next_btn")}
                              >
                                <img src={paginateArrow} alt="arrow-right" />
                              </Tooltip>
                            </div>
                          </div>
                        }
                        <ButtonWithIcon
                          buttonType="success add-person"
                          buttonIcon="order-detail-accept"
                          buttonHandler={() => handleInvoice(2)}
                          buttonLabel={t("overview.buttons.approve")}
                          buttonStyles={{ marginLeft: "10px", marginRight: "10px", width: "180px" }}
                          disabled={invoice.status.type === 2}
                        />
                        <ButtonWithIcon
                          paddingTop="10px"
                          buttonType="primary add-person"
                          buttonIcon="orders-detail-reject"
                          buttonHandler={() => setShowRejectInvoiceModal(true)}
                          buttonLabel={t("overview.buttons.reject")}
                          disabled={invoice.status.type === 3}
                          buttonStyles={{ width: "180px" }}
                        />

                      </div>
                    ) : null}
                </div>

                <Spacer spacerHeight="23px" />

                <VerticalDivider ratio="49% 2% 49%">
                  <DashboardContainer type="standard-padding">
                    <p className="invoice-status">
                      {t("overview.details.status")}
                    </p>

                    <Badge
                      color={getBadgeDataForInvoiceStatus(invoice.status.type).color}
                      text={getBadgeDataForInvoiceStatus(invoice.status.type).text}
                    />

                    {
                      invoice && invoice.ocrErrors?.length > 0
                      &&
                      <div className="invoice-ocr-error">
                        <img src={WarningBadgeIcon} alt="warning"/>
                        <p>{t("overview.details.ocrErrorBadge.badge", {field: t(`overview.details.ocrErrorBadge.fields.${invoice.ocrErrors[0].field}`)})}</p>
                      </div>
                    }

                    <Spacer spacerHeight="8px" />

                    <p className="invoice-status">
                      {t("overview.details.detail")}
                    </p>

                    <div className="space-between">
                      <p className="invoice_detail_title">
                        {t("overview.details.employee")}
                      </p>
                      <div style={{display: "flex", flexDirection: "column", alignItems: "flex-end"}}>
                        <p className="invoice_detail_value">
                          {`${invoice.user.firstName} ${invoice.user.lastName}`}
                        </p>
                        <p className="invoice_detail_value">
                          {invoice.user.email}
                        </p>
                      </div>
                    </div>

                    <Spacer spacerHeight="8px" />

                    <div className="space-between">
                      <p className="invoice_detail_title">
                        {t("overview.details.type")}
                      </p>
                      <p className="invoice_detail_value">
                        {invoice.invoice.invoiceType === "2"
                          ? t("overview.details.supermarket")
                          : t("overview.details.restaurant")}
                      </p>
                    </div>

                    <Spacer spacerHeight="8px" />

                    <div className="space-between">
                      <p className="invoice_detail_title">
                        {t("overview.details.name")}
                      </p>
                      <p className="invoice_detail_value">
                        {invoice.invoice.merchant}
                      </p>
                    </div>

                    <Spacer spacerHeight="8px" />

                    <div className="space-between">
                      <p className="invoice_detail_title">
                        {t("overview.details.invoice_date")}
                      </p>
                      <p className="invoice_detail_value">
                        {moment.utc(invoice.invoice.date).format("DD.MM.YYYY; HH:mm")}
                      </p>
                    </div>

                    <Spacer spacerHeight="8px" />

                    <div className="space-between">
                      <p className="invoice_detail_title">
                        {t("overview.details.invoice_amount")}
                      </p>
                      <p className="invoice_detail_value">
                        {rewriteCurrency(invoice.invoice.amount, "EUR")}
                      </p>
                    </div>

                    <Spacer spacerHeight="32px" />

                    <div className="space-between">
                      <p className="invoice_detail_title">
                        {t("overview.details.sum_amount")}
                      </p>
                      <p className="invoice_detail_value_bold">
                        {rewriteCurrency(+invoice.claim, "EUR")}
                      </p>
                    </div>

                    {
                      invoice && invoice.comment &&
                      <div className="invoice-ocr-comment">
                        <p className="invoice-status">
                          {t("overview.details.comment.title")}
                        </p>
                        <div>
                          <UserAvatar imageUrl={invoice.comment.author.imageUrl} />
                          <div>
                            <p>{`${invoice.comment.author.firstName} ${invoice.comment.author.lastName}`}
                              <span>{moment(invoice.comment.createdAt).format("DD.MM.YYYY HH:mm")}</span>
                              <Tooltip
                                style={{minWidth: "180px", paddingLeft: "14px"}}
                                text={invoice.comment.isViewed
                                  ? t("overview.details.comment.readBadge", {name: `${invoice.user.firstName} ${invoice.user.lastName}`})
                                  : t("overview.details.comment.pendingBadge", {name: `${invoice.user.firstName} ${invoice.user.lastName}`})
                                }
                              >
                                <img
                                  className="invoice-ocr-comment-tooltip"
                                  src={invoice.comment.isViewed ? MessageReadApprovedIcon : MessageReadPendingIcon}
                                  alt="message"
                                />
                              </Tooltip>
                            </p>
                            <p>{invoice.comment.text}</p>
                          </div>
                        </div>
                      </div>
                    }

                  </DashboardContainer>

                  <div />

                  <PlainContainer type="standard-padding center">
                    <InvoiceImage imageKey={invoice.invoice.imageKey} />
                  </PlainContainer>
                </VerticalDivider>

                <Spacer spacerHeight="120px" />

                <PurpleContainer>
                  <DownloadBox />
                </PurpleContainer>
              </>
            )}
          </>
        )}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    invoice: state.invoices.invoice.invoice,
    updatedInvoices: state.invoices
  };
};

export default connect(mapStateToProps, null)(OverviewDepartments);
