import React, { useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useNavigate, useLocation } from "react-router-dom";
import { handleSidebar } from "../../redux/actions/sidebarActions";
import { toast } from "react-toastify";
import useWindowDimensions from "../../utils/useWindowSize";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import { ReactComponent as TrashIcon } from "../../img/i_trash.svg";
// import { ReactComponent as TrashIcon2 } from "../../img/";

import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../utils/permissionsAllow";
import { useTranslation } from "react-i18next";
import { getDepartments } from "../../redux/actions/branchAndDepartmentActions";
import {
  clearCompanyMessages,
  getCompanies,
  getCompanyData,
  updateCompany,
} from "../../redux/actions/companiesActions";
import { getUsers } from "../../redux/actions/usersActions";

import BannerTitle from "Components/SimpleComponents/BannerWithTitle";
import Button from "Components/Buttons/Button";
import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import Spacer from "Components/SimpleComponents/Spacer";
import VerticalDivider from "Components/Containers/VerticalDivider";
import SimpleInput from "Components/SimpleComponents/SimpleInput";
import ImageUpload from "../../Components/SimpleComponents/ImageUpload";
import GeneralInfoLoading from "../../Components/LoadingPlaceholders/GeneralInfoPageLoading";
import FormikInput from "../../Components/SimpleComponents/FormikInput";
import GeneralDropdown from "../../Components/SimpleComponents/GeneralDropdown";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import { createNewCompany } from "../../redux/actions/api";
import { createDepsAndBranches } from "../../redux/actions/branchesAndDepartmentsApi";
import Modal from "react-modal";
import LoadingModal from "../../Components/Modals/LoadingModal";
import { capitalize } from "../../utils/capitalizeFirstLetter";
import DepAndBranchContainer from "Components/Containers/depAndBranchContainer/depAndBranchContainer";
import { modalStyles } from "../../utils/modalStyles";
import axios from "axios";
import environment from "../../environment";
import { urls } from "../../api/urls";
import { getToken } from "../../utils/cookies/tokensCookie";
import GeneralInfoSupermarketWarningModal from "../../Components/Modals/GeneralInfoSupermarketWarningModal/GeneralInfoSupermarketWarningModal";
import SelectWithSearch from "../../Components/SimpleComponents/SelectWithSearch";
import validatePhoneNumber from "utils/phoneNumberValidation";
import {
  getUserCompany,
  getUserId,
  setUserCompany,
} from "../../utils/cookies/userCookies/userCookies";
import PdfUploadButton from "Components/SimpleComponents/PdfUploadButton";
import CompanySettingsSwitcher from "../../Components/Elements/CompanySettingsSwitcher/CompanySettingsSwitcher";

interface CustomProps {
  company?: any;
  employee?: any;
  postCompanyDataState?: any;
  companies?: any;
  user?: any;
}
export interface IGeneralInfoForm {
  approvalProcess: boolean;
  plans: any;
  departments?: [""];
  isActive?: boolean;
  updatedAt?: string;
  companyId?: string;
  notifications: boolean;
  isNotificationAllowed: boolean;
  isLunchActivated: boolean;
  isSupermarketAllowed: boolean;
  isBenefitsActivated: boolean;
  isHrzoneActivated: boolean;
  ocr: boolean;
  maxOcr: number;
  departmentAndBranches?: any[];
  virtualCard: boolean;
  virtualCardUploadRestaurantInvoices: boolean;
  budgetCorrection: boolean;
  budgetMoving: boolean;
  canBeDeleted: boolean;
  isManualInvoiceForVirtualCardAllowed: boolean;
  isCumulationAllowed: boolean;
  //   apiKey: string,
  companyInfo: {
    companyName: string;
    streetName: string;
    city: string;
    zipCode: string;
    country: "AT" | "DE";
    image: string;
    website: string;
    phone: string;
    guideLine: string;
    guideLineName: string;
  };
  contactPerson: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

function GeneralInfo({
  company,
  employee,
  postCompanyDataState,
  user,
}: CustomProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const location = useLocation();

  const [downloadLoading, setDownloadLoading] = useState(false);
  const downloadingRef = useRef(downloadLoading);
  downloadingRef.current = downloadLoading;

  const countriesOptions = [
    {
      label: t("dashboard.general_info.countries_options.at"),
      value: "AT",
    },
    {
      label: t("dashboard.general_info.countries_options.de"),
      value: "DE",
    },
  ];

  // Show Profile Page if user comes from changePassword page
  useEffect(() => {
    if (location.state) {
      const state: any = location.state;
      if (
        state.prevPath &&
        state.prevPath.toString().includes("resetPassword")
      ) {
        navigate("general-info/admin");
      }
    }
  }, [navigate]);

  // State for show add company
  const [createCompany, setCreateCompany] = useState(false);

  // Count of supermarket plans in future to show warning Popup on toggle isSupermarketAllowed
  const [supermarketPlans, setSupermarketPlans] = useState(0);
  const [showWarningSupermarketPopup, setShowWarningSupermarketPopup] =
    useState(false);

  // Page is initialized
  const [pageIsInitialized, setPageIsInitialized] = useState(false);

  useEffect(() => {
    const path = location.search;
    if (path && path === "?add") {
      setCreateCompany(true);
    } else {
      setCreateCompany(false);
    }
    setPageIsInitialized(true);
  }, [location]);

  // Get open plans with supermarket limits to show warning popup on toggle isSupermarketAllowed
  useEffect(() => {
    const getSupermarketLimitData = async () => {
      const token = getToken();
      const companyId = getUserCompany();
      await axios
        .get(
          `${environment.baseUrl}${urls.plans.getSupermarketPlansExists}/${companyId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res: any) => {
          setSupermarketPlans(Number(res.data?.plansCount));
        })
        .catch((err: any) => {
          setSupermarketPlans(0);
        });
    };
    getSupermarketLimitData();
  }, []);

  useEffect(() => {
    if (postCompanyDataState.success === "update_company") {
      dispatch(clearCompanyMessages());
      dispatch(getCompanyData());
      if (imageUpdate || guideLineUpdate) {
        toast.success(
          <CustomToastContainer
            message={t(
              imageUpdate
                ? "warnings.update_company_logo_success"
                : "warnings.update_company_guideline_success"
            )}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        setGuideLineUpdate(false);
        setImageUpdate(false);
      } else {
        toast.success(
          <CustomToastContainer
            message={t("warnings.update_company_info_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      }
      setShowSubmitButtonLoading(false);
    } else if (postCompanyDataState.error === "update_company") {
      dispatch(clearCompanyMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.update_company_info_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
      setShowSubmitButtonLoading(false);
    }
  }, [postCompanyDataState, t, dispatch]);

  //Image URl from upload
  const [imageUrl, setImageUrl] = useState("");

  const [guideLine, setGuideLine] = useState("");
  const [guideLineName, setGuideLineName] = useState("");

  const [dataLoaded, setDataLoaded] = useState(false);

  const formik = useFormik<IGeneralInfoForm>({
    initialValues: {
      approvalProcess: false,
      notifications: false,
      isLunchActivated: false,
      isBenefitsActivated: false,
      isHrzoneActivated: false,
      isSupermarketAllowed: false,
      isNotificationAllowed: false,
      ocr: false,
      maxOcr: 0,
      plans: [""],
      updatedAt: "",
      companyId: "",
      departmentAndBranches: [],
      virtualCard: false,
      virtualCardUploadRestaurantInvoices: false,
      budgetCorrection: false,
      budgetMoving: false,
      canBeDeleted: false,
      isManualInvoiceForVirtualCardAllowed: false,
      isCumulationAllowed: false,
      //   apiKey: "",
      companyInfo: {
        companyName: "",
        streetName: "",
        city: "",
        zipCode: "",
        country: "AT",
        image: "",
        website: "",
        phone: "",
        guideLine: "",
        guideLineName: "",
      },
      contactPerson: {
        firstName: "",
        lastName: "",
        email: "",
      },
    },
    validationSchema: Yup.object({
      companyInfo: Yup.object().shape({
        companyName: Yup.string()
          .required()
          .required(t("formik_warnings.general_info.company")),
        streetName: Yup.string().required(
          t("formik_warnings.general_info.streetName")
        ),
        city: Yup.string().required(t("formik_warnings.general_info.city")),
        zipCode: Yup.string().required(
          t("formik_warnings.general_info.zipCode")
        ),
        country: Yup.string().required(
          t("formik_warnings.general_info.country")
        ),
        website: Yup.string().required(
          t("formik_warnings.general_info.website")
        ),
        phone:
          (company && company.virtualCard) || createCompany
            ? Yup.string().test("test-name", "Error", function (value: any) {
              if (value && value.length > 0) {
                const { path, createError } = this;
                const phoneValidation = validatePhoneNumber(value);
                if (phoneValidation) {
                  return true;
                } else {
                  return createError({
                    path,
                    message: t(
                      "formik_warnings.general_info.phoneNumber_validation"
                    ),
                  });
                }
              } else {
                return true;
              }
            })
            : Yup.string(),
      }),
      contactPerson: Yup.object().shape({
        firstName: Yup.string().required(
          t("formik_warnings.general_info.firstName")
        ),
        lastName: Yup.string().required(
          t("formik_warnings.general_info.lastName")
        ),
        email: Yup.string()
          .required(t("formik_warnings.general_info.email"))
          .email(t("formik_warnings.general_info.emailValid")),
      }),
    }),
    // handle form submitting
    onSubmit: () => {
      //
    },
  });

  // taking data from server
  useEffect(() => {
    dispatch(getUsers({ limit: 1 }));
    dispatch(getCompanyData());
    dispatch(getDepartments());
  }, [dispatch, pageIsInitialized, createCompany]);

  // take data for inputs from redux store
  useEffect(() => {
    if (
      company &&
      Object.keys(company).length &&
      pageIsInitialized &&
      !createCompany
    ) {
      formik.values.plans = company.plans;
      formik.values.companyId = getUserCompany() || "";
      formik.values.companyInfo.companyName = company.companyInfo.companyName;
      formik.values.companyInfo.streetName = company.companyInfo.streetName;
      formik.values.companyInfo.city = company.companyInfo.city;
      formik.values.companyInfo.zipCode = company.companyInfo.zipCode;
      formik.values.companyInfo.country = company.companyInfo.country;
      formik.values.companyInfo.image = company.companyInfo.image;
      formik.values.companyInfo.website = company.companyInfo.website;
      formik.values.companyInfo.phone = company.companyInfo.phone;
      formik.values.contactPerson.firstName = company.contactPerson.firstName;
      formik.values.contactPerson.lastName = company.contactPerson.lastName;
      formik.values.contactPerson.email = company.contactPerson.email;
      formik.values.approvalProcess = company.approvalProcess;
      formik.values.notifications = company.notifications || false;
      formik.values.budgetCorrection = company.budgetCorrection || false;
      formik.values.budgetMoving = company.budgetMoving || false;
      formik.values.canBeDeleted = company.canBeDeleted;
      formik.values.virtualCardUploadRestaurantInvoices = company.virtualCardUploadRestaurantInvoices;
      formik.values.isLunchActivated = company.modules[0].isActivated;
      formik.values.isBenefitsActivated = company.modules[1].isActivated;
      formik.values.isHrzoneActivated =
        company?.modules[2]?.isActivated || false;
      formik.values.isSupermarketAllowed = company.isSupermarketAllowed;
      formik.values.isNotificationAllowed = company.isNotificationAllowed;
      formik.values.isManualInvoiceForVirtualCardAllowed =
        company.isManualInvoiceForVirtualCardAllowed;
      formik.values.isCumulationAllowed = company.isCumulationAllowed;
      formik.values.ocr = company.ocr;
      formik.values.maxOcr = company.maxOcr;

      formik.values.companyInfo.guideLine = company.companyInfo.guideLine;
      formik.values.companyInfo.guideLineName =
        company.companyInfo.guideLineName;
      setImageUrl(company.companyInfo.image);
      setGuideLine(company.companyInfo.guideLine);
      setGuideLineName(company.companyInfo.guideLineName);
    }
    if (createCompany) {
      formik.setValues({
        approvalProcess: false,
        notifications: false,
        isLunchActivated: true,
        isBenefitsActivated: true,
        isHrzoneActivated: false,
        isSupermarketAllowed: true,
        isNotificationAllowed: false,
        plans: [""],
        updatedAt: "",
        companyId: "",
        virtualCard: false,
        virtualCardUploadRestaurantInvoices: false,
        budgetCorrection: false,
        budgetMoving: false,
        canBeDeleted: false,
        isManualInvoiceForVirtualCardAllowed: false,
        isCumulationAllowed: false,
        ocr: false,
        maxOcr: 0,
        // apiKey: "",
        companyInfo: {
          companyName: "",
          streetName: "",
          city: "",
          zipCode: "",
          country: "AT",
          image: "",
          website: "",
          phone: "",
          guideLine: "",
          guideLineName: "",
        },
        contactPerson: {
          firstName: "",
          lastName: "",
          email: "",
        },
      });
      setImageUrl("");
      setGuideLineName("");
      setGuideLine("");
    }
    setDataLoaded(true);
    //Need to disable it, because eslint says, that I need to add
    //all dependencies from formik, but we need to update, when company
    // state is updated
    // eslint-disable-next-line
  }, [company, createCompany]);

  // empty handler for disabled inputs
  const handleInputChange = (inputString: string, id: string) => {
    //
  };

  const [imageUpdate, setImageUpdate] = useState(false);
  const [guideLineUpdate, setGuideLineUpdate] = useState(false);

  // Post Data
  const handleFormSubmit = (
    e?: any,
    imageKey?: string,
    type?: "image" | "guideLine",
    imageName?: string
  ) => {
    if (!imageKey) {
      if (e) e.preventDefault();
    } else {
      if (type === "guideLine") {
        setGuideLineUpdate(true);
      } else if (type === "image") {
        setImageUpdate(true);
      }
    }
    if (formik.isValid) {
      setShowSubmitButtonLoading(true);
      let postData: any;
      if (imageKey) {
        postData = {
          ...formik.values,
          companyInfo: {
            ...formik.values.companyInfo,
            [`${type}`]: imageKey,
            guideLineName: imageName,
          },
        };
      } else {
        postData = {
          ...formik.values,
          companyInfo: {
            ...formik.values.companyInfo,
            image: imageUrl,
            guideLine: type === "guideLine" ? "" : guideLine,
            guideLineName: type === "guideLine" ? "" : imageName,
          },
          approvalProcess: formik.values.approvalProcess,
          ocr: formik.values.ocr,
          maxOcr: formik.values.maxOcr,
        };
      }
      delete postData.isBenefitsActivated;
      delete postData.isLunchActivated;
      postData.modules = [
        {
          name: "Lunch",
          isActivated: formik.values.isLunchActivated,
        },
        {
          name: "Benefits",
          isActivated: formik.values.isBenefitsActivated,
        },
        {
          name: "HRZone",
          isActivated: formik.values.isHrzoneActivated,
        },
      ];
      if (createCompany) {
        setShowModal(true);
        delete postData.companyId;
        delete postData.updatedAt;
        postData.isActive = true;
        postData.departmentAndBranches = branchAndDeps.depsAndBranches;
        postData.contactPerson.firstName = capitalize(
          postData.contactPerson.firstName
        );
        postData.contactPerson.lastName = capitalize(
          postData.contactPerson.lastName
        );

        // Creating new Company
        createNewCompany(postData).then((res: any) => {
          if (res === "OK") {
            dispatch(getCompanies());
            toast.success(
              <CustomToastContainer
                message={t("warnings.company_create_success")}
                status={1}
              />,
              {
                autoClose: 5000,
              }
            );
            formik.resetForm({ values: formik.values });
            setShowSubmitButtonLoading(false);
            // Clear input values
            setTimeout(() => {
              navigate("/");
            }, 100);
          } else {
            if (res && res.status === 422) {
              toast.error(
                <CustomToastContainer
                  message={t(`warnings.create_company_response.${res.message}`)}
                  status={3}
                />,
                {
                  autoClose: 5000,
                }
              );

              setShowSubmitButtonLoading(false);
            } else {
              toast.error(
                <CustomToastContainer
                  message={t("warnings.plans_update_error")}
                  status={3}
                />,
                {
                  autoClose: 5000,
                }
              );
              setShowSubmitButtonLoading(false);
            }
          }
          setShowModal(false);
        });
      } else {
        postData.contactPerson.firstName = capitalize(
          postData.contactPerson.firstName
        );
        postData.contactPerson.lastName = capitalize(
          postData.contactPerson.lastName
        );
        // postData.comguideLineUrl= guideLineUrl

        dispatch(updateCompany(postData));
        formik.resetForm({ values: formik.values });
      }
    } else {
      toast.warning(
        <CustomToastContainer message={t("warnings.not_allowed")} status={2} />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const deleteGuidelines = () => {
    //todo
  };

  // set less item in table for small screens
  const [mainSpaceRatio, setMainSpaceRation] = useState("60% 10% 30%");
  const [contactRatio, setContactRation] = useState("1fr 1fr 1fr");
  const [titleRatio, setTitleRatio] = useState("60% 40%");
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 915) {
      setMainSpaceRation("1fr");
    }
    if (width < 650) {
      setContactRation("1fr");
      setTitleRatio("1fr");
    }
    if (width < 1000) {
      dispatch(handleSidebar(false));
    } else {
      setMainSpaceRation("60% 10% 30%");
      setContactRation("1fr 1fr 1fr");
      setTitleRatio("60% 40%");
    }
  }, [width]);

  // State for Branches and departments values
  const [branchAndDeps, setBranchAndDeps] = useState<any>([]);

  // Show loading modal
  const [showModal, setShowModal] = useState(false);

  // Show add new branch and department for update
  const [showAddNewBranchAndDepartment, setShowAddNewBranchAndDepartment] =
    useState(false);

  const handleDepsAndBranchesSubmit = () => {
    setShowModal(true);
    branchAndDeps.companyId = getUserCompany();
    createDepsAndBranches(branchAndDeps).then((CreateRes: any) => {
      if (CreateRes === "ok") {
        toast.success(
          <CustomToastContainer
            message={t("warnings.create_branch_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      } else {
        if (CreateRes === 422) {
          toast.error(
            <CustomToastContainer
              message={t("warnings.create_branch_error")}
              status={3}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      }
      dispatch(getUsers({ limit: 1 }));
      dispatch(getCompanyData());
      dispatch(getDepartments());
      setShowAddNewBranchAndDepartment(false);
      setShowModal(false);
    });
  };

  // State for department and branches validation
  const [departmentsValidation, setDepartmentsValidation] = useState(true);
  // Validation state for submit button
  const [submitIsDisabled, setSubmitIsDisabled] = useState(true);

  useEffect(() => {
    if (createCompany) {
      // Validation for create company state
      setSubmitIsDisabled(
        !(formik.isValid && formik.dirty && !departmentsValidation)
      );
    } else {
      // Validation for general info state
      setSubmitIsDisabled(!(formik.isValid && formik.dirty));
    }
  }, [formik.isValid, departmentsValidation, formik.dirty]);

  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);

  const [inputsDisabled, setInputsDisabled] = useState(false);

  // Disable all inputs for Viewer role users
  useEffect(() => {
    setInputsDisabled(
      !isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
          permissions: {
            isAdmin: false,
            permissionsArray: [userPermissions.users],
          },
        },
      })
    );
  }, []);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const deleteModal = (
    <Modal isOpen={showDeleteModal} style={modalStyles}>
      <div
        // ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "20px",
        }}
      >
        <img
          onClick={() => setShowDeleteModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px",
          }}
          src={closeIcon}
          alt="close"
        />
        You are about to delete a company, are you really sure?
        <Button
          buttonType={"primary"}
          buttonLabel={"Delete"}
          buttonStyles={{ marginTop: "20px" }}
          buttonHandler={() => {
            deleteCompny();
            setShowDeleteModal(false);
          }}
        />
      </div>
    </Modal>
  );

  const deleteCompny = () => {
    if (company.canBeDeleted) {
      const request = async () => {
        const fullUrl = `${environment.baseUrl}${urls.companies.testCompanyDelete}/${company.id}`;
        const options = { headers: { Authorization: `Bearer ${getToken()}` } };
        const request = await axios.delete(fullUrl, options);

        if (request.status === 200) {
          setUserCompany(user.userInfo.user.companyId);
          window.location.reload();
        } else {
          toast.error(
            <CustomToastContainer
              message={t("warnings.update_company_info_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      };
      request();
    }
  };

  const handleDownload = async () => {

    const postData = async () => {
      try {
        const token = await getToken();
        const res = await axios.get(
          environment.baseUrl + urls.files.files + "/" + guideLine,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const saveFile = async () => {
          return new Promise(function (resolve, reject) {
            const fileName = guideLineName;
            const fileType = "application/pdf";
            const xhr = new XMLHttpRequest();
            const a = document.createElement("a");
            let file: any;
            xhr.open("GET", res.data, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
              file = new Blob([xhr.response], { type: fileType });
              a.href = window.URL.createObjectURL(file);
              a.download = fileName;
              a.click();
              resolve(true);
            };
            xhr.send();
          });
        };

        if (res && res.data) {
          return await saveFile();
        } else {
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
          setDownloadLoading(false);
        }
      } catch (e) {
        toast.error(
          <CustomToastContainer
            message={t("warnings.plans_download_error")}
            status={2}
          />,
          {
            autoClose: 5000,
          }
        );
        setDownloadLoading(false);
      }
    };

    if (!downloadLoading) {
      setDownloadLoading(true);
      const response = await postData();
      setDownloadLoading(false);
    }
  };

  //Initial fetch for api key if user has admin permissions
  useEffect(() => {
    {
      isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin],
        },
      }) && company?.id.length > 0
        ? getApiKey()
        : null;
    }
  }, [company?.id]);

  const [apiKey, setApiKey] = useState("");
  const generateApiKey = async () => {
    try {
      const token = await getToken();
      const res = await axios.post(
        `${environment.baseUrl}apikeys/`,
        {
          companyId: company.id,
        },
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );

      if (res.status === 201) {
        setApiKey(res.data.apiKey);
        toast.success(
          <CustomToastContainer
            message={t("warnings.create_api_key_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      } else {
        toast.error(
          <CustomToastContainer
            message={t("warnings.update_company_info_error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("warnings.update_company_info_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  const getApiKey = async () => {
    try {
      const token = await getToken();
      const res = await axios.get(`${environment.baseUrl}apikeys/`, {
        headers: { Authorization: `Bearer ${token}` },
        params: {
          limit: 1,
          skip: 0,
          sortBy: "",
          status: true,
          companyId: company.id,
        },
      });

      if (res.status === 200) {
        if (res.data.keys.length > 0) {
          setApiKey(res.data.keys[0].key);
        }
      } else {
        //toastMessage
        toast.error(
          <CustomToastContainer
            message={t("warnings.update_company_info_error")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("warnings.update_company_info_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  return !company ||
    !Object.keys(company).length ||
    !employee ||
    !dataLoaded ? (
      <GeneralInfoLoading />
    ) : (
      <ContentContainer>
        {deleteModal}
        <Modal style={modalStyles} isOpen={showWarningSupermarketPopup}>
          <GeneralInfoSupermarketWarningModal
            openPlans={supermarketPlans}
            onCancel={() => {
              setShowWarningSupermarketPopup(false);
            }}
            onSubmit={() => {
              handleFormSubmit();
              setShowWarningSupermarketPopup(false);
            }}
          />
        </Modal>
        <Modal style={modalStyles} isOpen={showModal}>
          <LoadingModal />
        </Modal>
        <BannerTitle
          mainTitle={
            createCompany
              ? t("dashboard.general.new_company_title")
              : company.companyInfo.companyName
          }
        />
        <MainSpace>
          <VerticalDivider ratio={mainSpaceRatio}>
            <form onSubmit={(e: any) => handleFormSubmit(e)}>
              {width < 915 && !createCompany ? (
                <ImageUpload
                  update={handleFormSubmit}
                  ImageUrl={imageUrl || ""}
                  setImageUrl={setImageUrl}
                />
              ) : null}
              <VerticalDivider ratio={titleRatio}>
                {createCompany ? (
                  <div className="relative">
                    <FormikInput
                      style={{ marginRight: "10px" }}
                      htmlFor="companyName"
                      name="companyInfo.companyName"
                      value={formik.values.companyInfo.companyName}
                      disabled={false}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("create_company.title_1.input_1")}
                    />
                    {formik.errors.companyInfo?.companyName &&
                    formik.touched.companyInfo?.companyName && (
                      <p className="input_warning">
                        {formik.errors.companyInfo.companyName}
                      </p>
                    )}
                  </div>
                ) : (
                  <SimpleInput
                    style={{ marginRight: "10px" }}
                    id="companyName"
                    name={t("create_company.title_1.input_1")}
                    isDisabled={!createCompany}
                    placeholder={formik.values.companyInfo.companyName}
                    handleChange={handleInputChange}
                  />
                )}
              </VerticalDivider>

              <h4>{t("dashboard.general.input_item_0")}</h4>
              <VerticalDivider ratio={contactRatio}>
                <div className="relative">
                  <FormikInput
                    style={{ marginRight: "10px" }}
                    htmlFor="firstName"
                    name="contactPerson.firstName"
                    value={formik.values.contactPerson.firstName}
                    disabled={inputsDisabled}
                    handleChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    label={t("dashboard.general.input_item_1")}
                  />
                  {formik.errors.contactPerson?.firstName &&
                  formik.touched.contactPerson?.firstName && (
                    <p className="input_warning">
                      {formik.errors.contactPerson.firstName}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <FormikInput
                    style={{ marginRight: "10px" }}
                    htmlFor="lastName"
                    name="contactPerson.lastName"
                    value={formik.values.contactPerson.lastName}
                    disabled={inputsDisabled}
                    handleChange={formik.handleChange}
                    label={t("dashboard.general.input_item_2")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.contactPerson?.lastName &&
                  formik.touched.contactPerson?.lastName && (
                    <p className="input_warning">
                      {formik.errors.contactPerson.lastName}
                    </p>
                  )}
                </div>
                {!createCompany &&
              isAllowed({
                data: {
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
              }) ? (
                    <Button
                      buttonHtmlType="button"
                      fullWidth={true}
                      buttonStyles={{ marginTop: "32px" }}
                      buttonType="tertiary btn_lg align_form"
                      buttonLabel={t("dashboard.general.button_3")}
                      buttonHandler={() => navigate(`/general-info/${getUserId()}`)}
                    />
                  ) : null}
              </VerticalDivider>
              <div
                className="space-between"
                style={{ width: "100%", gap: "20px" }}
              >
                <div className="relative" style={{ width: "100%" }}>
                  <FormikInput
                    htmlFor="email"
                    name="contactPerson.email"
                    value={formik.values.contactPerson.email}
                    disabled={inputsDisabled}
                    handleChange={formik.handleChange}
                    label={t("dashboard.general.input_item_3")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.contactPerson?.email &&
                  formik.touched.contactPerson?.email && (
                    <p className="input_warning">
                      {formik.errors.contactPerson.email}
                    </p>
                  )}
                </div>

                <div className="relative" style={{ width: "100%" }}>
                  <FormikInput
                    htmlFor="phone"
                    name="companyInfo.phone"
                    value={formik.values.companyInfo.phone}
                    disabled={inputsDisabled}
                    handleChange={(event: any) => {
                    // Remove all spaces from string and set to formik, to run correct validation
                      formik.setFieldValue(
                        "companyInfo.phone",
                        event.target.value.replace(/ /g, ""),
                        true
                      );
                    }}
                    label={t("dashboard.general.input_item_phone")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.companyInfo?.phone &&
                  formik.touched.companyInfo?.phone && (
                    <p className="input_warning">
                      {formik.errors.companyInfo.phone}
                    </p>
                  )}
                </div>
              </div>
              <div className="relative">
                <FormikInput
                  htmlFor="street"
                  name="companyInfo.streetName"
                  value={formik.values.companyInfo.streetName}
                  disabled={inputsDisabled}
                  handleChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  label={t("dashboard.general.input_item_5")}
                />
                {formik.errors.companyInfo?.streetName &&
                formik.touched.companyInfo?.streetName && (
                  <p className="input_warning">
                    {formik.errors.companyInfo?.streetName}
                  </p>
                )}
              </div>
              <VerticalDivider ratio={titleRatio}>
                <div className="relative">
                  <FormikInput
                    style={{ marginRight: "10px" }}
                    htmlFor="city"
                    name="companyInfo.city"
                    value={formik.values.companyInfo.city}
                    disabled={inputsDisabled}
                    handleChange={formik.handleChange}
                    label={t("dashboard.general.input_item_6")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.companyInfo?.city &&
                  formik.touched.companyInfo?.city && (
                    <p className="input_warning">
                      {formik.errors.companyInfo?.city}
                    </p>
                  )}
                </div>
                <div className="relative">
                  <FormikInput
                    htmlFor="zipCode"
                    name="companyInfo.zipCode"
                    value={formik.values.companyInfo.zipCode}
                    disabled={inputsDisabled}
                    handleChange={formik.handleChange}
                    label={t("dashboard.general.input_item_7")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.companyInfo?.zipCode &&
                  formik.touched.companyInfo?.zipCode && (
                    <p className="input_warning">
                      {formik.errors.companyInfo?.zipCode}
                    </p>
                  )}
                </div>
              </VerticalDivider>
              <div className="relative">
                <label className="simple_label" htmlFor="companyInfo.country">
                  {t("dashboard.general.input_item_8")}
                </label>
                <SelectWithSearch
                  isDisabled={!createCompany}
                  value={{
                    label: t(
                      `dashboard.general_info.countries_options.${formik.values.companyInfo.country.toLowerCase()}`
                    ),
                    value: formik.values.companyInfo.country,
                  }}
                  onBlur={formik.handleBlur("companyInfo.country")}
                  name={"companyInfo.country"}
                  data={countriesOptions}
                  handleSelect={(value: any) => {
                    formik.setFieldValue(
                      "companyInfo.country",
                      value.value,
                      true
                    );
                  }}
                />
                {formik.errors.companyInfo?.country &&
                formik.touched.companyInfo?.country && (
                  <p className="input_warning">
                    {formik.errors.companyInfo?.country}
                  </p>
                )}
              </div>
              <div className="relative">
                <FormikInput
                  htmlFor="website"
                  name="companyInfo.website"
                  value={formik.values.companyInfo.website}
                  disabled={inputsDisabled}
                  handleChange={formik.handleChange}
                  label={t("dashboard.general.input_item_9")}
                  onBlur={formik.handleBlur}
                />
                {formik.errors.companyInfo?.website &&
                formik.touched.companyInfo?.website && (
                  <p className="input_warning">
                    {formik.errors.companyInfo?.website}
                  </p>
                )}
              </div>

              {isAllowed({
                data: {
                  roles: [userRoles.superAdmin],
                },
              }) ||
            (isAllowed({
              data: {
                roles: [userRoles.admin],
              },
            }) &&
              apiKey.length > 0) ? (
                  <>
                    <VerticalDivider ratio={"70% 30%"}>
                      <FormikInput
                        htmlFor="apikey"
                        name="apiKey"
                        value={apiKey}
                        disabled={true}
                        handleChange={formik.handleChange}
                        label={"ApiKey"}
                        onBlur={formik.handleBlur}
                        style={{ marginRight: "10px" }}
                      />
                      {isAllowed({
                        data: {
                          roles: [userRoles.superAdmin],
                        },
                      }) ? (
                          <Button
                            buttonStyles={{ marginTop: "32px" }}
                            buttonHtmlType="button"
                            fullWidth={false}
                            buttonType="tertiary btn_lg align_form"
                            buttonLabel={"Api Key generieren"}
                            buttonHandler={async () => await generateApiKey()}
                          />
                        ) : (
                          <></>
                        )}
                    </VerticalDivider>
                  </>
                ) : (
                  <></>
                )}

              {/*Show switchers only in case if it's create company layout*/}
              {createCompany
                &&
                <CompanySettingsSwitcher
                  formik={formik}
                  createCompany={true}
                  inputsDisabled={inputsDisabled}
                />
              }

              {createCompany ? null : (
                <>
                  <VerticalDivider ratio={titleRatio}>
                    <SimpleInput
                      style={{ marginRight: "10px" }}
                      id="employeeCount"
                      name={t("dashboard.general.input_item_10")}
                      inputValue={(employee && employee.totalUser) || ""}
                      isDisabled={true}
                      handleChange={handleInputChange}
                    />
                    {isAllowed({
                      data: {
                        roles: [
                          userRoles.superAdmin,
                          userRoles.admin,
                          userRoles.custom,
                        ],
                        permissions: {
                          isAdmin: false,
                          permissionsArray: [userPermissions.users],
                        },
                      },
                    }) ? (
                        <Button
                          buttonStyles={{ marginTop: "32px" }}
                          buttonHtmlType="button"
                          fullWidth={true}
                          buttonType="tertiary btn_lg align_form"
                          buttonLabel={t("dashboard.general.button_4")}
                          buttonHandler={() => navigate("/employee")}
                        />
                      ) : null}
                  </VerticalDivider>
                  <VerticalDivider ratio={titleRatio}>
                    <SimpleInput
                      style={{ marginRight: "10px" }}
                      id="employeeCount"
                      name={t("dashboard.general.input_item_11")}
                      inputValue={"Corplife Lunch"}
                      isDisabled={true}
                      handleChange={handleInputChange}
                    />
                    {isAllowed({
                      data: {
                        roles: [
                          userRoles.superAdmin,
                          userRoles.admin,
                          userRoles.custom,
                        ],
                        permissions: {
                          isAdmin: false,
                          permissionsArray: [userPermissions.plans],
                        },
                      },
                    }) ? (
                        <Button
                          buttonHtmlType="button"
                          fullWidth={true}
                          buttonStyles={{ marginTop: "32px" }}
                          buttonType="tertiary btn_lg align_form mb-25"
                          buttonLabel={t("dashboard.general.button_5")}
                          buttonHandler={() => navigate("/plans")}
                        />
                      ) : null}
                  </VerticalDivider>
                </>
              )}

              {company &&
              Object.keys(company).length &&
              Object.keys(company.departmentAndBranches).map(
                (item: any, i: number) => (
                  <React.Fragment key={item}>
                    {!createCompany ? (
                      <GeneralDropdown
                        data={company.departmentAndBranches[item]}
                        name={item}
                        addBranchAndDeps={() =>
                          setShowAddNewBranchAndDepartment(true)
                        }
                      />
                    ) : null}
                  </React.Fragment>
                )
              )}

              {isAllowed({
                data: {
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
              }) && createCompany ? (
                  <DepAndBranchContainer
                    setDepartmentsValidation={(state: boolean) =>
                      setDepartmentsValidation(state)
                    }
                    setBranchAndDeps={setBranchAndDeps}
                    countryCode={formik.values.companyInfo.country}
                  />
                ) : null}
              {showAddNewBranchAndDepartment &&
            isAllowed({
              data: {
                roles: [userRoles.superAdmin, userRoles.admin],
              },
            }) ? (
                  <DepAndBranchContainer
                    setDepartmentsValidation={(state: boolean) =>
                      setDepartmentsValidation(state)
                    }
                    setBranchAndDeps={setBranchAndDeps}
                    update
                    submitClick={handleDepsAndBranchesSubmit}
                    closeAddDepsAndBranches={() =>
                      setShowAddNewBranchAndDepartment(false)
                    }
                    countryCode={formik.values.companyInfo.country}
                  />
                ) : null}

              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "1rem",
                  marginBottom: "1rem",
                }}
              >

                {isAllowed({
                  data: {
                    roles: [
                      userRoles.superAdmin,
                      userRoles.admin,
                      userRoles.custom,
                    ],
                    permissions: {
                      isAdmin: true,
                      permissionsArray: [userPermissions.generalInfo],
                    },
                  },
                }) ? (
                    <></>
                  ) : (
                    <></>
                  )}
              </div>
              {isAllowed({
                data: {
                  roles: [
                    userRoles.superAdmin,
                    userRoles.admin,
                    userRoles.custom,
                  ],
                  permissions: {
                    isAdmin: true,
                    permissionsArray: [userPermissions.generalInfo],
                  },
                },
              }) ? (
                  <Button
                    buttonType="primary"
                    buttonLabel={
                      createCompany
                        ? t("dashboard.general.button_1_new")
                        : t("dashboard.general.button_1")
                    }
                    buttonHandler={() =>
                      supermarketPlans > 0 && !formik.values.isSupermarketAllowed
                        ? setShowWarningSupermarketPopup(true)
                        : handleFormSubmit()
                    }
                    disabled={submitIsDisabled}
                    loading={showSubmitButtonLoading}
                  />
                ) : null}

              {isAllowed({
                data: {
                  roles: [
                    userRoles.superAdmin,
                    userRoles.admin,
                    userRoles.custom,
                  ],
                  permissions: {
                    isAdmin: true,
                    permissionsArray: [userPermissions.generalInfo],
                  },
                },
              }) &&
            company.canBeDeleted &&
            !createCompany ? (
                  <Button
                    buttonType="primary"
                    buttonLabel={t("dashboard.employee.item_menu_delete")}
                    buttonHandler={() => setShowDeleteModal(true)}
                    disabled={!company.canBeDeleted}
                    loading={showSubmitButtonLoading}
                    buttonStyles={{ marginLeft: "16px" }}
                  />
                ) : null}
            </form>
            <div />
            {width > 915 && !createCompany ? (
              <ImageUpload
                update={(e, imageKey) => handleFormSubmit(e, imageKey, "image")}
                ImageUrl={imageUrl || ""}
                setImageUrl={setImageUrl}
                companyImage={true}
              />
            ) : null}
          </VerticalDivider>
          <Spacer spacerHeight="120px" />
        </MainSpace>
      </ContentContainer>
    );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users,
    employee: state.users.users,
    company: state.company.company.company,
    companies: state.company.company.companies,
    postCompanyDataState: state.company,
  };
};

export default connect(mapStateToProps, null)(GeneralInfo);
