import React, {useEffect, useState} from "react";
import { Calendar } from "antd";
import MainSpace from "../../Components/Containers/MainSpace";
import ContentContainer from "../../Components/Containers/ContentContainer";
import moment from "moment/moment";
import {modalStyles} from "../../utils/modalStyles";
import Modal from "react-modal";
import BillingCalendarModal from "../../Components/Modals/BillingCalendarModal";
import {useLocation} from "react-router-dom";
import axios from "axios";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import {useTranslation} from "react-i18next";

export default function PlanBillingCalendar() {
  const location = useLocation();
  const { t } = useTranslation();

  const [planId, setPlanId] = useState("");
  const [userId, setUserId] = useState("");
  const [blockedDaysData, setBlockedDaysData] = useState([]);

  const getBlockedDaysData = async () => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.plans.requestAbsenceDaysGet}`;
    const options = {
      headers: {"Authorization": `Bearer ${token}`},
      params: {userId: userId, planId: planId},
    };

    await axios.get(fullUrl, options)
      .then((res: any) => {
        setBlockedDaysData(res.data);
      })
      .catch((e: any) => {
        if (!errorHandler(e.request.status)) {
          toast.error(
            <CustomToastContainer
              message={"Something went wrong"}
              status={3}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      });
  };

  useEffect(() => {
    if (location && location.pathname) {
      setPlanId(location.pathname.split("/")[3]);
      setUserId(location.pathname.split("/")[4]);
    }
  }, [location]);

  useEffect(() => {
    if (planId && userId) {
      getBlockedDaysData();
    }
  }, [planId, userId]);

  const [selectedDate, setSelectedDate] = useState<any>("");

  const getListData = (value:any) => {
    let returnValue = "";
    if (blockedDaysData && blockedDaysData.length > 0) {
      blockedDaysData.map((blockedDay:any) => {
        if (blockedDay.date === moment(value).format("DD.MM.YYYY")) {
          returnValue = blockedDay.type;
        }
      });
    }
    return returnValue;
  };

  const dateCellRender = (value:any) => {
    const data = getListData(value);
    return (
      <>
        {data && data.length > 0
          ? <div className={`plan_calendar_day_badge ${data}`}>
            <div />
            <p>{t(`dashboard.plans.billing_page.calendar.days.${data}`)}</p>
          </div>
          : null
        }
      </>
    );
  };

  const onSelect = (date:any) => {
    setSelectedDate(moment(date).toDate());
  };

  const closeModal = () => {
    getBlockedDaysData();
    setSelectedDate("");
  };

  return(
    <ContentContainer>
      <MainSpace>
        <Modal style={modalStyles} isOpen={selectedDate.toString().length > 0}>
          <BillingCalendarModal
            closeModal={() => closeModal()}
            planId={planId}
            userId={userId}
            selectedDate={selectedDate}
            blockedDaysData={blockedDaysData}
          />
        </Modal>
        <Calendar dateCellRender={dateCellRender} onSelect={onSelect} />
      </MainSpace>
    </ContentContainer>
  );
}
