// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.general_info_supermarket_warning_modal {
    width: 100%;
    max-width: 800px;
    min-width: 600px;
    position: relative;
    padding: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.general_info_supermarket_warning_modal > h2 {
    font-size: 18px;
    font-weight: bold;
    margin-top: 20px;
    margin-bottom: 20px;
}

.general_info_supermarket_warning_modal > p {
    font-size: 14px;
    text-align: center;
    margin-bottom: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/GeneralInfoSupermarketWarningModal/GeneralInfoSupermarketWarningModal.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,gBAAgB;IAChB,gBAAgB;IAChB,kBAAkB;IAClB,aAAa;IACb,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,kBAAkB;IAClB,mBAAmB;AACvB","sourcesContent":[".general_info_supermarket_warning_modal {\n    width: 100%;\n    max-width: 800px;\n    min-width: 600px;\n    position: relative;\n    padding: 30px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.general_info_supermarket_warning_modal > h2 {\n    font-size: 18px;\n    font-weight: bold;\n    margin-top: 20px;\n    margin-bottom: 20px;\n}\n\n.general_info_supermarket_warning_modal > p {\n    font-size: 14px;\n    text-align: center;\n    margin-bottom: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
