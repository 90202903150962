export const POST_MARKETING_SUCCESS = "POST_MARKETING_SUCCESS";
export const POST_MARKETING_ERROR = "POST_MARKETING_ERROR";
export const CLEAR_MARKETING_MESSAGES = "CLEAR_MARKETING_MESSAGES";

export interface postMarketingSuccess {
	type: typeof POST_MARKETING_SUCCESS
}
export interface postMarketingError {
	type: typeof POST_MARKETING_ERROR
	payload: string
}
export interface clearMarketingMessages {
	type: typeof CLEAR_MARKETING_MESSAGES
	payload: string
}


export type postMarketingDispatchTypes =
	postMarketingSuccess | postMarketingError | clearMarketingMessages
