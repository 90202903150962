import {IGetUsersWithCardsParams, IGetUsersWithCardsResponse} from "./user.interface";
import {getToken} from "../../../utils/cookies/tokensCookie";
import {getUserCompany} from "../../../utils/cookies/userCookies/userCookies";
import environment from "../../../environment";
import {urls} from "../../urls";
import axios from "axios";
import qs from "qs";
import {errorHandler} from "../../../utils/errorHandler";

export class userService {
  constructor() {}

  async getUsersWithCards(params: IGetUsersWithCardsParams): Promise<IGetUsersWithCardsResponse> {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.users.getUsersWithCards}`;
    const dynamicParams = {
      search: params.search
    };
    return await axios.get(fullUrl, {
      headers: { Authorization: `Bearer ${token}` },
      params: dynamicParams,
      paramsSerializer: (params: any) => qs.stringify(params),
    })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false,
          data: []
        };
      });
  }

}
