import React, { Component, useEffect, useState } from "react";

import "./Popup.css";
import FormikInput from "Components/SimpleComponents/FormikInput";
import VerticalDivider from "./VerticalDivider";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import Button from "../Buttons/Button";
import {modalStyles} from "../../utils/modalStyles";
import Modal from "react-modal";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import axios from "axios";
import environment from "../../environment";
import {getToken} from "../../utils/cookies/tokensCookie";
import successImage from "../../img/Dashboard/form_success.png";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {urls} from "../../api/urls";
import PhoneNumberInput from "../Elements/PhoneNumberInput/PhoneNumberInput";


interface CustomProps {
  popupVisible: any;
  userData?: any;
  companyData?: any;
  setPopupVisible: () => void;
}

interface IForm {
  firstName: string;
  lastName: string;
  companyName: string;
  email: string;
  phoneNumber: string;
  message: string;
}

function Popup({ popupVisible, setPopupVisible, userData, companyData }: CustomProps) {
  const [open, setOpen] = React.useState(false);
  const [successState, setSuccessState] = useState(false);
  const handleClose = () => {
    setPopupVisible();
    setOpen(false);
  };
  const { t } = useTranslation();
  const [contactRatio, setContactRation] = useState("1fr 1fr");

  useEffect(() => {
    setOpen(popupVisible);
  }, []);

  // Set user and company data to inputs
  useEffect(() => {
    if (userData && Object.keys(userData).length) {
      formik.setFieldValue("firstName", userData.firstName, true);
      formik.setFieldValue("lastName", userData.lastName, true);
      formik.setFieldValue("email", userData.email, true);
    }
    if (companyData && companyData.company && companyData.company.companyInfo) {
      formik.setFieldValue("companyName", companyData.company.companyInfo.companyName, true);
    }
  }, [userData, companyData]);


  const formik = useFormik<IForm>({
    initialValues: {
      firstName: "",
      lastName: "",
      companyName: "",
      email: "",
      phoneNumber: "",
      message: ""
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required(
        t("formik_warnings.general_info.firstName")
      ),
      lastName: Yup.string().required(
        t("formik_warnings.general_info.lastName")
      ),
      companyName: Yup.string().required(
        t("dashboard.home.contact_modal.errors.company_name")
      ),
      email: Yup.string()
        .required(t("formik_warnings.general_info.email"))
        .email(t("formik_warnings.general_info.emailValid")),
    }),
    // handle form submitting
    onSubmit: async () => {
      await axios.post(
        `${environment.baseUrl}${urls.marketing.benefitsContact}`,
        {...formik.values, companyId: companyData.company.id},
        {
          headers: {Authorization: `Bearer ${getToken()}`},
        }
      ).then((res:any) => {
        setSuccessState(true);
        setTimeout(() => {
          handleClose();
        }, 5000);
      }).catch((e:any) => {
        toast.error(
          <CustomToastContainer
            message={t("dashboard.home.contact_modal.warnings.exists")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      });
    },
  });

  return (
    <div>
      <Modal
        style={modalStyles}
        isOpen={open}
      >
        <div className="modal_container" style={{maxWidth: "500px"}}>
          {!successState
            ? <>
              <img
                onClick={() => handleClose()}
                className="close_popup"
                src={closeIcon} alt="close"/>
              <div className="row">
                <h4 style={{paddingBottom: "20px", marginTop: "30px" }}>
                  {t("dashboard.home.contact_modal.title")}
                </h4>
              </div>
              <form onSubmit={(e: any) => formik.handleSubmit(e)}>
                {/* FIRST AND LAST NAME ROW */}
                <VerticalDivider ratio={contactRatio}>
                  <div className="relative">
                    <FormikInput
                      style={{ marginRight: "10px" }}
                      htmlFor="firstName"
                      name="firstName"
                      value={formik.values.firstName}
                      disabled={false}
                      handleChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      label={t("dashboard.general.input_item_1")}
                    />
                    {formik.errors.firstName &&
                    formik.touched.firstName && (
                      <p className="input_warning">
                        {formik.errors.firstName}
                      </p>
                    )}
                  </div>
                  <div className="relative">
                    <FormikInput
                      style={{ marginLeft: "10px" }}
                      htmlFor="lastName"
                      name="lastName"
                      value={formik.values.lastName}
                      disabled={false}
                      handleChange={formik.handleChange}
                      label={t("dashboard.general.input_item_2")}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.lastName &&
                    formik.touched.lastName && (
                      <p className="input_warning">
                        {formik.errors.lastName}
                      </p>
                    )}
                  </div>
                </VerticalDivider>

                {/* Company Name */}
                <div className="relative">
                  <FormikInput
                    htmlFor="companyName"
                    name="companyName"
                    value={formik.values.companyName}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.home.contact_modal.labels.company_name")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.companyName &&
                  formik.touched.companyName && (
                    <p className="input_warning">
                      {formik.errors.companyName}
                    </p>
                  )}
                </div>

                {/* Email */}
                <div className="relative">
                  <FormikInput
                    htmlFor="email"
                    name="email"
                    value={formik.values.email}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.general.input_item_3")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email &&
                  formik.touched.email && (
                    <p className="input_warning">
                      {formik.errors.email}
                    </p>
                  )}
                </div>

                {/* Phone number */}
                <div className="relative">
                  <PhoneNumberInput
                    label={t("dashboard.employee.employee_modal_label_7")}
                    name={"phoneNumber"}
                    handleChange={(value: string) => {
                      formik.setFieldValue("phoneNumber", value.replace(/ /g,""), true);
                    }}
                    value={formik.values.phoneNumber}
                    onBlur={formik.handleBlur}
                    error={formik.touched.phoneNumber && formik.errors.phoneNumber ? formik.errors.phoneNumber : undefined}
                  />
                </div>

                {/* Message */}
                <div className="relative">
                  <FormikInput
                    htmlFor="message"
                    name="message"
                    value={formik.values.message}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.home.contact_modal.labels.text_field")}
                    onBlur={formik.handleBlur}
                    type={"textarea"}
                    placeholder={t("dashboard.home.contact_modal.placeholders.text_field")}
                  />
                  {formik.errors.message &&
                  formik.touched.message && (
                    <p className="input_warning">
                      {formik.errors.message}
                    </p>
                  )}
                </div>

                <div className="center" style={{marginTop: "20px"}}>
                  <Button
                    buttonType="primary"
                    submitButton={true}
                    buttonLabel={t("dashboard.home.contact_modal.btn")}
                    buttonHandler={() => {
                      //
                    }}
                    disabled={!(formik.isValid && formik.dirty)}
                  />
                </div>
              </form>
            </>
            : <div className="dashboard_form_success_container">
              <img
                onClick={() => handleClose()}
                className="close_popup"
                src={closeIcon} alt="close"/>
              <h4>{t("dashboard.home.contact_modal.success.title")}</h4>
              <img
                src={successImage}
                className="dashboard_form_success_img"
                alt="success plane"
              />
              <p>{t("dashboard.home.contact_modal.success.text")}</p>
            </div>
          }
        </div>
      </Modal>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: state.users.userInfo.user,
    companyData: state.company.company
  };
};

export default connect(mapStateToProps, null)(Popup);
