// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_overview_header_container_header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.payment_overview_header_container_tab_content_container {
    padding-top: 20px;
    padding-bottom: 20px;
}

.payment_overview_header_container_tab_content_container_title {
    font-size: 25px;
    font-weight: bold;
    color: #3D3D8E;
}

.payment_overview_header_container_tab_content_container_sub_title {
    font-size: 18px;
    font-weight: normal;
    color: #0D0A3D;
}

.payment_overview_header_container_tab_content_container_cancel_btn {
    padding-left: 30px;
    padding-right: 30px;
    font-size: 18px;
    color: #0D0A3D;
    cursor: pointer;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/PaymentsOverviewHeaderContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;AACvB;;AAEA;IACI,iBAAiB;IACjB,oBAAoB;AACxB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,eAAe;IACf,cAAc;IACd,eAAe;AACnB","sourcesContent":[".payment_overview_header_container_header {\n    width: 100%;\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n}\n\n.payment_overview_header_container_tab_content_container {\n    padding-top: 20px;\n    padding-bottom: 20px;\n}\n\n.payment_overview_header_container_tab_content_container_title {\n    font-size: 25px;\n    font-weight: bold;\n    color: #3D3D8E;\n}\n\n.payment_overview_header_container_tab_content_container_sub_title {\n    font-size: 18px;\n    font-weight: normal;\n    color: #0D0A3D;\n}\n\n.payment_overview_header_container_tab_content_container_cancel_btn {\n    padding-left: 30px;\n    padding-right: 30px;\n    font-size: 18px;\n    color: #0D0A3D;\n    cursor: pointer;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
