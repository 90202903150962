// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.radio_text {
    font-size: 12px;
}

.input {
    margin-top: 5px;
    border: none;
    border-radius: 5px;
    height: 30px;
    background-color: #F0F0F0;
    padding-left: 10px;
}

.input.div {
    display: flex;
    align-items: center;
    font-size: 12px;
    padding-right: 10px;
}

.input.div.selected {
    border: 1px solid black;
}


.input.error {
    border: 1px solid darkred;
}

.input_error {
    position: absolute;
    bottom: -25px;
    left: 0;
    font-size: 10px;
    color: #ED4337;
}

.input_error.main {
    position: absolute;
    bottom: -5px;
    left: 83%;
    font-size: 10px;
    color: #ED4337;
}

.radio_group {
    display: flex;
    flex-direction: column;
    max-width: 740px;
}

.radio_container:not(:first-child) {
    margin-top: 15px;
}

.radio_description {
    padding-left: 35px;
    margin-top: 5px;
    font-size: 12px;
    margin-bottom: 0;
    color: #727070;
}

.feature_description {
    margin-top: 5px;
    font-size: 12px;
    color: #727070;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Widgets/Checklist/Components/RadioGroup/Inputs.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;IACnB,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,uBAAuB;AAC3B;;;AAGA;IACI,yBAAyB;AAC7B;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,OAAO;IACP,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,SAAS;IACT,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,eAAe;IACf,cAAc;AAClB","sourcesContent":[".radio_text {\n    font-size: 12px;\n}\n\n.input {\n    margin-top: 5px;\n    border: none;\n    border-radius: 5px;\n    height: 30px;\n    background-color: #F0F0F0;\n    padding-left: 10px;\n}\n\n.input.div {\n    display: flex;\n    align-items: center;\n    font-size: 12px;\n    padding-right: 10px;\n}\n\n.input.div.selected {\n    border: 1px solid black;\n}\n\n\n.input.error {\n    border: 1px solid darkred;\n}\n\n.input_error {\n    position: absolute;\n    bottom: -25px;\n    left: 0;\n    font-size: 10px;\n    color: #ED4337;\n}\n\n.input_error.main {\n    position: absolute;\n    bottom: -5px;\n    left: 83%;\n    font-size: 10px;\n    color: #ED4337;\n}\n\n.radio_group {\n    display: flex;\n    flex-direction: column;\n    max-width: 740px;\n}\n\n.radio_container:not(:first-child) {\n    margin-top: 15px;\n}\n\n.radio_description {\n    padding-left: 35px;\n    margin-top: 5px;\n    font-size: 12px;\n    margin-bottom: 0;\n    color: #727070;\n}\n\n.feature_description {\n    margin-top: 5px;\n    font-size: 12px;\n    color: #727070;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
