import { Dispatch } from "redux";
import {
  BRANCH_AND_DEPS_ERROR,
  branchAndDepsDispatchTypes,
  GET_BRANCHES_SUCCESS,
  GET_DEPARTMENTS_AND_BRANCHES_SUCCESS,
  GET_DEPARTMENTS_SUCCESS
} from "../types/branchAndDepartmentTypes";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import { urls } from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

// Get departments
export const getDepartments =
  () => async (dispatch: Dispatch<branchAndDepsDispatchTypes>) => {
    const dynamicParams = {
      limit: 0,
      skip: 0
    };
    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.branchAndDepartments.departmentsByCompanyId}${companyId}`,
      dynamicParams,
      successDispatchType: GET_DEPARTMENTS_SUCCESS,
      errorDispatchType: BRANCH_AND_DEPS_ERROR,
      errorPayload: "get_departments"
    }).get(dispatch);
  };

// Get branches
export const getBranches =
  () => async (dispatch: Dispatch<branchAndDepsDispatchTypes>) => {
    const dynamicParams = {
      limit: 0,
      skip: 0
    };
    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.branchAndDepartments.branchesByCompanyId}${companyId}`,
      dynamicParams,
      successDispatchType: GET_BRANCHES_SUCCESS,
      errorDispatchType: BRANCH_AND_DEPS_ERROR,
      errorPayload: "get_branch"
    }).get(dispatch);
  };

// Get departments and branches
export const getDepartmentsAndBranches =
  () => async (dispatch: Dispatch<branchAndDepsDispatchTypes>) => {
    const dynamicParams = {
      limit: 0,
      skip: 0
    };
    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.branchAndDepartments.departmentAndBranchesByCompanyId}${companyId}`,
      dynamicParams,
      successDispatchType: GET_DEPARTMENTS_AND_BRANCHES_SUCCESS,
      errorDispatchType: BRANCH_AND_DEPS_ERROR,
      errorPayload: "get_departments_and_branches"
    }).get(dispatch);
  };
