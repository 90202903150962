import React, {useEffect, useState} from "react";
import {prepareImage} from "../../redux/actions/prepareImage";

interface CustomProps {
  imageUrl: string,
  small?: boolean,
  customSize?: any
}

export default function UserAvatar ({imageUrl, small, customSize}:CustomProps) {
  const [currentImage, setCurrentImage] = useState("");

  const customizedStyles = {...customSize, backgroundImage: `url(${currentImage})`};

  useEffect(() => {
    async function fetchData() {
      if (imageUrl !== "undefined") {
        try {
          const response = await prepareImage(imageUrl);
          setCurrentImage(response);
        } catch (e: any) {
          setCurrentImage("/images/user_avatar.svg");
        }
      } else {
        setCurrentImage("/images/user_avatar.svg");
      }
    }
    fetchData();
  }, [imageUrl]);

  return(
    <div
      className={small ? "update_info_img small" : "update_info_img"}
      style={ customSize ? customizedStyles : {backgroundImage: `url(${currentImage})`}}
    />
  );
}
