/*Post new branches and departments*/
import axios from "axios";

import { getToken } from "../../utils/cookies/tokensCookie";
import { errorHandler } from "../../utils/errorHandler";
import {urls} from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

const takeLiveUrl = () => {
  if (process.env.REACT_APP_ENV === "development") {
    return "https://api-dev.corplife.at";
  } else if (process.env.REACT_APP_ENV === "staging") {
    return "https://api-stag.corplife.at";
  } else if (process.env.REACT_APP_ENV === "production") {
    return "https://api.corplife.at";
  } else {
    return "localhost:8000";
  }
};

export async function readNotifications(data: any) {
  const token = getToken();
  const fullUrl = `${takeLiveUrl()}/v0/${urls.notification.markAsRead}`;

  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    await axios.put(
      fullUrl,
      {
        notificationIds: data,
      },
      options
    );
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      console.error(e);
    }
  }
}

export async function readAllNotifications() {
  const token = getToken();
  const companyId = getUserCompany() || "";
  const fullUrl = `${takeLiveUrl()}/v0/${urls.notification.markAllAsRead}`;
  const options = { headers: { Authorization: `Bearer ${token}` } };
  try {
    await axios.put(
      fullUrl,
      {
        companyId: companyId,
      },
      options
    );
  } catch (e: any) {
    if (!errorHandler(e.request.status)) {
      console.error(e);
    }
  }
}
