export const companyTypes = [
  {
    value: "SOLE_TRADER",
    label: "dashboard.card_onboarding.constants.companyType.SOLE_TRADER"
  },
  {
    value: "LLC",
    label: "dashboard.card_onboarding.constants.companyType.LLC"
  },
  {
    value: "PUBLIC_LIMITED_COMPANY",
    label: "dashboard.card_onboarding.constants.companyType.PUBLIC_LIMITED_COMPANY"
  },
  {
    value: "LIMITED_LIABILITY_PARTNERSHIP",
    label: "dashboard.card_onboarding.constants.companyType.LIMITED_LIABILITY_PARTNERSHIP"
  },
  {
    value: "NON_PROFIT_ORGANISATION",
    label: "dashboard.card_onboarding.constants.companyType.NON_PROFIT_ORGANISATION"
  }
];

export const industryTypes = [
  {
    value: "ACCOUNTING",
    label: "dashboard.card_onboarding.constants.industryType.ACCOUNTING"
  },
  {
    value: "AUDIT",
    label: "dashboard.card_onboarding.constants.industryType.AUDIT"
  },
  {
    value: "FINANCE",
    label: "dashboard.card_onboarding.constants.industryType.FINANCE"
  },
  {
    value: "PUBLIC_SECTOR_ADMINISTRATION",
    label: "dashboard.card_onboarding.constants.industryType.PUBLIC_SECTOR_ADMINISTRATION"
  },
  {
    value: "ART_ENTERTAINMENT",
    label: "dashboard.card_onboarding.constants.industryType.ART_ENTERTAINMENT"
  },
  {
    value: "AUTO_AVIATION",
    label: "dashboard.card_onboarding.constants.industryType.AUTO_AVIATION"
  },
  {
    value: "BANKING_LENDING",
    label: "dashboard.card_onboarding.constants.industryType.BANKING_LENDING"
  },
  {
    value: "BUSINESS_CONSULTANCY_LEGAL",
    label: "dashboard.card_onboarding.constants.industryType.BUSINESS_CONSULTANCY_LEGAL"
  },
  {
    value: "CONSTRUCTION_REPAIR",
    label: "dashboard.card_onboarding.constants.industryType.CONSTRUCTION_REPAIR"
  },
  {
    value: "EDUCATION_PROFESSIONAL_SERVICES",
    label: "dashboard.card_onboarding.constants.industryType.EDUCATION_PROFESSIONAL_SERVICES"
  },
  {
    value: "INFORMATIONAL_TECHNOLOGIES",
    label: "dashboard.card_onboarding.constants.industryType.INFORMATIONAL_TECHNOLOGIES"
  },
  {
    value: "TOBACCO_ALCOHOL",
    label: "dashboard.card_onboarding.constants.industryType.TOBACCO_ALCOHOL"
  },
  {
    value: "GAMING_GAMBLING",
    label: "dashboard.card_onboarding.constants.industryType.GAMING_GAMBLING"
  },
  {
    value: "MEDICAL_SERVICES",
    label: "dashboard.card_onboarding.constants.industryType.MEDICAL_SERVICES"
  },
  {
    value: "MANUFACTURING",
    label: "dashboard.card_onboarding.constants.industryType.MANUFACTURING"
  },
  {
    value: "PR_MARKETING",
    label: "dashboard.card_onboarding.constants.industryType.PR_MARKETING"
  },
  {
    value: "PRECIOUS_GOODS_JEWELRY",
    label: "dashboard.card_onboarding.constants.industryType.PRECIOUS_GOODS_JEWELRY"
  },
  {
    value: "NON_GOVERNMENTAL_ORGANIZATION",
    label: "dashboard.card_onboarding.constants.industryType.NON_GOVERNMENTAL_ORGANIZATION"
  },
  {
    value: "INSURANCE_SECURITY",
    label: "dashboard.card_onboarding.constants.industryType.INSURANCE_SECURITY"
  },
  {
    value: "RETAIL_WHOLESALE",
    label: "dashboard.card_onboarding.constants.industryType.RETAIL_WHOLESALE"
  },
  {
    value: "TRAVEL_TOURISM",
    label: "dashboard.card_onboarding.constants.industryType.TRAVEL_TOURISM"
  },
  {
    value: "FREELANCER",
    label: "dashboard.card_onboarding.constants.industryType.FREELANCER"
  }
];

export const sourceOfFoundsTypes = [
  {
    value: "LABOUR_CONTRACT",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.LABOUR_CONTRACT"
  },
  {
    value: "CIVIL_CONTRACT",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.CIVIL_CONTRACT"
  },
  {
    value: "RENT",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.RENT"
  },
  {
    value: "FUNDS_FROM_OTHER_AUXILIARY_SOURCES",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.FUNDS_FROM_OTHER_AUXILIARY_SOURCES"
  },
  {
    value: "SALE_OF_MOVABLE_ASSETS",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.SALE_OF_MOVABLE_ASSETS"
  },
  {
    value: "SALE_OF_REAL_ESTATE",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.SALE_OF_REAL_ESTATE"
  },
  {
    value: "ORDINARY_BUSINESS_ACTIVITY",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.ORDINARY_BUSINESS_ACTIVITY"
  },
  {
    value: "DIVIDENDS",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.DIVIDENDS"
  },
  {
    value: "LOAN_FROM_FINANCIAL_INSTITUTIONS_CREDIT_UNIONS",
    // eslint-disable-next-line max-len
    label: "dashboard.card_onboarding.constants.sourceOfFounds.LOAN_FROM_FINANCIAL_INSTITUTIONS_CREDIT_UNIONS"
  },
  {
    value: "LOAN_FROM_THIRD_PARTIES",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.LOAN_FROM_THIRD_PARTIES"
  },
  {
    value: "SALE_OF_COMPANY_SHARES_BUSINESS",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.SALE_OF_COMPANY_SHARES_BUSINESS"
  },
  {
    value: "OTHER",
    label: "dashboard.card_onboarding.constants.sourceOfFounds.OTHER"
  }
];

export const countriesAbbreviations = [
  {
    value: "AT",
    label: "dashboard.card_onboarding.constants.countriesAbbreviations.AT"
  },
  {
    value: "DE",
    label: "dashboard.card_onboarding.constants.countriesAbbreviations.DE"
  },
  {
    value: "CH",
    label: "dashboard.card_onboarding.constants.countriesAbbreviations.CH"
  }
];

export const phoneCodes = [
  {
    value: "43",
    label: "+43"
  },
  {
    value: "49",
    label: "+49"
  },
  {
    value: "41",
    label: "+41"
  }
];
