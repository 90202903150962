import {
  CLEAR_PLAN_DATA,
  DEACTIVATE_PLAN_SUCCESS, GET_BILLING_PLANS_SUCCESS,
  GET_ONE_PLAN_SUCCESS, GET_PLAN_DATA_SUCCESS,
  GET_PLANS_SUCCESS,
  PLAN_CLEAR_MESSAGES,
  PLAN_ERROR, PLANS_LOADING,
  plansDispatchTypes,
  POST_PLANS_SUCCESS,
  UPDATE_PLAN_SUCCESS
} from "../types/plansTypes";

interface DefaultStateI {
  error?: string,
  success?: string,
  loading?: boolean,
  plan?: any,
  plans?: any,
  planData?: any,
  billingPlans?: any
}

const initialState:DefaultStateI = {
  error: "",
  success: "",
  loading: true,
  plan: {},
  plans: [],
  planData: {},
  billingPlans: {}
};

export const plansReducer = (
  state: DefaultStateI = initialState,
  action: plansDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case DEACTIVATE_PLAN_SUCCESS:
    return {
      ...state,
      success: action.payload,
      loading: false
    };
  case GET_PLAN_DATA_SUCCESS:
    return {
      ...state,
      planData: action.payload,
      loading: false
    };
  case GET_ONE_PLAN_SUCCESS:
    return {
      ...state,
      plan: action.payload,
      loading: false
    };
  case GET_PLANS_SUCCESS:
    return {
      ...state,
      plans: action.payload,
      loading: false
    };
  case GET_BILLING_PLANS_SUCCESS:
    return {
      ...state,
      billingPlans: action.payload,
      loading: false
    };
  case POST_PLANS_SUCCESS:
    return {
      ...state,
      success: action.payload,
      loading: false
    };
  case UPDATE_PLAN_SUCCESS:
    return {
      ...state,
      success: action.payload,
      loading: false
    };
  case PLANS_LOADING:
    return {
      ...state,
      loading: true
    };
  case PLAN_ERROR:
    return {
      ...state,
      error: action.payload,
      loading: false
    };
  case PLAN_CLEAR_MESSAGES:
    return {
      ...state,
      success: "",
      error: ""
    };
  case CLEAR_PLAN_DATA:
    return {
      ...state,
      planData: {},
      loading: false
    };
  default:
    return state;
  }
};
