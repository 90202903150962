// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.budget_moving_modal_container {
    padding-top: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.budget_moving_modal_container > h3 {
    font-size: 20px;
    font-weight: 600;
}

.budget_moving_modal_container > p {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 12px;
}

.budget_moving_modal_container > div {
    margin-top: 30px;
    display: flex;
    align-items: center;
    gap: 30px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/PlansPage/PlanBudgetMovingModal/PlanBudgetMovingModal.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,aAAa;IACb,mBAAmB;IACnB,SAAS;AACb","sourcesContent":[".budget_moving_modal_container {\n    padding-top: 30px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.budget_moving_modal_container > h3 {\n    font-size: 20px;\n    font-weight: 600;\n}\n\n.budget_moving_modal_container > p {\n    font-size: 14px;\n    font-weight: 500;\n    margin-bottom: 12px;\n}\n\n.budget_moving_modal_container > div {\n    margin-top: 30px;\n    display: flex;\n    align-items: center;\n    gap: 30px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
