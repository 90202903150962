import React, {useState} from "react";
import "./OrdersFilteringBlock.css";
import filterShape from "../../img/Dashboard/Orders/shape.svg";

interface IProps {
  title: string;
  children: JSX.Element
}

export default function FilteringBlock(props:IProps) {

  const [openDropdown, setOpenDropdown] = useState(false);

  return(
    <div>
      <div className="filtering_block">
        <div className="space-between_drop filtering_container" onClick={() => setOpenDropdown(!openDropdown)}>
          <div className="filtering_title row">
            <img src={filterShape} alt="filtering" />
            <p className="filtering_text">
              {props.title}
            </p>
          </div>
          <div className="row">
            <div className="general_drop_plus">
              <div className="general_line_one" />
              <div
                className={
                  openDropdown ? "general_line_two_clicked" : "general_line_two"
                }
              />
            </div>
          </div>
        </div>

        <div
          className="space_between_filter"
          style={ openDropdown ? { paddingBottom: "0.6rem", display: "grid"} : {display: "none"} }
        >
          {props.children}
        </div>
		
      </div>
    </div>
  );
}
