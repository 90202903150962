import React, { useEffect, useState } from "react";
import { useDispatch, connect } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "redux/reducers/rootReducer";
import { loginUser } from "../../../redux/actions/authActions";
import queryString from "query-string";

function MagentoLogin(props: any) {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const parsedSearch: any = queryString.parse(location.search) || "";

  const [fetched, setFetched] = useState(false);

  const getNavigationPath = () => {
    if (parsedSearch && parsedSearch.navigation && parsedSearch.navigation.length > 0) {
      switch (parsedSearch.navigation) {
      case "hr":
        return "hrzone/dashboard";
      case "pp":
        return "partner-portal/profile";
      default:
        return "";
      }
    } else {
      return "";
    }
  };

  useEffect(() => {
    if (location.search.length > 0 && !fetched) {
      const options = {
        email: parsedSearch.email,
        token: `Bearer ${parsedSearch.token}`,
      };
      dispatch(loginUser(options));
      setFetched(true);
    }
    // Set timeout to let all login data to be saved
    setTimeout(() => {
      navigate(`/${getNavigationPath()}`);
    }, 300);
  }, []);

  return <></>;
}

const mapStateToProps = (state: RootState) => {
  return {
    login: state.auth,
    token: state.auth.userToken,
    expiredJWT: state.notificationData.error,
    user: state.users.userInfo.user,
  };
};

export default connect(mapStateToProps)(MagentoLogin);
