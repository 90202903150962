import {CHANGE_SIDEBAR_TAB, HANDLE_SIDEBAR} from "../types/sidebarTypes";

export function handleSidebar(sidebarOpen:boolean) {
  return {
    type: HANDLE_SIDEBAR,
    payload: sidebarOpen
  };
}
export function changeSidebarTab(sidebarTab:string) {
  return {
    type: CHANGE_SIDEBAR_TAB,
    payload: sidebarTab
  };
}
