import React, { useEffect, useState } from "react";
import {Route, Routes, Navigate, useNavigate} from "react-router-dom";
import OnboardingPage from "./page";
import { changeSidebarTab } from "../../redux/actions/sidebarActions";
import { connect, useDispatch } from "react-redux";
import axios from "axios";
import environment from "../../environment";
import { getToken, removeToken } from "../../utils/cookies/tokensCookie";
import "./payments.css";
import PaymentOverview from "./overview";
import OverviewDetail from "./overviewDetail";
import CreateCard from "./createCard";
import { clearPaymentsMessages } from "../../redux/actions/paymentsActions";
import { getWeavrCorporate } from "../../redux/actions/weavrActions";
import VerifyWeavrPage from "./verifyWeavrPage";
import { urls } from "../../api/urls";
import {VirtualCardOnboardingStep, weavrStatusEnum} from "../../enums/weavrEnums";
import { RootState } from "../../redux/reducers/rootReducer";
import PaymentsChangePassword from "./ChangePasssword";
import DashboardLoading from "../../Components/LoadingPlaceholders/DashboardLoading";
import WeavrEmailVerification from "./Onboarding/WeavrEmailVerification";
import PaymentAuthenticationPopup from "../../Components/Payments/PaymentAuthenticationPopup/PaymentAuthenticationPopup";

interface IProps {
  payments?: any;
  weavr?: any;
  company?: any;
  user?: any;
}

function OnboardingRouter({ payments, weavr, company, user }: IProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [weavrStep, setWeavrStep] = useState<VirtualCardOnboardingStep>(VirtualCardOnboardingStep.INITIAL);

  useEffect(() => {
    dispatch(getWeavrCorporate());
  }, []);

  const [loading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [userEmail, setUserEmail] = useState("");

  // Detect if user logged in to weavr
  const checkTokenExists = async () => {
    const weavrToken = await getToken(true);
    if (
      !weavrToken
      && weavr
      && Object.keys(weavr).length > 0
      && weavrStep !== VirtualCardOnboardingStep.INITIAL
      && weavrStep !== VirtualCardOnboardingStep.INFO_FORM
      && weavrStep !== VirtualCardOnboardingStep.PASSWORD_CREATION
      && weavrStep !== VirtualCardOnboardingStep.EMAIL_VERIFICATION
    ) {
      setShowModal(true);
    }
  };

  useEffect(() => {
    checkTokenExists();
  }, [weavr]);

  useEffect(() => {
    if (weavr && weavr.rootUser && weavr.rootUser.email) {
      setUserEmail(weavr.rootUser.email);
    }
  }, [weavr]);

  // Get weavr corporate information
  const getWeavrInfo = async () => {
    const token = await getToken();
    try {
      // eslint-disable-next-line max-len
      const response = await axios.get(
        `${environment.baseUrl}${urls.payments.getCorporateByCompanyId}`,
        {
          headers: { Authorization: `Bearer ${token}` }
        }
      );
      if (response.data) {
        if (!response.data.changelog.length) {
          setLoading(false);
        }
        setWeavrStep(response.data.currentStep);
        setLoading(false);
      }
    } catch (e) {
      setLoading(false);
    }
  };

  useEffect(() => {
    dispatch(changeSidebarTab("payments"));
    getWeavrInfo();
  }, []);

  // Logout if there is any error in weavr
  useEffect(() => {
    if (payments && payments.error && payments.error.length) {
      dispatch(clearPaymentsMessages());
      removeToken(true);
      setShowModal(true);
    }
  }, [payments]);

  return(
    <div>
      {
        company
        && company.company
        && company.company.company
        && (company.company.company.virtualCard || company.company.company?.companyInfo?.companyName === "Corplife GmbH")
          ? loading
            ? <DashboardLoading />
            : weavrStep === VirtualCardOnboardingStep.COMPLETED
              ? <>
                <PaymentAuthenticationPopup
                  showModal={showModal}
                  onModalChange={(state: boolean) => setShowModal(state)}
                  email={userEmail}
                  user={user.userInfo.user}
                  onLoginSuccess={() => {
                    setTimeout(() => {
                      window.location.reload();
                    }, 50);
                  }}
                  onCloseModal={() => {
                    setShowModal(false);
                    navigate("/");
                  }}
                />
                <Routes>
                  <Route path="/" element={<Navigate to="/payments/overview" />} />
                  <Route path="/overview" element={<PaymentOverview showLogin={() => setShowModal(true)} />}/>
                  <Route path="/overview/changePassword" element={<PaymentsChangePassword />}/>
                  <Route path="/overview/create" element={<CreateCard />}/>
                  <Route
                    path="/overview/:id"
                    element={<OverviewDetail />}/>
                </Routes>
              </>
              : <Routes>
                <Route path="/" element={<OnboardingPage />}/>
                <Route path="/verify" element={<VerifyWeavrPage />}/>
                <Route path="/emailVerification" element={<WeavrEmailVerification />}/>
              </Routes>
          : null
      }
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    payments: state.payments,
    weavr: state.weavr.weavrCorporate,
    company: state.company,
    user: state.users
  };
};

export default connect(mapStateToProps, null)(OnboardingRouter);
