// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.empty_table_container {
    width: 100%;
    height: 600px;
    background-color: white;
}
.empty_table_box > h2 {
    text-align: center;
    padding-top: 1rem;
    padding-bottom: 1rem;
}
.empty_table_box > p {
    text-align: center;
    max-width: 400px;
    line-height: 1.5rem;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/TableEmptyState.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;AAC3B;AACA;IACI,kBAAkB;IAClB,iBAAiB;IACjB,oBAAoB;AACxB;AACA;IACI,kBAAkB;IAClB,gBAAgB;IAChB,mBAAmB;AACvB","sourcesContent":[".empty_table_container {\n    width: 100%;\n    height: 600px;\n    background-color: white;\n}\n.empty_table_box > h2 {\n    text-align: center;\n    padding-top: 1rem;\n    padding-bottom: 1rem;\n}\n.empty_table_box > p {\n    text-align: center;\n    max-width: 400px;\n    line-height: 1.5rem;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
