import React, { useEffect, useState } from "react";
import { DateRange } from "react-date-range";
import moment from "moment";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect } from "react-redux";
import de from "date-fns/locale/de";
import { useTranslation } from "react-i18next";

import "./CalendarContainer.css";
import arrowIcon from "img/Dashboard/PlansAndModules/ic-chevron-down.svg";
import { enumerateDaysBetweenDates } from "../../utils/enumerateDaysBetweenDates";

interface CustomProps {
  plans?: any;
  submitDates: (dates: any) => void;
  plansArray: any;
  updatePlanData: any;
  isCopyPlan?: boolean;
}

function CalendarContainer({
  submitDates,
  plansArray,
  updatePlanData,
  isCopyPlan
}: CustomProps) {
  const { t } = useTranslation();
  const [showDateRanger, setShowDateRanger] = useState(false);

  const [state, setState] = useState<any>([
    {
      startDate: new Date(),
      endDate: new Date(),
      key: "selection"
    }
  ]);
  const [formattedDate, setFormattedDate] = useState([
    {
      startDate: "--",
      endDate: "--",
      key: "selection"
    }
  ]);

  useEffect(() => {
    if (updatePlanData && Object.keys(updatePlanData).length && !isCopyPlan) {
      // && !isCopyPlan
      setState([
        {
          startDate: new Date(updatePlanData.plan.startDate),
          endDate: new Date(updatePlanData.plan.endDate),
          key: "selection"
        }
      ]);
      const newDate = [
        {
          startDate: moment(updatePlanData.plan.startDate).format("DD.MM.YYYY"),
          endDate: moment(updatePlanData.plan.endDate).format("DD.MM.YYYY"),
          key: "selection"
        }
      ];
      setFormattedDate(newDate);
    }
  }, [updatePlanData]);

  // Format data and save for local input and paste for parent component
  const datePickerHandler = (item: any) => {
    setState(item);
    const newDate = [
      {
        startDate: moment(item[0].startDate)
          .format("DD/MM/YYYY")
          .replaceAll("/", "."),
        endDate: moment(item[0].endDate)
          .format("DD/MM/YYYY")
          .replaceAll("/", "."),
        key: "selection"
      }
    ];
    setFormattedDate(newDate);
    submitDates(newDate);
  };

  return (
    <React.Fragment>
      <div
        style={{ marginTop: "10px", alignItems: "center" }}
        className="flex-end"
      >
        <p className="rem-08">{t("dashboard.plans.date_text")}</p>
        <div
          onClick={() => setShowDateRanger(!showDateRanger)}
          className="lunch_datepicker"
        >
          <p>
            {formattedDate[0].startDate +
              " " +
              "-" +
              " " +
              formattedDate[0].endDate}
          </p>
          <img src={arrowIcon} alt="arrowDown" />
        </div>
      </div>

      {showDateRanger && (
        <div className="custom_calendar flex-end">
          <DateRange
            editableDateInputs={true}
            onChange={(item: any) => datePickerHandler([item.selection])}
            moveRangeOnFirstSelection={false}
            ranges={state}
            direction="horizontal"
            showDateDisplay={false}
            showPreview={false}
            minDate={moment().toDate()}
            locale={de}
          />
        </div>
      )}
    </React.Fragment>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    plans: state.plans.plans
  };
};

export default connect(mapStateToProps, null)(CalendarContainer);
