// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 15px;
}

.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider_switch {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
}

.slider_switch:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: -1px;
    bottom: -1px;
    background-color: white;
    transition: 0.4s;
}

input:checked+.slider_switch {
    background-color: #2196f3;
}

input:focus+.slider_switch {
    box-shadow: 0 0 1px #2196f3;
}

input:checked+.slider_switch:before {
    transform: translateX(15px);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Elements/Switch/Switch.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,qBAAqB;IACrB,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,UAAU;IACV,QAAQ;IACR,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,eAAe;IACf,MAAM;IACN,OAAO;IACP,QAAQ;IACR,SAAS;IACT,sBAAsB;IAEtB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,WAAW;IACX,UAAU;IACV,YAAY;IACZ,uBAAuB;IAEvB,gBAAgB;AACpB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IAGI,2BAA2B;AAC/B","sourcesContent":[".switch {\n    position: relative;\n    display: inline-block;\n    width: 30px;\n    height: 15px;\n}\n\n.switch input {\n    opacity: 0;\n    width: 0;\n    height: 0;\n}\n\n.slider_switch {\n    position: absolute;\n    cursor: pointer;\n    top: 0;\n    left: 0;\n    right: 0;\n    bottom: 0;\n    background-color: #ccc;\n    -webkit-transition: 0.4s;\n    transition: 0.4s;\n}\n\n.slider_switch:before {\n    position: absolute;\n    content: \"\";\n    height: 17px;\n    width: 17px;\n    left: -1px;\n    bottom: -1px;\n    background-color: white;\n    -webkit-transition: 0.4s;\n    transition: 0.4s;\n}\n\ninput:checked+.slider_switch {\n    background-color: #2196f3;\n}\n\ninput:focus+.slider_switch {\n    box-shadow: 0 0 1px #2196f3;\n}\n\ninput:checked+.slider_switch:before {\n    -webkit-transform: translateX(15px);\n    -ms-transform: translateX(15px);\n    transform: translateX(15px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
