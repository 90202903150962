import {
  BRANCH_AND_DEPS_ERROR,
  branchAndDepsDispatchTypes, GET_BRANCHES_SUCCESS, GET_DEPARTMENTS_AND_BRANCHES_SUCCESS,
  GET_DEPARTMENTS_SUCCESS
} from "../types/branchAndDepartmentTypes";

interface DefaultStateI {
	error?: string,
	departments?: any,
	branches?: any,
	departmentAndBranches?: any
}

const initialState:DefaultStateI = {
  error: "",
  departments: [],
  branches: [],
  departmentAndBranches: []
};

export const departmentsAndBranchReducer = (
  state: DefaultStateI = initialState,
  action: branchAndDepsDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case GET_DEPARTMENTS_SUCCESS:
    return {
      ...state,
      departments: action.payload
    };
  case GET_BRANCHES_SUCCESS:
    return {
      ...state,
      branches: action.payload
    };
  case GET_DEPARTMENTS_AND_BRANCHES_SUCCESS:
    return {
      ...state,
      departmentAndBranches: action.payload
    };
  case BRANCH_AND_DEPS_ERROR:
    return {
      ...state,
      error: action.payload
    };
  default:
    return state;
  }
};
