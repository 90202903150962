import React from "react";
import "../SimpleComponents/Modal.css";
import closeIcon from "img/Dashboard/Employee/close_btn.svg";
import deleteImage from "img/Dashboard/Employee/delete_warning_image.svg";
import Spacer from "../SimpleComponents/Spacer";
import Button from "../Buttons/Button";
import { useTranslation } from "react-i18next";

interface CustomProps {
  closeModal: () => void
  handleSubmit: () => void
}

export default function PlansUpdateWarningModal({closeModal, handleSubmit}:CustomProps) {
  const { t } = useTranslation();

  return (
    <div className="modal_container">
      <Spacer spacerHeight="30px" />
      <img
        onClick={closeModal}
        className="close_popup"
        src={closeIcon} alt="close"
      />
      <Spacer spacerHeight="30px" />
      <div className="center">
        <img src={deleteImage} alt="delete"/>
      </div>
      <p
        style={{fontWeight: "normal", fontSize: "0.8rem"}}
        className="text-center">
        {t("dashboard.plans.update_warning_text")}
      </p>
      <div className="center">
        <div>
          <Button
            buttonStyles={{minWidth:"140px"}}
            buttonType="primary"
            buttonLabel=
              {t("dashboard.employee.employee_modal_btn_2")}
            buttonHandler={handleSubmit}
          />
          <p
            onClick={closeModal}
            className="modal_close_text center">
            {t("dashboard.employee.employee_modal_btn_1")}
          </p>
        </div>

      </div>
    </div>
  );

}
