import React, { useEffect } from "react";
import dashboardIcon from "img/Dashboard/SidebarIcons/icon_dashboard.svg";
import generalInfoIcon from "img/Dashboard/SidebarIcons/icon_general-info.svg";
import plansIcon from "img/Dashboard/SidebarIcons/icon_plans.svg";
import employeeIcon from "img/Dashboard/SidebarIcons/icon_employee.svg";
import ordersIcon from "img/Dashboard/SidebarIcons/icon_overview.svg";
import settingsIcon from "img/Dashboard/SidebarIcons/icon_settings.svg";
import onboardingIcon from "img/Dashboard/SidebarIcons/icon_onboarding.svg";
import companiesIcon from "img/Dashboard/SidebarIcons/icon_companies.svg";
import employeePaymentIcon from "img/Dashboard/SidebarIcons/icon_cards.svg";
import notificationIcon from "img/Dashboard/SidebarIcons/icon_notification-center.svg";
import corplifeBenefitsIcon from "img/hrZone/ic_MW_unselected.svg";

interface CustomProps {
  handleSidebarChanges: (page: string) => void;
  selectedTab: string;
  itemName: string;
  itemText: string;
  openSidebar: boolean;
  customStyles?: any;
  linkTo?: string;
  image: string
}

function SidebarItem({
  handleSidebarChanges,
  selectedTab,
  itemName,
  itemText,
  openSidebar,
  customStyles,
  linkTo,
  image
}: CustomProps) {
  // Setting image for tab
//   let image = "";
//   const cutItemName = itemName.substr(itemName.lastIndexOf("/") + 1);

  switch (image) {
  case "dashboard":
    image = dashboardIcon;
    break;
  case "general-info":
    image = generalInfoIcon;
    break;
  case "plans":
    image = plansIcon;
    break;
  case "employee":
    image = employeeIcon;
    break;
  case "overview":
    image = ordersIcon;
    break;
  case "settings":
    image = settingsIcon;
    break;
  case "payments":
    image = onboardingIcon;
    break;
  case "companies":
    image = companiesIcon;
    break;
  case "notification-center":
    image = notificationIcon;
    break;
  case "hrzone":
    image = employeeIcon;
    break;
  case "corplifebenefits":
    image = corplifeBenefitsIcon;
    break;
  case "employeePayments":
    image = employeePaymentIcon;
    break;
  default:
    image = dashboardIcon;
  }

  const isActive = (itemName: string) => {
    let result = true;
    const selectedArray = selectedTab.split("/");
    itemName.split("/").forEach((part: string, index: number) => {
      if (part !== selectedArray[index]) {
        result = false;
      }
    });
    return result;
  };

  return (
    <li
      id="sidebar_item"
      data-testid="sidebar_item"
      className={
        isActive(itemName) ? "sidebar_tab row selected_tab" : "row sidebar_tab"
      }
      onClick={() => {
		  if(linkTo){
          window.open(linkTo,"_blank");
		  }else{
          handleSidebarChanges(itemName);
		  }
      }}
      style={customStyles}
    >
      <img
        data-testid="sidebar_image"
        id="dashboard_icon"
        src={image}
        alt={itemText}
      />
      {openSidebar ? (
        <p id="sidebar_text" className="sidebar_text">
          {itemText}
        </p>
      ) : null}
    </li>
  );
}

export default SidebarItem;
