import React from "react";
import PropagateLoader from "react-spinners/PropagateLoader";

export default function LoadingModal() {
  return (
    <div style={{
      width: "100vw",
      height: "100vh",
      display: "flex",
      justifyContent: "center",
      alignItems: "center"
    }}>
      <PropagateLoader color={"#fff"} />
    </div>
  );
}
