export const modalStyles = {
  overlay: {
    backgroundColor: "rgba(55,52,52,0.7)",
    zIndex: 9999,
  },
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
  },
};
