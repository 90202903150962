export const HANDLE_SIDEBAR = "HANDLE_SIDEBAR";
export const CHANGE_SIDEBAR_TAB = "CHANGE_SIDEBAR_TAB";

interface HandleSidebarAction {
	type: typeof HANDLE_SIDEBAR
	payload: boolean
}
interface ChangeSidebarAction {
	type: typeof CHANGE_SIDEBAR_TAB
	payload: string
}

export type SidebarActionTypes = HandleSidebarAction | ChangeSidebarAction
