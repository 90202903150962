import {Dispatch} from "redux";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import {
  GET_WEAVE_CORPORATE,
  SHOW_WEAVR_ERROR,
  SHOW_WEAVR_LOADING,
  weavrTypes
} from "../types/weavrTypes";
import {urls} from "../../api/urls";

// Get Users
export const getWeavrCorporate = () => async (dispatch: Dispatch<weavrTypes>) => {
  await new reduxRequestHandler({
    url: urls.payments.getCorporateByCompanyId,
    successDispatchType: GET_WEAVE_CORPORATE,
    errorDispatchType: SHOW_WEAVR_ERROR,
    errorPayload: "get_users"
  }).get(dispatch);
};

// Show loading
export function showUserLoading() {
  return {
    type: SHOW_WEAVR_LOADING
  };
}
