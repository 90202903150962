import { Dispatch } from "redux";
import {
  CORPORATES_DATA_ERROR,
  CORPORATES_INFORMATION_SUCCESS
} from "redux/types/corporateTypes";
import { reduxRequestHandler } from "services/reduxRequestHandler";
import { urls } from "api/urls";

export const getHrZoneCompanies =
  (filters: any) => async (dispatch: Dispatch<any>) => {
    const dynamicParams = {
      perPage: filters.perPage || 8,
      page: filters.page || 1,
	  sessionKey: filters.sessionKey,
	  hr_email: filters.hr_email
    };

    Object.keys(filters).map((key: any) => {
      if (filters[key] || filters[key] === 0) {
        Object.assign(dynamicParams, { [key]: filters[key] });
      }
    });
    await new reduxRequestHandler({
      url: urls.hrZone.base + "getOffices",
      dynamicParams,
      successDispatchType: CORPORATES_INFORMATION_SUCCESS,
      errorDispatchType: CORPORATES_DATA_ERROR,
      errorPayload: "corporates_error"
    }).postHrZone(dispatch);
  };
