import React, {useEffect, useState} from "react";
import "./PaymentsOverviewHeaderContainerTransactionHistoryTab.css";
import {useTranslation} from "react-i18next";
import CustomSelect from "../../../../../../SimpleComponents/Select";
import PaymentTransactionsList from "../../../../../../Lists/PaymentTransactionsList";
import {getToken} from "../../../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../../../environment";
import {urls} from "../../../../../../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../../../../SimpleComponents/ToastContainer";
import FilteringBlock from "Components/Blocks/FilteringBlock";
import FilterDatePicker from "../../../../../../SimpleComponents/FilterdatePicker";
import {getCards} from "../../../../../../../redux/actions/paymentsActions";


interface IPaymentsOverviewHeaderContainerTransactionHistoryTab {
  showLogin: () => void;
}

export default function PaymentsOverviewHeaderContainerTransactionHistoryTab(props: IPaymentsOverviewHeaderContainerTransactionHistoryTab) {
  const { t } = useTranslation();

  const [loading, setLoading] = useState(true);
  const [transactions, setTransactions] = useState<any>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [filters, setFilters] = useState<any>({
    limit: 8,
    skip: 0,
    startDate: null,
    endDate: null
  });
  const [showDownloadButtonLoading, setShowDownloadButtonLoading] = useState(false);

  const getTransactions = async (data: {skip: number, limit: number, startDate?: string, endDate?: string}) => {
    setLoading(true);

    const token = await getToken();
    const weavrToken = await getToken(true);
    const dynamicParams = {
      limit: data.limit || 8,
      skip: data.skip || 0,
      startDate: data.startDate || "",
      endDate: data.endDate || "",
      //   ...filters
    };
    axios.get(
      // eslint-disable-next-line max-len
      `${environment.baseUrl}${urls.payments.managedAccountStatement}?weavrToken=${weavrToken}`,
      {
        headers: {Authorization: `Bearer ${token}`},
        params: dynamicParams
      })
      .then((res: any) => {
        if (res.data) {
          setTransactions(res.data);
        }
      })
      .catch((e: any) => {
        console.error(e.response);
      }).finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getTransactions({skip: 0, limit: 8});
  }, []);

  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      const nextPage = (pageNumber - 1) * 8;
      setCurrentPage(nextPage);
      getTransactions({skip: nextPage, limit: 8, startDate: filters.startDate, endDate: filters.endDate});
    }
  };

  const handleDownload = (option: string) => {
    setShowDownloadButtonLoading(true);
    let type: string;
    if (option === "xlsx") {
      type = "1";
    }
    if (option === "csv") {
      type = "2";
    }

    const postData = async () => {
      const weavrToken = await getToken(true);
      await axios.get(
        `${environment.baseUrl}${urls.files.transactions}`,
        {
          headers: {Authorization: `Bearer ${getToken()}`},
          responseType: "blob",
          params: {
            exportType: type,
            weavrToken: weavrToken,
            startDate: filters.startDate,
            endDate: filters.endDate
          }
        }
      ).then((res: any) => {
        try{
          let fileName = "Corplife_transactions.csv";
          let fileType = "application/csv";

          if (type === "1") {
            fileName = "Corplife_transactions.xlsx";
            fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          }
          if (type === "2") {
            fileName = "Corplife_transactions.csv";
            fileType = "application/csv";
          }

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file:any;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();

        } catch(e) {
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      }).catch((e: any) => {
        if (e.response.status === 401) {
          props.showLogin();
        } else {
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
        }
      }).finally(() => {
        setShowDownloadButtonLoading(false);
      });
    };
    postData();
  };


  const handleDatePicker = (dates: {startDate: string, endDate: string}) => {
    setFilters({
      ...filters,
      startDate: dates.startDate,
      endDate: dates.endDate
    });
    getTransactions({
      skip: 0,
      limit: 8,
      startDate: dates.startDate,
      endDate: dates.endDate
    });
    setCurrentPage(1);
  };

  return(
    <div className="payment_overview_header_container_tab_content_container">

      <div className="space-between">

        <div>
          <h3 className="payment_overview_header_container_tab_content_container_title">
            {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.title")}
          </h3>

          <p className="payment_overview_header_container_tab_content_container_sub_title">
            {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.sub_title")}
          </p>
        </div>

        <CustomSelect
          options={[t("dashboard.overview.export_all") as string, "csv", "xlsx"]}
          icon="export"
          onChange={handleDownload}
          buttonStyle={{
            backgroundColor: "#707070"
          }}
          loading={showDownloadButtonLoading}
        />

      </div>

      <div style={{height: "30px"}} />

      <FilteringBlock title={t("dashboard.plans.filters.title")}>
        <>
          <FilterDatePicker
            startDate={filters.startDate}
            endDate={filters.endDate}
            handleChange={(values:any) => handleDatePicker(values)}
          />
        </>
      </FilteringBlock>

      <PaymentTransactionsList
        transactions={transactions}
        currentPage={currentPage}
        filters={filters}
        pageLimit={8}
        paginate={(pageNumber: number) => paginate(pageNumber)}
        loading={loading}
      />

    </div>
  );
}
