export const appStoreLink =
  "https://apps.apple.com/us/app/appetio/id1528205503";
export const googlePlayLink =
  "https://play.google.com/store/apps/details?id=com.corplife.appetio";
export const agbLink =
  "https://docs.google.com/document/d/1TE0l5OlMr2nTwd3ceeAb-XzJPaxDZUmNNwy_UHmdpMg/edit?usp=sharing";
export const agbLunchKartenLink =
  "https://docs.google.com/document/d/1QdwdI87y519g3coThHL-63oudFYsYQSfpBvxE04C2dI/edit?usp=sharing";
export const uberLunchKartenLink =
  "https://docs.google.com/document/d/1hJ7xtjKK1TqWAqODL-Y9IWKj_b_FXPBvKgdxdYoTEMQ/edit?usp=sharing";
export const datenschutzLink =
  "https://docs.google.com/document/d/1DiLZe3Lp34liSvlq8345_6OGO0anKkPTCwhzvltA3ks/edit?usp=sharing";
export const impressumLink =
  "https://docs.google.com/document/d/1cg-J5hU8hELVKr6dSMen1bPboi2ao8fiPNssRiWp1nQ/edit?usp=sharing";
