// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.infoCard {
    background-color: #ffffff!important;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
    min-height: 120px;
    display: flex;
    align-items: flex-start;
    position: relative;
}

.infoCard_link {
    position: absolute;
    right: 16px;
    bottom: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
}

.infoCard_link > p {
    font-size: 12px;
    font-weight: 500;
    color: rgba(86, 82, 94, 1);
}

.infoCard_link > img {
    margin-left: 7px;
    height: 10px;
}

.infoCard_image_container {
    width: 60px;
    height: 60px;
    background-color: rgba(242, 242, 244, 1);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 16px;
    margin-right: 15px;
}

.infoCard_content {
    height: 60px;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.infoCard_content > h6 {
    font-size: 16px;
    font-weight: 700;
    color: rgba(86, 82, 94, 1);
}

.infoCard_content > h5 {
    font-size: 22px;
    font-weight: 700;
    color: rgba(86, 82, 94, 1);
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/InfoCard/InfoCard.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,aAAa;IACb,kBAAkB;IAGlB,yCAAyC;IACzC,iBAAiB;IACjB,aAAa;IACb,uBAAuB;IACvB,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,aAAa;IACb,mBAAmB;IACnB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,wCAAwC;IACxC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;AAC9B","sourcesContent":[".infoCard {\n    background-color: #ffffff!important;\n    padding: 16px;\n    border-radius: 8px;\n    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);\n    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);\n    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);\n    min-height: 120px;\n    display: flex;\n    align-items: flex-start;\n    position: relative;\n}\n\n.infoCard_link {\n    position: absolute;\n    right: 16px;\n    bottom: 16px;\n    display: flex;\n    align-items: center;\n    cursor: pointer;\n}\n\n.infoCard_link > p {\n    font-size: 12px;\n    font-weight: 500;\n    color: rgba(86, 82, 94, 1);\n}\n\n.infoCard_link > img {\n    margin-left: 7px;\n    height: 10px;\n}\n\n.infoCard_image_container {\n    width: 60px;\n    height: 60px;\n    background-color: rgba(242, 242, 244, 1);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    border-radius: 16px;\n    margin-right: 15px;\n}\n\n.infoCard_content {\n    height: 60px;\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n}\n\n.infoCard_content > h6 {\n    font-size: 16px;\n    font-weight: 700;\n    color: rgba(86, 82, 94, 1);\n}\n\n.infoCard_content > h5 {\n    font-size: 22px;\n    font-weight: 700;\n    color: rgba(86, 82, 94, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
