import {
  AUTH_ERROR,
  authDispatchTypes,
  CHANGE_PASSWORD,
  CLEAR_AUTH_MESSAGES,
  LOGIN_SUCCESS,
  LOGOUT, RESET_PASSWORD,
  USER_TOKEN
} from "../types/authTypes";

interface DefaultStateI {
	success?: string,
	loaded?: boolean,
	error?: string,
	userToken?: string
}

const initialState:DefaultStateI = {
  success: "",
  error: "",
  loaded: false,
  userToken: undefined
};

export const authReducer = (
  state: DefaultStateI = initialState,
  action: authDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case AUTH_ERROR:
    return {
      ...state,
      loaded: false,
      error: action.payload
    };
  case LOGIN_SUCCESS:
    return {
      ...state,
      loaded: true
    };
  case USER_TOKEN:
    return {
      ...state,
      userToken: action.payload
    };
  case CHANGE_PASSWORD:
    return {
      ...state,
      success: action.payload
    };
  case RESET_PASSWORD:
    return {
      ...state,
      success: action.payload
    };
  case LOGOUT:
    return {
      ...state,
      userToken: undefined
    };
  case CLEAR_AUTH_MESSAGES:
    return {
      ...state,
      success: "",
      error: "",
      loaded: true
    };
  default:
    return state;
  }
};
