// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `footer {
    width: 100%;
    box-sizing: border-box;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #0e0a40;
    padding-left: 20px;
    padding-right: 20px;
}

footer > h4 {
    color: #0e0a40;
    font-weight: 300;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Widgets/Checklist/Components/Footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,sBAAsB;IACtB,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,cAAc;IACd,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["footer {\n    width: 100%;\n    box-sizing: border-box;\n    height: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    color: #0e0a40;\n    padding-left: 20px;\n    padding-right: 20px;\n}\n\nfooter > h4 {\n    color: #0e0a40;\n    font-weight: 300;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
