import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import VerticalDivider from "Components/Containers/VerticalDivider";
import PageTitle from "Components/SimpleComponents/PageTitle";
import Spacer from "Components/SimpleComponents/Spacer";
import {useFormik} from "formik";
import moment from "moment";
import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {connect, useDispatch} from "react-redux";
import {useNavigate, useParams} from "react-router-dom";
import MoonLoader from "react-spinners/MoonLoader";
import {toast} from "react-toastify";
import * as Yup from "yup";

import SimpleInput from "../../Components/SimpleComponents/SimpleInput";
import {
  cleanupUserInformation,
  clearUserMessages,
  getOneUser,
  getUserInfo,
  getUserStatistic,
  showUserLoading,
  updateEmployee,
} from "../../redux/actions/usersActions";
import {RootState} from "../../redux/reducers/rootReducer";
import {capitalize} from "../../utils/capitalizeFirstLetter";
import {isAllowed, userPermissions, userRoles,} from "../../utils/permissionsAllow";
import useWindowDimensions from "../../utils/useWindowSize";
import Button from "../../Components/Buttons/Button";
import GeneralInfoLoading from "../../Components/LoadingPlaceholders/GeneralInfoPageLoading";
import FormikInput from "../../Components/SimpleComponents/FormikInput";
import ImageUpload from "../../Components/SimpleComponents/ImageUpload";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import Switch from "../../Components/Elements/Switch/Switch";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";

interface CustomProps {
  userData?: any;
  postUpdateUserState?: any;
  employeeInfo?: any;
  userStatistic?: any;
  loading?: boolean;
  withId?: boolean;
}

interface IUserInformation {
  companyId: string;
  imageUrl: string;
  firstName: string;
  lastName: string;
  email: string;
  approveInvoices: string;
  branchId: string;
  departmentId: string;
  emailNotification: boolean;
  pushNotification: boolean;
}

function ProfileSettings({
  userData,
  postUpdateUserState,
  withId,
  employeeInfo,
  loading,
}: CustomProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const params = useParams();
  const lunchSettings = new getLunchSettings();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (postUpdateUserState.success === "update_employee") {
      setShowSubmitButtonLoading(false);
      dispatch(clearUserMessages());
      if (imageUpdate) {
        toast.success(
          <CustomToastContainer
            message={t("warnings.update_user_logo_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        setImageUpdate(false);
      } else {
        toast.success(
          <CustomToastContainer
            message={t("warnings.update_user_info_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      }
      if (params && params.userId) {
        dispatch(getOneUser(params.userId));
      } else {
        dispatch(getUserInfo());
      }
    } else if (postUpdateUserState.error === "update_employee") {
      setShowSubmitButtonLoading(false);
      dispatch(clearUserMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.update_user_info_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    } else if (postUpdateUserState.error === "This email already registered") {
      setShowSubmitButtonLoading(false);
      dispatch(clearUserMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.duplicated_email")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    } else if (postUpdateUserState.error === "get_one_user") {
      navigate("/error");
      dispatch(clearUserMessages());
    }
  }, [postUpdateUserState, t, dispatch]);

  useEffect(() => {
    if (params.userId && withId) {
      dispatch(showUserLoading());
      dispatch(getOneUser(params.userId));
    }
  }, [dispatch, params]);

  useEffect(() => {
    dispatch(getUserInfo());
    return () => {
      dispatch(cleanupUserInformation());
    };
  }, []);

  // Initialize Formik values
  useEffect(() => {
    if (employeeInfo.user && employeeInfo.user._id) {
      setDataLoaded(true);
      formik.values.companyId = employeeInfo.user.companyId || "";
      formik.values.imageUrl = employeeInfo.user.imageUrl || "";
      formik.values.firstName = employeeInfo.user.firstName || "";
      formik.values.lastName = employeeInfo.user.lastName || "";
      formik.values.email = employeeInfo.user.email || "";
      formik.values.departmentId = employeeInfo.user.departmentId || "";
      formik.values.branchId = employeeInfo.user.branchId || "";
      formik.values.approveInvoices = employeeInfo.user.approveInvoices;
      setImageUrl(employeeInfo.imageUrl);
    }
    //Need to disable it, because eslint says, that I need to add
    //all dependencies from formik, but we need to update, when company
    // state is updated
    // eslint-disable-next-line
  }, [employeeInfo]);

  // Set formik values
  useEffect(() => {
    if (userData && userData.user && !params.userId) {
      setDataLoaded(true);
      formik.values.companyId = userData.user.companyId || "";
      formik.values.imageUrl = userData.user.imageUrl || "undefined";
      formik.values.firstName = userData.user.firstName || "";
      formik.values.lastName = userData.user.lastName || "";
      formik.values.email = userData.user.email || "";
      formik.values.departmentId = userData.user.departmentId || "";
      formik.values.branchId = userData.user.branchId || "";
      formik.values.approveInvoices = userData.user.approveInvoices || "false";
      formik.values.emailNotification = userData.user.emailNotification || false;
      formik.values.pushNotification = userData.user.pushNotification || false;
      setImageUrl(userData.user.imageUrl);
    }
    //Need to disable it, because eslint says, that I need to add
    //all dependencies from formik, but we need to update, when company
    // state is updated
    // eslint-disable-next-line
  }, [userData]);

  // set less item in table for small screens
  useEffect(() => {
    if (width < 600) {
      setBtnsRatio("1fr");
      setRatio("100%");
      setSmallRatio("100%");
    } else if (width < 1000) {
      setRatio("100%");
      setSmallRatio("100%");
      setBtnsRatio("50% 50%");
    } else if (width > 1000) {
      setRatio("52% 7% 33%");
      setSmallRatio("52% 20%");
      setBtnsRatio("26% 26%");
    }
  }, [width]);

  // User Statistic
  useEffect(() => {
    if (userData.user && userData.user.userInfo && userData.user.userInfo._id) {
      dispatch(
        getUserStatistic(
          userData.userInfo._id,
          moment(new Date())
            .subtract(1, "months")
            .endOf("month")
            .format("YYYY-MM-DD"),
          moment(new Date()).format("YYYY-MM-DD")
        )
      );
    }
  }, [userData]);

  //Image URl from upload
  const [imageUrl, setImageUrl] = useState("");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [imageUpdate, setImageUpdate] = useState(false);
  // set responsive ratio for container
  const [ratio, setRatio] = useState("52% 7% 33%");
  const [smallRatio, setSmallRatio] = useState("52% 20%");
  const [btnsRatio, setBtnsRatio] = useState("26% 26%");
  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);

  const formik = useFormik<IUserInformation>({
    initialValues: {
      companyId: "",
      imageUrl: "",
      firstName: "",
      lastName: "",
      email: "",
      approveInvoices: "false",
      departmentId: "",
      branchId: "",
      emailNotification: false,
      pushNotification: false
    },
    validationSchema: Yup.object().shape({
      firstName: Yup.string().required(
        t("formik_warnings.general_info.firstName")
      ),
      lastName: Yup.string().required(
        t("formik_warnings.general_info.lastName")
      ),
      email: Yup.string()
        .required(t("formik_warnings.general_info.email"))
        .email(t("formik_warnings.general_info.emailValid")),
    }),
    // handle Form submitting
    onSubmit: () => {
      //
    },
  });

  const handleInputChange = (inputString: string, id: string) => {
    //Nothing, it's disabled input
  };

  const handleFormSubmit = (e: any, imageKey?: string) => {
    if (!imageKey) {
      e.preventDefault();
    } else {
      setImageUpdate(true);
    }
    setShowSubmitButtonLoading(true);
    let postData;
    if (imageKey) {
      postData = { ...formik.values, imageUrl: imageKey };
    } else {
      postData = { ...formik.values, imageUrl: formik.values.imageUrl };
    }
    let userID;
    params.userId
      ? (userID = employeeInfo.user._id)
      : (userID = userData.user._id);
    postData.firstName = capitalize(postData.firstName);
    postData.lastName = capitalize(postData.lastName);
    dispatch(updateEmployee(postData, userID));
  };

  return (!dataLoaded ? (
    <>
      <GeneralInfoLoading />
    </>
  ) : (
    <ContentContainer>
      <MainSpace>
        {loading ? (
          <div className="center" style={{ minHeight: "20vh" }}>
            <MoonLoader />
          </div>
        ) : (
          <form onSubmit={(e: any) => handleFormSubmit(e)}>
            <VerticalDivider ratio={ratio}>
              <div>
                {width > 1000 ? null : (
                  <div>
                    <ImageUpload
                      update={handleFormSubmit}
                      setImageUrl={setImageUrl}
                      ImageUrl={imageUrl ? imageUrl : "/images/user_avatar.svg"}
                    />
                  </div>
                )}
                <PageTitle
                  mainTitle={`${formik.values.firstName} 
									${formik.values.lastName}`}
                />
                <Spacer spacerHeight="11px" />
                <div className="relative">
                  <FormikInput
                    htmlFor="firstName"
                    name="firstName"
                    value={formik.values.firstName}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.settings.input_1")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.firstName && formik.touched.firstName && (
                    <p className="input_warning">{formik.errors.firstName}</p>
                  )}
                </div>
                <div className="relative">
                  <FormikInput
                    htmlFor="lastName"
                    name="lastName"
                    value={formik.values.lastName}
                    disabled={false}
                    handleChange={formik.handleChange}
                    label={t("dashboard.settings.input_2")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.lastName && formik.touched.lastName && (
                    <p className="input_warning">{formik.errors.lastName}</p>
                  )}
                </div>
                <div className="relative">
                  <FormikInput
                    htmlFor="email"
                    name="email"
                    value={formik.values.email}
                    disabled={!isAllowed({data: {
                      roles: [userRoles.superAdmin, userRoles.admin]
                    }})}
                    handleChange={formik.handleChange}
                    label={t("dashboard.settings.input_3")}
                    onBlur={formik.handleBlur}
                  />
                  {formik.errors.email && formik.touched.email && (
                    <p className="input_warning">{formik.errors.email}</p>
                  )}
                </div>
              </div>

              <div />
              {width < 1000 ? null : (
                <div className="settings_image_box">
                  <ImageUpload
                    update={handleFormSubmit}
                    setImageUrl={setImageUrl}
                    ImageUrl={imageUrl ? imageUrl : "/images/user_avatar.svg"}
                  />
                </div>
              )}
            </VerticalDivider>

            <VerticalDivider ratio={smallRatio}>
              {params.userId ? null : (
                <>
                  <div>
                    <SimpleInput
                      id="password" // only as visual
                      name={t("dashboard.settings.password")}
                      inputValue="**********"
                      handleChange={handleInputChange}
                      isDisabled={!isAllowed({data: {
                        roles: [userRoles.superAdmin, userRoles.admin]
                      }})}
                    />
                  </div>
                  <div className="align_form" style={{ marginLeft: "0.5rem" }}>
                    <Button
                      buttonType="tertiary btn_lg"
                      buttonLabel={t("dashboard.settings.password_btn")}
                      buttonHandler={() => {
                        navigate(
                          `/resetPassword?query=${userData.user.email}`,
                          {state: {reset: true}}
                        );
                      }}
                      fullWidth={width < 1000}
                      buttonHtmlType="button"
                    />
                  </div>
                </>
              )}
            </VerticalDivider>

            {lunchSettings.isVirtualCard()
              &&
              <Switch
                id={"emailNotification"}
                checked={formik.values.emailNotification}
                onChange={() => formik.setFieldValue("emailNotification", !formik.values.emailNotification, true)}
                label={t("dashboard.settings.switches.emailNotification")}
              />
            }

            {lunchSettings.isVirtualCard()
              &&
              <Switch
                id={"pushNotification"}
                checked={formik.values.pushNotification}
                onChange={() => formik.setFieldValue("pushNotification", !formik.values.pushNotification, true)}
                label={t("dashboard.settings.switches.pushNotification")}
              />
            }

            <Spacer spacerHeight="31px" />

            <VerticalDivider ratio={btnsRatio}>
              <div>
                <Button
                  buttonType="tertiary"
                  buttonLabel={t("dashboard.settings.back_btn")}
                  buttonHandler={() => {
                    navigate(-1);
                  }}
                  buttonStyles={{ fontSize: "0.9rem" }}
                  fullWidth={width < 600}
                  submitButton={false}
                  buttonHtmlType="button"
                />
              </div>

              {isAllowed({
                data: {
                  roles: [
                    userRoles.superAdmin,
                    userRoles.admin,
                    userRoles.custom,
                    userRoles.user
                  ],
                  permissions: {
                    isAdmin: true,
                    permissionsArray: [userPermissions.users],
                  },
                },
              }) ? (
                  <div
                    style={width < 600 ? { marginTop: "1rem" } : {}}
                    className={width > 600 ? "text-right" : ""}
                  >
                    <Button
                      buttonHtmlType="submit"
                      submitButton={true}
                      disabled={!(formik.isValid && formik.dirty)}
                      buttonType="primary"
                      buttonLabel={t("dashboard.settings.save_btn")}
                      buttonStyles={{ fontSize: "0.9rem" }}
                      fullWidth={width < 600}
                      loading={showSubmitButtonLoading}
                    />
                  </div>
                ) : null}
            </VerticalDivider>

            <Spacer spacerHeight="120px" />
          </form>
        )}
      </MainSpace>
    </ContentContainer>
  ));
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: state.users.userInfo,
    postUpdateUserState: state.users,
    employeeInfo: state.users.userInformation,
    userStatistic: state.users.userStatistic,
    loading: state.users.loading,
  };
};

export default connect(mapStateToProps, null)(ProfileSettings);
