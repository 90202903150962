import React, {useEffect, useState} from "react";
import logoExample from "../../../../../../img/corplifeSVG.svg";
import exampleImageOne from "../../../../../../img/example_image_1.png";
import exampleImageTwo from "../../../../../../img/example_image_2.png";
import exampleImageThree from "../../../../../../img/example_image_3.png";
import exampleImageFour from "../../../../../../img/example_image_4.png";
import exampleImageFive from "../../../../../../img/example_image_5.png";
import memberCard from "../../../../../../img/local_wallet.png";
import linkImage from "../../../../../../img/external-link-alt-solid.svg";
import {RootState} from "../../../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import {useTranslation} from "react-i18next";
import signImages from "../../../../../../utils/signImages";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";

interface CustomProps {
  tooltip?: any,
  stateValues?: any,
  id?: string
}

function LocalExamplePage({tooltip, stateValues, id}:CustomProps) {
  const { t } = useTranslation();

  const textSplitter = (text: string) => {
    return text.toString().split("\n");
  };

  const [countryState, setCountryState] = useState("at");

  // Images
  const [mainImage, setMainImage] = useState<any>(exampleImageOne);
  const [imagesArray, setImagesArray] = useState<any>([
    exampleImageTwo,
    exampleImageThree,
    exampleImageFour,
    exampleImageFive
  ]);

  // Initialize images
  useEffect(() => {
    if (stateValues[1].image !== "1") {
      setMainImage(exampleImageOne);
      setImagesArray([
        exampleImageTwo,
        exampleImageThree,
        exampleImageFour,
        exampleImageFive
      ]);
    } else if (stateValues[1] && stateValues[1].uploadedImages.length) {
      const newImagesArray:any = [];
      const newModifiedArray:any = [];
      setImagesArray([]);
      let values;
      if (countryState === "at") {
        values = stateValues[1].uploadedImages;
      } else {
        values = stateValues[1].uploadedImagesDE;
      }
      if (values && values.length) {
        values.forEach((file:any, i:number) => {
          if (i === 0) {
            if (id && typeof file === "string") {
              const image = signImages(file);
              const promise = Promise.resolve(image);
              promise.then((val) => {
                setMainImage(val);
              });
            } else {
              if (mainImage !== URL.createObjectURL(file)) {
                setMainImage(URL.createObjectURL(file));
              }
            }
          } else {
            if (id && typeof file === "string") {
              const image = signImages(file);
              const promise = Promise.resolve(image);
              promise.then((val) => {
                setImagesArray((oldValue:any) => [...oldValue, val]);
              });
            } else {
              if (imagesArray[i-1] !== URL.createObjectURL(file)) {
                newImagesArray.push(URL.createObjectURL(file));
              }
            }
          }
        });
        if (newImagesArray.length) {
          setImagesArray(newImagesArray);
        }
      } else {
        setMainImage(exampleImageOne);
        setImagesArray([
          exampleImageTwo,
          exampleImageThree,
          exampleImageFour,
          exampleImageFive
        ]);
      }
    }
  }, [stateValues, stateValues[1].image, countryState]);

  const [logoImage, setLogoImage] = useState(logoExample);

  useEffect(() => {
    if (id && typeof stateValues[1].logoImage[0] === "string") {
      const image = signImages(stateValues[1].logoImage[0]);
      const promise = Promise.resolve(image);
      promise.then((val) => {
        setLogoImage(val);
      });
    } else if (stateValues[1].logoImage.length) {
      setLogoImage(URL.createObjectURL(stateValues[1].logoImage[0]));
    }
  }, [stateValues[1]]);

  return (
    <div className="main_form_right">
      <div className="example_header">
        <div className="row">
          <div className="dot" style={{backgroundColor: "#FFAA3F"}} />
          <div className="dot" style={{backgroundColor: "#C43B34"}} />
          <div className="dot" style={{backgroundColor: "#3E86A2"}} />
        </div>
        {stateValues[0].country.length === 2
          ? <div className="row">
            <p
              onClick={() => setCountryState("at")}
              style={{
                color: countryState === "at" ? "#ffffff" : "#bebebe",
                margin: 0,
                fontWeight: countryState === "at" ? "bold" : "normal",
                cursor: "pointer"
              }}
            >
              AT
            </p>
            <p
              onClick={() => setCountryState("de")}
              style={{
                color: countryState === "de" ? "#ffffff" : "#bebebe",
                margin: 0,
                marginLeft: "10px",
                fontWeight: countryState === "de" ? "bold" : "normal",
                cursor: "pointer"
              }}
            >
              DE
            </p>
          </div>
          : null
        }
        <p>
          {t("partner_portal.deals.create.main_form.example_container.title")}
        </p>
      </div>
      <div className="example_body">
        <div className="top_section">

          {/*IMAGES*/}
          <div className={tooltip.element === "images"
            ? "example_title_container outlined images"
            : "example_title_container"}
          data-testid="tooltip_image"
          >
            <div className="images_container">
              <div
                className="main_image"
                style={{backgroundImage: `url(${mainImage})`}}
              />
              <div className="sub_images_grid">
                <Carousel
                  showThumbs={false}
                  centerMode
                  centerSlidePercentage={100 / 3}
                  showIndicators={false}
                  showStatus={false}
                >
                  {imagesArray.map((image:any, i:number) => (
                    <img
                      src={image}
                      key={i}
                      style={{width: "95%", height: "80%"}}
                    />
                  ))}
                </Carousel>
              </div>
            </div>

            {tooltip.element === "images"
              ?
              <div
                data-testid="hint_container_images"
                className="hint_container_checklist images"
              >
                <div className="tooltip_checklist">
                  <span
                    className="tooltiptext">
                    {t("partner_portal.deals.create.main_form.example_container.tooltips.10")}
                  </span>
                </div>
                <div
                  className="hint_dot"
                  style={{backgroundColor: "#298693"}}
                />
              </div>
              : null
            }
          </div>

          <div className="top_section_text">

            {/*TITLE*/}

            <div
              data-testid="top_section_title"
              className={tooltip.element === "title"
                ? "example_title_container outlined"
                : "example_title_container"}>
              <h4 style={{padding: "5px"}}>
                {countryState === "at"
                  ? stateValues[2].title.length
                    ? stateValues[2].title
                    : t("partner_portal.deals.create.main_form.example_container.header_title")
                  : stateValues[2].titleDE.length
                    ? stateValues[2].titleDE
                    : t("partner_portal.deals.create.main_form.example_container.header_title")
                }
              </h4>
              {tooltip.element === "title"
                ?
                <div className="hint_container_checklist title">
                  <div className="tooltip_checklist">
                    <span
                      className="tooltiptext">
                      {t("partner_portal.deals.create.main_form.example_container.tooltips.4")}
                    </span>
                  </div>
                  <div
                    className="hint_dot"
                    style={{backgroundColor: "#FFAF42"}}
                  />
                </div>
                : null
              }
            </div>

            {/*LINKS*/}
            <div style={{
              display: "flex",
              gap: "10px",
              paddingRight: "40px"
            }}>
              <div className="local_example_title">
                <h4>{t("partner_portal.deals.create.main_form.example_container.local_title")}</h4>
                <p>{t("partner_portal.deals.create.main_form.example_container.local_li_one")}</p>
                <p>{t("partner_portal.deals.create.main_form.example_container.local_li_two")}</p>
                <p>{t("partner_portal.deals.create.main_form.example_container.local_li_three")}</p>
                <div className="mw_button icon"
                  style={{
                    maxWidth:"200px",
                    padding:"8px 16px",
                    borderRadius: "4px",
                    marginTop: "20px"
                  }}
                >
                  {t("partner_portal.deals.create.main_form.example_container.local_header_btn")}
                  <img src={linkImage} alt="download"
                    onClick={() => console.log(stateValues)}/>
                </div>
              </div>
              <div className="buttons_container" style={{padding: "8px 0"}}>
                <img src={memberCard} alt="memberCard"
                  style={{maxWidth:"100%", width:"200px"}}/>
              </div>
            </div>

            {/*COMPANY NAME*/}
            <div
              data-testid="top_section_comnpany_name"
              className={tooltip.element === "merchant"
                ? "example_title_container outlined merchant"
                : "example_title_container"}>
              <h4 style={{fontSize: "12px"}}>
                {countryState === "at"
                  ? stateValues[2].merchant.length
                    ?
                    <>
                      {textSplitter(stateValues[2].merchant)
                        .map((text:string, i:number) => (
                          <p className="highlights_item" key={i}>
                            {`${text}`}
                          </p>
                        ))}
                    </>
                    : t("partner_portal.deals.create.main_form.example_container.merchant_name")
                  : stateValues[2].merchantDE.length
                    ?
                    <>
                      {textSplitter(stateValues[2].merchantDE)
                        .map((text:string, i:number) => (
                          <p className="highlights_item" key={i}>
                            {`${text}`}
                          </p>
                        ))}
                    </>
                    : t("partner_portal.deals.create.main_form.example_container.merchant_name")
                }
              </h4>
              {tooltip.element === "merchant"
                ?
                <div className="hint_container_checklist merchant">
                  <div className="tooltip_checklist">
                    <span
                      className="tooltiptext">
                      {t("partner_portal.deals.create.main_form.example_container.tooltips.6")}

                    </span>
                  </div>
                  <div
                    className="hint_dot"
                    style={{backgroundColor: "#ED90C9"}}
                  />
                </div>
                : null
              }
            </div>

            {/*LOGO*/}
            <div
              data-testid="top_section_logo"
              className={tooltip.element === "logo"
                ? "example_title_container outlined logo"
                : "example_title_container"}
            >

              <div
                className="example_logo"
                style={{backgroundImage: `url(${logoImage})`}}
              />

              {tooltip.element === "logo"
                ?
                <div className="hint_container_checklist logo">
                  <div className="tooltip_checklist">
                    <span
                      className="tooltiptext">
                      {t("partner_portal.deals.create.main_form.example_container.tooltips.2")}
                    </span>
                  </div>
                  <div
                    className="hint_dot"
                    style={{backgroundColor: "#FFAF42"}}
                  />
                </div>
                : null
              }
            </div>
          </div>
        </div>

        <div className="container_underline full" />

        {/*CONDITIONS*/}
        <div
          data-testid="top_section_conditions"
          className={tooltip.element === "conditions"
            ? "example_title_container outlined conditions"
            : "example_title_container"}>
          <h3 className="example_title">
            {t("partner_portal.deals.create.main_form.example_container.condition_title")}
          </h3>
          <p className="example_text">
            <strong>
              {t("partner_portal.deals.create.main_form.example_container.condition_text.1")}
            </strong>
          </p>
          <p className="example_text">
            {t("partner_portal.deals.create.main_form.example_container.condition_text.local_2")}
          </p>
          <p className="example_text">
            {t("partner_portal.deals.create.main_form.example_container.condition_text.local_3")}
          </p>
          <p className="example_text">
            {t("partner_portal.deals.create.main_form.example_container.condition_text.local_4")}
          </p>
        </div>
        <div
          data-testid="top_section_additional_conditions"
          className={tooltip.element === "additionalConditions"
            ? "example_title_container outlined additionalConditions"
            : "example_title_container"}>
          <p className="example_text" style={{marginBottom: "0px"}}>
            <strong>{t("partner_portal.deals.create.main_form.example_container.condition_text.5")}</strong>
          </p>
          <div className="example_text">
            {countryState === "at"
              ? stateValues[2].additionalConditions.length
                ?
                <>
                  {textSplitter(stateValues[2].additionalConditions)
                    .map((text:string, i:number) => (
                      <p className="highlights_item" key={i}>
                        {`${text}`}
                      </p>
                    ))}
                </>
                : t("partner_portal.deals.create.main_form.example_container.condition_text.5_1")
              : stateValues[2].additionalConditionsDE.length
                ?
                <>
                  {textSplitter(stateValues[2].additionalConditionsDE)
                    .map((text:string, i:number) => (
                      <p className="highlights_item" key={i}>
                        {`${text}`}
                      </p>
                    ))}
                </>
                : t("partner_portal.deals.create.main_form.example_container.condition_text.5_1")
            }
          </div>
          {tooltip.element === "additionalConditions"
            ?
            <div className="hint_container_checklist additionalConditions">
              <div className="tooltip_checklist">
                <span
                  className="tooltiptext">
                  {t("partner_portal.deals.create.main_form.example_container.tooltips.5_1")}
                </span>
              </div>
              <div
                className="hint_dot"
                style={{backgroundColor: "#9588FF"}}
              />
            </div>
            : null
          }
        </div>

        <div className="container_underline full" />

        <h3 data-testid="highlights_title" className="example_title">
          {t("partner_portal.deals.create.main_form.example_container.highlights_title")}
        </h3>

        {/*DESCRIPTION*/}
        <div
          data-testid="top_container_description"
          className={tooltip.element === "description"
            ? "example_title_container outlined description"
            : "example_title_container"}>
          <p className="example_text">
            {countryState === "at"
              ? stateValues[2].description.length
                ? stateValues[2].description
                : t("partner_portal.deals.create.main_form.example_container.condition_text.6")
              : stateValues[2].descriptionDE.length
                ? stateValues[2].descriptionDE
                : t("partner_portal.deals.create.main_form.example_container.condition_text.6")
            }
          </p>
          {tooltip.element === "description"
            ?
            <div className="hint_container_checklist description">
              <div className="tooltip_checklist">
                <span
                  className="tooltiptext">
                  {t("partner_portal.deals.create.main_form.example_container.tooltips.7")}
                </span>
              </div>
              <div
                className="hint_dot"
                style={{backgroundColor: "#9DD663"}}
              />
            </div>
            : null
          }
        </div>

        {/*HIGHLIGHTS*/}
        <div
          data-testid="top_container_highlights"
          className={tooltip.element === "highlights"
            ? "example_title_container outlined highlights"
            : "example_title_container"}>
          <div>
            {countryState === "at"
              ? stateValues[2].highlights.length
                ?
                <>
                  {textSplitter(stateValues[2].highlights)
                    .map((text:string, i:number) => (
                      <p className="highlights_item" key={i}>
                        {`- ${text}`}
                      </p>
                    ))}
                </>
                :
                <>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                </>
              : stateValues[2].highlightsDE.length
                ?
                <>
                  {textSplitter(stateValues[2].highlightsDE)
                    .map((text:string, i:number) => (
                      <p className="highlights_item" key={i}>
                        {`- ${text}`}
                      </p>
                    ))}
                </>
                :
                <>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                  <p className="example_text">
                    {t("partner_portal.deals.create.main_form.example_container.condition_text.7")}
                  </p>
                </>
            }
          </div>
          {tooltip.element === "highlights"
            ?
            <div className="hint_container_checklist highlights">
              <div className="tooltip_checklist">
                <span
                  className="tooltiptext">
                  {t("partner_portal.deals.create.main_form.example_container.tooltips.8")}
                </span>
              </div>
              <div
                className="hint_dot"
                style={{backgroundColor: "#9588FF"}}
              />
            </div>
            : null
          }
        </div>

        {/*TEXT*/}
        <div
          data-testid="top_container_text"
          className={tooltip.element === "text"
            ? "example_title_container outlined text"
            : "example_title_container"}
          style={{marginTop: "30px"}}
        >
          <div>
            <p className="highlights_item">
              {countryState === "at"
                ? stateValues[2].text.length
                  ? stateValues[2].text
                  : t("partner_portal.deals.create.main_form.example_container.condition_text.8")
                : stateValues[2].textDE.length
                  ? stateValues[2].textDE
                  : t("partner_portal.deals.create.main_form.example_container.condition_text.8")
              }
            </p>
          </div>
          {tooltip.element === "text"
            ?
            <div className="hint_container_checklist text">
              <div className="tooltip_checklist">
                <span
                  className="tooltiptext">
                  {t("partner_portal.deals.create.main_form.example_container.tooltips.9")}
                </span>
              </div>
              <div
                className="hint_dot"
                style={{backgroundColor: "#ED90C9"}}
              />
            </div>
            : null
          }
        </div>

      </div>


      <div className="example_footer">
        <div className="example_footer_skeleton" style={{width: "80%"}} />
        <div className="example_footer_skeleton" style={{width: "90%"}} />
        <div className="example_footer_skeleton" style={{width: "50%"}} />
        <div className="example_footer_skeleton" style={{width: "40%"}} />
        <div className="example_footer_skeleton" style={{width: "90%"}} />
        <div className="example_footer_skeleton" style={{width: "60%"}} />
      </div>

    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    tooltip: state.tooltip,
    stateValues: state.steps.values
  };
};

export default connect(mapStateToProps, null)(LocalExamplePage);
