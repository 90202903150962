import React from "react";
import "./Footer.css";
import {useTranslation} from "react-i18next";

interface CustomProps {
	type: number
}

export default function ChecklistFooter({type}:CustomProps) {
  const { t } = useTranslation();

  // Render text elements for footer depends on checklist types
  const renderFooterText = () => {
    switch (type) {
    case 0:
      return <h4>
        {t("partner_portal.deals.create.footer.text.1")}
        <a href = "mailto:partner@corplife.at">
          <strong>
            {t("partner_portal.deals.create.footer.text.2")}
          </strong>
        </a>
        {t("partner_portal.deals.create.footer.text.3")}
        <strong data-testid="strong_type_0">
          {t("partner_portal.deals.create.footer.text.4")}
        </strong>
      </h4>;
    case 1:
      return <h4>
        {t("partner_portal.deals.create.footer.text.1")}
        <a href = "mailto:partner@corplife.at">
          <strong>
            {t("partner_portal.deals.create.footer.text.5")}
          </strong>
        </a>
      </h4>;
    case 2:
      return <h4>
        {t("partner_portal.deals.create.footer.text.1")}
        <a href = "mailto:partner@corplife.at">
          <strong>
            {t("partner_portal.deals.create.footer.text.5")}
          </strong>
        </a>
        {t("partner_portal.deals.create.footer.text.3")}
        <strong>
          {t("partner_portal.deals.create.footer.text.4")}
        </strong>
      </h4>;
    case 5:
      return <h4>
        {t("partner_portal.deals.create.footer.text.1")}
        <a href = "mailto:partner@corplife.at">
          <strong>
            {t("partner_portal.deals.create.footer.text.6")}
          </strong>
        </a>
      </h4>;
    default:
      return <h4>
        {t("partner_portal.deals.create.footer.text.1")}
        <a href = "mailto:partner@corplife.at">
          <strong>
            {t("partner_portal.deals.create.footer.text.2")}
          </strong>
        </a>
        {t("partner_portal.deals.create.footer.text.3")}
        <strong>
          {t("partner_portal.deals.create.footer.text.4")}
        </strong>
      </h4>;
    }
  };

  return (
    <footer>
      {renderFooterText()}
    </footer>
  );
}
