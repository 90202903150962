import React from "react";
import LunchImage from "img/Dashboard/dash_side_products_essen.png";
import activeIcon from "img/Dashboard/PlansAndModules/Active_icon.png";
import Spacer from "../SimpleComponents/Spacer";
import girlImage from "img/Dashboard/PlansAndModules/plans_success_image.png";
import Button from "../Buttons/Button";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";

interface CustomProps {
	sum: number
}

export default function PlansSuccess({sum}:CustomProps) {
  const navigate = useNavigate();
  const { t } = useTranslation();

  return (
    <div className="plans_container">
      <div className="plans_container_header space-between shadow">
        <div className="row">
          <img data-testid="plans_lunch_image" src={LunchImage} alt="corplife_plan"/>
          <h4 style={{paddingLeft:"15px"}}>Corplife Lunch</h4>
        </div>
        <div
          style={{cursor:"pointer"}}
          className="row"
        >
          <img
            src={activeIcon}
            alt="close_plans"/>
          <p className="plans_close">
            {t("dashboard.plans.plan_active")}
          </p>
        </div>
      </div>
      <div
        className="plans_container_main shadow center">
        <img
          src={girlImage}
          className="plans_success_img"
          alt="girl"
        />
        <div className="plans_success_wrapper">
          <div className="row" style={{position:"relative"}}>
            <img
              className="active_icon_plans_submit"
              style={{position:"absolute", left:"-60px", top:"20px"}}
              src={activeIcon} alt="active_icon"/>
            <h2 className="plans_success_title">
              {t("dashboard.plans.success-title")}
            </h2>
          </div>
          <p>
            {t("dashboard.plans.success-text")}
          </p>
          <Spacer spacerHeight="30px" />
          <h3>{t("dashboard.plans.success_summary")}</h3>
          <div className="plans_success_box">
            <p>Corplife Lunch</p>
            <strong>€ {sum.toFixed(2)}</strong>
          </div>
          <div className="summary_total">
            <h2 data-testid="plans_summary_total">{t("dashboard.plans.total")}</h2>
            <h2 data-testid="plans_summary_total_amount">{sum.toFixed(2)}</h2>
          </div>
          <p
            style={{marginBottom:"20px"}}
            className="flex-end">
            {t("dashboard.plans.summary_total_notif")}
          </p>
          <div className="flex-end">
            <Button
              buttonType="primary"
              buttonLabel={t("dashboard.plans.success_to_plans")}
              buttonHandler={() => navigate("/plans")}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
