// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.tooltip_container {
    position: relative;
	display: inline-flex
}

.tooltip_box {
    position: absolute;
    padding: 5px;
    background-color: rgba(0,0,0,0.7);
    border-radius: 5px;
    max-width: 200px;
    min-width: 70px;
}

.tooltip_box > p {
    font-size: 10px;
    color: white;
}

.arrow-up {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba(0,0,0,0.7);
    position: absolute;
    top: -5px;
    left: calc(50% - 5px);
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid rgba(0,0,0,0.7);
    position: absolute;
    bottom: -5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Tooltip/Tooltip.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;CACrB;AACD;;AAEA;IACI,kBAAkB;IAClB,YAAY;IACZ,iCAAiC;IACjC,kBAAkB;IAClB,gBAAgB;IAChB,eAAe;AACnB;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,kCAAkC;IAClC,mCAAmC;IACnC,wCAAwC;IACxC,kBAAkB;IAClB,SAAS;IACT,qBAAqB;AACzB;;AAEA;IACI,QAAQ;IACR,SAAS;IACT,kCAAkC;IAClC,mCAAmC;IACnC,qCAAqC;IACrC,kBAAkB;IAClB,YAAY;AAChB","sourcesContent":[".tooltip_container {\n    position: relative;\n\tdisplay: inline-flex\n}\n\n.tooltip_box {\n    position: absolute;\n    padding: 5px;\n    background-color: rgba(0,0,0,0.7);\n    border-radius: 5px;\n    max-width: 200px;\n    min-width: 70px;\n}\n\n.tooltip_box > p {\n    font-size: 10px;\n    color: white;\n}\n\n.arrow-up {\n    width: 0;\n    height: 0;\n    border-left: 5px solid transparent;\n    border-right: 5px solid transparent;\n    border-bottom: 5px solid rgba(0,0,0,0.7);\n    position: absolute;\n    top: -5px;\n    left: calc(50% - 5px);\n}\n\n.arrow-down {\n    width: 0;\n    height: 0;\n    border-left: 5px solid transparent;\n    border-right: 5px solid transparent;\n    border-top: 5px solid rgba(0,0,0,0.7);\n    position: absolute;\n    bottom: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
