// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashboard_form_success_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dashboard_form_success_container > h4 {
  font-size: 26px;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.dashboard_form_success_img {
  width: 100px;
}

.dashboard_form_success_container > p {
  width: 300px;
  text-align: center;
  margin-top: 20px;
  line-height: 30px;
  color: #4A4A4A;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Containers/Popup.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,YAAY;EACZ,kBAAkB;EAClB,gBAAgB;EAChB,iBAAiB;EACjB,cAAc;AAChB","sourcesContent":[".dashboard_form_success_container {\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  position: relative;\n}\n\n.dashboard_form_success_container > h4 {\n  font-size: 26px;\n  font-weight: bold;\n  margin-top: 20px;\n  margin-bottom: 20px;\n}\n\n.dashboard_form_success_img {\n  width: 100px;\n}\n\n.dashboard_form_success_container > p {\n  width: 300px;\n  text-align: center;\n  margin-top: 20px;\n  line-height: 30px;\n  color: #4A4A4A;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
