import "./style.css";

import SettingsIcon from "img/Dashboard/permission_settings.svg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";

import Button from "../../../Components/Buttons/Button";
import TableSortedHeadItem from "../../../Components/Lists/TableSortedHeadItem";
import GeneralInfoLoading from "../../../Components/LoadingPlaceholders/GeneralInfoPageLoading";
import PermissionsListModal from "../../../Components/Modals/PermissionsListModal";
import PageTitle from "../../../Components/SimpleComponents/PageTitle";
import Pagination from "../../../Components/SimpleComponents/Pagination";
import PermissionsListMenu from "../../../Components/SimpleComponents/PermissionsListMenu";
import RolesListAvatar from "../../../Components/SimpleComponents/RolesListAvatar";
import SearchBarLight from "../../../Components/SimpleComponents/SearchBarLight";
import Spacer from "../../../Components/SimpleComponents/Spacer";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import {
  clearRoleMessages,
  getUsersWithRoles,
} from "../../../redux/actions/rolesActions";
import { RootState } from "../../../redux/reducers/rootReducer";
import {
  isAllowed,
  userPermissions,
  userRoles,
} from "../../../utils/permissionsAllow";
import {modalStyles} from "../../../utils/modalStyles";
import ActionDotsRoles from "../../../Components/SimpleComponents/ActionsDotsRoles";

interface CustomProps {
  users?: any;
  rolesState?: any;
}

function PermissionsTab({ users, rolesState }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (users.users) {
      setLoading(false);
    }
  }, [users]);

  useEffect(() => {
    dispatch(
      getUsersWithRoles({
        limit: 5,
        skip: 0,
      })
    );
  }, [dispatch]);

  interface Lookup {
    [index: string]: string;
  }
  const [showModal, setShowModal] = useState(false);

  // Search handlers
  const [searchValue, setSearchValue] = useState("");
  const handleSearchbar = (value: string) => {
    setSearchValue(value);
    dispatch(
      getUsersWithRoles({
        limit: 5,
        skip: 0,
        search: value,
      })
    );
  };

  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    firstName: "up",
    role: "up",
  });

  // Sort table
  const sortingData = (name: string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortValue === "up" ? "" : "-"}${name}`;
    dispatch(
      getUsersWithRoles({
        limit: 5,
        skip: 0,
        search: searchValue,
        sortBy: postValue,
      })
    );
    setSortButton({ ...sortButton, [name]: sortValue });
  };

  //Save skip for refresh page after update user role
  const [skipParams, setSkipParams] = useState(0);

  // handle Paginate
  const paginate = (pageNumber: number) => {
    const skip = (pageNumber - 1) * 5;
    setSkipParams(skip);
    dispatch(
      getUsersWithRoles({
        limit: 5,
        skip: skip,
        search: searchValue,
      })
    );
  };

  // Get update messages
  useEffect(() => {
    if (rolesState.success === "add_role") {
      dispatch(clearRoleMessages());
      dispatch(
        getUsersWithRoles({
          limit: 5,
          skip: skipParams,
        })
      );
      toast.success(
        <CustomToastContainer
          message={t("warnings.role_added_success")}
          status={1}
        />,
        {
          autoClose: 5000,
        }
      );
    } else if (rolesState.error === "add_role") {
      dispatch(clearRoleMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.role_added_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    } else if (rolesState.success === "update_role") {
      dispatch(clearRoleMessages());
      toast.success(
        <CustomToastContainer message={t("warnings.change_roles")} status={1} />,
        {
          autoClose: 5000,
        }
      );
      dispatch(
        getUsersWithRoles({
          limit: 5,
          skip: skipParams,
        })
      );
    } else if (rolesState.error === "update_role") {
      dispatch(clearRoleMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.role_added_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
    }
    // disable re renders
    // eslint-disable-next-line
  }, [dispatch, rolesState]);

  return (
    <div className="settings_tab">
      {loading ? (
        <GeneralInfoLoading />
      ) : (
        <>
          <Modal style={modalStyles} isOpen={showModal}>
            <PermissionsListModal closeModal={() => setShowModal(false)} />
          </Modal>
          <div className="space-between_perm">
            <div>
              <PageTitle mainTitle={t("dashboard.settings.page_title")} />
            </div>
            {isAllowed({
              data: {
                roles: [
                  userRoles.superAdmin,
                  userRoles.admin,
                  userRoles.custom,
                ],
                permissions: {
                  isAdmin: true,
                  permissionsArray: [userPermissions.settings],
                },
              },
            }) ? (
                <Button
                  buttonStyles={{ fontSize: "12px", marginBottom: "1rem"}}
                  buttonType="primary"
                  buttonLabel={t("dashboard.permissions_tab.add_btn")}
                  buttonHandler={() => setShowModal(true)}
                />
              ) : null}
          </div>
          <div className="flex-end">
            <SearchBarLight
              searchPlaceholder={t("dashboard.settings.search_placeholder")}
              handleSearchBar={(val: string) => handleSearchbar(val)}
            />
          </div>
          <div className="perm_table_box" style={{ marginTop: "1rem" }}>
            <table className="full-width page-table perm_table">
              <thead>
                <tr>
                  <th>
                    <div className="row">
                      <Spacer spacerWidth="1rem" />
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={t("dashboard.settings.th_1")}
                        name="firstName"
                        sortButton={sortButton}
                      />
                    </div>
                  </th>
                  <th>{t("dashboard.settings.th_2")}</th>
                  <th style={{textAlign: "center"}}>{t("dashboard.settings.list.headers.action")}</th>
                </tr>
              </thead>
              <tbody className="bg_white">
                {users.users.map((user: any) => (
                  <React.Fragment key={user.user._id}>
                    <tr className="spacer" />
                    <tr className="table-item">
                      <td style={{ width: "30%" }} className="permission_row">
                        <div className="row">
                          <RolesListAvatar userImg={user.user.imageUrl} />
                          <div>
                            <p className="permission_card_title">
                              {user.user.firstName + " " + user.user.lastName}
                            </p>
                            {user.user.customIdentifier
                              ? <p style={{fontSize: "10px"}}>{user.user.customIdentifier}</p>
                              : null
                            }
                            <p
                              style={{ marginRight: "10px" }}
                              className="permission_card_text"
                            >
                              {user.user.email}
                            </p>
                          </div>
                        </div>
                      </td>
                      <td className="permission_row">
                        <div className="permission_top_row">
                          <PermissionsListMenu
                            user={user}
                            type={user.role.type}
                            role={user.role.role}
                            roleId={user.role._id}
                          />
                        </div>
                      </td>
                      <td className="permission_row">
                        <ActionDotsRoles
                          style={{margin: "auto"}}
                          data={user}
                          updateData={() => {
                            dispatch(
                              getUsersWithRoles({
                                limit: 5,
                                skip: 0,
                              })
                            );
                          }}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
          <Pagination
            postsPerPage={5}
            totalPosts={users && users.totalUser}
            paginate={paginate}
          />
          {isAllowed({
            data: {
              roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.settings],
              },
            },
          })
            ? <div className="permission_table_footer">
              <p className="permission_footer_text">
                {t("dashboard.settings.footer_text_1")}
                <span>
                  {
                    <img
                      style={{ marginBottom: "-3px" }}
                      src={SettingsIcon}
                      alt="setting"
                    />
                  }
                </span>
                <span>{t("dashboard.settings.footer_text_2")}</span>
              </p>
            </div>
            : null
          }
        </>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    users: state.roles.usersWithRoles,
    rolesState: state.roles,
  };
};

export default connect(mapStateToProps, null)(PermissionsTab);
