import React from "react";
import LoadingText from "../../LoadingPlaceholders/LoadingText";
import {rewriteCurrency} from "../../../utils/rewriteCurrency";
import "./InfoCard.css";
import arrowIcon from "../../../img/components/containers/infoCard/arrow.png";

export enum InfoCardNumberTypeEnum {
  number = "number",
  amount = "amount",
  percentage = "percentage"
}

interface IInfoCard {
  title: string;
  number: number | undefined | null;
  numberType: InfoCardNumberTypeEnum.number | InfoCardNumberTypeEnum.amount | InfoCardNumberTypeEnum.percentage
  icon: string;
  iconWidth?: string;
  iconHeight?: string;
  link?: {
    onClick: () => void;
    linkText: string;
  }
}

export default function InfoCard(props: IInfoCard) {

  const transformAmount = () => {
    if (props.number || props.number === 0) {
      switch (props.numberType) {
      case InfoCardNumberTypeEnum.amount:
        return rewriteCurrency(Number(props.number.toFixed(2)), "EUR");
      case InfoCardNumberTypeEnum.number:
        return props.number;
      case InfoCardNumberTypeEnum.percentage:
        return `${props.number} %`;
      }
    }
  };

  return(
    <div
      className="infoCard"
    >

      {/*Show link text if provided*/}
      {props.link
        &&
        <div
          className="infoCard_link"
          onClick={() => props.link?.onClick()}
        >
          <p>{props.link.linkText}</p>
          <img src={arrowIcon} alt="arrow"/>
        </div>
      }

      <div className="infoCard_image_container">
        <img
          src={props.icon}
          alt={`icon_${props.title}`}
          style={{
            width: props.iconWidth,
            height: props.iconHeight
          }}
        />
      </div>

      <div className="infoCard_content">

        <h6>
          {props.title}
        </h6>

        {props.number === undefined
          ? <LoadingText
            height="40px"
            width="30%"
            borderRadius="5px"
            opacity="opacity(0.5)"
          />
          : <h5>
            {transformAmount()}
          </h5>
        }

      </div>

    </div>
  );
}
