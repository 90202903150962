// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.employeePaymentPage_not_activated_container {}


.employeePaymentPage_password-field {
	background: #fff;
	width: -moz-fit-content;
	width: fit-content;
	max-width: 250px;
	width: 100%;
	display: flex;
	justify-content: center;
	border-radius: 4px;
	margin: 8px 0;
	padding: 6px 8px;
	position: relative;

}`, "",{"version":3,"sources":["webpack://./src/pages/employeePayments/employeePaymentsPage.css"],"names":[],"mappings":"AAAA,8CAA8C;;;AAG9C;CACC,gBAAgB;CAChB,uBAAkB;CAAlB,kBAAkB;CAClB,gBAAgB;CAChB,WAAW;CACX,aAAa;CACb,uBAAuB;CACvB,kBAAkB;CAClB,aAAa;CACb,gBAAgB;CAChB,kBAAkB;;AAEnB","sourcesContent":[".employeePaymentPage_not_activated_container {}\n\n\n.employeePaymentPage_password-field {\n\tbackground: #fff;\n\twidth: fit-content;\n\tmax-width: 250px;\n\twidth: 100%;\n\tdisplay: flex;\n\tjustify-content: center;\n\tborder-radius: 4px;\n\tmargin: 8px 0;\n\tpadding: 6px 8px;\n\tposition: relative;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
