import { urls } from "api/urls";
import { Dispatch } from "redux";
import { reduxRequestHandler } from "services/reduxRequestHandler";
import { GET_HRZONEAUTH_SUCCESS,GET_HRZONEAUTH_ERROR, CLEAR_HRZONEAUTH } from "redux/types/hrZoneAuthTypes";

export const fetchHrZoneAuth =
  (hr_email: any) => async (dispatch: Dispatch<any>) => {
    const dynamicParams = {
	  hr_email: hr_email
    };

    await new reduxRequestHandler({
      url: urls.hrZone.base + "preflight",
      dynamicParams,
      successDispatchType: GET_HRZONEAUTH_SUCCESS,
      errorDispatchType: GET_HRZONEAUTH_ERROR,
      errorPayload: "GET_HRZONEAUTH_ERROR"
    }).postHrZone(dispatch);
  };


export function clearHrZoneAuth() {
  return {
	  type: CLEAR_HRZONEAUTH
  };
}
