import React, {useState} from "react";
import "./Table.css";
import TableLoading from "../../LoadingPlaceholders/TableLoading";
import Pagination from "../../SimpleComponents/Pagination";
import EmptyTableState from "../../SimpleComponents/TableEmptyState";

export interface ITableHeader {
  id: string;
  name: string;
  render?: (value: any) => JSX.Element;
}

interface ITable {
  headers: ITableHeader[];
  data: any[];
  paginate: (page: number) => void;
  totalData: number;
  dataIdentifier?: string;
  loading?: boolean;
  pageLimit?: number;
}

export default function Table(props: ITable) {

  const [currentPage, setCurrentPage] = useState(1);

  const getValueFromPath = (item: any, path: string) => {
    // Teilen Sie den Pfad in ein Array von Schlüsseln auf
    const keysArray = path.split(".");

    // Initialisieren Sie das aktuelle Objekt mit dem übergebenen Objekt
    let actualItem = item;

    // Durchlaufen Sie das Array der Schlüssel
    for (const key of keysArray) {
      // Überprüfen Sie, ob der aktuelle Schlüssel im aktuellen Objekt vorhanden ist
      if (Object.prototype.hasOwnProperty.call(actualItem, key)) {
        // Aktualisieren Sie das aktuelle Objekt auf das Unterelement
        actualItem = actualItem[key];
      } else {
        // Wenn der Schlüssel nicht im Objekt vorhanden ist, geben Sie undefined zurück
        return undefined;
      }
    }

    // Gib den gefundenen Wert zurück
    return actualItem;
  };

  return(
    <div style={{width: "100%", position: "relative"}}>
      {
        props.loading
          ? <TableLoading />
          : props.data.length
            ? <div className="table_box">
              <div className="table_wrapper-overview">
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      {props.headers.map((header, idx) => (
                        <th key={idx.toString()}>
                          {header.name}
                        </th>
                      ))}
                    </tr>
                  </thead>
                  <tbody>
                    {props.data.map((item: any) => (
                      <tr key={item[props.dataIdentifier || "_id"]} className="table-item">
                        {props.headers.map((header) => (
                          <td key={header.id}>{
                            header.render
                              ? header.render(getValueFromPath(item, header.id))
                              : getValueFromPath(item, header.id)
                          }</td>
                        ))}
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            : <EmptyTableState />
      }
      <Pagination
        currentPage={currentPage}
        postsPerPage={props.pageLimit || 8}
        totalPosts={props.totalData}
        paginate={(newPage: number) => {
          //Pagination for wire transactions
          if(currentPage !== newPage){
            setCurrentPage(newPage);
            props.paginate(newPage);
          }
        }}
      />
    </div>
  );
}
