import React from "react";
import "./Checkbox.css";

interface CustomInputProps {
  id: string;
  text?: string;
  checked?: boolean;
  handleCheckboxChange: (checked: boolean, id: string) => void;
  disabled?: boolean;
  style?: React.CSSProperties;
}

// eslint-disable-next-line max-len
export default function Checkbox({
  id,
  text,
  handleCheckboxChange,
  checked,
  disabled,
  style
}: CustomInputProps) {
  const handleCheckbox = (e: any) => {
    e.preventDefault();
    handleCheckboxChange(!checked, id);
  };
  return (
    <div
      onClick={(e: any) => {
        if (!disabled) {
          handleCheckbox(e);
        }
      }}
      style={style}
    >
      <input
        disabled={disabled}
        className={
          checked ? "checkbox_unchecked checkbox_checked" : "checkbox_unchecked"
        }
        id={id}
        type="checkbox"
      />
      <label className="remember-text" htmlFor={id}>
        {text}
      </label>
    </div>
  );
}
