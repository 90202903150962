import React from "react";
import "./DashboardContainer.css";

const DashboardContainer = (props?: any) => {

  return(
    <div className={props.type 
      ? props.type + " secondary-container"
      : "secondary-container" }>
      {props.children}
    </div>
  );
};

export default DashboardContainer;
