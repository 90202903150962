import React from "react";
import "./LoadingPlaceholders.css";
import {uniqueIdGenerator} from "../../utils/uniqueIdGenerator";
import LoadingText from "./LoadingText";

interface CustomProps {
  rowsNumber?: number;
}

export default function TableLoading({rowsNumber}:CustomProps) {
  return(
    <div className="table_loading">
      <table style={{width:"100%"}}>
        <thead>
          <tr>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="50%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="100%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="70%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="70%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="100%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="100%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="70%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
            <th style={{paddingLeft: "30px"}}>
              <LoadingText
                height="30px"
                width="40%"
                borderRadius="5px"
                opacity="opacity(0.5)"
              />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from(Array(rowsNumber ? rowsNumber : 8).keys()).map((item:any) => (
            <React.Fragment key={uniqueIdGenerator("table_loading")}>
              <tr className="spacer" />
              <tr className="table-item">
                <td>
                  <LoadingText
                    height="30px"
                    width="50%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="100%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="70%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="70%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="100%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="100%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="70%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
                <td>
                  <LoadingText
                    height="30px"
                    width="40%"
                    borderRadius="5px"
                    opacity="opacity(0.3)"
                  />
                </td>
              </tr>
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
}
