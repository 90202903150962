import React, {useEffect, useRef, useState} from "react";
import SettingsIcon from "../../img/Dashboard/permission_settings.svg";
import PermissionSelect from "./PermissionSelect";
import {connect, useDispatch} from "react-redux";
import {RootState} from "../../redux/reducers/rootReducer";
import infoIcon from "img/Dashboard/info_black_48dp.svg";
import {isAllowed, userPermissions, userRoles} from "../../utils/permissionsAllow";
import {useTranslation} from "react-i18next";
import {motion} from "framer-motion";
import {getRoles, updateRole} from "../../redux/actions/rolesActions";
import PaymentAuthenticationPopup from "../Payments/PaymentAuthenticationPopup/PaymentAuthenticationPopup";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import {getToken} from "../../utils/cookies/tokensCookie";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";

interface CustomProps {
	type: number,
	roles?: any,
	role: string,
	user: any,
	roleId: string;
  rootUser?: any;
}

function PermissionsListMenu(
  {type, roles, role, user, roleId, rootUser}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const lunchSettings = new getLunchSettings();

  const [showWeavrLoginPopup, setShowWeavrLoginPopup] = useState(false);
  // Custom settings values
  const [customSettings, setCustomSettings] = useState([]);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    dispatch(getRoles());
  }, [dispatch]);

  let title = "";
  let text = "";
  switch (type) {
  case 1:
    title = t("dashboard.settings.permissions.title_1");
    text = t("dashboard.settings.permissions.text_1");
    break;
  case 2:
    title = t("dashboard.settings.permissions.title_2");
    text = t("dashboard.settings.permissions.text_2");
    break;
  case 3:
    title = t("dashboard.settings.permissions.title_3");
    text = t("dashboard.settings.permissions.text_3");
    break;

  default:
    title = "Owner";
  }

  // Detect click outside for close menu
  const PermissionMenuRef = useRef<HTMLDivElement>(null);
  const PermissionSelectRef = useRef<any>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!PermissionMenuRef.current?.contains(event.target as Node)) {
        setShowSelect(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const [showSelect, setShowSelect] = useState(false);

  const [selectOptions, setSelectOptions] = useState([
    t("dashboard.settings.role_viewer"),
    t("dashboard.settings.role_admin"),
    t("dashboard.settings.role_custom")
  ]);

  useEffect(() => {
    const newOptions:any = [];
    if (roles.roles && roles.roles.length) {
      roles.roles.forEach((roleItem:any) => {
        let name;
        if (roleItem.role === "Admin") {
          name = t("dashboard.settings.role_admin");
        } else if (roleItem.role === "Viewer") {
          name = t("dashboard.settings.role_viewer");
        } else if (roleItem.role === "Custom"){
          name = t("dashboard.settings.role_custom");
        } else {
          name = roleItem.role;
        }
        newOptions.push(name);
      });
    }
    setSelectOptions(newOptions);
  }, [roles]);


  const handleShowSelect = () => {
    if (isAllowed({data: {
      roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
      permissions: {
        isAdmin: true,
        permissionsArray: [userPermissions.settings]
      }
    }})) {
      setShowSelect(!showSelect);
    }
  };

  const handleSelect = (selectedItem: string) => {
    setSelectedItem(selectedItem);
    setShowSelect(false);
    const userId = user.user._id;
    let createdRole:any;
    if (selectedItem === t("dashboard.settings.role_admin")) {
      createdRole = "Admin";
    } else if (selectedItem === t("dashboard.settings.role_viewer")) {
      createdRole = "Viewer";
    } else if (selectedItem === t("dashboard.settings.role_custom")){
      createdRole = "Custom";
    } else {
      createdRole = selectedItem;
    }
    let type:any = "";
    roles.roles.forEach((selectedRole:any) => {
      if (selectedRole.role === createdRole) {
        type = selectedRole.type;
      }
    });
    const weavrToken = getToken(true);
    const postObj = {
      companyId: getUserCompany() || "",
      userId: userId,
      type: type,
      role: createdRole,
      token: weavrToken
    };
    if (selectedItem === t("dashboard.settings.role_custom")) {
      Object.assign(postObj, {permissions: customSettings});
    }
    if (lunchSettings.isVirtualCard() && createdRole === "Admin" && !weavrToken) {
      setShowWeavrLoginPopup(true);
    } else {
      dispatch(updateRole(postObj, roleId));
    }
  };

  return (
    <>
      <PaymentAuthenticationPopup
        showModal={showWeavrLoginPopup}
        onModalChange={(state: boolean) => setShowWeavrLoginPopup(state)}
        email={rootUser.user.email}
        onLoginSuccess={() => {
          handleSelect(selectedItem);
        }}
        onCloseModal={() => {
          setShowWeavrLoginPopup(false);
        }}
      />
      {
        user.user.email === "it@corplife.at"
        || user.user.email === "product@corplife.at"
          ? null
          :
          <div className="permission_menu_box" ref={PermissionMenuRef}>
            {isAllowed({
              data: {
                roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
                permissions: {
                  isAdmin: true,
                  permissionsArray: [userPermissions.settings],
                },
              },
            })
              ? <motion.img
                animate={{ x: -10 ,rotate:240 }}
                transition={{duration:0.3}}
                className="permission_settings_icon"
                onClick={() => handleShowSelect()}
                src={SettingsIcon}
                alt="change_permission"
              />
              : null
            }
            <div className="tooltip">
              <img
                className="permission_info_icon"
                src={infoIcon}
                alt="info"/>
              <div className="tooltiptext">
                <p
                  style={{
                    fontWeight: "bolder",
                    marginBottom: "5px",
                    fontSize: "8px"
                  }}>
                  {title}
                </p>
                {text}
              </div>
            </div>

            {showSelect
              ?
              <div className="permissions_select_box">
                <PermissionSelect
                  closeSelect={() => setShowSelect(false)}
                  user={user}
                  allRoles={roles}
                  role={role}
                  options={selectOptions}
                  roleId={roleId}
                  showLoginPopup={() => setShowWeavrLoginPopup(true)}
                  customSettings={customSettings}
                  handleSelect={handleSelect}
                  setCustomSettings={(settings: any) => setCustomSettings(settings)}
                />
              </div>
              : null}
            <p
              className={showSelect
                ? "permission_card_title_hide"
                : "permission_card_title"}>
              {title}
            </p>
            <p
              className={showSelect
                ? "permission_card_text_hide"
                : "permission_card_text"}
            >
              {text}
            </p>
          </div>
      }
    </>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    roles: state.roles.roles,
    rootUser: state.users.userInfo
  };
};

export default connect(mapStateToProps, null)(PermissionsListMenu);
