import i18n from "../../i18next";
import {BadgeColorsEnum} from "../../Components/Elements/Badge/Badge";

export enum TransactionCategoryEnum {
  Restaurant = "1",
  Supermarket = "2",
  Deposit = "deposit",
  OutgoingWireTransfer = "outgoing_wire_transfer",
  Settlement = "SETTLEMENT",
  Authorisations = "AUTHORISATION",
  Completed = "COMPLETED",
  Pending = "PENDING",
  NotAllowed = "not_allowed",
  AuthorisationDecline = "AUTHORISATION_DECLINE",
  OUTGOING_WIRE_TRANSFER = "OUTGOING_WIRE_TRANSFER",
}

export function getTransactionsCategoryName(category: TransactionCategoryEnum) {
  return i18n.t(`dashboard.payments.overview.header_component.tabs_content.transaction_history.category_names.${category}`)
}

export function getTransactionsCategoryColor(category: TransactionCategoryEnum): BadgeColorsEnum {
  switch (category) {
  case "1":
    return BadgeColorsEnum.blue;
  case "2":
    return BadgeColorsEnum.yellow;
  case "deposit":
    return BadgeColorsEnum.green;
  case "AUTHORISATION":
    return BadgeColorsEnum.yellow;
  case "SETTLEMENT":
    return BadgeColorsEnum.green;
  case "PENDING":
    return BadgeColorsEnum.yellow;
  default:
    return BadgeColorsEnum.red;
  }
};
