import {
  CLEAR_MARKETING_MESSAGES,
  POST_MARKETING_ERROR,
  POST_MARKETING_SUCCESS,
  postMarketingDispatchTypes
} from "../types/postMarketing";

interface DefaultStateI {
	error: string,
	success: string
}

const initialState:DefaultStateI = {
  error: "",
  success: ""
};

export const postMarketingReducer = (
  state: DefaultStateI = initialState,
  action: postMarketingDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case POST_MARKETING_SUCCESS:
    return {
      error: "",
      success: "Your request was successfully sent"
    };
  case POST_MARKETING_ERROR:
    return {
      error: action.payload,
      success: ""
    };
  case CLEAR_MARKETING_MESSAGES:
    return {
      error: "",
      success: ""
    };
  default:
    return state;
  }
};
