import environment from "../../../environment";
import {urls} from "../../urls";
import axios from "axios";
import {getToken} from "../../../utils/cookies/tokensCookie";
import {
  IGetPartnerPortalDealsParams, IGetPartnerPortalDealsResponse,
  IGetPartnerPortalProfileResponse,
  IUpdatePartnerPortalProfileRequestData,
  IUpdatePartnerPortalProfileResponse
} from "./partner-portal.interface";
import {getMagentoData} from "../../../utils/cookies/userCookies/userCookies";

export class partnerPortalService {
  constructor() {}

  // Profile
  async getProfile(): Promise<IGetPartnerPortalProfileResponse> {
    const magentoData = getMagentoData();
    const fullUrl = `${environment.baseUrl}${urls.partnerPortal.getProfile}`;
    const userToken = await getToken();
    return await axios.get(
      fullUrl,
      {
        params: {magentoToken: magentoData.token},
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

  async updateProfile(data: IUpdatePartnerPortalProfileRequestData): Promise<IUpdatePartnerPortalProfileResponse> {
    const magentoData = getMagentoData();
    const fullUrl = `${environment.baseUrl}${urls.partnerPortal.updateProfile}`;
    const userToken = await getToken();
    return await axios.put(
      fullUrl,
      data, {
        params: {magentoToken: magentoData.token},
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

  // Deals
  async getDeals(params: IGetPartnerPortalDealsParams): Promise<IGetPartnerPortalDealsResponse> {
    const magentoData = getMagentoData();
    const fullUrl = `${environment.baseUrl}${urls.partnerPortal.getDeals}`;
    const userToken = await getToken();
    return await axios.get(
      fullUrl,
      {
        params: {
          magentoToken: magentoData.token,
          limit: params.limit,
          skip: params.skip
        },
        headers: { Authorization: `Bearer ${userToken}` }
      })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

}
