import React from "react";
import "./styles.css";
import {modalStyles} from "../../../utils/modalStyles";
import Modal from "react-modal";
import {useTranslation} from "react-i18next";
import Button from "../../Buttons/Button";

interface IProps {
  confirmNavigation: any;
  cancelNavigation: any;
}

export default function LeavingPageModal(props:IProps) {
  const { t } = useTranslation();

  return (
    <Modal
      style={modalStyles}
      isOpen={true}
    >
      <div className="leaving_page_modal">
        <h3>{t("dashboard.plans.leaving_page_modal.title")}</h3>
        <p>{t("dashboard.plans.leaving_page_modal.text")}</p>
        <div>
          <Button
            buttonType={"primary"}
            buttonLabel={t("dashboard.plans.leaving_page_modal.submit_btn")}
            buttonHandler={() => props.confirmNavigation()}
          />
          <Button
            buttonType={"secondary"}
            buttonLabel={t("dashboard.plans.leaving_page_modal.cancel_btn")}
            buttonHandler={() => props.cancelNavigation()}
          />
        </div>
      </div>

    </Modal>
  );
}
