import ContentContainer from "Components/Containers/ContentContainer";
import React, {useEffect, useRef, useState} from "react";
import "./style.css";
import MainSpace from "../../Components/Containers/MainSpace";
import PageTitle from "../../Components/SimpleComponents/PageTitle";
import {useTranslation} from "react-i18next";
import CompanySettingsSwitcher from "../../Components/Elements/CompanySettingsSwitcher/CompanySettingsSwitcher";
import {useFormik} from "formik";
import * as Yup from "yup";
import validatePhoneNumber from "../../utils/phoneNumberValidation";
import { IGeneralInfoForm } from "pages/general_info/page";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import Button from "../../Components/Buttons/Button";
import {getToken} from "../../utils/cookies/tokensCookie";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {useEffectOnce} from "../../hooks/useEffectOnce";
import {modalStyles} from "../../utils/modalStyles";
import GeneralInfoSupermarketWarningModal
  from "../../Components/Modals/GeneralInfoSupermarketWarningModal/GeneralInfoSupermarketWarningModal";
import Modal from "react-modal";
import {capitalize} from "../../utils/capitalizeFirstLetter";
import {clearCompanyMessages, getCompanyData, updateCompany} from "../../redux/actions/companiesActions";
import {toast} from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import {isAllowed, userPermissions, userRoles} from "../../utils/permissionsAllow";
import PdfUploadButton from "../../Components/SimpleComponents/PdfUploadButton";
import {ReactComponent as TrashIcon} from "../../img/i_trash.svg";

interface ISettingsPage {
  company?: any;
  postCompanyDataState?: any;
}

function SettingsPage(props: ISettingsPage) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Count of supermarket plans in future to show warning Popup on toggle isSupermarketAllowed
  const [supermarketPlans, setSupermarketPlans] = useState(0);
  const [showWarningSupermarketPopup, setShowWarningSupermarketPopup] = useState(false);
  const [pageIsInitialized, setPageIsInitialized] = useState(false);
  const [guideLine, setGuideLine] = useState("");
  const [guideLineName, setGuideLineName] = useState("");
  const [guideLineUpdate, setGuideLineUpdate] = useState(false);
  const [buttonLoading, setButtonLoading] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const downloadingRef = useRef(downloadLoading);
  downloadingRef.current = downloadLoading;

  const formik = useFormik<IGeneralInfoForm>({
    initialValues: {
      approvalProcess: false,
      notifications: false,
      isLunchActivated: false,
      isBenefitsActivated: false,
      isHrzoneActivated: false,
      isSupermarketAllowed: false,
      isNotificationAllowed: false,
      ocr: false,
      maxOcr: 0,
      plans: [""],
      updatedAt: "",
      companyId: "",
      departmentAndBranches: [],
      virtualCard: false,
      virtualCardUploadRestaurantInvoices: false,
      budgetCorrection: false,
      budgetMoving: false,
      canBeDeleted: false,
      isManualInvoiceForVirtualCardAllowed: false,
      isCumulationAllowed: false,
      //   apiKey: "",
      companyInfo: {
        companyName: "",
        streetName: "",
        city: "",
        zipCode: "",
        country: "AT",
        image: "",
        website: "",
        phone: "",
        guideLine: "",
        guideLineName: "",
      },
      contactPerson: {
        firstName: "",
        lastName: "",
        email: "",
      },
    },
    validationSchema: Yup.object({
      companyInfo: Yup.object().shape({
        companyName: Yup.string()
          .required()
          .required(t("formik_warnings.general_info.company")),
        streetName: Yup.string().required(
          t("formik_warnings.general_info.streetName")
        ),
        city: Yup.string().required(t("formik_warnings.general_info.city")),
        zipCode: Yup.string().required(
          t("formik_warnings.general_info.zipCode")
        ),
        country: Yup.string().required(
          t("formik_warnings.general_info.country")
        ),
        website: Yup.string().required(
          t("formik_warnings.general_info.website")
        ),
        phone:
          (props.company && props.company.virtualCard)
            ? Yup.string().test("test-name", "Error", function (value: any) {
              if (value && value.length > 0) {
                const { path, createError } = this;
                const phoneValidation = validatePhoneNumber(value);
                if (phoneValidation) {
                  return true;
                } else {
                  return createError({
                    path,
                    message: t(
                      "formik_warnings.general_info.phoneNumber_validation"
                    ),
                  });
                }
              } else {
                return true;
              }
            })
            : Yup.string(),
      }),
      contactPerson: Yup.object().shape({
        firstName: Yup.string().required(
          t("formik_warnings.general_info.firstName")
        ),
        lastName: Yup.string().required(
          t("formik_warnings.general_info.lastName")
        ),
        email: Yup.string()
          .required(t("formik_warnings.general_info.email"))
          .email(t("formik_warnings.general_info.emailValid")),
      }),
    }),
    // handle form submitting
    onSubmit: () => {
      handleFormSubmit();
    },
  });

  // Get open plans with supermarket limits to show warning popup on toggle isSupermarketAllowed
  useEffectOnce(() => {
    const getSupermarketLimitData = async () => {
      const token = getToken();
      const companyId = getUserCompany();
      await axios
        .get(
          `${environment.baseUrl}${urls.plans.getSupermarketPlansExists}/${companyId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        )
        .then((res: any) => {
          setSupermarketPlans(Number(res.data?.plansCount));
        })
        .catch((err: any) => {
          setSupermarketPlans(0);
        });
    };
    getSupermarketLimitData();
  });

  useEffect(() => {
    if (
      props.company &&
      Object.keys(props.company).length &&
      pageIsInitialized
    ) {
      formik.values.plans = props.company.plans;
      formik.values.companyId = getUserCompany() || "";
      formik.values.companyInfo.companyName = props.company.companyInfo.companyName;
      formik.values.companyInfo.streetName = props.company.companyInfo.streetName;
      formik.values.companyInfo.city = props.company.companyInfo.city;
      formik.values.companyInfo.zipCode = props.company.companyInfo.zipCode;
      formik.values.companyInfo.country = props.company.companyInfo.country;
      formik.values.companyInfo.image = props.company.companyInfo.image;
      formik.values.companyInfo.website = props.company.companyInfo.website;
      formik.values.companyInfo.phone = props.company.companyInfo.phone;
      formik.values.contactPerson.firstName = props.company.contactPerson.firstName;
      formik.values.contactPerson.lastName = props.company.contactPerson.lastName;
      formik.values.contactPerson.email = props.company.contactPerson.email;
      formik.values.approvalProcess = props.company.approvalProcess;
      formik.values.notifications = props.company.notifications || false;
      formik.values.budgetCorrection = props.company.budgetCorrection || false;
      formik.values.budgetMoving = props.company.budgetMoving || false;
      formik.values.canBeDeleted = props.company.canBeDeleted;
      formik.values.virtualCardUploadRestaurantInvoices = props.company.virtualCardUploadRestaurantInvoices;
      formik.values.virtualCard = props.company.virtualCard;
      formik.values.isLunchActivated = props.company.modules[0].isActivated;
      formik.values.isBenefitsActivated = props.company.modules[1].isActivated;
      formik.values.isHrzoneActivated = props.company?.modules[2]?.isActivated || false;
      formik.values.isSupermarketAllowed = props.company.isSupermarketAllowed;
      formik.values.isNotificationAllowed = props.company.isNotificationAllowed;
      formik.values.isManualInvoiceForVirtualCardAllowed = props.company.isManualInvoiceForVirtualCardAllowed;
      formik.values.isCumulationAllowed = props.company.isCumulationAllowed;
      formik.values.ocr = props.company.ocr;
      formik.values.maxOcr = props.company.maxOcr;
      formik.values.companyInfo.guideLine = props.company.companyInfo.guideLine;
      formik.values.companyInfo.guideLineName = props.company.companyInfo.guideLineName;
      setGuideLine(props.company.companyInfo.guideLine);
      setGuideLineName(props.company.companyInfo.guideLineName);
    }
    setPageIsInitialized(true);
    //Need to disable it, because eslint says, that I need to add
    //all dependencies from formik, but we need to update, when company
    // state is updated
    // eslint-disable-next-line
  }, [props.company]);

  const handleFormSubmit = (
    e?: any,
    imageKey?: string,
    type?: "image" | "guideLine",
    imageName?: string
  ) => {
    if (type === "guideLine") {
      setGuideLineUpdate(true);
    }
    if (formik.isValid) {
      setButtonLoading(true);
      let postData: any;

      if (imageKey) {
        postData = {
          ...formik.values,
          companyInfo: {
            ...formik.values.companyInfo,
            [`${type}`]: imageKey,
            guideLineName: imageName,
          },
        };
      } else {
        postData = {
          ...formik.values,
          companyInfo: {
            ...formik.values.companyInfo,
            guideLine: type === "guideLine" ? "" : guideLine,
            guideLineName: type === "guideLine" ? "" : imageName,
          },
          approvalProcess: formik.values.approvalProcess,
          ocr: formik.values.ocr,
        };
      }

      delete postData.isBenefitsActivated;
      delete postData.isLunchActivated;
      postData.modules = [
        {
          name: "Lunch",
          isActivated: formik.values.isLunchActivated,
        },
        {
          name: "Benefits",
          isActivated: formik.values.isBenefitsActivated,
        },
        {
          name: "HRZone",
          isActivated: formik.values.isHrzoneActivated,
        },
      ];
      postData.contactPerson.firstName = capitalize(
        postData.contactPerson.firstName
      );
      postData.contactPerson.lastName = capitalize(
        postData.contactPerson.lastName
      );
      // postData.comguideLineUrl= guideLineUrl

      dispatch(updateCompany(postData));
      formik.resetForm({ values: formik.values });
    } else {
      toast.warning(
        <CustomToastContainer message={t("warnings.not_allowed")} status={2} />,
        {
          autoClose: 5000,
        }
      );
    }
  };

  useEffect(() => {
    if (props.postCompanyDataState.success === "update_company") {
      dispatch(clearCompanyMessages());
      dispatch(getCompanyData());
      if (guideLineUpdate) {
        toast.success(
          <CustomToastContainer
            message={t("warnings.update_company_guideline_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
        setGuideLineUpdate(false);
      } else {
        toast.success(
          <CustomToastContainer
            message={t("warnings.update_company_info_success")}
            status={1}
          />,
          {
            autoClose: 5000,
          }
        );
      }
      setButtonLoading(false);
    } else if (props.postCompanyDataState.error === "update_company") {
      dispatch(clearCompanyMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.update_company_info_error")}
          status={3}
        />,
        {
          autoClose: 5000,
        }
      );
      setButtonLoading(false);
    }
  }, [props.postCompanyDataState]);

  const handleDownload = async () => {

    const postData = async () => {
      try {
        const token = await getToken();
        const res = await axios.get(
          environment.baseUrl + urls.files.files + "/" + guideLine,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );

        const saveFile = async () => {
          return new Promise(function (resolve, reject) {
            const fileName = guideLineName;
            const fileType = "application/pdf";
            const xhr = new XMLHttpRequest();
            const a = document.createElement("a");
            let file: any;
            xhr.open("GET", res.data, true);
            xhr.responseType = "blob";
            xhr.onload = function () {
              file = new Blob([xhr.response], { type: fileType });
              a.href = window.URL.createObjectURL(file);
              a.download = fileName;
              a.click();
              resolve(true);
            };
            xhr.send();
          });
        };

        if (res && res.data) {
          return await saveFile();
        } else {
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
          setDownloadLoading(false);
        }
      } catch (e) {
        toast.error(
          <CustomToastContainer
            message={t("warnings.plans_download_error")}
            status={2}
          />,
          {
            autoClose: 5000,
          }
        );
        setDownloadLoading(false);
      }
    };

    if (!downloadLoading) {
      setDownloadLoading(true);
      const response = await postData();
      setDownloadLoading(false);
    }
  };

  return(
    <ContentContainer>
      <MainSpace>

        <Modal style={modalStyles} isOpen={showWarningSupermarketPopup}>
          <GeneralInfoSupermarketWarningModal
            openPlans={supermarketPlans}
            onCancel={() => {
              setShowWarningSupermarketPopup(false);
            }}
            onSubmit={() => {
              formik.handleSubmit();
              setShowWarningSupermarketPopup(false);
            }}
          />
        </Modal>

        <PageTitle mainTitle={t("dashboard.company_settings.title")} />

        <CompanySettingsSwitcher
          formik={formik}
          createCompany={true}
          inputsDisabled={false}
        />

        <Button
          buttonType="primary"
          buttonLabel={t("dashboard.general.button_1")}
          buttonHandler={() =>
            supermarketPlans > 0 && !formik.values.isSupermarketAllowed
              ? setShowWarningSupermarketPopup(true)
              : formik.handleSubmit()
          }
          disabled={!(formik.isValid && formik.dirty)}
          loading={buttonLoading}
          buttonStyles={{
            marginTop: "20px"
          }}
        />

        <h4
          style={{
            marginTop: "20px",
            marginBottom: "10px"
          }}
        >
          {t("dashboard.general.pdf_title")}
        </h4>

        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "1rem",
            marginBottom: "1rem"
          }}
        >

          {isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin,
                userRoles.custom,
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.generalInfo],
              },
            },
          }) ? (
              <>
                <PdfUploadButton
                  update={(e, imageKey, imageName) =>
                    handleFormSubmit(e, imageKey, "guideLine", imageName)
                  }
                  ImageUrl={guideLine}
                  setImageUrl={setGuideLine}
                  setImageName={setGuideLineName}
                  icon={false}
                />
              </>
            ) : null}
          {guideLine ? (
            <div
              style={{ cursor: "pointer" }}
              onClick={() => handleDownload()}
            >
              {/* {t("dashboard.general.guideline")} */}
              {guideLineName}
            </div>
          ) : (
            <></>
          )}

          {isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin,
                userRoles.custom,
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.generalInfo],
              },
            },
          }) && guideLine ? (
              <div style={{ display: "flex", gap: "0.5rem" }}>
                <PdfUploadButton
                  update={(e, imageKey, imageName) =>
                    handleFormSubmit(e, imageKey, "guideLine", imageName)
                  }
                  ImageUrl={guideLine}
                  setImageUrl={setGuideLine}
                  setImageName={setGuideLineName}
                  icon={true}
                />
                <TrashIcon
                  style={{ cursor: "pointer", width: "18px" }}
                  onClick={() => handleFormSubmit("", "", "guideLine")}
                />
              </div>
            ) : (
              <></>
            )}

          {isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin,
                userRoles.custom,
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.generalInfo],
              },
            },
          }) ? (
              <></>
            ) : (
              <></>
            )}
        </div>

      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    company: state.company.company.company,
    postCompanyDataState: state.company
  };
};

export default connect(mapStateToProps, null)(SettingsPage);
