import React from "react";
import "./CreateCardSuccessModal.css";
import {modalStyles} from "../../../../utils/modalStyles";
import Modal from "react-modal";
import closeIcon from "../../../../img/Dashboard/Employee/close_btn.svg";
import successImage from "../../../../img/Dashboard/Payments/Overview/OutgoingWireTransfer/card_success.png";
import {useTranslation} from "react-i18next";

interface IProps {
  showModal: boolean;
  onClose: () => void;
}

export default function CreateCardSuccessModal(props: IProps) {
  const { t } = useTranslation();

  return(
    <Modal
      style={modalStyles}
      isOpen={props.showModal}
    >
      <img
        style={{top:"15px", right:"15px"}}
        onClick={() => props.onClose()}
        className="close_popup"
        src={closeIcon} alt="close"
      />
      <div className="modal_container" style={{marginTop: "20px"}}>

        <div className="create_card_success_container">

          <h4>
            {t("dashboard.payments.create_card.success_modal.title")}
          </h4>

          <img
            src={successImage} alt="success"
          />

          <p>
            {t("dashboard.payments.create_card.success_modal.text_1")}
          </p>

          <p>
            {t("dashboard.payments.create_card.success_modal.text_2")}
          </p>

        </div>

      </div>
    </Modal>
  );
}
