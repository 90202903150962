import React, {useEffect, useRef, useState} from "react";
import "../Lists/ListStyles.css";
import {useTranslation} from "react-i18next";

interface CustomProps {
  edit: () => void,
  removeBranch: () => void,
  addDepartment: () => void,
  addBranchAndDeps: () => void
}

export default function ActionDotsDepsAndBranches({
  edit,
  removeBranch,
  addDepartment,
  addBranchAndDeps
}:CustomProps) {
  const { t } = useTranslation();

  const actionRef:any = useRef();

  const [menuListOpen, setMenuListOpen] = useState(false);

  // Detect click outside for close menu

  const branchesActions = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!branchesActions.current?.contains(event.target as Node)) {
        setMenuListOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const handleClick = (func:string) => {
    setMenuListOpen(false);
    if (func === "edit") {
      edit();
    } else if (func === "delete") {
      removeBranch();
    } else if (func === "add") {
      addDepartment();
    } else if (func === "addBranch") {
      addBranchAndDeps();
    }
  };

  useEffect(() => {
    if (menuListOpen) {
      actionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuListOpen]);

  return(
    <div ref={branchesActions} className="action_dots_container">
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container" style={{width: "30px"}}
        ref={actionRef}
      >
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          <div
            onClick={() => handleClick("addBranch")}
            className="table_menu_item row">
            <p>{t("dashboard.general.dep_branch.add_branch")}</p>
          </div>
          <div
            onClick={() => handleClick("add")}
            className="table_menu_item row">
            <p>{t("dashboard.general.dep_branch.add_department")}</p>
          </div>
          <div
            onClick={() => handleClick("edit")}
            className="table_menu_item row">
            <p>{t("dashboard.general.dep_branch.update_branch")}</p>
          </div>
          <div
            onClick={() => handleClick("delete")}
            className="table_menu_item row">
            <p>{t("dashboard.general.dep_branch.remove_branch")}</p>
          </div>
        </div>
      )}
    </div>
  );
}
