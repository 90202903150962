// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.billing_column {
    display: flex;
    align-items: center;
    position: relative;
}

.billing_column > img {
    width: 15px;
}

.billing_column > input {
    max-width: 30px;
    border: none;
}

.billing_column_warning {
    position: absolute;
    left: -30px;
}

.billing_column_warning > img {
    width: 16px;
}

.billing_column_warning > div {
    background-color: #59567E;
    color: #ffffff;
    position: absolute;
    z-index: 2;
    left: 4px;
    bottom: 20px;
    width: 299px;
    padding: 10px;
    border-radius: 4px;
}

.billing_column_warning > div:before {
    content: "";
    position: absolute;
    width: 0;
    height: 0;
    bottom: -10px;
    border-left: 20px solid transparent;
    border-right: 20px solid transparent;
    border-top: 16px solid #59567E;
}
`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/BillingListColumn/styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,eAAe;IACf,YAAY;AAChB;;AAEA;IACI,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,cAAc;IACd,kBAAkB;IAClB,UAAU;IACV,SAAS;IACT,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,kBAAkB;IAClB,QAAQ;IACR,SAAS;IACT,aAAa;IACb,mCAAmC;IACnC,oCAAoC;IACpC,8BAA8B;AAClC","sourcesContent":[".billing_column {\n    display: flex;\n    align-items: center;\n    position: relative;\n}\n\n.billing_column > img {\n    width: 15px;\n}\n\n.billing_column > input {\n    max-width: 30px;\n    border: none;\n}\n\n.billing_column_warning {\n    position: absolute;\n    left: -30px;\n}\n\n.billing_column_warning > img {\n    width: 16px;\n}\n\n.billing_column_warning > div {\n    background-color: #59567E;\n    color: #ffffff;\n    position: absolute;\n    z-index: 2;\n    left: 4px;\n    bottom: 20px;\n    width: 299px;\n    padding: 10px;\n    border-radius: 4px;\n}\n\n.billing_column_warning > div:before {\n    content: \"\";\n    position: absolute;\n    width: 0;\n    height: 0;\n    bottom: -10px;\n    border-left: 20px solid transparent;\n    border-right: 20px solid transparent;\n    border-top: 16px solid #59567E;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
