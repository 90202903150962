import React, {useEffect, useState} from "react";
import "./PaymentsOverviewHeaderContainerCardsBottomTab.css";
import CardsList from "../../../../../../Lists/CardsList";
import {connect, useDispatch} from "react-redux";
import {getCards} from "../../../../../../../redux/actions/paymentsActions";
import {RootState} from "../../../../../../../redux/reducers/rootReducer";
import FilteringBlock from "Components/Blocks/FilteringBlock";
import {useTranslation} from "react-i18next";
import FilterDatePicker from "../../../../../../SimpleComponents/FilterdatePicker";
import moment from "moment";
import {getFiltersData} from "../../../../../../../redux/actions/invoicesActions";
import CustomSelect from "../../../../../../SimpleComponents/Select";
import {getToken} from "../../../../../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../../../../../environment";
import {urls} from "../../../../../../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../../../../SimpleComponents/ToastContainer";
import CardsSelectedMenu from "../../../../../../SelectedMenu/CardsSelectedMenu";
import { paginationItemClasses } from "@mui/material";
import SelectWithChoices from "../../../../../../SimpleComponents/SelectWithChoices";
import {userService} from "../../../../../../../api/services/User/user.service";

interface IProps {
  company?: any;
  filters?: any;
}

function PaymentsOverviewHeaderContainerCardsBottomTab(props: IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [filters, setFilters] = useState<any>({
    limit: 8,
    skip: 0,
    startDate: null,
    endDate: null,
    search: ""
  });
  const [selectedCards, setSelectedCards] = useState<any[]>([]);
  const [usersWithCards, setUsersWithCards] = useState<any[]>([]);

  useEffect(() => {
    dispatch(getFiltersData());
    dispatch(getCards({
      companyId: props.company.company.id,
      skip: filters.skip,
      limit: filters.limit,
	  startDate: filters.startDate,
	  endDate: filters.endDate,
      search: ""
    }));
    getUsersWithCards();
  }, []);

  const getUsersWithCards = async () => {
    const users = await new userService().getUsersWithCards({});
    const formattedUsersWithCards = users.data.map((userWithCard) => {
      const userName = `${userWithCard.firstName} ${userWithCard.lastName}`;
      return [
        {
          value: `${userName} Restaurant`,
          label: `${userName} Restaurant`
        },
        {
          value: `${userName} Supermarket`,
          label: `${userName} Supermarket`
        }
      ];
    }).flat();
    setUsersWithCards(formattedUsersWithCards);
  };

  const handleDatePicker = (dates: {startDate: string, endDate: string}) => {
    setFilters({
      ...filters,
      startDate: dates.startDate,
      endDate: dates.endDate
    });
    dispatch(getCards({
      companyId: props.company.company.id,
      skip: 0,
      limit: 8,
      startDate: dates.startDate,
      endDate: dates.endDate,
      search: filters.search
    }));
  };

  const handleUserSelect = (options: {label: string, value: string}) => {
    setFilters({
      ...filters,
      search: options.value
    });
    dispatch(getCards({
      companyId: props.company.company.id,
      skip: 0,
      limit: 8,
      startDate: filters.startDate,
      endDate: filters.endDate,
      search: options.value
    }));
  };

  const handleDownload = (option: string) => {
    let type: string;
    if (option === "xlsx") {
      type = "1";
    }
    if (option === "csv") {
      type = "2";
    }

    const postData = async () => {
      try {

        const weavrToken = await getToken(true);

        const res = await axios.get(
          `${environment.baseUrl}${urls.files.cards}`,
          {
            headers: {Authorization: `Bearer ${getToken()}`},
            responseType: "blob",
            params: {
              exportType: type,
              weavrToken: weavrToken,
              companyId: props.company.company.id,
              startDate: filters.startDate,
              endDate: filters.endDate
            }
          }
        );

        if (res) {
          let fileName = "Corplife_transactions.csv";
          let fileType = "application/csv";

          if (type === "1") {
            fileName = "Corplife_transactions.xlsx";
            fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
          }
          if (type === "2") {
            fileName = "Corplife_transactions.csv";
            fileType = "application/csv";
          }

          const xhr = new XMLHttpRequest();
          const a = document.createElement("a");
          let file:any;
          const url = window.URL.createObjectURL(
            new Blob([res.data],
              {type: fileType})
          );
          xhr.open("GET", url, true);
          xhr.responseType = "blob";
          xhr.onload = function () {
            file = new Blob([xhr.response], { type : fileType });
            a.href = window.URL.createObjectURL(file);
            a.download = fileName;
            a.click();
          };
          xhr.send();
        } else {
          toast.error(
            <CustomToastContainer
              message={t("warnings.plans_download_error")}
              status={2}
            />,
            {
              autoClose: 5000,
            }
          );
        }

      } catch (e) {
        toast.error(
          <CustomToastContainer
            message={t("warnings.plans_download_error")}
            status={2}
          />,
          {
            autoClose: 5000,
          }
        );
      }
    };
    postData();
  };

  return(
    <>
      <div style={{height: "100%", position: "relative"}}>
        <div
          style={{
            position: "absolute",
            right: 0,
            top: "-50px"
          }}
        >
          <div className="row">
            {selectedCards && selectedCards.length > 0
              ? <CardsSelectedMenu
                selectedRows={selectedCards}
                updateListData={() => {
                  dispatch(getCards({
                    companyId: props.company.company.id,
                    skip: filters.skip,
                    limit: filters.limit
                  }));
                  setSelectedCards([]);
                }}
              />
              : null
            }
            <CustomSelect
              options={[t("dashboard.overview.export_all") as string, "csv", "xlsx"]}
              icon="export"
              onChange={handleDownload}
              buttonStyle={{
                backgroundColor: "#707070"
              }}
            />
          </div>
        </div>
      </div>
      <FilteringBlock title={t("dashboard.plans.filters.title")}>
        <>
          <div>
            <p className="filter_label" style={{ height: "1.2rem" }}>
              {t("dashboard.overview.filter_opt_6_label")}
            </p>
            <FilterDatePicker
              startDate={filters.startDate}
              endDate={filters.endDate}
              handleChange={(values:any) => handleDatePicker(values)}
            />
          </div>
          <div>
            <p className="filter_label" style={{ height: "1.2rem" }}>
              {t("dashboard.overview.filter_opt_3_label")}
            </p>
            <SelectWithChoices
              defaultValue={""}
              name={t("dashboard.overview.filter_opt_3_place")}
              selectPlaceHolder={t("dashboard.overview.filter_opt_3_place")}
              selectId="status"
              isMulti={"no"}
              options={usersWithCards}
              handleAddChoice={handleUserSelect}
            />
          </div>
        </>
      </FilteringBlock>
      <CardsList
        companyId={props.company.company.id}
        setSelectedCards={(cards: any[]) => setSelectedCards(cards)}
        selectedCards={selectedCards}
        filters={filters}
        updateListData={() => {
          dispatch(getCards({
            companyId: props.company.company.id,
            skip: filters.skip,
            limit: filters.limit,
            startDate: filters.startDate,
            endDate: filters.endDate
          }));
        }}
      />
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    company: state.company.company,
    filters: state.invoices.filters
  };
};

export default connect(mapStateToProps, null)(PaymentsOverviewHeaderContainerCardsBottomTab);
