import {
  CLEAR_FILES_MESSAGES,
  DOWNLOAD_FILES_ERROR,
  DOWNLOAD_FILES_SUCCESS,
  postDownloadFileDispatchTypes
} from "../types/downloadFilesTypes";

interface DefaultStateI {
	error: string,
	success: string
}

const initialState:DefaultStateI = {
  error: "",
  success: ""
};

export const postDownloadFileReducer = (
  state: DefaultStateI = initialState,
  action: postDownloadFileDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case DOWNLOAD_FILES_SUCCESS:
    return {
      error: "",
      success: action.payload
    };
  case DOWNLOAD_FILES_ERROR:
    return {
      error: action.payload,
      success: ""
    };
  case CLEAR_FILES_MESSAGES:
    return {
      error: "",
      success: ""
    };
  default:
    return state;
  }
};
