import {
  CLEAR_INVOICES_MESSAGES,
  GET_INVOICES_SUCCESS,
  GET_ONE_INVOICE_SUCCESS,
  INVOICES_ERROR,
  invoicesDispatchTypes,
  SHOW_INVOICES_LOADING,
  UPDATE_INVOICES,
  GET_FILTER_DATA_SUCCESS
} from "../types/invoicesTypes";

interface DefaultStateI {
	success?: string,
	error?: string,
	loading?: boolean,
	invoice?: any,
	invoices?: any,
	filters?: any
}

const stats1 = {} as any;

const initialState:DefaultStateI = {
  success: "",
  error: "",
  loading: true,
  invoice: {},
  invoices: [],
  filters: []
};

export const invoicesReducer = (
  state: DefaultStateI = initialState,
  action: invoicesDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case GET_ONE_INVOICE_SUCCESS:
    return {
      ...state,
      invoice: action.payload
    };
  case GET_INVOICES_SUCCESS:
    return {
      ...state,
      invoices: action.payload,
      loading: false
    };
  case GET_FILTER_DATA_SUCCESS:
    return {
      ...state,
      filters: action.payload,
      loading: false
    };
  case UPDATE_INVOICES:
    return {
      ...state,
      success: action.payload
    };
  case SHOW_INVOICES_LOADING:
    return {
      ...state,
      loading: true
    };
  case INVOICES_ERROR:
    return {
      ...state,
      error: action.payload
    };
  case CLEAR_INVOICES_MESSAGES:
    return {
      ...state,
      success: "",
      error: ""
    };
  default:
    return state;
  }
};
