// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.create_card_success_container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.create_card_success_container > h4 {
    font-size: 37px;
    font-weight: bold;
    color: #191818;
    margin-bottom: 40px;
}

.create_card_success_container > img {
    width: 80%;
    max-width: 120px;
    margin-bottom: 40px;
}

.create_card_success_container > p {
    font-size: 12px;
    font-weight: bold;
    margin-bottom: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/PaymentsPage/CreateCard/CreateCardSuccessModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,cAAc;IACd,mBAAmB;AACvB;;AAEA;IACI,UAAU;IACV,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,kBAAkB;AACtB","sourcesContent":[".create_card_success_container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.create_card_success_container > h4 {\n    font-size: 37px;\n    font-weight: bold;\n    color: #191818;\n    margin-bottom: 40px;\n}\n\n.create_card_success_container > img {\n    width: 80%;\n    max-width: 120px;\n    margin-bottom: 40px;\n}\n\n.create_card_success_container > p {\n    font-size: 12px;\n    font-weight: bold;\n    margin-bottom: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
