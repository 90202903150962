import customHistory from "../customHistory";
import {
  removeUserPermission,
  removeUserPermissionType,
  removeUserRoles
} from "./cookies/userRolesCookies";
import {toast} from "react-toastify";
import {removeToken} from "./cookies/tokensCookie";
import {removeUserCompany, removeUserId} from "./cookies/userCookies/userCookies";

export function errorHandler(status:number) {
  let previousPath = "";

  // If error comes from error page change path (needed for reload button in error page)
  if (window.location.pathname !== "/server-error" && previousPath !== "/error") {
    previousPath = window.location.pathname;
  }
  if (status === 404 && window.location.pathname !== "/error") {
    customHistory.push(`/error${previousPath ? `?previousPath=${previousPath}` : ""}`);
    return true;
  } else if (status === 500 || status === 0 && window.location.pathname !== "/server-error") {
    customHistory.push(`/server-error${previousPath ? `?previousPath=${previousPath}` : ""}`);
    return true;
  } else if (status === 429) {
    toast.error("Please refresh your page");
    return true;
  } else if (status === 401) {
    // Remove all stored values logout user
    removeToken();
    removeUserPermission();
    removeUserPermissionType();
    removeUserRoles();
    removeUserId();
    removeUserCompany();
    sessionStorage.clear();
    customHistory.push("/login");
    return true;
  } else if (status === 403) {
    removeToken();
    removeUserPermission();
    removeUserPermissionType();
    removeUserRoles();
    removeUserId();
    removeUserCompany();
    sessionStorage.clear();
    customHistory.push({
      pathname: "/login",
      search: "?error=permission"
    });
    return true;
  } else {
    return false;
  }
}
