import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import NoRecordsFound from "./NoRecordsList";
import {useTranslation} from "react-i18next";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import BillingListColumn from "../SimpleComponents/BillingListColumn/BillingListColumn";
import PlansChangelog from "../Containers/PlansChangelog/PlansChangelog";
import Pagination from "../SimpleComponents/Pagination";
import {useDispatch} from "react-redux";
import {getBillingPlan} from "../../redux/actions/plansActions";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

interface IProps {
  planId: string;
  data: any;
  loading: boolean | undefined;
  getData: (skip: number) => void;
  total: number;
  usersSelectionState: string[];
}

export default function BillingList(props:IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [skipState, setSkipState] = useState(0);
  const [showCustomIdentifierColumn, setShowCustomIdentifierColumn] = useState(false);
  const [showEmploymentTypeColumn, setShowEmploymentTypeColumn] = useState(false);

  const getData = () => {
    props.getData(skipState);
  };

  const paginate = (pageNumber: number) => {
    const nextPage = (pageNumber - 1) * 8;
    setSkipState(nextPage);
    dispatch(getBillingPlan({
      limit: 8,
      skip: nextPage,
      users: props.usersSelectionState,
    }, props.planId));
  };

  const getLimit = (item:any, type: "vacation" | "sick" | "other") => {
    let value = item.businessDays;
    const types = [
      {
        type: "vacation",
        value: item.vacationCorrection
      },
      {
        type: "sick",
        value: item.sickCorrection
      },
      {
        type: "other",
        value: item.otherCorrection
      },
    ];
    types.map((typeItem:any) => {
      if (typeItem.type === type) {
        value += typeItem.value;
      }
    });
    return value;
  };

  useEffect(() => {
    const usersWithCustomIdentifier = props.data.filter((user:any) => user.user.customIdentifier);
    if (usersWithCustomIdentifier?.length > 0) {
      setShowCustomIdentifierColumn(true);
    } else {
      setShowCustomIdentifierColumn(false);
    }
    const usersWithEmploymentType = props.data.filter((user:any) => user.user.employmentType);
    if (usersWithEmploymentType?.length > 0) {
      setShowEmploymentTypeColumn(true);
    } else {
      setShowEmploymentTypeColumn(false);
    }
  }, [props.data]);

  return(
    props.data && !props.data.length ? (
      <NoRecordsFound
        text={t("warnings.dash_empty_table")}
      />
    ) : (
      <>
        <div className="table_box">
          {props.loading
            ? <TableLoading />
            :
            <div className="table_wrapper-overview" >
              <table style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.firstName")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.lastName")}
                      </div>
                    </th>
                    {showCustomIdentifierColumn
                      ? <th>
                        <div className="responsive-mr-40">
                          {t("dashboard.plans.billing_page.table_headers.customIdentifier")}
                        </div>
                      </th>
                      : null
                    }
                    {showEmploymentTypeColumn
                      ? <th>
                        <div className="responsive-mr-40">
                          {t("dashboard.plans.billing_page.table_headers.employmentType")}
                        </div>
                      </th>
                      : null
                    }
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.working_days")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.holiday_days")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.sick_days")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.other_days")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.budget")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.claim")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.plans.billing_page.table_headers.last_changes")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {props.data.map((item:any) => (
                    <React.Fragment key={item.user._id}>
                      <tr className="spacer" />
                      <tr className="table-item">
                        <td>
                          <p>
                            {item.user.firstName}
                          </p>
                        </td>
                        <td>
                          <p>
                            {item.user.lastName}
                          </p>
                        </td>
                        {showCustomIdentifierColumn
                          ? <td>
                            <p>
                              {item.user.customIdentifier}
                            </p>
                          </td>
                          : null
                        }
                        {showEmploymentTypeColumn
                          ? <td>
                            {item.user.employmentType
                              ? <p>
                                {t(`dashboard.home.employmentType.${item.user.employmentType}`)}
                              </p>
                              : null
                            }
                          </td>
                          : null
                        }
                        <td>
                          <p>
                            {item.businessDays}
                          </p>
                        </td>
                        <td>
                          <BillingListColumn
                            amount={item.vacationCorrection}
                            limit={getLimit(item, "vacation")}
                            getData={getData}
                            planId={props.planId}
                            companyId={getUserCompany()}
                            userId={item.user._id}
                            vacationCorrection={item.vacationCorrection}
                            sickCorrection={item.sickCorrection}
                            otherCorrection={item.otherCorrection}
                            type={"vacation"}
                          />
                        </td>
                        <td>
                          <BillingListColumn
                            amount={item.sickCorrection}
                            limit={getLimit(item, "sick")}
                            getData={getData}
                            planId={props.planId}
                            companyId={getUserCompany()}
                            userId={item.user._id}
                            vacationCorrection={item.vacationCorrection}
                            sickCorrection={item.sickCorrection}
                            otherCorrection={item.otherCorrection}
                            type={"sick"}
                          />
                        </td>
                        <td>
                          <BillingListColumn
                            amount={item.otherCorrection}
                            limit={getLimit(item, "other")}
                            getData={getData}
                            planId={props.planId}
                            companyId={getUserCompany()}
                            userId={item.user._id}
                            vacationCorrection={item.vacationCorrection}
                            sickCorrection={item.sickCorrection}
                            otherCorrection={item.otherCorrection}
                            type={"other"}
                          />
                        </td>
                        <td>
                          <p>
                            {`€ ${item.totalBudget.toFixed(2).replace(".", ",")}`}
                          </p>
                        </td>
                        <td>
                          <p>
                            {`€ ${item.claimedAmount.toFixed(2).replace(".", ",")}`}
                          </p>
                        </td>
                        <td>
                          <PlansChangelog
                            data={item.changelog}
                          />
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
        <Pagination
          postsPerPage={8}
          totalPosts={ props.total }
          paginate={paginate}
        />
      </>

    )
  );
}
