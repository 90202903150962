import React, {Component} from "react";
import {isAllowed, userPermissions, userRoles} from "utils/permissionsAllow";
import SidebarDropdownItem from "./SidebarDropdownItem";
import SidebarItem from "./SidebarItem";
import i18n from "i18next";
import {ICompanyUpdate} from "Interfaces/ICompany";
import {getMagentoData, getUserId} from "utils/cookies/userCookies/userCookies";
import moduleIsActivated from "../../utils/moduleIsActivated";

interface ISidebar {
  allowedTabs: string[];
  company: ICompanyUpdate;
  showResponsiveSidebar: boolean;
  sidebarTab: any;
  handleSidebarChanges: (page: string) => void;
  modules: any;
  hrZoneAuth: HrZoneAuthInterface;
  user: any;
}

export interface HrZoneAuthInterface {
  url: string;
  sessionKey: string;
  isMasterHr: boolean;
  fetched: boolean;
}

export class SideBarItemsClass extends Component {
  allowedTabs: any[];
  sideBarItems: any[] = [];
  company: any;
  showResponsiveSidebar: any;
  sidebarTab: any;
  handleSidebarChanges: any;
  modules: any;
  hrZoneAuth: any;
  user: any;

  constructor(props: ISidebar) {
    super(props);
    this.allowedTabs = props.allowedTabs;
    this.company = props.company;
    this.showResponsiveSidebar = props.showResponsiveSidebar;
    this.sidebarTab = props.sidebarTab;
    this.handleSidebarChanges = props.handleSidebarChanges;
    this.modules = props.modules;
    this.hrZoneAuth = props.hrZoneAuth;
    this.user = props.user;

    const magentoUser = getUserId({ withSplit: true });
    const magentoData = getMagentoData();

    this.sideBarItems = [
      {
        itemName: "lunch",
        itemText: i18n.t("components.sidebar.menu_12"),
        isAllowed:
          isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin,
                userRoles.custom,
                userRoles.user,
                userRoles.viewer,
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.users, userPermissions.invoices],
              },
            },
          }) &&
          (this.modules[0]?.isActivated ?? false),
        image: "",
        children: [
          {
            itemName: "dashboard",
            itemText: i18n.t("components.sidebar.menu_1"),
            isAllowed: this.allowedTabs.includes("1"),
            children: [],
            image: "dashboard",
          },
          {
            itemName: "employee",
            itemText: i18n.t("components.sidebar.menu_3"),
            isAllowed: this.allowedTabs.includes("4"),
            children: [],
            image: "employee",
          },
          {
            itemName: "plans",
            itemText: i18n.t("components.sidebar.menu_4"),
            isAllowed: this.allowedTabs.includes("3"),
            children: [],
            image: "plans",
          },
          {
            itemName: "overview",
            itemText: i18n.t("components.sidebar.menu_5"),
            isAllowed: this.allowedTabs.includes("5"),
            children: [],
            image: "overview",
          },
          {
            itemName: "notification-center",
            itemText: i18n.t("components.sidebar.menu_6"),
            isAllowed:
              this.company &&
              Object.values(this.company).length &&
              this.company.notifications &&
              isAllowed({
                data: {
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
              }),
            children: [],
            image: "notification-center",
          },
          {
            itemName: "settings",
            itemText: i18n.t("components.sidebar.menu_8"),
            isAllowed: this.allowedTabs.includes("6"),
            children: [],
            image: "settings",
          },
          {
            itemName: "payments",
            itemText: i18n.t("components.sidebar.menu_9"),
            isAllowed:
              this.company &&
              this.company.virtualCard &&
              // Compare if user company is same as root company (CGR-2487)
              this.company.id === this.user.companyId &&
              isAllowed({
                data: {
                  roles: [userRoles.superAdmin, userRoles.admin],
                },
              }),

            children: [],
            image: "payments",
          },
          {
            itemName: "employeePayments",
            itemText: i18n.t("components.sidebar.menu_9_employee"),
            isAllowed:
              this.company &&
              this.company.virtualCard &&
              // Compare if user company is same as root company (CGR-2487)
              this.company.id === this.user.companyId &&
              isAllowed({
                data: {
                  roles: [userRoles.user, userRoles.superAdmin, userRoles.admin, userRoles.custom],
                  permissions: {
                    isAdmin: true,
                    permissionsArray: [userPermissions.users, userPermissions.invoices],
                  }
                },
              }),

            children: [],
            image: "employeePayments",
          },
        ],
      },
      {
        itemName: "pp",
        itemText: i18n.t("components.sidebar.menu_13"),
        isAllowed:
          ((isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin,
                userRoles.custom,
                userRoles.user,
                userRoles.viewer,
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.users],
              },
            },
          }) &&
              moduleIsActivated({company: this.company, module: "PartnerPortal", magentoUser: magentoUser})))
        && process.env.REACT_APP_ENV !== "production",
        image: "",
        children: [
          {
            itemName: "partner-portal/profile",
            itemText: "Profile Page",
            isAllowed: true,
            children: [],
            image: "general-info",
          },
          {
            itemName: "partner-portal/deals",
            itemText: "Deals",
            isAllowed: true,
            children: [],
            image: "plans",
          }
        ],
      },
      {
        itemName: "hr",
        itemText: i18n.t("components.sidebar.menu_11"),
        isAllowed:
          ((isAllowed({
            data: {
              roles: [
                userRoles.superAdmin,
                userRoles.admin,
                userRoles.custom,
                userRoles.user,
                userRoles.viewer,
              ],
              permissions: {
                isAdmin: true,
                permissionsArray: [userPermissions.users],
              },
            },
          }) &&
              magentoData.isHr) ||
            (magentoUser && magentoUser[0] === "magento_user")) &&
          this.hrZoneAuth.url?.length > 0 &&
          this.hrZoneAuth.sessionKey?.length > 0,
        image: "",
        children: [
          {
            itemName: "hrzone/dashboard",
            itemText: i18n.t("components.sidebar.hrZone.dashboard"),
            isAllowed:
              magentoData.isHr ??
              (magentoUser && magentoUser[0] === "magento_user"),
            children: [],
            image: "dashboard",
          },
          {
            itemName: "hrzone/employee",
            itemText: i18n.t("components.sidebar.hrZone.user"),
            isAllowed:
              magentoData.isHr ??
              (magentoUser && magentoUser[0] === "magento_user"),
            children: [],
            image: "employee",
          },
          {
            itemName: "hrzone/general-info",
            itemText: i18n.t("components.sidebar.hrZone.profile"),
            isAllowed:
              magentoData.isHr ??
              (magentoUser && magentoUser[0] === "magento_user"),
            children: [],
            image: "settings",
          },
          {
            itemName: "corplifebenefits",
            itemText: i18n.t("components.sidebar.hrZone.benefits"),
            linkTo:
              (process.env.REACT_APP_ENV === "production"
                ? "https://" + this.hrZoneAuth?.url + "/rest/V1"
                : process.env.REACT_APP_HR_ZONE_URL) +
              "/hrredirect/" +
              btoa(this.user?.email) +
              "/" +
              btoa(this.hrZoneAuth?.sessionKey) +
              "/" +
              btoa(
                process.env.REACT_APP_ENV === "production"
                  ? this.hrZoneAuth?.url ?? ""
                  : process.env.REACT_APP_HR_ZONE_URL?.replace(
                    "https://",
                    ""
                  ).replace("/rest/V1", "")
              ),
            // linkTo: props.hrZoneAuth?.url,
            isAllowed:
              magentoData.isHr ??
              (magentoUser && magentoUser[0] === "magento_user"),
            children: [],
            image: "corplifebenefits",
          },
        ],
      },
    ];
  }

  renderChildren = (items: any) => {
    return items.map((child: any, index: number) => {
      if (child.isAllowed) {
        return (
          <SidebarItem
            key={child.itemName + "-" + index}
            openSidebar={this.showResponsiveSidebar}
            selectedTab={this.sidebarTab}
            handleSidebarChanges={this.handleSidebarChanges}
            itemName={child.itemName}
            itemText={child.itemText}
            linkTo={child.linkTo}
            image={child.image}
          />
        );
      }
    });
  };

  generateAllSideBarItems() {
    return this.sideBarItems.map((sideBarItem, index) => {
      if (sideBarItem.isAllowed) {
        if (sideBarItem.children.length > 0) {
          return (
            <SidebarDropdownItem
              key={sideBarItem.itemName + "-" + index}
              openSidebar={this.showResponsiveSidebar}
              selectedTab={this.sidebarTab}
              handleSidebarChanges={this.handleSidebarChanges}
              itemName={sideBarItem.itemName}
              itemText={sideBarItem.itemText}
              image={sideBarItem.image}
            >
              {this.renderChildren(sideBarItem.children)}
            </SidebarDropdownItem>
          );
        } else {
          return (
            <SidebarItem
              key={sideBarItem.itemName + "-" + index}
              openSidebar={this.showResponsiveSidebar}
              selectedTab={this.sidebarTab}
              handleSidebarChanges={this.handleSidebarChanges}
              itemName={sideBarItem.itemName}
              itemText={sideBarItem.itemText}
              linkTo={sideBarItem.linkTo}
              image={sideBarItem.image}
            />
          );
        }
      }
    });
  }
}