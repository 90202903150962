import "../../Components/Lists/ListStyles.css";
import "./style.css";

import ContentContainer from "Components/Containers/ContentContainer";
import MainSpace from "Components/Containers/MainSpace";
import React, { useEffect } from "react";
import { connect, useDispatch } from "react-redux";
import useWindowDimensions from "utils/useWindowSize";

import GeneralInfoLoading from "../../Components/LoadingPlaceholders/GeneralInfoPageLoading";
import PermissionsTab from "./permissions/page";
import {
  changeSidebarTab,
  handleSidebar
} from "../../redux/actions/sidebarActions";
import { RootState } from "../../redux/reducers/rootReducer";

function UserSettingsPage() {
  const dispatch = useDispatch();
  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width < 1000) {
      dispatch(handleSidebar(false));
    }
  }, [width, dispatch]);

  return !dispatch ? (
    <GeneralInfoLoading />
  ) : (
    <ContentContainer>
      <MainSpace>
        <div className="tabs">
          <div className="tab-body">
            <PermissionsTab />
          </div>
        </div>
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    userData: state.users.userInfo.user
  };
};

export default connect(mapStateToProps, null)(UserSettingsPage);
