import React, { useEffect, useState } from "react";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import ContentContainer from "../../Components/Containers/ContentContainer";
import MainSpace from "../../Components/Containers/MainSpace";
import {useNavigate, useParams} from "react-router-dom";
import PageTitle from "../../Components/SimpleComponents/PageTitle";
import MoonLoader from "react-spinners/MoonLoader";
import EmptyTableState from "../../Components/SimpleComponents/TableEmptyState";
import PlanDetailsList from "../../Components/Lists/PlanDetailsList";
import useWindowDimensions from "../../utils/useWindowSize";
import { useTranslation } from "react-i18next";
import { getOnePlan } from "../../redux/actions/plansActions";

interface CustomProps {
  plans?: any;
  RouterProps?: any;
}

function PlansDetailPage({ plans }: CustomProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [plansId, setPlansId] = useState("");

  useEffect(() => {
    if (params && params.planId) {
      dispatch(getOnePlan(params.planId, limit, 0));
      setPlansId(params.planId);
    }
  }, []);

  // skip and limit for dispatch data
  const [limit, setLimit] = useState(8);

  // set less item in table for small screens
  const { height } = useWindowDimensions();
  useEffect(() => {
    if (height < 950) {
      setLimit(5);
    }
  }, [height]);

  return (
    <ContentContainer>
      <MainSpace>
        <div className="row">
          <p onClick={() => navigate("/plans")} className="crumb" test-id="plans_detail_crumb">
            {t("dashboard.plan_detail.breadcrumb")}
          </p>
          <p className="crumb_arrow">{">"}</p>
          <p className="crumb_current">{t("dashboard.plan_detail.title")}</p>
        </div>
        <div className="space-between header_orders">
          <PageTitle mainTitle={t("dashboard.plan_detail.title")} />
        </div>

        <div style={{ height: "2rem" }} />

        {!plans.plan.result && plans.loading ? (
          <div className="center" style={{ minHeight: "20vh" }}>
            <MoonLoader />
          </div>
        ) : plans.plan.result ? (
          <React.Fragment>
            {plans && plans.plan.result.length ? (
              <PlanDetailsList
                invoiceState={false}
                selectAll={() => {
                  //
                }}
                branches={["as"]}
                departments={["as"]}
                search="as"
                requestParams={[]}
                setRequestParams={() => {
                  //
                }}
                plansId={plansId}
              />
            ) : (
              <EmptyTableState />
            )}
          </React.Fragment>
        ) : (
          <EmptyTableState />
        )}
      </MainSpace>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    plans: state.plans,
  };
};

export default connect(mapStateToProps, null)(PlansDetailPage);
