import "./style.css";

import ContentContainer from "Components/Containers/ContentContainer";
import DashboardContainer from "Components/Containers/DashboardContainer";
import PurpleContainer from "Components/Containers/PurpleContainer";
import DownloadBox from "Components/Content/DownloadBox";
import SideProducts from "Components/Content/SideProducts";
import BranchesChart from "Components/DashboardCharts/BranchesChart";
import DailyComparisons from "Components/DashboardCharts/DailyComparisons";
import MaxLimitChart from "Components/DashboardCharts/MaxLimitChart";
import ProfileCompletion from "Components/DashboardCharts/ProfileCompletion";
import SummaryChart from "Components/DashboardCharts/SummaryChart";
import EmployeeList from "Components/Lists/EmployeeList";
import PendingList from "Components/Lists/PendingList";
import DashboardLoading from "Components/LoadingPlaceholders/DashboardLoading";
import BannerTitle from "Components/SimpleComponents/BannerWithTitle";
import Spacer from "Components/SimpleComponents/Spacer";
import React, { Suspense, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import {
  handleSidebar
} from "../../redux/actions/sidebarActions";
import { getUsers } from "../../redux/actions/usersActions";
import { RootState } from "../../redux/reducers/rootReducer";
import useWindowDimensions from "../../utils/useWindowSize";
import Popup from "../../Components/Containers/Popup";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";

interface CustomProps {
  userData?: any;
}

function Dashboard({ userData }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [popupVisible, setPopupVisible] = useState<boolean>(false);

  useEffect(() => {
    dispatch(
      getUsers({
        limit: 4,
        skip: 0
      })
    );
  }, []);

  // If !name showing only welcome in banner
  if (userData && !userData.firstName) {
    userData.firstName = "";
  }

  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 1000) {
      dispatch(handleSidebar(false));
    }
  }, [width, dispatch]);

  return (
    <Suspense fallback={<DashboardLoading />}>
      <ContentContainer>
        {popupVisible ? (
          <Popup
            popupVisible={popupVisible}
            setPopupVisible={() => setPopupVisible(!popupVisible)}
          />
        ) : null}
        {userData ? (
          <BannerTitle
            mainTitle={
              t("dashboard.home.welcome_title") + " " + userData.firstName + "!"
            }
          />
        ) : null}
        <div className="main_sections">
          <div className="left_section_first">
            <div className="left_section_first_top">
              <ProfileCompletion />
              <div className="dash_first_one center_circle">
                <MaxLimitChart />
              </div>
            </div>
            <div className="left_section_first_bottom">
              <DashboardContainer>
                <div className="summary_chart_grid">
                  <DailyComparisons />
                  <SummaryChart />
                </div>
              </DashboardContainer>

              <Spacer spacerHeight="24px" />

              <DashboardContainer>
                <BranchesChart />
              </DashboardContainer>
            </div>
          </div>
          <div className="responsive_side">
            <Spacer spacerHeight="50px" />
            <div className="right_section">
              <div className="right_section_content">
                <PurpleContainer>
                  <SideProducts
                    contactForm={() => setPopupVisible(!popupVisible)}
                  />
                </PurpleContainer>
              </div>
            </div>
          </div>
        </div>

        {new getLunchSettings().isApprovalProcess() ? (
          <div className="left_section_first">
            <div className="left_section_second_bottom">
              <DashboardContainer type="pending_box">
                <PendingList />
              </DashboardContainer>
            </div>
          </div>
        ) : (
          <></>
        )}

        <div className="main_sections">
          <div className="left_section_first">
            <div className="left_section_second_bottom">
              <div className="dash_table_container">
                <EmployeeList />
              </div>
              <Spacer spacerHeight="40px" />
            </div>
          </div>
        </div>
        <div className="left_section_charts">
          <div className="left_section_second_bottom">
            <PurpleContainer>
              <DownloadBox />
            </PurpleContainer>
          </div>
        </div>
      </ContentContainer>
    </Suspense>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    loading: state.users.loading,
    userData: state.users.userInfo.user
  };
};

export default connect(mapStateToProps, null)(Dashboard);
