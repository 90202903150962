import React, { useEffect, useState } from "react";
import "./ListStyles.css";
import Checkbox from "../SimpleComponents/Checkbox";
import TableSortedHeadItem from "./TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import NoRecordsFound from "./NoRecordsList";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import { getUsers } from "../../redux/actions/usersActions";
import TableLoading from "Components/LoadingPlaceholders/TableLoading";

interface CustomProps {
  employee?: any;
  searchData?: any;
  selectedRows?: any;
  selectAll: (state: boolean) => void;
  branches: any;
  departments: any;
  search: any;
  updatePlanData: any;
  employeeInPlan: any;
  plans: any;
  currentPlan: any;
  currentPlanIndex: number;
  startDate: string;
  endDate: string;
  usersWithInvoices: string[];
  selectedEmploymentType: any;
  isCopyPlan?: boolean;
  addedPlans?: any;
  users?: any;
  company?: any;
  selectedUsers: string[];
  setSelectedUsers: (data: string[]) => void;
}

export type departmentType = {
  department: string;
  branch: string;
  streetName: string;
  city: string;
  zipCode: string;
  costCenter: string;
  _id: string;
  companyId: string;
  createdAt: string;
  updatedAt: string;
  __v: number;
};

function EmployeePlansList({
  employee,
  search,
  departments,
  employeeInPlan,
  selectedRows,
  selectAll,
  branches,
  updatePlanData,
  plans,
  currentPlan,
  currentPlanIndex,
  startDate,
  endDate,
  usersWithInvoices,
  isCopyPlan,
  addedPlans,
  users,
  selectedUsers,
  setSelectedUsers
}: CustomProps) {
  interface Lookup {
    [index: string]: string;
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();
  /*const [selectedUsers, setSelectedUsers] = useState<string[]>([]);*/
  /*const [checkedItems, setCheckedItems] = useState<any>([]);*/
  const [sortedData, setSortedData] = useState([]);
  const currentPosts: any = sortedData.slice(0, 5);

  // Check if chosen dates are conflicted with local
  const checkDates = (plan: any) => {
    const checkedPlanStartDate = plan.dateRange.startDate;
    const checkedPlanEndDate = plan.dateRange.endDate;
    const currentPlanStartDate = currentPlan.dateRange.startDate;
    const currentPlanEndDate = currentPlan.dateRange.endDate;
    if (checkedPlanStartDate === currentPlanStartDate) {
      return true;
    } else if (checkedPlanEndDate === currentPlanEndDate) {
      return true;
    } else if (
      currentPlanStartDate > checkedPlanStartDate &&
      currentPlanStartDate < checkedPlanEndDate
    ) {
      return true;
    } else if (
      currentPlanStartDate < checkedPlanStartDate &&
      currentPlanEndDate > checkedPlanEndDate
    ) {
      return true;
    } else if (
      currentPlanEndDate > checkedPlanStartDate &&
      currentPlanEndDate < checkedPlanEndDate
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (employee.users) {
      if (plans.length > 1) {
        const temporaryArray: any = [...employee.users];
        temporaryArray.map((post: any, idx: number) => {
          let exists = false;
          plans.forEach((plan: any, i: number) => {
            if (i !== currentPlanIndex) {
              if (checkDates(plan)) {
                plan.employee.forEach((localUser: any) => {
                  if (localUser._id === post._id) {
                    exists = true;
                  }
                });
              }
            }
          });
          post.isBlocked = exists;
        });
        setSortedData(temporaryArray);
      } else {
        setSortedData(employee.users);
      }
    }
  }, [employee]);

  useEffect(() => {
    let shouldMarkAsSelected = true;
    if (selectedUsers.length) {
      for (const user of currentPosts) {
        let hasPlan = false;
        const foundUser = employee.users.filter((foundUserItem:any) => foundUserItem._id === user._id);
        if (foundUser && foundUser.length > 0) {
          hasPlan = !!foundUser[0].hasPlan;
          if (!selectedUsers.includes(user._id) && !employeePlansCheck({_id: user._id, hasPlan: hasPlan})) {
            shouldMarkAsSelected = false;
          }
        }
      }
    } else {
      shouldMarkAsSelected = false;
    }
    setCheckAll(shouldMarkAsSelected);
  }, [selectedUsers, currentPosts]);

  // handling checkboxes
  const [checkedRows, setCheckedRows] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    setCheckedRows(users);
  }, [users]);

  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    firstName: "up",
    lastName: "up",
    email: "up"
  });

  const [sortValue, setSortValue] = useState("");
  const sortingData = (name: string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortingValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortingValue === "up" ? "" : "-"}${name}`;
    setSortValue(postValue);

    dispatch(
      getUsers({
        limit: 5,
        skip: 0,
        sortBy: postValue,
        startDate,
        endDate,
        isActive: 1,
        forPlanCreation: 1
      })
    );
    setSortButton({ ...sortButton, [name]: sortingValue });
  };

  // handle Paginate

  const paginate = (pageNumber: number) => {
    const nextPage = (pageNumber - 1) * 5;

    dispatch(
      getUsers({
        limit: 5,
        skip: nextPage,
        branchId: branches,
        departmentId: departments,
        search,
        sortBy: sortValue,
        startDate,
        endDate,
        isActive: 1,
        forPlanCreation: 1
      })
    );
  };

  // Check if employee has already plan
  const employeePlansCheck = (employeeItem: any) => {

    const planEmployeeIds = currentPlan.employee.map(
      (employee: any) => employee._id
    );
    if (planEmployeeIds.includes(employeeItem._id)) {
      return false;
    }

    if (updatePlanData && updatePlanData.plan) {
      let finished = false;
      let state = true;
      const updateStartDate = moment(
        updatePlanData.plan.startDate.slice(0, 10),
        "YYYY-MM-DD"
      ).format("YYYY.MM.DD");
      const updateEndDate = moment(
        updatePlanData.plan.endDate.slice(0, 10),
        "YYYY-MM-DD"
      ).format("YYYY.MM.DD");
      const newStartDate = moment(startDate, "YYYY-MM-DD").format("YYYY.MM.DD");
      const newEndDate = moment(endDate, "YYYY-MM-DD").format("YYYY.MM.DD");
      updatePlanData.plan.employees.forEach((item: any) => {
        if (item === employeeItem._id) {
          finished = true;
          if (usersWithInvoices.includes(employeeItem._id)) {
            state = true;
          } else {
            state = false;
          }
        }
      });

      if (!finished) {
        if (updateStartDate !== newStartDate || updateEndDate !== newEndDate) {
          if (
            employeeItem.hasPlan ||
            usersWithInvoices.includes(employeeItem._id)
          ) {
            state = true;
          } else {
            state = false;
          }
        } else {
          if (!updatePlanData.plan.employees.includes(employeeItem._id) && !employeeItem.hasPlan) {
            state = false;
          }
        }
      }
      return state;
    } else if (
      employeeItem.hasPlan ||
      usersWithInvoices.includes(employeeItem._id)
    ) {
      return true;
    } else {
      return false;
    }
  };

  /**
   * Helper function to get the IDs of all selected rows
   * @returns Array of IDs
   */
  /*const getCheckedIds = () => {
    return checkedItems.map((item: any) => item._id);
  };*/

  /**
   * Function to handle single checkbox changd. Used for toggling checkbox states.
   * @param item - any
   */
  /*const handleCheckboxChange = (item: any) => {
    if (checkedItems?.length > 0 && getCheckedIds().includes(item._id)) {
      const index = getCheckedIds().indexOf(item._id);
      if (index !== -1) {
        checkedItems.splice(index, 1);
      }
      setCheckedItems([...checkedItems]);
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };*/

  const handleCheckboxChange = (id: string) => {
    const stateCopy = [...selectedUsers];
    if (selectedUsers.includes(id)) {
      const filteredArray = stateCopy.filter(item => item !== id);
      setSelectedUsers(filteredArray);
    } else {
      stateCopy.push(id);
      setSelectedUsers(stateCopy);
    }
  };

  const [checkLoading, setCheckLoading] = useState(false);

  /**
   * Function to handle all checkbox changes. Used for toggling all checkboxes at the same time.
   */

  const handleCheckAll = () => {
    setCheckLoading(true);
    const selectedUsersCopy = [...selectedUsers];
    if (checkAll) {
      const currentUsersIds = currentPosts.map((user: any) => user._id);
      const arrayWithoutCurrentUsers = selectedUsersCopy.filter((user: string) => !currentUsersIds.includes(user));
      setSelectedUsers(arrayWithoutCurrentUsers);
    } else {
      for (const user of currentPosts) {
        let hasPlan = false;
        const foundUser = employee.users.filter((foundUserItem:any) => foundUserItem._id === user._id);
        hasPlan = !!foundUser[0].hasPlan;
        if (!selectedUsersCopy.includes(user._id) && !employeePlansCheck({_id: user._id, hasPlan: hasPlan})) {
          selectedUsersCopy.push(user._id);
          setSelectedUsers(selectedUsersCopy);
        }
      }
    }
    setCheckLoading(false);
  };

  /*const handleCheckAll = () => {
    setCheckLoading(true);
    if (checkAll) {
      setCheckAll(false);
      //Remove current displayed plans
      currentPosts.forEach((post: any) => {
        if (checkedItems?.length > 0 && getCheckedIds().includes(post._id)) {
          const index = getCheckedIds().indexOf(post._id);
          if (index !== -1) {
            checkedItems.splice(index, 1);
          }
          setCheckedItems([...checkedItems]);
        }
      });
    } else {
      setCheckAll(true);
      //Add current displayed plans
      currentPosts.forEach((post: any) => {
        if (!getCheckedIds().includes(post._id) && !employeePlansCheck(post)) {
          setCheckedItems((oldArray: any) => [...oldArray, post]);
        }
      });
    }
    setCheckLoading(false);
  };*/

  //Helper state to track if at least one row checkbox can be toggled
  const [blockedRowCount, setBlockedRowCount] = useState<number>(0);
  /**
   * Hook to listen to checkboxes (checkedItems) and currently displayed rows (currentPosts).
   * Used to detect if all displayed rows are selected to handle the checkAll checkbox on top.
   */
  useEffect(() => {
    let blockedCount = 0;
    //count all Rows if they are either selected or blocked to figure out if every possible row is selected
    currentPosts.forEach((item: any) => {
      if (employeePlansCheck(item)) {
        //Blocked row
        blockedCount++;
      } else {
        /*if (getCheckedIds().includes(item._id)) {
          //Selected row
          checkedCount++;
        }*/
      }
    });
    setBlockedRowCount(blockedCount);
    /*if (
      checkedCount + blockedCount === currentPosts.length &&
      currentPosts.length > 0
    ) {
      //all rows selected - set checkAll to true to check the box in the list header
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }*/
  }, [currentPosts]);

  /**
   * Hook to update Redux on checked Items change
   */
  /*useEffect(() => {
    let array = checkedItems;
    if (checkedItems && checkedItems.length > 1) {
      array = checkedItems?.filter(
        (value: any, index: any, self: any) =>
          index === self.findIndex((t: any) => t === value)
      );
    }

    dispatch(changeSelectedRows(array));
  }, [checkedItems]);*/

  /*useEffect(() => {
    if (isCopyPlan) {
      setCheckedItems(addedPlans[0].employee.map((employee: any) => employee));
    }
  }, []);*/
  /**
   * Hook to initially listen to redux which rows are already checked
   */
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isCopyPlan) {
      //not empty
    } else {
      if (currentPlan.employee.length > 0) {
        /*currentPlan.employee.forEach((employee: any) => {
          setCheckedItems((oldItems: any) => [...oldItems, employee]);
        });*/
      }
    }
    dispatch(
      getUsers({
        limit: 5,
        skip: 0,
        branchId: branches,
        departmentId: departments,
        search,
        sortBy: sortValue,
        startDate,
        endDate,
        isActive: 1,
        forPlanCreation: 1
      })
    );
    setIsLoading(false);
  }, []);

  return (
    <div>
      {isLoading ? (
        <div style={{ maxHeight: "480px", overflow: "hidden" }}>
          <TableLoading rowsNumber={5} />
        </div>
      ) : (
        <>
          {Array.isArray(employee) ||
          (!Array.isArray(employee) && employee?.users?.length === 0) ||
          !checkedRows?.length ? (
              <NoRecordsFound text={t("warnings.dash_empty_table")} />
            ) : (
              <>
                <div style={{ overflowX: "auto" }}>
                  <table className="full-width page-table">
                    <thead>
                      <tr>
                        <th className="pl-10">
                          <div className="row">
                            {/*{Object.keys(updatePlanData).length <= 0 ? null : (
                              <Checkbox
                                id="selectAll"
                                handleCheckboxChange={() => {
                                //check if at least one displayed row is not blocked
                                  handleCheckAll();
                                }}
                                disabled={
                                  checkLoading ||
                                blockedRowCount >= currentPosts.length
                                }
                                checked={checkAll}
                              />
                            )}*/}
                            <Checkbox
                              id="selectAll"
                              handleCheckboxChange={() => {
                                //check if at least one displayed row is not blocked
                                handleCheckAll();
                              }}
                              disabled={
                                checkLoading ||
                                blockedRowCount >= currentPosts.length
                              }
                              checked={checkAll}
                            />
                            <TableSortedHeadItem
                              sortingData={sortingData}
                              text={t("dashboard.home.employee_list_th_1")}
                              name="firstName"
                              sortButton={sortButton}
                            />
                          </div>
                        </th>
                        <th>
                          <TableSortedHeadItem
                            sortingData={sortingData}
                            text={t("dashboard.home.employee_list_th_2")}
                            name="lastName"
                            sortButton={sortButton}
                          />
                        </th>
                        <th>
                          <TableSortedHeadItem
                            sortingData={sortingData}
                            text={t("dashboard.home.employee_list_th_3")}
                            name="email"
                            sortButton={sortButton}
                          />
                        </th>
                        <th>{t("dashboard.home.employee_list_th_7_1")}</th>
                        <th>{t("dashboard.home.employee_list_th_7")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((item: any, index: number) => (
                        <React.Fragment key={item._id}>
                          <tr className="spacer" />
                          <tr
                            className={
                              employeePlansCheck(item)
                                ? "blocked_item"
                                : selectedUsers.includes(item._id)
                                  ? "selected_row"
                                  : "table-item"
                            }
                          >
                            <td style={{ width: "14%" }}>
                              <div className="row">
                                {employeePlansCheck(item) ? (
                                  <p
                                    style={{
                                      marginLeft: "35px"
                                    }}
                                    className="list_check_text"
                                  >
                                    {item.firstName}
                                  </p>
                                ) : (
                                  <>
                                    <Checkbox
                                      id={item._id.toString()}
                                      handleCheckboxChange={() => {
                                        handleCheckboxChange(item._id);
                                      }}
                                      checked={selectedUsers.includes(item._id)}
                                    />
                                    <p className="list_check_text">
                                      {item.firstName}
                                    </p>
                                  </>
                                )}
                              </div>
                            </td>
                            <td style={{ width: "14%" }}>{item.lastName}</td>
                            <td style={{ width: "19%" }}>{item.email}</td>
                            <td style={{ width: "19%" }}>
                              {item.branch || "Not provided"}
                            </td>
                            <td style={{ width: "19%" }}>
                              {item.department || "Not provided"}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Pagination
                  postsPerPage={5}
                  totalPosts={employee && employee.totalUser}
                  paginate={paginate}
                />
              </>
            )}
        </>
      )}
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    employee: state.users.users,
    searchData: state.pagesSearch.employeeSearchData,
    selectedRows: state.selectedRows.selectedRows,
    users: state.users.users.users,
    company: state.company.company.company
  };
};

export default connect(mapStateToProps, null)(EmployeePlansList);
