import React, {useEffect, useState} from "react";
import invoicePlaceholder from "img/Dashboard/Orders/invoice_placeholder.png";
import {prepareImage} from "../../redux/actions/prepareImage";
import BeatLoader from "react-spinners/BeatLoader";

interface CustomProps {
	imageKey: any
}


const InvoiceImage = ({imageKey}:CustomProps) => {

  const [currentImage, setCurrentImage] = useState(invoicePlaceholder);

  const [loading, setLoading] = useState(true);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await prepareImage(imageKey);
        setCurrentImage(response);
        setLoading(false);
      } catch (e: any) {
        setLoading(false);
        setCurrentImage(invoicePlaceholder);
      }
    }
    fetchData();
    //Need to disable it, because eslint says, that I need to add
    //uploadedImage, but it will broke a logic
    // eslint-disable-next-line
	}, [imageKey])

  return(
    loading
      ? <BeatLoader size="14px" />
      :
      <img
        className="invoice_image"
        src={currentImage}
        alt="Invoice preview"
        title={imageKey}
      />
  );
};

export default InvoiceImage;
