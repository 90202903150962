import React from "react";
import "./NotFound.css";
import errorImg from "img/Dashboard/error_image.svg";
import Button from "../../Components/Buttons/Button";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function NotFoundPage() {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const backToHome = () => {
    navigate("/");
  };
  return (
    <div className="error_page_box" style={{minHeight: "100vh"}}>
      <h1 className="error_type">404</h1>
      <h2 className="error_page_title">{t("warnings.404_page_title")}</h2>
      <h3 className="error_page_text">{t("warnings.404_page_text")}</h3>
      <div className="center">
        <img src={errorImg} alt="error" />
      </div>
      <div className="error_btn">
        <Button
          buttonType="primary"
          buttonHtmlType="submit"
          buttonLabel={t("warnings.error_btn")}
          fullWidth={true}
          buttonHandler={backToHome}
        />
      </div>
    </div>
  );
}
