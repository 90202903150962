import React from "react";
import "./Switch.css";

interface ISwitch {
  checked: boolean;
  onChange: (value: boolean) => void;
  id: string;
  label: string;
  disabled?: boolean;
}

export default function Switch(props: ISwitch) {
  return(
    <div className="row">
      <label className="switch">
        <input
          disabled={props.disabled}
          type="checkbox"
          onChange={() => props.onChange(!props.checked)}
          id={props.id}
          name={props.id}
          checked={props.checked}
        />
        <span className="slider_switch round"></span>
      </label>
      <p className="simple_label" style={{marginTop: 0}}>
        {props.label}
      </p>
    </div>
  );
}
