import React, { useEffect, useState } from "react";
import "./ListStyles.css";
import Pagination from "../SimpleComponents/Pagination";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import {rewriteCurrency} from "../../utils/rewriteCurrency";
import ActionDotsPaymentsTransaction from "../SimpleComponents/ActionDotsPaymentsTransaction";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import Badge from "../Elements/Badge/Badge";
import {getTransactionsCategoryColor, getTransactionsCategoryName} from "../../utils/virtualCards/getTransactionsCategoryName";

interface IProps {
  transactions?: any;
  currentPage: number;
  pageLimit: number;
  paginate: (pageNumber: number) => void;
  loading: boolean;
  filters: any
}

function PaymentTransactionsList(props: IProps) {
  const { t } = useTranslation();

  /**
   * Setting initial state
   */
  useEffect(() => {
    if (props.transactions && props.transactions.data && props.transactions.data.length) {
      setSortedData(props.transactions.data);
    }
  }, [props.transactions?.data]);

  const [sortedData, setSortedData] = useState([]);

  // handle Paginate
  const currentPosts: any = sortedData;

  const [currentPage, setCurrentPage] = useState(1);

  useEffect(()=> {
    if(props.filters.skip === 0){
      setCurrentPage(1);
      props.paginate(1);
    }
  },[props.filters]);

  return (
    <>
      {
        props.loading
          ? <TableLoading />
          : props.transactions.data && props.transactions.data.length === 0 ? (
            <EmptyTableState />
          ) : (
            <>
              <div className="table_box">
                <div className="table_wrapper-overview">
                  <table style={{ width: "100%" }}>
                    <thead>
                      <tr>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.date")}
                        </th>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.time")}
                        </th>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.merchant")}
                        </th>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.category")}
                        </th>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.amount")}
                        </th>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.status")}
                        </th>
                        <th>
                          {t("dashboard.payments.overview.header_component.tabs_content.transaction_history.list.action")}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentPosts.map((item: any, index: number) => (
                        <React.Fragment key={item.invoiceId}>
                          <tr className="spacer" />
                          <tr className="table-item">
                            <td>{item.date}</td>
                            <td>{item.time}</td>
                            <td>{item.merchant}</td>
                            <td>
                              <Badge
                                color={getTransactionsCategoryColor(item.category)}
                                text={getTransactionsCategoryName(item.category)}
                              />
                              {/*{getCategoryLabel(item.category)}*/}
                            </td>
                            <td>{rewriteCurrency(item.amount.toFixed(2), "EUR")}</td>
                            <td>
                              <Badge
                                color={getTransactionsCategoryColor(item.transactionId.type)}
                                text={getTransactionsCategoryName(item.transactionId.type)}
                              />
                            </td>
                            <td>
                              {item.transactionId.type === "AUTHORISATION"
                                ? <ActionDotsPaymentsTransaction
                                  transactionId={item.transactionId.id}
                                />
                                : null
                              }
                            </td>
                          </tr>
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </>)
      }
      <Pagination
        currentPage={currentPage}
        postsPerPage={props.pageLimit}
        totalPosts={props.transactions && props.transactions.total}
        paginate={(newPage: number) => {
          if(newPage !== currentPage){
            //pagination for card list
            setCurrentPage(newPage);
            props.paginate(newPage);}
        }}
      />
    </>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    invoices: state.invoices.invoices,
    searchData: state.pagesSearch.employeeSearchData,
    selectedRows: state.selectedRows.selectedRows,
    filteringState: state.filters.invoiceFilters
  };
};

export default connect(mapStateToProps, null)(PaymentTransactionsList);
