import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import Checkbox from "../SimpleComponents/Checkbox";
import TableSortedHeadItem from "./TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import NoRecordsFound from "./NoRecordsList";
import {changeSelectedRows} from "redux/actions/selectedUsersFromListActions";
import Spacer from "../SimpleComponents/Spacer";
import {rewriteCurrency} from "../../utils/rewriteCurrency";
import {useTranslation} from "react-i18next";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import {getInvoices, updateInvoice} from "../../redux/actions/invoicesActions";
import {changeInvoicesFiltering} from "../../redux/actions/filteringActions";
import UpdatesStatus from "../Containers/UpdatesStatus";
import {isAllowed, userPermissions, userRoles} from "../../utils/permissionsAllow";

import {useNavigate} from "react-router-dom";
import queryString from "query-string";
import ActionDots, {IActionDotsAction} from "../Elements/ActionDots/ActionDots";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import Badge, {BadgeColorsEnum} from "../Elements/Badge/Badge";
import getBadgeDataForInvoiceStatus from "../Elements/Badge/helpers/getBadgeDataForInvoiceStatus";
import moment from "moment";
import {InvoiceMerchantTypeEnum} from "../../api/services/Invoices/invoices.interface";

interface CustomProps {
  invoices?: any;
  searchData?: any;
  selectedRows?: any;
  invoiceState: any;
  filteringState?: any;
  showChangeLog: (data: any) => void;
  clearSelections: boolean;
  updateClearSelections: (value: boolean) => void;
  pullData: () => void;
}

function OrdersList({
  invoices,
  filteringState,
  showChangeLog,
  clearSelections,
  updateClearSelections,
  pullData
}: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  interface Lookup {
    [index: string]: string;
  }

  const [pageLimit, setPageLimit] = useState(8);
  const [checkedItems, setCheckedItems] = useState<any>([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [showCustomIdentifierColumn, setShowCustomIdentifierColumn] = useState(false);
  const [showEmploymentTypeColumn, setShowEmploymentTypeColumn] = useState(false);
  const [actionLoading, setActionLoading] = useState<string | undefined>(undefined);

  // List dots actions
  const actions: IActionDotsAction[] = [
    {
      key: "detail",
      label: t("dashboard.overview.menu_1"),
      onClick: (item: any) => {
        window.localStorage.setItem("amount", item.totalClaim.toFixed(2));
        navigate(
          `/overview/${item.invoiceId}`,
          {state: { prevPath: location.pathname}}
        );
      }
    },
    {
      key: "approve",
      label: t("dashboard.overview.menu_2"),
      onClick: (item: any) => {
        setActionLoading(item.invoiceId);
        handleInvoice(2, item);
      },
      hide: () => !isAllowed({
        data: {
          roles: [
            userRoles.superAdmin,
            userRoles.admin
          ],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.invoices],
          },
        },
      })
    },
    {
      key: "reject",
      label: t("dashboard.overview.menu_3"),
      onClick: (item: any) => {
        setActionLoading(item.invoiceId);
        handleInvoice(3, item);
      },
      hide: () => !isAllowed({
        data: {
          roles: [
            userRoles.superAdmin,
            userRoles.admin
          ],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.invoices],
          },
        },
      })
    }
  ];

  const handleInvoice = (status:number, data: any) => {
    if (isAllowed({data: {
      roles: [userRoles.superAdmin, userRoles.custom, userRoles.admin],
      permissions: {
        isAdmin: true,
        permissionsArray: [userPermissions.invoices]
      }
    }})) {
      const postData = {
        "invoices": [
          {
            "id": data.invoiceId,
            "status": status,
            "description": ""
          }
        ]
      };
      setShowLoading(true);
      dispatch(updateInvoice(postData));
      setTimeout(() => {
        pullData();
      }, 300);
    } else {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.not_allowed")}
          status={2} />);
    }
    setActionLoading(undefined);
  };

  // Change limits of items in page
  const changePageLimit = (newLimit: number) => {
    if (newLimit !== pageLimit) {
      const changedState = { ...filteringState, limit: newLimit, skip: 0 };
      dispatch(getInvoices(changedState));
      setPageLimit(newLimit);
    }
  };

  useEffect(() => {
    if (clearSelections) {
      setCheckedItems([]), dispatch(changeSelectedRows);
      updateClearSelections(false);
    }
  }, [clearSelections]);

  /**
   * Setting initial state
   */
  useEffect(() => {
    if (invoices.data && invoices.data.length) {
      setShowLoading(false);
      setSortedData(invoices.data);
      const invoicesWithCustomIdentifier = invoices.data.filter((data:any) => data.customIdentifier);
      if (invoicesWithCustomIdentifier.length > 0) {
        setShowCustomIdentifierColumn(true);
      } else {
        setShowCustomIdentifierColumn(false);
      }
      const invoicesWithEmploymentType = invoices.data.filter((data:any) => data.employmentType);
      if (invoicesWithEmploymentType.length > 0) {
        setShowEmploymentTypeColumn(true);
      } else {
        setShowEmploymentTypeColumn(false);
      }
    }
  }, [invoices.data]);

  const [checkAll, setCheckAll] = useState(false);

  const [sortedData, setSortedData] = useState([]);

  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    "invoice.date": "down",
    branch: "up",
    department: "up",
    "invoice.merchant": "up",
    userName: "up",
    grandTotal: "up",
    totalClaim: "up"
  });

  const sortingData = (name: string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortValue === "up" ? "" : "-"}${name}`;
    const changedState = { ...filteringState, sortBy: postValue };
    dispatch(changeInvoicesFiltering(changedState));
    dispatch(getInvoices(changedState));
    setSortButton({ ...sortButton, [name]: sortValue });
  };

  const [currentPage, setCurrentPage] = useState(1);

  // handle Paginate
  const currentPosts: any = sortedData;
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      navigate(location.pathname + `?page=${pageNumber}`);
      const skip = (pageNumber - 1) * pageLimit;
      setShowLoading(true);
      const changedState = { ...filteringState, skip: skip };
      dispatch(changeInvoicesFiltering(changedState));
      dispatch(getInvoices(changedState));
    }
  };

  const parsedSearch = queryString.parse(location.search);
  useEffect(() => {
    setCurrentPage(parsedSearch.page ? +parsedSearch.page : 1);
  }, [parsedSearch]);
  const navigate = useNavigate();

  const [showLoading, setShowLoading] = useState(false);

  /**
   * Function to handle single checkbox changd. Used for toggling checkbox states.
   * @param item - any
   */
  const handleCheckboxChange = (item: any) => {
    if (checkedItems?.length > 0 && getCheckedIds().includes(item.invoiceId)) {
      const index = getCheckedIds().indexOf(item.invoiceId);
      if (index !== -1) {
        checkedItems.splice(index, 1);
      }
      setCheckedItems([...checkedItems]);
    } else {
      setCheckedItems([...checkedItems, item]);
    }
  };

  /**
   * Function to handle all checkbox changes. Used for toggling all checkboxes at the same time.
   */
  const handleCheckAll = () => {
    setCheckLoading(true);
    if (checkAll) {
      setCheckAll(false);
      //Remove current displayed plans
      currentPosts.forEach((post: any) => {
        if (
          checkedItems?.length > 0 &&
          getCheckedIds().includes(post.invoiceId)
        ) {
          const index = getCheckedIds().indexOf(post.invoiceId);
          if (index !== -1) {
            checkedItems.splice(index, 1);
          }
          setCheckedItems([...checkedItems]);
        }
      });
    } else {
      setCheckAll(true);
      //Add current displayed plans
      currentPosts.forEach((post: any) => {
        if (!getCheckedIds().includes(post.invoiceId)) {
          setCheckedItems((oldArray: any) => [...oldArray, post]);
        }
      });
    }
    setCheckLoading(false);
  };

  /**
   * Hook to listen to checkboxes (checkedItems) and currently displayed rows (currentPosts).
   * Used to detect if all displayed rows are selected to handle the checkAll checkbox on top.
   */
  useEffect(() => {
    let count = 0;
    //count all selected rows
    currentPosts.forEach((item: any) => {
      if (getCheckedIds().includes(item.invoiceId)) {
        count++;
      }
    });
    if (
      count === currentPosts.length &&
      !showLoading &&
      currentPosts.length > 0
    ) {
      //all rows selected
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkedItems, currentPosts]);

  /**
   * Hook to update Redux on checked Items change
   */
  useEffect(() => {
    dispatch(changeSelectedRows([...new Set(checkedItems)]));
  }, [checkedItems]);

  /**
   * Helper function to get the IDs of all selected rows, can also be used for index
   * @returns Array of IDs
   */
  const getCheckedIds = () => {
    return checkedItems.map((item: any) => item.invoiceId);
  };

  return invoices.data && invoices.data.length === 0 ? (
    <NoRecordsFound text={t("warnings.dash_empty_table")} />
  ) : (
    <>
      <div className="table_box">
        {showLoading ? (
          <TableLoading />
        ) : (
          <div className="table_wrapper-overview">
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th className="">
                    <div className="row">
                      {isAllowed({
                        data: {
                          roles: [
                            userRoles.superAdmin,
                            userRoles.admin,
                            userRoles.custom
                          ],
                          permissions: {
                            isAdmin: true,
                            permissionsArray: [userPermissions.invoices]
                          }
                        }
                      }) ? (
                          <Checkbox
                            disabled={checkLoading}
                            id="selectAll"
                            handleCheckboxChange={() => handleCheckAll()}
                            checked={checkAll}
                          />
                        ) : null}
                      <Spacer spacerWidth="1rem" />
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={t("dashboard.overview.th_1")}
                        name="invoice.date"
                        sortButton={sortButton}
                      />
                    </div>
                  </th>
                  <th>
                    <div
                      className="responsive-mr-40"
                      style={{ textAlign: "center" }}
                    >
                      {t("dashboard.overview.invoice_merchant_type")}
                    </div>
                  </th>
                  <th>
                    <div
                      className="responsive-mr-40"
                      style={{ textAlign: "center" }}
                    >
                      {t("dashboard.overview.th_10")}
                    </div>
                  </th>
                  <th>
                    <TableSortedHeadItem
                      sortingData={sortingData}
                      text={t("dashboard.overview.th_4")}
                      name="invoice.merchant"
                      sortButton={sortButton}
                    />
                  </th>
                  <th>
                    <TableSortedHeadItem
                      sortingData={sortingData}
                      text={t("dashboard.overview.th_5")}
                      name="userName"
                      sortButton={sortButton}
                    />
                  </th>
                  {showCustomIdentifierColumn
                    ? <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.overview.th_custom_identifier")}
                      </div>
                    </th>
                    : null
                  }
                  <th>
                    <div className="responsive-mr-40">
                      {t("dashboard.overview.th_6")}
                    </div>
                  </th>
                  <th>
                    <div className="responsive-mr-40">
                      {t("dashboard.overview.th_7")}
                    </div>
                  </th>
                  <th>
                    <div className="responsive-mr-40">
                      {t("dashboard.overview.th_3")}
                    </div>
                  </th>
                  <th>
                    <div
                      className="responsive-mr-40"
                      style={{ textAlign: "center" }}
                    >
                      {t("dashboard.overview.th_8")}
                    </div>
                  </th>
                  <th>
                    <div
                      className="responsive-mr-40"
                      style={{ textAlign: "center" }}
                    >
                      {t("dashboard.overview.th_11")}
                    </div>
                  </th>
                  <th style={{ textAlign: "center", padding: 0 }}>
                    <div className="responsive-mr-40">
                      {t("dashboard.overview.th_9")}
                    </div>
                  </th>
                </tr>
              </thead>
              <tbody>
                {currentPosts.map((item: any, index: number) => (
                  <React.Fragment key={item.invoiceId}>
                    <tr className="spacer" />
                    <tr
                      className={
                        getCheckedIds().includes(item.invoiceId)
                          ? "selected_row"
                          : "table-item"
                      }
                    >
                      <td>
                        <div className="row">
                          {isAllowed({
                            data: {
                              roles: [
                                userRoles.superAdmin,
                                userRoles.admin,
                                userRoles.custom
                              ],
                              permissions: {
                                isAdmin: true,
                                permissionsArray: [userPermissions.invoices]
                              }
                            }
                          }) ? (
                              <Checkbox
                                id={item.invoiceId.toString()}
                                handleCheckboxChange={() =>
                                  handleCheckboxChange(item)
                                }
                                checked={getCheckedIds().includes(item.invoiceId)}
                              />
                            ) : null}
                          <Spacer spacerWidth="1rem" />
                          <p className="list_check_text">
                            {moment.utc(item.date).format("DD.MM.YYYY HH:mm")}
                          </p>
                        </div>
                      </td>
                      <td>
                        <Badge
                          color={item.merchantType === InvoiceMerchantTypeEnum.RESTAURANT ? BadgeColorsEnum.yellow : BadgeColorsEnum.blue}
                          text={item.merchantType === InvoiceMerchantTypeEnum.RESTAURANT
                            ? t("overview.details.restaurant")
                            : t("overview.details.supermarket")}
                        />
                      </td>
                      <td>
                        {item.planName ? (
                          <div style={{ textAlign: "center" }}>
                            {item.planName}
                          </div>
                        ) : (
                          <Badge
                            color={BadgeColorsEnum.red}
                            text={t("dashboard.overview.no_plan")}
                          />
                        )}
                      </td>
                      <td style={{ width: "10%" }}>{item.merchant}</td>
                      <td>{item.employee}</td>
                      {showCustomIdentifierColumn
                        ? <td>{item.customIdentifier}</td>
                        : null
                      }
                      <td>
                        {rewriteCurrency(item.grandTotal.toFixed(2), "EUR")}
                      </td>
                      <td>
                        {rewriteCurrency(item.totalClaim.toFixed(2), "EUR")}
                      </td>
                      <td>{item.department}</td>
                      <td>
                        <Badge
                          color={getBadgeDataForInvoiceStatus(item.status).color}
                          text={getBadgeDataForInvoiceStatus(item.status).text}
                        />
                      </td>
                      <td style={{ height: "auto" }}>
                        <UpdatesStatus
                          showChangeLog={showChangeLog}
                          data={item.changelog}
                          style={{ margin: "auto", display: "block" }}
                        />
                      </td>
                      <td style={{ padding: 0 }}>
                        <ActionDots
                          loadingItem={actionLoading}
                          loadingIdentifier={"invoiceId"}
                          actions={actions}
                          rootState={item}
                        />
                      </td>
                    </tr>
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <Pagination
        currentPage={currentPage}
        postsPerPage={pageLimit}
        totalPosts={invoices && invoices.totalInvoice}
        paginate={paginate}
        limit={pageLimit}
        changeLimits={(limit: number) => {
          setShowLoading(true);
          changePageLimit(limit);
        }}
      />
    </>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    invoices: state.invoices.invoices,
    searchData: state.pagesSearch.employeeSearchData,
    selectedRows: state.selectedRows.selectedRows,
    filteringState: state.filters.invoiceFilters
  };
};

export default connect(mapStateToProps, null)(OrdersList);
