import axios from "axios";
import environment from "../../environment";
import {getToken} from "../../utils/cookies/tokensCookie";
import {urls} from "../../api/urls";

export async function prepareImage(key: string): Promise<string> {
  const token = getToken();
  let imageUrl = "";
  const image = await axios.get(
    `${environment.baseUrl}${urls.files.files}/${key}`, {
      headers: {
        "Authorization": `Bearer ${token}`
      }
    });

  if (image.status <= 201) {
    imageUrl = image.data;
  } else {
    imageUrl = "/images/user_avatar.svg";
  }

  return imageUrl;
}



