import React, { useEffect, useState } from "react";
import "./ListStyles.css";
import Checkbox from "../SimpleComponents/Checkbox";
import TableSortedHeadItem from "./TableSortedHeadItem";
import Pagination from "../SimpleComponents/Pagination";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import { handleEmployeeCheckBox } from "redux/actions/employeePageCheckboxActions";
import Spacer from "../SimpleComponents/Spacer";
import { changeSelectedRows } from "../../redux/actions/selectedUsersFromListActions";
import { useTranslation } from "react-i18next";
import {
  clearUserMessages,
  getHrZoneUsers,
  getUsers,
  showUserLoading
} from "../../redux/actions/usersActions";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import { toast } from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {
  isAllowed,
  userPermissions,
  userRoles
} from "../../utils/permissionsAllow";
import Tooltip from "../Tooltip/Tooltip";
import { getHrZoneCompanies } from "redux/actions/corporateActions";
import { HrZoneAuthInterface } from "Components/Sidebar/SideBarItemsClass";


interface CustomProps {
  employee: any;
  searchData?: any;
  selectedRows?: any;
  usersMessagesState?: any;
  user: any;
  offices?: any;
  isMasterHr?: number;
  clearSelections: boolean;
  hrZoneAuth: HrZoneAuthInterface
}

function CompanyList(props: CustomProps) {
  interface Lookup {
    [index: string]: string;
  }
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();

  const [pageLimit, setPageLimit] = useState(8);

  // Change limits of items in page
  const changePageLimit = (newLimit: number) => {
    if (newLimit !== pageLimit) {
      dispatch(
        getHrZoneCompanies({
          perPage: newLimit,
          page: 1,
		  ...filter,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
        })
      );
      setPageLimit(newLimit);
    }
  };

  const parsedSearch = queryString.parse(location.search);

  useEffect(() => {
	  if(props.hrZoneAuth.sessionKey.length > 0){
      dispatch(showUserLoading());
      setShowLoading(true);
      dispatch(
		  getHrZoneCompanies({
          perPage: pageLimit,
		  ...filter,
          page: parsedSearch.page ? +parsedSearch.page : 1,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
		  })
      );
	  }

  }, [ props.hrZoneAuth.sessionKey]);

  useEffect(() => {
    setCurrentPage(parsedSearch.page ? +parsedSearch.page : 1);
  }, [parsedSearch]);
  const navigate = useNavigate();

  useEffect(() => {
    if (props.offices?.data?.length > 0) {
      setSortedData(props.offices.data);
    }
    else{
      setCurrentPage(1);
      paginate(1);
    }
  }, [props.offices.data]);

  // handling checkboxes
  const [checkedRows, setCheckedRows] = useState<any[]>([]);
  const [checkAll, setCheckAll] = useState(false);

  // Handle response messages
  useEffect(() => {
    if (props.usersMessagesState.success === "delete_user") {
      dispatch(
        getHrZoneCompanies({
          perPage: pageLimit,
		  ...filter,
          page: parsedSearch.page ? +parsedSearch.page : 1,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
        })
      );
      dispatch(clearUserMessages());
      dispatch(handleEmployeeCheckBox([]));
      dispatch(changeSelectedRows([]));
    } else if (props.usersMessagesState.success === "add_one_employee") {
      dispatch(
        getHrZoneCompanies({
          perPage: pageLimit,
		  ...filter,
          page: parsedSearch.page ? +parsedSearch.page : 1,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
        })
      );
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_created")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
      dispatch(clearUserMessages());
    } else if (props.usersMessagesState.error) {
      if (props.usersMessagesState.error === "user_exist") {
        toast.error(
          <CustomToastContainer
            message={t("warnings.warning_user_exists")}
            status={3}
          />,
          {
            autoClose: 5000
          }
        );
        dispatch(clearUserMessages());
      } else if (props.usersMessagesState.error === "delete_user_admin") {
        toast.error(
          <CustomToastContainer
            message={t("warnings.last_admin")}
            status={3}
          />,
          {
            autoClose: 5000
          }
        );
        dispatch(clearUserMessages());
      } else {
        toast.error(
          <CustomToastContainer
            message={t("warnings.warning_server_error")}
            status={3}
          />,
          {
            autoClose: 5000
          }
        );
        dispatch(clearUserMessages());
      }
    } else if (props.usersMessagesState.success === "update_employee") {
      dispatch(clearUserMessages());
      dispatch(
        getHrZoneCompanies({
          perPage: pageLimit,
		  ...filter,
          page: parsedSearch.page ? +parsedSearch.page : 1,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
        })
      );
      toast.success(
        <CustomToastContainer
          message={t("warnings.success_user_updated")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
    } else if (props.usersMessagesState.success === "invite_users") {
      dispatch(clearUserMessages());
      toast.success(
        <CustomToastContainer
          message={t("warnings.send_invite_success")}
          status={1}
        />,
        {
          autoClose: 5000
        }
      );
    } else if (props.usersMessagesState.error === "update_employee") {
      dispatch(clearUserMessages());
      toast.error(
        <CustomToastContainer
          message={t("warnings.warning_server_error")}
          status={3}
        />,
        {
          autoClose: 5000
        }
      );
    }
  }, [props.usersMessagesState]);

  // Listening checkbox changes and push to selected Users
  useEffect(() => {
    const uniqueArr = Array.from(
      new Set([...props.selectedRows, ...checkedRows])
    );
    const filteredArray = uniqueArr.filter(
      (item: any) => item.isClicked === true
    );
    const newArray = filteredArray.filter(
      (v: any, i: number, a: any) =>
        a.findIndex((fi: any) => fi._id === v._id) === i
    );
    dispatch(changeSelectedRows(newArray));
    //Need to disable it, because eslint says, that I need to add
    //selectedRows as dependency but it will woke
    // that function in every render
    // eslint-disable-next-line
  }, [checkedRows]);

  const [sortedData, setSortedData] = useState([]);
  useEffect(() => {
    if (props.searchData.length > 0) {
      setSortedData(props.searchData);
    }
  }, [props.searchData]);

  // set which sort is selected
  const [sortButton, setSortButton] = useState({
    name: "up",
    street: "up",
    city: "up",
    zip : "up"
  });

  const [sortValue, setSortValue] = useState("");

  const [filter, setFilter] = useState({
	  sortby: "",
	  sortdir: ""
  });

  const sortingData = (name: string) => {
    const sortButtonClone: Lookup = sortButton;
    const sortingValue = sortButtonClone[name] === "up" ? "down" : "up";
    const postValue = `${sortingValue === "up" ? "" : "-"}${name}`;
    setSortValue(postValue);

    setFilter({
      sortby: name,
      sortdir: sortingValue === "up" ? "ASC" : "DESC"
    });

    setShowLoading(true);
    dispatch(
      getHrZoneCompanies({
        perPage: pageLimit,
        page: parsedSearch.page ? +parsedSearch.page : 1,
        hr_email: props.user.email,
        sortby: name,
        sortdir: sortingValue === "up" ? "ASC" : "DESC",
        sessionKey: props.hrZoneAuth?.sessionKey,
      })
    );
    setSortButton({ ...sortButton, [name]: sortingValue });
  };

  // handle Paginate
  const currentPosts = sortedData;
  const [currentPage, setCurrentPage] = useState(
    Number(location.search.replace("?page=", "")) || 1
  );
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      setShowLoading(true);

      navigate(location.pathname + `?page=${pageNumber}`);
      const skip = (pageNumber - 1) * pageLimit;
      setShowLoading(true);
      dispatch(
        getHrZoneCompanies({
          perPage: pageLimit,
		  ...filter,
          page: pageNumber,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth?.sessionKey,
        })
      );
    }
  };

  const [showLoading, setShowLoading] = useState(false);
  const [checkedItems, setCheckedItems] = useState<any[]>([]);
  const [checkLoading, setCheckLoading] = useState(false);

  /**
   * Function to handle all checkbox changes. Used for toggling all checkboxes at the same time.
   */
  const handleCheckAll = () => {
    if (!checkLoading) {
      setCheckLoading(true);
      setCheckAll(!checkAll);
      if (checkAll) {
        //Remove current displayed plans
        currentPosts.forEach((post: any) => {
          if (checkedItems?.length > 0 && getCheckedIds().includes(post.id)) {
            const index = getCheckedIds().indexOf(post.id);
            if (index !== -1) {
              checkedItems.splice(index, 1);
            }
          }
        });
        setCheckedItems([...checkedItems]);
      } else {
        //Add current displayed plans
        currentPosts.forEach((post: any) => {
          if (!getCheckedIds().includes(post.id)) {
            setCheckedItems((oldArray) => [...oldArray, post]);
          }
        });
      }
    }
    setCheckLoading(false);
  };

  /**
   * Function to handle single checkbox changd. Used for toggling checkbox states.
   * @param item - any
   */
  const handleCheckboxChange = (item: any) => {
    if (checkedItems?.length > 0 && getCheckedIds().includes(item.id)) {
      //Getting index to remove the item from checkedItems
      const index = getCheckedIds().indexOf(item.id);
      if (index !== -1) {
        checkedItems.splice(index, 1);
      }
      setCheckedItems([...checkedItems]);
    } else {
      //Adding the item to checkedItems since it's not in there yet
      setCheckedItems([...checkedItems, item]);
    }
  };

  /**
   * Helper function to get the IDs of all selected rows
   * @returns Array of IDs
   */
  const getCheckedIds = () => {
    return checkedItems.map((item: any) => item.id);
  };

  /**
   * Hook to listen to checkboxes (checkedItems) and currently displayed rows (currentPosts).
   * Used to detect if all displayed rows are selected to handle the checkAll checkbox on top.
   */
  useEffect(() => {
    let count = 0;
    //count all selected rows and unavailable rows to detect if all available rows are selected
    currentPosts.forEach((post: any) => {
      if (getCheckedIds().includes(post.id)) {
        count++;
      }
    });
    if (
      count === currentPosts.length &&
      !showLoading &&
      currentPosts.length > 0
    ) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [checkedItems, currentPosts]);

  /**
   * Hook to update Redux on checked Items change
   */
  useEffect(() => {
    setShowLoading(false);
  }, [ currentPosts]);

  /**
   * Hook to update Redux on checked Items change
   */
  useEffect(() => {
    dispatch(changeSelectedRows(checkedItems));
    setShowLoading(false);
  }, [checkedItems, currentPosts]);

  useEffect(() => {
    setCheckedItems([]);
  },[props.clearSelections]);

  return (
    <div>
      { props.offices.data === null ? (
        <EmptyTableState
          style={{ height: "600px", backgroundColor: "transparent" }}
        />
      ) : (
        <div className="table_box">
          {showLoading ? (
            <TableLoading />
          ) : (
            <div className="table_wrapper-overview">
              <table style={{ width: "100%" }}>
                <thead>
                  <tr>
                    <th className="pl-10">
                      <div className="row">
					  {props.isMasterHr ? (
                            <Checkbox
                              disabled={checkLoading}
                              id="selectAll"
                              handleCheckboxChange={() => handleCheckAll()}
                              checked={checkAll}
                            />
                          ) : null}
                        <Spacer spacerWidth="1rem" />
                        <TableSortedHeadItem
                          sortingData={sortingData}
                          text={"Name"}
                          name="name"
                          sortButton={sortButton}
                        />
                      </div>
                    </th>
                    <th>
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={"Straße"}
                        name="street"
                        sortButton={sortButton}
                      />
                    </th>
                    <th>
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={"Stadt"}
                        name="city"
                        sortButton={sortButton}
                      />
                    </th>

                    <th>
                      <TableSortedHeadItem
                        sortingData={sortingData}
                        text={"PLZ"}
                        name="zip"
                        sortButton={sortButton}
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((item: any, index: number) =>
                    index < pageLimit ? (
                      <React.Fragment key={item.id}>
                        <tr className="spacer" />
                        <tr
                          className={
                            getCheckedIds().includes(item.id)
                              ? "selected_row"
                              : "table-item"
                          }
                        >
                          <td>
                            <div className="row">
                              {props.isMasterHr ? (
                                  <Checkbox
                                    id={item.id.toString()}
                                    handleCheckboxChange={() =>
                                      handleCheckboxChange(item)
                                    }
                                    checked={getCheckedIds().includes(item.id)}
                                  />
                                ) : null}
                              <Spacer spacerWidth="1rem" />
                              <p className="list_check_text">{item.name}</p>
                            </div>
                          </td>
                          <td>{item.street}</td>
                          <td>{item.city}</td>

                          <td>{item.zip}</td>
                        </tr>
                      </React.Fragment>
                    ) : (
                      <></>
                    )
                  )}
                </tbody>
              </table>
            </div>
          )}
        </div>
      )}
      {props.offices.data === null  ||
      showLoading ? null : (
          <Pagination
            currentPage={currentPage}
            postsPerPage={pageLimit}
            totalPosts={props.offices.data && props.offices.totalOffices}
            paginate={paginate}
            limit={pageLimit}
            changeLimits={(limit: number) => {
              changePageLimit(limit);
            }}
          />
        )}
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    searchData: state.pagesSearch.employeeSearchData,
    selectedRows: state.selectedRows.selectedRows,
    usersMessagesState: state.users,
    company: state.company,
    offices: state.corporates.corporates,
    hrZoneAuth: state.hrZone
  };
};

export default connect(mapStateToProps, null)(CompanyList);