import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";

import Plans from "./create";
import PlansDetailPage from "./detail";
import PlansScreen from "./page";
import { changeSidebarTab } from "../../redux/actions/sidebarActions";
import {
  isAllowed,
  userPermissions,
  userRoles
} from "../../utils/permissionsAllow";
import PlanBillingPage from "./billing.page";
import PlanBillingCalendar from "./billingCalendar.page";

export default function PlansRoute() {
  const dispatch = useDispatch();

  return (
    <Routes>
      <Route path="/" element={<PlansScreen />} />
      {/*Create plan route*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.plans]
          }
        }
      }) ? (
          <Route path="/create" element={<Plans />} />
        ) : (
          <Route path="/create" element={<Navigate to="/plans" />} />
        )}

      {/*Plan detail route*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.plans]
          }
        }
      }) ? (
          <Route path="/:planId" element={<PlansDetailPage />} />
        ) : (
          <Route path="/:planId" element={<Navigate to="/plans" />} />
        )}

      {/*Update plan route*/}
      {isAllowed({
        data: {
          roles: [userRoles.superAdmin, userRoles.admin, userRoles.custom],
          permissions: {
            isAdmin: true,
            permissionsArray: [userPermissions.plans]
          }
        }
      }) ? (
          <Route path="/update/:planId" element={<Plans update />} />
        ) : (
          <Route path="/update/:planId" element={<Navigate to="/plans" />} />
        )}
      <Route path="/billing/:planId" element={<PlanBillingPage />} />
      <Route
        path="/billing/:planId/:userId"
        element={<PlanBillingCalendar />}
      />
      <Route element={<Navigate to="/error" />} />
    </Routes>
  );
}
