import "./DownloadBox.css";

import appleStore from "img/Dashboard/app-store-icon.png";
import googlePlayImg from "img/Dashboard/google-play-icon.png";
import React from "react";
import { useTranslation } from "react-i18next";

import downloadImg from "../../img/dash_download_img.svg";
import {appStoreLink, googlePlayLink} from "../../contstants";

const DownloadBox = () => {
  const { t } = useTranslation();

  return (
    <div className="download-wrapper">
      <div className="center row">
        <div style={{ paddingLeft: "1rem" }}>
          <h2>{t("dashboard.home.bottom_download")}</h2>
          <div className="row mt-10">
            <a href={appStoreLink} target="_blank" rel="noreferrer">
              <img src={appleStore} alt="appStore" className="download_btn" />
            </a>
            <a href={googlePlayLink} target="_blank" rel="noreferrer">
              <img
                src={googlePlayImg}
                alt="googlePlay"
                className="download_btn"
              />
            </a>
          </div>
        </div>
      </div>
      <div className="download-app-image">
        <img src={downloadImg} alt="download" />
      </div>
    </div>
  );
};

export default DownloadBox;
