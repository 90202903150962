import React from "react";
import "./PurpleContainer.css";

const PurpleContainer = (props?: any) => {
	
  return(
    <div className="purple-container secondary-container">
      {props.children}
    </div>
  );
};

export default PurpleContainer;
