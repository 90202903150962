import {
  GET_MERCHANTS_ERROR,
  GET_MERCHANTS_SUCCESS,
  getMerchantsDispatchTypes
} from "../types/getMercahtsTypes";

interface DefaultStateI {
	error?: boolean,
	merchants?: any
}

const initialState:DefaultStateI = {
  error: false,
  merchants: []
};

export const getMerchantsReducer = (
  state: DefaultStateI = initialState,
  action: getMerchantsDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case GET_MERCHANTS_SUCCESS:
    return {
      error: false,
      merchants: action.payload
    };
  case GET_MERCHANTS_ERROR:
    return {
      error: true
    };
  default:
    return state;
  }
};
