import "./style.css";

import React, { Suspense, useEffect, useRef, useState } from "react";
import { connect, useDispatch } from "react-redux";
import {
  Routes,
  Route,
  Navigate,
  useLocation,
  useNavigate
} from "react-router-dom";

import Dashboard from "./page";
import NotificationCenter from "../notification/route";
import UserSettingsPage from "../userSettings/page";
import { getCompanyData } from "../../redux/actions/companiesActions";
import { getUserInfo } from "../../redux/actions/usersActions";
import { RootState } from "../../redux/reducers/rootReducer";
import { getToken } from "../../utils/cookies/tokensCookie";
import {
  getUserPermissionType,
  getUserRoles
} from "../../utils/cookies/userRolesCookies";
import NotFoundPage from "../error_pages/NotFoundPage";
import EmployeeRoute from "../employee/route";
import GeneralInfoRoute from "../general_info/route";
import OverviewRoute from "../overview/route";
import PlansRoute from "../plans/route";
import ServerErrorPage from "../error_pages/ServerErrorPage";
import DashboardLoading from "Components/LoadingPlaceholders/DashboardLoading";
import Sidebar from "Components/Sidebar/Sidebar";
import HeaderComponentWithUser from "Components/Header/HeaderComponentWithUser";
import OnboardingRouter from "../payments/router";
import AgbsPage from "../userSettings/agbs/page";
import HrZoneRoute from "pages/hrzone/route";
import { useTranslation } from "react-i18next";
import EmployeePaymentsPage from "../employeePayments/employeePaymentsPage";
import {getMagentoData, getUserCompany, getUserId} from "../../utils/cookies/userCookies/userCookies";
import { fetchHrZoneAuth } from "redux/actions/hrZoneAuthActions";
import { HrZoneAuthInterface } from "Components/Sidebar/SideBarItemsClass";
import { GET_USER_INFO } from "redux/types/userTypes";
import PartnerPortalRoute from "../partner-portal/route";
import moduleIsActivated from "../../utils/moduleIsActivated";
import SettingsPage from "pages/settings/page";

export const magentoData = {
  isHr: false,
  loggedIn: false,
  token: "",
  portalAccess: false
};

interface IProps {
  expiredJWT?: any;
  sidebar?: boolean;
  company?: any;
  user?: any;
  hrZoneAuth?: HrZoneAuthInterface
}

function DashboardPage({ expiredJWT, sidebar = false, company, user, hrZoneAuth }: IProps) {
  const token = getToken();
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const magentoUser = getUserId({withSplit: true});
  const magentoData = getMagentoData();
  const {t} = useTranslation();

  // Get company information
  useEffect(() => {

    if (getUserCompany() && getUserCompany() !== "magento_company") {
      dispatch(getCompanyData(getUserCompany()));
    }
    if (!token || expiredJWT) {
      navigate(`/login?path=${window.location.pathname}`);
    } else {
      if(magentoUser && magentoUser[0] === "magento_user"){
        dispatch({
          type: GET_USER_INFO,
          payload: {user: {
            email: magentoUser[1]
          }}
			  });
      }else{
        dispatch(getUserInfo());
      }

    }
  }, [dispatch, token, navigate, expiredJWT, getUserRoles]);

  const currentPage = location.pathname;

  const customRoleRedirect = (permissions: string) => {
    if (permissions?.includes("1")) {
      return <GeneralInfoRoute />;
    } else if (permissions?.includes("3")) {
      return <EmployeeRoute />;
    } else if (permissions?.includes("2")) {
      return <PlansRoute />;
    } else if (permissions?.includes("4")) {
      return <OverviewRoute />;
    } else if (permissions?.includes("5")) {
      return <UserSettingsPage />;
    } else {
      return <NotFoundPage />;
    }
  };

  //State which checks if the hrzone auth got fetched
  const [fetchedHrZone, setFetchedHrZone] = useState(false);
  const [amountFetched, setAmountFetched] = useState(0);
  useEffect(( ) => {
    if(user?.email && (hrZoneAuth)){
      if (!hrZoneAuth.fetched) {
        dispatch(fetchHrZoneAuth(user.email));
        setFetchedHrZone(true);
        setAmountFetched(amountFetched+1);
      }
	  if(magentoUser && magentoUser[0] === "magento_user"){
        if (!location.pathname.includes("/hrzone/") && !location.pathname.includes("/partner-portal/") && hrZoneAuth.url?.length > 0 && hrZoneAuth.sessionKey?.length > 0){
          navigate("/hrzone/dashboard");
        }
        if (!location.pathname.includes("/hrzone/") && !location.pathname.includes("/partner-portal/") && moduleIsActivated({company: company.company, module: "PartnerPortal", magentoUser: magentoUser})) {
          navigate("/partner-portal/profile");
        }
	  }
    }
  },[user, fetchedHrZone, hrZoneAuth]);

  //Hook which listens to the redux state of the hrzone auth and clears the local state if the sessionkey times out
  useEffect(() => {
	  if(!hrZoneAuth?.fetched){
      setFetchedHrZone(false);
	  }
  },[hrZoneAuth && !hrZoneAuth.fetched]);


  const hrModule = () => {
    return (magentoData.isHr || (magentoUser && magentoUser[0] === "magento_user") &&
      hrZoneAuth && hrZoneAuth.url?.length > 0 && hrZoneAuth.sessionKey?.length > 0) &&
		<>
		  <Route path="/hrzone/*" element={<HrZoneRoute />} />
		  <Route
		    path="/hrzone"
		    element={<Navigate to="/hrzone/dashboard" replace />}
		  />
		</>;
  };

  const partnerPortal = ():any => {
    return moduleIsActivated({company: company.company, module: "PartnerPortal", magentoUser: magentoUser}) && process.env.REACT_APP_ENV !== "production"
      ? <>
        <Route path="/partner-portal/*" element={<PartnerPortalRoute />} />
        <Route
          path="/partner-portal"
          element={<Navigate to="/partner-portal/profile" replace />}
        />
      </>
      : null;
  };

  const pageRendering = () => {
    if (company?.company?.company?.modules[0]?.isActivated) {
      const userType = getUserRoles();
      if (userType === "3") {
        const permissions = getUserPermissionType();
        if (permissions?.length) { // add check for lunch module
          return (
            <>
		  <Route path="/" element={customRoleRedirect(permissions)} />
              <Route
                path="/general-info/*"
                element={
                  permissions?.includes("1") ? (
                    <GeneralInfoRoute />
                  ) : (
                    <NotFoundPage />
                  )
                }
              />
              <Route
                path="/plans/*"
                element={
                  permissions?.includes("2") ? <PlansRoute /> : <NotFoundPage />
                }
              />
              <Route
                path="/employee/*"
                element={
                  permissions?.includes("3") ? (
                    <EmployeeRoute />
                  ) : (
                    <NotFoundPage />
                  )
                }
              />
              <Route
                path="/overview/*"
                element={
                  permissions?.includes("4") ? (
                    <OverviewRoute />
                  ) : (
                    <NotFoundPage />
                  )
                }
              />
              <Route
                path="/userSettings/*"
                element={
                  permissions?.includes("5") ? <UserSettingsPage /> : <NotFoundPage />
                }
              />
              <Route element={<NotFoundPage />} />
		  </>
          );
        }
      } else if (userType === "4") {
        return (
          <>
            <Route path="/*" element={<OverviewRoute user />} />
            <Route path="/overview/*" element={<OverviewRoute user />} />
            <Route path="/general-info/*" element={<GeneralInfoRoute user />} />
            <Route path="/employeePayments/*" element={<EmployeePaymentsPage />} />
            <Route path="/employeePaymentsVerification" element={<EmployeePaymentsPage />} />
            <Route element={<Navigate to="/overview" />} />
          </>
        );
      } else {
        return (
          <>
            <Route path="*" element={<NotFoundPage />} />
            <Route path="/" element={<Dashboard />} />
            <Route path="/dashboard" element={<Navigate to="/" />} />
            <Route path="/employeePayments/*" element={<EmployeePaymentsPage />} />
            <Route path="/employeePaymentsVerification" element={<EmployeePaymentsPage />} />
            <Route path="/general-info/*" element={<GeneralInfoRoute />} />
            <Route path="/plans/*" element={<PlansRoute />} />
            <Route path="/employee/*" element={<EmployeeRoute />} />
            <Route path="/overview/*" element={<OverviewRoute />} />
            <Route path="/userSettings/*" element={<UserSettingsPage />} />
            <Route path="/settings/*" element={<SettingsPage />} />
            <Route path="/payments/*" element={<OnboardingRouter />} />
            <Route path="/agb" element={<AgbsPage types={0} />} />
            <Route path="/agb-cards" element={<AgbsPage types={1} />} />
            <Route path="/about-cards" element={<AgbsPage types={2} />} />
            <Route path="/privacy-policy" element={<AgbsPage types={3} />} />
            <Route path="/imprint" element={<AgbsPage types={4} />} />
            <Route
              path="/notification-center/*"
              element={<NotificationCenter />}
            />
            <Route path="/server-error/*" element={<ServerErrorPage />} />
          </>
        );
      }
    }
  };


  return token && (user || magentoUser && magentoUser[0] === "magento_user") && (company.company?.company?.modules || getUserCompany() == "magento_company" ) && (hrZoneAuth?.fetched ?? true) ? (
    <Suspense fallback={<DashboardLoading />}>
      <div data-testid="dashboard_routes_container" style={{ width: "100vw" }}>
        <HeaderComponentWithUser
          withSearch={false}
          notification={true}
          withSidebar={true}
        />
        <div
          className={sidebar ? "dashboard_container" : "dashboard_hide_sidebar"}
        >
          <Sidebar showResponsiveSidebar={sidebar} />
          <div>
			  {currentPage
			  &&
			  <Routes>
				  {pageRendering()}
				  {hrModule()}
				  {partnerPortal()}
			  </Routes>
            }</div>
        </div>
      </div>
    </Suspense>
  ) : (
    <DashboardLoading />
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    sidebar: state.sidebar.sidebarCon,
    expiredJWT: state.notificationData.error,
    company: state.company,
    user: state.users?.userInfo.user,
    hrZoneAuth: state.hrZone
  };
};

export default connect(mapStateToProps, null)(DashboardPage);
