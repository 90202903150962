// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.banner_title {
    padding-left: 40px;
    padding-top: 23px;
}

.banner_title > p {
    font-size: 17px;
    font-weight: 300;
    padding-top: 10px;
    color: #0E0A40;
}

.banner_title > h2 {
    font-size: 24px;
    font-weight: 700;
    padding-top: 10px;
    color: #0E0A40;
}

@media (max-width: 600px) {
    .banner_title > h2 {
        font-size: 22px;
        display: flex;
        justify-content: center;
    }
    .banner_title {
        padding-left: 16px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/SimpleComponents/BannerWithTitle.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,iBAAiB;AACrB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,iBAAiB;IACjB,cAAc;AAClB;;AAEA;IACI;QACI,eAAe;QACf,aAAa;QACb,uBAAuB;IAC3B;IACA;QACI,kBAAkB;IACtB;AACJ","sourcesContent":[".banner_title {\n    padding-left: 40px;\n    padding-top: 23px;\n}\n\n.banner_title > p {\n    font-size: 17px;\n    font-weight: 300;\n    padding-top: 10px;\n    color: #0E0A40;\n}\n\n.banner_title > h2 {\n    font-size: 24px;\n    font-weight: 700;\n    padding-top: 10px;\n    color: #0E0A40;\n}\n\n@media (max-width: 600px) {\n    .banner_title > h2 {\n        font-size: 22px;\n        display: flex;\n        justify-content: center;\n    }\n    .banner_title {\n        padding-left: 16px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
