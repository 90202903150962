import {getToken} from "../../../utils/cookies/tokensCookie";
import environment from "../../../environment";
import {urls} from "../../urls";
import axios from "axios";
import qs from "qs";
import {IChangeCompanyAdmin, IChangeCompanyAdminResponse} from "./auth.interface";

export class authService {
  constructor() {}

  async changeCompanyAdmin(params: IChangeCompanyAdmin): Promise<IChangeCompanyAdminResponse> {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.auth.changeCompanyAdmin}`;
    return await axios.post(fullUrl, params,{
      headers: { Authorization: `Bearer ${token}` },
      paramsSerializer: (params: any) => qs.stringify(params),
    })
      .then((res: any) => {
        return {
          status: true,
          data: res.data
        };
      })
      .catch((err: any) => {
        console.error(err);
        return {
          status: false
        };
      });
  }

}
