export const GET_WEAVE_CORPORATE = "GET_WEAVE_CORPORATE";
export const SHOW_WEAVR_LOADING = "SHOW_WEAVR_LOADING";
export const SHOW_WEAVR_ERROR = "SHOW_WEAVR_ERROR";

interface getWeavrCorporate {
  type: typeof GET_WEAVE_CORPORATE
  payload: any
}

interface showWeavrLoading {
  type: typeof SHOW_WEAVR_LOADING
}

interface showWeavrError {
  type: typeof SHOW_WEAVR_ERROR
  payload: any
}


export type weavrTypes = getWeavrCorporate | showWeavrLoading | showWeavrError
