import ContentContainer from "Components/Containers/ContentContainer";
import React from "react";
import Checklist from "../../../Components/Widgets/Checklist";
import MainSpace from "../../../Components/Containers/MainSpace";
import queryString from "query-string";
import {useLocation} from "react-router-dom";
import LocalMain from "../../../Components/Widgets/Checklist/LocalChecklist";
import "../../../Components/Widgets/Checklist/index.css";

export default function PartnerPortalCreateDealPage() {
  const location = useLocation();
  const parsedSearch: any = queryString.parse(location.search) || "";

  return(
    <ContentContainer>
      <MainSpace>
        {parsedSearch && parsedSearch.deal === "local"
          ? <LocalMain />
          : <Checklist />
        }
      </MainSpace>
    </ContentContainer>
  );
}
