// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.simple_input.phoneNumber {
    padding-left: 60px;
}

.phoneNumber_flag {
    position: absolute;
    display: flex;
    align-items: center;
    left: 10px;
    cursor: pointer;
    padding-top: 18px;
}

.phoneNumber_flag > img {
    width: 9px;
    height: 9px;
    margin-left: 5px;
    transform: rotateZ(180deg);
}

.phone_number_input_dropdown {
    position: absolute;
    width: 100%;
    background-color: #FFFFFF;
    box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);
    border-radius: 10px;
    top: 80px;
    z-index: 2;
}

.phone_number_input_dropdown_item {
    padding: 7px;
    font-size: 12px;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.phone_number_input_dropdown_item:first-child {
    border-radius: 10px 10px 0 0;
    padding-top: 14px;
}

.phone_number_input_dropdown_item:last-child {
    border-radius: 0 0 10px 10px;
    padding-bottom: 14px;
}

.phone_number_input_dropdown_item:hover {
    background-color: rgba(13, 10, 61, 0.11);
}

.phone_number_input_dropdown_item > p:first-child {
    margin-right: 7px;
}

.input_error.phoneNumber {
    bottom: -5px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Elements/PhoneNumberInput/PhoneNumberInput.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,aAAa;IACb,mBAAmB;IACnB,UAAU;IACV,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,UAAU;IACV,WAAW;IACX,gBAAgB;IAChB,0BAA0B;AAC9B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,yBAAyB;IAGzB,yCAAyC;IACzC,mBAAmB;IACnB,SAAS;IACT,UAAU;AACd;;AAEA;IACI,YAAY;IACZ,eAAe;IACf,eAAe;IACf,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,4BAA4B;IAC5B,iBAAiB;AACrB;;AAEA;IACI,4BAA4B;IAC5B,oBAAoB;AACxB;;AAEA;IACI,wCAAwC;AAC5C;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,YAAY;AAChB","sourcesContent":[".simple_input.phoneNumber {\n    padding-left: 60px;\n}\n\n.phoneNumber_flag {\n    position: absolute;\n    display: flex;\n    align-items: center;\n    left: 10px;\n    cursor: pointer;\n    padding-top: 18px;\n}\n\n.phoneNumber_flag > img {\n    width: 9px;\n    height: 9px;\n    margin-left: 5px;\n    transform: rotateZ(180deg);\n}\n\n.phone_number_input_dropdown {\n    position: absolute;\n    width: 100%;\n    background-color: #FFFFFF;\n    -webkit-box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);\n    -moz-box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);\n    box-shadow: 0 0 16px 7px rgba(0,0,0,0.13);\n    border-radius: 10px;\n    top: 80px;\n    z-index: 2;\n}\n\n.phone_number_input_dropdown_item {\n    padding: 7px;\n    font-size: 12px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n}\n\n.phone_number_input_dropdown_item:first-child {\n    border-radius: 10px 10px 0 0;\n    padding-top: 14px;\n}\n\n.phone_number_input_dropdown_item:last-child {\n    border-radius: 0 0 10px 10px;\n    padding-bottom: 14px;\n}\n\n.phone_number_input_dropdown_item:hover {\n    background-color: rgba(13, 10, 61, 0.11);\n}\n\n.phone_number_input_dropdown_item > p:first-child {\n    margin-right: 7px;\n}\n\n.input_error.phoneNumber {\n    bottom: -5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
