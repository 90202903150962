import "../SimpleComponents/Modal.css";
import React, {useEffect, useState} from "react";
import closeIcon from "../../img/Dashboard/Employee/close_btn.svg";
import {useTranslation} from "react-i18next";
import SelectWithChoices from "../SimpleComponents/SelectWithChoices";
import de from "date-fns/locale/de";
import {DateRange} from "react-date-range";
import moment from "moment";
import Button from "../Buttons/Button";
import {useFormik} from "formik";
import * as Yup from "yup";
import {getToken} from "../../utils/cookies/tokensCookie";
import environment from "../../environment";
import {urls} from "../../api/urls";
import axios from "axios";
import {errorHandler} from "../../utils/errorHandler";

interface CustomProps {
  closeModal: () => void;
  planId: string;
  userId: string;
  selectedDate: string;
  blockedDaysData: any[];
}

export default function BillingCalendarModal(props: CustomProps) {
  const { t } = useTranslation();

  const [dateState, setDateState] = useState<any>(null);
  const [createNewAbsence, setCreateNewAbsence] = useState(true);

  useEffect(() => {
    if (props.selectedDate && props.blockedDaysData && props.blockedDaysData.length > 0) {
      props.blockedDaysData.map((blockedDay:any) => {
        if (blockedDay.date === moment(props.selectedDate).format("DD.MM.YYYY")) {
          setCreateNewAbsence(false);
          setDateState(blockedDay);
        }
      });
    }
  }, []);

  const absenceTypes = [
    {
      value: "sick",
      label: t("dashboard.plans.billing_page.calendar.modal.sick")
    },
    {
      value: "holiday",
      label: t("dashboard.plans.billing_page.calendar.modal.holiday")
    },
    {
      value: "absence",
      label: t("dashboard.plans.billing_page.calendar.modal.other")
    }
  ];

  const [state, setState] = useState<any>([
    {
      startDate: null,
      endDate: null,
      key: "selection"
    }
  ]);

  const [showSavingError, setShowSavingError] = useState(false);

  const formik = useFormik({
    initialValues: { type: "", startDate: "", endDate: "" },
    validationSchema: Yup.object({
      type: Yup.string().required(t("login.required")),
      startDate: Yup.string().required(t("login.required")),
      endDate: Yup.string().required(t("login.required"))
    }),
    // handle form submitting
    onSubmit: async () => {
      setShowSavingError(false);
      const token = getToken();
      const fullUrl = `${environment.baseUrl}${urls.plans.requestAbsenceDays}`;
      const options = {headers: {"Authorization": `Bearer ${token}`}};

      await axios.post(fullUrl, {
        ...formik.values,
        planId: props.planId,
        userId: props.userId
      }, options)
        .then((res:any) => {
          props.closeModal();
        })
        .catch((e:any) => {
          if (!errorHandler(e.request.status)) {
            setShowSavingError(true);
          }
        });
    },
  });

  const handleChoice = (select:any) => {
    formik.setFieldValue("type", select.value, true);
  };

  const datePickerHandler = (item:any) => {
    setState(item);
    formik.setFieldValue("startDate", moment(item[0].startDate).add(4, "hour").toDate());
    formik.setFieldValue("endDate", moment(item[0].endDate).add(4, "hour").toDate());
  };

  const removeDate = async () => {
    const token = getToken();
    const fullUrl = `${environment.baseUrl}${urls.plans.requestAbsenceDays}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    let type = dateState.type;
    if (dateState.type === "rest") {
      type = "absence";
    }
    await axios.put(fullUrl, {
      planId: props.planId,
      userId: props.userId,
      type: type,
      date: moment(props.selectedDate).toDate()
    }, options)
      .then((res:any) => {
        props.closeModal();
      })
      .catch((e:any) => {
        if (!errorHandler(e.request.status)) {
          setShowSavingError(true);
        }
      });
  };

  return (
    <form className="changelog_modal white" onSubmit={formik.handleSubmit}>
      <img
        onClick={() => props.closeModal()}
        style={{ top: "0px" }}
        className="close_popup_paddings"
        src={closeIcon}
        alt="close"
      />
      <div style={{paddingTop: "30px"}}>
        {createNewAbsence || !dateState
          ? <>
            <p>{t("dashboard.plans.billing_page.calendar.modal.title")}</p>

            {showSavingError
              ? <p style={{color: "red"}}>
                {t("dashboard.plans.billing_page.calendar.modal.error")}
              </p>
              : null
            }

            <p className="simple_label" style={{marginBottom: 0}}>
              {t("dashboard.plans.billing_page.calendar.modal.type_label")}
            </p>
            <SelectWithChoices
              defaultValue={""}
              name={t("dashboard.plans.billing_page.calendar.modal.type_label")}
              selectPlaceHolder={t("dashboard.overview.filter_opt_1_place")}
              selectId="status"
              options={absenceTypes}
              handleAddChoice={(options) => handleChoice(options)}
              isMulti={"no"}
            />

            <p className="simple_label" style={{marginBottom: 0, marginTop: "30px"}}>
              {t("dashboard.plans.billing_page.calendar.modal.time_label")}
            </p>
            <DateRange
              editableDateInputs={true}
              onChange={(item:any) => datePickerHandler([item.selection])}
              moveRangeOnFirstSelection={false}
              ranges={state}
              direction="horizontal"
              showDateDisplay={false}
              showPreview={false}
              locale={de}
            />

            <div>
              <Button
                disabled={!(formik.isValid && formik.dirty)}
                buttonType="primary with_tooltip"
                buttonHtmlType="submit"
                buttonStyles={{
                  maxWidth: "332px"
                }}
                buttonLabel={t("dashboard.plans.billing_page.calendar.modal.btn")}
                buttonHandler={formik.handleSubmit}
              />

            </div>
          </>
          : <div>
            <div className={`plan_calendar_day_badge ${dateState?.type}`}>
              <div />
              <p>{t(`dashboard.plans.billing_page.calendar.days.${dateState?.type}`)}</p>
              <p>{dateState?.date}</p>
            </div>
            <Button
              disabled={false}
              buttonType="primary with_tooltip"
              buttonStyles={{
                maxWidth: "332px",
                minWidth: "230px",
                marginTop: "20px",
                marginBottom: "10px"
              }}
              buttonLabel={t("dashboard.plans.billing_page.calendar.modal.delete_btn")}
              buttonHandler={removeDate}
            />
            <p
              style={{
                cursor: "pointer",
                textAlign: "center"
              }}
              onClick={() => setCreateNewAbsence(true)}
            >
              {t("dashboard.plans.billing_page.calendar.modal.switch_btn")}
            </p>
          </div>
        }
      </div>
    </form>
  );
}
