import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import { toast } from "react-toastify";

import { modalStyles } from "../../utils/modalStyles";
import PlansUpdateWarningModal from "../Modals/PlansUpdateWarningModal";
import AdvancedSelect from "../SimpleComponents/AdvancedSelect";
import CalendarContainer from "../SimpleComponents/CalendarContainer";
import DailyLimitForm from "../SimpleComponents/DailyLimitForm";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import Checkbox from "../SimpleComponents/Checkbox";

interface CustomProps {
  updatePlanData?: any;
  addNewPlan: (plan: any) => void;
  plansArray: any;
  daysOptions: any[];
  isCopyPlan?: boolean;
  addedPlans?: any;
  supermarketsAllowed: boolean;
}

export default function DailyLimitContainer({
  updatePlanData,
  addNewPlan,
  plansArray,
  daysOptions,
  isCopyPlan,
  addedPlans,
  supermarketsAllowed
}: CustomProps) {
  const { t } = useTranslation();

  //Initial values for daily limits
  const [dailyLimitValues, setDailyLimitValues] = useState({
    title: "",
    employee: [],
    restaurantLimit: "0",
    supermarketLimit: "0",
    generalLimit: "0",
    dateRange: {
      startDate: "--",
      endDate: "--"
    },
    selectedDays:  [1,2,3,4,5],
    allowWorkOnHolidays: false,
    supermarketsAllowed: supermarketsAllowed
  });


  // Detect supermarket switch changes and set supermarket limit to 0
  useEffect(() => {
    setDailyLimitValues({
      ...dailyLimitValues,
      supermarketsAllowed: supermarketsAllowed,
      supermarketLimit: "0"
    });
  }, [supermarketsAllowed]);

  // Set values if update plan
  useEffect(() => {
    if (
      updatePlanData &&
      Object.keys(updatePlanData).length &&
      plansArray.length
    ) {
      setDailyLimitValues({
        title: updatePlanData.plan.title,
        employee: isCopyPlan
          ? updatePlanData.plan.employees
          : plansArray[0].employee,
        restaurantLimit: updatePlanData.plan.restaurant.toString(),
        supermarketLimit: updatePlanData.plan.supermarket.toString(),
        generalLimit: updatePlanData.plan.generalLimit.toString(),
        dateRange: {
          startDate: moment(updatePlanData.plan.startDate).format("DD.MM.YYYY"),
          endDate: moment(updatePlanData.plan.endDate).format("DD.MM.YYYY")
        },
        selectedDays: updatePlanData.plan.selectedDays,
        allowWorkOnHolidays: updatePlanData.plan.allowWorkOnHolidays,
        supermarketsAllowed: supermarketsAllowed
      });


    }
    //Block re-renders
    // eslint-disable-next-line
  }, [updatePlanData, plansArray]);

  // Change plans limit
  const handleLimitsValues = (limits: any) => {
    setDailyLimitValues({
      ...dailyLimitValues,
      restaurantLimit: limits.restaurant,
      supermarketLimit: limits.supermarket,
      generalLimit: limits.general
    });
  };

  // save dates from calendar
  const submitDates = (dates: any) => {
    if (updatePlanData && Object.keys(updatePlanData).length) {
      setDailyLimitValues({
        ...dailyLimitValues,
        employee: updatePlanData.plan.employees ?? [],
        dateRange: dates[0]
      });
    } else {
      setDailyLimitValues({ ...dailyLimitValues, dateRange: dates[0] });
    }
  };

  // Check if all fields are present and submit
  const handleAddPlan = () => {
    if (+dailyLimitValues.restaurantLimit === 0 &&
        +dailyLimitValues.supermarketLimit === 0 && dailyLimitValues.generalLimit === "0"
    ) {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.warning_add_limit")}
          status={2}
        />,
        {
          autoClose: 5000
        }
      );
    } else if (
      dailyLimitValues.dateRange.startDate === "--" ||
      dailyLimitValues.dateRange.endDate === "--" ||
      dailyLimitValues.selectedDays.length === 0
    ) {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.plans_calendar_day_error")}
          status={2}
        />,
        {
          autoClose: 5000
        }
      );
    } else if (isCopyPlan && !isValidTimePeriod()) {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.plans_calendar_timePeriod_error")}
          status={2}
        />,
        {
          autoClose: 5000
        }
      );
    } else {
      addNewPlan(dailyLimitValues);
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleUpdateButtonClick = () => {
    if (!isCopyPlan) {
      setShowModal(true);
    } else {
      handleAddPlan();
    }
  };

  const checkShowDaysCondition = () => {
    if (
      +dailyLimitValues.restaurantLimit ||
      +dailyLimitValues.supermarketLimit
    ) {
      if (
        dailyLimitValues.dateRange.startDate !== "--" &&
        dailyLimitValues.dateRange.endDate !== "--"
      ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  const handleDaysSelect = (values: any) => {
    const updatedDays: any = [];
    values.map((value: any) => {
      updatedDays.push(value.value);
    });
    const dailyLimitsCopy = { ...dailyLimitValues };
    dailyLimitsCopy.selectedDays = updatedDays;
    setDailyLimitValues(dailyLimitsCopy);
  };

  const [defaultDays, setDefaultDays] = useState<any>([]);


  useEffect(() => {
    if (dailyLimitValues.selectedDays && dailyLimitValues.selectedDays.length) {
      const newDaysArray: any[] = [];
      dailyLimitValues.selectedDays.forEach((day: number) => {
        newDaysArray.push({
          value: day,
          label: t(`dashboard.plans.days.${day}`)
        });
      });
      setDefaultDays(newDaysArray);
      setDaysInitialized(true);
    }
  }, [dailyLimitValues]);

  const [daysInitialized, setDaysInitialized] = useState(false);

  const isValidTimePeriod = () => {
    if (isCopyPlan && addedPlans && addedPlans.length > 0) {
      const newPlanStartDate = moment(
        dailyLimitValues.dateRange.startDate,
        "DD.MM.YYYY"
      ).valueOf();
      const newPlanEndDate = moment(
        dailyLimitValues.dateRange.endDate,
        "DD.MM.YYYY"
      ).valueOf();
      const oldPlanStartDate = moment(updatePlanData.plan.startDate).valueOf();
      const oldPlanEndDate = moment(updatePlanData.plan.endDate).valueOf();

      if (
        newPlanStartDate > oldPlanEndDate ||
        newPlanEndDate < oldPlanStartDate
      ) {
        return true;
      }
    }
    return false;
  };

  return (
    <>
      <DailyLimitForm
        updatePlanData={updatePlanData}
        handleLimitsValues={handleLimitsValues}
        dailyLimitValues={dailyLimitValues}
      />
      <CalendarContainer
        submitDates={submitDates}
        plansArray={plansArray}
        updatePlanData={updatePlanData}
        isCopyPlan={isCopyPlan}
      />
      {/*Show day select*/}
      {checkShowDaysCondition() ? (
        <div
          className="row"
          style={{
            justifyContent: "flex-end",
            marginTop: "10px",
            minWidth: "500px"
          }}
        >
          <div className="row">
            <p className="simple_label" style={{ marginRight: "10px" }}>
              {t("dashboard.plans.days_of_the_week")}
            </p>

 				<AdvancedSelect
              name="days"
              placeholder={t("dashboard.plans.days_select_placeholder")}
              isMulti={true}
              defaultValue={isCopyPlan || location.pathname.includes("/update/") ? addedPlans[0]?.selectedDays.map((day: any ) => {
                return {
                  value: day,
                  label: t(`dashboard.plans.days.${day - 1}`)
					  };
				  }) : defaultDays}
              options={daysOptions}
              handleAddChoice={handleDaysSelect}
            />

          </div>
        </div>
      ) : null}

      <div
        className="row"
        style={{
          justifyContent: "flex-end",
          marginTop: "10px",
          minWidth: "500px",
        }}
      >
        <Checkbox
          id={"allow"}
          handleCheckboxChange={() =>
            setDailyLimitValues({
              ...dailyLimitValues,
              allowWorkOnHolidays: !dailyLimitValues.allowWorkOnHolidays
            })
          }
          checked={dailyLimitValues.allowWorkOnHolidays}
        />
        <p style={{fontSize: "14px"}}>
          {t("dashboard.plans.allow_work_on_holidays")}
        </p>
      </div>

      <div style={{ marginTop: "20px" }} className="flex-end">
        {updatePlanData && Object.keys(updatePlanData).length && !isCopyPlan ? (
          <button
            onClick={handleUpdateButtonClick}
            className="date_add_btn primary"
          >
            {t("dashboard.plans.update_btn")}
          </button>
        ) : (
          <button
            onClick={handleAddPlan}
            className="date_add_btn primary"
            // disabled={isCopyPlan && !isValidTimePeriod()}
          >
            {t("dashboard.plans.add_btn")}
          </button>
        )}
      </div>

      <Modal style={modalStyles} isOpen={showModal}>
        <PlansUpdateWarningModal
          closeModal={() => setShowModal(false)}
          handleSubmit={() => handleAddPlan()}
        />
      </Modal>
    </>
  );
}
