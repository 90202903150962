import React from "react";
import AdvancedSelect from "./AdvancedSelect";
import SingleSelect from "./SingleSelect";

interface CustomProps {
	options: any,
	handleAddChoice: (newChoices:any) => void,
	selectId: string,
	selectPlaceHolder: string,
	name: string,
	defaultValue?: any,
	isMulti?: string,
	size?: string,
	style?: any,
  onBlur?: any
}



export default function SelectWithChoices(
  {options, isMulti, handleAddChoice, style
    , selectPlaceHolder, defaultValue, size, onBlur}:CustomProps) {

  return(
    <React.Fragment>
      <div className={size === "small" ? "lunch_form_row small" : "lunch_form_row"}>
        <div className="lunch_form_select_box" style={style}>
          {isMulti === "no"
            ?
            <SingleSelect
              placeholder={selectPlaceHolder}
              isMulti={isMulti}
              defaultValue={defaultValue}
              options={options}
              handleAddChoice={handleAddChoice}
              onBlur={onBlur}
            />
            :
            <AdvancedSelect
              placeholder={selectPlaceHolder}
              isMulti={isMulti}
              defaultValue={defaultValue}
              options={options}
              handleAddChoice={handleAddChoice}
              onBlur={onBlur}
            />}
        </div>
      </div>
    </React.Fragment>
  );
}
