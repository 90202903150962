import React, {forwardRef, useEffect, useImperativeHandle, useRef, useState} from "react";
import "./Select.css";
import arrowSelect from "img/arrow_select_black.svg";
import Button from "../Buttons/Button";
import PermissionCustomRoleMenu from "./PermissionCustomRoleMenu";
import {useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
import {updateRole} from "../../redux/actions/rolesActions";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import {getLunchSettings} from "../../utils/lunch/getLunchSettings/getLunchSettings";
import {getToken} from "../../utils/cookies/tokensCookie";

interface CustomProps {
	options: any,
	onChange?: (option:string) => void,
	role: string,
	allRoles: any,
	user:any,
	leftSide?: boolean,
	closeSelect: () => void,
	roleId: string,
  showLoginPopup?: () => void;
  handleSelect: (selectedItem: string) => void;
  customSettings: any;
  setCustomSettings: (settings: any) => void;
}

export default function PermissionSelect(
  {options, role, allRoles, user, leftSide, setCustomSettings, customSettings, handleSelect}:CustomProps, ref: any) {
  const dispatch = useDispatch();
  const { t } = useTranslation();


  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedItem , setSelectedItem] = useState("");


  useEffect(() => {
    if (options.length) {
      setSelectedItem(translateSelectedOption(role));
    }
  }, [options, role]);

  const [customRoles, setCustomRoles] = useState([]);
  useEffect(() => {
    if (allRoles.roles) {
      const temporaryArray:any = [];
      allRoles.roles.forEach((roleItem:any) => {
        if (roleItem.role === "Custom") {
          temporaryArray.push(roleItem.permissions);
        }
      });
      setCustomRoles(temporaryArray);
    }
  }, [allRoles]);

  const translateSelectedOption = (option: string) => {
    switch (option) {
    case "Admin":
      return t("dashboard.settings.role_admin");
    case "Viewer":
      return t("dashboard.settings.role_viewer");
    case "Custom":
      return t("dashboard.settings.role_custom");
    default:
      return option;
    }
  };


  // Detect click outside for close menu
  const permissionsSelectRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!permissionsSelectRef.current?.contains(event.target as Node)) {
        setMenuOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // Validate custom roles select
  const [disableSubmitButton, setDisableSubmitButton] = useState(false);

  useEffect(() => {
    if (selectedItem === t("dashboard.settings.role_custom")) {
      let activePermissions = false;
      customSettings.forEach((permission:any) => {
        if (permission.isActive || permission.isAdmin) {
          activePermissions = true;
        }
      });
      setDisableSubmitButton(!activePermissions);
    }
  }, [customSettings]);

  useEffect(()=>{
    if(selectedItem===t("dashboard.settings.role_admin") ||
     selectedItem===t("dashboard.settings.role_viewer")){
      setDisableSubmitButton(false);
    }
  },[selectedItem]);



  return(
    <div className="custom-select-wrapper responsive_select">
      <div
        ref={permissionsSelectRef}
        className={menuOpen ? "custom-select open" : "custom-select"}>
        <div
          className="permission_select_trigger space-between"
          onClick={() => setMenuOpen(!menuOpen)}
        >
          <span>{translateSelectedOption(selectedItem)}</span>
          <img
            src={arrowSelect}
            alt="arrow"
            className={menuOpen
              ? "select-arrow-up" : "select-arrow"}
          />
        </div>
        {menuOpen && selectedItem === t("dashboard.settings.role_custom")
          ? <PermissionCustomRoleMenu
            setCustomSettings={setCustomSettings}
            options={customRoles}
            leftSide={leftSide}
            user={user}
          />
          : null}
        <div className="custom-options">
          {options.slice(0, 3).map((option:any) => (
            <div
              onClick={() => setSelectedItem(option)}
              className="custom-option"
              key={option}>
              <div className="form_radio">
                <input
                  onChange={() => setSelectedItem(option)}
                  type="radio"
                  id={option}
                  name="radio"
                  checked={selectedItem === option}
                />
                <label>{option}</label>
              </div>
            </div>
          ))}
          <div className="permission_btn_box">
            <p
              className="text_link"
              onClick={() => setMenuOpen(false)}
              style={{marginBottom: "7px"}}
            >
              {t("dashboard.settings.permissions.cancel_btn")}
            </p>
            <Button
              buttonStyles={{height: "30px", borderRadius: "5px"}}
              buttonType="primary"
              buttonLabel={t("dashboard.settings.permissions.save_btn")}
              fullWidth={true}
              buttonHandler={() => handleSelect(selectedItem)}
              disabled={disableSubmitButton}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
