import React, {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {getToken} from "../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import image from "img/Dashboard/Card/weavr_onboarding_password.svg";


interface IProps {
  weavr: any;
  handleSlider: () => void
}


export default function CreatePaymentsPassword({weavr, handleSlider}:IProps) {
  const { t } = useTranslation();

  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);
  const [inputIsMount, setInputIsMount] = useState(false);

  const setPasscode = async (tokenizedCode:string) => {
    setShowSubmitButtonLoading(true);
    const token = await getToken();
    const response = await axios.post(`${environment.baseUrl}${urls.payments.createPassword}`,
      {
        id: weavr.corporateId.id,
        email: weavr.rootUser.email,
        password: tokenizedCode
      },
      {
        headers: { Authorization: `Bearer ${token}` }
      });
    if (response.data) {
      setShowSubmitButtonLoading(false);
      handleSlider();
    }
  };

  const [ref, setRef] = useState<any>(null);

  useEffect(() => {
    if (weavr && weavr.rootUser && weavr.rootUser.email && !inputIsMount) {
      // Set the user’s authentication token
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const createdForm = window.OpcUxSecureClient.form();

      setRef(createdForm);

      // Create an instance of a secure Passcode component that will collect the passcode
      const input = createdForm.input(
        "passcode",
        "passCode",
        {
          placeholder: "0000"
        }
      );

      // Embed the secure Passcode component in the HTML element where you want
      // the Passcode component to the shown on screen
      input.mount(document.getElementById("passcode"));
      setInputIsMount(true);
    }
  }, [weavr]);

  const onSubmit = (e:any) => {
    e.preventDefault();
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore

    ref.tokenize(function(tokens:any) {
      if (tokens && tokens.passcode) {
        setPasscode(tokens.passcode);
      }
    });
  };


  return(
    <div className="create_password_page">
      {weavr && weavr.rootUser && weavr.rootUser.email
        ? <form onSubmit={(e) => onSubmit(e)} className="weavr_passcode_container">
          <div>
            <div className="weavr_passcode_content">
              <h1>{t("dashboard.payments.onboarding.stepper.create_password.title")}</h1>
              <div style={{display: "none"}}>
                Username: <input type="input" value={weavr.rootUser.email} name="u" /><br/>
              </div>
              <div className="weavr_passcode_input_box">
                {t("dashboard.payments.onboarding.stepper.create_password.passcode")} <div id="passcode"></div><br/>
              </div>
              <input className="weavr_passcode_btn" type="submit" value={t("dashboard.payments.onboarding.stepper.create_password.btn")} />
            </div>
          </div>
          <div className="center">
            <img src={image} alt="password"/>
          </div>
        </form>
        : <p>Loading</p>
      }
    </div>
  );
}
