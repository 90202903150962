import React, {useEffect, useState} from "react";
import Spacer from "./Spacer";
import { toast } from "react-toastify";
import CustomToastContainer from "./ToastContainer";
import caretIcon from "img/Dashboard/PlansAndModules/input_caret.svg";
import { useTranslation } from "react-i18next";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import moment from "moment/moment";
import DashboardLoading from "../LoadingPlaceholders/DashboardLoading";
import Checkbox from "./Checkbox";

export type dailyLimitsType = {
  restaurant: string;
  supermarket: string;
}

interface CustomProps {
  handleLimitsValues: (limits:dailyLimitsType) => void;
  updatePlanData: any;
  company?: any;
  dailyLimitValues: any;
}

function DailyLimitForm(
  {handleLimitsValues, updatePlanData, company, dailyLimitValues}:CustomProps) {
  const { t } = useTranslation();
  const RestaurantLimit = 8;
  const SupermarketLimit = 2;

  const [loading, setLoading] = useState(true);
  const [countryLayout, setCountryLayout] = useState<"AT" | "DE">("AT");
  const [planType, setPlanType] = useState<"daily" | "general">("daily");

  useEffect(() => {
    if (updatePlanData && Object.keys(updatePlanData).length) {
      setLimitValues({
        restaurant: updatePlanData.plan.restaurant.toString(),
        supermarket: updatePlanData.plan.supermarket.toString(),
        general: updatePlanData.plan.generalLimit.toString()
      });
      // If plan is general limit
      if (updatePlanData.plan.type === "2") {
        setPlanType("general");

      }
    }
  }, [updatePlanData]);

  // Detect company country and set to component state
  useEffect(() => {
    if (company && company.company) {
      setCountryLayout(company.company.companyInfo.country);
      setLoading(false);
    }
  }, [company]);

  const [limitValues, setLimitValues] = useState({
    restaurant: "0",
    supermarket: "0",
    general: "0"
  });

  const changeValue = (value:string, state:string) => {
    let newValue = 0;
    if (value === "restaurant") {
      if (countryLayout === "DE") {
        // For Germany
        if (state === "increment") {
          if (+limitValues.restaurant < 6) {
            newValue += +limitValues.restaurant + 1;
          }
        } else {
          if (+limitValues.restaurant > 0) {
            newValue += +limitValues.restaurant - 1;
          }
        }
      } else {
        if (state === "increment") {
          if (+limitValues.restaurant < 8) {
            newValue += +limitValues.restaurant + 1;
          }
        } else {
          if (+limitValues.restaurant > 0) {
            newValue += +limitValues.restaurant - 1;
          }
        }
      }
    } else if (value === "general") {
      if (state === "increment") {
        newValue += +limitValues.general + 1;
      } else {
        newValue += +limitValues.general - 1;
      }
    } else {
      if (state === "increment") {
        if (+limitValues.supermarket < 2) {
          newValue += +limitValues.supermarket + 1;
        }
      } else {
        if (+limitValues.supermarket > 0) {
          newValue += +limitValues.supermarket - 1;
        }
      }
    }
    const newLimits = {
      ...limitValues,
      restaurant: value === "restaurant" ? newValue.toString() : limitValues.restaurant,
      supermarket: value === "supermarket" ? newValue.toString() : limitValues.supermarket,
      general: value === "general" ? newValue.toString() : limitValues.general
    };
    setLimitValues(newLimits);
    handleLimitsValues(newLimits);
  };

  // Check if written number is smaller than existing limit amd greater 0
  const handleLimitInputs = (e:any, id:string) => {
    e.preventDefault();
    if (+e.target.value < 0) {
      toast.warning(
        <CustomToastContainer
          message={t("warnings.min_limit")}
          status={2} />, {
          autoClose: 5000,
        });
      if (id === "supermarket") {
        setLimitValues({...limitValues, supermarket: "0"});
      } else if (id === "restaurant") {
        setLimitValues({...limitValues, restaurant: "0"});
      } else if (id === "general") {
        setLimitValues({...limitValues, general: "0"});
      }
    } else if (countryLayout === "DE") {
      if (e.target.value > 6.90) {
        toast.warning(
          <CustomToastContainer
            message={`${t("warnings.max_limit")} ${6.90}`}
            status={2} />, {
            autoClose: 5000,
          });
      } else {
        const value = e.target.value.toString().replace(/,/g, ".");
        const newData = {...limitValues, restaurant: value};
        setLimitValues(newData);
        handleLimitsValues(newData);
      }
    } else if (id === "supermarket") {
      if (e.target.value > SupermarketLimit) {
        toast.warning(
          <CustomToastContainer
            message={`${t("warnings.max_limit")} ${SupermarketLimit}`}
            status={2} />, {
            autoClose: 5000,
          });
      } else {
        const value = e.target.value.toString().replace(/,/g, ".");
        const newData = {...limitValues, supermarket: value};
        setLimitValues(newData);
        handleLimitsValues(newData);
      }
    } else if (id === "restaurant") {
      if (e.target.value > RestaurantLimit) {
        toast.warning(
          <CustomToastContainer
            message={`${t("warnings.max_limit")} ${RestaurantLimit}`}
            status={2} />, {
            autoClose: 5000,
          });
      } else {
        const value = e.target.value.toString().replace(/,/g, ".");
        const newData = {...limitValues, restaurant: value};
        setLimitValues(newData);
        handleLimitsValues(newData);
      }
    } else if (id === "general") {
      const value = e.target.value.toString().replace(/,/g, ".");
      const newData = {...limitValues, general: value};
      setLimitValues(newData);
      handleLimitsValues(newData);
    }
  };

  return (
    <div>
      {loading
        ? <DashboardLoading />
        : <>
          {countryLayout === "DE"
            ? <>
              <div
                style={{paddingTop:"15px"}}
                className="flex-end-center"
              >
                <p className="rem-08">{t("dashboard.plans.limit")}</p>
                <Spacer spacerWidth="20px"/>
                <div style={{width: "176px", position: "relative"}}>
                  <input
                    step="0.1"
                    min={0}
                    max={6.90}
                    onChange={(e:any) => handleLimitInputs(e,"restaurant")}
                    className="lunch_form_input"
                    type="number"
                    value={limitValues.restaurant}
                  >
                  </input>
                  <div
                    onClick={() => changeValue("restaurant", "increment")}
                    className="arrow_up">
                    <img src={caretIcon} alt="caret"/>
                  </div>
                  <div
                    onClick={() => changeValue("restaurant", "decrement")}
                    className="arrow_bottom">
                    <img src={caretIcon} alt="caret"/>
                  </div>
                </div>
              </div>
            </>
            : <>
              <div
                style={{paddingTop:"15px"}}
                className="flex-end-center"
              >
                <Checkbox
                  id={"daily"}
                  handleCheckboxChange={() => {
                    setPlanType(planType === "daily" ? "general" : "daily");
                  }}
                  checked={planType === "daily"}
                  text={t("dashboard.plans.plan_type_switcher.daily")}
                />
                <div style={{width: "20px"}} />
                <Checkbox
                  id={"general"}
                  handleCheckboxChange={() => {
                    setPlanType(planType === "daily" ? "general" : "daily");
                  }}
                  checked={planType === "general"}
                  text={t("dashboard.plans.plan_type_switcher.general")}
                />
              </div>

              {planType === "daily"
                &&
                <>
                  <div
                    style={{paddingTop:"15px"}}
                    className="flex-end-center"
                  >
                    <p className="rem-08">{t("dashboard.plans.restaurant_limit")}</p>
                    <Spacer spacerWidth="20px"/>
                    <p className="rem-08">{t("dashboard.plans.limit")}</p>
                    <Spacer spacerWidth="20px"/>
                    <div style={{width: "176px", position: "relative"}}>
                      <input
                        step="0.1"
                        min={0}
                        max={RestaurantLimit}
                        onChange={(e:any) => handleLimitInputs(e,"restaurant")}
                        className="lunch_form_input"
                        type="number"
                        value={limitValues.restaurant}
                      >
                      </input>
                      <div
                        onClick={() => changeValue("restaurant", "increment")}
                        className="arrow_up">
                        <img src={caretIcon} alt="caret"/>
                      </div>
                      <div
                        onClick={() => changeValue("restaurant", "decrement")}
                        className="arrow_bottom">
                        <img src={caretIcon} alt="caret"/>
                      </div>
                    </div>
                  </div>
                  {dailyLimitValues.supermarketsAllowed || moment(updatePlanData?.plan?.startDate) < moment() && !updatePlanData?.plan?.isSupermarketDisabled
                    ? <div
                      style={{paddingTop:"10px"}}
                      className="flex-end-center"
                    >
                      <p className="rem-08">{t("dashboard.plans.supermarket_limit")}</p>
                      <Spacer spacerWidth="20px"/>
                      <p className="rem-08">{t("dashboard.plans.limit")}</p>
                      <Spacer spacerWidth="20px"/>
                      <div style={{width: "176px", position: "relative"}}>
                        <input
                          step="0.1"
                          max={SupermarketLimit}
                          min={0}
                          onChange={(e:any) => handleLimitInputs(e,"supermarket")}
                          className="lunch_form_input"
                          type="number"
                          value={limitValues.supermarket}
                        />
                        <div
                          onClick={() => changeValue("supermarket", "increment")}
                          className="arrow_up">
                          <img src={caretIcon} alt="caret"/>
                        </div>
                        <div
                          onClick={() => changeValue("supermarket", "decrement")}
                          className="arrow_bottom">
                          <img src={caretIcon} alt="caret"/>
                        </div>
                      </div>
                    </div>
                    : null
                  }
                </>
              }

              {
                planType === "general"
                &&
                <div
                  style={{paddingTop:"10px"}}
                  className="flex-end-center"
                >
                  <p className="rem-08">{t("dashboard.plans.general_limit")}</p>
                  <Spacer spacerWidth="20px"/>
                  <p className="rem-08">{t("dashboard.plans.limit")}</p>
                  <Spacer spacerWidth="20px"/>
                  <div style={{width: "176px", position: "relative"}}>
                    <input
                      step="0.1"
                      min={0}
                      onChange={(e:any) => handleLimitInputs(e,"general")}
                      className="lunch_form_input"
                      type="number"
                      value={limitValues.general}
                    />
                    <div
                      onClick={() => changeValue("general", "increment")}
                      className="arrow_up">
                      <img src={caretIcon} alt="caret"/>
                    </div>
                    <div
                      onClick={() => changeValue("general", "decrement")}
                      className="arrow_bottom">
                      <img src={caretIcon} alt="caret"/>
                    </div>
                  </div>
                </div>
              }

            </>
          }
        </>
      }
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(DailyLimitForm);
