import React, {useState} from "react";
import "./PaymentsOverviewHeaderContainerDepositBottomTab.css";
import {useEffectOnce} from "../../../../../../../hooks/useEffectOnce";
import {paymentsService} from "../../../../../../../api/services/Payments/payments.service";
import {DepositTransactionType} from "../../../../../../../api/services/Payments/payments.interface";
import Table, {ITableHeader} from "../../../../../../Elements/Table/Table";
import moment from "moment/moment";
import {rewriteCurrency} from "../../../../../../../utils/rewriteCurrency";
import Badge, {BadgeColorsEnum} from "../../../../../../Elements/Badge/Badge";
import getTransactionEntryStateStatusName
  from "../../../../../../../utils/virtualCards/getTransactionEntryStateStatusName";
import {useTranslation} from "react-i18next";

export default function PaymentsOverviewHeaderContainerDepositBottomTab() {
  const { t } = useTranslation();

  const pageLimit = 8;
  const tableHeaders:ITableHeader[] = [
    {
      id: "processedTimestamp",
      name: t("dashboard.payments.overview.bottom_tabs.depositTransactions.headers.processedTimestamp"),
      render: (value: any) => {
        return(<div>
          <p>{moment(value).format("DD.MM.YYYY")}</p>
        </div>);
      }
    },
    {
      id: "transactionAmount.amount",
      name: t("dashboard.payments.overview.bottom_tabs.depositTransactions.headers.transactionAmount"),
      render: (value: any) => {
        return(<div>
          <p>{rewriteCurrency(Number((value / 100).toFixed(2)), "EUR")}</p>
        </div>);
      }
    },
    {
      id: "entryState",
      name: t("dashboard.payments.overview.bottom_tabs.depositTransactions.headers.entryState"),
      render: (value: any) => {
        return(
          <Badge
            color={BadgeColorsEnum.green}
            text={getTransactionEntryStateStatusName(value)}
          />
        );
      }
    },
    {
      id: "additionalFields.senderIban",
      name: t("dashboard.payments.overview.bottom_tabs.depositTransactions.headers.senderIban")
    },
    {
      id: "actualBalanceAfter.amount",
      name: t("dashboard.payments.overview.bottom_tabs.depositTransactions.headers.actualBalanceAfter"),
      render: (value: any) => {
        return(<div>
          <p>{rewriteCurrency(Number((value / 100).toFixed(2)), "EUR")}</p>
        </div>);
      }
    }
  ];

  const [transactions, setTransactions] = useState<{data: DepositTransactionType[], total: number} | null>(null);
  const [loading, setLoading] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchTransactions = async (params: {skip: number}) => {
    setLoading(true);
    const transactions = await new paymentsService().getDepositTransaction({...params, limit: pageLimit});
    if (transactions.status && transactions.data) {
      setTransactions(transactions.data);
    } else {
      setTransactions(null);
    }
    setLoading(false);
  };

  useEffectOnce(() => {
    fetchTransactions({skip: 0});
  });

  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      const nextPage = (pageNumber - 1) * pageLimit;
      setCurrentPage(nextPage);
      fetchTransactions({skip: nextPage});
    }
  };

  return(
    <Table
      headers={tableHeaders}
      data={transactions?.data || []}
      totalData={transactions?.total || 0}
      paginate={(page: number) => paginate(page)}
      dataIdentifier={"processedTimestamp"}
      loading={loading}
    />
  );
}
