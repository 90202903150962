// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.payment_overview_header_component_account_info_container {
    margin-top: 30px;
    display: grid;
    grid-template-columns: 58% 1fr;
    grid-gap: 16px;
}

.payment_overview_header_component_account_info_card {
    background-color: #ffffff;
    padding: 16px;
    border-radius: 8px;
    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);
}

.payment_overview_header_component_account_info_card_grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
}

.payment_overview_header_component_account_info_card_grid > div {
    padding-bottom: 10px;
    margin-bottom: 10px;
}

.payment_overview_header_component_account_info_card_grid > div > div {
    min-height: 60px;
    height: 90%;
    width: 100%;
    border-right: 1px solid #cecece;
}

.payment_overview_header_component_account_info_card_grid > div > div > h6 {
    font-size: 14px;
    font-weight: normal;
    color: rgba(0,0,0, 0.36);
    margin-bottom: 10px;
}

.payment_overview_header_component_account_info_card_grid > div > div > p {
    font-size: 18px;
    font-weight: normal;
    color: #000000;
    margin-bottom: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Pages/Payments/Overview/PaymentsOverviewHeaderContainer/Tabs/PaymentsOverviewHeaderContainerAccountInfoTab/PaymentsOverviewHeaderContainerAccountInfoTab.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,aAAa;IACb,8BAA8B;IAC9B,cAAc;AAClB;;AAEA;IACI,yBAAyB;IACzB,aAAa;IACb,kBAAkB;IAGlB,yCAAyC;AAC7C;;AAEA;IACI,aAAa;IACb,8BAA8B;AAClC;;AAEA;IACI,oBAAoB;IACpB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,WAAW;IACX,WAAW;IACX,+BAA+B;AACnC;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,wBAAwB;IACxB,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,cAAc;IACd,mBAAmB;AACvB","sourcesContent":[".payment_overview_header_component_account_info_container {\n    margin-top: 30px;\n    display: grid;\n    grid-template-columns: 58% 1fr;\n    grid-gap: 16px;\n}\n\n.payment_overview_header_component_account_info_card {\n    background-color: #ffffff;\n    padding: 16px;\n    border-radius: 8px;\n    -webkit-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);\n    -moz-box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);\n    box-shadow: 0 2px 10px 0 rgba(0,0,0,0.15);\n}\n\n.payment_overview_header_component_account_info_card_grid {\n    display: grid;\n    grid-template-columns: 1fr 1fr;\n}\n\n.payment_overview_header_component_account_info_card_grid > div {\n    padding-bottom: 10px;\n    margin-bottom: 10px;\n}\n\n.payment_overview_header_component_account_info_card_grid > div > div {\n    min-height: 60px;\n    height: 90%;\n    width: 100%;\n    border-right: 1px solid #cecece;\n}\n\n.payment_overview_header_component_account_info_card_grid > div > div > h6 {\n    font-size: 14px;\n    font-weight: normal;\n    color: rgba(0,0,0, 0.36);\n    margin-bottom: 10px;\n}\n\n.payment_overview_header_component_account_info_card_grid > div > div > p {\n    font-size: 18px;\n    font-weight: normal;\n    color: #000000;\n    margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
