import React, {useEffect, useRef, useState} from "react";
import "./Pagination.css";
import paginateArrow from "img/pagination_arrow.png";
import { useTranslation } from "react-i18next";
import arrowImage from "img/Dashboard/PlansAndModules/chevron_down_blue.svg";

type PaginationProps = {
  postsPerPage: number;
  totalPosts: number;
  paginate: any;
  outsideControl?: number
  currentPage?: number
  limit?: number
  changeLimits?: (limit:number) => void
};

export default function Companies({
  postsPerPage,
  totalPosts,
  paginate,
  outsideControl,
  currentPage,
  limit,
  changeLimits
}: PaginationProps) {
  const { t } = useTranslation();
  const [firstItem, setFirstItem] = useState(((currentPage ?? 1)-1) * postsPerPage);
  const [lastItem, setLastItem] = useState(((currentPage ?? 1)) * postsPerPage);
  const [showLimitsMenu, setShowLimitsMenu] = useState(false);

  useEffect(() => {
    setLastItem(postsPerPage * selectedPage);
    setFirstItem((selectedPage-1) * postsPerPage);
  }, [postsPerPage]);
  const [selectedPage, setSelectedPage] = useState(currentPage ?? 1);

  useEffect(() => {
	  if(currentPage){
      setSelectedPage(currentPage);
      setLastItem(postsPerPage * currentPage);
   	    setFirstItem((currentPage-1) * postsPerPage);
	  }
  },[currentPage]);

  useEffect(() => {
    if (outsideControl) {
      changePage(outsideControl);
    }
  }, [outsideControl]);

  const changePage = (number: any) => {
    setSelectedPage(number);
    paginate(number);
    if (number === 0) {
      setFirstItem(0);
      setLastItem(postsPerPage);

    } else {
      setFirstItem((number - 1) * postsPerPage);
      setLastItem(number * postsPerPage);
    }
  };

  const pageNumbers = [];
  const prevPage = () => {
    selectedPage === 1
      ? changePage(selectedPage)
      : changePage(selectedPage - 1);
  };

  const nextPage = () => {
    selectedPage === pageNumbers.length
      ? changePage(selectedPage)
      : changePage(selectedPage + 1);
  };

  for (let i = 1; i <= Math.ceil(totalPosts / postsPerPage); i++) {
    pageNumbers.push(i);
  }

  const paginationMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event: any) => {
      if (!paginationMenuRef.current?.contains(event.target as Node)) {
        setShowLimitsMenu(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  return (
    <div className="pagination-footer-container space-between">
      <div className="pagination-footer-info row">
        <p>
          {t("companies_page.home_showing")}
          <span className="pagination-footer-count">
            {limit && firstItem > totalPosts ? firstItem-limit+1 : firstItem + 1}-{totalPosts < lastItem ? totalPosts : lastItem}
          </span>
          <span className="pagination-footer-text">
            {t("companies_page.home_of")}
          </span>
          <span className="pagination-footer-count">{totalPosts}</span>
        </p>
      </div>
      <div className="row">
        {limit && changeLimits
          ?
          <div
            className="change_limit_box"
            onClick={() => setShowLimitsMenu(!showLimitsMenu)}
            style={{cursor: "pointer"}}
          >
            <p>
              <span style={{cursor: "pointer"}}>
                {limit}{t("components.pagination.limits_title_span")}
              </span>
              <img src={arrowImage} alt="arrow"/>
            </p>
            {showLimitsMenu
              ?
              <div className="change_limit_menu" ref={paginationMenuRef}>
                <div className="change_limit_item" onClick={() => changeLimits(8)}>
                  {t("components.pagination.limits", {number: 8})}
                </div>
                <div className="change_limit_item" onClick={() => changeLimits(25)}>
                  {t("components.pagination.limits", {number: 25})}
                </div>
                <div className="change_limit_item" onClick={() => changeLimits(50)}>
                  {t("components.pagination.limits", {number: 50})}
                </div>
              </div>
              : null
            }
          </div>
          : null
        }
        {limit && limit >= totalPosts
          ? null
          : <div className="row">
            <div className="pagination-page center" onClick={() => prevPage()}>
              <img src={paginateArrow} alt="arrow-left" />
            </div>
            <div className="pagination-page center" onClick={() => nextPage()}>
              <img src={paginateArrow} alt="arrow-right" />
            </div>
          </div>
        }
      </div>
    </div>
  );
}
