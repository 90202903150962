import React from "react";
import "../SimpleComponents/Modal.css";
import closeIcon from "img/Dashboard/Employee/close_btn.svg";
import deleteImage from "img/Dashboard/Employee/delete_warning_image.svg";
import Spacer from "../SimpleComponents/Spacer";
import Button from "../Buttons/Button";
import { useTranslation } from "react-i18next";

interface CustomProps {
  closeModal: () => void;
  data?: any;
  type: string;
  handleSubmit: () => void;
}

export default function SubmitActionModal({
  closeModal,
  data,
  type,
  handleSubmit,
}: CustomProps) {
  const { t } = useTranslation();
  if (type === "employee_delete") {
    return (
      <div className="modal_container">
        <img
          onClick={closeModal}
          className="close_popup"
          src={closeIcon}
          alt="close"
        />
        <h4 className="text-center" data-testid="submit_text_center">
          {data.length > 1
            ? // eslint-disable-next-line max-len
            `${t("dashboard.employee.submit_remove_many_1")} ${
              data.length
            } ${t("dashboard.employee.submit_remove_many_2")}`
            : t("dashboard.employee.submit_remove")}
        </h4>
        <Spacer spacerHeight="30px" />
        <div className="center">
          <img
            data-testid="submit_delete_image"
            src={deleteImage}
            alt="delete"
          />
        </div>
        <p
          style={{ fontWeight: "normal", fontSize: "0.8rem" }}
          className="text-center"
        >
          {t("dashboard.employee.remove_warning_text")}
        </p>
        <div className="center">
          <div>
            <Button
              buttonStyles={{ minWidth: "140px" }}
              buttonType="primary"
              buttonLabel={t("dashboard.employee.employee_modal_btn_2")}
              buttonHandler={handleSubmit}
            />
            <p onClick={closeModal} className="modal_close_text center">
              {t("dashboard.employee.employee_modal_btn_1")}
            </p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className="modal_container">
        <img
          onClick={closeModal}
          className="close_popup"
          src={closeIcon}
          alt="close"
        />
      </div>
    );
  }
}
