import {Dispatch} from "redux";
import environment from "../../environment";
import axios from "axios";
import {
  CLEAR_INVOICES_MESSAGES,
  GET_FILTER_DATA_SUCCESS,
  GET_INVOICES_SUCCESS,
  GET_ONE_INVOICE_SUCCESS,
  INVOICES_ERROR,
  invoicesDispatchTypes,
  SHOW_INVOICES_LOADING,
  UPDATE_INVOICES
} from "../types/invoicesTypes";
import {errorHandler} from "../../utils/errorHandler";
import {getToken} from "../../utils/cookies/tokensCookie";
import {IUpdateInvoice} from "../../Interfaces/IInvoices";
import customHistory from "../../customHistory";
import { reduxRequestHandler } from "../../services/reduxRequestHandler";
import {urls} from "../../api/urls";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";

// Get invoices
export const getInvoices = (filters: any) =>
  async (dispatch: Dispatch<invoicesDispatchTypes>) => {
    const companyId = getUserCompany();
    const dynamicParams = {
      limit: filters.limit,
      skip: filters.skip,
    };
    Object.keys(filters).map((key:any) => {
      if (filters[key]) {
        Object.assign(dynamicParams, {[key]: filters[key]});
      }
    });
    await new reduxRequestHandler({
      url: `${urls.invoices.indexByMultipleDynamicSearch}${companyId}`,
      dynamicParams,
      successDispatchType: GET_INVOICES_SUCCESS,
      errorDispatchType: INVOICES_ERROR,
      errorPayload: "get_invoices"
    }).get(dispatch);
  };

// Get one invoice
export const getOneInvoice = (invoiceId:string) =>
  async (dispatch: Dispatch<invoicesDispatchTypes>) => {
    await new reduxRequestHandler({
      url: `${urls.invoices.invoices}/${invoiceId}`,
      successDispatchType: GET_ONE_INVOICE_SUCCESS,
      errorDispatchType: INVOICES_ERROR,
      errorPayload: "get_one_invoice"
    }).get(dispatch);
  };

// Update invoices
export const updateInvoice = (data:IUpdateInvoice) =>
  async (dispatch: Dispatch<invoicesDispatchTypes>) => {
    const token = getToken();
    const companyId = getUserCompany();
    const fullUrl = `${environment.baseUrl}${urls.invoices.statusByCompanyId}${companyId}`;
    const options = {headers: {"Authorization": `Bearer ${token}`}};
    try {
      await axios.put(fullUrl , data, options)
        .then((response) => {
          dispatch({
            type: UPDATE_INVOICES,
            payload: "update_invoices"
          });
        }, (error) => {
          dispatch({
            type: INVOICES_ERROR,
            payload: error.response?.data?.message || ""
          });
        });
    } catch (e: any) {
      if (!errorHandler(e.response?.status)) {
        dispatch({
          type: INVOICES_ERROR,
          payload: "update_invoices"
        });
      }
    }
  };

// Get data for filters
export const getFiltersData = () =>
  async (dispatch: Dispatch<invoicesDispatchTypes>) => {
    const companyId = getUserCompany();
    await new reduxRequestHandler({
      url: `${urls.invoices.filtersForOrderOverview}${companyId}`,
      successDispatchType: GET_FILTER_DATA_SUCCESS,
      errorDispatchType: INVOICES_ERROR,
      errorPayload: "get_filters_data"
    }).get(dispatch);
  };

// Clear invoices messages
export function clearInvoicesMessages() {
  return {
    type: CLEAR_INVOICES_MESSAGES
  };
}

// Show invoices loading
export function showInvoicesLoading() {
  return {
    type: SHOW_INVOICES_LOADING
  };
}

