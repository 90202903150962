import "./employee.css";
import ContentContainer from "Components/Containers/ContentContainer";
import { connect, useDispatch } from "react-redux";
import { RootState } from "redux/reducers/rootReducer";
import Button from "Components/Buttons/Button";
import React, { useEffect, useRef, useState } from "react";
import TableLoading from "Components/LoadingPlaceholders/TableLoading";
import {
  getHrZoneUsers,
  showUserLoading
} from "redux/actions/usersActions";
import queryString from "query-string";
import HrZoneEmployeeList from "Components/Lists/HrZoneEmployeeList";
import { isAllowed, userPermissions, userRoles } from "utils/permissionsAllow";
import Modal from "react-modal";
import SimpleInput from "Components/SimpleComponents/SimpleInput";
import closeIcon from "../../../img/Dashboard/Employee/close_btn.svg";
import HrzoneEmployeeSelectedMenu from "Components/SelectedMenu/HrzoneSelectedMenu";
import FilteringBlock from "Components/Blocks/FilteringBlock";
import SelectWithChoices from "Components/SimpleComponents/SelectWithChoices";
import i18n from "i18next";
import axios from "axios";
import { urls } from "api/urls";
import { getToken } from "utils/cookies/tokensCookie";
import { toast } from "react-toastify";
import CustomToastContainer from "Components/SimpleComponents/ToastContainer";
import { modalStyles } from "utils/modalStyles";
import UploadCsvEmployeeModal from "Components/Modals/HrZoneUploadCsvEmployeeModal";
import { useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import customHistory from "customHistory";

function EmployeePage(props: any) {
  const dispatch = useDispatch();

  const [showInviteModal, setInviteShowModal] = useState(false);
  const [showEditModal, setEditShowModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showInviteMultipleModal, setShowInviteMultipleModal] = useState(false);
  const [isMasterHr, setIsMasterHr] = useState(false);

  const navigate = useNavigate();

  const {t} = useTranslation();
  const local = window.localStorage.getItem("hrZoneEmployeeFilter");
  const [filter, setFilter] = useState<any>(local ? JSON.parse(local) : {
    status: undefined,
    firstName: "",
    lastName: "",
    email: "",
    isHR: 0
  });

  //handle localstorage
  const [initializedLocalFilters, setInitializedLocalFilters] = useState(false);
  useEffect(() => {
    if(initializedLocalFilters){
      const replacer = (key: any, value: any) => typeof value === "undefined" ? null : value;
      window.localStorage.setItem("hrZoneEmployeeFilter", JSON.stringify(filter, replacer));
    }
    setInitializedLocalFilters(true);
  },[filter]);

  const [modalUser, setModalUser] = useState({
    email: "",
    firstName: "",
    lastName: "",
    office: "",
    id: "",
    isHR: 0
  });

  const [clearSelections, setClearSelections] = useState(false);

  const [showCsvModal, setShowCsvModal] = useState(false);

  const headerMenuRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const handler = (event: any) => {
      if (!headerMenuRef.current?.contains(event.target as Node)) {
        setInviteShowModal(false);
        setEditShowModal(false);
        setModalUser({ email: "", firstName: "", lastName: "", office: "", id: "",isHR: 0});
        setShowDeleteModal(false);
        setShowInviteMultipleModal(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const location = useLocation();
  const parsedSearch = queryString.parse(location.search);
  const [currentPage, setCurrentPage] = useState( Number(location.search.replace("?page=", "")) || 1);

  useEffect(() => {
    setCurrentPage(parsedSearch.page ? +parsedSearch.page : 1);
  }, [parsedSearch]);

  useEffect(() => {
	  if(props.hrZoneAuth.sessionKey.length > 0){
      dispatch(showUserLoading());
      setShowLoading(true);
      dispatch(
		  getHrZoneUsers({
          perPage: 8,
          page: currentPage,
			  ...filter,
          hr_email: props.user.email,
		  sessionKey: props.hrZoneAuth.sessionKey
		  })
      );
	  }
  },[props.hrZoneAuth.sessionKey]);

  const [showLoading,  setShowLoading] = useState(true);

  useEffect(() => {
    setIsMasterHr(props.hrZoneAuth.isMasterHr === 1);
    setShowLoading(false);
  }, [props.employee.users]);


  const inviteEmployee = async () => {
    if (validInputs || props.selectedRows.length > 1) {
      try{
        const res: any = await axios.post(
          urls.hrZone.base + "invite",
          {
				  hr_email: props.user.email,
				  sessionKey: props.hrZoneAuth.sessionKey,
				  users: props.selectedRows.length > 1 ? props.selectedRows.reduce((users: any,  user:any) => {
					  if(user && !user.status){
						  users.push( {
                  email: user.email,
                  firstName: user.firstName,
                  isHR: 0,
                  lastName: user.lastName,
                  office: user.office
						  });
					  }
					  return users;
				  },[]) : [{ ...modalUser, isHR: 0 }]
          },
          {
				  headers: { Authorization: `Bearer ${getToken()}` },
				  timeout: 10000
          }
			  );
			  if (res.status == 200) {
          setInviteShowModal(false);
          setShowInviteMultipleModal(false);
          setModalUser({
            firstName: "",
            lastName: "",
            email: "",
            office: "",
            id: "",
            isHR: 0
          });
          toast.success(
				  <CustomToastContainer message={t("hrzone.toastMessages.invite.success")} status={1} />,
				  {
              autoClose: 5000
				  }
          );
          setClearSelections(!clearSelections);
          dispatch(
				  getHrZoneUsers({
					  perPage: 8,
					  page: currentPage,
              ...filter,
					  hr_email: props.user.email,
					  sessionKey: props.hrZoneAuth.sessionKey
				  })
				  );
			  } else {
          toast.error(
				  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
				  {
              autoClose: 5000
				  }
          );
			  }
      }catch(e){
        toast.error(
          <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
          {
				  autoClose: 5000
          }
			  );
      }
    } else {
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.wrongInput")} status={3} />,
        {
          autoClose: 5000
        }
      );
    }
  };

  const editEmployee = async () => {
    if (validInputs) {
      try{
        const res: any = await axios.post(
          urls.hrZone.base + "editEmployee",
          {
				  hr_email: props.user.email,
				  email: modalUser.email,
				  firstName: modalUser.firstName,
				  lastName: modalUser.lastName,
				  office: modalUser.office,
				  sessionKey: props.hrZoneAuth.sessionKey,
				  id: modalUser.id
          },
          {
				  headers: { Authorization: `Bearer ${getToken()}` },
				  timeout: 10000
          }
			  );
			  if (res.status == 200 && res.data.success === 1) {
          setEditShowModal(false);
          setModalUser({
				  firstName: "",
				  lastName: "",
				  email: "",
				  office: "",
				  id: "",
				  isHR: 0
          });
          toast.success(
				  <CustomToastContainer message={t("hrzone.toastMessages.edit.employee.success")} status={1} />,
				  {
              autoClose: 5000
				  }
          );
          setClearSelections(!clearSelections);
          dispatch(
				  getHrZoneUsers({
              perPage: 8,
              page: currentPage,
              ...filter,
              hr_email: props.user.email,
			  sessionKey: props.hrZoneAuth.sessionKey
				  })
          );
			  } else {
          toast.error(
				  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
				  {
              autoClose: 5000
				  }
          );
			  }
      }catch(e){
        toast.error(
          <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
          {
				  autoClose: 5000
          }
			  );
      }

    } else {
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.wrongInput")} status={3} />,
        {
          autoClose: 5000
        }
      );
    }
  };

  const deleteEmployee = async () => {
	  try{
      const res: any = await axios.post(
        urls.hrZone.base + "deleteEmployee",
        {
			  hr_email: props.user.email,
			  sessionKey: props.hrZoneAuth.sessionKey,
			  users: props.selectedRows.map((user: any) => user.email)
        },
        {
			  headers: { Authorization: `Bearer ${getToken()}` },
			  timeout: 10000
        }
		  );
		  if (res.status == 200 && res.data.success === 1) {
        toast.success(
			  <CustomToastContainer message={t("hrzone.toastMessages.delete.employee.success")} status={1} />,
			  {
            autoClose: 5000
			  }
        );
        setClearSelections(!clearSelections);
        dispatch(
			  getHrZoneUsers({
            perPage: 8,
            page: currentPage,
            ...filter,
            hr_email: props.user.email,
            sessionKey: props.hrZoneAuth.sessionKey
			  })
        );
        setShowDeleteModal(false);
		  } else {
        toast.error(
			  <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
			  {
            autoClose: 5000
			  }
        );
		  }
	  }catch(e){
      toast.error(
        <CustomToastContainer message={t("hrzone.toastMessages.error")} status={3} />,
        {
			  autoClose: 5000
        }
		  );
	  }

  };


  const validateEmail = () => {
    return String(modalUser.email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const validInputs =
    modalUser.firstName.length > 0 &&
    modalUser.lastName.length > 0 &&
    validateEmail();

  const invitationModal = (
    <Modal
		  style={{
        overlay: {
			  backgroundColor: "rgba(55,52,52,0.7)",
			  zIndex: 9999
        },
        content: {
			  top: "50%",
			  left: "50%",
			  right: "auto",
			  bottom: "auto",
			  marginRight: "-50%",
			  transform: "translate(-50%, -50%)",
			  width: "480px",
			  //   height: "520px",
			  padding: "0px",
			  borderRadius: "20px"
        }
		  }}
		  isOpen={showInviteModal}
    >
		  <div
        ref={headerMenuRef}
        style={{
			  display: "flex",
			  flexDirection: "column",
			  alignItems: "center",
			  justifyContent: "space-between",
			  height: "100%",
			  padding: "40px"
        }}
		  >
        <img
			  onClick={() => setInviteShowModal(false)}
			  style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
			  }}
			  src={closeIcon}
			  alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>{t("hrzone.modals.employee.invite.title")}</h1>
        <div style={{ width: "100%" }}>
			  <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={t("hrzone.modals.employee.email")}
            isDisabled={false}
            inputValue={modalUser.email}
            handleChange={(e) => {
				  setModalUser({ ...modalUser, email: e });
            }}
			  />
			  <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={t("hrzone.modals.employee.firstName")}
            isDisabled={false}
            inputValue={modalUser.firstName}
            handleChange={(e) => {
				  setModalUser({ ...modalUser, firstName: e });
            }}
			  />
			  <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={t("hrzone.modals.employee.lastName")}
            isDisabled={false}
            inputValue={modalUser.lastName}
            handleChange={(e) => {
				  setModalUser({ ...modalUser, lastName: e });
            }}
			  />
			  {/* <SimpleInput
				name={""}
				style={{ width: "100%" }}
				id={"lastName"}
				placeholder={t("hrzone.modals.employee.office")}
				isDisabled={false}
				inputValue={modalUser.office}
				handleChange={(e) => {
				  setModalUser({ ...modalUser, office: e });
				}}
			  /> */}
        </div>
        <div
			  style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end"
			  }}
        >
			  <p
            style={{
				  textAlign: "center",
				  marginBottom: "15px",
				  fontSize: "12px",
				  color: "#9aa9b3"
            }}
			  >
            {t("hrzone.modals.separator")}
			  </p>

			  <Button
            buttonStyles={{
				  margin: "0px auto 15px auto",
				  fontWeight: 600,
				  background: "none"
            }}
            buttonType={"secondary"}
            buttonLabel={t("hrzone.modals.csvUpload")}
            buttonHandler={() => {setShowCsvModal(true);setInviteShowModal(false);}}
			  />
			  <Button
            buttonStyles={{
				  width: "100%",
				  maxWidth: "100%",
				  fontWeight: 600
            }}
            disabled={!validInputs}
            buttonType={"primary"}
            buttonLabel={t("hrzone.buttons.invite")}
            buttonHandler={() => {
				  inviteEmployee();
				  setInviteShowModal(false);
            }}
			  />
        </div>
		  </div>
    </Modal>
	  );

  const editModal = (
    <Modal
      style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showEditModal}
    >
      <div
        ref={headerMenuRef}
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px"
        }}
      >
        <img
          onClick={() => setEditShowModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />

        <h1 style={{ marginBottom: "30px" }}>{t("hrzone.modals.employee.edit.title")}</h1>
        <div style={{ width: "100%" }}>
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"email"}
            placeholder={t("hrzone.modals.employee.email")}
            isDisabled={false}
            inputValue={modalUser.email}
            handleChange={(e) => {
              setModalUser({ ...modalUser, email: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"firstName"}
            placeholder={t("hrzone.modals.employee.firstName")}
            isDisabled={false}
            inputValue={modalUser.firstName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, firstName: e });
            }}
          />
          <SimpleInput
            name={""}
            style={{ width: "100%" }}
            id={"lastName"}
            placeholder={t("hrzone.modals.employee.lastName")}
            isDisabled={false}
            inputValue={modalUser.lastName}
            handleChange={(e) => {
              setModalUser({ ...modalUser, lastName: e });
            }}
          />

        </div>
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "end"
          }}
        >
          <Button
            buttonStyles={{
              width: "100%",
              maxWidth: "100%",
              fontWeight: 600
            }}
            buttonType={"primary"}
            buttonLabel={t("hrzone.buttons.save")}
            buttonHandler={() => editEmployee()}
          />
        </div>
      </div>
    </Modal>
  );

  const deleteModal = (
	  <Modal
	  style={{
        overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
        },
        content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
        }
      }}
      isOpen={showDeleteModal}>
		   <div
        ref={headerMenuRef}
        style={{
			  display: "flex",
			  flexDirection: "column",
			  alignItems: "center",
			  justifyContent: "space-between",
			  height: "100%",
			  padding: "40px"
        }}
		  >
			   <img
          onClick={() => setShowDeleteModal(false)}
          style={{
            position: "absolute",
            top: 20,
            right: 20,
            cursor: "pointer",
            height: "15px",
            width: "15px"
          }}
          src={closeIcon}
          alt="close"
        />
        <h1>{t("hrzone.modals.employee.delete.multipleTitle")}</h1>
        <p style={{textAlign: "center", marginTop: "20px"}}>{t("hrzone.modals.employee.delete.multipleText", {count: props.selectedRows.length})}</p>
		  {/* <div style={{display: "flex", flexWrap: "wrap",marginTop: "20px", maxHeight: "300px", overflowY: "scroll"}}>
	  		{props.selectedRows.map((row:any) => <div style={{ minWidth: "fit-content", margin: "8px"}}>{row.firstName + " " + row.lastName}</div>)}
		  </div> */}
		  <Button
          buttonStyles={{
            width: "50%",
            maxWidth: "100%",
            fontWeight: 600,
			  marginTop: "20px"
          }}
          buttonType={"primary"}
          buttonLabel={t("hrzone.buttons.delete")}
          buttonHandler={() => deleteEmployee()}
        />
		  </div>

	  </Modal >
  );

  const inviteMultipleModal = (
    <Modal
      style={{
	  overlay: {
          backgroundColor: "rgba(55,52,52,0.7)",
          zIndex: 9999
	  },
	  content: {
          top: "50%",
          left: "50%",
          right: "auto",
          bottom: "auto",
          marginRight: "-50%",
          transform: "translate(-50%, -50%)",
          width: "480px",
          padding: "0px",
          borderRadius: "20px"
	  }
      }}
      isOpen={showInviteMultipleModal}>
		 <div
		  ref={headerMenuRef}
		  style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-between",
          height: "100%",
          padding: "40px"
		  }}
      >
			 <img
          onClick={() => setShowInviteMultipleModal(false)}
          style={{
		  position: "absolute",
		  top: 20,
		  right: 20,
		  cursor: "pointer",
		  height: "15px",
		  width: "15px"
          }}
          src={closeIcon}
          alt="close"
	  />
	  <h1>{t("hrzone.modals.employee.invite.multipleTitle")}</h1>
	  <p style={{textAlign: "center", marginTop: "20px"}}>{t("hrzone.modals.employee.invite.multipleText", {count: props.selectedRows.filter((user: any) => !user.status).length})} </p>
        {/* <div style={{display: "flex", flexWrap: "wrap",marginTop: "20px", maxHeight: "300px", overflowY: "scroll"}}>
			{props.selectedRows.map((row:any) => <div style={{ minWidth: "fit-content", margin: "8px"}}>{row.firstName + " " + row.lastName}</div>)}
		</div> */}
        <Button
		  buttonStyles={{
            width: "50%",
            maxWidth: "100%",
            fontWeight: 600,
            marginTop: "20px"
		  }}
		  buttonType={"primary"}
		  buttonLabel={t("hrzone.buttons.invite")}
		  buttonHandler={() => inviteEmployee()}
        />
      </div>

    </Modal >
  );

  return (
    <ContentContainer>
      <Modal isOpen={showCsvModal} style={modalStyles}>
        <UploadCsvEmployeeModal
				  // users={[]}
				  filter={filter}
				  isHR={0}
				  closeModal={() => setShowCsvModal(false)} hrZoneAuth={props.hrZoneAuth}        />
      </Modal>
      <div className="employee_page">
        {invitationModal}
        {editModal}
        {deleteModal}
        {inviteMultipleModal}
        <h1 style={{ marginBottom: "30px" }}>{t("hrzone.employee.title")}</h1>
        <div
          style={{
            display: "flex",
            justifyContent: "end",
            marginBottom: "30px"
          }}
        >
          {props.selectedRows.length > 0 && isMasterHr ? (
            <HrzoneEmployeeSelectedMenu
              data={props.selectedRows}
              user={props.user}
              type={"employee"}
              openModal={(type) => {
                if(type !== "delete" && props.selectedRows.length === 1){
                  setModalUser({
                    email: props.selectedRows[0].email,
                    firstName: props.selectedRows[0].firstName,
                    lastName: props.selectedRows[0].lastName,
                    office: props.selectedRows[0].office,
					  id:props.selectedRows[0]._id,
					  isHR: 0
						  });
                }

                if(type === "invite"){
                  if(props.selectedRows.length > 1){
                    setShowInviteMultipleModal(true);
                  }else{

                    setInviteShowModal(true);
                  }
                }
                if(type === "edit"){
                  setEditShowModal(true);
                }
                if(type === "delete"){
                  setShowDeleteModal(true);
                }
              }}
            />
          ) : (
            <div style={{ height: "40px" }} />
          )}
          {isMasterHr &&
          <Button
            buttonType={"primary"}
            buttonLabel={t("hrzone.buttons.inviteEmployee")}
            buttonStyles={{
              display: "block",
              fontWeight: 600
            }}
            buttonHandler={() => setInviteShowModal(true)}
          />
          }
        </div>
        <div>
          <FilteringBlock title={i18n.t("dashboard.plans.filters.title")}>
            <>
              <div>
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("hrzone.employee.filters.firstName")}
                </p>

                <SimpleInput
                  inputStyle={{height: "50px"}}
                  id={"firstName"}
                  name={""}
                  inputValue={filter.firstName}
                  handleChange={(e) =>
                    setFilter({
                      ...filter,
                      firstName: e
                    })
                  }
                />
              </div>

              <div>
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("hrzone.employee.filters.lastName")}
                </p>

                <SimpleInput
                  inputStyle={{height: "50px"}}
                  id={"lastName"}
                  name={""}
                  inputValue={filter.lastName}
                  handleChange={(e) =>
                    setFilter({
                      ...filter,
                      lastName: e
                    })
                  }
                />
              </div>

              <div>
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("hrzone.employee.filters.email")}
                </p>

                <SimpleInput
                  inputStyle={{height: "50px"}}
                  id={"email"}
                  name={""}
                  inputValue={filter.email}
                  handleChange={(e) =>
                    setFilter({
                      ...filter,
                      email: e
                    })
                  }
                />
              </div>

              <div>
                <p className="filter_label" style={{ height: "1.2rem" }}>
                  {t("hrzone.employee.filters.status")}
                </p>
                <SelectWithChoices
                  style={{marginTop: "5px", borderRadius: "4px"}}
                  defaultValue={""}
                  name={"Status"}
                  selectPlaceHolder={i18n.t(
                    "dashboard.overview.filter_opt_3_place"
                  )}
                  selectId="status"
                  isMulti={"no"}
                  options={[
                    {
                      value: "all",
                      label: i18n.t("dashboard.employee.filter.active.all")
                    },
                    {
                      value: "true",
                      label: i18n.t("hrzone.employee.email.accepted")
                    },
                    {
                      value: "false",
                      label: i18n.t(
                        "hrzone.employee.email.pending"
                      )
                    }
                  ]}
                  handleAddChoice={(option) =>
                    setFilter({
                      ...filter,
                      status:
                        option.value === "all"
                          ? undefined
                          : option.value === "true"
                            ? 1
                            : 0
                    })
                  }
                />
              </div>
            </>
          </FilteringBlock>
        </div>

        {!(props.employee.users) ? (
          <TableLoading rowsNumber={8} />
        ) : (
          <HrZoneEmployeeList
            user={props.user}
            filter={filter}
            employee={props.employee}
            isHR={0}
            clearSelections={clearSelections}
            isMasterHr={isMasterHr}
          />
        )}
      </div>
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    user: state.users.userInfo.user,
    employee: state.users.users,
    loading: state.users.loading,
    sidebar: state.sidebar.sidebarTabCondition,
    checkedBoxes: state.employeeCheckboxesState.selectedCheckboxes,
    departments: state.depsAndBranches.departments,
    selectedRows: state.selectedRows.selectedRows,
    usersMessagesState: state.users,
    hrZoneAuth: state.hrZone
  };
};

export default connect(mapStateToProps, null)(EmployeePage);
