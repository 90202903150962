export enum VirtualCardOnboardingStep {
  INITIAL = "initial",
  INFO_FORM = "infoForm",
  PASSWORD_CREATION = "passwordCreation",
  EMAIL_VERIFICATION = "emailVerification",
  FIRST_LOGIN = "firstLogin",
  SMS_VERIFICATION = "smsVerification",
  KYB = "KYB",
  KYB_EMAIL_INFO = "KYBEmailInfo",
  KYC = "KYC",
  IN_REVIEW = "inReview",
  COMPLETED = "completed"
}

export const getVirtualCardOnboardingNextStepEnum = (currentStep: VirtualCardOnboardingStep): VirtualCardOnboardingStep => {
  const keys = Object.keys(VirtualCardOnboardingStep);
  for (const key of keys) {
    if ((VirtualCardOnboardingStep as any)[key] === currentStep) {
      const foundKey = keys[keys.indexOf(key) + 1];
      return (VirtualCardOnboardingStep as any)[foundKey];
    }
  }
  return currentStep;
};

export const getVirtualCardOnboardingPreviousStepEnum = (currentStep: VirtualCardOnboardingStep): VirtualCardOnboardingStep => {
  const keys = Object.keys(VirtualCardOnboardingStep);
  for (const key of keys) {
    if ((VirtualCardOnboardingStep as any)[key] === currentStep) {
      const foundKey = keys[keys.indexOf(key) - 1];
      return (VirtualCardOnboardingStep as any)[foundKey];
    }
  }
  return currentStep;
};


export enum weavrStatusEnum {
  notCreated = 0,
  sendCreateRequest = 1,
  passwordCreated = 2,
  emailVerified = 3,
  loggedIn = 4,
  smsVerified = 5,
  KYBStarted = 6,
  KYBPending = 7,
  KYBApproved = 8,
  KYBRejected = 9,
  KYCStarted = 10,
  KYCPending = 11,
  KYCApproved = 12,
  KYCRejected = 13
}
