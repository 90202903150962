// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card_creation_loading_container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.card_creation_loading_container > img {
    width: 80%;
    max-width: 400px;
}

.card_creation_loading_container > h6 {
    font-size: 18px;
    margin-bottom: 20px;
    font-weight: 700;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Modals/EmployeePage/CardCreationLoadingModal/CardCreationLoadingModal.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,aAAa;AACjB;;AAEA;IACI,UAAU;IACV,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".card_creation_loading_container {\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    padding: 20px;\n}\n\n.card_creation_loading_container > img {\n    width: 80%;\n    max-width: 400px;\n}\n\n.card_creation_loading_container > h6 {\n    font-size: 18px;\n    margin-bottom: 20px;\n    font-weight: 700;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
