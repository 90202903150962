import React, {useEffect} from "react";
import "./ListCounterFeedback.css";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import {changeSelectedRows} from "redux/actions/selectedUsersFromListActions";
import { useTranslation } from "react-i18next";

interface CustomProps {
	counter: number,
	selectAll: boolean,
	totalCount: number,
	employee?: any,
	departmentIDs: any,
	branchIDs: any,
	plans: any,
	currentPlanIndex: number,
	currentPlan: any,
	startDate: string,
	endDate: string
}

function ListCounterFeedback(
  {counter, totalCount, plans, currentPlan, employee, currentPlanIndex}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Check if chosen dates are conflicted with local
  const checkDates = (plan:any) => {
    const checkedPlanStartDate = plan.dateRange.startDate;
    const checkedPlanEndDate = plan.dateRange.endDate;
    const currentPlanStartDate = currentPlan.dateRange.startDate;
    const currentPlanEndDate = currentPlan.dateRange.endDate;
    if (checkedPlanStartDate === currentPlanStartDate) {
      return true;
    } else if (checkedPlanEndDate === currentPlanEndDate) {
      return true;
    } else if (currentPlanStartDate > checkedPlanStartDate
			&& currentPlanStartDate < checkedPlanEndDate) {
      return true;
    }
    else if (currentPlanStartDate < checkedPlanStartDate
			&& currentPlanEndDate > checkedPlanEndDate) {
      return true;
    } else if (currentPlanEndDate > checkedPlanStartDate
			&& currentPlanEndDate < checkedPlanEndDate) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    if (employee.users && employee.users.length === totalCount && totalCount > 5) {
      const newEmployee = employee.users.map((item:any) => {
        return ({
          ...item, isClicked: true, isBlocked: false
        });
      });

      const temporaryArray:any = [...newEmployee];
      const newArray:any = [];
      temporaryArray.map((post:any, idx:number) => {
        let exists = false;
        plans.forEach((plan:any, i:number) => {
          if (i !== currentPlanIndex) {
            if (checkDates(plan)) {
              plan.employee.forEach((localUser:any) => {
                if (localUser._id === post._id) {
                  exists = true;
                }
              });
            }
          }
        });
        if (!exists) {
          newArray.push(post);
        }
      });
      const enabledArray = newArray.filter((item:any) => !item.hasPlan);
      dispatch(changeSelectedRows(enabledArray));
    }
  }, [employee, dispatch, totalCount]);
  return (
    <div className="list_feedback_container">
      <h3 className="list_feedback_text">
        {t("dashboard.plans.feedback_1")}
        {counter}
        {t("dashboard.plans.feedback_2")}
        {totalCount}
        {t("dashboard.plans.feedback_3")}
      </h3>
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    employee: state.users.users
  };
};

export default connect(mapStateToProps, null)(ListCounterFeedback);
