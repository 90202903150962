import React from "react";
import LoadingText from "../../../../../../../LoadingPlaceholders/LoadingText";
import {Line} from "react-chartjs-2";
import { ICardData } from "../PaymentsOverviewHeaderContainerAccountInfoTab";
import {useTranslation} from "react-i18next";

interface IProps {
  cardsData: any[];
  spendData: any;
  chartLabels: any[];
}

export default function PaymentOverviewHeaderContainerAccountTabCardsLayout(props: IProps) {
  const { t } = useTranslation();

  const maxLimit = Math.max(...props.spendData);
  const step = Math.round(maxLimit / 5);

  const data = {
    labels: props.chartLabels,
    datasets: [
      {
        borderWidth: 1,
        label: t("charts.daily_chart.restaurant"),
        data: props.spendData,
        fill: false,
        backgroundColor: "rgb(255, 82, 66 ,0.2)",
        borderColor: "#FF5242",
        pointBackgroundColor: "#FF5242",
        yAxisID: "y-axis-1",
        lineTension: 0,
      }
    ],
  };

  const options = {
    elements: {
      point: {
        radius: 2,
      },
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          const item = data["datasets"][tooltipItem.datasetIndex];
          return `${item.label} ${item.data && item.data[tooltipItem.index].toFixed(2)} €`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
            borderDash: [3, 3],
            color: "rgb(100, 87, 232 ,0.1)",
          },
          ticks: {
            suggestedMax: maxLimit || 23,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: step,
            callback: function (value: any, index: any, values: any) {
              return `€ ${value}`;
            },
          },
        },
        {
          type: "linear",
          display: false,
          position: "right",
          id: "y-axis-2",
          ticks: {
            suggestedMax: maxLimit || 23,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: step,
            callback: function (value: any, index: any, values: any) {
              return `€ ${value}`;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            fontSize: 10,
            fontColor: "#7C828A",
          },
        },
      ],
    },
  };

  return (
    <div className="payment_overview_header_component_account_info_container">

      <div className="payment_overview_header_component_account_info_card payment_overview_header_component_account_info_card_grid">

        {props.cardsData.map((card: ICardData) => (
          <div key={card.key} style={{borderBottom: card.cardContainerBottomBorder ? "1px solid #cecece" : "none"}}>
            <div style={{
              borderRight: card.cardNestedRightBorder ? "1px solid #cecece" : "none",
              paddingLeft: card.paddingLeft ? "16px" : 0
            }}>

              <h6>
                {card.cardTitle}
              </h6>

              {card.cardData
                ? <p>
                  {card.cardData}
                </p>
                : <LoadingText
                  height="20px"
                  width="50%"
                  borderRadius="5px"
                  opacity="opacity(0.5)"
                />
              }

            </div>
          </div>
        ))}
      </div>

      <div className="payment_overview_header_component_account_info_card">
        <Line data={data} options={options} />
      </div>

    </div>
  );
}
