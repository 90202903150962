import moment from "moment";
import i18n from "../../i18next";
import {getToken} from "../../utils/cookies/tokensCookie";
import environment from "../../environment";
import axios from "axios";
import {errorHandler} from "../../utils/errorHandler";
import {getUserCompany} from "../../utils/cookies/userCookies/userCookies";
import {BadgeColorsEnum} from "../../Components/Elements/Badge/Badge";


export default class PlansHelperService {
  constructor() {
    //
  }

  getBillingStatus(plan: any): {color: BadgeColorsEnum; text: string} {
    if (
      plan?.isBilled === "billed"
    ) {
      return {
        color: BadgeColorsEnum.blue,
        text: i18n.t("dashboard.plans.table_status_billed")
      };
    } else if (
      moment(plan?.endDate).toDate() < new Date() &&
      plan.isActive
    ) {
      return {
        color: BadgeColorsEnum.yellow,
        text: i18n.t("dashboard.plans.table_body_ready")
      };
    } else if (plan?.isActive) {
      return {
        color: BadgeColorsEnum.green,
        text: i18n.t("dashboard.plans.table_body_1")
      };
    } else {
      return {
        color: BadgeColorsEnum.red,
        text: i18n.t("dashboard.plans.table_body_1_5")
      };
    }
  }

  async billPlan(option: string, planId: string, title: string) {
    let type;
    if (option === "csv - Mitarbeiter Übersicht") {
      type = 3;
    }
    if (option === "csv - Belege") {
      type = 2;
    }
    if (option === "xlsx") {
      type = 1;
    }
    if (type && planId) {
      let fileName = title;

      const token = getToken();
      const companyId = getUserCompany();
      const companyID = `?companyId=${companyId}`;
      const fullUrl = `${environment.baseUrl}export/billing${companyID}`;

      if (type && type === 1) {
        fileName += ".xlsx";
      }

      if (type && type === 2) {
        fileName += ".csv";
      }

      if (type && type === 3) {
        fileName += ".csv";
      }

      const dynamicParams = {
        planId: planId,
        exportType: type
      };
      try {
        const response = await axios({
          url: fullUrl,
          method: "GET",
          responseType: "blob",
          params: dynamicParams,
          headers: {Authorization: `Bearer ${token}`},
        });
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        link.click();
      } catch (e: any) {
        if (!errorHandler(e.request.status)) {
          console.error(e);
        }
      }
    }
  }

}
