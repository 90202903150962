import React from "react";
import "./SubTitle.css";

const SubTitle = (props: any) => {
	
  return(
    <h3>
      {props.children}
    </h3>
  );
};

export default SubTitle;
