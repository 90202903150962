// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.daily_dot {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    margin-right: 5px;
}
.blue {
    background-color: #9bbefd;
}
.gray {
    background-color: #9B9B9B;
}
.comparisons_chart_container {
    width: 100%;
    height: 330px;
    padding-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/DashboardCharts/DailyComparisons.css"],"names":[],"mappings":";AACA;IACI,UAAU;IACV,WAAW;IACX,kBAAkB;IAClB,iBAAiB;AACrB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,WAAW;IACX,aAAa;IACb,iBAAiB;AACrB","sourcesContent":["\n.daily_dot {\n    width: 8px;\n    height: 8px;\n    border-radius: 50%;\n    margin-right: 5px;\n}\n.blue {\n    background-color: #9bbefd;\n}\n.gray {\n    background-color: #9B9B9B;\n}\n.comparisons_chart_container {\n    width: 100%;\n    height: 330px;\n    padding-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
