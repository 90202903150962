import React from "react";

interface CustomProps {
	text: string
}

export default function NoRecordsFound({text}:CustomProps){
  return (
    <h3 className="center">{text}</h3>
  );
}
