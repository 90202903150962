import React, { useEffect, useState } from "react";
import "../SimpleComponents/TableSelectedItemActions.css";
import penIcon from "img/Dashboard/pen_icon.png";
import deactivateIcon from "img/Dashboard/Employee/deactivate_employee.svg";
import inviteUserIcon from "img/Dashboard/Employee/invite_user_2.svg";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import restoreIcon from "img/restore.png";
import { RootState } from "redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";

interface CustomProps {
  data: any;
  openSubmitModal: (
    users: string[],
    state: { showWarning: boolean; type: string }
  ) => void;
  users: any;
  openInvitationModal: (users: any[]) => void;
}

export default function EmployeeSelectedMenu({
  data,
  openSubmitModal,
  openInvitationModal,
  users
}: CustomProps) {
  const { t } = useTranslation();
  const [showMenu, setShowMenu] = useState(false);
  const navigate = useNavigate();

  const [updatedData, setUpdatedData] = useState(data);
  const [currentPosts, setCurrentPosts] = useState([]);

  const deleteUsersHandler = (type: string) => {
    const users: any = [];
    updatedData.map((item: any) => users.push(item._id));
    openSubmitModal(users, { showWarning: true, type: type });
    setShowMenu(false);
  };

  const getPlanStati = () => {
    return updatedData.map((item: any) =>
      //true  true false - green yellow  red
      item.isActive ? (item.isActivated ? true : true) : false
    );
  };

  const getUserStati = () => {
    return updatedData.map((item: any) => (item.isActivated ? true : false));
  };

  useEffect(() => {
    if (users && users.length > 0) {
      setCurrentPosts(users);
    }
  }, [users]);

  //Hook to update data so the isActive property updates if it changes
  useEffect(() => {
    const temp: any[] = [];
    data.forEach((checkedItem: any) => {
      currentPosts.forEach((post: any) => {
        if (checkedItem._id === post._id) {
          temp.push(post);
        }
      });
    });
    const newIds = temp.map((item: any) => item._id);
    data.forEach((checkedItem: any) => {
      if (!newIds.includes(checkedItem._id)) {
        temp.push(checkedItem);
      }
    });
    setUpdatedData(temp);
  }, [currentPosts, data]);

  return (
    <div className="selected_table_actions">
      <p data-testid="string_item">
        {data.length && data.length > 1
          ? `${data.length} ${t("dashboard.employee.selected_title_1")}`
          : `${data.length} ${t("dashboard.employee.selected_title_1_single")}`}
      </p>
      <div
        onClick={() => setShowMenu(!showMenu)}
        className={
          showMenu ? "selected_actions_dots selected" : "selected_actions_dots"
        }
      >
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
        <div className="selected_action_dot" />
      </div>
      <div
        className={
          showMenu
            ? "selected_actions_menu shadow"
            : "selected_actions_menu_hide"
        }
        style={data.length > 1 ? { bottom: -71 } : {}}
      >
        {data.length > 1 ? (
          <></>
        ) : (
          <div
            data-testid="employee_select_menu_plans"
            onClick={() => navigate("/plans")}
          >
            <img src={penIcon} alt="edit" />
            <p>{t("dashboard.employee.selected_menu_1")}</p>
          </div>
        )}
        {getPlanStati().includes(true) ? (
          <div onClick={() => deleteUsersHandler("delete")}>
            <img src={deactivateIcon} alt="deactivate" />
            <p>{t("dashboard.employee.selected_menu_2")}</p>
          </div>
        ) : (
          <></>
        )}
        {getPlanStati().includes(false) ? (
          <div
            onClick={() => deleteUsersHandler("restore")}
            className="table_menu_item row"
          >
            <img src={restoreIcon} alt="restore" />
            <p>{t("dashboard.employee.employee_menu_5")}</p>
          </div>
        ) : (
          <></>
        )}
        {getUserStati().includes(false) ? (
          <div
            onClick={() => openInvitationModal(updatedData)}
            className="table_menu_item row"
          >
            <img src={inviteUserIcon} alt="invite" />
            <p>{t("dashboard.employee.employee_menu_invite")}</p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
}
