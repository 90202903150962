// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `div.btn.secondary.icon img,
div.btn.primary.icon img {
    margin-top: -15px;
    display: inline;
}

div.btn.secondary.icon p {
    margin-right: 20px;
    color: #292452;
    display: inline-block;
    vertical-align: top;
}

div.btn.primary.icon p {
    margin-right: 20px;
    color: #ffffff;
    display: inline-block;
    vertical-align: top;
}
div.btn.success.icon p {
    color: #ffffff;
    display: inline-block;
    vertical-align: top;
}

div.btn.success.icon img {
    margin-top: -2px;
    margin-right: 10px;
}

.disabled {
    opacity: 0.25;
    cursor: not-allowed;
}

.add_branch_btn {
    width: 30px;
    height: 30px;
    position: absolute;
    right: 20px;
}

.btn > div > p {
    font-weight: 600;
    font-size: 16px;
}
`, "",{"version":3,"sources":["webpack://./src/Components/Buttons/ButtonWithIcon.css"],"names":[],"mappings":"AAAA;;IAEI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,kBAAkB;IAClB,cAAc;IACd,qBAAqB;IACrB,mBAAmB;AACvB;AACA;IACI,cAAc;IACd,qBAAqB;IACrB,mBAAmB;AACvB;;AAEA;IACI,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,WAAW;AACf;;AAEA;IACI,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":["div.btn.secondary.icon img,\ndiv.btn.primary.icon img {\n    margin-top: -15px;\n    display: inline;\n}\n\ndiv.btn.secondary.icon p {\n    margin-right: 20px;\n    color: #292452;\n    display: inline-block;\n    vertical-align: top;\n}\n\ndiv.btn.primary.icon p {\n    margin-right: 20px;\n    color: #ffffff;\n    display: inline-block;\n    vertical-align: top;\n}\ndiv.btn.success.icon p {\n    color: #ffffff;\n    display: inline-block;\n    vertical-align: top;\n}\n\ndiv.btn.success.icon img {\n    margin-top: -2px;\n    margin-right: 10px;\n}\n\n.disabled {\n    opacity: 0.25;\n    cursor: not-allowed;\n}\n\n.add_branch_btn {\n    width: 30px;\n    height: 30px;\n    position: absolute;\n    right: 20px;\n}\n\n.btn > div > p {\n    font-weight: 600;\n    font-size: 16px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
