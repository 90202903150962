import React, {useEffect, useState} from "react";
import "./PlanBudgetMovingModal.css";
import closeIcon from "../../../../img/Dashboard/Employee/close_btn.svg";
import Button from "../../../Buttons/Button";
import {apiPlanService} from "../../../../api/services/api.plan.service";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../SimpleComponents/ToastContainer";
import {useTranslation} from "react-i18next";
import {rewriteCurrency} from "../../../../utils/rewriteCurrency";
import PlansHelperService from "../../../../pages/plans/plans.service";
import {RootState} from "../../../../redux/reducers/rootReducer";
import {connect} from "react-redux";

interface IProps {
  closeModal: () => void;
  planId: string | undefined;
  downloadOption: {option: any, planId: string, title: string},
  company?: any;
}

function PlanBudgetMovingModal(props: IProps) {
  const { t } = useTranslation();

  const [budgetToMove, setBudgetToMove] = useState(0);
  const [negativeBudgetToMove, setNegativeBudgetToMove] = useState(0);
  const [getBudgetMovingDataLoading, setGetBudgetMovingDataLoading] = useState(false);
  const [confirmBudgetMovingDataLoading, setConfirmBudgetMovingDataLoading] = useState(false);

  const getBudgetMovingAmount = async () => {
    if (props.planId) {
      setGetBudgetMovingDataLoading(true);
      const budgetMovingData = await new apiPlanService().getBudgetToMove(props.planId);

      if (budgetMovingData?.status) {
        setBudgetToMove(budgetMovingData.data.totalAmount.toFixed(2));
        setNegativeBudgetToMove(budgetMovingData.data.totalNegativeAmount.toFixed(2));
      }
      setGetBudgetMovingDataLoading(false);
    }
  };

  useEffect(() => {
    getBudgetMovingAmount();
  }, []);

  const confirmBudgetMoving = async () => {
    setConfirmBudgetMovingDataLoading(true);
    if (props.planId) {
      const confirmBudgetMovingResponse = await new apiPlanService().confirmBudgetMoving(props.planId);
      if (confirmBudgetMovingResponse?.status) {
        await new PlansHelperService().billPlan(props.downloadOption.option, props.downloadOption.planId, props.downloadOption.title);
        toast.success(
          <CustomToastContainer
            message={t("dashboard.plans.billing_page.budgetMoving.confirmationModal.notifications.success")}
            status={1}
          />,
          {
            autoClose: 5000
          }
        );
      } else if (!confirmBudgetMovingResponse?.status && confirmBudgetMovingResponse?.data?.message === "Budget for this plan is moved already") {
        toast.error(
          <CustomToastContainer
            message={t("dashboard.plans.billing_page.budgetMoving.confirmationModal.notifications.already_moved")}
            status={3}
          />,
          {
            autoClose: 5000
          }
        );
      }
    } else {
      await new PlansHelperService().billPlan(props.downloadOption.option, props.downloadOption.planId, props.downloadOption.title);
    }
    props.closeModal();
    setConfirmBudgetMovingDataLoading(false);
  };

  return(
    <div className="modal_container">

      <img
        onClick={() => props.closeModal()}
        className="close_popup"
        src={closeIcon} alt="close"
      />

      <div>
        {getBudgetMovingDataLoading
          ? <p>Loading...</p>
          : <div className="budget_moving_modal_container">

            <h3>
              {t("dashboard.plans.billing_page.budgetMoving.confirmationModal.title")}
            </h3>

            <p>
              {t("dashboard.plans.billing_page.budgetMoving.confirmationModal.text")}
            </p>

            <p>
              {t("dashboard.plans.billing_page.budgetMoving.confirmationModal.text_budget")}
            </p>

            {budgetToMove > 0 && props.company.company?.budgetMoving
              &&
              <p>
                {t("dashboard.plans.billing_page.budgetMoving.confirmationModal.positiveMoving")}
                <strong>
                  {rewriteCurrency(Number(Number(budgetToMove).toFixed(2)), "EUR")}
                </strong>
              </p>
            }

            {negativeBudgetToMove < 0 && props.company.company?.virtualCard
              &&
              <p>
                {t("dashboard.plans.billing_page.budgetMoving.confirmationModal.negativeMoving")}
                <strong>
                  {rewriteCurrency(Number(Number(negativeBudgetToMove).toFixed(2)), "EUR")}
                </strong>
              </p>
            }

            <div>
              <Button
                buttonType={"primary"}
                buttonLabel={t("dashboard.plans.billing_page.budgetMoving.confirmationModal.confirm_btn")}
                buttonHandler={() => confirmBudgetMoving()}
                loading={confirmBudgetMovingDataLoading}
                buttonStyles={{
                  minWidth: "180px"
                }}
              />

              <Button
                buttonType={"outlined"}
                buttonLabel={t("dashboard.plans.billing_page.budgetMoving.confirmationModal.cancel_btn")}
                buttonHandler={() => props.closeModal()}
                buttonStyles={{
                  minWidth: "180px"
                }}
              />
            </div>

          </div>
        }

      </div>

    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(PlanBudgetMovingModal);
