import React from "react";
import "./ContentContainer.css";
import Footer from "../Footer/Footer";

const ContentContainer = (props: any) => {

  return(
    <div className={props.withoutHeader
      ? "main_container_full" : "main-container"}>
      {props.children}

      {process.env.REACT_APP_ENV === "production"
        ? null
        : <div style={{width: "100%", position: "relative", marginTop: "100px"}}>
          <Footer />
        </div>
      }
    </div>
  );
};

export default ContentContainer;
