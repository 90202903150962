import CryptoJS from "crypto-js";
import {getUserPermission, getUserRoles} from "./cookies/userRolesCookies";

export enum userRoles {
  admin = 1,
  viewer = 2,
  custom = 3,
  user = 4,
  superAdmin = 5,
}

export enum userPermissions {
  generalInfo = 1,
  plans = 2,
  users = 3,
  invoices = 4,
  settings = 5,
}
export const roleKey = "CorplifeLunchRole";

export function decryptWithAES(ciphertext: any) {
  const passphrase = roleKey;
  const bytes = CryptoJS.AES.decrypt(ciphertext, passphrase);
  const originalText = bytes.toString(CryptoJS.enc.Utf8);
  return originalText;
}

export function encryptWithAES(text: string) {
  const passphrase = roleKey;
  return CryptoJS.AES.encrypt(text, passphrase).toString();
}

export interface allowData {
  data: {
    roles: userRoles[],
    permissions?: {
      isAdmin: boolean,
      permissionsArray: userPermissions[]
    }
  }
}

export function isAllowed({data}:allowData) {
  const role = getUserRoles();
  const permissions = getUserPermission();

  // Check if defined only for superAdmin
  if (
    data.roles.includes(userRoles.superAdmin)
    && data.roles.length === 1) {
    // Allow only for 2 users
    // 1. it@corplife.at in production env
    // 2. it@corplife.at in development env
    if (role === userRoles.superAdmin.toString()) {
      return true;
    }
  } else {
    let isAdminSetted = false;
    let allowed = false;
    data.roles.map((allowRole: userRoles) => {
      if (allowRole === userRoles.custom && role === userRoles.custom.toString()) {
        if (data.permissions && data.permissions.isAdmin) {
          permissions.map((userPermission: any) => {
            if (
              data.permissions?.permissionsArray.includes(userPermission.type)
              && userPermission.isAdmin
            ) {
              allowed = true;
              isAdminSetted = true;
            } else {
              if (!isAdminSetted) {
                allowed = false;
              }
            }
          });
        }
      } else if (data.permissions && data.permissions.permissionsArray && permissions) {
        permissions.map((userPermission: any) => {
          if (data.permissions
            && data.permissions.permissionsArray.includes(+userPermission.type)
          ) {
            if (userPermission.isActive || userPermission.isAdmin) {
              allowed = true;
            }
          }
        });
      } else {
        if (allowRole.toString() === role) {
          allowed = true;
        }
      }
    });
    return allowed;
  }
}
