import React, {useEffect, useState} from "react";
import "./styles.css";
import ContentContainer from "../../Components/Containers/ContentContainer";
import MainSpace from "../../Components/Containers/MainSpace";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
import {clearPlanData, getBillingPlan, getPlanData} from "../../redux/actions/plansActions";
import {connect, useDispatch} from "react-redux";
import DashboardLoading from "../../Components/LoadingPlaceholders/DashboardLoading";
import PageTitle from "../../Components/SimpleComponents/PageTitle";
import {RootState} from "../../redux/reducers/rootReducer";
import moment from "moment/moment";
import CustomSelect from "../../Components/SimpleComponents/Select";
import FilteringBlock from "../../Components/Blocks/FilteringBlock";
import SelectWithChoices from "../../Components/SimpleComponents/SelectWithChoices";
import BillingList from "../../Components/Lists/BillingList";
import uploadImg from "../../img/Dashboard/icon-upload_2.svg";
import {modalStyles} from "../../utils/modalStyles";
import Modal from "react-modal";
import UploadBillingCsvModal from "../../Components/Modals/UploadBillingCsvModal";
import PlanBudgetMovingModal from "../../Components/Modals/PlansPage/PlanBudgetMovingModal/PlanBudgetMovingModal";
import PlansHelperService from "./plans.service";
import ButtonWithIcon from "../../Components/Buttons/ButtonWithIcon";

interface IProps {
  plan?: any;
  loading?: boolean;
  billing?: any;
  company?: any;
}

function PlanBillingPage(props:IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const params = useParams();

  const [usersSelectionState, setUsersSelectionState] = useState([]);
  const [showCsvModal, setShowCsvModal] = useState<any>(false);
  const [showBudgetMovingModal, setShowBudgetMovingModal] = useState(false);
  const [downloadOption, setDownloadOption] = useState({
    option: {},
    planId: "",
    title: ""
  });

  const getData = (filters?: {skip: number}) => {
    if (params && params.planId) {
      dispatch(getPlanData(params.planId));
      dispatch(getBillingPlan({
        limit: 8,
        skip: filters && filters.skip ? filters.skip : 0,
        users: usersSelectionState,
      }, params.planId));
    }
  };

  useEffect(() => {
    getData();
    return () => {
      dispatch(clearPlanData());
    };
  }, []);

  const handleDownload = async (option: string) => {
    if (params.planId) {
      // Save options for handle download file in budget moving
      setDownloadOption({
        option: option,
        planId: params.planId,
        title: props.plan.plan.title
      });
    }
    if ((props.company.company?.budgetMoving || props.company.company?.virtualCard) && props.plan.plan?.isBilled !== "billed" && !props.plan.plan.isBudgetMoved) {
      setShowBudgetMovingModal(true);
    } else {
      if (params.planId && props.plan.plan.title) {
        new PlansHelperService().billPlan(option, params.planId, props.plan.plan.title);
      }
    }
  };

  return(
    <ContentContainer>
      {props.loading || !props.billing?.data
        ? <DashboardLoading />
        : <MainSpace>
          <div className="space-between">

            <Modal style={modalStyles} isOpen={showBudgetMovingModal}>
              <PlanBudgetMovingModal
                closeModal={() => setShowBudgetMovingModal(false)}
                planId={params.planId}
                downloadOption={downloadOption}
              />
            </Modal>

            <Modal style={modalStyles} isOpen={showCsvModal}>
              <UploadBillingCsvModal
                closeModal={() => {
                  getData();
                  setShowCsvModal(false);
                }}
                planId={params.planId || ""}
                billingPlan={props.billing}
              />
            </Modal>
            <div className="plan_billing_heading">
              <PageTitle mainTitle={t("dashboard.plans.billing_page.title")} />
              <h6>{t("dashboard.plans.billing_page.heading_content.title")}</h6>
              <p>
                {t("dashboard.plans.billing_page.heading_content.name", {
                  name: props.plan.plan?.title
                })}
              </p>
              <p>
                {
                  t("dashboard.plans.billing_page.heading_content.status",
                    {status: new PlansHelperService().getBillingStatus(props.plan.plan).text})
                }
              </p>
              <p>
                {t("dashboard.plans.billing_page.heading_content.time", {
                  time: `${moment(props.plan.plan?.startDate).format("DD.MM.YYYY")}
                   -
                    ${moment(props.plan.plan?.endDate).format("DD.MM.YYYY")}`
                })}
              </p>
              <p>
                {t("dashboard.plans.billing_page.heading_content.working_days", {
                  workingDays: props.billing && props.billing.workingDays
                })}
              </p>
              <p>
                {t("dashboard.plans.billing_page.heading_content.budget", {
                  budget: props.plan.employeeBudget
                    && props.plan.employeeBudget.toFixed(2).replace(".", ",")
                })}
              </p>
            </div>
            <div className="plan_billing_heading_right" style={{maxWidth: "350px"}}>

              <ButtonWithIcon
                buttonStyles={{
                  marginBottom: "10px",
                  maxWidth: "none"
                }}
                paddingTop="14px"
                iconPadding={"12px"}
                buttonType="primary"
                buttonIcon="upload"
                buttonLabel={t("dashboard.plans.billing_page.heading_content.csv")}
                buttonHandler={() => setShowCsvModal(true)}
              />

              <CustomSelect
                style={{
                  minWidth: "350px"
                }}
                options={[
                  t("dashboard.plans.billing_page.heading_content.billing_btn") as string,
                  "xlsx",
                  "csv - Mitarbeiter Übersicht",
                  "csv - Belege",
                ]}
                icon="export"
                onChange={handleDownload}
              />
            </div>
          </div>
          <FilteringBlock title={t("dashboard.plans.filters.title")}>
            <SelectWithChoices
              defaultValue={""}
              name={t("dashboard.overview.filter_opt_3_place")}
              selectPlaceHolder={t("dashboard.overview.filter_opt_3_place")}
              selectId="employees"
              options={props.billing?.employees || []}
              handleAddChoice={(options) => {
                if (options && options.length > 0) {
                  if (options[0].value === "*") {
                    dispatch(getBillingPlan(
                      {limit: 8, skip: 0},
                      params.planId || ""
                    ));
                  } else {
                    const changedFilter = options.map((user:any) => {
                      return user.value;
                    });
                    setUsersSelectionState(changedFilter);
                    dispatch(getBillingPlan(
                      {limit: 8, skip: 0, users: changedFilter},
                      params.planId || ""
                    ));
                  }
                } else {
                  dispatch(getBillingPlan(
                    {limit: 8, skip: 0},
                    params.planId || ""
                  ));
                }
              }}
            />
          </FilteringBlock>

          <BillingList
            usersSelectionState={usersSelectionState}
            planId={params.planId || ""}
            data={props.billing.data}
            loading={props.loading}
            getData={(skip: number) => getData({skip: skip})}
            total={props?.billing?.total}
          />

        </MainSpace>
      }
    </ContentContainer>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    plan: state.plans.planData,
    loading: state.plans.loading,
    billing: state.plans.billingPlans,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(PlanBillingPage);
