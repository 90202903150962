import React, {useEffect, useState} from "react";
import NumberFormat from "react-number-format";
import Button from "../../../Components/Buttons/Button";
import {useTranslation} from "react-i18next";
import {getToken} from "../../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../../environment";
import {urls} from "../../../api/urls";
import image from "img/Dashboard/Card/weavr_onborading_verification.svg";
import {toast} from "react-toastify";
import CustomToastContainer from "../../../Components/SimpleComponents/ToastContainer";
import {useNavigate} from "react-router-dom";


interface IProps {
  weavr: any;
  handleSlider: (cond: "prev" | "next" | "login") => void
}


export default function SmsVerification({weavr, handleSlider}:IProps) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const getWeavrToken = async () => {
    const token = await getToken(true);
    if (!token) {
      handleSlider("prev");
    }
  };

  useEffect(() => {
    getWeavrToken();
  }, []);

  const [showSubmitButtonLoading, setShowSubmitButtonLoading] = useState(false);
  const [code, setCode] = useState("");

  const handleSvnInput = async (e:any) => {
    const inputNumber = e.target.value;
    setCode(inputNumber.toString().split(" ").join(""));
  };

  const handleSubmit = async (e:any) => {
    e.preventDefault();
    if (code.length === 6) {
      setShowSubmitButtonLoading(true);
      const token = await getToken();
      const weavrToken = await getToken(true);
      try {
        const response = await axios.post(`${environment.baseUrl}${urls.payments.verifySms}`,
          {
            token: weavrToken,
            code: code,
            corporateId: weavr.corporateId.id
          },
          {
            headers: { Authorization: `Bearer ${token}` }
          });
        if (response && response.status === 200) {
          navigate("/payments");
          handleSlider("next");
        }
      } catch (e:any) {
        if (e.response.status === 401) {
          toast.error(
            <CustomToastContainer
              message={t("dashboard.payments.onboarding.stepper.verification.toasts.error")}
              status={3} />, {
              autoClose: 5000,
            });
          navigate("/payments");
          handleSlider("prev");
        }
      }
    }
  };

  const resendSms = async () => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    const response = await axios.post(`${environment.baseUrl}${urls.payments.sendSmsVerification}`,
      {
        token: weavrToken
      },
      {
        headers: {Authorization: `Bearer ${token}`}
      });
    if (response && response.data) {
      toast.success(
        <CustomToastContainer
          message={t("dashboard.payments.onboarding.stepper.verification.success_notification")}
          status={1} />, {
          autoClose: 5000,
        });
    } else {
      toast.error(
        <CustomToastContainer
          message={"Something went wrong"}
          status={3} />, {
          autoClose: 5000,
        });
    }
  };

  return(
    <div className="create_password_page">
      <form className="verification_form" onSubmit={(e) => handleSubmit(e)}>
        <div className="center">
          <div className="verification_form_content">
            <h1>{t("dashboard.payments.onboarding.stepper.sms_verification.title")}</h1>
            <p>{t("dashboard.payments.onboarding.stepper.sms_verification.text")}</p>
            <div className="verification_form_content_input">
              <p>{t("dashboard.payments.onboarding.stepper.sms_verification.label")}</p>
              <NumberFormat
                disabled={false}
                className="code_input"
                format="# # # # # #"
                onChange={(e:any) => handleSvnInput(e)}
              />
            </div>

            <div style={{height: "15px"}} />

            <p
              style={{color: "blue", textDecoration: "underline", cursor: "pointer", marginBottom: "0px"}}
              onClick={() => {
                resendSms();
              }}
            >
              {t("dashboard.payments.onboarding.stepper.verification.resend_sms")}
            </p>

            <Button
              buttonType="primary"
              buttonLabel={t("dashboard.payments.onboarding.stepper.sms_verification.btn")}
              buttonHtmlType="submit"
              submitButton={true}
              fullWidth={true}
              buttonStyles={{maxWidth: "250px"}}
              disabled={code.length < 6}
              loading={showSubmitButtonLoading}
            />
          </div>
        </div>
        <div className="center">
          <img src={image} alt="verification"/>
        </div>
      </form>
    </div>
  );
}
