import {
  CLEAR_COMPANY_MESSAGES,
  COMPANIES_INFORMATION_SUCCESS,
  companiesDataDispatchTypes,
  COMPANY_DATA_ERROR,
  COMPANY_INFORMATION_SUCCESS, UPDATE_COMPANY_INFORMATION, CLEAR_COMPANY
} from "../types/companiesTypes";

interface DefaultStateI {
	success?: string,
	error?: string,
	company?: any,
	companies?: any
}

const initialState:DefaultStateI = {
  success: "",
  error: "",
  company: {},
  companies: []
};

export const companiesReducer = (
  state: DefaultStateI = initialState,
  action: companiesDataDispatchTypes):DefaultStateI => {
  switch (action.type) {
  case COMPANY_INFORMATION_SUCCESS:
    return {
      ...state,
      company: action.payload
    };
  case COMPANIES_INFORMATION_SUCCESS:
    return {
      ...state,
      companies: action.payload
    };
  case UPDATE_COMPANY_INFORMATION:
    return {
      ...state,
      success: action.payload
    };
  case CLEAR_COMPANY_MESSAGES:
    return {
      ...state,
      success: "",
      error: ""
    };
  case CLEAR_COMPANY:
    return {
      success: "",
      error: "",
      company: {},
      companies: []
    };
  case COMPANY_DATA_ERROR:
    return {
      ...state, 
      error: action.payload
    };
  default:
    return state;
  }
};
