import {decryptWithAES, encryptWithAES} from "../permissionsAllow";
import moment from "moment";

export function setToken(token: string, isWeavr?:boolean) {
  const encryptedRole = encryptWithAES(token.toString());
  if (isWeavr) {
    const weavrToken = {
      timestamp: moment().valueOf(),
      token: encryptedRole
    };
    localStorage.setItem("weavr_data", JSON.stringify(weavrToken));
  } else {
    localStorage.setItem("lunch_key", encryptedRole);
  }
}

export function getToken(isWeavr?:boolean) {
  const token = localStorage.getItem(isWeavr ? "weavr_data" : "lunch_key");
  if (token) {
    // Set token to refresh a time of expiration
    if (isWeavr) {
      const weavrTokenObj = JSON.parse(token);
      // If timestamp is not older then 15 minutes, use it and refresh existing token
      if (moment().diff(weavrTokenObj.timestamp, "minutes") < 15) {
        const weavrToken = {
          timestamp: moment().valueOf(),
          token: weavrTokenObj.token
        };
        localStorage.setItem("weavr_data", JSON.stringify(weavrToken));
        return decryptWithAES(weavrTokenObj.token);
      } else {
        return undefined;
      }
    } else {
      return decryptWithAES(token);
    }
  }
}

export function removeToken(isWeavr?:boolean) {
  localStorage.removeItem(isWeavr ? "weavr_data" : "lunch_key");
}

