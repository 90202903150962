export const GET_ONE_INVOICE_SUCCESS = "GET_ONE_INVOICE_SUCCESS";
export const GET_INVOICES_SUCCESS = "GET_INVOICES_SUCCESS";
export const GET_FILTER_DATA_SUCCESS = "GET_FILTER_DATA_SUCCESS";
export const UPDATE_INVOICES = "UPDATE_INVOICES";
export const INVOICES_ERROR = "INVOICES_ERROR";
export const SHOW_INVOICES_LOADING = "SHOW_INVOICES_LOADING";
export const CLEAR_INVOICES_MESSAGES = "CLEAR_INVOICES_MESSAGES";

export interface getOneInvoiceSuccess {
	type: typeof GET_ONE_INVOICE_SUCCESS
	payload: any
}

export interface getInvoicesSuccess {
	type: typeof GET_INVOICES_SUCCESS
	payload: any
}

export interface getFilterDataSuccess {
	type: typeof GET_FILTER_DATA_SUCCESS
	payload: any
}

export interface updateInvoices {
	type: typeof UPDATE_INVOICES
	payload: any
}

export interface showInvoicesLoading {
	type: typeof SHOW_INVOICES_LOADING
}

export interface invoicesError {
	type: typeof INVOICES_ERROR,
	payload: string
}

export interface clearInvoicesMessages {
	type: typeof CLEAR_INVOICES_MESSAGES
}


export type invoicesDispatchTypes =
	getOneInvoiceSuccess |
	getInvoicesSuccess |
	updateInvoices |
	showInvoicesLoading |
	invoicesError |
	clearInvoicesMessages |
	getFilterDataSuccess




