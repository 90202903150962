export const GET_HRZONEAUTH_SUCCESS = "GET_HRZONEAUTH_SUCCESS";
export const GET_HRZONEAUTH_ERROR = "GET_HRZONEAUTH_ERROR";
export const CLEAR_HRZONEAUTH = "CLEAR_HRZONEAUTH";


export interface getHrZoneAuthSuccess {
	type: typeof GET_HRZONEAUTH_SUCCESS
	payload: any
}
export interface getHrZoneAuthError {
	type: typeof GET_HRZONEAUTH_ERROR
	payload: any
}
export interface clearHrZoneAuth {
	type: typeof CLEAR_HRZONEAUTH
	payload: any
}




export type hrZoneAuthTypes = getHrZoneAuthSuccess | getHrZoneAuthError | clearHrZoneAuth
