import React, {useEffect, useState} from "react";
import "./ListStyles.css";
import Checkbox from "../SimpleComponents/Checkbox";
import Pagination from "../SimpleComponents/Pagination";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import {useNavigate} from "react-router-dom";
import {getCards} from "../../redux/actions/paymentsActions";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import ActionDots, {IActionDotsAction} from "../Elements/ActionDots/ActionDots";
import {getToken} from "../../utils/cookies/tokensCookie";
import axios from "axios";
import environment from "../../environment";
import {urls} from "../../api/urls";
import {toast} from "react-toastify";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import {ICard, virtualCardState} from "../../api/services/Payments/card.interface";
import Badge, {BadgeColorsEnum} from "../Elements/Badge/Badge";

interface IProps {
  companyId: string;
  cards?: any;
  setSelectedCards: (cards: any[]) => void;
  selectedCards: any[];
  updateListData: () => void;
  filters: any
}

function CardsList({filters, cards, companyId, setSelectedCards, selectedCards, updateListData}:IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const actions: IActionDotsAction[] = [
    {
      key: "detail",
      imageKey: "cardDetail",
      imageAlt: "card detail",
      label: t("dashboard.payments.overview.header_component.tabs_content.card_info.actions.card_details"),
      onClick: (item: ICard) => navigate(`/payments/overview/${item.id}`)
    },
    {
      key: "freeze",
      imageKey: "freezeCard",
      imageAlt: "freeze card",
      label: (state: any) => state.state.state === "DESTROYED"
        ? t("dashboard.payments.overview.bottom_tabs.cards.actions.recreate_card")
        : state.state.state === "ACTIVE"
          ? t("dashboard.payments.overview.bottom_tabs.cards.actions.freeze_card")
          : t("dashboard.payments.overview.bottom_tabs.cards.actions.activate_card"),
      onClick: (item: ICard) => toggleCardStatus(item)
    },
    {
      key: "remove",
      imageKey: "removeCard",
      imageAlt: "remove card",
      label: t("dashboard.payments.overview.bottom_tabs.cards.actions.remove_card"),
      hide: (rootState: ICard) => rootState.state.state === virtualCardState.DESTROYED,
      onClick: (item: ICard) => removeCard(item)
    }
  ];

  /*const [checkedItems, setCheckedItems] = useState<any>([]);*/
  const [showLoading, setShowLoading] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [checkLoading, setCheckLoading] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  useEffect(() => {
    if (cards && cards.cards && cards.cards.cards && cards.cards.cards.length) {
      setSortedData(cards.cards.cards);
    }
  }, [cards]);

  const handleCheckboxChange = (item: any) => {
    if (selectedCards?.length > 0 && getCheckedIds().includes(item.id)) {
      const index = getCheckedIds().indexOf(item.id);
      if (index !== -1) {
        selectedCards.splice(index, 1);
      }
      setSelectedCards([...selectedCards]);
    } else {
      setSelectedCards([...selectedCards, item]);
    }
  };

  const handleCheckAll = () => {
    setCheckLoading(true);
    if (checkAll) {
      setCheckAll(false);
      //Remove current displayed plans
      currentPosts.forEach((post: any) => {
        if (
          selectedCards?.length > 0 &&
          getCheckedIds().includes(post.id)
        ) {
          const index = getCheckedIds().indexOf(post.id);
          if (index !== -1) {
            selectedCards.splice(index, 1);
          }
          setSelectedCards([...selectedCards]);
        }
      });
    } else {
      setCheckAll(true);
      //Add current displayed plans
      currentPosts.forEach((post: any) => {
        if (!getCheckedIds().includes(post.id)) {
          selectedCards.push(post);
          setSelectedCards(selectedCards);
        }
      });
    }
    setCheckLoading(false);
  };

  useEffect(() => {

    if(filters.skip === 0){
      setCurrentPage(1);
      paginate(1);
    }
  },[filters]);

  // handle Paginate
  const currentPosts:any = sortedData.slice(0, 8);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      setCurrentPage(pageNumber);
      const skip = (pageNumber - 1) * 8;

      dispatch(getCards({
        companyId: companyId,
        skip: skip,
        limit: 8,
        startDate: filters.startDate,
        endDate: filters.endDate
      }));
    }
  };

  // define string for table because of max-length
  const active = t("dashboard.employee.active_employee");
  const inactive = t("dashboard.employee.inactive_employee");
  const destroyed = t("dashboard.employee.destroyed");

  const transformExpire = (value: string) => {
    return value.slice(0,2) + "/" + value.slice(2,4);
  };

  const transformCardNumber = (firstValues: string, lastValues: string) => {
    return firstValues.slice(0,4) + " " + firstValues.slice(4,6) + "** " + " **** " + lastValues;
  };

  const getCheckedIds = () => {
    return selectedCards.map((item: any) => item.id);
  };

  const toggleCardStatus = async (card: ICard) => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    let response;
    try {
      if (card.state.state === "DESTROYED") {
        response = await axios.post(
          // eslint-disable-next-line max-len
          `${environment.baseUrl}${urls.payments.recreate}/${card.id}?token=${weavrToken}`,
          {},
          {
            headers: {Authorization: `Bearer ${token}`}
          });
      } else if (card.state.state === "ACTIVE") {
        response = await axios.post(
          // eslint-disable-next-line max-len
          `${environment.baseUrl}${urls.payments.blockCard}/${card.id}?token=${weavrToken}`,
          {},
          {
            headers: {Authorization: `Bearer ${token}`}
          });
      } else {
        response = await axios.post(
          // eslint-disable-next-line max-len
          `${environment.baseUrl}${urls.payments.unblockCard}/${card.id}?token=${weavrToken}`,
          {},
          {
            headers: {Authorization: `Bearer ${token}`}
          });
      }
    } catch (e) {
      toast.error(
        <CustomToastContainer
          message={t("dashboard.payments.overview.bottom_tabs.cards.actions.toasts.toggle_status_error")}
          status={3}
        />,
        {
          autoClose: 3000,
        }
      );
    }
    if (response && response.data && response.data.message) {
      if (response.data.message === "card_unblocked") {
        toast.success(
          <CustomToastContainer
            message={t("dashboard.payments.overview.bottom_tabs.cards.actions.toasts.card_unblocked")}
            status={1}
          />,
          {
            autoClose: 3000,
          }
        );
      } else if (response.data.message === "created") {
        toast.success(
          <CustomToastContainer
            message={t("dashboard.payments.overview.bottom_tabs.cards.actions.toasts.card_recreated")}
            status={1}
          />,
          {
            autoClose: 3000,
          }
        );
      } else {
        toast.success(
          <CustomToastContainer
            message={t("dashboard.payments.overview.bottom_tabs.cards.actions.toasts.card_blocked")}
            status={1}
          />,
          {
            autoClose: 3000,
          }
        );
      }
      updateListData();
    }
  };

  const removeCard = async (card: ICard) => {
    const token = await getToken();
    const weavrToken = await getToken(true);
    try {
      const response = await axios.post(
        // eslint-disable-next-line max-len
        `${environment.baseUrl}${urls.payments.removeCard}/${card.id}?token=${weavrToken}`,
        {},
        {
          headers: {Authorization: `Bearer ${token}`}
        });
      if (response && response.status === 200) {
        toast.success(
          <CustomToastContainer
            message={t("dashboard.payments.overview.bottom_tabs.cards.actions.toasts.card_removed")}
            status={1}
          />,
          {
            autoClose: 3000,
          }
        );
      }
    } catch (e: any) {
      toast.error(
        <CustomToastContainer
          message={t("dashboard.payments.overview.bottom_tabs.cards.actions.toasts.card_removed_error")}
          status={3}
        />,
        {
          autoClose: 3000,
        }
      );
    }
    updateListData();
  };

  return(
    cards.cards && cards.cards?.cards?.length < 1 ? (
      <EmptyTableState />
    ) : (
      <>
        <div className="table_box">
          {showLoading
            ? <TableLoading />
            :
            <div className="table_wrapper-overview" >
              <table style={{width:"100%"}}>
                <thead>
                  <tr>
                    <th>
                      <div className="responsive-mr-40">
                        <div className="row">
                          <Checkbox
                            disabled={checkLoading}
                            id="selectAll"
                            handleCheckboxChange={() => handleCheckAll()}
                            checked={checkAll}
                          />
                          {t("dashboard.payments.overview.bottom_tabs.cards.list.headers.status")}
                        </div>
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.payments.overview.bottom_tabs.cards.list.headers.name")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.payments.overview.bottom_tabs.cards.list.headers.email")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.payments.overview.bottom_tabs.cards.list.headers.expiry_date")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.payments.overview.bottom_tabs.cards.list.headers.card_number")}
                      </div>
                    </th>
                    <th>
                      <div className="responsive-mr-40">
                        {t("dashboard.payments.overview.bottom_tabs.cards.list.headers.action")}
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {currentPosts.map((item: ICard, index:number) => (
                    <React.Fragment key={item.id}>
                      <tr className="spacer" />
                      <tr
                        className={
                          getCheckedIds().includes(item.id)
                            ? "selected_row"
                            : "table-item"
                        }
                      >
                        <td>
                          <div className="row">
                            <Checkbox
                              id={item.id.toString()}
                              handleCheckboxChange={() =>
                                handleCheckboxChange(item)
                              }
                              checked={getCheckedIds().includes(item.id)}
                            />
                            <Badge
                              color={item.state.state === "ACTIVE" ? BadgeColorsEnum.green : BadgeColorsEnum.red }
                              text={item.state.state === "ACTIVE"
                                ? active
                                : item.state.state === "BLOCKED"
                                  ? inactive
                                  : destroyed}
                            />
                          </div>
                        </td>
                        <td>
                          <p>{item.nameOnCard}</p>
                        </td>
                        <td>
                          <p>{item.email}</p>
                        </td>
                        <td>
                          <p>{transformExpire(item.expiryMmyy)}</p>
                        </td>
                        <td>
                          <p>{
                            transformCardNumber(item.cardNumberFirstSix, item.cardNumberLastFour)
                          }</p>
                        </td>
                        <td>
                          <ActionDots
                            actions={actions}
                            rootState={item}
                          />
                          {/*<ActionDotsCards
                            id={item.id}
                            card={item}
                            updateListData={() => updateListData()}
                          />*/}
                        </td>
                      </tr>
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
            </div>
          }
        </div>
        <Pagination
		  currentPage={currentPage}
          postsPerPage={8}
          totalPosts={cards.cards.cards && cards.cards.total}
          paginate={paginate}
        />
      </>

    )
  );
}
const mapStateToProps = (state:RootState) => {
  return {
    cards: state.payments
  };
};

export default connect(mapStateToProps, null)(CardsList);
