// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.success_container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    max-width: 600px;
}

.center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.success_container > img {
    width: 100%;
    max-width: 400px;
    margin-left: auto;
    margin-right: auto;
}

.success_container > p {
    font-size: 12px;
}

`, "",{"version":3,"sources":["webpack://./src/Components/Widgets/Checklist/Components/SuccessContainer/successContainer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,sBAAsB;IACtB,gBAAgB;AACpB;;AAEA;IACI,aAAa;IACb,uBAAuB;IACvB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,gBAAgB;IAChB,iBAAiB;IACjB,kBAAkB;AACtB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".success_container {\n    width: 100%;\n    display: flex;\n    justify-content: center;\n    flex-direction: column;\n    max-width: 600px;\n}\n\n.center {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n}\n\n.success_container > img {\n    width: 100%;\n    max-width: 400px;\n    margin-left: auto;\n    margin-right: auto;\n}\n\n.success_container > p {\n    font-size: 12px;\n}\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
