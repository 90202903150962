import React, {useEffect, useRef, useState} from "react";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect} from "react-redux";
import { useTranslation } from "react-i18next";
import penIcon from "../../img/Dashboard/PlansAndModules/Billing/pen.png";


interface CustomProps {
  item: valuesType;
  deleteUser: (index:number) => void;
  updateItem: (item: any, index: number) => void;
  data: any;
  index: number;
  currentPage: any;
  workingDays: any;
  employees: any;
}

type valuesType = {
	sick: number;
	holiday: number;
	vacation: number;
	firstName: string;
	lastName: string;
    email: string;
}

function CsvBillingTableItem({item, data, workingDays, employees, updateItem, index}:CustomProps) {
  const { t } = useTranslation();
  const [values, setValues] = useState<valuesType>(item);

  const [showError, setShowError] = useState(false);

  const sumDays = () => {
	  return values.sick + values.vacation + values.holiday;
  };

  const [invalidDays, setInvalidDays] = useState<boolean>(sumDays() > workingDays);

  //array of useRefs for dynamic input focus on button click
  const mapRef: any = [];
  Object.keys(item).forEach(key => {
    mapRef.push(useRef());
  });

  /**
   * executes callback function on item value change to keep current item uptodate with parent component
   */
  useEffect(() => {
    setInvalidDays(sumDays() > workingDays);
    updateItem(values, index);
  },[values]);

  const isDirty = (item:any):any => {
    if (item && item.branch) {
      if (
        item?.firstName?.trim()?.length < 1
      ) {
        return {
          state: true,
          field: "firstName"
        };
      } else if (item?.lastName?.trim()?.length < 1) {
        return {
          state: true,
          field: "lastName"
        };
      }
      return {
        state: false
      };
    }
    return true;
  };

  /**
   * Function to validate day input changes and set state afterwards. The new input will be checked and afterwards set in state by calling the callback function
   * @param event - Object which contains all the needed info for validation and state setting
   */
  const handleChange = (event:any) => {
    if(event.event.target && event.event.target.value.length > 0){
	  const inputValue = +event.event.target.value.replace(/\D+/g, "");
      if (inputValue < 0) {
        event.callBack("");
      }else{
        if (inputValue <= event.limit) {
          event.callBack(+inputValue);
        }else{
          event.callBack(+event.limit);
        }
      }
    }else{
	  event.callBack("");
    }
  };

  /**
   * Function to check if a employee name is valid. The name is valid if its in the plan and also only once in the csv upload modal (no duplicates allowed)
   * @param item - any
   * @returns boolean
   */
  const validName = (item: any) => {
    if(employees.includes(item.firstName.toLowerCase() +  " " + item.lastName.toLowerCase())){
      let count = 0;
      data.forEach((employee: any) => {
        if(employee.firstName.toLowerCase() == item.firstName.toLowerCase() &&
				employee.lastName.toLowerCase() == item.lastName.toLowerCase()){
          count++;
        }
      });
      return count > 1 ? false : true;
    }
    return false;
  };

  /**
   * Function to dynamically get Error messages for each position (names and days...)
   * @param item - any
   * @param position - string (indicator for which position)
   * @returns string
   */
  const getErrorMessage = (item: any, position: string) => {
    if(position=="name"){
      if(employees.includes(item.firstName.toLowerCase() +  " " + item.lastName.toLowerCase())){
        let count = 0;
        data.forEach((employee: any) => {
          if(employee.firstName.toLowerCase() == item.firstName.toLowerCase() &&
			employee.lastName.toLowerCase() == item.lastName.toLowerCase()){
            count++;
          }
        });
        return count > 1 ? t("dashboard.employee.csv_modal.errors.employee_duplicate") : "";
      }
     	return  t("dashboard.employee.csv_modal.errors.employee_notInPlan") ;
	  }
	  if(position=="days"){
      if(sumDays() > workingDays){
        return  t("dashboard.employee.csv_modal.errors.employee_tooManyDays", {workingDays:workingDays});
      }
    }
    return "";
  };

  /**
   * Event listener so the modal doesnt close on enter press
   */
  useEffect(() => {
    const listener = (event:any) => {
      if (event.code === "Enter" || event.code === "NumpadEnter") {
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, []);

  return(
    <React.Fragment>
      {Object.keys(item).length
        ?
        <>
          <tr className="spacer" />
          <tr className={isDirty(item).state ? "table-item dirty" : "table-item"}>
            <td className={!validName(item) ? "table-item dirty" : "table-item"} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
			  <form className="billing_column">
                {showError && getErrorMessage(item, "name").length > 0
                  ? <div className="billing_column_warning">
                    <div>
                      <p>{getErrorMessage(item, "name")}</p>
                    </div>
                  </div>
                  : null
                }
			  <input
                  style={{background: "transparent",maxWidth: "none"}}
                  ref={mapRef[0]}
                  type="text"
                  value={values.firstName}
                  onChange={(event:any) => setValues({...values, firstName: event.target.value})}
                />
                <img
                  src={penIcon}
                  alt="pen"
                  onClick={() => mapRef[0].current.focus()}
                />
              </form>

              {isDirty(item).field && isDirty(item).field === "firstName"
                ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                  {t("formik_warnings.general_info.firstName")}
                </p>
                : null
              }
            </td>
            <td className={!validName(item) ? "table-item dirty" : "table-item"} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
			  <form className="billing_column" >
			  {showError && getErrorMessage(item, "name").length > 0
                  ? <div className="billing_column_warning">
                    <div>
                      <p>{getErrorMessage(item, "name")}</p>
                    </div>
                  </div>
                  : null
                }
			  <input
			  style={{background: "transparent",maxWidth: "none"}}
			  ref={mapRef[1]}
                  type="text"
                  value={values.lastName}
                  onChange={(event:any) => setValues({...values, lastName: event.target.value})}
                />
                <img
                  src={penIcon}
                  alt="pen"
                  onClick={() => mapRef[1].current.focus()}
                />
              </form>
              {isDirty(item).field && isDirty(item).field === "lastName"
                ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                  {t("formik_warnings.general_info.lastName")}
                </p>
                : null
              }
            </td>
            <td className={!validName(item) ? "table-item dirty" : "table-item"} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
              <form className="billing_column" >
                <input
                  style={{background: "transparent",maxWidth: "none"}}
                  ref={mapRef[1]}
                  type="text"
                  value={values.email}
                  onChange={(event:any) => setValues({...values, email: event.target.value})}
                />
                <img
                  src={penIcon}
                  alt="pen"
                  onClick={() => mapRef[1].current.focus()}
                />
              </form>
            </td>
            <td className={invalidDays ? "table-item dirty" : "table-item"} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
			  <form className="billing_column">
			  {showError && getErrorMessage(item, "days").length > 0 && +values.vacation > 0
                  ? <div className="billing_column_warning">
                    <div>
                      <p>{getErrorMessage(item, "days")}</p>
                    </div>
                  </div>
                  : null
                }
			  <input
			  style={{background: "transparent"}}
			  ref={mapRef[2]}
                  min={0}
                  type="number"
                  value={values.vacation}
                  onChange={(event:any) => handleChange({
                    event,
                    limit: (workingDays-(values.sick+values.holiday)) > 0 ? (workingDays-(values.sick+values.holiday)) : 0,
                    callBack: (correction: any) =>  setValues({...values, vacation:correction})}
                  )}
                />
                <img
                  src={penIcon}
                  alt="pen"
                  onClick={() => mapRef[2].current.focus()}
                />
              </form>

              {isDirty(item).field && isDirty(item).field === "email"
                ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                  {t("formik_warnings.general_info.emailValid")}
                </p>
                : null
              }
            </td>
            <td className={invalidDays ? "table-item dirty" : "table-item"} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
			  <form className="billing_column">
			  {showError && getErrorMessage(item, "days").length > 0 && +values.sick > 0
                  ? <div className="billing_column_warning">
                    <div>
                      <p>{getErrorMessage(item, "days")}</p>
                    </div>
                  </div>
                  : null
                }
			  <input
			  style={{background: "transparent"}}
			  ref={mapRef[3]}
                  min={0}
                  type="number"
                  value={values.sick}
                  onChange={(event:any) => handleChange({
					  event,
					  limit: (workingDays-(values.vacation+values.holiday)) > 0 ? (workingDays-(values.vacation+values.holiday)) : 0,
					  callBack: (correction: any) =>  setValues({...values, sick: correction})}
                  )}
                />
                <img
                  src={penIcon}
                  alt="pen"
                  onClick={() => mapRef[3].current.focus()}
                />
              </form>
              {isDirty(item).field && isDirty(item).field === "email"
                ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                  {t("formik_warnings.general_info.emailValid")}
                </p>
                : null
              }
            </td>
            <td className={invalidDays ? "table-item dirty" : "table-item"} onMouseEnter={() => setShowError(true)} onMouseLeave={() => setShowError(false)}>
			  <form className="billing_column">
			  {showError && getErrorMessage(item, "days").length > 0 && +values.holiday > 0
                  ? <div className="billing_column_warning">
                    <div>
                      <p>{getErrorMessage(item, "days")}</p>
                    </div>
                  </div>
                  : null
                }
			  <input
			    style={{background: "transparent"}}
                  ref={mapRef[4]}
                  min={0}
                  type="number"
                  value={values.holiday}
                  onChange={(event:any) => handleChange({
					  event,
					  limit: (workingDays-(values.sick+values.vacation)) > 0 ? (workingDays-(values.sick+values.vacation)): 0,
					  callBack: (correction: any) =>  setValues({...values, holiday: correction})}
                  )}
                />
                <img
                  src={penIcon}
                  alt="pen"
                  onClick={() => mapRef[4].current.focus()}
                />
              </form>
              {isDirty(item).field && isDirty(item).field === "email"
                ? <p style={{position: "absolute", marginTop: "3px", fontSize: "10px"}}>
                  {t("formik_warnings.general_info.emailValid")}
                </p>
                : null
              }
            </td>
          </tr>
        </>
        : null
      }
    </React.Fragment>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
	  branches: state.depsAndBranches.branches,
	  departments: state.depsAndBranches.departments,
	  branchesAndDep: state.depsAndBranches.departmentAndBranches
  };
};

export default connect(mapStateToProps, null)(CsvBillingTableItem);
