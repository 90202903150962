// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.purple-container {
    padding-top: 22px;
    padding-bottom: 24px;
    background: rgb(14,0,247);
    background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));
}

@media (max-width: 400px){
    .purple-container {
        width: -moz-fit-content;
        width: fit-content;
        padding-top: 22px;
        padding-bottom: 24px;
        background: rgb(14,0,247);
        background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);
        background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Containers/PurpleContainer.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,oBAAoB;IACpB,yBAAyB;IACzB,0HAA0H;IAC1H,4GAA4G;AAChH;;AAEA;IACI;QACI,uBAAkB;QAAlB,kBAAkB;QAClB,iBAAiB;QACjB,oBAAoB;QACpB,yBAAyB;QACzB,0HAA0H;QAC1H,4GAA4G;IAChH;AACJ","sourcesContent":[".purple-container {\n    padding-top: 22px;\n    padding-bottom: 24px;\n    background: rgb(14,0,247);\n    background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);\n    background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));\n}\n\n@media (max-width: 400px){\n    .purple-container {\n        width: fit-content;\n        padding-top: 22px;\n        padding-bottom: 24px;\n        background: rgb(14,0,247);\n        background: linear-gradient(0deg, rgba(14,0,247,0.0844712885154062) 0%, rgba(255,255,255,1) 35%, rgba(255,255,255,1) 100%);\n        background: -webkit-linear-gradient(bottom,rgba(14,0,247,0.1),rgba(255,255,255,0.3) 55%,rgba(255,255,255,0));\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
