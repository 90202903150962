import {
  corporatesDataDispatchTypes,
  CORPORATES_DATA_ERROR,
  CORPORATES_INFORMATION_SUCCESS
} from "redux/types/corporateTypes";

interface DefaultStateI {
  success?: string;
  error?: string;
  corporates?: any;
}

const initialState: DefaultStateI = {
  success: "",
  error: "",
  corporates: []
};

export const corporatesReducer = (
  state: DefaultStateI = initialState,
  action: corporatesDataDispatchTypes
): DefaultStateI => {
  switch (action.type) {
  case CORPORATES_INFORMATION_SUCCESS:
    return {
      ...state,
      corporates: action.payload
    };
  case CORPORATES_DATA_ERROR:
    return {
      ...state,
      error: action.payload
    };
  default:
    return state;
  }
};
