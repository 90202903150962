import React from "react";
import "./CardCreationLoadingModal.css";
import {modalStyles} from "../../../../utils/modalStyles";
import Modal from "react-modal";
import image from "img/Dashboard/Card/weavr_onborading_verification.svg";
import {useTranslation} from "react-i18next";

interface IProps {
  showModal: boolean;
}

export default function CardCreationLoadingModal(props: IProps) {
  const { t } = useTranslation();

  return(
    <Modal
      style={modalStyles}
      isOpen={props.showModal}
    >

      <div className="card_creation_loading_container">

        <img src={image} alt="loading_image" />

        <h6>
          {t("dashboard.employee.card_creation_loading_popup.title")}
        </h6>

        <p>
          {t("dashboard.employee.card_creation_loading_popup.text")}
        </p>

      </div>

    </Modal>
  );
}
