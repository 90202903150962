export interface IInvoice {
  status: {
    type: number;
    manuallyApproved: boolean;
    description: string;
  };
  ocr: {
    type: string;
    category: string;
    status: boolean;
    refNumber: string;
    documentType: string;
    vendorCategory: string;
    vendorType: string;
    vendor: {
      name: string;
      logo: string;
      category: string;
      type: string;
      address: string;
    };
    error: {
      isDuplicate: boolean;
      code: string;
      reason: string;
    };
  };
  invoice: {
    imageKey: string;
    imageUrl: string;
    merchant: string;
    invoiceType: string;
    date: Date;
    amount: number;
    isManuallyClaimed: boolean;
    manualClaim: number;
  };
  changelog: [
    {
      updatedBy: string;
      update: {
        prevStatus: number;
        currStatus: number;
      };
      updatedAt: Date;
    }
  ];
  planId: string;
  userName: string;
  branchId: string;
  companyId: string;
  departmentId: string;
  merchantId: string;
  userId: string;
  isProcessed: "PENDING" | "FAILED" | "COMPLETED" | "ARCHIVED";
  paymentType: "VIRTUAL" | "PHYSICAL" | "CASH";
  package: {
    appName: string;
    packageName: string;
    version: string;
    buildNumber: string;
  };
  transactionId: string;
  invoiceUploadRequired: boolean;
  createdAt?: Date;
  updatedAt: Date;
}

export enum InvoiceMerchantTypeEnum {
  RESTAURANT = "1",
  SUPERMARKET = "2"
}

export interface IInvoiceNavigationData {
  preInvoice?: string | null;
  nextInvoice?: string | null;
}

export interface IInvoicesTopCardsData {
  automaticallyApproved: number;
  manuallyApproved: number;
  pending: number;
  rejected: number;
  firstPendingItem: string | undefined;
}

export interface IGetInvoicesTopCardsDataResponse {
  status: boolean;
  data?: IInvoicesTopCardsData
}

export interface IGetNearestInvoicesResponse {
  status: boolean;
  data?: IInvoiceNavigationData;
}
