// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.branches_chart_header {
    max-width: 500px;
    width: 100%;
    margin-bottom: 20px;
}
.branches_chart_row > p {
    font-size: 14px;
    color: #767493;
    font-weight: 300;
    padding-left: 10px;
}

.branches_text {
    font-size: 12px;
    font-weight: 500;
    color: #7C828A;
}

.branches_chart_container {
    position: relative;
    width: 100%;
    max-width: 100%;
    box-sizing: border-box;
    height: 265px;
}
.chart_box {
    width: 96%;
    max-width: 100%;
    box-sizing: border-box;
    height: 240px;
    min-width: 600px;
}

.charts_labels {
    font-size: 11px;
    font-weight: 500;
    color: #767493;
    letter-spacing: 0.5px;
}

@media (max-width: 700px) {
    .branches_chart_container {
        overflow-x: scroll;
    }
}

@media (max-width: 500px) {
    .branch_chart_row {
        display: block;
    }
}
`, "",{"version":3,"sources":["webpack://./src/Components/DashboardCharts/BranchesChart.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,mBAAmB;AACvB;AACA;IACI,eAAe;IACf,cAAc;IACd,gBAAgB;IAChB,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;AAClB;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,eAAe;IACf,sBAAsB;IACtB,aAAa;AACjB;AACA;IACI,UAAU;IACV,eAAe;IACf,sBAAsB;IACtB,aAAa;IACb,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,cAAc;IACd,qBAAqB;AACzB;;AAEA;IACI;QACI,kBAAkB;IACtB;AACJ;;AAEA;IACI;QACI,cAAc;IAClB;AACJ","sourcesContent":[".branches_chart_header {\n    max-width: 500px;\n    width: 100%;\n    margin-bottom: 20px;\n}\n.branches_chart_row > p {\n    font-size: 14px;\n    color: #767493;\n    font-weight: 300;\n    padding-left: 10px;\n}\n\n.branches_text {\n    font-size: 12px;\n    font-weight: 500;\n    color: #7C828A;\n}\n\n.branches_chart_container {\n    position: relative;\n    width: 100%;\n    max-width: 100%;\n    box-sizing: border-box;\n    height: 265px;\n}\n.chart_box {\n    width: 96%;\n    max-width: 100%;\n    box-sizing: border-box;\n    height: 240px;\n    min-width: 600px;\n}\n\n.charts_labels {\n    font-size: 11px;\n    font-weight: 500;\n    color: #767493;\n    letter-spacing: 0.5px;\n}\n\n@media (max-width: 700px) {\n    .branches_chart_container {\n        overflow-x: scroll;\n    }\n}\n\n@media (max-width: 500px) {\n    .branch_chart_row {\n        display: block;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
