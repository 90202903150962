import React, { useEffect, useState } from "react";
import "./ListStyles.css";
import Pagination from "../SimpleComponents/Pagination";
import { RootState } from "../../redux/reducers/rootReducer";
import { connect, useDispatch } from "react-redux";
import EmptyTableState from "../SimpleComponents/TableEmptyState";
import shopIcon from "img/icon-maw.svg";
import TrasactionsActionsDots from "../SimpleComponents/TrasactionsActionsDots";
import TableLoading from "../LoadingPlaceholders/TableLoading";
import TableSortedHeadItem from "./TableSortedHeadItem";
import { getTransactions } from "../../redux/actions/paymentsActions";
import moment from "moment/moment";
import { useTranslation } from "react-i18next";
import Badge from "../Elements/Badge/Badge";
import {
  getTransactionsCategoryColor,
  getTransactionsCategoryName, TransactionCategoryEnum
} from "../../utils/virtualCards/getTransactionsCategoryName";

interface IProps {
  payments?: any;
  cardId: string;
}

function EmployeeList({ payments, cardId }: IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState(false);
  const [sortedData, setSortedData] = useState([]);
  const [sortButton, setSortButton] = useState({
    date: "down",
  });

  useEffect(() => {
    if (payments.transactions && payments.transactions.data) {
      setSortedData(payments.transactions.data);
    }
  }, [payments.transactions]);

  useEffect(() => {
    if (payments.transactions.data) {
      setShowLoading(false);
    }
    //Need to disable it, because eslint says, that I need to add
    //selectedRows as dependency but it will woke
    // that function in every render
    // eslint-disable-next-line
  }, [payments.transactions, dispatch]);

  const [sortValue, setSortValue] = useState("DESC");
  const currentPosts = sortedData.slice(0, 8);
  const [currentPage, setCurrentPage] = useState(1);
  const paginate = (pageNumber: number) => {
    if (currentPage !== pageNumber) {
      setCurrentPage(pageNumber);
      const skip = (pageNumber - 1) * 8;
      setShowLoading(true);
      dispatch(
        getTransactions({
          id: cardId,
          limit: 8,
          skip: skip,
          sortBy: sortValue,
        })
      );
    }
  };

  /*const getStatement = (state: string) => {
    switch (state) {
    case "SETTLEMENT":
      return <div className="badge accept_badge">Approved</div>;
    case "AUTHORISATION":
      return <div className="badge pending_badge">Authorisation</div>;
    case "AUTHORISATION_DECLINE":
      return <div className="badge reject_badge">Declined</div>;
    }
  };*/

  const sortingData = () => {
    setShowLoading(true);
    dispatch(
      getTransactions({
        id: cardId,
        limit: 8,
        skip: 0,
        sortBy: sortButton.date === "up" ? "DESC" : "ASC",
      })
    );
    sortButton.date === "up"
      ? setSortButton({ date: "down" })
      : setSortButton({ date: "up" });
  };

  const getTypeName = (type: "supermarket" | "restaurant") => {
    switch (type) {
    case "supermarket":
      return t(
        "dashboard.payments.card_detail.transactions_list.merchant_types.supermarket"
      );
    case "restaurant":
      return t(
        "dashboard.payments.card_detail.transactions_list.merchant_types.restaurant"
      );
    default:
      return t(
        "dashboard.payments.card_detail.transactions_list.merchant_types.unknown"
      );
    }
  };

  return (
    <div>
      {payments &&
      payments.transactions &&
      (!payments.transactions.data ||
        payments.transactions.data.length === 0) ? (
          <>
            <div className="payments_card_detail_header">
              <h1 style={{ textAlign: "center" }}>
                {t(
                  "dashboard.payments.overview.header_component.tabs.transaction_history"
                )}
              </h1>
              <EmptyTableState
                style={{ height: "600px", backgroundColor: "transparent" }}
              />
            </div>
          </>
        ) : (
          <div className="table_box">
            {showLoading ? (
              <TableLoading />
            ) : (
              <div className="table_wrapper-overview">
                <table style={{ width: "100%" }}>
                  <thead>
                    <tr>
                      <th>
                        <TableSortedHeadItem
                          text={t(
                            "dashboard.payments.card_detail.transactions_list.headers.date"
                          )}
                          sortingData={sortingData}
                          name="date"
                          sortButton={sortButton}
                        />
                      </th>
                      <th>
                        <div className="responsive-mr-40">
                          {t(
                            "dashboard.payments.card_detail.transactions_list.headers.time"
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="responsive-mr-40">
                          {t(
                            "dashboard.payments.card_detail.transactions_list.headers.merchant_type"
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="responsive-mr-40">
                          {t(
                            "dashboard.payments.card_detail.transactions_list.headers.merchant_name"
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="responsive-mr-40">
                          {t(
                            "dashboard.payments.card_detail.transactions_list.headers.amount"
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="responsive-mr-40">
                          {t(
                            "dashboard.payments.card_detail.transactions_list.headers.type"
                          )}
                        </div>
                      </th>
                      <th>
                        <div className="responsive-mr-40">
                          {t(
                            "dashboard.payments.card_detail.transactions_list.headers.actions"
                          )}
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {currentPosts.map((item: any, index: number) => (
                      <React.Fragment key={item.processedTimestamp}>
                        <tr className="spacer" />
                        <tr className="table-item">
                          <td>
                            <p>
                              {moment(item.processedTimestamp).format(
                                "DD.MM.YYYY"
                              )}
                            </p>
                          </td>
                          <td>
                            <p>
                              {moment(item.processedTimestamp).format("HH:mm")}
                            </p>
                          </td>
                          <td>{getTypeName(item.merchantCategory.name)}</td>
                          <td>
                            <p>{item.merchantName}</p>
                          </td>
                          <td>
                            <p>
                              {item.amount ? `${item.amount.toFixed(2)} €` : 0}
                            </p>
                          </td>
                          <td>
                            <Badge
                              color={getTransactionsCategoryColor(item.type)}
                              text={getTransactionsCategoryName(item.type)}
                            />
                          </td>
                          <td>
                            {/*Because only AUTHORISATION transaction could be used to find invoice*/}
                            {item.type === TransactionCategoryEnum.Authorisations ? (
                              <TrasactionsActionsDots
                                transactionId={item.transactionId}
                              />
                            ) : null}
                          </td>
                        </tr>
                      </React.Fragment>
                    ))}
                  </tbody>
                </table>
              </div>
            )}
          </div>
        )}
      {payments &&
      payments.transactions &&
      (!payments.transactions.data ||
        payments.transactions.data.length === 0) ? null : (
          <Pagination
            currentPage={currentPage}
            postsPerPage={8}
            totalPosts={
              payments.transactions &&
            payments.transactions &&
            payments.transactions.count
            }
            paginate={paginate}
          />
        )}
    </div>
  );
}
const mapStateToProps = (state: RootState) => {
  return {
    payments: state.payments,
  };
};

export default connect(mapStateToProps, null)(EmployeeList);
