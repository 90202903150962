import React, {CSSProperties, useState} from "react";
import "./Select.css";
import arrowSelect from "img/arrow_select.svg";
import { useTranslation } from "react-i18next";
import BeatLoader from "react-spinners/BeatLoader";

interface CustomProps {
  options: string[] | Array<{value: string; label: string}>;
  icon: any;
  onChange?: (option:string) => void;
  style?: CSSProperties;
  buttonStyle?: CSSProperties;
  loading?: boolean;
}

export default function CustomSelect({options, icon, onChange, style, buttonStyle, loading}:CustomProps) {
  const { t } = useTranslation();

  const [menuOpen, setMenuOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(options[0]);
  const handleSelect = (option:string) => {
    setMenuOpen(false);
    setSelectedOption(option);
    if (onChange) {
      onChange(option);
    }
  };

  return(
    <div className="custom-select-wrapper" style={style}>
      <div className={menuOpen ? "custom-select open" : "custom-select"}>
        <div
          className="custom-select__trigger space-between"
          style={buttonStyle}
          onClick={() => {
            if (!loading) {
              setMenuOpen(!menuOpen);
            }
          }}
        >
          {loading
            ? <div className="center" style={{width: "100%"}}>
              <BeatLoader size="10px" color="white" />
            </div>
            : <>
              <img src={"/images/icon-" + icon + ".svg"} alt="calendar"/>
              <span>{t(typeof options[0] === "object"
                ? options[0].label
                : options[0])}</span>
              <img
                src={arrowSelect}
                alt="arrow"
                className={menuOpen
                  ? "select-arrow-up" : "select-arrow"}
              />
            </>
          }
        </div>
        <div className="custom-options">
          {options.slice(1).map(option => (
            <span
              key={typeof option === "object"
                ? option.value
                : option}
              className="custom-option"
              data-value="tesla"
              onClick={() => handleSelect(typeof option === "object"
                ? option.value
                : option)}
            >
              {typeof option === "object"
                ? option.label
                : option
              }
            </span>
          ))}
        </div>
      </div>
    </div>
  );
}
