import React, { useEffect, useRef, useState } from "react";
import "./CreditCard.css";
import bg from "./card_background.png";
// import restaurant_card from "./card_restaurant1.svg";
// import supermarket_card from "./card_supermarket1.svg";
import restaurant_card from "./card_black_no_back.svg";
import supermarket_card from "./card_white_no_back.svg";

import restaurant_icon from "./i_restaurant.svg";
import supermarket_icon from "./i_cart.svg";

import { rewriteCurrency } from "../../../utils/rewriteCurrency";
import { useTranslation } from "react-i18next";
import { getToken } from "../../../utils/cookies/tokensCookie";

interface IProps {
  cardNumberLastFour: number;
  amount: number;
  type: "restaurant" | "supermarket";
  cardNumberValue: string;
  showDetails: boolean;
  cvvValue: string;
  expiryDate: string;
  setShowPaymentsAuthModal?: (state: boolean) => void;
}

export default function CreditCard(props: IProps) {
  const { t } = useTranslation();

  const cardNumberRef: any = useRef(null);
  const cardCvvRef: any = useRef(null);
  const cardExpiryRef: any = useRef(null);

  const cardTextColor = props.type === "restaurant" ? "#fff" : "#000";

  const createCardNumber = async () => {
    if (props.cardNumberValue) {
      const weavrToken = await getToken(true);
      // Set the user’s authentication token
      (window as any).OpcUxSecureClient.associate(
        `Bearer ${weavrToken}`,
        function () {
          // eslint-disable-next-line max-len
          const span = (window as any).OpcUxSecureClient.span(
            "cardNumber",
            props.cardNumberValue,
            {
              style: {
                color: cardTextColor,
                fontFamily: "Overpass Mono",
                fontSize: "12px",
                height: "16px",
              },
            }
          );
          span.mount(document.getElementById("cardNumber"));
        },
        function (e: any) {
          console.error(e);
          if (e.toString().includes("401")) {
            props.setShowPaymentsAuthModal &&
            props.setShowPaymentsAuthModal(true);
          }
        }
      );
    }
  };

  const createCvvNumber = async () => {
    const weavrToken = await getToken(true);
    // Set the user’s authentication token
    (window as any).OpcUxSecureClient.associate(
      `Bearer ${weavrToken}`,
      function () {
        const span = (window as any).OpcUxSecureClient.span(
          "cvv",
          props.cvvValue,
          {
            style: {
              color: cardTextColor,
              fontFamily: "Overpass Mono",
              fontSize: "12px",
              textAlign: "end",
              height: "16px",
            },
          }
        );
        span.mount(document.getElementById("cvv"));
      },
      function (e: any) {
        console.error("associate failed " + e);
        if (e.toString().includes("401")) {
          props.setShowPaymentsAuthModal &&
          props.setShowPaymentsAuthModal(true);
        }
      }
    );
  };

  useEffect(() => {
    if (props.showDetails) {
      createCardNumber();
      createCvvNumber();
      cardExpiryRef.current.innerText = `${props.expiryDate.slice(
        0,
        2
      )}/${props.expiryDate.slice(2, 4)}`;
    } else {
      cardNumberRef.current.innerText = `**** **** **** ${props.cardNumberLastFour}`;
      cardCvvRef.current.innerText = "***";
      cardExpiryRef.current.innerText = "**/**";
    }
  }, [props.showDetails]);

  return (
    <div
      className="card"
      style={{
        backgroundImage: `url(${
          props.type === "restaurant" ? restaurant_card : supermarket_card
        })`,
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        boxShadow: "rgba(0, 0, 0, 0.24) 0px 3px 8px",
      }}
    >
      <p className="card_type">
        <img
          className={"card_icon"}
          src={props.type === "restaurant" ? restaurant_icon : supermarket_icon}
          alt="cardTypeIcon"
        />
        {t(`dashboard.payments.card_detail.cards.card.types.${props.type}`)}
      </p>
      <p className="card_amount" style={{ color: cardTextColor }}>
        {rewriteCurrency(props.amount, "EUR")}
      </p>

      <span
        className="card_number"
        id="cardNumber"
        style={{ color: cardTextColor, height: "16px" }}
        ref={cardNumberRef}
      >
        {`**** **** **** ${props.cardNumberLastFour}`}
      </span>

      <div className="card_expiry" style={{ color: cardTextColor }}>
        <p>EXP</p>
        <p ref={cardExpiryRef}>{"**/**"}</p>
      </div>

      <div className="card_cvv" style={{ color: cardTextColor }}>
        <p>CVV</p>
        <span id={"cvv"} ref={cardCvvRef}>
          {"***"}
        </span>
      </div>
    </div>
  );
}
