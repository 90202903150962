import React from "react";
import "./PlainContainer.css";

const PlainContainer = (props?: any) => {

  return(
    <div className={props.type 
      ? props.type + " plain-container"
      : "plain-container" }>
      {props.children}
    </div>
  );
};

export default PlainContainer;
