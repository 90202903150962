import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Route, Routes } from "react-router-dom";

import GeneralInfo from "./page";
import ProfileSettings from "./profile_page";
import { changeSidebarTab } from "../../redux/actions/sidebarActions";
import {NotFoundPage} from "../../routes";

export default function GeneralInfoRoute(params: {user?: boolean}) {
  const dispatch = useDispatch();

  return (
    <Routes test-id="router_general_info">
      <Route path="/" element={params.user ? <NotFoundPage /> : <GeneralInfo />} />
      <Route path={"/:id"} element={<ProfileSettings />} />
    </Routes>
  );
}
