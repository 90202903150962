import i18n from "../../../../i18next";
import {BadgeColorsEnum} from "../Badge";

export default function getBadgeDataForInvoiceStatus(type: 1 | 2 | 3 | 4): {text: string; color: BadgeColorsEnum} {
  let color: BadgeColorsEnum;
  let text: string;
  switch (type) {
  case 1:
    color = BadgeColorsEnum.yellow;
    text = i18n.t("dashboard.overview.status_1");
    break;
  case 2:
    color = BadgeColorsEnum.green;
    text = i18n.t("dashboard.overview.status_2");
    break;
  case 3:
    color = BadgeColorsEnum.red;
    text = i18n.t("dashboard.overview.status_3");
    break;
  case 4:
    color = BadgeColorsEnum.red;
    text = i18n.t("dashboard.overview.status_4");
    break;
  }
  return {
    color,
    text
  };
};
