import "./SideProducts.css";
import productsEssen from "img/icon-appetio.svg";
import productsTitle from "img/icon-your-products.svg";
import productsImage from "img/il-products.svg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import {
  clearMarketingMessages,
} from "../../redux/actions/postMarketingActions";
import { RootState } from "../../redux/reducers/rootReducer";
import Button from "../Buttons/Button";
import Spacer from "../SimpleComponents/Spacer";
import CustomToastContainer from "../SimpleComponents/ToastContainer";
import axios from "axios";
import environment from "../../environment";
import {getToken} from "../../utils/cookies/tokensCookie";
import {urls} from "../../api/urls";
interface CustomProps {
  marketingMessage?: any;
  contactForm?: any;
}

function SideProducts({ marketingMessage, contactForm }: CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [marketingButtonDisabled, setMarketingButtonDisabled] = useState(false);

  const handleSendMarketing = (banner: string) => {
    contactForm();
  };

  // Get marketing button state
  const getMarketingState = async () => {
    await axios.get(
      `${environment.baseUrl}${urls.marketing.benefitsContact}`,
      {
        headers: {Authorization: `Bearer ${getToken()}`},
      }
    ).then((res: any) => {
      setMarketingButtonDisabled(res.data.status);
    }).catch((e: any) => {
      console.error(e);
    });
  };

  useEffect(() => {
    getMarketingState();
  }, []);

  useEffect(() => {
    if (marketingMessage.success) {
      dispatch(clearMarketingMessages());
      toast.success(
        <CustomToastContainer
          message={t("warnings.marketing_success")}
          status={1}
        />,
        {
          autoClose: 5000,
        }
      );
    } else if (marketingMessage.error) {
      marketingMessage.error === "1"
        ? toast.error(
          <CustomToastContainer
            message={t("warnings.marketing_error_1")}
            status={3}
          />,
          {
            autoClose: 5000,
          }
        )
        : toast.error(
          <CustomToastContainer
            message={t("warnings.marketing_error_2")}
            status={3}
          />,
          {
            autoClose: 5000,
            toastId: "marketing",
          }
        );
      dispatch(clearMarketingMessages());
    }
  }, [marketingMessage, t, dispatch]);

  return (
    <div>
      <div className="side_products">
        <div className="row">
          <img src={productsTitle} alt="titleImg" />
          <p className="side_product_title">
            {t("dashboard.home.side_products_name")}
          </p>
        </div>
        <div className="line">
          <div className="line_element" />
        </div>

        <div className="row products_row">
          <img src={productsEssen} alt="essenszuschuss" />
          <div className="side_product_container">
            <div className="space_between_products space-between">
              <p>{t("dashboard.home.side_products_opt_grant")}</p>
            </div>
            <div className="products_progress">
              <div className="progress_two" style={{ width: "100%" }} />
            </div>
          </div>
        </div>

        <Spacer spacerHeight="40px" />

        <h2 style={{ textAlign: "center" }}>
          {t("dashboard.home.side_products_title")}
        </h2>
        <p className="product_hint_text">
          {t("dashboard.home.side_products_text")}
        </p>
        <div style={{ width: "100%" }}>
          <Spacer spacerHeight="16px" />
          <div className="center">
            <Button
              disabled={marketingButtonDisabled}
              buttonType="primary"
              buttonLabel={t("dashboard.home.side_products_button")}
              buttonHandler={() => handleSendMarketing("Corplife Lunch")}
            />
          </div>
          <Spacer spacerHeight="16px" />
        </div>
        <div className="side_prod_img center">
          <img src={productsImage} alt="product_girl" />
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    marketingMessage: state.marketingState,
  };
};

export default connect(mapStateToProps, null)(SideProducts);
