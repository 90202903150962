import axios from "axios";
import environment from "../../environment";
import {urls} from "../urls";
import {getToken} from "../../utils/cookies/tokensCookie";
import {toast} from "react-toastify";
import CustomToastContainer from "../../Components/SimpleComponents/ToastContainer";
import React from "react";

export class apiPlanService {
  constructor() {
    //
  }

  async getBudgetToMove(planId: string):Promise<{status: boolean, data: any} | undefined> {
    return await axios.get(
      `${environment.baseUrl}${urls.plans.getBudgetToMove}/${planId}`,
      {
        headers: {Authorization: `Bearer ${getToken()}`},
      }
    )
      .then((res: any) => {
        if (res.status === 200) {
          return {
            status: true,
            data: res.data
          };
        }
      })
      .catch((err: any) => {
        return {
          status: false,
          data: err.response.data
        };
      });
  }

  async confirmBudgetMoving(planId: string):Promise<{status: boolean, data: any} | undefined> {
    return await axios.post(
      `${environment.baseUrl}${urls.plans.confirmBudgetMoving}/${planId}`,
      {},
      {
        headers: {Authorization: `Bearer ${getToken()}`}
      }
    )
      .then((res: any) => {
        if (res.status === 200) {
          return {
            status: true,
            data: res.data
          };
        }
      })
      .catch((err: any) => {
        return {
          status: false,
          data: err.response.data
        };
      });
  }

}
