export const CORPORATES_INFORMATION_SUCCESS = "CORPORATES_INFORMATION_SUCCESS";

export const CORPORATES_DATA_ERROR = "CORPORATES_DATA_ERROR";

export interface corporatesInformationSuccess {
  type: typeof CORPORATES_INFORMATION_SUCCESS;
  payload: any;
}

export interface corporatesDataError {
  type: typeof CORPORATES_DATA_ERROR;
  payload: string;
}

export type corporatesDataDispatchTypes =
  // corporatesDataDispatchTypes |
  corporatesInformationSuccess | corporatesDataError;
