export const INVOICES_STATS_SUCCESS = "INVOICES_STATS_SUCCESS";
export const DAILY_CHART_SUCCESS = "DAILY_CHART_SUCCESS";
export const SUMMARY_CHART_SUCCESS = "SUMMARY_CHART_SUCCESS";
export const BRANCHES_CHART_SUCCESS = "BRANCHES_CHART_SUCCESS";
export const CHARTS_DATA_ERROR = "CHARTS_DATA_ERROR";

export interface getInvoicesStatsSuccess {
	type: typeof INVOICES_STATS_SUCCESS
	payload: any
}

export interface getDailyChartSuccess {
	type: typeof DAILY_CHART_SUCCESS
	payload: any
}

export interface getSummaryChartSuccess {
	type: typeof SUMMARY_CHART_SUCCESS
	payload: any
}

export interface getBranchesChartSuccess {
	type: typeof BRANCHES_CHART_SUCCESS
	payload: any
}

export interface chartsDataError {
	type: typeof CHARTS_DATA_ERROR,
	payload: string
}


export type chartsDataDispatchTypes =
	getInvoicesStatsSuccess |
	chartsDataError |
	getDailyChartSuccess |
	getSummaryChartSuccess |
	getBranchesChartSuccess




