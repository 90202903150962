import
{HANDLE_CHECKBOX, 
  SidebarCheckboxesTypes} from "../types/employeePageCheckboxTypes";


const initialState = {
  selectedCheckboxes: []
};

export const checkBoxReducer = (
  state = initialState,
  action:SidebarCheckboxesTypes) => {
  switch (action.type) {
  case HANDLE_CHECKBOX:
    return{...state, selectedCheckboxes: action.payload};
  default: return state;
  }
};
