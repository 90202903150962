import React, {useState} from "react";
import "./Inputs.css";
import showPasswordImg from "img/Dashboard/ic-actions-view.svg";
import hidePasswordImg from "img/Dashboard/eye_closed.svg";
import {motion} from "framer-motion";


interface CustomInputProps {
	htmlFor: string
	name: string
	placeholder?: string
	value: string
	disabled: boolean
	handleChange: any
	onBlur?: any
	label: string
	style?: any,
	autoFocus?: boolean
    type?: "number" | "textarea";
	rows?: number
  errors?: any;
  touched?: any;
  inputStyle?: React.CSSProperties;
  maxLength?: number;
}

export default function FormikInput(
  {name, placeholder, value, disabled, handleChange,
    htmlFor, onBlur, label, style, autoFocus, type, rows, errors, touched, inputStyle, maxLength}
		: CustomInputProps) {
  const [inputType, setInputType] = useState(false);



  if (htmlFor === "password") {
    return (
      <div
        style={style}
        className="simple_input">
        <label
          className="simple_label"
          htmlFor="password"
        >
          {label}
        </label>
        <input
          autoFocus={autoFocus}
          name={name}
          type={!inputType ? "password" : "text"}
          className="formik_input"
          placeholder={placeholder}
          value = {value}
          onChange={handleChange}
          onBlur={onBlur}
        />
        <div className="hide-container">
          <img
            className="hide-toggle"
            src={inputType ? hidePasswordImg : showPasswordImg}
            alt="hide"
            onClick={() => setInputType(!inputType)}
          />
        </div>
      </div>
    );
  } else {
    if (type === "number") {
      return (
        <div
          style={style}
          className="simple_input">
          <label
            className="simple_label"
            htmlFor={htmlFor}
          >
            {label}
          </label>
          <motion.input
            whileFocus={{ backgroundColor: "rgba(151, 151, 226, 0.281)"}}
            name={name}
            type="number"
            className={"simple_input" }
            placeholder={placeholder}
            disabled = {disabled}
            value = {value}
            onChange={handleChange}
            onBlur={onBlur}
          />
        </div>
      );
    } else if (type === "textarea") {
      return(
        <div
          style={{
            ...style,
            position: "relative"
          }}
          className="simple_input">
          <label
            className="simple_label"
            htmlFor={htmlFor}
          >
            {label}
          </label>
          <motion.textarea
            style={inputStyle}
            whileFocus={{ backgroundColor: "rgba(151, 151, 226, 0.281)"}}
            name={name}
            rows={rows ? rows : 4}
            className="text_area"
            placeholder={placeholder}
            disabled = {disabled}
            value = {value}
            onChange={handleChange}
            onBlur={onBlur}
            maxLength={maxLength}
          />
          {errors && touched && <p className="input_error">{errors}</p>}
        </div>
      );
    } else {
      return (
        <div
          style={style}
          className="simple_input">
          <label
            className="simple_label"
            htmlFor={htmlFor}
          >
            {label}
          </label>
          <motion.input
            whileFocus={{ backgroundColor: "rgba(151, 151, 226, 0.281)"}}
            name={name}
            type="text"
            className={"simple_input" }
            placeholder={placeholder}
            disabled = {disabled}
            value = {value}
            onChange={handleChange}
            onBlur={onBlur}
          />
          {errors && touched && <p className="input_error">{errors}</p>}
        </div>
      );
    }
  }
}
