import React, {useEffect, useRef, useState} from "react";
import { useTranslation } from "react-i18next";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import {getInvoices, showInvoicesLoading} from "../../redux/actions/invoicesActions";
import {changeInvoicesFiltering} from "../../redux/actions/filteringActions";

import "./FilterRange.css";
import "react-input-range/lib/css/index.css";

import InputRange from "react-input-range";
import Spacer from "./Spacer";
import Button from "../Buttons/Button";


interface CustomProps {
	filteringState?: any
}

function FilterRange({filteringState}:CustomProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Show DatePicker dropdown
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [selectedValues, setSelectedValues] = useState("");

  // Detect click outside for close menu

  const filtersAmountRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!filtersAmountRef.current?.contains(event.target as Node)) {
        setShowDatePicker(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // Values of ranger
  const [values, setValues] = useState<any>({
    min: filteringState.minAmount ?? 0,
    max: filteringState.maxAmount ?? 100
  });

  // handle Open/Close dropdown
  const handleDropdown = () => {
    setShowDatePicker(!showDatePicker);
  };

  // handle submit limits

  const handleAmountSubmit = () => {
    setShowDatePicker(!showDatePicker);
    const drawValues = `${values.min} - ${values.max}`;
    setSelectedValues(drawValues);
    const changedState = {
      ...filteringState,
      minAmount: values.min,
      maxAmount: values.max
    };
    dispatch(showInvoicesLoading());
    dispatch(changeInvoicesFiltering(changedState));
    dispatch(getInvoices(changedState));
  };

  return (
    <div className="filter_date_box" ref={filtersAmountRef}>
      <div onClick={handleDropdown} className="filter_date_space">
        <p>{selectedValues.length ? selectedValues : "Min-Max"}</p>
        <svg height="20" width="20" viewBox="0 0 20 20">
          {/* eslint-disable-next-line max-len */}
          <path id="arrow_svg" d="M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z"></path>
        </svg>
      </div>
      {showDatePicker
			&&
			<div
			  style={{padding:"20px"}}
			  className="filter_date_drop shadow">
			  <p className="filter_range_title">
			    {t("dashboard.overview.filter_range_title")}
			  </p>
			  <InputRange
			    maxValue={100}
			    minValue={0}
			    onChange={(value:any) => setValues(value)}
			    value={values}
			  />
			  <Spacer spacerHeight="2rem" />
			  <div className="space-between">
			    <div className="filter_range_input-box">
			      <label htmlFor="min">
			        {t("dashboard.overview.filter_range_min")}
			      </label>
			      <input
			        type="number"
			        className="filter-range_input"
			        value={values.min}
			        onChange={(e:any) => setValues({...values, min: +e.target.value})}
			      />
			    </div>
			    <div className="filter_range_input-box">
			      <label htmlFor="min">
			        {t("dashboard.overview.filter_range_max")}
			      </label>
			      <input
			        type="number"
			        className="filter-range_input"
			        value={values.max}
			        onChange={(e:any) => setValues({...values, max: +e.target.value})}
			      />
			    </div>
			  </div>
			  <Spacer spacerHeight="10px" />
			  <Button
			    buttonType="primary"
			    buttonLabel=
			      {t("dashboard.overview.date_btn_2")}
			    buttonHandler={handleAmountSubmit}
			    fullWidth={true}
			  />
			</div>
      }
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    filteringState: state.filters.invoiceFilters
  };
};

export default connect(mapStateToProps, null)(FilterRange);
