import React from "react";
import "./MainSpace.css";

const MainSpace = (props: any) => {
  return(
    <main>
      {props.children}
    </main>
  );
};

export default MainSpace;
