import React, { Component } from "react";
import makeAnimated from "react-select/animated";
import MySelect from "./MySelect.js";
import { components } from "react-select";
import Checkbox from "./Checkbox";
import SelectItemBox from "./SelectItemBox";
import i18n from "i18next";

const customStyles = {
  control: (provided, state) => ({
    ...provided,
    background: "#fff",
    borderColor: "#E3E5F1",
    minHeight: "50px",
    height: "50px",
    minWidth: "175px",
    boxShadow: null
  }),
  valueContainer: (provided, state) => ({
    ...provided,
    height: "50px",
    fontSize: "12px"
  }),

  input: (provided, state) => ({
    ...provided,
    margin: "0px",
  }),
  indicatorSeparator: (state) => ({
    display: "none",
  }),
  indicatorsContainer: (provided, stat) => ({
    ...provided,
    height: "50px"
  }),
  placeholder: (defaultStyles) => {
    return {
      ...defaultStyles,
      color: "#0E0A40",
    };
  }
};

const Option = props => {
  return (
    <div>
      <components.Option {...props}>
        <div className="row">
          <Checkbox
            id={props.value}
            checked={props.isSelected}
            handleCheckboxChange={() => null}
          />{" "}
          <label style={{fontSize: "12px"}}>{props.label} </label>
        </div>
      </components.Option>
    </div>
  );
};


const allOption = {
  label: i18n.t("dashboard.home.select_all"),
  value: "*"
};

const ValueContainer = ({ children, ...props }) => {
  const currentValues = props.getValue();
  let toBeRendered = children;
  if (currentValues.some(val => val.value === allOption.value)) {
    toBeRendered = [[children[0][0]], children[1]];
  }
  if (props.selectProps.value && props.selectProps.value.length) {
    let allOptions = false;
    props.selectProps.value.forEach(item => {
      if (item.value === "*") {
        allOptions = true;
      }
    });
    if (props.selectProps.value.length > 2) {
      toBeRendered = <SelectItemBox
        items={props.selectProps.value.length}
        name={props.selectProps.name}
        allOptions={allOptions}
      />;
    }
  }
  return (
    <components.ValueContainer {...props}>
      {toBeRendered}
    </components.ValueContainer>
  );
};

const MultiValue = props => {
  let labelToBeDisplayed = `${props.data.label}, `;
  if (props.data.value === allOption.value) {
    labelToBeDisplayed = "All is selected";
  }
  return (
    <components.MultiValue {...props}>
      <span>{labelToBeDisplayed}</span>
    </components.MultiValue>
  );
};


const animatedComponents = makeAnimated();
export default class AdvancedSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      optionSelected: this.props.defaultValue || null
    };
  }

	handleChange = selected => {
	  this.setState({
	    optionSelected: selected

	  });
	  this.props.handleAddChoice(selected);
	  /*this.props.selectedOptions(selected)*/
	}

	render() {
	  return (
	    <span
	      className="d-inline-block"
	      data-toggle="popover"
	      data-trigger="focus"
	      data-content="Please select account(s)"
	    >
	      <MySelect
	        placeholder={this.props.placeholder || "Select..."}
	        styles={customStyles}
	        options={this.props.options}
	        isMulti={this.props.isMulti !== "no"}
	        name={this.props.name || ""}
	        closeMenuOnSelect={false}
	        components={{
	          Option,
	          MultiValue,
	          ValueContainer,
	          animatedComponents
	        }}
	        onBlur={this.props.onBlur}
	        onChange={this.handleChange}
	        allowSelectAll={true}
	        value={this.state.optionSelected}
	      />
	    </span>
	  );
	}
}
