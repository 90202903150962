import "./BranchesChart.css";

import Spacer from "Components/SimpleComponents/Spacer";
import React, { useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { useTranslation } from "react-i18next";
import { connect, useDispatch } from "react-redux";

import { getBranchesChartData } from "../../redux/actions/chartsActions";
import { RootState } from "../../redux/reducers/rootReducer";

interface IProps {
  branchesData?: any;
  company?: any;
}

function BranchesChart(props:IProps) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  // Get branches data after component is mounted
  useEffect(() => {
    dispatch(getBranchesChartData());
  }, [dispatch]);


  const [labels, setLabels] = useState(["Vienna"]);
  const [restaurantData, setRestaurantData] = useState<any>([0]);
  const [supermarketData, setSupermarketData] = useState<any>([0]);
  const [chartIsInitialized, setChartIsInitialized] = useState(false);

  // Manipulating Data
  useEffect(() => {
    if (props.branchesData && props.branchesData.datasets && !chartIsInitialized) {
      setLabels(props.branchesData.labels);
      const restaurantsArr: any = [];
      const supermarketArr: any = [];
      Object.keys(props.branchesData.datasets).forEach((branch: any) => {
        let restaurantsAmount = 0;
        let supermarketAmount = 0;
        props.branchesData.datasets[branch].forEach((item: any) => {
          if (item.type === "1") {
            restaurantsAmount += +item.amount;
          } else if (item.type === "2") {
            supermarketAmount += +item.amount;
          }
        });
        restaurantsArr.push(restaurantsAmount.toFixed(2));
        supermarketArr.push(supermarketAmount.toFixed(2));
      });
      setChartIsInitialized(true);
      setRestaurantData(restaurantsArr);
      setSupermarketData(supermarketArr);
    }
  }, [props.branchesData]);

  // Get maximal amount and step for chart
  const maxRestaurant = Math.max(...restaurantData);
  const maxSupermarket = Math.max(...supermarketData);
  const maxLimit =
    maxRestaurant > maxSupermarket ? maxRestaurant : maxSupermarket;
  const step = Math.round(maxLimit / 5);

  const data = {
    labels: labels,
    datasets: [
      {
        barPercentage: 0.9,
        categoryPercentage: 0.5,
        label: t("charts.branches_chart.restaurant"),
        data: restaurantData,
        backgroundColor: "#FF5242",
      },
      props.company?.company?.isSupermarketAllowed
        ? {
          barPercentage: 0.9,
          categoryPercentage: 0.5,
          label: t("charts.branches_chart.supermarket"),
          data: supermarketData,
          backgroundColor: "#6457E8",
        }
        : {}
    ],
  };

  const options = {
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    cornerRadius: 30,
    tooltips: {
      callbacks: {
        label: function (tooltipItem: any) {
          const item = data["datasets"][tooltipItem.datasetIndex];
          return `${item.label} ${+item.data[0]} €`;
        },
      },
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          gridLines: {
            drawOnArea: false,
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            suggestedMax: maxLimit,
            suggestedMin: 0,
            stepSize: step,
            fontColor: "#7C828A",
            callback: function (value: any, index: any, values: any) {
              return `€ ${value}`;
            },
          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            drawOnArea: false,
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3],
          },
          ticks: {
            suggestedMax: 1000,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: 200,
          },
        },
      ],
    },
  };

  return (
    <>
      <div className="dashboard_box_padding">
        <div className="space-between">
          <h3>{t("dashboard.home.orders_branches_title")}</h3>
          <div>
            <div className="row charts_labels">
              <div className="summary_dot red_summary" />
              <p>{t("dashboard.home.orders_branches_opt_1")}</p>
            </div>
            {props.company?.company?.isSupermarketAllowed
              ? <div className="row charts_labels">
                <div className="summary_dot blue_summary" />
                <p>{t("dashboard.home.orders_branches_opt_2")}</p>
              </div>
              : null
            }
          </div>
        </div>
        <p style={{ marginTop: "-10px" }} className="branches_text">
          {labels.length} {t("dashboard.home.orders_branches_text")}
        </p>
        <Spacer spacerHeight="51px" spacerWidth="100%" />

        <div className="branches_chart_container">
          <div className="chart_box">
            <Bar data={data} options={options} />
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    branchesData: state.charts.branchesChart,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(BranchesChart);
