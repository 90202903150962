import React, {useEffect, useState} from "react";
import "./SummaryChart.css";
import { Line } from "react-chartjs-2";
import greenCaret from "../../img/caret-up.svg";
import redCaret from "../../img/caret-down.svg";
import {RootState} from "../../redux/reducers/rootReducer";
import {connect, useDispatch} from "react-redux";
import { useTranslation } from "react-i18next";
import {getSummaryChartData} from "../../redux/actions/chartsActions";

interface IProps {
  summaryData?: any;
  company?: any;
}

function SummaryChart(props:IProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  // Dispatch needed data
  useEffect(() => {
    dispatch(getSummaryChartData());
  }, [dispatch]);

  const [datesForLabels, setDatesForLabels] =
		useState(["APR", "MAI", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]);
  const [restaurantData, setRestaurantData] = useState([0,0,0,0,0,0,0,0,0]);
  const [supermarketData, setSupermarketData] = useState([0,0,0,0,0,0,0,0,0]);
  const [monthlyRate, setMonthlyRate] = useState(0);
  const [generalRate, setGeneralRate] = useState(0);

  // Taking last 9 months
  function getLastMonths(n:number) {
    const chartLabels = [];
    const d = new Date();
    d.setDate(1);
    for (let mMonth = 0; mMonth < n; mMonth++){
      chartLabels[mMonth] = d.getFullYear().toString() + "-" + (d.getMonth() + 1);
      d.setMonth(d.getMonth()-1);
    }
    return chartLabels;
  }

  // Manipulating data for chart
  useEffect(() => {
    const newLabels:string[] = [];
    const restaurantArr:number[] = [];
    const supermarketArr:number[] = [];
    if (props.summaryData && props.summaryData.labels) {
      const dates = getLastMonths(9);
      dates.forEach((item:string) => {
        const monthName = item.toString().trim()
          .slice(5, item.toString().trim().length);
        newLabels.push(t("charts.summary_chart.month_" + monthName));
        let restaurantAmount = 0;
        let supermarketAmount = 0;
        Object.keys(props.summaryData.datasets)
          .forEach((month:string) => {
            if (month === item) {
              props.summaryData.datasets[month].forEach((obj:any) => {
                if (obj.type === "1") {
                  restaurantAmount = +obj.amount;
                } else {
                  supermarketAmount = +obj.amount;
                }
              });
            }
          });
        restaurantArr.push(restaurantAmount);
        supermarketArr.push(supermarketAmount);
      });
      const reversedRestaurantData = restaurantArr.reverse();
      setRestaurantData(reversedRestaurantData);
      const reversedSupermarketData = supermarketArr.reverse();
      setSupermarketData(reversedSupermarketData);
    }
    const reversedDates = newLabels.reverse();
    setDatesForLabels(reversedDates);
  }, [props.summaryData, t]);

  // Get maximal amount and step for chart

  const maxRestaurant = Math.max(...restaurantData);
  const maxSupermarket = Math.max(...supermarketData);
  const maxLimit = maxRestaurant > maxSupermarket ? maxRestaurant : maxSupermarket;
  const step = Math.round(maxLimit/5);

  // taking monthly stats

  useEffect(() => {
    const previousMonthAmount = restaurantData[7] + supermarketData[7];
    const lastMonthAmount = restaurantData[8] + supermarketData[8];
    if (previousMonthAmount === 0 && lastMonthAmount === 0) {
      setMonthlyRate(0);
    } else if (previousMonthAmount !== 0 && lastMonthAmount === 0) {
      setMonthlyRate(-100);
    }
    else {
      const divider = lastMonthAmount/previousMonthAmount;
      if (divider < 1) {
        const percentage = (1 - divider) * -1;
        setMonthlyRate(Math.round(percentage*100));
      } else if (divider === 1) {
        setMonthlyRate(0);
      } else if (divider === Infinity) {
        setMonthlyRate(100);
      } else {
        const dot = divider.toString().indexOf(".");
        const percent = divider.toString().slice(0, dot)
					+ divider.toString().slice(dot + 1, dot + 3);
        setMonthlyRate(+percent);
      }
    }

    // Get general percentage
    const firstMonthAmount = restaurantData[0] + supermarketData[0];
    if (firstMonthAmount === 0 && lastMonthAmount === 0) {
      setGeneralRate(0);
    } else if (firstMonthAmount !== 0 && lastMonthAmount === 0) {
      setGeneralRate(-100);
    }
    else {
      const divider = firstMonthAmount/lastMonthAmount;
      if (divider < 1) {
        const percentage = 1 - divider;
        setGeneralRate(Math.round(percentage*100));
      } else if (divider === 1) {
        setGeneralRate(0);
      } else {
        const dot = divider.toString().indexOf(".");
        const percent = divider.toString().slice(0, dot)
					+ divider.toString().slice(dot + 1, dot + 3);
        setGeneralRate(+percent);
      }
    }
  }, [restaurantData, supermarketData]);

  const data = (canvas:any) => {
    const ctx = canvas.getContext("2d");
    const gradient = ctx.createLinearGradient(0, 0, 0, 180);
    gradient.addColorStop(0, "rgb(214,35,35,0.15)");
    gradient.addColorStop(1, "rgba(250,174,50,0)");
    const gradientTwo = ctx.createLinearGradient(0, 0, 0, 180);
    gradientTwo.addColorStop(0, "rgba(100,87,232,0.15)");
    gradientTwo.addColorStop(1, "rgba(100,87,232,0)");

    return {
      labels: datesForLabels,
      datasets: [
        {
          borderWidth: 1,
          label: "Restaurant spends",
          data: restaurantData,
          fill: "start",
          backgroundColor : gradient,
          borderColor: "#FF5242",
          yAxisID: "y-axis-1",
          lineTension: 0
        },
        props.company?.company?.isSupermarketAllowed
          ? {
            borderWidth: 1,
            label: "Supermarket spends",
            data: supermarketData,
            fill: "start",
            backgroundColor: gradientTwo,
            borderColor: "#6457E8",
            yAxisID: "y-axis-2",
            lineTension: 0
          }
          : {}
      ],
    };
  };

  const options = {
    elements: {
      point:{
        radius: 2,
      }
    },
    maintainAspectRatio: false,
    legend: {
      display: false,
    },
    tooltips: {
      callbacks: {
        label: function(tooltipItem:any, data:any) {
          const item = data["datasets"][tooltipItem.datasetIndex];
          return `${item.label} ${item.data[tooltipItem.index].toFixed(2)} €`;
        }
      }
    },
    scales: {
      yAxes: [
        {
          type: "linear",
          display: true,
          position: "left",
          id: "y-axis-1",
          gridLines: {
            drawOnArea: false,
            borderDash: [3, 3],
            color: "rgb(100, 87, 232 ,0.1)"
          },
          ticks: {
            suggestedMax: maxLimit,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: step,
            callback: function(value:any, index:any, values:any) {
              return `€ ${value}`;
            }
          }
        },
        {
          type: "linear",
          display: false,
          position: "right",
          id: "y-axis-2",
          ticks: {
            suggestedMax: maxLimit,
            suggestedMin: 0,
            fontColor: "#7C828A",
            stepSize: step,
            callback: function(value:any, index:any, values:any) {
              return `€ ${value}`;
            }
          }
        },
      ],
      xAxes: [
        {
          gridLines: {
            color: "rgb(100, 87, 232 ,0.1)",
            borderDash: [3, 3]
          },
          ticks: {
            fontSize: 10,
            fontColor: "#7C828A"
          }
        }
      ],
    },
  };
  return (
    <div className="dashboard_box_padding">
      <div className="space-between-no-center">
        <div className="flex-end">
          <div>
            <div className="summary_stat">
              <p>{t("dashboard.home.summary_total_1")}</p>
              <span data-testid="monthly_rate">{monthlyRate} %</span>
              <img
                src={monthlyRate > 0 ? greenCaret : redCaret}
                alt="increase"
              />
            </div>
            <div className="summary_stat">
              <p>{t("dashboard.home.summary_total_2")}</p>
              <span>{generalRate} %</span>
              <img
                src={generalRate > 0 ? greenCaret : redCaret}
                alt="decrease"
              />
            </div>
          </div>
        </div>
      </div>
      <h3 style={{marginLeft: "15px"}}>
        {t("dashboard.home.summary_title")}
      </h3>
      <div className="summary_chart_container">
        <Line data={data} options={options} />
      </div>
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    summaryData: state.charts.summaryChartData,
    company: state.company.company
  };
};

export default connect(mapStateToProps, null)(SummaryChart);
