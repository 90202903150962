import React, {useEffect, useRef, useState} from "react";
import {connect} from "react-redux";
import {RootState} from "../../redux/reducers/rootReducer";
import {useTranslation} from "react-i18next";
import deleteIcon from "../../img/Dashboard/Employee/delete_employee.svg";
import editIcon from "../../img/Dashboard/Employee/edit_employee.svg";

interface CustomProps {
	removeUser: (index:number) => void,
	index: number,
	updateData: (state: boolean) => void
}

function ActionDotsCSVUpload(
  {removeUser, index, updateData}:CustomProps) {
  const { t } = useTranslation();

  const [menuListOpen, setMenuListOpen] = useState(false);

  // Detect click outside for close menu
  const EmployeeActionsRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handler = (event:any) => {
      if (!EmployeeActionsRef.current?.contains(event.target as Node)) {
        if (event.target.classList.value !== "csv_upload_input") {
          setChangeDataSelected(false);
          setMenuListOpen(false);
          updateData(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  // Show if change data selected
  const [changeDataSelected, setChangeDataSelected] = useState(false);

  const changeData = () => {
    setChangeDataSelected(!changeDataSelected);
    updateData(!changeDataSelected);
  };

  const handleDeleteUser = (idx:number) => {
    setMenuListOpen(false);
    removeUser(idx);
  };

  useEffect(() => {
    if (menuListOpen && EmployeeActionsRef && EmployeeActionsRef.current) {
      EmployeeActionsRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [menuListOpen]);

  return(
    <div ref={EmployeeActionsRef} className="action_dots_container">
      <div
        onClick={() => setMenuListOpen(!menuListOpen)}
        className="action_container">
        <div className="action_dot" />
        <div className="action_dot" />
        <div className="action_dot" />
      </div>
      {menuListOpen && (
        <div className="table_menu shadow">
          <div
            data-testid="changeEmployee"
            onClick={changeData}
            className="table_menu_item row">
            <img src={editIcon} alt="info"/>
            <p>{t("dashboard.employee.item_menu_change")}</p>
          </div>
          <div
            data-testid="deleteEmployee"
            onClick={() => handleDeleteUser(index)}
            className="table_menu_item row">
            <img src={deleteIcon} alt="info"/>
            <p>{t("dashboard.employee.item_menu_delete")}</p>
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state:RootState) => {
  return {
    deleteSuccess: state.users
  };
};

export default connect(mapStateToProps, null)(ActionDotsCSVUpload);
