import React, { useEffect, useState } from "react";

import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { RootState } from "../../redux/reducers/rootReducer";
import useWindowDimensions from "../../utils/useWindowSize";

import "./PlansContainer.css";
import LunchImg from "img/Dashboard/PlansAndModules/plans_lunch_img.svg";
import BenefitsImg from "img/Dashboard/PlansAndModules/plans_benefits_img.svg";
import LunchImage from "img/Dashboard/dash_side_products_essen.png";
import BenefitsImage from "img/Dashboard/dash_side_products_mitarbeiter.png";
import closeBtn from "img/Dashboard/PlansAndModules/closePlansBtn.png";
import ActiveIcon from "img/Dashboard/PlansAndModules/Active_icon.png";

import Button from "../Buttons/Button";
import LunchForm from "./LunchForm";
import VerticalDivider from "./VerticalDivider";

interface CustomProps {
  showBenefits?: (state: boolean) => void;
  plan: string;
  handleSum: (sum: number) => void;
  handleData: (data: any) => void;
  showForm: boolean;
  setShowForm: (s: boolean) => void;
  updatePlanData: any;
  plans?: any;
  company?: any;
  isCopyPlan?: boolean;
  addedPlans?: any;
  supermarketsAllowed: boolean;
  toggleSupermarketAllowed: () => void;
  setIsFetched: (value: boolean) => void;
}

function PlansContainer({
  showBenefits,
  plan,
  handleSum,
  handleData,
  showForm,
  setShowForm,
  updatePlanData,
  plans,
  company,
  isCopyPlan,
  supermarketsAllowed,
  toggleSupermarketAllowed,
  setIsFetched
}: CustomProps) {
  const { t } = useTranslation();
  let containerHeader;
  let containerImg;
  let containerTitle;
  let containerText;
  let button;
  let planImg;
  switch (plan) {
  case "lunch":
    containerImg = LunchImage;
    containerHeader = "Corplife Lunch";
    containerTitle = "Corplife Lunch";
    containerText = "dashboard.plans.lunch_text";
    button = ["true", "add", "dashboard.plans.lunch_button"];
    planImg = BenefitsImg;
    break;
  case "benefits":
    containerImg = BenefitsImage;
    containerHeader = "Corplife Benefits";
    containerTitle = "Mitarbeiterwelt Benefits";
    containerText = "dashboard.plans.benefits_text";
    button = ["false", "dashboard.plans.benefits_button"];
    planImg = LunchImg;
    break;
  default:
    containerImg = LunchImage;
    containerHeader = "Corplife Lunch";
    containerTitle = "Corplife Lunch";
    containerText = "dashboard.plans.lunch_text";
    button = ["true", "export", "Export"];
    planImg = LunchImg;
  }

  // Handling lunch button click
  const lunchButtonHandler = () => {
    showBenefits && showBenefits(false);
    setShowForm(true);
  };

  // set less item in table for small screens
  const [mainRatio, setMainRatio] = useState("60% 40%");
  const { width } = useWindowDimensions();
  useEffect(() => {
    if (width < 800) {
      setMainRatio("1fr");
    } else {
      setMainRatio("60% 40%");
    }
  }, [width]);

  // Set active icon in header
  const [isActive, setIsActive] = useState(false);
  useEffect(() => {
    if (plans && plans.total > 0) {
      setIsActive(true);
    }
  }, [plans]);

  useEffect(() => {
    if (showForm) {
      setIsActive(true);
    }
  }, [showForm]);

  const [isActivated, setIsActivated] = useState<boolean>(false);

  useEffect(() => {
    if (company && company.modules && company.modules.length) {
      setIsActivated(company.modules[0].isActivated ?? false);
    }
  }, [company]);

  return (
    <div className="plans_container">
      {showForm ? (
        <LunchForm
          handleSum={handleSum}
          handleData={handleData}
          updatePlanData={updatePlanData}
          isCopyPlan={isCopyPlan}
		  setIsFetched={(value: boolean) => setIsFetched(value)}
          supermarketsAllowed={supermarketsAllowed}
          toggleSupermarketAllowed={toggleSupermarketAllowed}
        />
      ) : (
        <div className="plans_container_main shadow">
          <VerticalDivider ratio={mainRatio}>
            <div>
              <h3 className="plans_title">{containerTitle}</h3>
              <p className="plans_text">{t(containerText)}</p>
            </div>
            <img src={planImg} className="full-width" alt="plansImg" />
          </VerticalDivider>

          <div style={{ marginTop: "3rem" }} className="flex-end">
            {button[0] === "true" && isActivated ? (
              <Button
                buttonType="primary"
                buttonLabel={t(button[2])}
                buttonHandler={lunchButtonHandler}
              />
            ) : (
              <Button
                buttonType="primary"
                buttonLabel={t(button[2])}
                disabled={true}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}

const mapStateToProps = (state: RootState) => {
  return {
    plans: state.plans.plans,
    company: state.company.company.company
  };
};

export default connect(mapStateToProps, null)(PlansContainer);
